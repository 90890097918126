import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { useTable, useSortBy, useGlobalFilter, usePagination, useRowSelect } from 'react-table';
import { Fragment } from 'react';
import GlobalFilter from './GlobalFilter';
import './MainTable.css';
import Checkbox from './Checkbox';
// import { AddNew } from './AddNew';
import { Tooltip } from 'reactstrap';
import CellCustUser from './CellCustUser';
// import CustStatus from './CustStatus';
import RedressalFormStatus from './RedressalFormStatus';
import { AddNew } from './AddNew';
import { AddTableRow } from './AddTableRow';
import { TableRow } from '@material-ui/core';

const FundsTable = (props) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const columns = useMemo(() => props.COLUMNS, [props.COLUMNS]);
    const data = useMemo(() => props.tableData, [props.tableData]);

    console.log(data);

    const { getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
        selectedFlatRows,
        state,
        setGlobalFilter } = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination, useRowSelect,
            // (hooks) => {
            //     hooks.visibleColumns.push((columns) => {
            //         return [
            //             {
            //                 id: 'selection',
            //                 Header: ({ getToggleAllRowsSelectedProps }) => (
            //                     <Checkbox {...getToggleAllRowsSelectedProps()} />
            //                 ),
            //                 Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
            //             },
            //             ...columns
            //         ]
            //     })
            // }
        );
    const { globalFilter, pageIndex, pageSize } = state;
    console.log(state);

    const numberWithCommas = (num) => {
        if(num)
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const toggle = () => setTooltipOpen(!tooltipOpen);



    return (
        <Fragment>
            <div >
                <Table {...getTableProps()} hover>
                    <thead >
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                        </span>
                                    </th>
                                ))}
                                {props.type === "Grievance" && (<th><p id="widthAction">Action</p></th>)}
                                {(props.type === "Customer" || props.type === "Users") && <th>Action</th>}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row)
                            console.log("row", row.original)
                            return (
                                <tr>
                                    <td>{row.original['Name of Securities']}</td>
                                    <td>{row.original['Current Weight']}</td>
                                    <td>{numberWithCommas(row.original['Number of Shares'])}</td>
                                    <td>{numberWithCommas(row.original['Suggested Amount Allocation'])}</td>
                                    <td>{numberWithCommas(row.original['Actual Allocation'])}</td>
                                    <td>{numberWithCommas(row.original['CMP'])}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
            <div >
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <span style={{ paddingLeft: '10px' }}>
                    | Go to page: {' '}
                    <input type='number' defaultValue={pageIndex + 1}
                        onChange={(e) => {
                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                            gotoPage(pageNumber);
                        }}
                        style={{ width: '35px', height: '35px' }} />
                </span>
                <span style={{ paddingLeft: '10px' }}>
                    <select value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))} style={{ width: '9em', height: '35px' }}>
                        {[5, 25, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize} Records
                            </option>
                        ))}
                    </select>
                </span>

            </div>
            {/* <pre>
            <code>
                {JSON.stringify(
                    {
                        selectedFlatRows: selectedFlatRows.map((row) => row.original)
                    },
                    null,
                    2
                )}
            </code>
        </pre> */}
        </Fragment>
    );
}

export default FundsTable;