import { useState } from 'react';
import { toast } from 'react-toastify';
import {
	Button,
	FormGroup,
	Label,
	CustomInput,
	Breadcrumb,
	BreadcrumbItem,
} from 'reactstrap';
import '../form.css';
import { Formik } from 'formik';
// import Modal from '../Modal-UI/Modal';

import ApiServices from '../../ApiService/ApiConfig';

// material-ui icons
import ShortTextIcon from '@material-ui/icons/ShortText';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import LoadingSpinner from '../UI/Loader';
import { Link } from 'react-router-dom';
// import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

const AddUser = (props) => {
	const [role, setRole] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const addUserForm = () => (
		<div>
			<Formik
				initialValues={{
					firstName: '',
					middleName: '',
					lastName: '',
					mobileNumber: '',
					password: '',
					//  role: [{admin: 2}, {superAdmin: 1}],
					email: '',
					designation: '',
				}}
				validate={(values) => {
					const errors = {};
					// firstname
					if (!values.firstName) {
						errors.firstName = 'Required';
					} else if (!/^[A-Za-z]+/i.test(values.firstName)) {
						errors.firstName = 'Field contain only letters';
					}
					// middlename
					if (!values.middleName) {
						errors.middleName = 'Required';
					} else if (!/^[A-Za-z]+/i.test(values.middleName)) {
						errors.middleName = 'Field contain only letters';
					}
					// lastname
					if (!values.lastName) {
						errors.lastName = 'Required';
					} else if (!/^[A-Za-z]+/i.test(values.lastName)) {
						errors.lastName = 'Field contain only letters';
					}
					// mobilenumber
					if (!values.mobileNumber) {
						errors.mobileNumber = 'Required';
					} else if (
						!/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(
							values.mobileNumber
						)
					) {
						errors.mobileNumber = 'Number contain Ten Digits only';
					}
					// password
					if (!values.password) {
						errors.password = 'Required';
					} else if (
						!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(
							values.password
						)
					) {
						errors.password = 'Please create a valid password';
					}
					// email
					if (!values.email) {
						errors.email = 'Required';
					} else if (
						!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
							values.email
						)
					) {
						errors.email = 'Invalid email address';
					}
					// designation
					if (!values.designation) {
						errors.designation = 'Required';
					} else if (!/^[A-Za-z]+/i.test(values.designation)) {
						errors.designation =
							'Designation contain only letters ';
					}
					// role
					// if (!values.role) {
					//   errors.role = 'Required';
					// }
					return errors;
				}}
				onSubmit={(values, { setSubmitting }) => {
					setSubmitting(false);
					console.log(values, role);
					// console.log(role);

					setIsLoading(true);
					ApiServices.addUser({
						first_name: values.firstName,
						middle_name: values.middleName,
						last_name: values.lastName,
						username: values.mobileNumber,
						password: values.password,
						phone: values.mobileNumber,
						email: values.email,
						designation: values.designation,
						role_id: parseInt(role),
						status: '',
					})
						.then((res) => {
							setIsLoading(false);
							if (res.code === 'P2002') {
								toast.warning(res.message);
							} else {
								props.history.push('/user');
								toast.success(`User Added Successful.`);
							}
						})
						.catch((err) => {
							setIsLoading(false);
							toast.warning(`${err}`);
						});
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					/* and other goodies */
				}) => (
					<form onSubmit={handleSubmit} className="mt-3">
						<div className="row">
							<div className="col-6">
								<div className="form-group mb-3 ml-3">
									<label className="form-label required">
										<ShortTextIcon /> First Name
									</label>
									<input
										type="text"
										name="firstName"
										min="2"
										className="form-control"
										placeholder="e.g. : Akshay"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.firstName}
										required
									/>
									<p style={{ color: 'red' }}>
										{errors.firstName}
									</p>
									<p style={{ color: 'red' }}>
										{touched.firstName}
									</p>
								</div>
							</div>
							<div className="col-6">
								<div className="form-group mb-3 mr-3">
									<label className="form-label required">
										<ShortTextIcon /> Middle name
									</label>
									<input
										type="text"
										name="middleName"
										min="2"
										className="form-control"
										placeholder="e.g. : Mukesh"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.middleName}
										required
									/>
									<p style={{ color: 'red' }}>
										{errors.middleName}
									</p>
									<p style={{ color: 'red' }}>
										{touched.middleName}
									</p>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<div className="form-group mb-3 ml-3">
									<label className="form-label required">
										<ShortTextIcon /> Last name
									</label>
									<input
										type="text"
										name="lastName"
										min="2"
										className="form-control"
										placeholder="e.g. : Sharma"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.lastName}
										required
									/>
									<p style={{ color: 'red' }}>
										{errors.lastName}
									</p>
									<p style={{ color: 'red' }}>
										{touched.lastName}
									</p>
								</div>
							</div>
							<div className="col-6">
								<div className="form-group mb-3 mr-3">
									<label className="form-label required">
										<PhoneIphoneIcon /> Mobile Number
									</label>
									<input
										type="tel"
										name="mobileNumber"
										maxLength="10"
										className="form-control"
										placeholder="e.g. : 9878654532"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.mobileNumber}
										required
									/>
									<p style={{ color: 'red' }}>
										{errors.mobileNumber}
									</p>
									<p style={{ color: 'red' }}>
										{touched.mobileNumber}
									</p>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<div className="form-group mb-3 ml-3">
									<label className="form-label required">
										<VpnKeyIcon /> Password
									</label>
									<input
										type="password"
										name="password"
										min="6"
										max="10"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.password}
										className="form-control"
										placeholder="e.g. : Abc!@#"
										required
									/>
									<p style={{ color: 'red' }}>
										{errors.password}
									</p>
									<p style={{ color: 'red' }}>
										{touched.password}
									</p>
								</div>
							</div>
							<div className="col-6">
								<div className="form-group mb-3 mr-3">
									<label className="form-label required">
										<EmailOutlinedIcon /> Email address
									</label>
									<input
										type="email"
										name="email"
										className="form-control"
										placeholder="e.g. : akshay23@gmail.com"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.email}
									/>
									<p style={{ color: 'red' }}>
										{errors.email}
									</p>
									<p style={{ color: 'red' }}>
										{touched.email}
									</p>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<div className="form-group mb-3 ml-3">
									<label className="form-label required">
										<ShortTextIcon /> Designation
									</label>
									<input
										type="text"
										name="designation"
										min="2"
										className="form-control"
										placeholder="Enter Designation For Users"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.designation}
										required
									/>
									<p style={{ color: 'red' }}>
										{errors.designation}
									</p>
									<p style={{ color: 'red' }}>
										{touched.designation}
									</p>
								</div>
							</div>
							<div className="col-6">
								<div className="mr-3">
									<FormGroup>
										<Label className="required mr-3">
											<i class="fas fa-user-tie"></i> Role
										</Label>
										<CustomInput
											type="select"
											onChange={(e) =>
												setRole(e.target.value)
											}
											required
										>
											<option>Select Option</option>
											<option value="2" name={role}>
												Admin
											</option>
											<option value="1" name={role}>
												Super Admin
											</option>
										</CustomInput>
									</FormGroup>
								</div>
							</div>
						</div>

						<button
							type="submit"
							className="btn btn-success mt-3 mb-3"
							style={{ width: '35%', marginLeft: '142px' }}
							disabled={isSubmitting}
						>
							Submit
						</button>
					</form>
				)}
			</Formik>
		</div>
	);

	return (
		<>
			<div className="container" id="adjust-top">
				<Breadcrumb>
					<BreadcrumbItem>
						<Link to={{pathname: `/user`}}>Users</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>Add User</BreadcrumbItem>
				</Breadcrumb>
				<div className="container-fluid bg-light text-center">
					<h3 className="pl-3 pr-3 pt-3 pb-3">Add User</h3>
				</div>

				<div className="container">
					<div className="row">
						<div className="col-md-6 offset-md-3">
							{addUserForm()}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddUser;
