import React, { useRef, useState, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import Print from './Print';
// import ComponentToPrintNew from './ComponentToPrintNew';
import Testing from './Testing';
import Pcomp from './Pcomp';
import '../form.css';
import './customerStyle.css';
import SEBI from './SEBI - KYC.pdf';
import axios from 'axios';
import moment, { invalid } from 'moment';
//country code imports
import PhoneCode from 'react-phone-code';
import Select from 'react-select';
import {
	Col,
	Row,
	Button,
	Form,
	FormGroup,
	Label,
	Input,
	FormText,
	CustomInput,
	Breadcrumb,
	BreadcrumbItem,
} from 'reactstrap';

import ApiServices from '../../ApiService/ApiConfig';

// material-ui icons
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ShortTextIcon from '@material-ui/icons/ShortText';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CreateIcon from '@mui/icons-material/Create';
import EqualizerOutlinedIcon from '@material-ui/icons/EqualizerOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import { useUserInfo } from '../../ContextProviders/UserInfo';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useParams } from 'react-router-dom';

const enumGenderOption = ['Male', 'Female', 'Other'];
const enumSourceOfFundOption = [
	{ source_of_fund_flow: 'Salary' },
	{ source_of_fund_flow: 'Gifts' },
	{ source_of_fund_flow: 'Inheritance' },
	{ source_of_fund_flow: 'Investment Income' },
	{ source_of_fund_flow: 'Business/Profession' },
	{ source_of_fund_flow: 'Other Sources' },
];

const getAssetClasses = (parameter) => {
	let splited = parameter.split('$$').map((el) => {
		return { value: el, label: el };
	});
	return splited;
};

const ViewCustomer = (props) => {
	const userInfo = useUserInfo();

	const [disablePreview, setDisablePreview] = useState(true);

	const Cust_Data = props.location.state.data;
	const cust_id = Cust_Data.id;
	const fname = Cust_Data.first_name;
	const mname = Cust_Data.middle_name;
	const lname = Cust_Data.last_name;
	const emailId = Cust_Data.email;
	const phone = Cust_Data.phone;

	//myAddition(VG)
	let createdAt = Cust_Data.createdAt.split('.')[0];
	let updatedAt = Cust_Data.updatedAt.split('.')[0];

	let newNomineeDet;
	if (Cust_Data.customer_profile.nominee_details.length > 0) {
		newNomineeDet = Cust_Data.customer_profile.nominee_details;
	} else {
		newNomineeDet = [
			{
				nominee_name: '',
				nominee_mobile_no: '',
				nominee_relation: '',
				createdBy: userInfo.id,
			},
		];
	}

	//broker
	let newBrokerDet;
	if (Cust_Data.customer_profile.broker_details.length > 0) {
		newBrokerDet = Cust_Data.customer_profile.broker_details.map(
			(broker) => {
				return {
					...broker,
					broker_sub_asset_class: getAssetClasses(
						broker.broker_sub_asset_class
					),
				};
			}
		);
	} else {
		newBrokerDet = [
			{
				broker_name: '',
				broker_code: '',
				broker_email: '',
				broker_sub_asset_class: '',
				createdBy: userInfo.id,
			},
		];
	}
	// broker

	let newBankDet;
	if (Cust_Data.customer_profile.bank_acc_details.length > 0) {
		// newNomineeDet = Cust_Data.customer_profile.nominee_details;
		newBankDet = Cust_Data.customer_profile.bank_acc_details;
	} else {
		newBankDet = [
			{
				bank_name: '',
				bank_acc_no: '',
				ifsc_code: '',
				micr_code: '',
				crn: '',
				customer_id: '',
				createdBy: userInfo.id,
			},
		];
	}

	let newDematDet;
	if (Cust_Data.customer_profile.demat_acc_details.length > 0) {
		// newNomineeDet = Cust_Data.customer_profile.nominee_details;
		newDematDet = Cust_Data.customer_profile.demat_acc_details;
	} else {
		newDematDet = [
			{ dp_id: '', dp_name: '', client_code: '', createdBy: userInfo.id },
		];
	}

	let newTradingDet;
	if (Cust_Data.customer_profile.trading_acc_details.length > 0) {
		// newNomineeDet = Cust_Data.customer_profile.nominee_details;
		newTradingDet = Cust_Data.customer_profile.trading_acc_details;
	} else {
		newTradingDet = [
			{ dp_id: '', dp_name: '', client_code: '', createdBy: userInfo.id },
		];
	}
	const source_of_fund = Cust_Data.customer_profile.source_of_fund_flow;
	const newSourceOfFundField = enumSourceOfFundOption.map((ele) => ({
		source_of_fund_flow: ele.source_of_fund_flow,
		checked: source_of_fund.some(
			(NewEle) => NewEle.source_of_fund_flow === ele.source_of_fund_flow
		),
	}));

	const [defaultDisabled, setDefaultDisabled] = useState(false);

	// name and other-info state
	const [profileData, setProfileData] = useState();
	const [firstname, setFirstname] = useState(fname);
	const [middleName, setMiddleName] = useState(mname);
	const [lastName, setLastName] = useState(lname);
	const [dob, setDob] = useState(
		moment(Cust_Data.customer_profile.dob).format('YYYY-MM-DD')
	);
	const [occupation, setOccupation] = useState(
		Cust_Data.customer_profile.occupation
	);
	const [residental_status, setResidental_status] = useState(
		Cust_Data.customer_profile.residental_status
	);
	const [designation, setDesignation] = useState(
		Cust_Data.customer_profile.designation
	);
	const [organization, setOrganization] = useState(
		Cust_Data.customer_profile.occupation
	);
	const [mobileNumber, setMobileNumber] = useState(phone);
	const [whatsAppNum, setWhatsAppNum] = useState(
		Cust_Data.customer_profile.whatsapp_no
	);
	const [email, setEmail] = useState(emailId);
	const [emailVerificationStatus, setEmailVerificationStatus] = useState(
		Cust_Data.cust_email_verified_status !== null
			? Cust_Data.cust_email_verified_status
			: 'Unverified'
	);
	const [phoneVerificationStatus, setPhoneVerificationStatus] = useState(
		Cust_Data.cust_phone_verified_status !== null
			? Cust_Data.cust_phone_verified_status
			: 'Unverified'
	);
	const [emailPassword, setEmailPassword] = useState(
		Cust_Data.customer_profile.cust_email_password
	);
	const [motherName, setMotherName] = useState(
		Cust_Data.customer_profile.mothers_maiden_name
	);
	const [personal_email, setPersonal_email] = useState(
		Cust_Data.customer_profile.personal_email
	);
	const [fathers_spouse_name, setFathers_spouse_name] = useState(
		Cust_Data.customer_profile.fathers_spouse_name
	);
	const [proof_of_identity_submitted, setProof_of_identity_submitted] =
		useState(Cust_Data.customer_profile.proof_of_identity_submitted);

	//other-contact details
	const [tel_off, setTel_off] = useState(Cust_Data.customer_profile.tel_off);
	const [tel_res, setTel_res] = useState(Cust_Data.customer_profile.tel_res);
	const [fax, setFax] = useState(Cust_Data.customer_profile.fax);

	const [
		specify_permanant_address_proof,
		setSpecify_permanant_address_proof,
	] = useState(Cust_Data.customer_profile.specify_permanant_address_proof);
	const [
		permanant_adr_city_town_village,
		setPermanant_adr_city_town_village,
	] = useState(Cust_Data.customer_profile.permanant_adr_city_town_village);
	const [permanant_adr_state, setPermanant_adr_state] = useState(
		Cust_Data.customer_profile.permanant_adr_state
	);
	const [permanant_adr_country, setPermanant_adr_country] = useState(
		Cust_Data.customer_profile.permanant_adr_country
	);

	// Create Broker Section states
	const [brokerId, setBrokerId] = useState(
		Cust_Data.customer_profile.broker_id
	);
	const [brokerName, setBrokerName] = useState(
		Cust_Data.customer_profile.broker_name
	);
	const [brokerEmail, setBrokerEmail] = useState(
		Cust_Data.customer_profile.broker_email
	);
	// gender state
	const [gender, setGender] = useState(Cust_Data.customer_profile.gender);
	// documents and address state
	const [nationality, setNationality] = useState(
		Cust_Data.customer_profile.nationality
	);
	const [pan, setPan] = useState(Cust_Data.customer_profile.pan_no);
	const [adhar, setAdhar] = useState(Cust_Data.customer_profile.aadhar_no);
	const [address, setAddress] = useState(Cust_Data.customer_profile.address);
	const [pincode, setPincode] = useState(Cust_Data.customer_profile.pincode);

	// commu address (correspondence address) states
	const [commAddress, setCommAddress] = useState(
		Cust_Data.customer_profile.communication_address
	);
	const [commPincode, setCommPincode] = useState(
		Cust_Data.customer_profile.communicationAddressPincode
	);
	const [
		adr_correspondence_city_town_village,
		setAdr_correspondence_city_town_village,
	] = useState(
		Cust_Data.customer_profile.adr_correspondence_city_town_village
	);
	const [adr_correspondence_state, setAdr_correspondence_state] = useState(
		Cust_Data.customer_profile.adr_correspondence_state
	);
	const [adr_correspondence_country, setAdr_correspondence_country] =
		useState(Cust_Data.customer_profile.adr_correspondence_country);
	const [
		specify_correspondence_address_proof,
		setSpecify_correspondence_address_proof,
	] = useState(
		Cust_Data.customer_profile.specify_correspondence_address_proof
	);

	// bank-details state
	// const [bankName, setBankName] = useState(Cust_Data.customer_profile.bank_name);
	// const [accountNum, setAccountNum] = useState(Cust_Data.customer_profile.bank_acc_no);
	// const [ifsc, setIfsc] = useState(Cust_Data.customer_profile.ifsc_code);

	const [bankDetails, setBankDetails] = useState(newBankDet);
	const [dematAccount, setDematAccount] = useState(newDematDet);
	const [tradingAccount, setTradingAccount] = useState(newTradingDet);

	// Nominee state
	const [nomineeDetails, setNomineeDetails] = useState(newNomineeDet);

	// Broker state
	const [brokerDetails, setBrokerDetails] = useState(newBrokerDet);

	// Source of Fund Flow details state
	const [incsSource, setIncsSource] = useState(
		newSourceOfFundField || enumSourceOfFundOption
	);

	// Annual Income Range state
	const [incomeRang, setIncomeRang] = useState(
		Cust_Data.customer_profile.annual_income
	);
	// other-info state
	const [birthPlace, setBirthPlace] = useState(
		Cust_Data.customer_profile.birth_place
	);
	const [netWorth, setNetWorth] = useState(
		Cust_Data.customer_profile.net_worth
	);
	const [highestEdu, setHighestEdu] = useState(
		Cust_Data.customer_profile.highest_education
	);
	// weather politically exposed state
	const [weatherPoliticallyExp, setWeatherPoliticallyExp] = useState(
		Cust_Data.customer_profile.politically_exposed
	);
	// marriage state
	const [marriageStatus, setMarriageStatus] = useState(
		Cust_Data.customer_profile.marital_status
	);
	const [dateOfMariage, setDateOfMariage] = useState(
		moment(Cust_Data.customer_profile.date_of_marriage).format('YYYY-MM-DD')
	);

	const [countyCode, setCountyCode] = useState(
		Cust_Data.customer_profile.country_code
	);

	// file upload state data
	const [passPortPhoto, setPassPortPhoto] = useState(
		Cust_Data.customer_profile.passport_photo_path
	);
	const [panFile, setPanFile] = useState(
		Cust_Data.customer_profile.pan_file_path
	);
	const [adharFile, setAdharFile] = useState(
		Cust_Data.customer_profile.adhar_file_path
	);
	const [provAccountCanCheck, setProvAccountCanCheck] = useState(
		Cust_Data.customer_profile.prov_account_file_path
	);
	const [provAccountTwoCanCheck, setProvAccountTwoCanCheck] = useState(
		Cust_Data.customer_profile.prov_account_file_path_two
	);
	const [someFileCanCheck, setsomeFileCanCheck] = useState(
		Cust_Data.customer_profile.some_file_path
	);
	
	const [score, setScore] = useState(Cust_Data.customer_profile.score);
	const [riskProfStatus, setRiskProfStatus] = useState(
		Cust_Data.customer_profile.status
	);

	// other
	const [username, setUserName] = useState(phone); //username
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (createdAt !== updatedAt) setDisablePreview(false);
	}, [updatedAt, createdAt]);

	const { id: id } = useParams();

	const downloadCommAddressProof = (e) => {
		e.preventDefault();
		saveAs(
			`${process.env.REACT_APP_URL_API}/${Cust_Data.customer_profile.communication_address_proof_path}`
		);
	};

	const downloadPassportPhoto = (e) => {
		e.preventDefault();
		saveAs(`${process.env.REACT_APP_URL_API}/${passPortPhoto}`);
	};

	const downloadPanCard = (e) => {
		e.preventDefault();
		saveAs(`${process.env.REACT_APP_URL_API}/${panFile}`);
	};

	const downloadAdharCard = (e) => {
		e.preventDefault();
		saveAs(`${process.env.REACT_APP_URL_API}/${adharFile}`);
	};

	const downloadCancelletionCheck = (e) => {
		e.preventDefault();
		saveAs(`${process.env.REACT_APP_URL_API}/${provAccountCanCheck}`);
	};

	const downloadCancelletionCheckTwo = (e) => {
		e.preventDefault();
		saveAs(`${process.env.REACT_APP_URL_API}/${provAccountTwoCanCheck}`);
	};
	const downloadNomineeAadhaar = (path) => {
		saveAs(`${process.env.REACT_APP_URL_API}/${path}`);
	}

	const downloadSomeFile = (e) => {
		e.preventDefault();
		saveAs(`${process.env.REACT_APP_URL_API}/${someFileCanCheck}`);
	};

	// kyc section controll
	const handleEditPage = (e) => {
		e.preventDefault();
		// setDefaultDisabled(true);
		saveAs(`${SEBI}`);
	};

	const onValueChange = (index, e) => {
		const { name, value } = e.target;
		const list = [...nomineeDetails];
		list[index][name] = value;
		setNomineeDetails(list);
	};

	const addNominee = (e) => {
		setNomineeDetails([
			...nomineeDetails,
			{
				nominee_name: '',
				nominee_mobile_no: '',
				nominee_relation: '',
				createdBy: userInfo.id,
			},
		]);
	};

	const removeNominee = (index) => {
		const list = [...nomineeDetails];
		list.splice(index, 1);
		setNomineeDetails(list);
	};

	const onValueBroChange = (index, e) => {
		const { name, value } = e.target;
		const list = [...brokerDetails];
		list[index][name] = value;

		setBrokerDetails(list);
	};

	const addBroker = (e) => {
		setBrokerDetails([
			...brokerDetails,
			{
				broker_name: '',
				broker_code: '',
				broker_sub_asset_class: '',
				broker_email: '',
				createdBy: userInfo.id,
			},
		]);
	};

	const removeBroker = (index) => {
		const list = [...brokerDetails];
		list.splice(index, 1);
		setBrokerDetails(list);
	};

	const MultiselectCheckbox = ({ options, onChange }) => {
		const toggle = (index) => {
			const newData = [...incsSource];
			newData.splice(index, 1, {
				source_of_fund_flow: incsSource[index].source_of_fund_flow,
				checked: !incsSource[index].checked,
			});
			setIncsSource(newData);
			onChange(newData.filter((x) => x.checked));
		};

		return (
			<>
				{incsSource.map((item, index) => (
					<label key={item.source_of_fund_flow}>
						<input
							readOnly
							type="checkbox"
							checked={item.checked || false}
							onClick={() => toggle(index)}
							disabled
						/>
						{item.source_of_fund_flow}
					</label>
				))}
			</>
		);
	};

	const style = {
		position: 'absolute',
		overflow: 'auto',
		top: '55%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 900,
		height: 550,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	};

	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});
	
	return (
		<>
			<div className="container" id="adjust-top">
				<Breadcrumb>
					<BreadcrumbItem>
						<Link to={{pathname: `/customer`}}>Customers</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>View Customer</BreadcrumbItem>
				</Breadcrumb>
				<div className="container-fluid bg-light text-center">
					<h3 className="pl-3 pr-3 pt-3 pb-3">View Customer</h3>
				</div>

				<div className="row">
					<div className="col-6">
						<button
							style={{ marginRight: '435px' }}
							onClick={handlePrint}
							title="Refresh Upload Model"
							className="btn btn-outline-success"
							id="btn-success"
							disabled={disablePreview}
						>
							Preview KYC
						</button>

						<div style={{ display: 'none' }}>
							<Print
								passPortPhoto={passPortPhoto}
								CustData={Cust_Data}
								ref={componentRef}
							/>
						</div>

					</div>

					<div className="col-6">
						<button
							style={{ marginRight: '0%' }}
							title="Refresh Upload Model"
							className="btn btn-outline-success"
							id="btn-success"
						>
							<Link
								style={{ color: 'black' }}
								to={{
									pathname: `/updatecustomer/${id}`,
									state: { data: Cust_Data },
								}}
							>
								<CreateIcon />
							</Link>
						</button>
					</div>
				</div>

				<div>
					<Form>
						<Row form>
							<Col md={12}>
								<br />
								<FormGroup className="shadow-none rounded">
									<h5
										className="pl-1 pr-1 pt-1 pb-1"
										style={{
											width: '100%',
											textAlign: 'center',
											backgroundColor: '#eff6f9',
										}}
									>
										Personal Details
									</h5>
								</FormGroup>
							</Col>
							<Col md={4}>
								<FormGroup>
									<Label className="required">
										<ShortTextIcon /> First Name
									</Label>
									<Input
										type="text"
										min="2"
										className="form-control"
										placeholder="e.g. : Akshay"
										pattern="[A-Za-z]{2,15}"
										title="FirstName should only contain letters. e.g. john"
										value={firstname}
										required
										readOnly
									/>
								</FormGroup>
							</Col>
							<Col md={4}>
								<FormGroup>
									<Label>
										<ShortTextIcon /> Middle Name
									</Label>
									<Input
										type="text"
										min="2"
										className="form-control"
										placeholder="e.g. : Mukesh"
										pattern="[A-Za-z]{2,15}"
										title="MiddleName should only contain letters. e.g. john"
										value={middleName}
										required
										readOnly
									/>
								</FormGroup>
							</Col>
							<Col md={4}>
								<FormGroup>
									<Label className="required">
										<ShortTextIcon /> Last Name
									</Label>
									<Input
										type="text"
										min="2"
										className="form-control"
										placeholder="e.g. : Sharma"
										pattern="[A-Za-z]{2,15}"
										title="LastName should only contain letters. e.g. john"
										value={lastName}
										required
										readOnly
									/>
								</FormGroup>
							</Col>
							<Col md={4}>
								<FormGroup>
									<Label className="required">
										<i class="far fa-calendar-plus"></i>{' '}
										Date of Birth
									</Label>
									<Input
										type="date"
										className="form-control"
										name="dob"
										placeholder="Date-of-Birth"
										value={dob}
										required
										readOnly
									/>
								</FormGroup>
							</Col>
							<Col md={4}>
								<FormGroup>
									<Label className="required">
										<PeopleOutlinedIcon /> Gender
									</Label>
									<br />
									{enumGenderOption.map((a) => (
										<>
											<input
												readOnly
												type="radio"
												value={a}
												name={gender}
												checked={a === gender}
												className="ml-1"
												disabled
											/>{' '}
											{a}
										</>
									))}
								</FormGroup>
							</Col>
							<Col md={4}>
								<FormGroup>
									<Label>
										<ShortTextIcon /> Father/ Spouse Name
									</Label>
									<Input
										type="text"
										name="fathers_spouse_name"
										min="2"
										className="form-control"
										placeholder="e.g. : Mukesh or Neha"
										// pattern="[A-Za-z]{2,15}"
										// title="MiddleName should only contain letters.and only have single word e.g. john"
										value={fathers_spouse_name}
										disabled={defaultDisabled}
										readOnly
									/>
								</FormGroup>
							</Col>
							<Col md={2}>
								<FormGroup>
									<Label className="required">
										<ShortTextIcon /> Occupation
									</Label>
									<Input
										type="text"
										min="2"
										className="form-control"
										placeholder="e.g. : Doctor"
										pattern="[A-Za-z]{2,15}"
										title="Occupation should only contain letters. e.g. Doctor"
										value={occupation}
										required
										readOnly
									/>
								</FormGroup>
							</Col>
							<Col md={2}>
								<FormGroup>
									<Label className="required">
										<ShortTextIcon /> Resident Status
									</Label>
									<Input
										type="text"
										min="2"
										className="form-control"
										placeholder="e.g. : Doctor"
										pattern="[A-Za-z]{2,15}"
										title="residental_status should only contain letters. e.g. Doctor"
										value={residental_status}
										required
										readOnly
									/>
								</FormGroup>
							</Col>
							<Col md={5}>
								<FormGroup>
									<Label className="required">
										<i class="far fa-address-book"></i>{' '}
										Permanent Address
									</Label>
									<Input
										type="text"
										min="3"
										className="form-control"
										placeholder="e.g. : Apartment, studio, or floor"
										title="Address should contain atlist Three letters. e.g. City Name"
										value={address}
										required
										readOnly
									/>
								</FormGroup>
							</Col>

							<Col md={3}>
								<FormGroup>
									<Label className="required">
										<ShortTextIcon />
										Specify Permanent Address Proof
									</Label>
									<Input
										type="text"
										name="specify_permanant_address_proof"
										className="form-control"
										placeholder="e.g. : adhar/pan/elec bill"
										value={specify_permanant_address_proof}
										disabled={defaultDisabled}
										required
										readOnly
									/>
								</FormGroup>
							</Col>

							<Col md={2}>
								<FormGroup>
									<Label className="required">
										<RoomOutlinedIcon /> Pin code
									</Label>
									<Input
										type="tel"
										max="6"
										className="form-control"
										placeholder="e.g. : 425403"
										pattern="[0-9]{6,6}"
										title="Pin-Code Number should only have Digites. e.g. 654532 And should have length of Six Digits"
										value={pincode}
										required
										readOnly
									/>
								</FormGroup>
							</Col>

							<Col md={3}>
								<FormGroup>
									<Label>
										<ShortTextIcon /> City/Town/Village
									</Label>
									<Input
										type="text"
										min="3"
										className="form-control mt-1"
										placeholder="e.g. : Mumbai"
										title="City/Town/Village should contain atlist Three letters. e.g. City Name"
										value={permanant_adr_city_town_village}
										disabled={defaultDisabled}
										readOnly
									/>
								</FormGroup>
							</Col>

							<Col md={3}>
								<FormGroup>
									<Label>
										<ShortTextIcon /> State
									</Label>
									<Input
										type="text"
										min="3"
										className="form-control mt-1"
										placeholder="e.g. : Maharashtra"
										title="State should contain only letters. e.g. GOA"
										value={permanant_adr_state}
										disabled={defaultDisabled}
										readOnly
									/>
								</FormGroup>
							</Col>

							<Col md={3}>
								<FormGroup>
									<Label>
										<ShortTextIcon /> Country
									</Label>
									<Input
										type="text"
										min="3"
										className="form-control mt-1"
										placeholder="e.g. : India"
										title="Country should contain only letters. e.g. India"
										value={permanant_adr_country}
										disabled={defaultDisabled}
										readOnly
									/>
								</FormGroup>
							</Col>

							<Col md={4}>
								<FormGroup>
									<Label>
										<i class="far fa-address-book"></i>{' '}
										Communication Address
									</Label>
									<Input
										type="text"
										min="3"
										className="form-control mt-1"
										placeholder="e.g. : Apartment, studio, or floor"
										title="Address should contain atlist Three letters. e.g. City Name"
										value={commAddress}
										disabled={defaultDisabled}
										readOnly
									/>

								</FormGroup>
							</Col>

							<Col md={4}>
								<FormGroup>
									<Label>
										<ShortTextIcon /> City/Town/Village
									</Label>
									<Input
										type="text"
										min="3"
										className="form-control mt-1"
										placeholder="e.g. : Mumbai"
										title="City/Town/Village should contain atlist Three letters. e.g. City Name"
										value={
											adr_correspondence_city_town_village
										}
										disabled={defaultDisabled}
										readOnly
									/>
								</FormGroup>
							</Col>

							<Col md={4}>
								<FormGroup>
									<Label>
										<ShortTextIcon /> State
									</Label>
									<Input
										type="text"
										min="3"
										className="form-control mt-1"
										placeholder="e.g. : Maharashtra"
										title="State should contain only letters. e.g. GOA"
										value={adr_correspondence_state}
										disabled={defaultDisabled}
										readOnly
									/>
								</FormGroup>
							</Col>

							<Col md={4}>
								<FormGroup>
									<Label>
										<ShortTextIcon /> Country
									</Label>
									<Input
										type="text"
										min="3"
										className="form-control mt-1"
										placeholder="e.g. : India"
										title="Country should contain only letters. e.g. India"
										value={adr_correspondence_country}
										disabled={defaultDisabled}
										readOnly
									/>
								</FormGroup>
							</Col>

							<Col md={4}>
								<FormGroup>
									<Label>
										<RoomOutlinedIcon /> Communication Pin
										code
									</Label>
									<Input
										type="tel"
										minLength="4"
										maxLength="10"
										className="form-control mt-1"
										placeholder="e.g. : 425403"
										pattern="[0-9]{4,10}"
										title="Communication Pin-Code should only have Digites. e.g. 654532 And should have length of range 4-10 Digits"
										value={commPincode}
										disabled={defaultDisabled}
										readOnly
									/>
								</FormGroup>
							</Col>

							<Col md={4}>
								<FormGroup>
									<Label>
										<ShortTextIcon />
										Specify Communication Address Proof
									</Label>
									<Input
										type="text"
										name="specify_correspondence_address_proof"
										className="form-control"
										placeholder="e.g. : adhar"
										value={
											specify_correspondence_address_proof
										}
										disabled={defaultDisabled}
										readOnly
									/>
									{/* <p style={{color: 'red'}}>{errors.personal_email}</p>
            <p style={{color: 'red'}}>{touched.personal_email}</p>    */}
								</FormGroup>
							</Col>

							<Col md={4}>
								<FormGroup>
									<Label>
										<DescriptionOutlinedIcon />
										Address Proof
									</Label>
									<br />
									{Cust_Data.customer_profile
										.communication_address_proof_path ===
									'' ? (
										<p
											style={{
												marginLeft: '15px',
												color: 'red',
											}}
										>
											Not Uploaded
										</p>
									) : (
										<>
											<embed
												src={`${process.env.REACT_APP_URL_API}/${Cust_Data.customer_profile.communication_address_proof_path}`}
												width="170px"
												height="160"
											/>
											<Button
												className="btn btn-success mt-2 mb-1"
												onClick={
													downloadCommAddressProof
												}
											>
												<CloudDownloadIcon /> Download
											</Button>
										</>
									)}
								</FormGroup>
							</Col>

							<Col md={1}>
								<FormGroup>
									<Label className="required">
										<ShortTextIcon /> code
									</Label>
									<Input
										type="tel"
										name="mobileNumber"
										className="form-control"
										value={countyCode}
										disabled={defaultDisabled}
										required
										readOnly
									/>
								</FormGroup>
							</Col>

							<Col md={3}>
								<FormGroup>
									<Label className="required">
										<PhoneIphoneIcon /> Mobile Number
									</Label>
									<Input
										type="tel"
										name="mobileNumber"
										className="form-control"
										placeholder="e.g. : 7745021891"
										value={mobileNumber}
										disabled={defaultDisabled}
										required
										readOnly
									/>
									
								</FormGroup>
							</Col>

							<Col md={3}>
								<FormGroup>
									<Label className="required">
										<WhatsAppIcon /> WhatsApp Number
									</Label>
									<Input
										type="tel"
										name="whatsAppNum"
										maxLength="20"
										className="form-control"
										placeholder="e.g. : 7745021891"
										value={whatsAppNum}
										disabled={defaultDisabled}
										required
										readOnly
									/>
									
								</FormGroup>
							</Col>

							<Col md={2}>
								<FormGroup>
									<Label>
										<PhoneIphoneIcon /> Tel(Off).
									</Label>
									<Input
										type="tel"
										name="tel_off"
										className="form-control"
										placeholder="e.g. : +2309"
										value={tel_off}
										disabled={defaultDisabled}
										readOnly
									/>
								</FormGroup>
							</Col>

							<Col md={2}>
								<FormGroup>
									<Label>
										<PhoneIphoneIcon /> Tel(Res).
									</Label>
									<Input
										type="tel"
										name="tel_res"
										className="form-control"
										placeholder="e.g. : +2309"
										value={tel_res}
										disabled={defaultDisabled}
										readOnly
									/>
								</FormGroup>
							</Col>

							<Col md={2}>
								<FormGroup>
									<Label>
										<PhoneIphoneIcon /> Fax{' '}
									</Label>
									<Input
										type="text"
										name="fax"
										className="form-control"
										placeholder="e.g. : +2309"
										value={fax}
										disabled={defaultDisabled}
										readOnly
									/>
								</FormGroup>
							</Col>

							<Col md={3}>
								<FormGroup>
									<Label className="required">
										<EmailOutlinedIcon /> Email
									</Label>
									<Input
										type="email"
										className="form-control"
										placeholder="e.g. : akshay23@gmail.com"
										value={email}
										required
										readOnly
									/>
								</FormGroup>
							</Col>
							<Col md={3}>
								<FormGroup>
									<label className="form-label required">
										<VpnKeyIcon /> Email Password
									</label>
									<input
										type="password"
										name="password"
										min="6"
										max="10"
										value={emailPassword}
										className="form-control"
										placeholder="e.g. : Abc!@#"
										required
										readOnly
									/>
								</FormGroup>
							</Col>
							<Col md={4}>
								<FormGroup>
									<Label className="">
										<ShortTextIcon /> Designation
									</Label>
									<Input
										type="text"
										min="2"
										className="form-control"
										placeholder="e.g. : Doctor"
										pattern="[A-Za-z]{2,15}"
										title="Occupation should only contain letters. e.g. Doctor"
										value={designation}
										
										readOnly
									/>
								</FormGroup>
							</Col>
							<Col md={4}>
								<FormGroup>
									<Label className="">
										<ShortTextIcon /> Name Of Organization
									</Label>
									<Input
										type="text"
										min="2"
										className="form-control"
										placeholder="e.g. : Doctor"
										pattern="[A-Za-z]{2,15}"
										title="Occupation should only contain letters. e.g. Doctor"
										value={organization}
										
										readOnly
									/>
								</FormGroup>
							</Col>
							<Col md={4}>
								<FormGroup>
									<Label>
										<ShortTextIcon /> Mother’s maiden name
									</Label>
									<Input
										type="text"
										min="2"
										className="form-control mt-2"
										placeholder="e.g. : Akshay"
										pattern="[A-Za-z]{2,15}"
										title="FirstName should only contain letters. e.g. john"
										value={motherName}
										readOnly
									/>
								</FormGroup>
							</Col>
							<Col md={4}>
								<FormGroup>
									<Label>
										<EmailOutlinedIcon /> Personal Email
									</Label>
									<Input
										type="email"
										name="personal_email"
										className="form-control"
										placeholder="e.g. : akshay23@gmail.com"
										value={personal_email}
										disabled={defaultDisabled}
										readOnly
									/>
								</FormGroup>
							</Col>
							<Col md={4}>
								<FormGroup>
									<Label>
										<EmailOutlinedIcon /> Email & Whatsapp
										Verification Status
									</Label>
									<Input
										type="text"
										name="email_verification_status"
										className="form-control"
										placeholder="e.g. : Verified / Unverified"
										value={emailVerificationStatus}
										disabled={true}
									/>
								</FormGroup>
							</Col>
							<Col md={4}>
								<FormGroup>
									<Label>
										<EmailOutlinedIcon /> Whatsapp
										Verification Status
									</Label>
									<Input
										type="text"
										name="mobile_verification_status"
										className="form-control"
										placeholder="e.g. : Verified / Unverified"
										value={phoneVerificationStatus}

										disabled={true}
									/>
								</FormGroup>
							</Col>
							<Col md={12}>
								<br />
								<FormGroup
									className="shadow-none rounded"
									id="page-head"
								>
									<h5
										className="pl-1 pr-1 pt-1 pb-1"
										style={{
											width: '100%',
											textAlign: 'center',
											backgroundColor: '#eff6f9',
										}}
									>
										Additional Info
									</h5>
								</FormGroup>
							</Col>
							<Col md={3}>
								<FormGroup>
									<Label className="required">
										<ShortTextIcon /> Nationality
									</Label>
									<Input
										type="text"
										min="2"
										className="form-control"
										placeholder="e.g. : Indian"
										pattern="[A-Za-z]{2,15}"
										title="Nationality should only contain letters. e.g. Indian"
										value={nationality}
										required
										readOnly
									/>
								</FormGroup>
							</Col>
							<Col md={3}>
								<FormGroup>
									<Label className="required">
										<i class="fas fa-id-card"></i> PAN Card
										Number
									</Label>
									<Input
										type="text"
										max="10"
										className="form-control"
										placeholder="e.g. : ABCTY1234D"
										pattern="[A-Z0-9]{10,10}"
										title="Pan-Card number should contain Upparcase letters And Numbers. e.g. ABCTY1234D"
										value={pan}
										required
										readOnly
									/>
								</FormGroup>
							</Col>
							<Col md={3}>
								<FormGroup>
									<Label className="required">
										<i class="far fa-address-card"></i>{' '}
										Aadhar Card Number
									</Label>
									<Input
										type="tel"
										max="14"
										className="form-control"
										placeholder="e.g. : 1234/12345/12345"
										pattern="[0-9]{14,14}"
										title="Aadhar Number should only have Digites. e.g. 4468.. And should have length of 14 Digits"
										value={adhar}
										required
										readOnly
									/>
								</FormGroup>
							</Col>
							<Col md={3}>
								<FormGroup>
									<Label className="required">
										<ShortTextIcon /> Specify the proof of
										Identity submitted{' '}
									</Label>
									<Input
										type="text"
										name="proof_of_identity_submitted"
										className="form-control"
										placeholder="e.g. : adhar"
										value={proof_of_identity_submitted}
										disabled={defaultDisabled}
										required
										readOnly
									/>
								
								</FormGroup>
							</Col>

							<Col md={12}>
								<br />
								<FormGroup
									className="shadow-none rounded"
									id="page-head"
								>
									<h5
										className="pl-1 pr-1 pt-1 pb-1"
										style={{
											width: '100%',
											textAlign: 'center',
											backgroundColor: '#eff6f9',
										}}
									>
										Broker Details
									</h5>
								</FormGroup>
							</Col>

							{brokerDetails.map((broker, index) => (
								<>
									<Row md={12}>
										<Row className="ml-4 mr-1">
											<FormGroup>
												<Label className="required">
													<ShortTextIcon /> Broker
													Name
												</Label>
												<Input
													type="text"
													min="2"
													style={{ width: '300px' }}
													className="form-control"
													placeholder="e.g. : Akshay"
													pattern="[A-Za-z]{2,30}"
													title="Broker Name should only contain letters. e.g. john"
													value={
														broker.broker_name || ''
													}
													name="broker_name"
													id="broker_name"
													required
													readOnly
												/>
											</FormGroup>
										</Row>
										<Row className="ml-2 mr-1">
											<FormGroup>
												<Label className="required">
													<PhoneIphoneIcon /> Broker
													Code
												</Label>
												<Input
													type="tel"
													min="1"
													style={{ width: '300px' }}
													className="form-control"
													placeholder="e.g. : 774502"
													value={broker.broker_code}
													name="broker_code"
													required
													readOnly
												/>
											</FormGroup>
										</Row>

										<Row className="ml-4 mr-1">
											<FormGroup>
												<Label className="required">
													<PhoneIphoneIcon /> Broker
													Sub-Asset Class
												</Label>

												<Select
													name="brockerSubAssetClass"
													isMulti={true}
													value={
														broker.broker_sub_asset_class
													}
													isDisabled={true}
												/>
											</FormGroup>
										</Row>

										<Row className="ml-5 mt-5">
											<FormGroup>
												{brokerDetails.length !== 1 && (
													<Button
														readOnly
														onClick={() =>
															removeBroker(index)
														}
														className="btn btn-danger"
														disabled = {true}
													>
														-
													</Button>
												)}
											</FormGroup>
										</Row>
									</Row>

									<br />
									<Col md={6}>
										<FormGroup>
											{brokerDetails.length - 1 ===
												index && (
												<Button
													readOnly
													onClick={addBroker}
													className="btn btn-success"
													disabled = {true}
												>
													+
												</Button>
											)}
										</FormGroup>
									</Col>
								</>
							))}

							

							<Col md={12}>
								<br />
								<FormGroup
									className="shadow-none rounded"
									id="page-head"
								>
									<h5
										className="pl-1 pr-1 pt-1 pb-1"
										style={{
											width: '100%',
											textAlign: 'center',
											backgroundColor: '#eff6f9',
										}}
									>
										Bank Details
									</h5>
								</FormGroup>
								<FormGroup className="shadow-none rounded">
									<h5
										className="pl-1 pr-1 pt-1 pb-1"
										style={{ width: '100%' }}
									>
										Bank Account Details :
									</h5>
								</FormGroup>
							</Col>
							{bankDetails.map((bankdetNew, index) => (
								<>
									<Row md={12}>
										<Row className="ml-4 mr-1">
											<FormGroup>
												<Label className="required">
													<AccountBalanceIcon /> Bank
													Name
												</Label>
												<Input
													type="text"
													min="2"
													className="form-control"
													placeholder="e.g. : HDFC BANK"
													pattern="[A-Za-z]{2,30}"
													title="Bank-Name should only contain letters. e.g. HDFC BANK"
													value={bankdetNew.bank_name}
													name="bank_name"
													id="bank_name"
													required
													readOnly
												/>
											</FormGroup>
										</Row>
										<Row className="ml-4 mr-1">
											<FormGroup>
												<Label className="required">
													<ShortTextIcon /> Bank
													Account Number(Demat
													Account)
												</Label>
												<Input
													type="tel"
													min="12"
													className="form-control"
													placeholder="e.g. : 5623456789123456"
													pattern="[0-9]{12,18}"
													title="Mobile Number should only have Digites. e.g. 58786.. And should have length of 12 Digits"
													value={
														bankdetNew.bank_acc_no
													}
													name="bank_acc_no"
													id="bank_acc_no"
													required
													readOnly
												/>
											</FormGroup>
										</Row>
										<Row className="ml-4 mr-1">
											<FormGroup>
												<Label className="required">
													<ShortTextIcon /> IFSC Code
												</Label>
												<Input
													type="text"
													min="11"
													className="form-control"
													placeholder="e.g. : SBIN0001707"
													pattern="[A-Z0-9]{11,15}"
													title="IFSC Code should contain Upparcase letters And Numbers. e.g. ABC12.."
													value={bankdetNew.ifsc_code}
													name="ifsc_code"
													id="ifsc_code"
													required
													readOnly
												/>
											</FormGroup>
										</Row>
										<Row className="ml-4 mr-1">
											<FormGroup>
												<Label className="required">
													<ShortTextIcon /> MICR Code
												</Label>
												<Input
													type="text"
													min="11"
													className="form-control"
													placeholder="e.g. : SBIN0001707"
													pattern="[A-Z0-9]{11,15}"
													title="IFSC Code should contain Upparcase letters And Numbers. e.g. ABC12.."
													value={bankdetNew.micr_code}
													name="micr_code"
													id="micr_code"
													required
													readOnly
												/>
											</FormGroup>
										</Row>

										<Row className="ml-4 mr-1">
											<FormGroup>
												<Label className="required">
													<ShortTextIcon /> CRN /
													Customer Id{' '}
												</Label>
												<Input
													type="text"
													minLength="1"
													className="form-control"
													placeholder="e.g. : CRN-516567913"
													value={bankdetNew.crn}
													name="crn"
													id="crn"
													disabled={defaultDisabled}
													required
													readOnly
												/>
											</FormGroup>
										</Row>

										
									</Row>
								</>
							))}
							<Col md={12}>
								<FormGroup className="shadow-none rounded">
									<h5
										className="pl-1 pr-1 pt-1 pb-1"
										style={{ width: '100%' }}
									>
										Securities Account Details :
									</h5>
								</FormGroup>
							</Col>
							{dematAccount.map((dematNew, index) => (
								<>
									<Row md={12}>
										<Row className="ml-4 mr-1">
											<FormGroup>
												<Label className="required">
													<ShortTextIcon /> DP Id
												</Label>
												<Input
													type="text"
													min="2"
													className="form-control"
													placeholder="e.g. : AD35678"
													pattern="[A-Za-z]{2,30}"
													title="DP Id contains letters and numbers. e.g. AD35678"
													value={dematNew.dp_id}
													name="dp_id"
													id="dp_id"
													required
													readOnly
												/>
											</FormGroup>
										</Row>
										<Row className="ml-2 mr-1">
											<FormGroup>
												<Label className="required">
													<ShortTextIcon /> DP Name
												</Label>
												<Input
													type="text"
													min="12"
													className="form-control"
													placeholder="e.g. : DP Name"
													pattern="[A-Za-z]{2,30}"
													title="Bank-Name should only contain letters. e.g. Ramesh J. Rao"
													value={dematNew.dp_name}
													name="dp_name"
													id="dp_name"
													required
													readOnly
												/>
											</FormGroup>
										</Row>

										<Row className="ml-2">
											<FormGroup>
												<Label className="required">
													<ShortTextIcon /> Client
													Code
												</Label>
												<Input
													type="text"
													min="8"
													className="form-control"
													placeholder="e.g. : DEFN0001"
													pattern="[A-Z0-9]{11,15}"
													title="Client Code should contain Upparcase letters And Numbers. e.g. ABC12.."
													value={dematNew.client_code}
													name="client_code"
													id="client_code"
													required
													readOnly
												/>
											</FormGroup>
										</Row>

										<Row className="ml-4 mr-1">
											<FormGroup>
												<Label className="required">
													<ShortTextIcon /> User Id
												</Label>
												<Input
													type="text"
													maxLength="30"
													className="form-control"
													placeholder="e.g. : ABCD/1234/*#@"
													value={dematNew.user_id}
													name="user_id"
													id="user_id"
													required
													readOnly
												/>
											</FormGroup>
										</Row>
									</Row>
								</>
							))}
							<Col md={12}>
								<br />
								<FormGroup
									className="shadow-none rounded"
									id="page-head"
								>
									<h5
										className="pl-1 pr-1 pt-1 pb-1"
										style={{
											width: '100%',
											textAlign: 'center',
											backgroundColor: '#eff6f9',
										}}
									>
										Nominee Details
									</h5>
								</FormGroup>
							</Col>

							{nomineeDetails.map((nominee, index) => (
								<>
									<Row md={12}>
										<Row className="ml-4 mr-1">
											<FormGroup>
												<Label className="required">
													<ShortTextIcon /> Nominee
													Name
												</Label>
												<Input
													type="text"
													min="2"
													style={{
														width: '300px',
													}}
													className="form-control"
													placeholder="e.g. : Akshay"
													value={
														nominee.nominee_name ||
														''
													}
													name="nominee_name"
													id="nominee_name"
													disabled={defaultDisabled}
													required
													readOnly
												/>
											</FormGroup>
										</Row>

										<Row className="ml-2 mr-1">
											<FormGroup>
												<Label className="required">
													<PhoneIphoneIcon /> Nominee
													Mobile Number
												</Label>
												<Input
													type="tel"
													maxLength="10"
													style={{
														width: '300px',
													}}
													className="form-control"
													placeholder="e.g. : 7745021881"
													pattern="[0-9]{10,10}"
													title="Nominee Mobile Number should only have Digits. e.g. 9878654532 And should have length of Ten Digits"
													value={
														nominee.nominee_mobile_no
													}
													name="nominee_mobile_no"
													disabled={defaultDisabled}
													required
													readOnly
												/>
											</FormGroup>
										</Row>

										<Row className="ml-2">
											<FormGroup>
												<Label className="required">
													<ShortTextIcon />{' '}
													Relationship with Nominee
												</Label>
												<Input
													type="text"
													min="2"
													style={{
														width: '300px',
													}}
													className="form-control"
													placeholder="e.g. : Brother, Sister"
													value={
														nominee.nominee_relation
													}
													name="nominee_relation"
													disabled={defaultDisabled}
													required
													readOnly
												/>
											</FormGroup>
										</Row>

										<Row className="ml-4 mr-1">
											<FormGroup>
												<Label className="required">
													<EmailOutlinedIcon />{' '}
													Nominee Email
												</Label>
												<Input
													type="email"
													className="form-control"
													placeholder="e.g. : akshay23@gmail.com"
													name="nominee_email"
													value={
														nominee.nominee_email
													}
													disabled={defaultDisabled}
													required
													readOnly
												/>
											</FormGroup>
										</Row>
									</Row>
									<Col md={12}>
										<FormGroup>
											<Label>
												<DescriptionOutlinedIcon />
												Nominee Aadhaar / Pan
											</Label>
											<br />
											<a
												href={`${process.env.REACT_APP_URL_API}/${nominee.nomineeAAdharPanPath}`}
												target="_blank"
											>
												<img
													src={`${process.env.REACT_APP_URL_API}/${nominee.nomineeAAdharPanPath}`}
													className="thumbnail"
													id="thumbnailImage"
													height="100"
													width="100"
												/>
											</a>
											
										<br />
									<Button
									
										className="btn btn-success mt-2 mb-1"
										onClick={(e)=>{
											e.preventDefault();
											downloadNomineeAadhaar(nominee.nomineeAAdharPanPath)}
										}
									>
										<CloudDownloadIcon /> Download
									</Button>
										</FormGroup>
									</Col>

									<br />
								</>
							))}

							<Col md={12}>
								<br />
								<FormGroup
									className="shadow-none rounded"
									id="page-head"
								>
									<h5
										className="pl-1 pr-1 pt-1 pb-1"
										style={{
											width: '100%',
											textAlign: 'center',
											backgroundColor: '#eff6f9',
										}}
									>
										Source of Fund Flow
									</h5>
								</FormGroup>
							</Col>

							<Col md={12}>
								<FormGroup>
									<Label
										for="exampleCheckbox"
										className="required"
									>
										<ShortTextIcon /> Select Source
									</Label>
									<div>
										<MultiselectCheckbox
											options={enumSourceOfFundOption}
											disabled={defaultDisabled}
											readOnly
										/>
									</div>
								</FormGroup>
							</Col>

							<Col md={12}>
								<br />
								<FormGroup
									className="shadow-none rounded"
									id="page-head"
								>
									<h5
										className="pl-1 pr-1 pt-1 pb-1"
										style={{
											width: '100%',
											textAlign: 'center',
											backgroundColor: '#eff6f9',
										}}
									>
										Annual Income Range
									</h5>
								</FormGroup>
							</Col>
							<Col md={6}>
								<FormGroup
								>
									<Label className="required">
										<EqualizerOutlinedIcon /> Select Range
									</Label>
									<CustomInput
										type="select"
										id="IncomeId"
										value={incomeRang}
										required
										disabled
									>
										<option value="Less than 10 Lakhs">
											Less than 10 Lakhs
										</option>
										<option value="10 to 15 Lakhs">
											10 to 15 Lakhs
										</option>
										<option value="15 Lakhs and above">
											15 Lakhs and above
										</option>
									</CustomInput>
								</FormGroup>
							</Col>
							<Col md={12}>
								<br />
								<FormGroup
									className="shadow-none rounded"
									id="page-head"
								>
									<h5
										className="pl-1 pr-1 pt-1 pb-1"
										style={{
											width: '100%',
											textAlign: 'center',
											backgroundColor: '#eff6f9',
										}}
									>
										Other Details
									</h5>
								</FormGroup>
							</Col>
							<Col md={4}>
								<FormGroup>
									<Label>
										<ShortTextIcon /> Place of Birth
									</Label>
									<Input
										type="text"
										min="2"
										className="form-control"
										placeholder="e.g. : Delhi"
										pattern="[A-Za-z]{2,20}"
										title="Birth-Place should only contain letters. e.g. Delhi"
										value={birthPlace}
										readOnly
									/>
								</FormGroup>
							</Col>

							<Col md={4}>
								<FormGroup>
									<Label className="required">
										<ShortTextIcon />
										Whether Politically Exposed
									</Label>
									<CustomInput
										disabled
										type="select"
										value={weatherPoliticallyExp}
										required
									>
										<option value="Politically Exposed Person (PEP)">
											Politically Exposed Person (PEP)
										</option>
										<option value="Related to a Politically Exposed Person (PEP)">
											Related to a Politically Exposed
											Person (RPEP)
										</option>
										<option value="No">No</option>
									</CustomInput>
								</FormGroup>
							</Col>
							<Col md={4}>
								<FormGroup>
									<Label>
										<i class="fas fa-user-graduate"></i>{' '}
										Highest Education
									</Label>
									<Input
										type="text"
										min="2"
										className="form-control"
										placeholder="e.g. : Bachelore's, Master's"
										pattern="[A-Za-z]{2,20}"
										title="Education Details should Have a letters. e.g. Bachelore's etc etc."
										value={highestEdu}
										readOnly
									/>
								</FormGroup>
							</Col>
							<Col md={4}>
								<FormGroup>
									<Label className="required">
										<ShortTextIcon />
										Marital Status
									</Label>
									<CustomInput
										type="select"
										value={marriageStatus}
										required
										disabled
									>
										<option
											value="Single"
											name={marriageStatus}
										>
											Single
										</option>
										<option
											value="Married"
											name={marriageStatus}
										>
											Married
										</option>
									</CustomInput>
								</FormGroup>
							</Col>
							{marriageStatus === 'Married' && (
								<Col md={4}>
									<FormGroup>
										<Label>
											<i class="far fa-calendar-plus"></i>{' '}
											Date of Marriage
										</Label>
										<Input
											type="date"
											className="form-control"
											value={dateOfMariage}
											readOnly
										/>
									</FormGroup>
								</Col>
							)}

							<Col md={4}>
								<FormGroup>
									<Label>
										<ShortTextIcon /> Risk Profile Score
									</Label>
									<Input
										type="text"
										min="2"
										className="form-control"
										value={score}
										readOnly
									/>
								</FormGroup>
							</Col>
							<Col md={4}>
								<FormGroup>
									<Label>
										<ShortTextIcon /> Customer Status
									</Label>
									<Input
										type="text"
										min="2"
										className="form-control"
										value={riskProfStatus}
										readOnly
									/>
								</FormGroup>
							</Col>

							<Col md={12}>
								<br />
								<FormGroup
									className="shadow-none rounded"
									id="page-head"
								>
									<h5
										className="pl-1 pr-1 pt-1 pb-1"
										style={{
											width: '100%',
											textAlign: 'center',
											backgroundColor: '#eff6f9',
										}}
									>
										Documents
									</h5>
								</FormGroup>
							</Col>

							<Col md={3}>
								<br />
								<FormGroup>
									<Label className="required">
										<DescriptionOutlinedIcon />
										Passport Size Photo
									</Label>
									<br />
									<embed
										src={`${process.env.REACT_APP_URL_API}/${passPortPhoto}`}
										width="170px"
										height="160"
									/>
									<br />
									<Button
										className="btn btn-success mt-2 mb-1"
										onClick={downloadPassportPhoto}
									>
										<CloudDownloadIcon /> Download
									</Button>
								</FormGroup>
							</Col>

							<Col md={3}>
								<br />
								<FormGroup>
									<Label className="required">
										<DescriptionOutlinedIcon />
										Pan Card
									</Label>
									<br />
									<embed
										src={`${process.env.REACT_APP_URL_API}/${panFile}`}
										width="170px"
										height="160"
									/>
									<br />
									<Button
										className="btn btn-success mt-2 mb-1"
										onClick={downloadPanCard}
									>
										<CloudDownloadIcon /> Download
									</Button>
								</FormGroup>
							</Col>

							<Col md={3}>
								<br />
								<FormGroup>
									<Label className="required">
										<DescriptionOutlinedIcon />
										Aadhar Card (Front and Back)
									</Label>
									<br />
									<embed
										src={`${process.env.REACT_APP_URL_API}/${adharFile}`}
										width="170px"
										height="160"
									/>
									<br />
									<Button
										className="btn btn-success mt-2 mb-1"
										onClick={downloadAdharCard}
									>
										<CloudDownloadIcon /> Download
									</Button>
								</FormGroup>
							</Col>

							<Col md={3}>
								<br />
								<FormGroup>
									<Label className="required">
										<DescriptionOutlinedIcon />
										Cancelled Cheque of Provided Account
									</Label>
									<br />
									<embed
										src={`${process.env.REACT_APP_URL_API}/${provAccountCanCheck}`}
										width="170px"
										height="160"
									/>
									<br />
									<Button
										className="btn btn-success mt-2 mb-1"
										onClick={downloadCancelletionCheck}
									>
										<CloudDownloadIcon /> Download
									</Button>
								</FormGroup>
							</Col>

							<Col md={3}>
								<br />
								<FormGroup>
									<Label className="required">
										<DescriptionOutlinedIcon />
										CML - Client Master List
									</Label>
									<br />
									<embed
										src={`${process.env.REACT_APP_URL_API}/${someFileCanCheck}`}
										width="170px"
										height="160"
									/>
									<br />
									<Button
										className="btn btn-success mt-2 mb-1"
										onClick={downloadCancelletionCheckTwo}
									>
										<CloudDownloadIcon /> Download
									</Button>
								</FormGroup>
							</Col>

							<Col md={3}>
								<br />
								<FormGroup>
									<Label className="required">
										<DescriptionOutlinedIcon />
										Cancelled Cheque of Provided Account Two
									</Label>
									<br />
									<embed
										src={`${process.env.REACT_APP_URL_API}/${provAccountTwoCanCheck}`}
										width="170px"
										height="160"
									/>
									<br />
									<Button
										className="btn btn-success mt-2 mb-1"
										onClick={downloadCancelletionCheckTwo}
									>
										<CloudDownloadIcon /> Download
									</Button>
								</FormGroup>
							</Col>
						</Row>
					</Form>
					<br />
				</div>
			</div>
		</>
	);
};

export default ViewCustomer;
