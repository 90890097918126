import { useState, useEffect } from "react";
import ApiServices from "../../ApiService/ApiConfig";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import AccordianComponent from "./../Layout/Table-layout/Accordian";

import { Formik, Form } from "formik";

const CustomerLandingReportPage1 = (props) => {
  const [reportInfo, setReportInfo] = useState("");
  const [modalData, setModalData] = useState("");
  const [reportStatus, setReportStatus] = useState("");
  const [custData, setCustData] = useState([]);
  const [securitiesNotAvailable, setSecuritiesNotAvailable] = useState([]);
  const [isExpand, setIsExpand] = useState(false);

  let {
    name: model_name,
    trade_advice_code: trade_advice_code,
    // resetToken,
    // resetValidity,
    // trade_advice_code
  } = useParams();

  // resetValidity = resetValidity.replace(/_/g,' ')
  // trade_advice_code = trade_advice_code.replace(/_/g,' ')

  var newRes;

  useEffect(() => {
    LandingPageData();
  }, []);

  const LandingPageData = () => {
    ApiServices.verifyReportData(model_name)
      .then((res) => {
        console.log("response:: 40", res);
        const modeldatasecu = res.filter(function (dt) {
          return dt.User_Action !== "No" || dt.Actionable === "Yes";
        });
        let modeldatasecuNew = modeldatasecu.map((data) => ({
          model_name: data["model_name"],
          Name_of_Securities: data["Name_of_Securities"],
          Suggested_Trade:
            data["Suggested_Trade"] !== "-"
              ? toIndianCurrency(data["Suggested_Trade"])
              : data["Suggested_Trade"],
          Buy_Sell: data["Buy_Sell"] !== null ? data["Buy_Sell"] : "-",
          Price: data["Price"],
          Actionable: data["Actionable"],
          User_Action:
            data["User_Action"] !== null
              ? data["User_Action"]
              : data["Actionable"],
        }));
        setReportInfo(modeldatasecuNew);
        getDelta();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    LandingPageDataCust();
  }, []);

  const LandingPageDataCust = () => {
    ApiServices.verifyReportDataCustomerSecuritoes(model_name)
      .then((res) => {
        const dataAaray = res.filter((el) => {
          return el.user.customer_status === "Active";
        });
        console.log("dataary---------------------------", dataAaray);
        setCustData(dataAaray);
        dataAaray.forEach((element) => {
          if (dataAaray.length) {
            if (element.customer_portfolio_securities.length === 0) {
              setSecuritiesNotAvailable([...securitiesNotAvailable, "Yes"]);
            }
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDelta = () => {
    const delta = localStorage.getItem("responseData");
  };

  useEffect(() => {
    modalPrevData();
  }, []);

  const modalPrevData = () => {
    ApiServices.getModel(model_name)
      .then((res) => {
        console.log("modalPrevData---", res);
        newRes = res;
        setModalData(newRes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toIndianCurrency = (num) => {
    if (num !== undefined) {
      const curr = Number(num).toLocaleString("en-IN", {
        currency: "INR",
      });
      return curr;
    }
  };

  const columns = [
    {
      headerName: "Name of Securities",
      field: "Name_of_Securities",
    },

    {
      headerName: "Suggested Trade Qty",
      field: "Suggested_Trade",
    },

    {
      headerName: "Buy Sell",
      field: "Buy_Sell",
      cellStyle: (params) => {
        if (params.value === "Buy") return { color: "#FF9F1C" };
        if (params.value === "Sell") return { color: "green" };
      },
    },

    {
      headerName: "Price",
      field: "Price",
    },

    {
      headerName: "System Suggested Action",
      field: "Actionable",
    },

    {
      headerName: "User Action",
      field: "User_Action",
    },
  ];

  const custColumns = [
    {
      headerName: "Name of Securities",
      field: "Name_of_Securities",
    },

    {
      headerName: "Suggested Trade Qty",
      field: "Suggested_Trade",
    },

    {
      headerName: "Buy Sell",
      field: "Buy_Sell",
      cellStyle: (params) => {
        if (params.value === "Buy") return { color: "#FF9F1C" };
        if (params.value === "Sell") return { color: "green" };
      },
    },

    {
      headerName: "Price",
      field: "Price",
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  let today = new Date().toISOString().slice(0, 10);

  function changeDateFormat(today) {
    var splitDate = today.split("-");
    if (splitDate.count == 0) {
      return null;
    }

    var year = splitDate[0];
    var month = splitDate[1];
    var day = splitDate[2];

    return day + "\\" + month + "\\" + year;
  }

  var newDate = changeDateFormat(today);

  const SubmitHandler = async () => {
    // console.log(
    //   "payload",
    //   {
    //     status: reportStatus,
    //     resetToken: modalData.resetToken,
    //     reason: "no reason",
    //     trade_advice_code: trade_advice_code,
    //   },
    //   "model",
    //   modalData
    // );
    const destUrl = `${process.env.REACT_APP_URL_API}/model/updateRecord/${model_name}`;
    await axios({
      method: "post",
      url: destUrl,
      data: {
        status: reportStatus,
        resetToken: modalData.resetToken,
        reason: "no reason",
        trade_advice_code: trade_advice_code,
      },
    })
      .then(() => {
        toast.success(`Response Recorded Successfully`);
        props.history.push("/thank");
      })
      .catch((err) => {
        toast.warning(err.response.data);
      });
  };

  return (
    <div className="container" style={{ height: "600px", marginTop: "100px" }}>
      <div
        className="container-fluid text-center"
        style={{
          width: "85%",
          marginTop: 100,
          backgroundColor: "#eff6f9",
        }}
      >
        <h3 className="pt-3 pb-3">Model Report</h3>
      </div>

      <div
        className="ag-theme-alpine"
        style={{
          height: "200px",
          width: "900px",
          marginTop: "30px",
          marginLeft: "10%",
        }}
      >
        <p>Trade ID: {newDate}</p>
        <p
          style={{
            display: "inline-block",
          }}
        >
          <span
            style={{
              padding: "4px 6px",
              textTransform: "capitalize",
              border: "2px solid #4895EF",
            }}
          >
            model name
          </span>
          <span
            style={{
              background: "#4895EF",
              border: "2px solid #4895EF",
              color: "white",
              padding: "4px 6px",
              fontWeight: "bold",
            }}
          >
            {model_name}
          </span>
        </p>
        <AgGridReact
          rowData={reportInfo}
          columnDefs={columns}
          defaultColDef={defaultColDef}
        />
        <br />

        {custData.length ? (
          <button
            id="dvwm-accordian-toggle"
            style={{ marginBottom: "1.5rem" }}
            onClick={() => setIsExpand((prev) => !prev)}
          >
            {isExpand ? "Collapse All" : "Expand All"}
          </button>
        ) : null}

        {custData.map((el, index) => {
          let { portfolio_name } = el;
          let { first_name, middle_name, last_name } = el.user;
          const datatable = el.customer_portfolio_securities.filter(function (
            dt
          ) {
            return (
              dt.User_Action !== "No" &&
              dt.Buy_Sell !== "-" &&
              dt.Suggested_Trade !== "0"
              // dt.User_Action !== 'No' ||
              // dt.Buy_Sell === 'Yes' ||
              // dt.Buy_Sell === 'No'
            );
          });
          let datatableNew = datatable.map((data) => ({
            portfolio_name: data["portfolio_name"],
            Name_of_Securities: data["Name_of_Securities"],
            No_of_Shares:
              data["No_of_Shares"] === 0
                ? 0
                : toIndianCurrency(data["No_of_Shares"]),
            Suggested_Trade:
              data["Suggested_Trade"] === "-"
                ? 0
                : toIndianCurrency(data["Suggested_Trade"]),
            User_Action: data["User_Action"],
            Buy_Sell: data["Buy_Sell"],
            Price: data["Price"],
          }));
          return (
            <div key={index}>
              {datatable && (
                <AccordianComponent
                  key={index}
                  title={
                    <b>
                      {`${first_name} ${middle_name} ${last_name}`}
                      <span
                        style={{
                          marginLeft: "12px",
                          color: "gray",
                        }}
                      >{`(${portfolio_name})`}</span>
                    </b>
                  }
                  isExpand={isExpand}
                  content={
                    <div
                      style={{
                        height: "200px",
                        width: "71%",
                        margin: "auto",
                      }}
                    >
                      <AgGridReact
                        rowData={datatableNew}
                        columnDefs={custColumns}
                        defaultColDef={defaultColDef}
                      />
                    </div>
                  }
                ></AccordianComponent>
              )}
              <br />
            </div>
          );
        })}
        <br />

        <Formik
          initialValues={{
            acceptTerms: false,
          }}
          onSubmit={SubmitHandler}
        >
          {() => (
            <div>
              <Form>
                <div className="form-group" style={{ height: 100 }}>
                  {custData.length > 0 &&
                    custData.length !== securitiesNotAvailable.length && (
                      <center>
                        <button
                          id="firstInput"
                          type="submit"
                          className="btn btn-success mb-3 mr-2"
                          onClick={() => setReportStatus("Accepted")}
                        >
                          Accept
                        </button>
                        <button
                          id="secondInput"
                          type="submit"
                          className="btn btn-danger mb-3 mr-2"
                          onClick={() => setReportStatus("Rejected")}
                        >
                          Reject
                        </button>
                      </center>
                    )}
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CustomerLandingReportPage1;
