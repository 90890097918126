import React, { useEffect, useState } from 'react';

//import UserService from "../services/user.service";
import ApiServices from '../../ApiService/ApiConfig';
import ModelListTable from '../Layout/Table-layout/ModelListTable';
//import tableData from './json-data/customerTableData.json';
import { COLUMNS } from '../Layout/Table-layout/Columns/columns-modal';
import '../UserCustRedressalStyle.css';
import * as moment from 'moment';
import { toast } from 'react-toastify';

const ModalList = (props) => {
	console.log('props-history', props.history);
	const [tableData, settableData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [del, setDel] = useState();

	useEffect(() => {
		modelData();
		console.log('list model');
	}, [del]);

	const modelData = () => {
		ApiServices.listModel()
			.then((res) => {
				console.log(res);
				setIsLoading(false);
				let modified = res.map((element) => {
					var momentDate = moment(element['date']);
					return {
					...element,
					date: momentDate.format('MM-DD-YYYY hh:mm:ss A '),
					};
					});
				   
					settableData(modified);
			})
			.catch((err) => {
				setIsLoading(false);
				console.log(err);
			});
	};

	if (!tableData) return null;

	const onDelete = (data) => {
		console.log(props.rowId);

		ApiServices.deleteModel(data)
			.then((res) => {
				console.log(res, 'history', props.history);
				setIsLoading(false);
				props.history.push('/modport');
				setDel(Math.random());
				toast.success(`${res.message}`);
			})
			.catch((err) => {
				setIsLoading(false);
				console.log(err);
			});
	};

	return (
		<div id="style_main">
			<center>
				{/* <span>
 <button className="btn btn-success" >Add Customer +</button>
 <span> </span>
 <button className="btn btn-success" >Update Customer </button>
 </span> */}
			</center>
			<div
				className="shadow-none mb-3 rounded"
				style={{ textAlign: 'center' }}
			>
				<h3
					style={{
						marginTop: '110px',
						width: '100%',
						backgroundColor: '#f5f3f3',
						fontFamily: 'sans-serif',
					}}
					className="pt-2 pb-2"
				>
					Model Portfolio List
				</h3>
			</div>
			<ModelListTable
				tableData={tableData}
				COLUMNS={COLUMNS}
				type="Model"
				urlAdd="/addmodel"
				urlActionEdit="/updatemodel/"
				urlActionView="/viewmodel/"
				onDelete={onDelete}
				isLoading={isLoading}
			/>
		</div>
	);
};

export default ModalList;
