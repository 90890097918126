import { useEffect, useState } from 'react';
import { Breadcrumb, BreadcrumbItem, CustomInput } from 'reactstrap';
import '../form.css';
import XLSX from 'xlsx';
// material-ui icons
import ShortTextIcon from '@material-ui/icons/ShortText';

import MoneyIcon from '@material-ui/icons/Money';

import Form from 'react-bootstrap/Form';

import ModelTable from '../Layout/Table-layout/ModelTable';
import ApiServices from '../../ApiService/ApiConfig';
import { columns } from '../Layout/Table-layout/Columns/columns-model';
import { useUserInfo } from '../../ContextProviders/UserInfo';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 700,
	height: 500,
	bgcolor: 'background.paper',
	border: '1px solid #096dd9',
	boxShadow: 24,
	p: 4,
};

const AddCustPortfolio = (props) => {
	const userInfo = useUserInfo();
	const [tableData, settableData] = useState([]);
	const [modelDropdownData, setModelDropdownData] = useState([]);
	const [customersList, setCustomersList] = useState([]);
	const [filteredCustList, setFilteredCustList] = useState([]);
	const [name, setName] = useState('');
	const [customerName, setCustomerName] = useState('');
	const [custId, setCustId] = useState('');
	const [brokerName, setBrokerName] = useState('');
	const [type, setType] = useState('');
	const [thresholdLowerLimit, setThresholdLowerLimit] = useState('');
	const [thresholdUpperLimit, setThresholdUpperLimit] = useState('');
	const [inceptionDate, setInceptionDate] = useState('');
	const [inceptionValue, setInceptionValue] = useState('');
	const [totalCurrentValue, setTotalCurrentValue] = useState();

	const [disable, setDisable] = useState(true);

	const [model, setModel] = useState([]);
    const [aryofcust_identities, setAryofCust_identities] = useState([]);
	const [modelFileContents, setModelFileContents] = useState();
	const [columnsNew, setColumnsNew] = useState([]);
	const [showHide, setShowHide] = useState(false);
	const [disabledAddSecurities, setDisabledAddSecurities] = useState(true);
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(false);
	const handleClose = () => {
		setOpen(false);
		setShowHide(false);
		if (name !== '' && customerName !== '') {
			setDisabledAddSecurities(false);
		}
	};

	useEffect(() => {
		ApiServices.listStatusVerifiedModel().then((res) => {
			setModelDropdownData(res);
		});
		ApiServices.listCustomer().then((res) => {
			const filtered = res.filter((element) => {
				return element.customer_profile.status === 'Completed';
			});
			setCustomersList(filtered);
		});
	}, []);

	const getPortfolioName = (customer, model) => {
		if (model !== '' && customer !== '') {
			let splitted = customer.split('$');
			let partOne = model.match(/[a-z]|[A-Z]|[0-9]/g).join('');
			let partTwo = '';
			splitted[0].split(' ').forEach((el) => {
				partTwo += el.charAt(0);
			});
			setName(partOne + '-' + partTwo + '-' + splitted[1]);
			let date = new Date().getDate().toString();
			let month = (new Date().getMonth() + 1).toString();
			if (month.length === 1) {
				month = '0' + month;
			}
			let year = new Date().getFullYear().toString();

			let modifiedDate = year + '-' + month + '-' + date;
			console.log('...', modifiedDate);
			setInceptionDate(modifiedDate);
		} else {
			setName('');
		}
	};

	const onChangeModelNameHandler = async (e) => {
		setType(e.target.value);
		setCustomerName('');
		setName('');
		if (!e.target.value) {
			setFilteredCustList([]);
			return;
		}
		let { customer_portfolio } = await ApiServices.getModel(e.target.value);
		let cust_identities = customer_portfolio.map((el) => {
			return el;
		});
		const ayr = cust_identities.map((el) => {
			return el.cust_id
		})

		setAryofCust_identities(cust_identities)
		let filteredCustList = customersList.filter((customer) => {
			return customer.cust_email_verified_status === 'Verified'
		});
        
		const checkListAry = filteredCustList.filter((el) => {
			return !ayr.includes(el.id)
		})

		setFilteredCustList(checkListAry);
		if (e.target.value !== '') {
			setOpen(false);
		}
		setShowHide(false);
	};

	const onChangeCustomerHandler = (e) => {
		
		setCustomerName(e.target.value);
		getPortfolioName(e.target.value, type);
		if (e.target.value !== '') {
			let filteredCustomerData = customersList.filter((ele) => {
				let splitted = e.target.value.split('$')[1];
				let nameData = e.target.value.split('$')[0];
				let clientname = ele.first_name + ' ' + ele.middle_name + ' ' + ele.last_name
				return ele.customer_profile.pan_no === splitted && clientname === nameData;
			});
			const newbro =
				filteredCustomerData[0].customer_profile.broker_details.map(
					(bro) => {
						return bro.broker_name;
					}
				);
			setBrokerName(newbro);
			setCustId(filteredCustomerData[0].id);
		}
	};

	let apiData = [];

	if (name !== '') {
		let obj = {
			portfolio_name: 'only fill name of securities',
			name: 'only fill no of shares of customer',
		};
		apiData.push(obj);
	}

	const calculateTableRow = (id, property) => {
		let g = tableData[id];
		g[property] = tableData[id]['cmp'] * tableData[id]['numberOfShares'];

		settableData([
			...tableData.slice(0, id),
			g,
			...tableData.slice(id + 1),
		]);
	};

	const updateTableRow = (id, property, value) => {
		let g = tableData[id];
		g[property] = value;

		settableData([
			...tableData.slice(0, id),
			g,
			...tableData.slice(id + 1),
		]);
	};

	const processModel = (tableData) => {
		let model = [];

		let total = null;

		if (showHide === false) {
			let filteredRecord = modelDropdownData.filter(
				(ele) => ele.name === type
			);

			const arrById = new Map(
				tableData.map((it) => [it['Name of Securities'], it])
			);
			const arrNew = filteredRecord[0].model_securities.map(
				(element, index) => {
					const arrB = arrById.get(element['Name of Securities']);
					return {
						...element,
						'No of Shares': arrB[name],
					};
				}
			);
			arrNew.forEach((element, index) => {
				let obj = {};

				obj['ISIN Number'] = element['ISIN Number'];

				obj['Name of Securities'] = element['Name of Securities'];

				obj['Asset Class'] = element['Asset Class'];

				obj['Sub Asset Class'] = element['Sub Asset Class'];

				obj['Marketcap'] = element['Marketcap'];

				obj['CMP'] = element['CMP'];

				obj['Prescribed Weight'] = Number(
					element['Prescribed Weight']
				).toFixed(3);

				obj['No of Shares'] = element['No of Shares'];

				obj['Current Value'] = obj['No of Shares'] * obj['CMP'];
				total += obj['Current Value'];

				model.push(obj);
			});
			setTotalCurrentValue(Number(total.toFixed(2)));
			if (inceptionValue != Number(total.toFixed(2))) {
				toast.warning(
					`Inception Value and Total Current Value should be same`
				);
			} else {
				setDisable(false);
			}
		} else {
			tableData.forEach((element, index) => {
				let obj = {};

				obj['ISIN Number'] = element['ISIN Number'];

				obj['Name of Securities'] = element['Name of Securities'];

				obj['Asset Class'] = element['Asset Class'];

				obj['Sub Asset Class'] = element['Sub Asset Class'];

				obj['Marketcap'] = element['Marketcap'];

				obj['CMP'] = element['CMP'];

				obj['Prescribed Weight'] = Number(
					element['Prescribed Weight']
				).toFixed(3);

				obj['No of Shares'] = element['No of Shares'];

				obj['Current Value'] = obj['No of Shares'] * obj['CMP'];
				total += obj['Current Value'];

				model.push(obj);
			});
		}

		model.map((element) => {
			if (!element['Name of Securities'].toLowerCase().includes('cash')) {
				element['Current Weight'] = Number(
					((element['Current Value'] / total) * 100).toFixed(3)
				);
				element['Delta'] = Number(
					(
						(element['Current Weight'] /
							element['Prescribed Weight']) *
						100
					).toFixed(3)
				);
				if (
					element['Delta'] < thresholdLowerLimit ||
					element['Delta'] > thresholdUpperLimit
				) {
					element['Actionable'] = 'Yes';

					element['Suggested Trade'] = String(
						Math.round(
							Math.abs(
								(element['Current Weight'] -
									element['Prescribed Weight']) *
									total
							) /
								element['CMP'] /
								100
						)
					);
					if (element['Delta'] < thresholdLowerLimit)
						element['Suggested Trade'] += '(buy)';
					if (element['Delta'] > thresholdUpperLimit)
						element['Suggested Trade'] += '(sell)';
				} else {
					element['Actionable'] = 'No';
					element['Suggested Trade'] = '-';
				}
			}
		});
		setModel(model);
		return model;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		let jsonRequestBody = {};
		let filteredRecord = modelDropdownData.filter(
			(ele) => ele.name === type
		);

		if (showHide === true) {
			filteredRecord.map((ele) => {
				jsonRequestBody['name'] = ele.name;
				jsonRequestBody['type'] = ele.type;
				jsonRequestBody['threshold_lower_limit'] =
					ele.threshold_lower_limit;
				jsonRequestBody['threshold_upper_limit'] =
					ele.threshold_upper_limit;
				jsonRequestBody['date'] = ele.date;
				jsonRequestBody['inception_value'] = ele.inception_value;
			});
			jsonRequestBody['createdBy'] =
				userInfo.first_name +
				' ' +
				userInfo.middle_name +
				' ' +
				userInfo.last_name +
				'' +
				'(' +
				userInfo.id +
				')';
		} else {
			jsonRequestBody['name'] = name;
			jsonRequestBody['type'] = type;
			jsonRequestBody['threshold_lower_limit'] = thresholdLowerLimit;
			jsonRequestBody['threshold_upper_limit'] = thresholdUpperLimit;
			jsonRequestBody['date'] = inceptionDate;
			jsonRequestBody['inception_value'] = parseFloat(inceptionValue);
			jsonRequestBody['cust_id'] = custId;
			jsonRequestBody['createdBy'] =
				userInfo.first_name +
				' ' +
				userInfo.middle_name +
				' ' +
				userInfo.last_name +
				'' +
				'(' +
				userInfo.id +
				')';
		}

		jsonRequestBody['model'] = model;
		console.log('jsonRequestBody', jsonRequestBody);

		if (showHide === true) {
			ApiServices.updateModel(jsonRequestBody, jsonRequestBody.name)
				.then((res) => {
					console.log(res.data);
					handleClose();
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			ApiServices.createCustomerPortfolio(jsonRequestBody)
				.then((res) => {
					console.log(res.data);
					if (res.code === 'P2002') {
						toast.warning(res.message);
					} else {
						toast.success(`${res.message}`);
						props.history.push('/customerportfolio');
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
		setShowHide(false);
		if (name !== '' && customerName !== '') {
			setDisabledAddSecurities(false);
		}
	};

	const createTableColumns = (data) => {
		if (showHide === false) {
			setColumnsNew([]);
		}
		let keys = Object.keys(data[0]);

		Object.entries(data[0]).forEach((el) => {
			let obj = {};
			obj['Header'] = el[0];
			obj['accessor'] = el[0];
			if (showHide === false) {
				if (el[0] === name || el[0] === 'Name of Securities') {
					setColumnsNew((columnsNew) => [...columnsNew, obj]);
				}
			}
		});
	};

	const createTableData = (data) => {
		console.log('daaata', data);
		if (showHide === true) {
			let tableDataLocal = [];

			Object.entries(data).forEach((el) => {
				tableDataLocal.push(el[1]);
			});

			let model = processModel(tableDataLocal);

			setModel(model);
			settableData([]);
			model.forEach((element) => {
				settableData((tableData) => [...tableData, element]);
			});
		} else {
			settableData([]);
			let tableDataLocal = [];
			let keys = Object.keys(data[0]);
			Object.entries(data).forEach((el) => {
				settableData((tableData) => [...tableData, el[1]]);
				tableDataLocal.push(el[1]);
			});
			let matchedOrNot = [];
			keys.map((keyName) => {
				if (keyName === name) {
					processModel(tableDataLocal);
					matchedOrNot.push('Matched');
				} else {
					matchedOrNot.push('Not Matched');
				}
			});
			const matched = matchedOrNot.filter((item) => item === 'Matched');
			if (!matched.length) {
				toast.warning(
					`Portfolio name ${name} is not available in excel sheet`
				);
			}
		}
	};

	const fileUpload = (file) => {
		const fileReader = new FileReader();
		fileReader.readAsArrayBuffer(file);

		fileReader.onload = (e) => {
			const bufferArray = e.target.result;

			const wb = XLSX.read(bufferArray, { type: 'buffer' });

			const wsname = wb.SheetNames[0];

			const ws = wb.Sheets[wsname];

			const data = XLSX.utils.sheet_to_json(ws);

			console.log('data', data);
			if (showHide === false) {
				createTableColumns(data);
				createTableData(data);
			} else {
				createTableData(data);
			}
		};
	};

	const PopupWindow = () => {
		return (
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<div className="container">
						<h4>First you need to update latest model details</h4>
						<div className="row">
							<div className="col-sm-6">
								<label className="form-label">
									<MoneyIcon /> Update Securities +
								</label>
								<Form.Group controlId="modelFileContents">
									<Form.Control
										type="file"
										name="modelFileContents"
										value={modelFileContents}
										onChange={(e) => {
											const file = e.target.files[0];
											fileUpload(file);
										}}
									/>
								</Form.Group>
							</div>
						</div>

						{showHide && (
							<div
								style={{ height: '300px', overflow: 'scroll' }}
							>
								<ModelTable
									type="modelTableInCustPortfolio"
									tableData={tableData}
									COLUMNS={columns}
									calculateTableRow={calculateTableRow}
									updateTableRow={updateTableRow}
								/>
							</div>
						)}
					</div>
					<center>
						<Button onClick={handleSubmit}>Apply</Button>
						<Button onClick={handleClose}>Skip</Button>
					</center>
				</Box>
			</Modal>
		);
	};

	const addModelForm = () => (
		<form onSubmit={handleSubmit}>
			<div className="row">
				<div className="col-sm-3">
					<label className="form-label required">
						<ShortTextIcon /> Portfolio Name
					</label>

					<Form.Group controlId="threshold">
						<Form.Control
							type="text"
							className="form-control"
							placeholder="e.g. : Portfolio name"
							value={name}
							required
							readOnly
						/>
					</Form.Group>
				</div>

				<div className="col-sm-3">
				<label className="form-label required">
						<ShortTextIcon /> Select Model
					</label>

					<Form.Group>
						<CustomInput
							type="select"
							required
							onChange={onChangeModelNameHandler}
							value={type}
						>
							<option value="">Select Option</option>
							{modelDropdownData.map((ele) => (
								<option value={ele.name}>{ele.name}</option>
							))}
						</CustomInput>
					</Form.Group>
				</div>

				{handleOpen && <div>{PopupWindow()}</div>}
			</div>

			<div className="row">
				<div className="col-sm-3">
					<label className="form-label required">
						<ShortTextIcon /> Select Customer
					</label>

					<Form.Group>
						<CustomInput
							type="select"
							style={{ width: 525 }}
							required
							onChange={onChangeCustomerHandler}
							value={customerName}
						>
							<option value="">Select Option</option>
							{filteredCustList.map((ele) => (
								<option
									value={
										ele.first_name +
										' ' +
										ele.middle_name +
										' ' +
										ele.last_name +
										'$' +
										ele.customer_profile.pan_no
									}
								>
									{ele.first_name +
										' ' +
										ele.middle_name +
										' ' +
										ele.last_name}
									&nbsp;&nbsp;&nbsp;
									{`( PAN: ${ele.customer_profile.pan_no} )`}
								</option>
							))}
						</CustomInput>
					</Form.Group>
				</div>
			</div>
		</form>
	);

	return (
		<>
			<div className="container" id="adjust-top">
				<Breadcrumb>
					<BreadcrumbItem>
						<Link to={{pathname: `/customerportfolio`}}>Customer Portfolio</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>
						Add Customer Portfolio
					</BreadcrumbItem>
				</Breadcrumb>

				<div className="container">
					<div className="row">
						<div className="col-md-12 offset-md-3">
							{addModelForm()}
						</div>
					</div>
					<div>
						<span>
							{
								<button
									className="btn btn-outline-success"
									id="btn-success"
									onClick={handleSubmit}
								>
									Create Portfolio
								</button>
							}
						</span>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddCustPortfolio;
