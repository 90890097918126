import React from 'react';
import './other-settings.css';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import ManageSubAssets from './components-manage-subassets.js/manage-subassets';
import ManageMailFormats from './components-manage-mailformats/manage-mailformats';

function OtherSettings() {
	return (
		<div
			id="style_main"
			style={{ position: 'relative', marginBottom: '50px' }}
		>
			<Breadcrumb>
				<BreadcrumbItem>
					<Link to="/profile">Settings</Link>
				</BreadcrumbItem>
				<BreadcrumbItem active>Other Settings</BreadcrumbItem>
			</Breadcrumb>
			{/* <div style={{ marginBottom: '150px' }}> */}
			<ManageSubAssets />
			{/* </div> */}
			{/* <div style={{ marginBottom: '150px' }}> */}
			<ManageMailFormats />
			{/* </div> */}
		</div>
	);
}
export default OtherSettings;
