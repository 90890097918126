import { SUB_ASSET_RECEIVED } from "../actions/index";

const initialState = {}
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    console.log("in reducer 123456 ==================",action.data)
    switch (action.type) {
        case SUB_ASSET_RECEIVED:
            console.log("1")
            return { ...state, subAssetMasterList: action.data };  
        default:
            return state;
    }
} 