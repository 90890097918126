import React, { Fragment } from 'react';
import './forget-password.css';

const forgetPass = (props) => {
    return(
        <Fragment>
            <div className="col-md-12">
                <div className="card card-container">
                <h2>Create a New Password</h2>
                    <div className="form-group">
                        <label>Username(Mobile Number)</label>
                        <input type="phone" />
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary btn-block">Generate OTP</button>
                    </div>
                    <div className="form-group">
                        <label>Entered Generated OTP</label>
                        <input type="text" />
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary btn-block">Submit</button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default forgetPass;