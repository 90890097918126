import {Link} from 'react-router-dom';
export const COLUMNS = [
    {
        Header: 'Ticket ID',
        accessor: 'id',
        // Cell: ({ row }) => {
        //     return(
        //         <Link to={{pathname: `/updateredressalform/${row.original.id}`, state: {data : row.original}}}>{row.original.id}</Link>
        //     )
        // }
    },
    {
        Header: 'Name',
        accessor: 'cust_name',
        Cell: ({ row }) => {
            return(
                <Link to={{pathname: `/updateredressalform/${row.original.id}`, state: {data : row.original}}}>{row.original.cust_name}</Link>
            )
        }
    },
    {
        Header: 'Date',
        accessor: properties => new Date(properties.date).toLocaleString("en-US", { year: 'numeric', month: '2-digit', day: 'numeric'})
    },
    {
        Header: 'Email Id',
        accessor: 'cust_email'
    },
    {
        Header: 'Mobile No',
        accessor: 'cust_phone'
    },
    {
        Header: 'Subject Line',
        accessor: 'subject',
        Cell: ({ row }) => {
            return(
                <p >{row.original.subject}</p>
            )
        }
    },
    // {
    //     Header: 'Grievance Type',
    //     accessor: 'grievance_type'
    // },
    {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => {
            // console.log("row ori",row.original);
            // const date1 = new Date();
            // const date2 = new Date(row.original.date);
            // const date3 = Math.floor((date1.getTime() - date2.getTime())/(1000 * 3600 * 24));
            // console.log("Date Diff",date3);
            return(
                row.original.status==="Open" ? <p id="status-open">{row.original.status}</p> : row.original.status==="Pending" ? <p id="status-pending">{row.original.status}</p> : <p id="status-resolved">{row.original.status}</p>
                //((row.original.priority === "High" && date3 > 2) || (row.original.priority === "Moderate" && date3 > 4) || (row.original.priority === "Low" && date3 > 6)) && (row.original.status==="Open") ? <p id="status-overdue">Overdue</p> : row.original.status==="Open" ? <p id="status-open">{row.original.status}</p> : ((row.original.priority === "High" && date3 > 2) || (row.original.priority === "Moderate" && date3 > 4) || (row.original.priority === "Low" && date3 > 6)) && row.original.status==="Pending" ? <p id="status-overdue">Overdue</p> : row.original.status==="Pending" ? <p id="status-pending">{row.original.status}</p> :  <p id="status-resolved">{row.original.status}</p>
            )  
        }
    },
    // {
    //     Header: 'Priority',
    //     accessor: 'priority',
    //     Cell: ({ row }) => {
    //         return(
    //             row.original.priority==="High" ? <p id="rcorners3">{row.original.priority}</p> : row.original.priority==="Moderate" ? <p id="rcorners1">{row.original.priority}</p> : <p id="rcorners2">{row.original.priority}</p>
    //         )
    //     }
    // }
]