//import {Link} from 'react-router-dom';

export const COLUMNS = [
    {  
        Header: 'Name of Securities',  
        accessor: 'Name of Securities'
    },
    {
        Header: 'Model Current Weight(%)',
        accessor: 'Current Weight'
    },
    {
        Header: 'No of Shares',
        accessor: 'Number of Shares'
    },
    {
        Header: 'Suggested Amount Allocation',
        accessor: 'Suggested Amount Allocation'
    },
    {
        Header: 'Actual Allocation',
        accessor: 'Actual Allocation'
    },
    {
        Header: 'CMP',
        accessor: 'CMP'
    },
   
]