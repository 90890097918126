import {Link} from 'react-router-dom';
const COLUMNS = [
    // {
    //     Header: 'Sr. No.',
    //     accessor: 'Sr_no',
    // },
    {
        Header: 'Trade ID',
        accessor: 'trade_advice_code',
        Cell: ({ row }) => {
            return(
                row.original.reportVerified === "Send To Broker" ? row.original.trade_advice_code : <Link to={{pathname: `/checkcustomerportfolio/${row.original.trade_advice_code}`, state: {data : row.original}}}>{row.original.trade_advice_code}</Link>
            )
        }
    },
    {
        Header: 'Client Name',
        accessor: properties => properties.customer_portfolio.user.middle_name === "" ? properties.customer_portfolio.user.first_name + ' ' + properties.customer_portfolio.user.last_name : properties.customer_portfolio.user.first_name + ' ' + properties.customer_portfolio.user.middle_name + ' ' + properties.customer_portfolio.user.last_name,
    },
    {
        Header: 'Portfolio Name',
        accessor: 'portfolio_name',
    },
    // {
    //     Header: 'Model Name',
    //     accessor: 'model_name'
    // },
    {
        Header: 'Source',
        accessor: 'source'
    },
    // {
    //     Header: 'Super Admin Approval',
    //     accessor: 'super_admin_approval'
    // },
    {
        Header: 'Verification Status',
        accessor: properties => (properties.reportVerified === null) ? "Send To Broker" : properties.reportVerified,
        // Cell: ({ row }) => {
        //     return(
        //         (row.original.reportVerified === null) ? <p>Send To Broker</p> : <p>{row.original.reportVerified}</p>
        //     )
        // }
    },
    {
        Header: 'Customer Consent Date',
        accessor: properties => properties.type === "Broker" ? new Date(properties.createdAt).toLocaleString('en-IN', { day: 'numeric', month: 'numeric',year: 'numeric' }) : new Date(properties.updatedAt).toLocaleString('en-IN', { day: 'numeric', month: 'numeric',year: 'numeric' }),
    },
]

export default COLUMNS;