//import {Link} from 'react-router-dom';
export const COLUMNS = [
    { 
    Header: 'ID', 
    accessor: 'id'
    },
    {
    Header: 'Broker Name',
    accessor: properties => properties.first_name + ' ' + properties.middle_name + ' ' + properties.last_name
    },
    {
    Header: 'Status',
    accessor: properties => properties.customer_status,
    Cell: ({ row }) => {
        return(
            row.original.customer_status=== 'Active' ? <p style={{color: "blue"}}>{row.original.customer_status}</p> : <p style={{color: "red"}}>{row.original.customer_status}</p>
        )
    }
    },
   ]