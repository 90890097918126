import React from 'react';

const Otpverified = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '200px'}}>
            <h1 style={{color: 'teal'}}>Your OTP is Verified.</h1>
        </div>
    )
}

export default Otpverified;