import { ASSET_RECEIVED } from "../actions/index";

const initialState = {}
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    console.log("in reducer ==================",action.data)
    switch (action.type) {
        case ASSET_RECEIVED:
            console.log("1")
            return { ...state, assetMasterList: action.data };  
        default:
            return state;
    }
} 