//import {Link} from 'react-router-dom';
export const columns = [
	{ Header: 'Client Name', accessor: 'client name' },
	{ Header: 'ISIN Number', accessor: 'ISIN Number' },
	{
		Header: 'CMP',
		accessor: 'CMP',
	},
	{
		Header: 'Prescribed Weight(%)',
		accessor: 'Prescribed Weight',
	},
	{
		Header: 'Model Current Weight(%)',
		accessor: 'Model Current Weight',
	},
	{
		Header: 'Portfolio Current Weight(%)',
		accessor: 'Portfolio Current Weight',
	},
	{
		Header: 'Percentage(%)',
		accessor: 'Delta',
	},
	{
		Header: 'Actionable',
		accessor: 'Actionable',
	},
	{
		Header: 'User Action',
		accessor: 'User Action',
	},
	{
		Header: 'Suggested Trade Qty',
		accessor: 'Suggested Trade',
	},
	// {
	// 	Header: 'Suggested Amount Allocation',
	// 	accessor: 'Suggested Amount Allocation',
	// },
	{
		Header: 'Buy/Sell',
		accessor: 'Buy Sell',
	},
	{
		Header: 'Price',
		accessor: 'Price',
	},
];
