import React, { useState } from 'react';
import { CustomInput } from 'reactstrap';
import ShortTextIcon from '@material-ui/icons/ShortText';
import DateRangeIcon from '@mui/icons-material/DateRange';
import RefreshIcon from '@material-ui/icons/Refresh';
import Form from 'react-bootstrap/Form';

const ReportFilter = (props) => {
	const [fromDate, setFromDate] = useState();
	const [toDate, setToDate] = useState();
	// const [tradeAdviceCode, setTradeAdviceCode] = useState();
	// const [portfolioName, setPortfolioName] = useState();
	// const [modelName, setModelName] = useState();
	// const [type, setType] = useState();
	// const [source, setSource] = useState();
	// const [reportStatus, setReportStatus] = useState();
	// const [consentDate, setConsentDate] = useState();

	const onClickSearch = () => {
		// props.handleSearch({"fromDate": fromDate, "toDate": toDate, "trade_advice_code": tradeAdviceCode, "model_name": modelName, "portfolio_name": portfolioName, "type": type, "source": source, "reportVerified": reportStatus, "consent_date": new Date(consentDate)});
		props.handleSearch({ fromDate: fromDate, toDate: toDate });
	};

	const onClickRefresh = () => {
    setFromDate("");
    setToDate("");
		props.handleRefresh();
	};

	return (
		<>
			<div className="row">
				<div className="col-md-4">
					<label className="form-label">
						<DateRangeIcon /> From Date
					</label>
					<input
						type="date"
						className="form-control"
						placeholder="From Date"
						value={fromDate}
						name={fromDate}
						onChange={(e) => setFromDate(e.target.value)}
					/>
				</div>
				<div className="col-md-4">
					<label className="form-label">
						<DateRangeIcon /> To Date
					</label>
					<input
						type="date"
						className="form-control"
						placeholder="To Date"
						value={toDate}
						name={toDate}
						onChange={(e) => setToDate(e.target.value)}
					/>
				</div>
				<div className="col-md-4">
					<button
						title="Refresh"
						className="btn btn-outline-success"
						id="btn-success"
						onClick={onClickRefresh}
						style={{ marginRight: '10px', marginTop: '35px' }}
					>
						<RefreshIcon />
					</button>
					<button
						className="btn btn-outline-success mr-3"
						id="btn-success"
						onClick={onClickSearch}
						style={{ marginTop: '35px' }}
					>
						Search
					</button>
				</div>
			</div>
			{/* <div className="row"> */}
			{/* <div className="col-sm-3">
          <label className="form-label"><ShortTextIcon/> Select Trade Advice Code</label>
          <Form.Group >
            <CustomInput type="select" onChange={(e) => setTradeAdviceCode(e.target.value)} value={tradeAdviceCode} >
              <option value="-1">Select Option</option>
                {Array.from(props.tradeAdviceCodeData).map((ele) => 
                  <option value={ele} >
                    {ele}
                  </option>)}
            </CustomInput>
          </Form.Group>
      </div> */}
			{/* <div className="col-sm-3">
        <label className="form-label"><ShortTextIcon/> Select Model</label>
        <Form.Group >
          <CustomInput type="select" onChange={(e) => setModelName(e.target.value)} value={modelName} >
            <option value="-1">Select Option</option>
              {Array.from(props.modelNameData).map((ele) => 
                <option value={ele} >
                  {ele}
                </option>)}
          </CustomInput>
        </Form.Group>
      </div>
      <div className="col-sm-3">
        <label className="form-label"><ShortTextIcon/> Select Portfolio Name</label>
        <Form.Group >
          <CustomInput type="select" onChange={(e) => setPortfolioName(e.target.value)} value={portfolioName} >
            <option value="-1">Select Option</option>
              {Array.from(props.portfolioNameData).map((ele) => 
                <option value={ele} >
                  {ele}
                </option>)}
          </CustomInput>
        </Form.Group>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-3">
        <label className="form-label"><ShortTextIcon/> Select Location</label>
        <Form.Group >
          <CustomInput type="select" onChange={(e) => setType(e.target.value)} value={type} >
            <option value="-1">Select Option</option>
              {Array.from(props.typeData).map((ele) => 
                <option value={ele} >
                  {ele}
                </option>)}
          </CustomInput>
        </Form.Group>
      </div>
      <div className="col-sm-3">
        <label className="form-label"><ShortTextIcon/> Select Status</label>
        <Form.Group >
          <CustomInput type="select" onChange={(e) => setReportStatus(e.target.value)} value={reportStatus} >
            <option value="-1">Select Option</option>
              {Array.from(props.statusData).map((ele) => 
                <option value={ele} >
                  {ele}
                </option>)}
          </CustomInput>
        </Form.Group>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-3">
        <label className="form-label"><ShortTextIcon/> Select Accept / Consent Date</label>
        <input
          type="date"
          className="form-control"
          placeholder="To Date"
          value={consentDate}
          name={consentDate}
          onChange={(e) => setConsentDate(e.target.value)}
        />
      </div>
      <div className="col-sm-3">
        <label className="form-label"><ShortTextIcon/> Select Source</label>
        <Form.Group >
          <CustomInput type="select" onChange={(e) => setSource(e.target.value)} value={source} >
            <option value="-1">Select Option</option>
              {Array.from(props.sourceData).map((ele) => 
                <option value={ele} >
                  {ele}
                </option>)}
          </CustomInput>
        </Form.Group>
      </div>
      </div> */}
		</>
	);
};

export default ReportFilter;
