import React from 'react';
import CommunicationChannelTable from './communication-structure/communication-channel-table';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import './communication-channel.css';
import { useState } from 'react';

const headers = [
	'grievance redressal',
	'profile completion',
	'generate report',
	'incoming data (sub-asset classes)',
	'forgot password',
];

function CommunicationChannel() {
	return (
		<div id="style_main">
			<Breadcrumb>
				<BreadcrumbItem>
					<Link to="/profile">Settings</Link>
				</BreadcrumbItem>
				<BreadcrumbItem active>Communication Channel</BreadcrumbItem>
			</Breadcrumb>
			{headers.map((header, index) => {
				return (
					<>
						<div className="communication-channel-table-header">
							{header}
						</div>
						<CommunicationChannelTable id={index} />
					</>
				);
			})}
		</div>
	);
}

export default CommunicationChannel;
