import React from 'react';
import ReactDOM from 'react-dom';

import Card from './Card';
import Button from './Button';
import classes from './Modal.module.css';

const Backdrop = (props) => {
	return <div className={classes.backdrop} />;
};

const ModalOverlay = (props) => {
	return (
		<Card className={classes.modal}>
			<header className={classes.header}>
				<h2>{props.title}</h2>
			</header>

			<form>
				<div className={classes.content}>
					<p>{props.message}</p>
				</div>

				<footer className={classes.actions}>
					{props.type === 'funds_added_globally' ? (
						<Button
							className={classes.button}
							onClick={props.onConfirm}
						>
							Trade Now
						</Button>
					) : (
						<Button
							className={classes.button}
							onClick={props.onConfirm}
						>
							Apply
						</Button>
					)}
					<Button className={classes.button} onClick={props.onCancel}>
						Cancel
					</Button>
				</footer>
			</form>
		</Card>
	);
};

const ModalNew = (props) => {
	return (
		<React.Fragment>
			{ReactDOM.createPortal(
				<Backdrop
					onConfirm={props.onConfirm}
					onCancel={props.onCancel}
				/>,
				document.getElementById('backdrop-root')
			)}
			{ReactDOM.createPortal(
				<ModalOverlay
					title={props.title}
					message={props.message}
					onConfirm={props.onConfirm}
					onCancel={props.onCancel}
					type={props.type}
				/>,
				document.getElementById('overlay-root')
			)}
		</React.Fragment>
	);
};

export default ModalNew;
