import { put, call } from 'redux-saga/effects';
import { loginService } from '../services/auth.service';

import * as types from '../actions'
import {loginSuccessCall} from '../actions/auth'

// export function* registerSaga(payload) {
//   try {
//     const response = yield call(registerUserService, payload);
//     yield [
//       put({ type: types.REGISTER_USER_SUCCESS, response })
//     ];
//   } catch(error) {
//     yield put({ type: types.REGISTER_USER_ERROR, error });
//   }
// }

export function* loginSaga(payload) {
  console.log("payload",payload)
  try {
    console.log("payload",payload)
    const response = yield call(loginService,payload);
    console.log("responseAfterSuccess",response)
    yield  put({type:types.LOGIN_SUCCESS,response});
      // put(loginSuccessCall(response))
     
  } catch(error) {
    yield put({ type: types.LOGIN_USER_ERROR, error })
  }
}