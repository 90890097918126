import { useEffect, useState } from 'react';
import { Link,useParams } from 'react-router-dom';
import { 
        Button,
        Breadcrumb, 
        BreadcrumbItem,
        Label,
        Row,
        Form,
        FormGroup
    } from 'reactstrap';
import { Formik,Field } from 'formik';
import TextEditor from '../Layout/components-manage-mailformats/reactquill';

// material-ui icons
import ShortTextIcon from '@material-ui/icons/ShortText';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import LoadingSpinner from '../UI/Loader';

import CreateIcon from '@mui/icons-material/Create';

import Select from 'react-select';
import '../form.css';
import ApiServices from '../../ApiService/ApiConfig';

const DropDown = props => {
    
    const options = props.multi
      ? [{ label: "Select All", value: "all" }, ...props.options]
      : props.options;
    return (
      <div>
        <Select
          name="example"
          options={options}
          isMulti={props.multi}
          value={props.value ? props.value : null}
          isDisabled={true}

          onChange={selected => {
          console.log("selected is >>",selected);
         
          props.multi &&
          selected.length &&
          selected.find(option => option.value === "all")
            ? props.handleChange(options.slice(1))
            : !props.multi
              ? props.handleChange((selected && selected.value) || null)
              : props.handleChange(selected);
        }}
        />
      </div>
    );
  };

const ViewBroker = (props) => {

    const { id: id } = useParams();

    const [broker,setBroker]=useState({
        brokerName: "",
        address:"",
        drafting:"",
        sub_asset_classes: [],
        contact: [],
        mail: []
    });

    useEffect(()=>{
        fetchBrokerbyID(id);
    },[])

    const fetchBrokerbyID=(id)=>{
        console.log("first");
        ApiServices.getBrokersByID(id)
        .then(res=>{
            console.log("view data",res);
            setBroker(res.found);
        })
        .catch(err=>{
        })
    }
   
    const {broker_house: brokerName , address, mail_drafting : drafting, contact, mail, sub_asset_classes} = broker;

    const selectedBrokder = sub_asset_classes?.map((el) => ({
        value: el.name,
        label: el.name
    }));

	const viewBrokerForm = () => (
		<div>
			<Formik
				initialValues={{
					//brokerName: '',
                    address:'',
                    mobileNumber:'',
					email:'',
                    drafting:''
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					/* and other goodies */
				}) => (
					<form onSubmit={handleSubmit} className="mt-3">
						<div className="row">
							<div className="col-6">
								<div className="form-group mb-3">
									<label className="form-label required">
										<ShortTextIcon /> Name of the Broker House 
									</label>
									<input
										type="text"
										name="brokerName"
										min="2"
										className="form-control"
										placeholder="e.g. : Akshay"
										
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={brokerName}
                                        required
                                        readOnly
									/>
									
								</div>
							</div>
						</div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group mb-3">
                                    <label>
                                        <ShortTextIcon /> Address
                                    </label>
                                    <input
                                        type="text"
                                        name="address"
                                        min="2"
                                        className="form-control"
                                        placeholder="e.g. : Address"

                                        value={address}
                                        onChange={handleChange}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>

						<div className="row">
							<div className="col-6">
								<div className="form-group mb-3">
									<label>
										<PhoneIphoneIcon /> Mobile Number
									</label>
                                    {
                                        contact?.map((el,index)=>{
                                            return(
                                                <input
                                                type="tel"
                                                name="mobileNumber"
                                                maxLength="10"
                                                className="form-control"
                                                placeholder="e.g. : 9878654532"
                                                pattern="[0-9]{10,10}"
                                                Title="Number contain Ten Digits only"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={contact[index].number}
                                                readOnly
									        />
                                            )
                                        })

                                    }
								</div>
							</div>
                            <div className="col-6">
								<div className="form-group mb-3">
									<label className="form-label required">
										<EmailOutlinedIcon /> Email address
									</label>
                                    {
                                        mail?.map((el,index)=>{
                                            return(
                                                <input
                                                type="email"
                                                name="email"
                                                className="form-control"
                                                placeholder="e.g. : akshay23@gmail.com"
                                                
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={mail[index].email}
                                                required
                                                readOnly
                                                />
                                            )
                                        })
                                    }
								</div>
							</div>
						</div>
                        
						<Row md={12}>
                            <Row className="ml-4 mr-1">
                                <FormGroup>
                                    <Label className="required">
                                        <PhoneIphoneIcon />{' '}
                                        Broker Sub-Asset Class
                                    </Label>
                                    <div>
                                        <DropDown
                                            value={selectedBrokder}
                                            options={selectedBrokder}
                                            //handleChange={handlebrokerdrp}
                                            multi={true}
                                        />
                                    </div>
                                </FormGroup>
                            </Row>
						</Row>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group mb-3">
                                    <label>
                                        <ShortTextIcon /> Drafting
                                    </label>
                                    <TextEditor
                                        value={drafting}
                                        setTextarea={()=>{}}
                                        fieldName = 'mail_drafting'
                                        readOnly = {true}
                                    />
                                </div>
                            </div>
                        </div>
					</form>
				)}
			</Formik>
		</div>
	);

	return (
		<>
			<div className="container" id="adjust-top">
				<Breadcrumb>
					<BreadcrumbItem>
						<Link to={{pathname: `/broker`}}>Brokers</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>View Broker</BreadcrumbItem>
				</Breadcrumb>
				<div className="container-fluid bg-light text-center">
					<h3 className="pl-3 pr-3 pt-3 pb-3">View Brokers</h3>
				</div>
                <div className='row'>
                    <div className="col-12">
                        <button
                            style={{ marginRight: '0%' }}
                            title="Refresh Upload Model"
                            className="btn btn-outline-success"
                            id="btn-success"
                        >
                            <Link
                                style={{ color: 'black' }}
                                to={{
                                	pathname: `/UpdateBroker/${id}`,
                                	state: { data: broker },
                                }}
                            >
                                <CreateIcon />
                            </Link>
                        </button>
                    </div>
                </div>
				<div className="container">
					<div className="row">
						<div className="col-md-6 offset-md-3">
							{viewBrokerForm()}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ViewBroker;
