import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { useTable, useSortBy, useGlobalFilter, usePagination, useRowSelect } from 'react-table';
import { Fragment } from 'react';
import GlobalFilter from '../Layout/Table-layout/GlobalFilter';
import '../Layout/Table-layout/MainTable.css';
import Checkbox from '../Layout/Table-layout/Checkbox';
// import { AddNew } from './AddNew';
import { Tooltip } from 'reactstrap';
import CellCustUser from '../Layout/Table-layout/CellCustUser';
// import CustStatus from './CustStatus';
import RedressalFormStatus from '../Layout/Table-layout/RedressalFormStatus';
import { AddNew } from '../Layout/Table-layout/AddNew';
import { AddTableRow } from '../Layout/Table-layout/AddTableRow';
import { TableRow } from '@material-ui/core';

const GlobalFundsTable = (props) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const columns = useMemo(() => props.COLUMNS, [props.COLUMNS]);
    const data = useMemo(() => props.tableData, [props.tableData]);

    console.log(data);

    const { getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
        selectedFlatRows,
        state,
        setGlobalFilter } = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination, useRowSelect,
            // (hooks) => {
            //     hooks.visibleColumns.push((columns) => {
            //         return [
            //             {
            //                 id: 'selection',
            //                 Header: ({ getToggleAllRowsSelectedProps }) => (
            //                     <Checkbox {...getToggleAllRowsSelectedProps()} />
            //                 ),
            //                 Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
            //             },
            //             ...columns
            //         ]
            //     })
            // }
        );
    const { globalFilter, pageIndex, pageSize } = state;
    console.log(state);

    const numberWithCommas = (num) => {
        if(num)
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const toIndianCurrency = (num) => {
		if(num !== '-'){
			const curr = Number(num).toLocaleString('en-IN', {
			currency: 'INR'
			});
			return curr;
		}else{
			return '-';
		}
	 };

    const toggle = () => setTooltipOpen(!tooltipOpen);



    return (
        <Fragment>
            <div >
                <Table {...getTableProps()} hover>
                    <thead >
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                        </span>
                                    </th>
                                ))}
                                {props.type === "Grievance" && (<th><p id="widthAction">Action</p></th>)}
                                {(props.type === "Customer" || props.type === "Users") && <th>Action</th>}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row)
                            console.log("row", row.original)
                            return (
                                <tr>
                                    <td>{row.original['Name of Securities']}</td>
                                    <td>{toIndianCurrency(row.original['CMP'])}</td>
                                    {/* <td>{row.original['Current Weight']}</td> */}
                                    <td>{toIndianCurrency(Math.abs(row.original['Suggested Amount Allocation']))}</td>
                                    <td>{!row.original['Name of Securities'].toLowerCase().includes('cash') ? <select value={row.original['User Action']} onChange={(e) => props.onSaveUserActionDataHandler(e.target.value, row.original['ISIN Number'], row.original['Name of Securities'])} >
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select> : '-'}</td>
                                    {(<td>{row.original['Suggested Trade'] > 0 ? toIndianCurrency(row.original['Suggested Trade']) : row.original['Suggested Trade']}</td>)}
                                    <td>{!row.original['Name of Securities'].toLowerCase().includes('cash') ? row.original['Buy Sell'] : '-'}</td>
                                    <td>{!row.original['Name of Securities'].toLowerCase().includes('cash') &&  (row.original['User Action'] !== "No" && row.original['Suggested Trade'] !== "0") ? <input style={{width:'60px'}}
                                    type="text"
                                    placeholder="e.g. : 80"
                                    value={row.original['Price']}
                                    onChange={(e) => props.handlePrice(e.target.value, row.original['ISIN Number'], row.original['Name of Securities'])}
                                    /> : '-'}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
            <div >
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <span style={{ paddingLeft: '10px' }}>
                    | Go to page: {' '}
                    <input type='number' defaultValue={pageIndex + 1}
                        onChange={(e) => {
                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                            gotoPage(pageNumber);
                        }}
                        style={{ width: '35px', height: '35px' }} />
                </span>
                <span style={{ paddingLeft: '10px' }}>
                    <select value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))} style={{ width: '9em', height: '35px' }}>
                        {[5, 25, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize} Records
                            </option>
                        ))}
                    </select>
                </span>

            </div>
            {/* <pre>
            <code>
                {JSON.stringify(
                    {
                        selectedFlatRows: selectedFlatRows.map((row) => row.original)
                    },
                    null,
                    2
                )}
            </code>
        </pre> */}
        </Fragment>
    );
}

export default GlobalFundsTable;