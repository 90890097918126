import { useState, useEffect } from 'react'; // getRecordByUniqueId
import ApiServices from '../../../ApiService/ApiConfig';
import COLUMNSBROKERDATAREPORTS from "../columns/columns-tradMIS-BrokerReportInfo";
import TradeMIStable from '../Trade-MIS-table/table';
import { Button, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import './MainTable.css';
import { Link, useParams } from 'react-router-dom';

const BrokerReportInfo = (props) => {

    const [reportInfo, setReportInfo] = useState([])
    
    const data = props.location.state.data;
    //console.log("data", data.unique_id_send_report_broker);

    useEffect(() => {
        LandingPageData();
        console.log("data", data)
        }, [])

    const LandingPageData = () => {
        ApiServices.getRecordByUniqueId(data.unique_id_send_report_broker)
        .then((res) => {
            console.log("responceData", res[0].dummy_model_data)
            const newdata = res[0].dummy_model_data
            const dummyData = newdata.filter((el) => {
                return el.User_Action === "Yes" && !el.Name_of_Securities.toLowerCase().includes('cash')
            })
            setReportInfo(dummyData)
        })
        .catch((err) => {
            console.log(err)
        })
    }

  return (
    <div id="style_main">
        <Breadcrumb>
            <BreadcrumbItem>
                <Link to={{pathname: `/trademisreports`}}>Back To TradeMisReports</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Data To Broker</BreadcrumbItem> 
        </Breadcrumb>   
        <div className="container-fluid bg-light text-center"> 
             <h3 className="pl-3 pr-3 pt-3 pb-3">Data To Broker</h3>
         </div>
         <h5>Trad-Advice-Code: {data.trade_advice_code}</h5>
        <TradeMIStable tableData={reportInfo} COLUMNS={COLUMNSBROKERDATAREPORTS} />
    </div>

  )
}

export default BrokerReportInfo