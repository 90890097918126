import React, { useState, useRef } from 'react';

import Card from '../Card';
import Button from '../Button';
import Modal from '../Modal';
import Wrapper from '../Wrapper';
import classes from './ModalTestExample.module.css';

const FundsModal = (props) => {
  const [modal, setModal] = useState();
  const [files, setFiles] = useState({});

  const modalTestHandler = (event) => {
    event.preventDefault();
    setModal({
      title: props.title,
      message: props.message
    });
    
    
  };
   
  const clickHandler = () => {
    setModal(null);
  };

  return (
    <Wrapper>
      {modal && (
        <Modal 
          title={modal.title}
          message={modal.message}
          onConfirm={clickHandler}
          
        />
        
      )}
     
        { <form onSubmit={modalTestHandler}>
          <Button type="submit">{props.buttonName}</Button>
        </form> 
        }
     
    </Wrapper>

  );
};

export default FundsModal;