import React, { useEffect, useState } from 'react';
import ApiServices from '../../../ApiService/ApiConfig';
import { toast } from 'react-toastify';
import TextEditor from './reactquill';


function ManageMailFormats() {
	const [formatList, setFormatList] = useState([]);
	const [textareaVal, setTextarea] = useState({});
	const [flagA, setFlagA] = useState(false);
	const [flagB, setFlagB] = useState(false);
	const [dependency, setDependency] = useState('');
	const [restore, setRestore] = useState('');
	const [dynamics, setDynamics] = useState([]);

	console.log(textareaVal, 'textareaVAALLL');

	const getDynamicValues = (purpose) => {
		let found = dynamics.find((el) => el.purpose === purpose);
		let found_filtered = found.dynamics.filter(
			(el) =>
				el !== '${url}' &&
				el !== '${token}' &&
				el !== '${reset_token}' &&
				el !== '${reset_validity}' &&
				el !== '${id}' &&
				el !== '${user_id}'
		);
		return found_filtered.map((el, i) => <li id={i}>{el}</li>);
	};

	const fetchFormats = async () => {
		let { fetchedAll, mapDynamics } = await ApiServices.GetMailFormats();
		setFormatList(fetchedAll);
		setDynamics(mapDynamics);
	};

	useEffect(() => {
		console.log('useEffect ran');
		fetchFormats();
	}, [dependency]);

	const applyHandler = async () => {
		let response = await ApiServices.UpdateMailFormat({
			html: textareaVal.html,
			id: textareaVal.id,
		});
		toast.success(
			`Mail Format Successfully Updated For Purpose ${textareaVal.purpose}`
		);
		setDependency(response);
		setFlagA(false);
		setFlagB(false);
	};

	return (
		<>
			<div className="horizontal-row">
				<div className="dropdown">
					<button className="dropbtn manage-mails">
						Manage Mail Formats
					</button>
					<div className="dropdown-content manage-mails">
						{formatList.map((element) => {
							return (
								<div key={element.id} className="list-element">
									<div>{element.purpose}</div>
									<button
										className="customize-mail-button"
										onClick={() => {
											setTextarea({
												purpose: element.purpose,
												html: element.html,
												id: element.id,
											});
											setFlagA(true);
											setRestore(element.html);
										}}
									>
										customize
									</button>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			{flagB ? (
				<div className="display-mail preview">
					<iframe srcDoc={textareaVal.html} frameBorder="0"></iframe>
				</div>
			) : null}
			{flagA ? (
				<div className="display-mail-html">
					<div className="texteditor-div">
						<label htmlFor="mailformat">
							{textareaVal.purpose}
						</label>
						<span
							className={
								getDynamicValues(textareaVal.purpose).length
									? 'note-span'
									: 'hide-me'
							}
						>
							<b>NOTE</b> : Do not change the values written in{' '}
							<b>{'${ }'}</b> and link
						</span>
						<span
							className={
								getDynamicValues(textareaVal.purpose).length
									? 'custom-tooltip'
									: 'hide-me'
							}
						>
							VALUES TOOLTIP
							<ul className="tooltiptext-block">
								{getDynamicValues(textareaVal.purpose)}
							</ul>
						</span>
						<TextEditor
							value={textareaVal.html}
							setTextarea={setTextarea}
							fieldName = 'html'
						/>
					</div>

					<div className="tripple-buttons">
						<button
							className="customize-mail-button previewbtn"
							onClick={() => {
								setFlagB((prev) => !prev);
							}}
						>
							{flagB ? 'close preview' : 'preview'}
						</button>
						<div>
							<button
								className="customize-mail-button cancelbtn"
								onClick={() => {
									setFlagA(false);
									setFlagB(false);
									setTextarea({});
									setRestore('');
								}}
							>
								cancel
							</button>
							<button
								className="customize-mail-button restorebtn"
								onClick={() => {
									setTextarea((prev) => ({
										...prev,
										html: restore,
									}));
								}}
							>
								discard
							</button>
							<button
								className="customize-mail-button"
								onClick={applyHandler}
							>
								apply
							</button>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
}
export default ManageMailFormats;
