import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import './MainTable.css';
import { Tooltip } from 'reactstrap';

import ApiServices from '../../../ApiService/ApiConfig';

const CellCustUser = (props) => {
const [status, setStatus] = useState(props.rowData.customer_status);
const onDeleteHandler = () => {
    props.onDelete(props.rowId);
}

const onPortfolioDeleteHandler = () => {
    props.onDelete(props.rowId1);
}

const updateCustomerStatusHandler = (e) => {
    console.log("id", props.rowData.id);
    setStatus(e.target.value);
    props.onUpdateCustomerStatus(props.rowData.id, {customer_status : e.target.value});
}

//  const handleDelete=()=>{
//  console.log(props.rowId);

//  ApiServices.deleteModel(props.rowId)
//  .then(res => {
//  console.log(res, "history", props.history);
//  props.history.push("/modport");
//  }).catch((err) => {
//  console.log(err);
//  })
//  }
// console.log("rowData", props.rowData.customer_profile.status);
 return (
 <>
 {(props.type === "Customer" ) ? <td>
     {/* <span><Link to={{pathname:`${props.urlActionEdit}`+`${props.rowId}`, state: {data : props.rowData}}} ><i class="fa fa-edit" id="Tooltip" aria-hidden="true" data-tip data-for="Tooltip11"></i>
 <ReactTooltip id="Tooltip11" place="bottom" effect="solid" >Edit Customer</ReactTooltip>
 </Link>
 <span> </span>
 <Link to={{pathname:`${props.urlActionView}`+`${props.rowId}`, state: {data : props.rowData}}}><i class="fa fa-eye" aria-hidden="true" data-tip data-for="Tooltip12"></i>
 <ReactTooltip id="Tooltip12" place="bottom" effect="solid" >View Customer</ReactTooltip>
 </Link></span>  */}
 <span> </span>
 {/* {(props.rowData.customer_profile.first_name !== null && props.rowData.customer_profile.pan_no !== null && props.rowData.customer_profile.score === 'Not Yet Complete') && <span><Link to={{pathname:`${props.urlRiskProfile}`+`${props.rowId}`, state: {data : props.rowData}}}>
 <i class="fa fa-exclamation-circle" aria-hidden="true" data-tip data-for="Tooltip13"></i>
 <ReactTooltip id="Tooltip13" place="bottom" effect="solid" >Risk Profilling Form</ReactTooltip>
 </Link></span>}</td> : */}
 {(props.rowData.customer_profile.first_name !== null && props.rowData.customer_profile.pan_no !== null ) && <span><Link to={{pathname:`${props.urlRiskProfile}`+`${props.rowId}`, state: {data : props.rowData}}}>
 <i class="fa fa-exclamation-circle" aria-hidden="true" data-tip data-for="Tooltip13"></i>
 <ReactTooltip id="Tooltip13" place="bottom" effect="solid" >Risk Profilling Form</ReactTooltip>
 </Link></span>}</td> :
//  (props.type === "Users") ? 
//  <td><span><Link to={{pathname:`${props.urlActionEdit}`+`${props.rowId}`, state: {data : props.rowData}}} ><i class="fa fa-edit" id="Tooltip" aria-hidden="true" data-tip data-for="Tooltip14"></i>
//  {/* <Tooltip placement="right" isOpen={tooltipOpen} target="Tooltip" toggle={toggle}>
//  Hello world!
//  </Tooltip> */}
//  <ReactTooltip id="Tooltip14" place="bottom" effect="solid" >Edit User</ReactTooltip>
//  </Link>

//  <span> </span>
//  <Link to={{pathname:`${props.urlActionView}`+`${props.rowId}`, state: {data : props.rowData}}}><i class="fa fa-eye" aria-hidden="true" data-tip data-for="Tooltip15"></i>
//  <ReactTooltip id="Tooltip15" place="bottom" effect="solid" >View User</ReactTooltip>
//  </Link></span> 
//  <span> </span></td> :
 (props.type === "Model") ? 
 <td><span>
 {/* <Link to={{pathname:`${props.urlActionEdit}`+`${props.rowId}`, state: {data : props.rowData}}} ><i class="fa fa-edit" id="Tooltip" aria-hidden="true" data-tip data-for="Tooltip16"></i>
 <ReactTooltip id="Tooltip16" place="bottom" effect="solid" >Edit Model</ReactTooltip>
 </Link>
 <span> </span> */}
 {/* <Link to={{pathname:`${props.urlActionView}`+`${props.rowId}`, state: {data : props.rowData}}}><i class="fa fa-eye" aria-hidden="true"></i></Link>
 <span> </span> */}
 <Link onClick={onDeleteHandler}><i class="fa fa-trash" aria-hidden="true" data-tip data-for="Tooltip17"></i>
 <ReactTooltip id="Tooltip17" place="bottom" effect="solid" >Delete Model</ReactTooltip>
 </Link>
 </span></td> :
 (props.type === "CustomerPortfolio") ? 
 <td><span>
 {/* <Link to={{pathname:`${props.urlActionEdit}`+`${props.rowId1}`, state: {data : props.rowData}}} ><i class="fa fa-eye" id="Tooltip" aria-hidden="true" data-tip data-for="Tooltip18"></i>
 <ReactTooltip id="Tooltip18" place="bottom" effect="solid" >View Customer Portfolio</ReactTooltip>
 </Link>
 <span> </span> */}
 {/* <Link to={{pathname:`${props.urlActionView}`+`${props.rowId}`, state: {data : props.rowData}}}><i class="fa fa-eye" aria-hidden="true"></i></Link>
 <span> </span> */}
 <Link onClick={onPortfolioDeleteHandler}><i class="fa fa-trash" aria-hidden="true" data-tip data-for="Tooltip19"></i>
 <ReactTooltip id="Tooltip19" place="bottom" effect="solid" >Delete Customer Portfolio</ReactTooltip>
 </Link>
 </span></td> : 
 (props.type === "manage-customers" || props.type === "manage-users" || props.type === "manage-brokers") ? <td>
     <select value={status} onChange={updateCustomerStatusHandler}>
        <option value="Active">Active</option>
        <option value="InActive">InActive</option>
     </select>
 </td> : 
 null
 }
 </>
 );
}

export default CellCustUser;