import {Link} from 'react-router-dom';
import { Row } from 'reactstrap';
export const COLUMNS = [
    // {
    //     Header: 'Employee Id',
    //     accessor: 'id'
    // },
    {
        Header: 'Employee Name',
        // accessor: 'firstName'
        accessor: properties => properties.first_name + ' ' + properties.middle_name + ' ' + properties.last_name,
        Cell: ({ row }) => {
            return(
                <Link to={{pathname: `/updateuser/${row.original.id}`, state: {data : row.original}}}>{row.original.first_name + ' ' + row.original.middle_name + ' ' + row.original.last_name}</Link>
            )
        }
    },
    {
        Header: 'Designation',
        accessor: 'designation'
    },
    {
        Header: 'User Role',
        accessor: 'role_id',
        Cell: ({ row }) => {
            return(
                row.original.role_id===1 ? <p>Super-Admin</p> : <p>Admin</p>
            )
        }
    },
    {
        Header: 'Email Id',
        accessor: 'email'
    },
    {
        Header: 'Mobile No.',
        accessor: 'phone'
    }
]