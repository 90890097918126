//import {Link} from 'react-router-dom';
export const COLUMNS = [
    {
        Header: 'ID',
        accessor: 'id'
    },
    {
        Header: 'Initial Name',
        accessor: 'initial_name'
    },
    {
        Header: 'Securities',
        accessor: 'securities'
    },
    {
        Header: 'CMP',
        accessor: 'CMP'
    },
    {
        Header: 'ISIN No.',
        accessor: 'isin_no'
    },
]