  
import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import assetMaster from "./assetMaster"
import subAssetMaster from "./subAssetReducer"

const rootReducer =  combineReducers({
  auth,
  message,
  assetMaster,
  subAssetMaster
});

export default rootReducer;