const COLUMNS = [
    {
    Header: 'Trade ID',
    accessor: 'trade_advice_code',
    },
    {
    Header: 'Model Name',
    accessor: 'model_name'
    },
    {
    Header: 'Portfolio Name',
    accessor: 'portfolio_name',
    },
    {
    Header: 'Customer Name',
    accessor: 'customer_name',
    },
    {
    Header: 'Mutual Fund Name',
    accessor: 'mutual_fund_name'
    },
    {
        Header: 'Unique Code',
        accessor: 'unique_code',
    },
]

export default COLUMNS;
