//import {Link} from 'react-router-dom';
export const columns = [
    { Header: 'ISIN Number',
      accessor: 'ISIN Number'
    },
    {  
        Header: 'Name of Securities',  
        accessor: 'Name of Securities'
    },
    // {
    //     Header: 'Asset Class',
    //     accessor: 'Asset Class'
    // },
    // {
    //     Header: 'Sub Asset Class',
    //     accessor: 'Sub Asset Class'
    // },
    // {
    //     Header: 'Market Cap',
    //     accessor: 'Marketcap'
    // },
    {
        Header: 'CMP',
        accessor: 'CMP'
    },
    {
        Header: 'NSE Symbol',
        accessor: 'NSE Symbol'
    },
    {
        Header: 'No of Shares',
        accessor: 'No of Shares'
    },
    {
        Header: 'Prescribed Weight(%)',
        accessor: 'Prescribed Weight'
    },
    {
        Header: 'Current Value',
        accessor: 'Current Value'
    },
    {
        Header: 'Current Weight(%)',
        accessor: 'Current Weight'
    },
    {
        Header: 'Delta(CW/PW)(%)',
        accessor: 'Delta'
    },
    {
        Header: 'Ratio Start Pt',
        accessor: 'Ratio Start Pt'
    },
    {
        Header: 'Ratio End Pt',
        accessor: 'Ratio End Pt'
    },
    {
        Header: 'Actionable',
        accessor: 'Actionable'
    },
    {
        Header: 'User Action',
        accessor: 'User Action'
    },
    {
        Header: 'Suggested Trade',
        accessor: 'Suggested Trade'
    },
    {
        Header: 'Buy/Sell',
        accessor: 'Buy Sell'
    },
    {
        Header: 'Price',
        accessor: 'Price'
    },
    // {
    //     Header: 'Updated On',
    //     accessor: 'Updated On'
    // },
    // {
    //     Header: 'Updated By',
    //     accessor: 'updatedBy'
    // }
   
]
