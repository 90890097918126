import {Link} from 'react-router-dom';
export const COLUMNS = [
    { 
    Header: 'ID', 
    accessor: 'id'
    },
    {
    Header: 'Customer Name',
    accessor: properties => properties.first_name + ' ' + properties.last_name,
    Cell: ({ row }) => {
        return(
            <Link to={{pathname: `/viewcustomer/${row.original.id}`, state: {data : row.original}}}>{row.original.first_name + ' ' + row.original.middle_name + ' ' + row.original.last_name}</Link>
        )
    }
    },
    {
    Header: 'Email Id',
    accessor: properties => !properties.email ? <p style={{color: 'red'}}>Not Filled Yet</p> : <p>{properties.email}</p>
    },
    {
    Header: 'Mobile No.',
    accessor: properties => properties.customer_profile.country_code === null && properties.phone === null ? <p style={{color: 'red'}}>Not Filled Yet</p> : properties.customer_profile.country_code === null && properties.phone !== null ? <p>{properties.phone}</p> : <p>{properties.customer_profile.country_code + properties.phone}</p>
    },
    {
    Header: 'Risk Profile Type',
    accessor: properties => (properties.customer_profile.score === 'Not Yet Complete' || properties.customer_profile.score === 'Aggressive') ? <p style={{color: 'red'}}>{properties.customer_profile.score}</p> : (properties.customer_profile.score === 'Conservative') ? <p style={{color: 'blue'}}>{properties.customer_profile.score}</p> : <p style={{color: 'green'}}>{properties.customer_profile.score}</p>
    },
    {
    Header: 'Status',
    accessor: properties => properties.customer_profile.status==="Completed" ? <p style={{color: 'green'}}>Completed</p> : <p style={{color: 'red'}}>Incomplete</p>
    // Cell: ({ row }) => {
    // return(
    // <p style={{color: 'green'}}>{row.original.customer_profile.status}</p>
    // )
    // }
    }
   ]