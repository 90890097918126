import { useState, useEffect } from "react";
import { Breadcrumb, BreadcrumbItem, Label, CustomInput } from "reactstrap";
import "../form.css";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

// material-ui icons
import ShortTextIcon from "@material-ui/icons/ShortText";
import TodayIcon from "@material-ui/icons/Today";
import MoneyIcon from "@material-ui/icons/Money";
import CreateIcon from "@mui/icons-material/Create";
import RefreshIcon from "@material-ui/icons/Refresh";
import DownloadIcon from "@mui/icons-material/Download";

import Form from "react-bootstrap/Form";

import ModelTable from "../Layout/Table-layout/ModelTable";
import ApiServices from "../../ApiService/ApiConfig";
import { useUserInfo } from "../../ContextProviders/UserInfo";

import { Link, useParams } from "react-router-dom";
import * as moment from "moment";

import Box from "@mui/material/Box";
// import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import axios from "axios";

import { toast } from "react-toastify";

import ModalNew from "../Modal-UI/Modal";
import Wrapper from "../Modal-UI/Wrapper";
import Button from "../Modal-UI/Button";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import FundsTable from "../Layout/Table-layout/FundsTable";
import GlobalFundsTable from "../Add-Funds-global.js/GlobalFundsTable";
import { columns } from "../Layout/Table-layout/Columns/columns-custportfolio-new";

import { COLUMNS } from "../Add-Funds-global.js/columns-global-funds";

import AccordianComponent from "../Layout/Table-layout/Accordian";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: 500,
  bgcolor: "background.paper",
  border: "1px solid #096dd9",
  boxShadow: 24,
  p: 4,
};

const UpdateCustomerPortfolio = (props) => {
  console.log("props", props);
  const userInfo = useUserInfo();
  const item = userInfo.role.role_name;
  const Cust_Portfolio_Data = props.location.state.data;
  let apiData = [];
  let filename;
  if (Cust_Portfolio_Data.cust_funds_log.length) {
    // let inceptionVal = Cust_Portfolio_Data.inception_value;
    Cust_Portfolio_Data.cust_funds_log.map((item) => {
      const created_date = new Date(item.createdAt).toLocaleDateString("en", {
        day: "numeric",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });
      let obj = {
        Added_Funds: item.funds_added,
        Funds_created_date: created_date,
      };
      apiData.push(obj);
    });
    filename =
      "added_funds_log" +
      Cust_Portfolio_Data.cust_funds_log[0].portfolio_name +
      new Date().toLocaleDateString("en", {
        day: "numeric",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });
  }
  // added by vishal
  const [seeMore, setSeeMore] = useState(true);
  //
  const [modal, setModal] = useState();
  const [model, setModel] = useState();
  const [userEmail, setUserEmail] = useState("");

  const [amount, setAmount] = useState("");
  const [addedFunds, setAddedFunds] = useState("");
  const [fundCash, setfundCash] = useState("");
  const [tableData, settableData] = useState([]);
  const [tableFundsData, settableFundsData] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const [modelDropdownData, setModelDropdownData] = useState([]);
  const [portfolioDropdownData, setPortfolioDropdownData] = useState([]);
  const [portfolioDataList, setPortfolioDataList] = useState();
  const [portfolioName, setPortfolioName] = useState();
  const [name, setName] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [custId, setCustId] = useState("");
  const [brokerName, setBrokerName] = useState([]);
  const [type, setType] = useState("");
  const [thresholdLowerLimit, setThresholdLowerLimit] = useState("");
  const [thresholdUpperLimit, setThresholdUpperLimit] = useState("");
  const [inceptionDate, setInceptionDate] = useState("");
  const [inceptionValue, setInceptionValue] = useState("");
  const [investmentAmt, setInvestmentAmt] = useState("");
  const [addFunds, setAddFunds] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [totalCurrVal, setTotalCurrVal] = useState(0);

  const [fileContents, setFileContents] = useState();
  const [modelFileContents, setModelFileContents] = useState();
  const [files, setFiles] = useState();

  const [processedData, setProcessedData] = useState();

  const [columnsNew, setColumnsNew] = useState([]);
  const [showHide, setShowHide] = useState(true);
  const [ok, setOk] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(false);
  const handleClose = () => setOpen(false);

  const [buttonDisable, setButtonDisable] = useState(true);
  const [fundsButtonDisable, setFundsButtonDisable] = useState(false);
  const [fileUploadDisable, setFileUploadDisable] = useState(false);
  const [status, setStatus] = useState("Unverified");
  const [buttonRefreshDisable, setButtonRefreshDisable] = useState(false);
  const [brokerMail, setBrokerMail] = useState("");
  const [receivedUserData, setReceivedUserData] = useState("");
  const [allPortfolioData, setAllPortfolioData] = useState();
  const [modelCurrWeight, setModelCurrWeight] = useState();
  const [userActionNew, setUserActionNew] = useState();
  const [idForUserActionNew, setIdForUserActionNew] = useState();
  var modelcustomerid;
  var custbrokers;
  const [newTotalCurrVal, setNewTotalCurrVal] = useState();
  const [cashAvailable, setCashAvailable] = useState();
  const [defaultDisabled, setDefaultDisabled] = useState(true);
  const [portfolioDataForAddFunds, setPortfolioDataForAddFunds] = useState([]);
  const [totalCurrValTrade, setTotalCurrValTrade] = useState();
  const [totalSuggestedAllocation, setTotalSuggestedAllocation] = useState();
  const [isExpand_one, setIsExpandOne] = useState(false);

  const { name: portfolio_name } = useParams();

  var usersD;

  useEffect(() => {
    ApiServices.listUser().then((res) => {
      usersD = res[0].email;
      setReceivedUserData(usersD);
    });
  }, []);

  useEffect(() => {
    ApiServices.getModel(type).then((res) => {});
    modelData();

    ApiServices.listModel().then((res) => {
      setModelDropdownData(res);
    });

    ApiServices.listCustomer().then((res) => {
      setPortfolioDropdownData(res);

      const getuserbyid = res.filter((el) => {
        return el.id === modelcustomerid;
      });

      const getcustdete = getuserbyid.map((el) => {
        return el.customer_profile;
      });

      const getborkerdete = getcustdete.map((el) => {
        return el.broker_details;
      });

      const broarraydata = getborkerdete[0];

      setBrokerName(broarraydata);
    });

    ApiServices.customerPortfolioList().then((res) => {
      let custData = [];
      let filenameNew;
      filenameNew =
        portfolio_name +
        new Date().toLocaleDateString("en", {
          day: "numeric",
          month: "2-digit",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        });
      const first_cust_portfolio_name = res[0].portfolio_name;
      res.map((item) => {
        let cust_portfolio_name = item.portfolio_name;
        item.customer_portfolio_securities.forEach((newItem) => {
          let existing = custData.filter((v, i) => {
            return v["Name of Securities"] === newItem["Name of Securities"];
          });
          let obj = {};
          if (existing.length) {
            var existingIndex = custData.indexOf(existing[0]);
            custData[existingIndex][cust_portfolio_name] =
              newItem["No of Shares"];
          } else {
            obj["Name of Securities"] = newItem["Name of Securities"];
            obj[cust_portfolio_name] = newItem["No of Shares"];
            custData.push(obj);
          }
        });
      });

      setPortfolioDataList(custData);
      setPortfolioName(filenameNew);
    });
  }, [userActionNew, idForUserActionNew]);

  const numberWithCommas = (num) => {
    let formatter = new Intl.NumberFormat("en-IN");
    return formatter.format(num);
  };

  const getAutoAllocateButtonFlag = () => {
    if (investmentAmt) return false;
    else return true;
  };

  const generateAssetClass = (data) => {
    let assetListLocal = [];
    setAssetList([]);
    data.forEach((element) => {
      if (element["Name of Securities"].toLowerCase() !== "cash")
        assetListLocal.push(element["Asset Class"]);
      else assetListLocal.push("Cash");
    });

    let assetClassSet = new Set(assetListLocal);
    Array.from(assetClassSet).map((element) => {
      setAssetList((assetList) => [...assetList, element]);
    });
  };

  const calculatePrescribedWt = (type, WTtype) => {
    let totalPrescribedWt = 0;
    tableData.map((element) => {
      if (type === element["Asset Class"]) {
        if (WTtype === "Prescribed Wt") {
          totalPrescribedWt += Number(element["Prescribed Weight"]);
        } else {
          totalPrescribedWt += Number(element["Current Weight"]);
        }
      } else if (type.toLowerCase() === "cash") {
        if (WTtype === "Prescribed Wt") {
          totalPrescribedWt = Number(element["Prescribed Weight"]);
        } else {
          totalPrescribedWt = Number(element["Current Weight"]);
        }
      }
    });
    return totalPrescribedWt.toFixed(3);
  };

  const calculateTotalPrescribedWt = (WTtype) => {
    let totalPrescribedWt = 0;
    tableData.map((element) => {
      if (WTtype === "Prescribed Wt") {
        totalPrescribedWt += Number(element["Prescribed Weight"]);
      } else {
        totalPrescribedWt += Number(element["Current Weight"]);
      }
    });
    return totalPrescribedWt.toFixed(3);
  };

  const modelData = () => {
    ApiServices.getCustomerPortfolio(portfolio_name)
      .then((res) => {
        setName(res.portfolio_name);
        setCustomerName(
          res.user.first_name +
            " " +
            res.user.middle_name +
            " " +
            res.user.last_name
        );
        modelcustomerid = res.user.id;
        // setBrokerName(res.user.customer_profile.broker_name);
        setBrokerMail(res.user.customer_profile.broker_email);
        setType(res.type);
        setThresholdLowerLimit(res.threshold_lower_limit);
        setThresholdUpperLimit(res.threshold_upper_limit);
        setInceptionDate(res.date);
        setInceptionValue(res.inception_value);
        setTotalCurrVal(res.total_current_value);
        setUserEmail(res.user.email);
        setPortfolioDataForAddFunds(res);
        console.log("res121222", res);
        let processedData = [];
        let processedDataFunds = [];
        let total = 0;
        res.customer_portfolio_securities.map((element) => {
          let temp =
            res.portfolio_model.model_securities.find(
              (ele) =>
                ele["ISIN_Number"] === element["ISIN Number"] &&
                ele["Name_of_Securities"] === element["Name of Securities"]
            ) || {};

          let obj = {};
          obj["id"] = element["id"];
          obj["ISIN Number"] = element["ISIN Number"];
          obj["Name of Securities"] = element["Name of Securities"];
          obj["Asset Class"] = element["Asset Class"];
          obj["Sub Asset Class"] = element["Sub Asset Class"];
          obj["Marketcap"] = element["Marketcap"];
          obj["CMP"] = String(Number(element["CMP"]).toFixed(3));
          obj["NSE Symbol"] = element["NSE Symbol"];
          obj["Ratio Start Pt"] = temp["Ratio_Start_Pt"];
          obj["Ratio End Pt"] = temp["Ratio_End_Pt"];
          obj["No of Shares"] = element["No of Shares"];
          obj["Prescribed Weight"] = Number(
            element["Prescribed Weight"]
          ).toFixed(3);

          obj["Current Value"] = Number(element["Current Value"]);
          if (element["Current Weight"] === null) {
            obj["Current Weight"] = Number(element["Current Weight"]);
          } else {
            obj["Current Weight"] = Number(element["Current Weight"]);
          }
          if (element["Delta"] === null) {
            obj["Delta"] = 100;
          } else {
            obj["Delta"] = Number(element["Delta"].toFixed(1));
          }
          obj["Actionable"] = element["Actionable"];
          obj["User Action"] = element["User Action"];
          obj["Suggested Trade"] = element["Suggested Trade"];
          obj["Buy Sell"] = element["Buy Sell"];
          obj["Price"] = element["Price"];
          var momentDate = moment(element["updatedAt"]);
          obj["Updated On"] = momentDate.format("MM-DD-YYYY hh:mm:ss A ");
          obj["updatedBy"] = element["updatedBy"];
          total += Number(obj["Current Value"]);
          processedData.push(obj);
        });

        res.portfolio_model.model_securities.map((element) => {
          let obj = {};
          obj["ISIN Number"] = element["ISIN_Number"];
          obj["Name of Securities"] = element["Name_of_Securities"];
          obj["Asset Class"] = element["Asset_Class"];
          obj["Sub Asset Class"] = element["Sub_Asset_Class"];
          obj["Marketcap"] = element["Marketcap"];
          obj["CMP"] = String(Number(element["CMP"]).toFixed(3));
          obj["NSE Symbol"] = element["NSE_Symbol"];
          obj["Ratio Start Pt"] = element["Ratio_Start_Pt"];
          obj["Ratio End Pt"] = element["Ratio_End_Pt"];
          obj["No of Shares"] = 0;
          obj["Prescribed Weight"] = Number(
            element["Prescribed_Weight"]
          ).toFixed(3);
          obj["Current Value"] = Number(
            (obj["No of Shares"] * Number(obj["CMP"])).toFixed(2)
          );
          if (element["Name_of_Securities"].toLowerCase().includes("cash")) {
            setCashAvailable(obj["No of Shares"]);
          }
          processedDataFunds.push(obj);
        });
        setNewTotalCurrVal(Number(total.toFixed(3)));
        settableData([]);
        settableFundsData([]);
        settableData(processedData);
        settableFundsData(processedDataFunds);
        generateAssetClass(processedDataFunds);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSaveActionDataHandler = (value, isin_number) => {
    setUserActionNew(value);
    setIdForUserActionNew(isin_number);
  };

  const onChangeModelNameHandler = (e) => {
    setType(e.target.value);
    setOpen(true);
  };

  const onChangeCustomerHandler = (e) => {
    setCustomerName(e.target.value);
    let filteredCustomerData = portfolioDropdownData.filter(
      (ele) =>
        ele.first_name + " " + ele.middle_name + " " + ele.last_name ===
        e.target.value
    );
    const newbro = filteredCustomerData[0].customer_profile.broker_details.map(
      (bro) => {
        return bro.broker_name;
      }
    );
    setCustId(filteredCustomerData[0].id);
  };

  const onChangeInvestmentAmt = (e) => {
    if (Number(e.target.value) === 0) return setInvestmentAmt("");
    setInvestmentAmt(e.target.value);
  };

  const onClickAutoAllocate = () => {
    setAmount(Number(investmentAmt) + Number(cashAvailable));
    handleFundsChange(Number(investmentAmt) + Number(cashAvailable));
    modalTestHandler();
  };

  const onSaveUserActionDataHandler = (
    value,
    isin_number,
    nameOfSecurities
  ) => {
    let index = tableFundsData.findIndex(
      (element) =>
        element["ISIN Number"] == isin_number &&
        element["Name of Securities"] == nameOfSecurities
    );
    tableFundsData[index]["User Action"] = value;

    settableFundsData([...tableFundsData]);
    let model = processUserActionChangeMath(tableFundsData, index);
    let TotalSuggestedAlloc = 0;
    model.forEach((ele) => {
      TotalSuggestedAlloc += ele["Suggested Amount Allocation"];
    });
    setTotalSuggestedAllocation(TotalSuggestedAlloc.toFixed(3));
    settableFundsData(model);
  };

  const handlePrice = (val, isinNumber, nameOfSecurities) => {
    let index = tableFundsData.findIndex(
      (element) =>
        element["ISIN Number"] == isinNumber ||
        element["Name of Securities"] == nameOfSecurities
    );
    tableFundsData[index]["Price"] = val;
    settableFundsData([]);
    tableFundsData.forEach((element) => {
      settableFundsData((tableFundsData) => [...tableFundsData, element]);
    });
  };

  const processUserActionChangeMath = (tabFundsData, i) => {
    let newTableFund = [];
    // let index = tableFundsData.findIndex((ele) => ele['Name of Securities'].toLowerCase() === 'cash');
    // let temp = tableFundsData[i]['Suggested Amount Allocation'];
    // tabFundsData.map((element) => {
    //     if(element['Name of Securities'].toLowerCase() !== 'cash'){
    let index = tabFundsData.findIndex((ele) =>
      ele["Name of Securities"].toLowerCase().includes("cash")
    );
    let temp = Number(
      Math.round(
        Number(
          (totalCurrValTrade * tabFundsData[i]["Current Weight"]) /
            100 /
            tabFundsData[i]["CMP"]
        )
      ) * tabFundsData[i]["CMP"]
    );
    if (tabFundsData[i]["User Action"] === "No") {
      tabFundsData[i]["Suggested Trade"] = "-";
      tabFundsData[i]["Suggested Amount Allocation"] = 0;
      tabFundsData[i]["Buy Sell"] = "-";
      newTableFund = [
        ...tabFundsData.slice(0, index),
        Object.assign({}, tabFundsData[index], {
          "No of Shares":
            tabFundsData[index]["Suggested Amount Allocation"] + temp,
          "Suggested Amount Allocation": Number(
            (tabFundsData[index]["Suggested Amount Allocation"] + temp).toFixed(
              3
            )
          ),
          "Suggested Trade": String(
            (tabFundsData[index]["Suggested Amount Allocation"] + temp).toFixed(
              3
            )
          ),
        }),
        ...tabFundsData.slice(index + 1),
      ];
    } else if (tabFundsData[i]["User Action"] === "Yes") {
      tabFundsData[i]["Suggested Trade"] = numberWithCommas(
        Math.round(
          Number(
            (totalCurrValTrade * tabFundsData[i]["Current Weight"]) /
              100 /
              tabFundsData[i]["CMP"]
          )
        )
      );
      tabFundsData[i]["Suggested Amount Allocation"] = Number(
        (
          Math.round(
            Number(
              (totalCurrValTrade * tabFundsData[i]["Current Weight"]) /
                100 /
                tabFundsData[i]["CMP"]
            )
          ) * tabFundsData[i]["CMP"]
        ).toFixed(3)
      );
      if (tabFundsData[i]["Suggested Trade"] === undefined) {
        tabFundsData[i]["Suggested Trade"] = 0;
        tabFundsData[i]["Suggested Amount Allocation"] = 0;
      }
      if (
        Number(String(tabFundsData[i]["Suggested Trade"]).replace(/,/g, "")) > 0
      ) {
        tabFundsData[i]["Buy Sell"] = "Buy";
      } else if (Number(tabFundsData[i]["Suggested Trade"]) === 0) {
        tabFundsData[i]["Suggested Trade"] = String(
          tabFundsData[i]["Suggested Trade"]
        );
        tabFundsData[i]["Buy Sell"] = "-";
      } else {
        tabFundsData[i]["Buy Sell"] = "Sell";
      }
      newTableFund = [
        ...tabFundsData.slice(0, index),
        Object.assign({}, tabFundsData[index], {
          "No of Shares":
            tabFundsData[index]["Suggested Amount Allocation"] - temp,
          "Suggested Amount Allocation": Number(
            (tabFundsData[index]["Suggested Amount Allocation"] - temp).toFixed(
              3
            )
          ),
          "Suggested Trade": String(
            (tabFundsData[index]["Suggested Amount Allocation"] - temp).toFixed(
              3
            )
          ),
        }),
        ...tabFundsData.slice(index + 1),
      ];
    }
    // }
    // })
    return newTableFund;
  };

  const handleEditPage = () => {
    setDefaultDisabled(false);
  };

  let tableRowObj = {
    securities: "abce",
    cmp: null,
    prescribedWeight: null,
    numberOfShares: null,
    currentValue: null,
  };

  const calculateTableRow = (id, property) => {
    let g = tableData[id];
    g[property] = tableData[id]["cmp"] * tableData[id]["numberOfShares"];

    settableData([...tableData.slice(0, id), g, ...tableData.slice(id + 1)]);
  };

  const updateTableRow = (id, property, value) => {
    let g = tableData[id];
    g[property] = value;

    settableData([...tableData.slice(0, id), g, ...tableData.slice(id + 1)]);
  };

  const processModel = (tableData) => {
    let model = [];
    console.log("TABLEDATA", tableData);
    let total = 0;
    tableData.forEach((element, index) => {
      let obj = {};

      obj["id"] = element["id"];

      obj["ISIN Number"] = element["ISIN Number"];

      obj["Name of Securities"] = element["Name of Securities"];

      obj["Asset Class"] = element["Asset Class"];

      obj["Sub Asset Class"] = element["Sub Asset Class"];

      obj["Marketcap"] = element["Marketcap"];

      obj["CMP"] = String(element["CMP"]);

      obj["NSE Symbol"] = element["NSE Symbol"];

      obj["Ratio Start Pt"] = element["Ratio Start Pt"];

      obj["Ratio End Pt"] = element["Ratio End Pt"];

      obj["Prescribed Weight"] = Number(element["Prescribed Weight"]).toFixed(
        3
      );

      obj["No of Shares"] = Number(element["No of Shares"]);

      obj["Current Value"] = Number(
        (obj["No of Shares"] * Number(obj["CMP"])).toFixed(2)
      );

      obj["Price"] = element["Price"];

      obj["Actionable"] = element["Actionable"];

      obj["User Action"] = element["User Action"];

      total += obj["Current Value"];

      model.push(obj);
    });
    // }
    setNewTotalCurrVal(Number(total.toFixed(3)));
    model.map((element) => {
      element["Current Weight"] = Number(
        (
          ((element["No of Shares"] * Number(element["CMP"])) / total) *
          100
        ).toFixed(3)
      );
      element["Delta"] = Number(
        (
          ((((element["No of Shares"] * Number(element["CMP"])) / total) *
            100) /
            Number(element["Prescribed Weight"])) *
          100
        ).toFixed(3)
      );
      if (typeof element["Delta"] === "number" && isNaN(element["Delta"])) {
        element["Delta"] = 0;
      }
      if (
        element["Delta"] < element["Ratio Start Pt"] ||
        element["Delta"] > element["Ratio End Pt"]
      ) {
        element["Actionable"] = "Yes";
        if (element["User Action"] === undefined) {
          element["User Action"] = element["Actionable"];
        }
      } else {
        element["Actionable"] = "No";
      }

      if (element["Delta"] === Infinity) {
        element["Delta"] = 100;
      }

      if (element["User Action"] === "Yes") {
        if (
          Number(element["Prescribed Weight"]) === 0 &&
          element["No of Shares"] !== 0
        ) {
          element["Suggested Trade"] = String(element["No of Shares"]);
          element["Delta"] = 100;
          element["Buy Sell"] = "Sell";
          if (element["Price"] === "-") {
            element["Price"] = "Market Price";
          }
        } else {
          element["Suggested Trade"] = Math.round(
            Math.abs(
              (Number(
                (
                  ((element["No of Shares"] * Number(element["CMP"])) / total) *
                  100
                ).toFixed(6)
              ) -
                element["Prescribed Weight"]) *
                total
            ) /
              element["CMP"] /
              100
          );
          if (element["Suggested Trade"] === undefined) {
            element["Suggested Trade"] = "0";
            element["Buy Sell"] = "-";
            element["Price"] = "-";
          } else {
            if (element["Suggested Trade"] === 0) {
              element["Suggested Trade"] = String(element["Suggested Trade"]);
              element["Buy Sell"] = "-";
              element["Price"] = "-";
            } else if (element["Suggested Trade"] > 0) {
              console.log("TRADE", element["Suggested Trade"]);
              element["Suggested Trade"] = String(element["Suggested Trade"]);
              if (element["Delta"] < 100) {
                element["Buy Sell"] = "Buy";
              } else if (element["Delta"] > 100) {
                element["Buy Sell"] = "Sell";
              }
              element["Price"] = "Market Price";
            }
          }
        }
      } else {
        element["Suggested Trade"] = "-";
        element["Price"] = "-";
      }
      if (element["Name of Securities"].toLowerCase().includes("cash")) {
        element["Buy Sell"] = "-";
        element["Price"] = "-";
      }
    });
    setModel(model);
    return model;
  };

  const addFundsClick = (data) => {
    setAddFunds(true);
  };

  const handleRefresh = () => {
    modelData();
    setFileUploadDisable(false);
    setFundsButtonDisable(false);
  };

  //add funds logs report(Excel sheet)

  const exportToCSV = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  // customer-portfolio generate report
  const generateReportHandleSubmit = async () => {
    const destUrl = `${process.env.REACT_APP_URL_API}/customerportfolio/reportapprovel/${portfolio_name}`;
    const data = await axios({
      method: "post",
      url: destUrl,
      data: {
        name: portfolio_name,
        cust_email: userEmail,
        // super_use_email: receivedUserData,
        broker_email: brokerMail,
      },
    });
    toast.success(
      `Report Generated Successfully and Email is sent to all super-users For Verification.`
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let jsonRequestBody = {};
    let filteredRecord = modelDropdownData.filter((ele) => ele.name === type);
    if (showHide === false) {
      filteredRecord.map((ele) => {
        jsonRequestBody["name"] = ele.name;
        jsonRequestBody["type"] = ele.type;
        jsonRequestBody["threshold_lower_limit"] = ele.threshold_lower_limit;
        jsonRequestBody["threshold_upper_limit"] = ele.threshold_upper_limit;
        jsonRequestBody["date"] = ele.date;
        jsonRequestBody["inception_value"] = ele.inception_value;
      });
      jsonRequestBody["updatedBy"] =
        userInfo.first_name +
        " " +
        userInfo.middle_name +
        " " +
        userInfo.last_name +
        "" +
        "(" +
        userInfo.id +
        ")";
    } else {
      jsonRequestBody["name"] = name;
      jsonRequestBody["type"] = type;
      jsonRequestBody["threshold_lower_limit"] = thresholdLowerLimit;
      jsonRequestBody["threshold_upper_limit"] = thresholdUpperLimit;
      jsonRequestBody["date"] = inceptionDate;
      jsonRequestBody["inception_value"] = parseFloat(inceptionValue);
      jsonRequestBody["updatedBy"] =
        userInfo.first_name +
        " " +
        userInfo.middle_name +
        " " +
        userInfo.last_name +
        "" +
        "(" +
        userInfo.id +
        ")";
      jsonRequestBody["status"] = status;
    }
    if (addedFunds !== "") {
      jsonRequestBody["funds_added"] = parseFloat(addedFunds);
    } else {
      jsonRequestBody["funds_added"] = parseFloat(0);
    }

    jsonRequestBody["model"] = model;

    if (showHide === false) {
      ApiServices.updateModel(jsonRequestBody, jsonRequestBody.name)
        .then((res) => {
          handleClose();
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (showHide === true || oki === false) {
      ApiServices.updateCustomerPortfolio(jsonRequestBody, jsonRequestBody.name)
        .then((res) => {
          props.history.push("/customerportfolio");
          toast.success(`${res.message}`);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setShowHide(true);
  };

const handleSecurityChange = (val, isinNumber, nameOfSecurities) => {
    let index = tableData.findIndex(
      (element) =>
        element["ISIN Number"] == isinNumber &&
        element["Name of Securities"] == nameOfSecurities
    );
    let temp = [...tableData];
    if (nameOfSecurities.length > 0) {
      temp[index] = {
        ...temp[index],
        ["Name of Securities"]: val,
      };
    }
    settableData(temp);
    let model = processModel(temp);
    setFundsButtonDisable(true);
    setButtonDisable(false);

    setModel(model);
    settableData([]);
    model.forEach((element) => {
      settableData((tableData) => [...tableData, element]);
    });
  };

  const handleShares = (val, isinNumber, nameOfSecurities) => {
    let modified = val.replace(/,/g, "");
    if (
      isNaN(Number(modified)) ||
      Math.sign(Number(modified)) === -1 ||
      modified.includes(".")
    )
      return;
    let index = tableData.findIndex(
      (element) =>
        element["ISIN Number"] == isinNumber &&
        element["Name of Securities"] == nameOfSecurities
    );
    let temp = [...tableData];
    if (val.length > 0) {
      temp[index] = { ...temp[index], ["No of Shares"]: modified };
    } else {
      temp[index] = { ...temp[index], ["No of Shares"]: "0" };
    }
    settableData(temp);
    let model = processModel(temp);
    setFundsButtonDisable(true);
    setButtonDisable(false);

    setModel(model);
    settableData([]);
    model.forEach((element) => {
      settableData((tableData) => [...tableData, element]);
    });
  };

  const createViewTableColumns = (data) => {
    // setColumns([]);
    let keys = Object.keys(data[0]);

    Object.entries(data[0]).forEach((el) => {
      let obj = {};
      obj["Header"] = el[0];
      obj["accessor"] = el[0];
      // setColumns(columns => [...columns, obj]);
    });
  };

  const createTableColumns = (data) => {
    if (showHide === false) {
      setColumnsNew([]);
    }
    let keys = Object.keys(data[0]);

    Object.entries(data[0]).forEach((el) => {
      let obj = {};
      obj["Header"] = el[0];
      obj["accessor"] = el[0];
      if (showHide === false) {
        setColumnsNew((columnsNew) => [...columnsNew, obj]);
      }
    });
  };

  const createTableData = (data) => {
    if (showHide === true) {
      let tableDataLocal = [];
      let total = null;

      Object.entries(data).forEach((el) => {
        tableDataLocal.push(el[1]);
      });
      let model = processModel(tableDataLocal);
      setModel(model);
      settableData([]);
      model.forEach((element) => {
        settableData((tableData) => [...tableData, element]);
      });
      generateAssetClass(model);
    } else {
      settableData([]);
      let tableDataLocal = [];
      let keys = Object.keys(data[0]);

      Object.entries(data).forEach((el) => {
        settableData((tableData) => [...tableData, el[1]]);
        tableDataLocal.push(el[1]);
      });
      generateAssetClass(tableDataLocal);
      processModel(tableDataLocal);
    }
  };

  const fileUpload = (file) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    fileReader.onload = (e) => {
      const bufferArray = e.target.result;

      const wb = XLSX.read(bufferArray, { type: "buffer" });

      const wsname = wb.SheetNames[0];

      const ws = wb.Sheets[wsname];

      const data = XLSX.utils.sheet_to_json(ws);

      // createTableColumns(data);
      setFundsButtonDisable(true);
      createTableData(data);
    };
  };

  const modalTestHandler = (event) => {
    // event.preventDefault();
    setModal({
      title: props.title,
      message: props.message,
    });
  };

  const cancelHandler = () => {
    setInvestmentAmt("");
    setModal(null);
    setAmount("");
    tableFundsData.forEach((element, index) => {
      element["Actual Allocation"] = null;
      element["Suggested Amount Allocation"] = null;
      element["Number of Shares"] = null;
    });
  };

  const applyHandler = () => {
    let newTableData = [];
    let updateCashInPortfolio;
    tableFundsData.map((element) => {
      let obj = {};
      if (!element["Name of Securities"].toLowerCase().includes("cash")) {
        obj["ISIN Number"] = element["ISIN Number"];
        obj["Name of Securities"] = element["Name of Securities"];
        obj["Asset Class"] = element["Asset Class"];
        obj["Sub Asset Class"] = element["Sub Asset Class"];
        obj["Marketcap"] = element["Marketcap"];
        obj["CMP"] = element["CMP"];
        obj["Ratio Start Pt"] = element["Ratio Start Pt"];
        obj["Ratio End Pt"] = element["Ratio End Pt"];
        obj["Prescribed Weight"] = element["Prescribed Weight"];
        obj["Suggested Amount Allocation"] = Math.abs(
          element["Suggested Amount Allocation"]
        );
        if (
          element["User Action"] === undefined ||
          element["User Action"] === "Yes"
        ) {
          obj["User Action"] = "Yes";
        } else {
          obj["User Action"] = element["User Action"];
        }
        obj["Suggested Trade"] = element["Suggested Trade"];
        obj["Buy Sell"] = element["Buy Sell"];
        if (element["User Action"] === "No") {
          obj["Price"] = "-";
        } else {
          obj["Price"] = element["Price"];
        }
      } else {
        obj["ISIN Number"] = element["ISIN Number"];
        obj["Name of Securities"] = element["Name of Securities"];
        obj["Asset Class"] = element["Asset Class"];
        obj["Sub Asset Class"] = element["Sub Asset Class"];
        obj["Marketcap"] = element["Marketcap"];
        obj["CMP"] = element["CMP"];
        obj["Ratio Start Pt"] = element["Ratio Start Pt"];
        obj["Ratio End Pt"] = element["Ratio End Pt"];
        obj["Prescribed Weight"] = element["Prescribed Weight"];
        obj["Suggested Amount Allocation"] = Math.abs(
          element["Suggested Amount Allocation"]
        );
        if (
          element["User Action"] === undefined ||
          element["User Action"] === "Yes"
        ) {
          obj["User Action"] = "Yes";
        } else {
          obj["User Action"] = element["User Action"];
        }
        obj["Suggested Trade"] = element["Suggested Trade"];
        obj["Buy Sell"] = element["Buy Sell"];
        if (element["User Action"] === "No") {
          obj["Price"] = "-";
        } else {
          obj["Price"] = element["Price"];
        }
      }
      newTableData.push(obj);
    });
    const jsonRequestBody = {
      cust_email: portfolioDataForAddFunds.user.email,
      broker_details:
        portfolioDataForAddFunds.user.customer_profile.broker_details,
      customer_portfolio_inception_value: parseFloat(investmentAmt),
      updatedBy:
        userInfo.first_name +
        " " +
        userInfo.middle_name +
        " " +
        userInfo.last_name +
        "" +
        "(" +
        userInfo.id +
        ")",
      model: newTableData,
      // "updateCashInPortfolio": updateCashInPortfolio,
      source: "ModPort",
    };
    ApiServices.updateFundsGlobally(
      jsonRequestBody,
      portfolioDataForAddFunds.portfolio_name
    )
      .then((res) => {
        toast.success(`${res.message}`);
      })
      .catch((err) => {
        console.log(err);
      });
    setButtonRefreshDisable(false);
    setModal(null);
  };

  const PopupWindow = () => {
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="container">
            <h4>First you need to update latest model details</h4>
            <div className="row">
              <div className="col-sm-6">
                <label className="form-label">
                  <MoneyIcon /> Update Securities +
                </label>
                <Form.Group controlId="modelFileContents">
                  <Form.Control
                    type="file"
                    name="modelFileContents"
                    value={modelFileContents}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      setShowHide(true);
                      fileUpload(file);
                    }}
                  />
                </Form.Group>
              </div>
            </div>
            {/* <div className="row">
                    <div className="col-sm-10">
                    </div>
                    <div className="col-sm-2">
                        <Button onClick={handleClose}>Skip</Button>
                    </div>
                </div> */}
            {showHide && (
              <div style={{ height: "300px", overflow: "scroll" }}>
                <ModelTable
                  tableData={tableData}
                  COLUMNS={columns}
                  calculateTableRow={calculateTableRow}
                  updateTableRow={updateTableRow}
                />
              </div>
            )}
          </div>
          <center>
            <Button onClick={handleSubmit}>Apply</Button>
            <Button onClick={handleClose}>Skip</Button>
          </center>
        </Box>
      </Modal>
    );
  };
  let oki;
  const handleFileUpload = (e) => {
    setButtonDisable(false);
    oki = e.nativeEvent.cancelBubble;
    const file = e.target.files[0];
    fileUpload(file);
  };
  const addModelForm = () => (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-3">
          <label className="form-label">
            <ShortTextIcon /> Name
          </label>

          <Form.Group controlId="threshold">
            <Form.Control
              type="text"
              className="form-control"
              placeholder="e.g. : Portfolio name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              readOnly
            />
          </Form.Group>
        </div>
        <div className="col-sm-3">
          <label className="form-label required">
            <ShortTextIcon /> Select Customer
          </label>

          <Form.Group>
            <CustomInput
              type="select"
              required
              onChange={onChangeCustomerHandler}
              value={customerName}
              disabled
            >
              <option value="-1">Select Option</option>
              {portfolioDropdownData.map((ele) => (
                <option
                  value={
                    ele.first_name + " " + ele.middle_name + " " + ele.last_name
                  }
                >
                  {ele.first_name + " " + ele.middle_name + " " + ele.last_name}
                </option>
              ))}
            </CustomInput>
          </Form.Group>
        </div>

        {handleOpen && <div>{PopupWindow()}</div>}
      </div>
      <div className="row">
        {/* <div className="col-sm-3">
                <label className="form-label required"><ShortTextIcon /> Broker Name</label> */}

        {/* <Form.Group controlId="thresholdUpperLimit">
                        <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="e.g. : 125"
                            value={brokerName}
                            onChange={(e) => setBrokerName(e.target.value)}
                            required
                            readOnly
                        />
                </Form.Group> */}

        {/* <Form.Group >
                        <CustomInput type="select" required  value={brokerName} disabled={defaultDisabled} multiple>
                        { brokerName !== undefined ? brokerName.map((brokname) => {
                        return <option value={brokname.broker_name} readOnly>{brokname.broker_name}</option>
                    }) : <option value="" readOnly>Not Selected</option> } 
                        </CustomInput>
                    </Form.Group>

            </div> */}
        <div className="col-sm-3">
          <label className="form-label required">
            <ShortTextIcon /> Select Model
          </label>

          <Form.Group>
            <CustomInput
              type="select"
              required
              onChange={onChangeModelNameHandler}
              value={type}
              disabled
            >
              <option value="-1">Select Option</option>
              {modelDropdownData.map((ele) => (
                <option value={ele.name}>{ele.name}</option>
              ))}
            </CustomInput>
          </Form.Group>
        </div>
        <div className="col-sm-3">
          <label className="form-label">
            <TodayIcon /> Creation Date
          </label>

          <Form.Group controlId="date">
            <Form.Control
              type="date"
              name="duedate"
              placeholder="Due date"
              value={inceptionDate}
              onChange={(e) => setInceptionDate(e.target.value)}
              readOnly
            />
          </Form.Group>
        </div>
      </div>
      {/* <div className="row">
            <div className="col-sm-3">
                <label className="form-label"><TodayIcon /> Threshold Lower Limit({'<'}100)</label>

                <Form.Group controlId="thresholdLowerLimit">
                <Form.Control
                type="text"
                className="form-control"
                placeholder="e.g. : 80"
                value={thresholdLowerLimit}
                onChange={(e) => setThresholdLowerLimit(e.target.value)}
                />
                </Form.Group>
            </div>
            <div className="col-sm-3">
                <label className="form-label"><TodayIcon /> Threshold Upper Limit({'>'}100)</label>

                <Form.Group controlId="thresholdUpperLimit">
                <Form.Control
                type="text"
                className="form-control"
                placeholder="e.g. : 125"
                value={thresholdUpperLimit}
                onChange={(e) => setThresholdUpperLimit(e.target.value)}
                />
                </Form.Group>
            </div>
        </div> */}
      {/* <div className="row">
            <div className="col-sm-3">
                <label className="form-label"><TodayIcon /> Inception Date</label>

                <Form.Group controlId="date">
                <Form.Control
                type="date"
                name="duedate"
                placeholder="Due date"
                value={inceptionDate}
                onChange={(e) => setInceptionDate(e.target.value)}
                readOnly
                />
                </Form.Group>
            </div>

            <div className="col-sm-3">
                <label className="form-label"><MoneyIcon /> Investment Amount</label>
                <Form.Group controlId="inceptionValue">
                <Form.Control
                type="text"
                className="form-control"
                name="inceptionvalue"
                placeholder="e.g. : 10000000"
                value={numberWithCommas(inceptionValue)}
                onChange={(e) => setInceptionValue(e.target.value)}
                readOnly
                />
                </Form.Group>
            </div>
        </div> */}
      {/* <div className="row">
        <div className="col-sm-3">
                    <label className="form-label required"><MoneyIcon /> Total Current Value</label>
                    <Form.Group controlId="totalCurrValue">
                        <Form.Control
                            type="text"
                            className="form-control"
                            name="totalCurrVal"
                            placeholder="e.g. : 10000000"
                            value={numberWithCommas(newTotalCurrVal)}
                            onChange={(e) => setNewTotalCurrVal(e.target.value)}
                            readOnly
                        />
                    </Form.Group>
                </div>
            </div> */}
      {/* <div className="row">
            <div className="col-sm-3">
                <label className="form-label required"><MoneyIcon /> Add securities +</label>
                <Form.Group controlId="fileContents">
                <Form.Control
                type="file"
                name="fileContents"
                value={fileContents}
                onChange={(e) => handleFileUpload(e)}
                disabled={fileUploadDisable}
                />
                </Form.Group>
            </div>
        </div> */}
      {inceptionValue === null && (
        <div className="row">
          <div className="col-sm-3">
            <label className="form-label required">
              <ShortTextIcon /> Investment Amount
            </label>

            <Form.Group controlId="threshold">
              <Form.Control
                type="number"
                className="form-control"
                placeholder="e.g. : Investment Amount"
                value={investmentAmt}
                onChange={onChangeInvestmentAmt}
              />
              {investmentAmt ? (
                <span
                  style={{
                    color: "GrayText",
                    textTransform: "capitalize",
                  }}
                >
                  {seeMore ? (
                    <>
                      {getString(dreds(investmentAmt), "sub")}
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                        onClick={() => setSeeMore(false)}
                      >
                        .....
                      </span>
                    </>
                  ) : (
                    getString(dreds(investmentAmt), "full")
                  )}
                </span>
              ) : null}
            </Form.Group>
          </div>
        </div>
      )}
    </form>
  );

  const handleFundsChange = (amt) => {
    let tableFundsD;
    function updateItem(id, itemAttributes) {
      var index = tableFundsData.findIndex((x) =>
        x["Name of Securities"].toLowerCase().includes(id)
      );
      if (index === -1) {
        // handle error
      } else {
        tableFundsD = [
          ...tableFundsData.slice(0, index),
          Object.assign({}, tableFundsData[index], {
            "Current Value": amt,
            "No of Shares": amt,
          }),
          ...tableFundsData.slice(index + 1),
        ];
      }
    }

    updateItem("cash");
    const modelNew = processModel(tableFundsD);
    console.log("MODELNEW", modelNew);
    let totalModel = 0;
    portfolioDataForAddFunds.portfolio_model.model_securities.forEach((ele) => {
      totalModel += ele["No_of_Shares"] * Number(ele["CMP"]);
    });
    let totalPortfolio = 0;
    modelNew.forEach((ele) => {
      totalPortfolio += ele["No of Shares"] * Number(ele["CMP"]);
    });
    console.log("TOTAL", totalModel, totalPortfolio);
    let total = 0;
    let newTabData = [];
    modelNew.forEach((ele) => {
      let obj = {};
      let selectCurrWt =
        portfolioDataForAddFunds.portfolio_model.model_securities.filter(
          (element) =>
            element["ISIN_Number"] === ele["ISIN Number"] &&
            element["Name_of_Securities"] === ele["Name of Securities"]
        );
      // obj['id'] = ele['id'];

      obj["ISIN Number"] = ele["ISIN Number"];

      obj["Name of Securities"] = ele["Name of Securities"];

      obj["Asset Class"] = ele["Asset Class"];

      obj["Sub Asset Class"] = ele["Sub Asset Class"];

      obj["Marketcap"] = ele["Marketcap"];

      obj["CMP"] = String(ele["CMP"]);

      obj["NSE Symbol"] = ele["NSE Symbol"];

      obj["Ratio Start Pt"] = ele["Ratio Start Pt"];

      obj["Ratio End Pt"] = ele["Ratio End Pt"];

      obj["Prescribed Weight"] = String(ele["Prescribed Weight"]);

      obj["No of Shares"] = Number(ele["No of Shares"]);

      obj["Price"] = "Market Price";

      obj["Current Value"] = ele["No of Shares"] * Number(ele["CMP"]);
      total += obj["Current Value"];
      // if(ele['Name of Securities'].toLowerCase() !== "cash"){
      obj["Current Weight"] = Number(
        Number(
          (
            Number(
              (selectCurrWt[0]["No_of_Shares"] *
                Number(selectCurrWt[0]["CMP"])) /
                totalModel
            ) * 100
          ).toFixed(6)
        ) -
          Number(
            (
              ((ele["No of Shares"] * Number(ele["CMP"])) / totalPortfolio) *
              100
            ).toFixed(6)
          )
      );
      console.log("WEIGHT", obj["Current Weight"]);
      // }else{
      // obj["Current Weight"] = '-';
      // }
      // if(ele['User Action'] !== undefined){
      //     obj['User Action'] = ele['User Action']
      // }
      newTabData.push(obj);
    });
    console.log("NEWTAB", newTabData);
    setTotalCurrValTrade(total);
    let total_suggested_allocation = 0;
    newTabData.map((element) => {
      let currWt = element["Current Weight"];
      if (!element["Name of Securities"].toLowerCase().includes("cash")) {
        element["Current Weight"] = Number(currWt.toFixed(3));
        element["Suggested Trade"] = Math.round(
          Number((total * currWt) / 100 / element["CMP"])
        );
        element["Suggested Amount Allocation"] = Number(
          (
            Math.round(Number((total * currWt) / 100 / element["CMP"])) *
            element["CMP"]
          ).toFixed(3)
        );
        total_suggested_allocation +=
          Math.round(Number((total * currWt) / 100 / element["CMP"])) *
          element["CMP"];

        if (element["Suggested Trade"] > 0) {
          element["Suggested Trade"] = String(element["Suggested Trade"]);
          element["Buy Sell"] = "Buy";
        } else if (element["Suggested Trade"] === 0) {
          element["Suggested Trade"] = "-";
          element["User Action"] = "No";
          element["Buy Sell"] = "-";
          element["Price"] = "-";
        } else {
          element["Suggested Trade"] = String(
            Math.abs(Number(element["Suggested Trade"]))
          );
          element["Buy Sell"] = "Sell";
        }
      }
      // else if(element['Name of Securities'].toLowerCase() === "cash"){
      //     element['Suggested Trade'] = Math.round(Number(((total * element["Current Weight"])/100)/element["CMP"]));
      //     element["Suggested Amount Allocation"] = Number((element["Suggested Trade"] * element["CMP"]).toFixed(3));
      //     console.log("Auto-allocate", element['Suggested Trade'], element["Suggested Amount Allocation"])
      //     if(element['Suggested Trade'] > 0){
      //         element['Suggested Trade'] = String(element['Suggested Trade']);
      //     }else{
      //         element['Suggested Trade'] = String(Math.abs(element['Suggested Trade']));
      //     }
      // }
    });

    newTabData.map((element) => {
      if (element["Name of Securities"].toLowerCase().includes("cash")) {
        element["Suggested Trade"] = (amt - total_suggested_allocation).toFixed(
          2
        );
        element["Suggested Amount Allocation"] = Number(
          (amt - total_suggested_allocation).toFixed(2)
        );
      }
    });

    let TotalSuggestedAlloc = 0;
    newTabData.forEach((ele) => {
      TotalSuggestedAlloc += ele["Suggested Amount Allocation"];
    });
    setTotalSuggestedAllocation(TotalSuggestedAlloc.toFixed(3));

    setfundCash(0);
    let fundCash = 0;

    settableFundsData([]);
    newTabData.forEach((element) => {
      settableFundsData((newTabData) => [...newTabData, element]);
    });
  };

  const addFundsModelForm = () => (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-6">
          <label className="form-label">
            <ShortTextIcon /> Funds Addition + Available Cash
          </label>

          <Form.Group controlId="threshold">
            <Form.Control
              type="text"
              className="form-control"
              placeholder="e.g. : 10000000"
              value={numberWithCommas(amount)}
              onChange={(e) => {
                handleFundsChange(e);
                setInvestmentAmt(amount);
              }}
              readOnly
            />
          </Form.Group>
        </div>
        <div style={{ marginTop: "4.5%" }} className="col-sm-6"></div>
      </div>
    </form>
  );

  return (
    <>
      <div id="style_main">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={{ pathname: `/customerportfolio` }}>
              Customer Portfolio
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Update Customer Portfolio</BreadcrumbItem>
        </Breadcrumb>

        <div className="container">
          <div className="row">
            <div className="col-12">
              <button
                style={{ marginLeft: "1%" }}
                title="Edit Customer Portfolio"
                className="btn btn-outline-success"
                id="btn-success"
                onClick={handleEditPage}
              >
                <CreateIcon />
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 offset-md-3">{addModelForm()}</div>
          </div>
          <div>
            {item === "super-admin" &&
            Cust_Portfolio_Data.cust_funds_log.length ? (
              <button
                style={{ marginLeft: "1%" }}
                className="btn btn-outline-success mt-0 mr-3"
                id="btn-success"
                onClick={(e) => exportToCSV(apiData, filename)}
                disabled={defaultDisabled}
              >
                Funds Logs Report
              </button>
            ) : null}
            {/* <button style={{ marginLeft: '1%' }} title="Download Customer's no of shares in xlsx format" className="btn btn-success" id="btn-success" onClick={(e) => exportToCSV(portfolioDataList, portfolioName)}><DownloadIcon/></button> */}
            {item === "super-admin" && (
              <button
                style={{ marginLeft: "1%" }}
                // disabled={buttonRefreshDisable}
                className="btn btn-outline-success"
                id="btn-success"
                onClick={handleRefresh}
                disabled={defaultDisabled}
              >
                <RefreshIcon />
              </button>
            )}
            {inceptionValue === null && (
              <button
                style={{ marginLeft: "1%" }}
                className="btn btn-outline-success"
                id="btn-success"
                onClick={onClickAutoAllocate}
                disabled={getAutoAllocateButtonFlag()}
              >
                Auto Allocate
              </button>
            )}
            {item === "super-admin" && (
              <button
                style={{ marginLeft: "1%" }}
                disabled={buttonDisable}
                className="btn btn-outline-success"
                id="btn-success"
                onClick={handleSubmit}
              >
                Update Portfolio
              </button>
            )}
            {/* <button style={{marginLeft:'1%'}} className="btn btn-success mr-3" id="btn-success" onClick={generateReportHandleSubmit}>Generate Report</button> */}
            <Wrapper>
              {modal && (
                <ModalNew
                  title={modal.title}
                  type="funds_added_globally"
                  message={
                    <div>
                      <div className="row">
                        <div className="col-md-12">{addFundsModelForm()}</div>
                      </div>

                      <GlobalFundsTable
                        tableData={tableFundsData}
                        COLUMNS={COLUMNS}
                        handlePrice={handlePrice}
                        onSaveUserActionDataHandler={
                          onSaveUserActionDataHandler
                        }
                      />
                      <p
                        style={{
                          textAlign: "right",
                          fontWeight: "bold",
                          color: "#5d6a74",
                        }}
                      >
                        Total Amount Allocation:{" "}
                        {numberWithCommas(totalSuggestedAllocation)}
                      </p>
                    </div>
                  }
                  onConfirm={applyHandler}
                  onCancel={cancelHandler}
                />
              )}

              {
                <form onSubmit={modalTestHandler}>
                  {/* {(item === 'super-admin') && <Button className="btn btn-outline-success" disabled={fundsButtonDisable} type="submit" disabled={defaultDisabled}>Add Funds</Button>} */}
                </form>
              }
            </Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            {assetList.length ? (
              <button
                id="dvwm-accordian-toggle"
                onClick={() => setIsExpandOne((prev) => !prev)}
              >
                {isExpand_one ? "Collapse All" : "Expand All"}
              </button>
            ) : null}
          </div>
        </div>
        <div style={{ marginTop: "5%" }}>
          {assetList.map((element, index) => (
            // <Accordion key={index+1} defaultActiveKey={index+1}>
            // <Accordion.Toggle as={Card.Header} eventKey={index+1} eventKey={index+1} style={{border:'solid 1px #D3D3D3', borderRadius:'4px', cursor:'pointer',backgroundColor: '#e9ecef'}}>
            //     <span style={{fontWeight:'bolder'}}> {element} </span>
            //     <span style={{marginLeft: '10px', fontSize: '12px'}}>(PW Total : {calculatePrescribedWt(element)} of {calculateTotalPrescribedWt()})</span>
            // </Accordion.Toggle>

            // <Accordion.Collapse eventKey={index+1}>
            //     {showHide && <ModelTable tableData={tableData.filter(el=>{
            //                         if(element!='Cash' && el['Name of Securities'] !== 'Cash')
            //                          return el['Asset Class']==element;
            //                         else
            //                          return el['Name of Securities']==element;
            //                     })

            //                 } COLUMNS={columns} type="ViewPortfolioTable" calculateTableRow={calculateTableRow} updateTableRow={updateTableRow} handleShares={handleShares} buttonDisable={fileUploadDisable} onSaveActionData={onSaveActionDataHandler} defaultDisabled={defaultDisabled} />}
            // </Accordion.Collapse><br />
            // </Accordion>
            <AccordianComponent
              title={
                <span
                  style={{
                    marginLeft: "10px",
                    fontSize: "12px",
                  }}
                >
                  {
                    <span
                      style={{
                        fontWeight: "bold",
                        marginRight: "20px",
                      }}
                    >
                      {element}
                    </span>
                  }
                  <span style={{ color: "blue" }}>PW</span>:{" "}
                  {calculatePrescribedWt(element, "Prescribed Wt")}%{" "}
                  <span style={{ color: "blue" }}>CW</span>:{" "}
                  {calculatePrescribedWt(element, "Current Wt")}%
                </span>
              }
              isExpand={isExpand_one}
              content={
                showHide && (
                  <ModelTable
                    tableData={tableData.filter((el) => {
                      if (
                        element != "Cash" &&
                        el["Name of Securities"] !== "Cash"
                      )
                        return el["Asset Class"] == element;
                      else return el["Name of Securities"] == element;
                    })}
                    COLUMNS={columns}
                    type="ViewPortfolioTable"
                    calculateTableRow={calculateTableRow}
                    updateTableRow={updateTableRow}
                    handleShares={handleShares}
                    handleSecurityChange={handleSecurityChange}
                    buttonDisable={fileUploadDisable}
                    onSaveActionData={onSaveActionDataHandler}
                    defaultDisabled={defaultDisabled}
                  />
                )
              }
            ></AccordianComponent>
          ))}
          {oki === false && (
            <ModelTable
              // tableData={tableData.filter(el=>{
              //                             if(element!='Cash')
              //                              return el['Asset Class']==element;
              //                             else
              //                              return el['Name of Securities']==element;
              //                         })

              //                     }
              COLUMNS={columnsNew}
              type="updateModelPortfolioTable"
              calculateTableRow={calculateTableRow}
              updateTableRow={updateTableRow}
              handleShares={handleShares}
              handleSecurityChange={handleSecurityChange}
              buttonDisable={fileUploadDisable}
            />
          )}
        </div>
        {/* <div style={{marginTop: '15px', marginBottom: '15px', height: "125px"}}>
            {Cust_Portfolio_Data.cust_funds_log.length ? <button style={{ marginLeft: '1%', marginBottom: '1%' }} className="btn btn-success mt-0 mr-3" id="btn-success" onClick={(e) => exportToCSV(apiData, filename)}>Added Funds Logs Report</button> : null}
    </div> */}
      </div>
    </>
  );
};

export default UpdateCustomerPortfolio;

var tens = (num) => {
  var box = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  var box_ = [
    "twenty",
    "thirty",
    "fourty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  if (num > 19 && num < 100) {
    var splitted = num
      .toString()
      .split("")
      .map((el) => Number(el));
    var ready = box_[splitted[0] - 2] + " " + tens(splitted[1]);
    return ready.trim();
  }
  return box[num];
};

const dreds = (num) => {
  if (num > 10000000000) return "number too big";
  var modified = num.toString();
  var dredList = {
    3: ["hundred", 100],
    4: ["thousand", 1000],
    5: ["thousand", 1000],
    6: ["lakh", 100000],
    7: ["lakh", 100000],
    8: ["crore", 10000000],
    9: ["crore", 10000000],
    10: ["billion", 1000000000],
    11: ["billion", 1000000000],
  };

  if (modified.length > 2) {
    let callit = dredList[modified.length][0];
    let point = dredList[modified.length][1];
    let integer = Math.trunc(num / point);
    let devide = (num / point).toPrecision(modified.length);
    let decimal = devide.toString().split(".")[1];
    let toReturn = tens(integer) + " " + callit + " " + dreds(Number(decimal));

    return toReturn.trim();
  }
  return tens(num).trim();
};

const getString = (value, flag) => {
  if (flag === "sub") {
    return value.substring(0, 30);
  }
  if (flag === "full") {
    return value;
  }
};
