/* eslint-disable import/no-anonymous-default-export */

  import {LOGIN_SUCCESS,LOGIN_FAIL,LOGOUT} from '../actions/index';
  
  const user = localStorage.getItem("token");
  
  const initialState = user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };
  
  export default function (state = initialState, action) {
    // let { type, payload } = action;
  console.log("Auth reducer",action)
    switch (action.type) {
      // case REGISTER_SUCCESS:
      //   return {
      //     ...state,
      //     isLoggedIn: false,
      //   };
      // case types.REGISTER_FAIL:
      //   return {
      //     ...state,
      //     isLoggedIn: false,
      //   };
      case LOGIN_SUCCESS:
        console.log("sucesssssssssssssssss")
        return {
          ...state,
          isLoggedIn: true,
          user: action.response,
        };
      case LOGIN_FAIL:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
        };
      case LOGOUT:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
        };
      default:
        return state;
    }
  } 