//import {Link} from 'react-router-dom';

export const COLUMNS = [
    {  
        Header: 'Name of Securities',  
        accessor: 'Name of Securities'
    },
    {
        Header: 'CMP',
        accessor: 'CMP'
    },
    // {
    //     Header: 'Delta Current Weight(%)',
    //     accessor: 'Current Weight'
    // },
    {
        Header: 'Suggested Amount Allocation',
        accessor: 'Suggested Amount Allocation'
    },
    {
        Header: 'User Action',
        accessor: 'User Action'
    },
    {
        Header: 'Suggested Trade Qty',
        accessor: 'Suggested Trade'
    },
    {
        Header: 'Buy/Sell',
        accessor: 'Buy_Sell'
    },
    {
        Header: 'Price',
        accessor: 'Price'
    },
   
]