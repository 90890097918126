import React, { useContext, useEffect, useState } from "react";
const UserInfoConxtext = React.createContext();
const UserUpdateContext = React.createContext();

export function useUserInfo() {
  return useContext(UserInfoConxtext);
}

export function useUserInfoUpdate() {
  return useContext(UserUpdateContext);
}  

export function UserInfoProvider({ children }) {
  const [ userDetails, setUserDetails ] = useState(JSON.parse(localStorage.getItem('userInfo')));
  function updateUserDetails(newUserDetails) {
    if(newUserDetails) {
        localStorage.setItem('userInfo', JSON.stringify(newUserDetails));
        localStorage.setItem('token', newUserDetails.token);
        setUserDetails(newUserDetails);
    }
  }
  return (
    <UserInfoConxtext.Provider value={userDetails}>
      <UserUpdateContext.Provider value={updateUserDetails}>
        {children}
      </UserUpdateContext.Provider>
    </UserInfoConxtext.Provider>
  );
}
