import { useState, useEffect } from "react";
import ApiServices from "../../ApiService/ApiConfig";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import AccordianComponent from "./../Layout/Table-layout/Accordian";
import Checkbox from "@mui/material/Checkbox";
// import { Formik, Form } from 'formik';

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const AllCustomersReport = (props) => {
  const [reportInfo, setReportInfo] = useState("");
  const [modalData, setModalData] = useState("");
  const [checkeData, setCheckData] = useState(false);
  // const [reportStatus, setReportStatus] = useState();
  const [custData, setCustData] = useState([]);
  const [securitiesNotAvailable, setSecuritiesNotAvailable] = useState([]);
  const [isExpand, setIsExpand] = useState(false);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  let {
    name: model_name,
    resetToken,
    resetValidity,
    trade_advice_code,
    aryData,
  } = useParams();

  resetValidity = resetValidity.replace(/_/g, " ");
  trade_advice_code = trade_advice_code.replace(/\+/g, " ");

  var newRes;

  useEffect(() => {
    LandingPageData();
  }, []);

  const LandingPageData = () => {
    ApiServices.verifyReportData(model_name)
      .then((res) => {
        const modeldatasecu = res.filter(function (dt) {
          return dt.User_Action !== "No" || dt.Actionable === "Yes";
        });
        let modeldatasecuNew = modeldatasecu.map((data) => ({
          model_name: data["model_name"],
          Name_of_Securities: data["Name_of_Securities"],
          Suggested_Trade:
            data["Suggested_Trade"] !== "-"
              ? toIndianCurrency(data["Suggested_Trade"])
              : data["Suggested_Trade"],
          Buy_Sell: data["Buy_Sell"] !== null ? data["Buy_Sell"] : "-",
          Price: data["Price"],
          Actionable: data["Actionable"],
          User_Action:
            data["User_Action"] !== null
              ? data["User_Action"]
              : data["Actionable"],
        }));
        setReportInfo(modeldatasecuNew);
        getDelta();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    LandingPageDataCust();
  }, []);

  const LandingPageDataCust = () => {
    ApiServices.verifyReportDataCustomerSecuritoes(model_name)
      .then((res) => {
        const temp = res.filter((el) => {
          return el.user.customer_status === "Active";
        });
        console.log("temp---------------------------", temp);

        const dataAaray = temp.map((el) => {
          return {
            ...el,
            checked_data: false,
          };
        });
        setCustData(dataAaray);
        dataAaray.forEach((element) => {
          if (dataAaray.length) {
            if (element.customer_portfolio_securities.length === 0) {
              setSecuritiesNotAvailable([...securitiesNotAvailable, "Yes"]);
            }
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDelta = () => {
    const delta = localStorage.getItem("responseData");
  };

  useEffect(() => {
    modalPrevData();
  }, []);

  const modalPrevData = () => {
    ApiServices.getModel(model_name)
      .then((res) => {
        newRes = res;
        setModalData(newRes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toIndianCurrency = (num) => {
    if (num !== undefined) {
      const curr = Number(num).toLocaleString("en-IN", {
        currency: "INR",
      });
      return curr;
    }
  };

  console.log(
    "aryData----------------------",
    aryData,
    trade_advice_code.replace(/\+/g, " ")
  );

  const columns = [
    {
      headerName: "Name of Securities",
      field: "Name_of_Securities",
    },

    {
      headerName: "Suggested Trade Qty",
      field: "Suggested_Trade",
    },

    {
      headerName: "Buy Sell",
      field: "Buy_Sell",
      cellStyle: (params) => {
        if (params.value === "Buy") return { color: "#FF9F1C" };
        if (params.value === "Sell") return { color: "green" };
      },
    },

    {
      headerName: "Price",
      field: "Price",
    },

    {
      headerName: "System Suggested Action",
      field: "Actionable",
    },

    {
      headerName: "User Action",
      field: "User_Action",
    },
  ];

  const custColumns = [
    {
      headerName: "Name of Securities",
      field: "Name_of_Securities",
    },

    {
      headerName: "Suggested Trade Qty",
      field: "Suggested_Trade",
    },

    {
      headerName: "Buy Sell",
      field: "Buy_Sell",
      cellStyle: (params) => {
        if (params.value === "Buy") return { color: "#FF9F1C" };
        if (params.value === "Sell") return { color: "green" };
      },
    },

    {
      headerName: "Price",
      field: "Price",
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  let today = new Date().toISOString().slice(0, 10);

  function changeDateFormat(today) {
    var splitDate = today.split("-");
    if (splitDate.count == 0) {
      return null;
    }

    var year = splitDate[0];
    var month = splitDate[1];
    var day = splitDate[2];

    return day + "\\" + month + "\\" + year;
  }

  var newDate = changeDateFormat(today);

  const SubmitHandler = async (portfolio_name_data) => {
    var token_data = "";
    const response = ApiServices.getPortfolioCustomerReportData(
      portfolio_name_data
    )
      .then(async (res) => {
        token_data = res.resetToken_cust;
        if (res) {
          const destUrl = `${process.env.REACT_APP_URL_API}/customerportfolio/updateRecord/${portfolio_name_data}`;
          const data = await axios({
            method: "post",
            url: destUrl,
            data: {
              status: "Accepted",
              name: model_name,
              portfolio_name: portfolio_name_data,
              resetTokenData: token_data,
              trade_advice_code: trade_advice_code.replace(/\+/g, " "),
            },
          })
            .then((res) => {
              console.log(res);
              // toast.success(
              // 	`response recorded Successfully`
              // );
            })
            .catch((err) => {
              console.log(err);
              toast.warning(`response is recorded already Or Link is Expired`);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelectAll = () => {
    let temp = custData.map((row) => {
      return {
        ...row,
        checked_data: true,
      };
    });
    setCustData(temp);
  };

  const portfoliosAry = [];

  const handleFinalSubmit = () => {
    if (!portfoliosAry.length)
      return alert("please select atleat one customer");
    portfoliosAry.map((data) => {
      return SubmitHandler(data);
    });
    toast.success(`response recorded Successfully`);
  };

  const handleUnCheck = async (index) => {
    const modified = custData.map((row, i) => {
      if (i === index) {
        row.checked_data === true
          ? (row.checked_data = false)
          : (row.checked_data = true);
      }
      return row;
    });
    setCustData(modified);
  };

  return (
    <div className="container" style={{ height: "600px", marginTop: "100px" }}>
      <div
        className="container-fluid text-center"
        style={{
          width: "85%",
          marginTop: 100,
          backgroundColor: "#eff6f9",
        }}
      >
        <h3 className="pt-3 pb-3">All Customers Report</h3>
      </div>

      <div
        className="ag-theme-alpine"
        style={{
          height: "200px",
          width: "900px",
          marginTop: "30px",
          marginLeft: "10%",
        }}
      >
        <p>Trade ID: {newDate}</p>
        <p
          style={{
            display: "inline-block",
          }}
        >
          <span
            style={{
              padding: "4px 6px",
              textTransform: "capitalize",
              border: "2px solid #4895EF",
            }}
          >
            model name
          </span>
          <span
            style={{
              background: "#4895EF",
              border: "2px solid #4895EF",
              color: "white",
              padding: "4px 6px",
              fontWeight: "bold",
            }}
          >
            {model_name}
          </span>
        </p>
        <></>
        <br />

        <button
          onClick={() => handleSelectAll()}
          className="btn btn-outline-success mt-3 mb-3 mr-3"
        >
          Select All
        </button>

        {custData.length ? (
          <button
            id="dvwm-accordian-toggle"
            style={{ marginBottom: "1.5rem" }}
            onClick={() => setIsExpand((prev) => !prev)}
          >
            {isExpand ? "Collapse All" : "Expand All"}
          </button>
        ) : null}

        <button
          onClick={() => handleFinalSubmit()}
          className="btn btn-success mt-3 mb-3 mr-2"
          style={{ float: "right" }}
        >
          Submit
        </button>

        {custData.map((el, index) => {
          let { portfolio_name } = el;
          let { first_name, middle_name, last_name } = el.user;
          const datatable = el.customer_portfolio_securities.filter(function (
            dt
          ) {
            return (
              dt.User_Action !== "No" &&
              dt.Buy_Sell !== "-" &&
              dt.Suggested_Trade !== "0"
              // dt.User_Action !== 'No' ||
              // dt.Buy_Sell === 'Yes' ||
              // dt.Buy_Sell === 'No'
            );
          });
          let datatableNew = datatable.map((data) => ({
            portfolio_name: data["portfolio_name"],
            Name_of_Securities: data["Name_of_Securities"],
            No_of_Shares:
              data["No_of_Shares"] === 0
                ? 0
                : toIndianCurrency(data["No_of_Shares"]),
            Suggested_Trade:
              data["Suggested_Trade"] === "-"
                ? 0
                : toIndianCurrency(data["Suggested_Trade"]),
            User_Action: data["User_Action"],
            Buy_Sell: data["Buy_Sell"],
            Price: data["Price"],
          }));
          return (
            <div key={index}>
              {datatable && (
                <>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={1}>
                        <Checkbox
                          {...label}
                          color="success"
                          checked={el.checked_data}
                          onClick={() => handleUnCheck(index)}
                        />
                        {el.checked_data && portfoliosAry.push(portfolio_name)}
                      </Grid>
                      <Grid item xs={11}>
                        <AccordianComponent
                          key={index}
                          title={
                            <b>
                              {`${first_name} ${middle_name} ${last_name}`}
                              <span
                                style={{
                                  marginLeft: "12px",
                                  color: "gray",
                                }}
                              >{`(${portfolio_name})`}</span>
                            </b>
                          }
                          isExpand={isExpand}
                          content={
                            <div
                              style={{
                                height: "200px",
                                width: "71%",
                                margin: "1px auto 1px",
                              }}
                            >
                              <AgGridReact
                                rowData={datatableNew}
                                columnDefs={custColumns}
                                defaultColDef={defaultColDef}
                              />
                            </div>
                          }
                        ></AccordianComponent>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
              <br />
            </div>
          );
        })}
        <br />

        {/* <Formik
					initialValues={{
						acceptTerms: false,
					}}
					onSubmit={SubmitHandler}
				>
					{() => (
						<div>
							<Form>
								<div
									className="form-group"
									style={{ height: 100 }}
								>
									{(custData.length > 0 && custData.length !== securitiesNotAvailable.length) && 
										<center>
											<button
												id="firstInput"
												type="submit"
												className="btn btn-success mb-3 mr-2"
												onClick={() =>
													setReportStatus('Accepted')
												}
											>
												Accept
											</button>
											<button
												id="secondInput"
												type="submit"
												className="btn btn-danger mb-3 mr-2"
												onClick={() =>
													setReportStatus('Rejected')
												}
											>
												Reject
											</button>
										</center>
										}
								</div>
							</Form>
						</div>
					)}
				</Formik> */}
      </div>
    </div>
  );
};

export default AllCustomersReport;
