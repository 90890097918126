import React, { useEffect, useState } from "react";

//MUI imports for model
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

//import UserService from "../services/user.service";
import ApiServices from "../../ApiService/ApiConfig";
import ModelListTable from "../Layout/Table-layout/ModelListTable";
//import tableData from './json-data/customerTableData.json';
import { COLUMNS } from "../Layout/Table-layout/Columns/columns-cust-portfolio";
import "../UserCustRedressalStyle.css";
import * as moment from "moment";
import { toast } from "react-toastify";

const CustList = (props) => {
  console.log("props-history", props.history);
  const [tableData, settableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [del, setDel] = useState();
  const [delData, setDelData] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    modelData();
    console.log("list model");
  }, [del]);

  const modelData = () => {
    ApiServices.customerPortfolioList()
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        let modified = res.map((element) => {
          var momentDate = moment(element["date"]);
          return {
            ...element,
            date: momentDate.format("MM-DD-YYYY hh:mm:ss A "),
          };
        });

        settableData(modified);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  if (!tableData) return null;

  const onDelete = (data) => {
    console.log(props.rowId, "-----------", data);
    setDelData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    //console.log("delData", delData);
    //return;
    ApiServices.deleteCustomerPortfolio(delData)
      .then((res) => {
        console.log(res.message, "history", props.history);
        setIsLoading(false);
        props.history.push("/customerportfolio");
        setDel(Math.random());
        toast.success(`${res.message}`);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
    handleClose();
  };

  return (
    <div id="style_main">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure want to delete this Customer Portfolio ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Click on delete button to delete this Customer Portfolio OR click on
            cancel button to cancel deletion.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <span style={{ color: "green" }}>Cancel</span>
          </Button>
          <Button onClick={() => handleDelete()} autoFocus>
            <span style={{ color: "red" }}>Delete</span>
          </Button>
        </DialogActions>
      </Dialog>
      <center>
        {/* <span>
 <button className="btn btn-success" >Add Customer +</button>
 <span> </span>
 <button className="btn btn-success" >Update Customer </button>
 </span> */}
      </center>
      <div className="shadow-none mb-3 rounded" style={{ textAlign: "center" }}>
        <h3
          style={{
            marginTop: "110px",
            width: "100%",
            backgroundColor: "#f5f3f3",
            fontFamily: "sans-serif",
          }}
          className="pt-2 pb-2"
        >
          Customer Portfolio List
        </h3>
      </div>
      <ModelListTable
        tableData={tableData}
        COLUMNS={COLUMNS}
        type="CustomerPortfolio"
        urlAdd="/addcustomerportfolio"
        urlActionEdit="/updatecustomerportfolio/"
        urlActionView="/viewmodel/"
        onDelete={onDelete}
        isLoading={isLoading}
      />
    </div>
  );
};

export default CustList;
