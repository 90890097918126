import { useState, useEffect } from "react";
import ApiServices from "../../ApiService/ApiConfig";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import AccordianComponent from "./../Layout/Table-layout/Accordian";

import { Formik, Field, Form, ErrorMessage } from "formik";

import * as Yup from "yup";

const PopUpModelTable = (props) => {
  const [reportInfo, setReportInfo] = useState("");
  const [modalData, setModalData] = useState("");
  const [reportStatus, setReportStatus] = useState("Verified");
  const [custData, setCustData] = useState([]);
  const [isExpand, setIsExpand] = useState(false);

  const {
    name: model_name,
    token: token,
    resetValidity: resetValidity,
  } = useParams();

  var newRes;

  useEffect(() => {
    LandingPageData();
  }, []);

  const LandingPageData = () => {
    ApiServices.verifyReportData(model_name)
      .then((res) => {
        const a = res.filter(function (dt) {
          return dt.User_Action === "Yes";
        });
        const modeldatasecu = a.filter(function (dt) {
          return (
            dt.User_Action === "Yes" ||
            !dt.Name_of_Securities.toLowerCase().includes("cash")
          );
        });
        setReportInfo(modeldatasecu);
        getDelta();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    LandingPageDataCust();
  }, []);

  const LandingPageDataCust = () => {
    ApiServices.verifyReportDataCustomerSecuritoes(model_name)
      .then((res) => {
        const dataAaray = res.filter((el) => {
          return el.user.customer_status === "Active";
        });
        console.log("dataary---------------------------", dataAaray);
        setCustData(dataAaray);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // for testing
  const getDelta = () => {
    localStorage.getItem("responseData");
  };

  useEffect(() => {
    modalPrevData();
  }, []);

  const modalPrevData = () => {
    ApiServices.getModel(model_name)
      .then((res) => {
        newRes = res;
        setModalData(newRes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      headerName: "Name of Securities",
      field: "Name_of_Securities",
    },

    {
      headerName: "Suggested Trade Qty",
      field: "Suggested_Trade",
    },

    {
      headerName: "Buy Sell",
      field: "Buy_Sell",
      cellStyle: (params) => {
        if (params.value === "Buy") return { color: "#FF9F1C" };
        if (params.value === "Sell") return { color: "green" };
      },
    },

    {
      headerName: "Price",
      field: "Price",
    },

    {
      headerName: "System Suggested Action",
      field: "Actionable",
    },

    {
      headerName: "User Action",
      field: "User_Action",
    },
  ];

  const custColumns = [
    {
      headerName: "Name of Securities",
      field: "Name_of_Securities",
    },

    {
      headerName: "Suggested Trade Qty",
      field: "Suggested_Trade",
    },

    {
      headerName: "Buy Sell",
      field: "Buy_Sell",
      cellStyle: (params) => {
        if (params.value === "Buy") return { color: "#FF9F1C" };
        if (params.value === "Sell") return { color: "green" };
      },
    },

    {
      headerName: "Price",
      field: "Price",
    },

    {
      headerName: "User Action",
      field: "User_Action",
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  let today = new Date().toISOString().slice(0, 10);

  function changeDateFormat(today) {
    // expects Y-m-d
    var splitDate = today.split("-");
    if (splitDate.count == 0) {
      return null;
    }

    var year = splitDate[0];
    var month = splitDate[1];
    var day = splitDate[2];

    return day + "\\" + month + "\\" + year;
  }

  var newDate = changeDateFormat(today);

  const SubmitHandler = async () => {
    const destUrl = `${process.env.REACT_APP_URL_API}/model/updateRecord/${model_name}`;
    const data = await axios({
      method: "post",
      url: destUrl,
      data: {
        status: reportStatus,
      },
    }).then((res) => {
      if (res.data.message !== undefined) {
        toast.warning(`${res.data.message}`, { delay: 5000 });
      }
    });
    toast.success(`Response Recorded Successfully`);
    props.history.push("/thank");
  };

  return (
    <div className="container" style={{ height: "600px", marginTop: "80px" }}>
      <div
        className="container-fluid text-center"
        style={{ backgroundColor: "#eff6f9" }}
      >
        <h3 className="pt-3 pb-3">Trade Report</h3>
      </div>
      <div
        className="ag-theme-alpine"
        style={{
          height: "200px",
          width: "900px",
          marginTop: "30px",
        }}
      >
        <p>Trade ID: {newDate}</p>
        <p
          style={{
            display: "inline-block",
          }}
        >
          <span
            style={{
              padding: "4px 6px",
              textTransform: "capitalize",
              border: "2px solid #4895EF",
              fontWeight: "bold",
            }}
          >
            model name
          </span>
          <span
            style={{
              background: "#4895EF",
              border: "2px solid #4895EF",
              color: "white",
              padding: "4px 6px",
              fontWeight: "bold",
            }}
          >
            {model_name}
          </span>
        </p>
        <AgGridReact
          rowData={reportInfo}
          columnDefs={columns}
          defaultColDef={defaultColDef}
        />
        <br />

        {custData.length ? (
          <button
            id="dvwm-accordian-toggle"
            style={{ marginBottom: "1.5rem" }}
            onClick={() => setIsExpand((prev) => !prev)}
          >
            {isExpand ? "Collapse All" : "Expand All"}
          </button>
        ) : null}
        {custData.map((el, index) => {
          let { portfolio_name } = el;
          let { first_name, middle_name, last_name } = el.user;
          const datatable = el.customer_portfolio_securities.filter(function (
            dt
          ) {
            // console.log("datatable------", dt);
            return dt.User_Action !== "No";
          });
          return (
            <div key={index}>
              {datatable.length ? (
                <AccordianComponent
                  title={
                    <b>
                      {`${first_name} ${middle_name} ${last_name}`}
                      <span
                        style={{
                          marginLeft: "12px",
                          color: "gray",
                        }}
                      >{`(${portfolio_name})`}</span>
                    </b>
                  }
                  isExpand={isExpand}
                  content={
                    <div
                      style={{
                        height: "200px",
                        width: "90%",
                        margin: "auto",
                      }}
                    >
                      <AgGridReact
                        rowData={datatable}
                        columnDefs={custColumns}
                        defaultColDef={defaultColDef}
                      />
                    </div>
                  }
                ></AccordianComponent>
              ) : null}
            </div>
          );
        })}
        <br />

        <Formik
          initialValues={{
            acceptTerms: false,
          }}
          validationSchema={Yup.object().shape({
            acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
          })}
          onSubmit={SubmitHandler}
        >
          {() => (
            <div className="container">
              <Form>
                <div className="form-group"></div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PopUpModelTable;
