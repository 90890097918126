import { useState, useEffect } from 'react';
import ApiServices from '../../ApiService/ApiConfig';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { FormGroup, Input } from 'reactstrap';
import { Formik, Form } from 'formik';

const CustomerLandingReportPageDVWM = (props) => {
	const [tableData, setTableData] = useState([]);
	const [withoutMutualFundData, setWithoutMutualFundData] = useState([]);
	const [mutualFundData, setMutualFundData] = useState([]);
	const [remittanceAmt, setRemittanceAmt] = useState();

	console.log('MFD',mutualFundData);

	let {
		name: model_name,
		resetToken:resettoken,
		resetvalidity,
		portfolioName,
		trade_advice_code,
	} = useParams();

	console.log('PARAMS',useParams());

	trade_advice_code = trade_advice_code.replace(/_/g,' ')
	resetvalidity = resetvalidity.replace(/_/g,' ')

	useEffect(() => {
		console.log(resettoken,resetvalidity,trade_advice_code,'YOUR CHANGE');
		LandingPageData();
	}, []);

	const LandingPageData = () => {
		ApiServices.listDummyReportData()
			.then((res) => {
				console.log('res', res);
				res.forEach((element) => {
					if (element.new_reset_token === resettoken) {
						let filtered = element.dummy_model_data.filter(
							(el) => el.portfolio_name === portfolioName && el['User_Action'] === 'Yes' && Number(el['Suggested_Trade'])
						);
						setTableData(filtered);
						let tabDataNew = filtered.map((data) => (
							{
								'Suggested Amount Allocation':data['Suggested_Amount_Allocation'],
								'Name of Securities': data['Name_of_Securities'], 
								'Sub Asset Class': data['Sub_Asset_Class'], 
								'CMP': data['CMP'], 
								'No of Shares': toIndianCurrency(data['No_of_Shares']), 
								'Suggested Trade': data['Suggested_Trade'], 
								'Buy Sell': data['Buy_Sell'], 
								'Price': data['Price']
							}
						))
						let tabDataWithoutMutualFund = tabDataNew.filter(
							(data) =>
								!data['Sub Asset Class']
									.toLowerCase()
									.includes('mutual fund')
						);
						let tabDataWithMutualFund = tabDataNew.filter((data) =>
							data['Sub Asset Class'].toLowerCase().includes('mutual fund')
						);
						const tabDataWithMutualFundNew = tabDataWithMutualFund.map(
							(data) => ({
								'Name of Securities': data['Name of Securities'],
								Amount: toIndianCurrency(Math.ceil(data['Suggested Amount Allocation']/100)*100),
								'Buy Sell': data['Buy Sell'],
							})
						);
						setWithoutMutualFundData(tabDataWithoutMutualFund);
						setMutualFundData(tabDataWithMutualFundNew);
						let newWithMutualFundData = tabDataWithMutualFundNew.filter(
							(ele) => ele['Buy Sell'] === 'Buy'
						);
						let RemittanceAmt = 0;
						newWithMutualFundData.map((element) => {
							RemittanceAmt += Number(element['Amount'].replace(/,/g,""));
						});
						const RoundedRemittanceAmt =
							Math.ceil(RemittanceAmt / 100) * 100;
						setRemittanceAmt(RoundedRemittanceAmt);
					}
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const toIndianCurrency = (num) => {
        if(num !== undefined){
			const curr = Number(num).toLocaleString('en-IN', {
			currency: 'INR'
			});
			return curr;
        }
	 };

	const columns = [
		{
			headerName: 'Name of Securities',
			field: 'Name of Securities',
		},
		{
			headerName: 'Suggested Trade Qty',
			field: 'Suggested Trade',
		},
		{
			headerName: 'Buy Sell',
			field: 'Buy Sell',
		},
		{
			headerName: 'Price',
			field: 'Price',
		},
	];

	const MutualFundColumns = [
		{
			headerName: 'Script Name',
			field: 'Name of Securities',
		},
		{
			headerName: 'Amount',
			field: 'Amount',
		},
		{
			headerName: 'Buy/Sell',
			field: 'Buy Sell',
		},
		// {
		//     headerName: 'Price', field:'Price',
		// },
	];

	const defaultColDef = {
		sortable: true,
		filter: true,
	};

	let today = new Date().toISOString().slice(0, 10);

	function changeDateFormat(today) {
		// expects Y-m-d
		var splitDate = today.split('-');
		if (splitDate.count == 0) {
			return null;
		}

		var year = splitDate[0];
		var month = splitDate[1];
		var day = splitDate[2];

		return day + '\\' + month + '\\' + year;
	}

	var newDate = changeDateFormat(today);

	const SubmitHandler = async () => {
		const dataNew = {
			status: 'Accepted',
			tableData,
			// user_broker_cust_id: id,
			resetToken: resettoken,
			source: 'DVWM',
		};

		const destUrl = `${process.env.REACT_APP_URL_API}/customerportfolio/updatefundsgloballybroker/${portfolioName}`;
		axios({
			method: 'POST',
			url: destUrl,
			data: dataNew,
		})
			.then((res) => {
				console.log('res', res);
				toast.success(`Response Recorded Successfully`);
				props.history.push('/thank');
			})
			.catch((err) => {
				if(err.response.status === 406){
				toast.warning('Response already sent');
				}
			});
	};

	return (
		<div
			className="container"
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<div
				className="container-fluid text-center"
				style={{
					width: '75%',
					marginTop: '100px',
					backgroundColor: '#eff6f9',
				}}
			>
				<h3 className="pt-3 pb-3">Model Report DVWM</h3>
			</div>

				<div
				className="ag-theme-alpine"
				style={{ width: '50%', height: '500px', marginTop: '50px' }}
			>
				<p>Trade ID: {trade_advice_code}</p>
				{/* <h5>Model Name: {model_name}</h5> */}
				<AgGridReact
					rowData={withoutMutualFundData}
					columnDefs={columns}
					defaultColDef={defaultColDef}
				/>
				<br />
				{mutualFundData.length?<AgGridReact
					rowData={mutualFundData}
					columnDefs={MutualFundColumns}
					defaultColDef={defaultColDef}
				/>:null}
				<br />
				{mutualFundData.length?<p>
					Required remittance to MFU account - Rs{' '}
					{toIndianCurrency(remittanceAmt)}
				</p>:null}
				<div style={{ height: 100 }}>
					<button
						id="thirdInput"
						className="btn btn-success mt-3 mb-3 mr-2"
						onClick={SubmitHandler}
					>
						Submit
					</button>
				</div>
			</div>
		</div>
	);
};

export default CustomerLandingReportPageDVWM;
