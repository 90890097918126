import React from 'react';
import {Link} from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import './RedressalFormStatus.css';

const RedressalFormStatus = (props) => {
    return (
        <>
        {/* {props.type === "Grievance" && (<> */}
            {/* <td>
            {props.rowStatus === "Open" ? <p id="status-open">Open</p> :
            props.rowStatus === "Pending" ? <p id="status-pending">Pending</p> :  <p id="status-resolved">Resolved</p>}
            </td>
            <td>{props.rowPriority === "High" ? <p id="rcorners3">High</p> : props.rowPriority === "Moderate" ? <p id="rcorners1">Moderate</p> : <p id="rcorners2">Low</p> }</td> */}
            {/* <td><span id="link-actionview"><Link to={{pathname:`${props.urlActionEdit}`+`${props.rowId}`, state: {data : props.rowData}}} ><i class="fa fa-edit" id="Tooltip" aria-hidden="true" data-tip data-for="Tooltip20"></i>
            <ReactTooltip id="Tooltip20" place="bottom" effect="solid" >Edit Grievance</ReactTooltip>
            </Link></span></td></>)} */}
        </>
    );
}

export default RedressalFormStatus;