import { useState, useEffect } from 'react';
import ApiServices from '../../ApiService/ApiConfig';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { FormGroup, Input } from 'reactstrap';

import { Formik, Form } from 'formik';

const SuperAdminLandingPageDVWM = (props) => {
	const [reportStatus, setReportStatus] = useState('Accepted');
	const [tableData, setTableData] = useState([]);
	const [copy, setCopy] = useState([]);
	// const [reason, setReason] = useState('');
	const [dependency, setDependency] = useState(true);
	const [flag, setFlag] = useState(false);

	let {
		name: model_name,
		resettoken,
		resetvalidity,
		trade_advice_code,
	} = useParams();

	resetvalidity = resetvalidity.replace(/_/g,' ')
	trade_advice_code = trade_advice_code.replace(/_/g,' ')

	useEffect(() => {
		if (copy.length) return setTableData([...copy]);
		LandingPageData();
	}, [dependency]);

	const LandingPageData = () => {
		ApiServices.listDummyReportData()
			.then((res) => {
				let uniqueTradeCode = [];
				let temp = [...res]
				let tabDataNew = [];
				temp.map((element) => {
					if (element.trade_advice_code === trade_advice_code) {
						if(uniqueTradeCode.length < 1){
							uniqueTradeCode.push("Match");
							tabDataNew.push(...element.dummy_model_data);
						}
					}
				});
				setCopy(tabDataNew);
				setTableData(tabDataNew);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const columns = [
		{
			headerName: 'Client Name',
			field: 'client_name',
		},
		{
			headerName: 'Name of Securities',
			field: 'Name_of_Securities',
		},
		{
			headerName: 'Model Current Weight',
			field: 'Model_Current_Weight',
		},

		{
			headerName: 'Portfolio Current Weight',
			field: 'Portfolio_Current_Weight',
		},

		{
			headerName: 'Percentage%',
			field: 'Delta',
		},
		{
			headerName: 'Actionable',
			field: 'Actionable',
		},
		{
			headerName: 'User Action',
			field: 'User_Action',
		},
		{
			headerName: 'Suggested Trade Qty',
			field: 'Suggested_Trade',
		},
		{
			headerName: 'Buy/Sell',
			field: 'Buy_Sell',
		},
		{
			headerName: 'Price',
			field: 'Price',
		},
	];

	const defaultColDef = {
		sortable: true,
		filter: true,
	};

	const SubmitHandler = async () => {
		let filteredTableData = tableData.filter(
			(td) => td.User_Action === 'Yes'
		);
		const destUrl = `${process.env.REACT_APP_URL_API}/model/sendMailCustomerDVWM/${model_name}`;
		const toSend = reportStatus === 'Rejected'
			? { status: reportStatus, resetToken: resettoken, reason: 'no reason', source: "DVWM", model: filteredTableData, trade_advice_code }
			: { status: reportStatus, resetToken: resettoken, source: "DVWM", model: filteredTableData, trade_advice_code };

		await axios({
			method: 'post',
			url: destUrl,
			data: toSend,
		}).then((res) => {
			toast.success(`Response Recorded Successfully`);
			props.history.push('/thank');
			})
			.catch((err) => {
			toast.warning(`${err.response.data}`);
		});
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<div
				className="container-fluid text-center"
				style={{
					width: '75%',
					marginTop: '100px',
					backgroundColor: '#eff6f9',
				}}
			>
				<h3 className="pt-3 pb-3">Model Report</h3>
			</div>

			<div
				className="ag-theme-alpine"
				style={{ width: '75%', height: '500px', marginTop: '50px' }}
			>
				{flag ? (
					<button
						style={{
							float: 'right',
							textTransform: 'capitalize',
							padding: '1px 4px',
							border: '1px solid #006D77',
							background: 'white',
							color: '#006D77',
							borderRadius: '2px',
						}}
						onClick={() => {
							setDependency((prev) => !prev);
							setFlag(false);
							setTableData([]);
						}}
					>
						clear filter
					</button>
				) : null}

				<p>Trade ID: {trade_advice_code}</p>
				<AgGridReact
					rowData={tableData}
					columnDefs={columns}
					defaultColDef={defaultColDef}
					onFilterOpened={() => {
						setFlag(true);
					}}
				/>
				<br />
				<br />
				<Formik initialValues={{}} onSubmit={SubmitHandler}>
					{() => (
						<div>
							<Form>
								<div
									className="form-group"
									style={{ height: 100 }}
								>
									<center>
										<button
											id="firstInput"
											type="submit"
											className="btn btn-success mb-3 mr-2"
											onClick={(e) => {
												setReportStatus('Accepted');
											}}
										>
											Accept
										</button>
										<button
											id="secondInput"
											type='submit'
											className="btn btn-danger mb-3 mr-2"
											onClick={(e) =>
												setReportStatus('Rejected')
											}
										>
											Reject
										</button>
									</center>
								</div>
							</Form>
						</div>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default SuperAdminLandingPageDVWM;
