import {Link} from 'react-router-dom';

const numberWithCommas = (num) => {
    if(num)
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

export const COLUMNS = [
    
    {
        Header: 'Model Name',
        accessor: properties => properties.name,
        Cell: ({ row }) => {
            return(
                <Link to={{pathname: `/updatemodel/${row.original.name}`, state: {data : row.original}}}>{row.original.name}</Link>
            )
        }
    },
    {
        Header: 'Inception Date',
        accessor: properties => properties.date,
        Cell: ({ row }) => {
            const date = new Date(row.original.date).toLocaleDateString('en-IN', {day: 'numeric', month: 'numeric', year: 'numeric'})
            return(
                <p>{date}</p>
            )
        }
    },
    {
        Header: 'Inception Value',
        accessor: properties => numberWithCommas(properties.inception_value)
    },
    {
        Header: 'Created By',
        accessor: properties => properties.createdBy
    },
    {  
        Header: 'Status',
        accessor: properties => properties.status==="Unverified" ? <p style={{color: 'tomato'}}>Unverified</p> : properties.status==="Rejected" ? <p style={{color: 'tomato'}}>Rejected</p> : <p style={{color: 'green'}}>Verified</p>
    }
]