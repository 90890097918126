import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import { useUserInfo } from '../../../ContextProviders/UserInfo';
import { AddNew } from './AddNew';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './GlobalFilter.css';

const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 200,
    bgcolor: 'background.paper',
    border: '1px solid #096dd9',
    borderRadius: '35px',
    boxShadow: 24,
    p: 4,
  };

const GlobalFilter = (props) => {
    const userInfo = useUserInfo();

    var item;
   
    if(userInfo) {
    item = userInfo.role.role_name
    console.log(item);
    }
    const [value, setValue] = useState(props.filter);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(false);
    const handleClose = () => setOpen(false);

    const onChangeHandler = useAsyncDebounce((value) => {
        props.setFilter(value || undefined)
    }, 500);

    const deleteCustomerHandler = () => {
        setOpen(true);
    }

    const handleDelete = () => {
        setOpen(false)
        props.onDeleteCustomer(props.selectedFlatRows);
    }
    console.log("selectedFlatRows", props.selectedFlatRows);
    console.log("disabledButton", props.disabledDeleteButton);

    const PopupWindow = () => {
        return (
            <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
            <div className="container">
              <h4 id="modal-header">Delete Customer</h4>
              <hr />
              <div className="row">
                    <div className="col-sm-12">
                        <h5 id="modal-content">Are you sure?</h5>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-sm-10">
                    </div>
                    <div className="col-sm-2">
                        <Button onClick={handleClose}>Skip</Button>
                    </div>
                </div> */}
                <hr />
                </div>
            <div className="container" id="modal-footer">
                <center><Button onClick={handleDelete} id="btn-yes-style">Yes</Button><Button onClick={handleClose} id="btn-no-style">No</Button></center>
            </div>
            </Box>
          </Modal>
          )
    }

    return (
        <>
        <div className="searchInput">
          <input type="text" placeholder="Search" value={value || ''} onChange={(e) => {
              setValue(e.target.value);
              onChangeHandler(e.target.value);
          }} id="input-pass" />
          <span className="search-icon"><i className="fa fa-search"></i></span>
          {(props.type !== "manage-customers" && props.type !== 'manage-brokers' && props.type !== "manage-users" && props.type !== "Model" && props.type !== "tradeMISTable") && <AddNew type={props.type} urlAdd={props.urlAdd}/>}
          {(props.type === "Model" && (item === 'super-admin' || item === 'admin')) && <AddNew type={props.type} urlAdd={props.urlAdd}/>}
          {props.type === "manage-customers" && <button className="btn btn-outline-danger" id="btn-danger" onClick={deleteCustomerHandler} disabled={props.disabledDeleteButton}>Delete Customer</button>}
          {props.type === "manage-brokers" && <button className="btn btn-outline-danger" id="btn-danger" onClick={deleteCustomerHandler} disabled={props.disabledDeleteButton}>Delete Broker</button>}
          {props.type === "manage-users" && <button className="btn btn-outline-danger" id="btn-danger" onClick={deleteCustomerHandler} disabled={props.disabledDeleteButton}>Delete User</button>}
        </div>
        {handleOpen && <div>{PopupWindow()}</div>}
        </>
        
    );
}

export default GlobalFilter;