
import React from 'react';
import { Route, Redirect } from "react-router-dom";

function PrivateRoute({  ...rest }) {
    const isLoggedIn = !!localStorage.getItem('token');
    return (
        <Route
            {...rest}
            render={({ location }) =>
                !isLoggedIn &&
                    (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: location }
                            }}
                        />
                    )
            }
        />
    );
}

export default PrivateRoute;