import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import '../form.css';
import { Formik } from 'formik';
import ApiServices from '../../ApiService/ApiConfig';

// material-ui icons
import ShortTextIcon from '@material-ui/icons/ShortText';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import LoadingSpinner from '../UI/Loader';
import { Link } from 'react-router-dom';

const AddCustomer = (props) => {
	const [riskStatus, setRiskStatus] = useState('Incomplete');
	const [isLoading, setIsLoading] = useState(false);
	const [middleName, setMiddleName] = useState('');
	const [mobileNumber, setMobileNumber] = useState();
	const [password, setPassword] = useState();
	const [email, setEmail] = useState();

	const addCustomerForm = () => (
		<div>
			<Formik
				initialValues={{
					firstName: '',
					lastName: '',
				}}
				validate={(values) => {
					const errors = {};
					// firstname
					if (!values.firstName) {
						errors.firstName = 'Required';
					} else if (!/^[A-Za-z]+/i.test(values.firstName)) {
						errors.firstName = 'Field contain only letters';
					}
					
					// lastname
					if (!values.lastName) {
						errors.lastName = 'Required';
					} else if (!/^[A-Za-z]+/i.test(values.lastName)) {
						errors.lastName = 'Field contain only letters';
					}
					
					return errors;
				}}
				onSubmit={(values, { setSubmitting }) => {
					setSubmitting(false);
					console.log(values);
					setIsLoading(true);

					ApiServices.addCustomer({
						first_name: values.firstName,
						middle_name: middleName,
						last_name: values.lastName,
						status: 'Incomplete',
						score: 'Not Yet Complete',
						phone: mobileNumber,
						email: email,
						password: password,
						// "broker_id": values.brokerCode,
						// "broker_name": values.brokerName,
						role_id: 3,
					})
						.then((res) => {
							setIsLoading(false);
							if (res.code === 'P2002') {
								toast.warning(res.message);
							} else {
								props.history.push('/customer');
								toast.success(
									`Customer Created Successfully..`
								);
							}
						})
						.catch((err) => {
							setIsLoading(false);
							toast.warning(`${err}`);
						});
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					/* and other goodies */
				}) => (
					<form onSubmit={handleSubmit} className="mt-3">
						<div className="row">
							<div className="col-6">
								<div className="form-group mb-3">
									<label className="form-label required">
										<ShortTextIcon /> First Name
									</label>
									<input
										type="text"
										name="firstName"
										min="2"
										className="form-control"
										placeholder="e.g. : Akshay"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.firstName}
										required
									/>
									<p style={{ color: 'red' }}>
										{errors.firstName}
									</p>
									<p style={{ color: 'red' }}>
										{touched.firstName}
									</p>
								</div>
							</div>
							<div className="col-6">
								<div className="form-group mb-3">
									<label>
										<ShortTextIcon /> Middle name
									</label>
									<input
										type="text"
										name="middleName"
										min="2"
										className="form-control"
										placeholder="e.g. : Mukesh"
										// pattern="[A-Za-z]{2,15}"
										// title="MiddleName should only contain letters.and only have single word e.g. john"
										value={middleName}
										onChange={(e) =>
											setMiddleName(e.target.value)
										}
										// onChange={handleChange}
										// onBlur={handleBlur}
									/>
									{/* <p style={{color: 'red'}}>{errors.middleName}</p>
          <p style={{color: 'red'}}>{touched.middleName}</p> */}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<div className="form-group mb-3">
									<label className="form-label required">
										<ShortTextIcon /> Last name
									</label>
									<input
										type="text"
										name="lastName"
										min="2"
										className="form-control"
										placeholder="e.g. : Sharma"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.lastName}
										required
									/>
									<p style={{ color: 'red' }}>
										{errors.lastName}
									</p>
									<p style={{ color: 'red' }}>
										{touched.lastName}
									</p>
								</div>
							</div>
							<div className="col-6">
								<div className="form-group mb-3">
									<label>
										<PhoneIphoneIcon /> Mobile Number
									</label>
									<input
										type="tel"
										name="mobileNumber"
										maxLength="10"
										className="form-control"
										placeholder="e.g. : 9878654532"
										onChange={(e) =>
											setMobileNumber(e.target.value)
										}
										// onBlur={handleBlur}
										value={mobileNumber}
										// required
									/>
									{/* <p style={{color: 'red'}}>{errors.mobileNumber}</p>
          <p style={{color: 'red'}}>{touched.mobileNumber}</p> */}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<div className="form-group mb-3">
									<label>
										<VpnKeyIcon /> Password
									</label>
									<input
										type="password"
										name="password"
										min="6"
										max="10"
										onChange={(e) =>
											setPassword(e.target.value)
										}
										// onBlur={handleBlur}
										value={password}
										className="form-control"
										placeholder="e.g. : Abc!@#"
										// required
									/>
									{/* <p style={{color: 'red'}}>{errors.password}</p>
          <p style={{color: 'red'}}>{touched.password}</p> */}
								</div>
							</div>
							<div className="col-6">
								<div className="form-group mb-3">
									<label>
										<EmailOutlinedIcon /> Email address
									</label>
									<input
										type="email"
										name="email"
										className="form-control"
										placeholder="e.g. : akshay23@gmail.com"
										onChange={(e) =>
											setEmail(e.target.value)
										}
										// onBlur={handleBlur}
										value={email}
									/>
								
								</div>
							</div>
						</div>
					
						<Button
							className="btn btn-success mt-3 mb-3"
							type="submit"
							style={{ width: '40%', marginLeft: '132px' }}
						>
							{isLoading && (
								<LoadingSpinner isLoading={isLoading} />
							)}
							Submit
						</Button>
					</form>
				)}
			</Formik>
		</div>
	);

	return (
		<>
			<div className="container" id="adjust-top">
				<Breadcrumb>
					<BreadcrumbItem>
						<Link to={{pathname: `/customer`}}>Customers</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>Add Customer</BreadcrumbItem>
				</Breadcrumb>
				<div className="container-fluid bg-light text-center">
					<h3 className="pl-3 pr-3 pt-3 pb-3">Add Customer</h3>
				</div>

				<div className="container">
					<div className="row">
						<div className="col-md-6 offset-md-3">
							{addCustomerForm()}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddCustomer;