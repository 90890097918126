import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import { Fragment } from "react";
import GlobalFilter from "./GlobalFilter";
import "./MainTable.css";
import Checkbox from "./Checkbox";
// import { AddNew } from './AddNew';
import { Tooltip } from "reactstrap";
import CellCustUser from "./CellCustUser";
// import CustStatus from './CustStatus';
import RedressalFormStatus from "./RedressalFormStatus";
import { AddNew } from "./AddNew";
import { AddTableRow } from "./AddTableRow";
import { TableRow } from "@material-ui/core";
import Form from "react-bootstrap/Form";

const ModelTable = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  // const [rows, setRows] = useState(props.tableData);
  const [open, setOpen] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [disable, setDisable] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const columns = useMemo(() => props.COLUMNS, [props.COLUMNS]);
  const data = useMemo(() => props.tableData, [props.tableData]);
  const [rows, setRows] = useState();
  const [userAction, setUserAction] = useState();
  const [idForUserAction, setIdForUserAction] = useState();

  console.log("rows", rows);
  useEffect(() => {
    setRows(data);
  }, [isEdit]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    selectedFlatRows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns: ["ISIN Number", "NSE Symbol"] },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
    // (hooks) => {
    //     hooks.visibleColumns.push((columns) => {
    //         return [
    //             {
    //                 id: 'selection',
    //                 Header: ({ getToggleAllRowsSelectedProps }) => (
    //                     <Checkbox {...getToggleAllRowsSelectedProps()} />
    //                 ),
    //                 Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
    //             },
    //             ...columns
    //         ]
    //     })
    // }
  );
  const { globalFilter, pageIndex, pageSize } = state;

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const handleAdd = () => {
    setRows([
      ...rows,
      {
        id: rows.length + 1,
        initial_name: "",
        securities: "",
        CMP: "",
        isin_no: "",
      },
    ]);
  };

  const handleEdit = (i) => {
    // If edit mode is true setEdit will
    // set it to false and vice versa
    setDisable(false);
    setEdit(!isEdit);
  };

  // Function to handle save
  const handleSave = () => {
    setEdit(!isEdit);
    setRows(rows);
    console.log("saved : ", rows);
    setDisable(true);
    setOpen(true);
  };

  const handleInputChange = (e, key, index) => {
    console.log(e.target.value, "======", key, "=======", index);
    setDisable(false);
    const { name, value } = e.target;
    const list = [...rows];
    list[index][key] = value;
    console.log("list", list);
    setRows(list);
  };

  const handleConfirm = () => {
    setShowConfirm(true);
  };

  const handleRemoveClick = (i) => {
    const list = [...rows];
    list.splice(i, 1);
    setRows(list);
    setShowConfirm(false);
  };

  const handleNo = () => {
    setShowConfirm(false);
  };

  const numberWithCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const toIndianCurrency = (num) => {
    if (num !== "-") {
      const curr = Number(num).toLocaleString("en-IN", {
        currency: "INR",
      });
      return curr;
    } else {
      return "-";
    }
  };

  // const onChangeUserAction = (e, isin_number) => {
  //     console.log(e.target.value, "==", isin_number);
  //     setUserAction(e.target.value);
  //     setIdForUserAction(isin_number);
  //     props.onSaveActionData(e.target.value, isin_number)
  // }

  return (
    <Fragment>
      <div id="style-adjust" style={{ overflowX: "scroll" }}>
        <Table {...getTableProps()} hover>
          <thead className="set-background">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header") === "Buy/Sell" ? (
                      <p>
                        <span>Buy</span>/<span>Sell</span>
                      </p>
                    ) : (
                      column.render("Header")
                    )}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
                {props.type === "Grievance" && (
                  <th>
                    <p id="widthAction">Action</p>
                  </th>
                )}
                {(props.type === "Customer" || props.type === "Users") && (
                  <th>Action</th>
                )}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return props.type === "addPortfolio" ||
                props.type === "updateModelPortfolioTable" ? (
                <>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                </>
              ) : (
                <tr
                  style={
                    props.type === "DVWM" &&
                    row.original["Actionable"] === "Yes"
                      ? {
                          background: "rgb(243 128 116 / 22%)",
                        }
                      : {}
                  }
                >
                  {props.type === "DVWM" && (
                    <td>{row.original["client name"]}</td>
                  )}
                  <td style={{ display: "none" }}>
                    {row.original["ISIN Number"]}
                  </td>
                  {props.type === "ViewPortfolioTable" ||
                  props.type === "modelTableInCustPortfolio" ||
                  props.type === "SeperateAddFundsTable" ||
                  props.type === "add-model-table" ||
                  props.type === "modelTable" ||
                  props.type === "ViewPortfolioTable" ? (
                    <td>
                      <input
                        style={{
                          width: "200px",
                          border: "none",
                          paddingRight: "5px",
                        }}
                        type="text"
                        placeholder="e.g. : 80"
                        value={row.original["Name of Securities"]}
                        onChange={(e) =>
                          props.handleSecurityChange(
                            e.target.value,
                            row.original["ISIN Number"],
                            row.original["Name of Securities"]
                          )
                        }
                        disabled={
                          row.original["Name of Securities"] == "Cash" ||
                          props.buttonDisable
                        }
                      />
                    </td>
                   ) : null}
                  {/* <td>{row.original['Asset Class']}</td> */}
                  {/* <td>{row.original['Sub Asset Class']}</td> */}
                  {/* <td>{row.original['Marketcap']}</td> */}
                  <td>
                    {props.type === "ViewPortfolioTable" ||
                    props.type === "modelTableInCustPortfolio" ||
                    props.type === "SeperateAddFundsTable" ||
                    props.type === "DVWM" ? (
                      toIndianCurrency(row.original["CMP"])
                    ) : (
                      <input
                        style={{ width: "60px" }}
                        type="text"
                        placeholder="e.g. : 80"
                        value={row.original["CMP"]}
                        onChange={(e) =>
                          props.handleCMP(
                            e.target.value,
                            row.original["ISIN Number"],
                            row.original["Name of Securities"]
                          )
                        }
                        disabled={
                          row.original["Name of Securities"] == "Cash" ||
                          props.buttonDisable
                        }
                      />
                    )}
                  </td>
                  <td style={{ display: "none" }}>
                    {row.original["NSE Symbol"]}
                  </td>
                  {props.type === "modelTableInCustPortfolio" ||
                  props.type === "SeperateAddFundsTable" ? (
                    <td>{row.original["No of Shares"]}</td>
                  ) : props.type === "add-model-table" ||
                    props.type === "modelTable" ||
                    props.type === "ViewPortfolioTable" ? (
                    <td>
                      <input
                        style={{ width: "60px" }}
                        type="text"
                        placeholder="e.g. : 80"
                        value={toIndianCurrency(row.original["No of Shares"])}
                        onChange={(e) =>
                          props.handleShares(
                            e.target.value,
                            row.original["ISIN Number"],
                            row.original["Name of Securities"]
                          )
                        }
                        disabled={
                          row.original["Name of Securities"] == "Cash" ||
                          props.buttonDisable
                        }
                      />
                    </td>
                  ) : null}
                  <td>
                    {props.type === "ViewPortfolioTable" ||
                    props.type === "modelTableInCustPortfolio" ||
                    props.type === "SeperateAddFundsTable" ||
                    props.type === "DVWM" ? (
                      row.original["Prescribed Weight"]
                    ) : (
                      <input
                        style={{ width: "60px" }}
                        type="text"
                        placeholder="e.g. : 2.5"
                        value={row.original["Prescribed Weight"]}
                        onChange={(e) =>
                          props.handlePrescribedWeight(
                            e.target.value,
                            row.original["ISIN Number"],
                            row.original["Name of Securities"]
                          )
                        }
                        disabled={
                          row.original["Name of Securities"] == "Cash" ||
                          props.buttonDisable
                        }
                      />
                    )}
                  </td>
                  {props.type === "ViewPortfolioTable" ||
                  props.type === "modelTableInCustPortfolio" ||
                  props.type === "SeperateAddFundsTable" ||
                  props.type === "add-model-table" ||
                  props.type === "modelTable" ||
                  props.type === "ViewPortfolioTable" ? (
                    <td>{toIndianCurrency(row.original["Current Value"])}</td>
                  ) : null}
                  {props.type === "DVWM" ? (
                    <>
                      <td>{row.original["Model Current Weight"]}</td>
                      <td>{row.original["Portfolio Current Weight"]}</td>
                    </>
                  ) : (
                    <td>{row.original["Current Weight"]}</td>
                  )}
                  <td>{row.original["Delta"]}</td>
                  {props.type === "ViewPortfolioTable" ||
                  props.type === "modelTableInCustPortfolio" ||
                  props.type === "SeperateAddFundsTable" ? (
                    <td>{row.original["Ratio Start Pt"]}</td>
                  ) : props.type === "add-model-table" ||
                    props.type === "modelTable" ? (
                    <td>
                      <input
                        style={{ width: "60px" }}
                        type="number"
                        min={0}
                        max={row.original["Ratio End Pt"]}
                        placeholder="e.g. : 80"
                        value={row.original["Ratio Start Pt"]}
                        onChange={(e) =>
                          props.handleRatioStartPt(
                            e.target.value,
                            row.original["ISIN Number"],
                            row.original["Name of Securities"]
                          )
                        }
                        disabled={
                          row.original["Name of Securities"] == "Cash" ||
                          props.buttonDisable
                        }
                      />
                    </td>
                  ) : null}
                  {props.type === "ViewPortfolioTable" ||
                  props.type === "modelTableInCustPortfolio" ||
                  props.type === "SeperateAddFundsTable" ? (
                    <td>{row.original["Ratio End Pt"]}</td>
                  ) : props.type === "add-model-table" ||
                    props.type === "modelTable" ? (
                    <td>
                      <input
                        style={{ width: "60px" }}
                        type="number"
                        min={row.original["Ratio Start Pt"]}
                        max={200}
                        placeholder="e.g. : 80"
                        value={row.original["Ratio End Pt"]}
                        onChange={(e) =>
                          props.handleRatioEndPt(
                            e.target.value,
                            row.original["ISIN Number"],
                            row.original["Name of Securities"]
                          )
                        }
                        disabled={
                          row.original["Name of Securities"] == "Cash" ||
                          props.buttonDisable
                        }
                      />
                    </td>
                  ) : null}
                  <td>{row.original["Actionable"]}</td>
                  {props.type === "modelTable"
                    ? row.original["Name of Securities"] !== "Cash" && (
                        <td>
                          <select
                            value={row.original["User Action"]}
                            onChange={(e) =>
                              props.onSaveActionData(
                                e.target.value,
                                row.original["ISIN Number"],
                                row.original["Name of Securities"]
                              )
                            }
                            disabled={false}
                          >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </td>
                      )
                    : props.type === "DVWM"
                    ? row.original["Name of Securities"] !== "Cash" && (
                        <td>
                          <select
                            value={row.original["User Action"]}
                            onChange={(e) =>
                              props.onSaveActionData(
                                e.target.value,
                                row.original["client name"],
                                row.original["ISIN Number"],
                                row.original["Name of Securities"]
                              )
                            }
                            disabled={false}
                          >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </td>
                      )
                    : null}
                  {(props.type === "modelTable" || props.type === "DVWM") &&
                    row.original["Name of Securities"] === "Cash" && (
                      <td>
                        <select
                          value={row.original["User Action"]}
                          onChange={(e) =>
                            props.onSaveActionData(
                              e.target.value,
                              row.original["ISIN Number"],
                              row.original["Name of Securities"]
                            )
                          }
                          disabled={false}
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </td>
                    )}
                  {(props.type === "ViewPortfolioTable" ||
                    props.type === "SeperateAddFundsTable" ||
                    props.type === "previewReport") && (
                    <td>{row.original["User Action"]}</td>
                  )}
                  {(props.type === "modelTable" ||
                    props.type === "SeperateAddFundsTable" ||
                    props.type === "previewReport" ||
                    props.type === "DVWM") && (
                    <td>
                      {row.original["User Action"] === "Yes"
                        ? toIndianCurrency(row.original["Suggested Trade"])
                        : "-"}
                    </td>
                  )}
                  {/* {(props.type === 'DVWM') && (
										<td>
											{row.original['User Action'] ===
											'Yes'
												? toIndianCurrency(row.original[
														'Suggested Amount Allocation'
												  ])
												: '-'}
										</td>
									)} */}
                  {props.type === "ViewPortfolioTable" && (
                    <td>
                      {row.original["User Action"] === "Yes"
                        ? toIndianCurrency(row.original["Suggested Trade"])
                        : "-"}
                    </td>
                  )}
                  {(props.type === "modelTable" ||
                    props.type === "ViewPortfolioTable" ||
                    props.type === "SeperateAddFundsTable" ||
                    props.type === "DVWM") && (
                    <td>
                      {row.original["User Action"] === "Yes" ? (
                        <p
                          style={
                            row.original["Buy Sell"] === "Buy"
                              ? {
                                  fontWeight: "bolder",
                                  fontSize: "15px",
                                  color: "var(--toastify-color-info)",
                                }
                              : {
                                  fontWeight: "bolder",
                                  fontSize: "16px",
                                  color: "green",
                                }
                          }
                        >
                          {row.original["Buy Sell"]}
                        </p>
                      ) : (
                        "-"
                      )}
                    </td>
                  )}
                  {props.type === "add-model-table" && (
                    <td>{row.original["Suggested Trade"]}</td>
                  )}
                  {props.type === "add-model-table" && (
                    <td>
                      {row.original["Actionable"] === "Yes"
                        ? row.original["Buy Sell"]
                        : "-"}
                    </td>
                  )}
                  {(props.type === "modelTable" || props.type === "DVWM") &&
                    (row.original["User Action"] === "Yes" ? (
                      typeof row.original["Price"] !== "object" &&
                      row.original["Suggested Trade"] !== 0 ? (
                        <td>
                          <input
                            style={{
                              width: "60px",
                            }}
                            type="text"
                            placeholder="e.g. : 80"
                            value={row.original["Price"]}
                            onChange={(e) =>
                              props.handlePrice(
                                e.target.value,
                                row.original["ISIN Number"],
                                row.original["Name of Securities"],
                                row.original["client name"]
                              )
                            }
                            disabled={
                              row.original["Name of Securities"] == "Cash" ||
                              props.buttonDisable
                            }
                          />{" "}
                        </td>
                      ) : typeof row.original["Price"] === "object" &&
                        row.original["Suggested Trade"] !== 0 ? (
                        <td>
                          <input
                            style={{
                              width: "60px",
                            }}
                            type="text"
                            placeholder="e.g. : 80"
                            value={row.original["Price"]["main"]}
                            onChange={(e) =>
                              props.handlePrice(
                                e.target.value,
                                row.original["ISIN Number"],
                                row.original["Name of Securities"],
                                row.original["client name"]
                              )
                            }
                            disabled={
                              row.original["Name of Securities"] == "Cash" ||
                              props.buttonDisable
                            }
                          />
                          {row.original["Price"]["flag"] ? (
                            <span
                              style={{
                                fontSize: "9px",
                                color: "red",
                              }}
                            >
                              {row.original["Price"]["flag"]}
                            </span>
                          ) : null}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : (
                      <td>-</td>
                    ))}
                  {(props.type === "ViewPortfolioTable" ||
                    props.type === "SeperateAddFundsTable" ||
                    props.type === "previewReport") &&
                    (row.original["User Action"] === "Yes" ? (
                      <td>{row.original["Price"]}</td>
                    ) : (
                      <td>-</td>
                    ))}
                  {/* <td>{row.original['Updated On']}</td>
									<td>{row.original['updatedBy']}</td> */}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div id="style-adjust-footer">
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span style={{ paddingLeft: "10px" }}>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageNumber = e.target.value
                ? Number(e.target.value) - 1
                : 0;
              gotoPage(pageNumber);
            }}
            style={{ width: "35px", height: "35px" }}
          />
        </span>
        <span style={{ paddingLeft: "10px" }}>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            style={{ width: "9em", height: "35px" }}
          >
            {[5, 25, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize} Records
              </option>
            ))}
          </select>
        </span>
        {/* <span className="pagination-style">
                    <button className={`btn-actions ${!canPreviousPage ? 'disabled' : ''}`} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {'<<'}
                    </button>
                    <button className={`btn-style ${!canPreviousPage ? 'disabled' : ''}`} onClick={(e) => previousPage()} disabled={!canPreviousPage}>Previous</button>
                    <button className={`btn-style ${!canNextPage ? 'disabled' : ''}`} onClick={(e) => nextPage()} disabled={!canNextPage}>Next</button>
                    <button className={`btn-actions ${!canNextPage ? 'disabled' : ''}`} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {'>>'}
                    </button></span> */}
      </div>
      {/* <pre>
            <code>
                {JSON.stringify(
                    {
                        selectedFlatRows: selectedFlatRows.map((row) => row.original)
                    },
                    null,
                    2
                )}
            </code>
        </pre> */}
    </Fragment>
  );
};

export default ModelTable;
