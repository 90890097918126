import { SET_MESSAGE, CLEAR_MESSAGE } from "./index";

export const setMessage = (message) => (
  console.log("messae",message),{
  
  type: SET_MESSAGE,
  payload: message,
});

export const clearMessage = () => ({
  type: CLEAR_MESSAGE,
});