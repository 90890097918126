  
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const LOGOUT = "LOGOUT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const ASSETS_REQUEST = "ASSETS_REQUEST";
export const ASSET_RECEIVED =  "ASSET_RECEIVED";

export const ADD_ASSET =  "ADD_ASSET";
export const DELETE_ASSET = "DELETE_ASSET"
export const UPDATE_ASSET = "UPDATE_ASSET"
export const ASSET_REQUEST = "ASSET_REQUEST";

export const SUB_ASSETS_REQUEST = "SUB_ASSETS_REQUEST";
export const SUB_ASSET_RECEIVED =  "SUB_ASSET_RECEIVED";

export const ADD_SUB_ASSET =  "ADD_SUB_ASSET";
export const DELETE_SUB_ASSET = "DELETE_SUB_ASSET"
export const EDIT_SUB_ASSET = "EDIT_SUB_ASSET"


