import React, { useEffect, useState } from 'react';
import ApiServices from '../../ApiService/ApiConfig';
import { COLUMNS } from './Table-layout/Columns/columns-manage-customers';
import MainTable from './Table-layout/MainTable';
import { toast } from "react-toastify";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';

const ManageCustomers = (props) => {
    const [tableData, settableData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [del, setDel] = useState();
    
    useEffect(() => {
        customerdata();
    }, [del])

    const customerdata = () => {
        ApiServices.listCustomerWithStatus()
          .then((res) => {
            setIsLoading(false);
            settableData(res);
          }).catch((err) => {
            setIsLoading(false);
            console.log(err);
          })
    }

    if (!tableData) return null;

    console.log('state table d',tableData);
    const onDeleteCustomer = (data) => {
      console.log("dataaaaaa", data);
      ApiServices.deleteCustomer(data)
      .then(res => {
        console.log(res.message, "history", props.history);
        setIsLoading(false);
        props.history.push("/managecustomers");
        setDel(Math.random());
        if(res.message === undefined){
        toast.error(
          `${res.message1}`
        );
      }else if(res.message1 === undefined){
        toast.success(
          `${res.message}`
        );
      }else{
        toast.success(
          `${res.message}`
        );
        toast.error(
          `${res.message1}`
        );
      }
        }).catch((err) => {
          setIsLoading(false);
        console.log(err);
        })
    }

    const onUpdateCustomerStatus = (id, data) => {
      ApiServices.updateCustomerStatus(id, data)
      .then(res => {
        console.log(res.message, "history", props.history);
        setIsLoading(false);
        props.history.push("/managecustomers");
        setDel(Math.random());
        toast.success(
          `${res.message}`
        );
        }).catch((err) => {
          setIsLoading(false);
        console.log(err);
        })
    }

    console.log(tableData, 'tableDDAATTAA');
    return (
        <div id="style_main">
          <Breadcrumb>
            <BreadcrumbItem><Link to={{pathname: `/profile`}}>Settings</Link></BreadcrumbItem>
            <BreadcrumbItem active>Manage Customers </BreadcrumbItem>
          </Breadcrumb>
           <MainTable tableData={tableData} COLUMNS={COLUMNS} type="manage-customers" urlAdd="/adduser" urlActionEdit="/updateuser/" urlActionView="/viewuser/" isLoading={isLoading} onDeleteCustomer={onDeleteCustomer} onUpdateCustomerStatus={onUpdateCustomerStatus} /> 
        </div>
    );
}

export default ManageCustomers;