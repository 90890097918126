import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { auth } from "./firebase";
import { toast } from "react-toastify";

import ApiServices from "../../ApiService/ApiConfig";

const NewPasswordForm = ({ history, props }) => {
  const { token } = useParams();
  console.log("token", token);
  const [email, setEmail] = useState("");  
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    setEmail(window.localStorage.getItem("emailForRegistration"));
    setUserDetails(window.localStorage.getItem("userDet"));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if((newPassword && confirmPassword) && (newPassword === confirmPassword) && (newPassword.length > 6 && confirmPassword.length < 12) ){
    
      ApiServices.ResetPassword(token, {
        "password": confirmPassword
      }).then(res => {
        console.log(res);
        if(res.message === "Password Reset Done.."){
          toast.success(
            `${res.message}`
          );
        }else{
          toast.warning(
            `${res.message}`
          );  
        }
        history.push("/");
      })
      .catch((err) => {
        console.log(err);
      })
      } else {
        toast.warning(
          `New Password and Confirm Password must be same And Have atlist 6 characters`
        );
      }
  };

  const NewPasswordFormForm = () => (
    <form onSubmit={handleSubmit}>
      <input type="hidden" className="form-control" value={email} disabled />

      <div className="form-group mb-1">
        <label className="form-label required">New Password</label>
        <input
          type="password"
          min="6"
          className="form-control"
          placeholder="e.g. : Abc!@#"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
      </div>

      <div className="form-group mb-1">
        <label className="form-label required">Confirm Password</label>
        <input
          type="password"
          min="6"
          className="form-control"
          placeholder="e.g. : Abc!@#"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
      </div>
   
      <button type="submit"  className="btn btn-primary mt-3 mb-3" style={{width: '40%', marginLeft: '1px'}}>
        Reset Password</button>
    </form>
  );

  return (
    <div className="container p-5" style={{marginTop: "50px"}}>
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <h4>Complete Password Reset</h4>
          {NewPasswordFormForm()}
        </div>
      </div>
    </div>
  );
};

export default NewPasswordForm;
