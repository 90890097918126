import React, {PropTypes} from 'react';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";

import CheckButton from "react-validation/build/button";
import { connect } from 'react-redux';
// import { Link, browserHistory } from 'react-router';
import {postAssetMaster} from '../../actions/assetActions';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
}

class AssetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      asset_name: ''
    };

    this.handleAssetNameChange = this.handleAssetNameChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleAssetNameChange(event) {
    this.setState({asset_name: event.target.value});
  }

  handleSubmit() {
    if (this.state.asset_name === '') {
      alert('Asset Name cannot be null!');
    } else {
      this.props.postAssetMaster(this.state);
      this.props.history.push('/assets');
    }
  }

  render() {
    return (
      <Form
      onSubmit={this.handleSubmit}
      ref={(c) => {
        this.form = c;
      }}
    >
      <div className="form-group">
              <label htmlFor="username">Asset Name</label>
              <Input
                type="text"
                className="form-control"
                name="asset_name"
                value={this.state.asset_name}
                onChange={this.handleAssetNameChange}
                validations={[required]}
              />
            </div>
            <div className="form-group">
              <button
                className="btn btn-primary btn-block"
                disabled={this.state.loading}
              >
                {/* {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )} */}
                <span>Save Asset</span>
              </button>
            </div>
            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtn = c;
              }}
            />
      </Form>

    );
  }
}


const mapDispatchToProps = dispatch => ({
  postAssetMaster: assetName => dispatch(postAssetMaster(assetName))
});


export default connect(null, mapDispatchToProps)(AssetForm);
