import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Button, FormGroup, Label, CustomInput, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import './form.css';
import { Formik } from 'formik';
import ApiServices from "../ApiService/ApiConfig";

// material-ui icons
import ShortTextIcon from '@material-ui/icons/ShortText';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import LoadingSpinner from "../components/UI/Loader";
import { Link } from "react-router-dom";
 
const UpdateProfile = (props) => {

  // imported data
  console.log("state-data",props.location.state.data);
  const Cust_Data = props.location.state.data;
  // const fname = Cust_Data.employee_name.substr(0,Cust_Data.employee_name.indexOf(' '));
  // const lname = Cust_Data.employee_name.substr(Cust_Data.employee_name.indexOf(' ')+1);
  const user_id = Cust_Data.id;
  console.log("user_id", user_id);
  const fname = Cust_Data.first_name;
  const mname = Cust_Data.middle_name;
  const lname = Cust_Data.last_name;
  const emailId = Cust_Data.email;
  const phone = Cust_Data.phone;
  const designationE = Cust_Data.designation;
  const user_role = Cust_Data.role_id;
  console.log("user_role", user_role);
  // states
  const [whatsAppNum, setWhatsAppNum] = useState(phone);
  const [role, setRole] = useState(user_role);
  const [isLoading, setIsLoading] = useState(false);

  const updateUserForm = () => (
    <div>
      <Formik
      initialValues={{ firstName: fname, middleName: mname, 
                       lastName: lname, mobileNumber: phone,
                      //  password: '', 
                      //  role: [{admin: 2}, {superAdmin: 1}],
                       email: emailId, designation: designationE}}
      validate={values => {
        const errors = {};
         // firstname
        if (!values.firstName) {
          errors.firstName = 'Required';
        } else if (
          !/^[A-Za-z]+/i.test(values.firstName)
         
        ) {
          errors.firstName = 'Field contain only letters';
        }
        // middlename
        if (!values.middleName) {
          errors.middleName = 'Required';
        } else if (
          !/^[A-Za-z]+/i.test(values.middleName)
         
        ) {
          errors.middleName = 'Field contain only letters';
        }
        // lastname
        if (!values.lastName) {
          errors.lastName = 'Required';
        } else if (
          !/^[A-Za-z]+/i.test(values.lastName)
         
        ) {
          errors.lastName = 'Field contain only letters';
        }
        // mobilenumber
        if (!values.mobileNumber) {
          errors.mobileNumber = 'Required';
        } else if (
          !/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
          .test(values.mobileNumber)
         
        ) {
          errors.mobileNumber = 'Number contain Ten Digits only';
        }
        // email
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        // designation
        if (!values.designation) {
          errors.designation = 'Required';
        } else if (
          !/^[A-Za-z]+/i.test(values.designation)
         
        ) {
          errors.designation = 'Designation contain only letters ';
        }
        // role
        // if (!values.role) {
        //   errors.role = 'Required';
        // } 
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        console.log(values, role);
        // console.log(role);

        setIsLoading(true);
      ApiServices.updateUser(user_id,{
      "first_name": values.firstName,
      "middle_name": values.middleName,
      "last_name": values.lastName,
      "phone": values.mobileNumber,
      "email": values.email,
      "designation": values.designation,
      "role_id": parseInt(role),
    })
    .then(res => {
      console.log(res.data);
      setIsLoading(false);
      props.history.push("/user");
      toast.success(
        `User Updated Successful.`
      );
    }).catch((err) => {
      console.log(err);
      if (err.message === "Request failed with status code 406"){
        toast.warning(
          `Duplicate entries please enter unique inputs.`
        );
       } else {
        toast.warning(
            `${err}`
          ); 
       }
      setIsLoading(false);
    })

      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
   
<form onSubmit={handleSubmit} className="mt-3">
<div className="row">
<div className="col-6">
<div className="form-group mb-3">
  <label className="form-label"><ShortTextIcon/> First Name</label>
          <input
            type="text"
            name="firstName"
            min="2"
            className="form-control"
            placeholder="e.g. : Akshay"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            required
          />
          <p style={{color: 'red'}}>{errors.firstName}</p>
          <p style={{color: 'red'}}>{touched.firstName}</p>
</div>
</div>
<div className="col-6">
<div className="form-group mb-3">
  <label className="form-label"><ShortTextIcon/> Middle name</label>
          <input
            type="text"
            name="middleName"
            min="2"
            className="form-control"
            placeholder="e.g. : Mukesh"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.middleName}
            required
          />
          <p style={{color: 'red'}}>{errors.middleName}</p>
          <p style={{color: 'red'}}>{touched.middleName}</p>
</div>
</div>
</div>
<div className="row">
<div className="col-6">
<div className="form-group mb-3">
  <label className="form-label"><ShortTextIcon/> Last name</label>
           <input
            type="text"
            name="lastName"
            min="2"
            className="form-control"
            placeholder="e.g. : Sharma"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            required
          />
          <p style={{color: 'red'}}>{errors.lastName}</p>
          <p style={{color: 'red'}}>{touched.lastName}</p>
  </div>
  </div>
  <div className="col-6">
  <div className="form-group mb-3">
  <label className="form-label"><PhoneIphoneIcon/> Mobile Number</label>
          <input
            type="tel"
            name="mobileNumber"
            maxLength="10"
            className="form-control"
            placeholder="e.g. : 9878654532"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.mobileNumber}
            required
          />
          <p style={{color: 'red'}}>{errors.mobileNumber}</p>
          <p style={{color: 'red'}}>{touched.mobileNumber}</p>
</div>
</div>
</div>
<div className="row">
<div className="col-6">
<div className="form-group mb-3">
  <label className="form-label"><ShortTextIcon/> Designation</label>
          <input
            type="text"
            name="designation"
            min="2"
            className="form-control"
            placeholder="Enter Designation For Users"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.designation}
            required
          />
          <p style={{color: 'red'}}>{errors.designation}</p>
          <p style={{color: 'red'}}>{touched.designation}</p>
</div>
</div>
<div className="col-6">
<div>
<FormGroup onChange={(e) => setRole(e.target.value)}>
  <Label><i class="fas fa-user-tie"></i> Role</Label>
  <CustomInput type="select" value={role}>
    <option value="2" name={role}>
      Admin</option>
    <option value="1" name={role}>
      Super Admin</option>
  </CustomInput>
</FormGroup>
</div>
</div>
</div>
<div className="row">
<div className="col-6">
<div className="form-group mb-3">
  <label className="form-label"><EmailOutlinedIcon/> Email address</label>
          <input
            type="email"
            name="email"
            className="form-control"
            placeholder="e.g. : akshay23@gmail.com"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          <p style={{color: 'red'}}>{errors.email}</p>
          <p style={{color: 'red'}}>{touched.email}</p>
</div>
</div>
<div className="col-6">
<div className="form-group mb-3">
  <label className="form-label"><EmailOutlinedIcon/> WhatsApp Number</label>
  <input
    type="tel"
    maxLength="10"
    className="form-control"
    placeholder="e.g. : 9878654532"
    pattern="[0-9]{10,10}"
    title="WhatsApp Number should only have Digites. e.g. 9878654532 And should have length of Ten Digits"
    value={whatsAppNum}
    name={whatsAppNum}
    onChange={(e) => setWhatsAppNum(e.target.value)}
  />
</div>
</div>

</div>
<button className="btn btn-success mt-3 mb-3" type="submit" style={{width: '35%', marginLeft: '142px'}}>
{isLoading && <LoadingSpinner isLoading={isLoading} />}Submit</button>
</form>

        )}
      </Formik>
    </div>
  );

  return (
    <>
    <div className="container" id="adjust-top">
      <Breadcrumb>
        <BreadcrumbItem><Link to={{pathname: `/profile`}}>Settings</Link></BreadcrumbItem>
        <BreadcrumbItem active>Update Profile</BreadcrumbItem>
      </Breadcrumb>
      <div className="container-fluid bg-light text-center">
        <h3 className="pl-3 pr-3 pt-3 pb-3">Update Profile</h3>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">{updateUserForm()}</div>
        </div>
      </div>
    </div>
    </>
  );
};

export default UpdateProfile;

