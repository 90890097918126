import React, { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print'; 
// import ComponentToPrintNew from './ComponentToPrintNew';
import ComponentToPrint from './ComponentToPrint';
import PrintImg from './print.jpg';

const Pcomp = (props) => {

  const [showText, setShowText] = useState(false);

  const { CustData, passPortPhoto } = props

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    // console.log(componentRef.current);

    return (
        <div>
          {/* <button onClick={() => setShowText(!showText)}>Toggle Text</button> */}
          <button style={{ marginLeft: "620px" }} onClick={handlePrint}><img src={PrintImg} width="20px" height="20px" /></button>
          <div style={{ display: "none" }}>
            {showText &&  <ComponentToPrint passPortPhoto={passPortPhoto} CustData={CustData} ref={componentRef} />}
          {/* <ComponentToPrintNew passPortPhoto={passPortPhoto} CustData={CustData} ref={componentRef} /> */}
          </div>
          
        </div>
    )
}

export default Pcomp
