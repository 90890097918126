// import React from 'react'
// import TestTable from './TestTable'

// const ModalTestExample = () => {
//   return (
//     <div>
//       <div style={{marginLeft: '500px'}}>
//         <TestTable/>
//       </div>
//     </div>
//   )
// }

// export default ModalTestExample











// import { useState } from 'react';
// import axios from 'axios';

// const ModalTestExample = (props) => {
  
//   const [file, setFile] = useState()
//   const [upFile, setUpFile] = useState()

//    const uploadFileHandler = async (e) => {
//     const file = e.target.files[0];
//      const bodyFormData = new FormData();
//      bodyFormData.append('image', file)
//      try{
//        const {data} = await axios.post('http://localhost:8080/file/create', bodyFormData, {
//          headers: { "Content-Type": "multipart/form-data",
//         }
//        })
//        console.log(data.res);
//        setUpFile(`http://localhost:8080/${data.res}`)
//       //  setUpFile(data);
//      }catch(error) {
//       console.log(error);
//      }
//    }
  
//    const handleClick = async (e) => {
//      e.preventDefault()
//      console.log('clicked');
//      try{
//       const res = await axios.get('http://localhost:8082/file/list/:id')
//       // console.log(res.data);
//       // setImg(res.data);
//       const data = res.data
//       console.log(data[0].path);
//       const newData = data[0].path
//       setFile(`http://localhost:8082/${newData}`);
  
//     }catch(error) {
//      console.log(error);
//     }
//    }

//   return (
//     <div>

//       <form style={{marginLeft: '100px'}} enctype="multipart/form-data">
//         <input type="file" id="imageFile" onChange={uploadFileHandler}/>
//         <img src={upFile} width="100px" height="100px" />
//         {/* <img src={upFile} width="50px" height="50px" /> */}
//         <br/>
//         <button type="submit">Submit</button>
//       </form>
//       <br/>
//       <form style={{marginLeft: '100px'}} onSubmit={handleClick}>
//         <img src={file} width="100px" height="100px" />
//         <button type="submit" >Submit</button>
//       </form><br/><br/>
     

//     </div>
//   )
// }

// export default ModalTestExample














 import * as React from 'react';
 import Box from '@mui/material/Box';
 import Button from '@mui/material/Button';
 import Typography from '@mui/material/Typography';
 import Modal from '@mui/material/Modal';

 const style = {
   position: 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   width: 400,
   bgcolor: 'background.paper',
   border: '2px solid #000',
   boxShadow: 24,
   p: 4,
 };

 export default function BasicModal() {
   const [open, setOpen] = React.useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   return (
     <div>
       <Button onClick={handleOpen}>Open modal</Button>
       <Modal
         open={open}
         onClose={handleClose}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
       >
         <Box sx={style}>
          
         </Box>
       </Modal>
     </div>
   );
 }
