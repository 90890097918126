import React, { useState, useEffect } from 'react';
import AddSubAsset from './modal-add-subasset';
import ApiServices from '../../../ApiService/ApiConfig';

function ManageSubAssets() {
	const [flag, setFlag] = useState(false);
	const [list, setList] = useState([]);
	const [dependency, setDependency] = useState('');

	const deleteHandler = async (id) => {
		let response = await ApiServices.DeleteSubAssetFromList(id);
		setDependency(response);
	};

	useEffect(() => {
		console.log('USE-EFFECT');
		ApiServices.GetSubAssetList().then((res) => {
			setList(res);
		});
	}, [dependency]);
	return (
		<>
			<div className="horizontal-row">
				<div className="dropdown">
					<button className="dropbtn">
						Manage Sub Asset Classes
					</button>
					<div className="dropdown-content">
						{list.reverse().map((element) => {
							return (
								<div
									key={element.id}
									className="list-element"
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									<div>{element.asset}</div>
									<i
										className="fa fa-trash"
										style={{
											color: '#9B2226',
											fontSize: '18px',
											cursor: 'pointer',
										}}
										onClick={() => {
											deleteHandler(element.id);
										}}
									></i>
								</div>
							);
						})}
					</div>
				</div>
				<button
					className="manage-classes"
					onClick={() => {
						setFlag(true);
					}}
				>
					Add More
				</button>
			</div>
			{flag ? (
				<div className="add-subasset-modal-div">
					<AddSubAsset
						handleModel={setFlag}
						setDependency={setDependency}
						list={list}
					/>
				</div>
			) : null}
		</>
	);
}
export default ManageSubAssets;
