import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "http://localhost:8080/api/asset/";

  export const getAssetMaster = () => {
    return axios.get(API_URL + "getAllAsset", { headers: authHeader() });
  }

  export const getSingleAssetMaster = assetId => 
  {
    console.log("getSingleAssetMaster",assetId)
    return axios.get(API_URL + "getAssetMaster/"+assetId, { headers: authHeader() });
  }

  export const saveAssetMaster = (data) => {
    console.log("a=======================",data.assetName)
    return axios.post(API_URL + "save", data.assetName, { headers: authHeader() });
  }

  // export const editAsset = (assetId) => {
  //   return axios.put(API_URL+ "", assetId, {headers:authHeader()})
  // }

  export const deleteAsset = (assetId) => {
    console.log("service asset",assetId.assetId)
    return axios.delete(API_URL+ "delete/"+assetId.assetId,{headers: authHeader()})
  }


  export const updateAsset = (updatedAssetMaster, assetId) => 
  {
    return axios.put(API_URL+"update/"+assetId, updatedAssetMaster ,{headers: authHeader()})
  }


