import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ApiServices from '../ApiService/ApiConfig';
//import tableData from './json-data/userTableData.json';
import { COLUMNS } from './Layout/Table-layout/Columns/columns-user';
import MainTable from './Layout/Table-layout/MainTable';


const BoardUser = (props) => {
    const [tableData, settableData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        userdata();
    }, [])

    const userdata = () => {
        ApiServices.listUser()
          .then((res) => {
            console.log("result",res);
              setIsLoading(false);
            settableData(res);
          }).catch((err) => {
            setIsLoading(false);
            console.log(err);
          })
    }

    if (!tableData) return null;

    console.log('state table d',tableData);
    return (
        <div id="style_main">
           <div className="shadow-none mb-3 rounded" style={{textAlign: "center"}}>
            <h3 style={{ marginTop: "110px", width: "100%", backgroundColor: '#f5f3f3', fontFamily: 'sans-serif'}} className="pt-2 pb-2">User List</h3>
           </div>
           <MainTable tableData={tableData} COLUMNS={COLUMNS} type="Users" urlAdd="/adduser" urlActionEdit="/updateuser/" urlActionView="/viewuser/" isLoading={isLoading} /> 
        </div>
    );
}

export default BoardUser;