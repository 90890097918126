import React from "react";
import { UserInfoProvider } from "./ContextProviders/UserInfo";
import MainRouter from "./components/MainRouter";

//



import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  return (
    <UserInfoProvider>
      <MainRouter />
    </UserInfoProvider>
  )
}

export default App;