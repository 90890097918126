import React, { useEffect, useState } from 'react';
// import ApiServices from '../ApiService/ApiConfig';
import MainTable from "../../components/Layout/Table-layout/MainTable";
import { COLUMNS } from './columns-securities';
import '../../components/UserCustRedressalStyle.css';

const  SecurityList = (props) => {
  const [tableData, settableData] = useState([]);
    
    // useEffect(() => {
    //     customerdata();
    // }, [])

    // const customerdata = () => {
    //     ApiServices.listCustomer()
    //       .then((res) => {
    //         settableData(res);
    //       }).catch((err) => {
    //         console.log(err);
    //       })
    // }

    // if (!tableData) return null;

    return (
        <div id="style_main">
        <center>
        </center>
        <MainTable tableData={tableData} COLUMNS={COLUMNS} type="Security" urlAdd="/addcustomer" urlActionEdit="/updatecustomer/" urlActionView="/viewcustomer/" urlRiskProfile="/riskprofile/" />
      </div>
    );
}

export default SecurityList;