import React, { useEffect, useState } from 'react';
import ShortTextIcon from '@material-ui/icons/ShortText';
import Form from 'react-bootstrap/Form';
import { CustomInput } from 'reactstrap';
import ApiServices from '../../ApiService/ApiConfig';
import ModelTable from '../Layout/Table-layout/ModelTable';
import { columns } from './columns';
import AccordianComponent from './../Layout/Table-layout/Accordian';
import { toast } from 'react-toastify';
import { AgGridReact } from 'ag-grid-react';
import ReactTooltip from 'react-tooltip';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './main.css';

const defaultColDef = {
	sortable: true,
	filter: true,
};

const columnsSAC = [
	{
		headerName: 'Customer Name',
		field: 'name',
	},
	{
		headerName: 'TotalPCW',
		field: 'totalPCW',
	},
	{
		headerName: 'TotalMCW',
		field: 'totalMCW',
	},
	{
		headerName: 'Ratio(PCW/MCW)',
		field: 'ratio',
		cellStyle: (params) => {
			if (params.value <= 97.5 || params.value >= 102.5)
				return {
					color: 'red',
					border: '1px solid red',
				};
		},
	},
];

function MainComponent() {
	const [modelDropdownData, setModelDropdownData] = useState([]);
	const [type, setType] = useState('');
	const [tableData, setTableData] = useState([]);
	const [securitiesList, setSecuritiesList] = useState([]);
	const [modelData, setModelData] = useState([]);
	const [dataSAC, setDataSAC] = useState([]);
	const [totalVal, setTotalVal] = useState([]);
	const [SACList, setSACList] = useState([]);
	const [thresholdSecurities, setThresholdSecurities] = useState();
	const [thresholdSubAssetClass, setThresholdSubAssetClass] = useState();
	const [modelSelection, setModelSelection] = useState(false);
	const [isExpand_one, setIsExpandOne] = useState(false);
	const [isExpand_two, setIsExpandTwo] = useState(false);

	useEffect(() => {
		ApiServices.listStatusVerifiedModel().then((res) => {
			setModelDropdownData(res);
		});
		ApiServices.GetSubAssetList().then((res) => {
			let modified = res.map((el) => el.asset);
			setSACList(modified);
		});
	}, []);

	const getTableData = (prop, thresholdSec, thresholdSubAsset) => {
		setType(prop);

		if (prop === '') {
			setDataSAC([]);
			setSecuritiesList([]);
			setModelSelection(false);
			setThresholdSubAssetClass();
			setThresholdSecurities();
			return;
		}else{
			setModelSelection(true);
			}

		ApiServices.getModel(prop)
			.then((res) => {
				let CustomerPortfolioData = [];
				let CustomerNames = [];
				let totalArr = [];
				let totalModel = 0;
				res.model_securities.forEach((element) => {
					totalModel += element['No of Shares'] * Number(element['CMP']);
				})
				setModelData(res.model_securities);
				res.customer_portfolio.forEach((item) => {
					let { first_name, middle_name, last_name } = item.user;
					let customer_name = `${first_name} ${middle_name} ${last_name}`;
					CustomerNames.push(customer_name);
					let model = [];
					let total = 0;
					item.customer_portfolio_securities.forEach((ele) => {
						total += Number(
							ele['No_of_Shares'] * Number(ele['CMP'])
						);
					});
					totalArr.push({"client name": customer_name, "total": total});
					item.customer_portfolio_securities.forEach((ele) => {
						let newObj = {};
						let modelElement = res.model_securities.find(
							(element) =>
								element['ISIN Number'] === ele['ISIN_Number'] &&
								element['Name of Securities'] ===
									ele['Name_of_Securities']
						);
						newObj['id'] = ele['id'];
						newObj['client name'] =
							item.user.first_name +
							' ' +
							item.user.middle_name +
							' ' +
							item.user.last_name;
						newObj['ISIN Number'] = ele['ISIN_Number'];
						newObj['Name of Securities'] =
							ele['Name_of_Securities'];
						newObj['Asset Class'] = ele['Asset_Class'];
						newObj['Sub Asset Class'] = ele['Sub_Asset_Class'];
						newObj['CMP'] = String(ele['CMP']);
						newObj['Ratio Start Pt'] = ele['Ratio_Start_Pt'];
						newObj['Ratio End Pt'] = ele['Ratio_End_Pt'];
						newObj['Prescribed Weight'] = String(
							ele['Prescribed_Weight']
						);
						newObj['No of Shares'] = Number(ele['No_of_Shares']);
						newObj['Price'] = ele['Price'];
						newObj['Current Value'] = Number(
							newObj['No of Shares'] * Number(newObj['CMP'])
						);
						newObj['Model Current Weight'] =
							modelElement['Current Weight'];
						// total += newObj['Current Value'];
						model.push(newObj);
					});

					model.map((element) => {
						element['Portfolio_name'] = item.portfolio_name;
						let modelElement = res.model_securities.find(
							(ele) =>
								ele['ISIN Number'] === element['ISIN Number'] &&
								ele['Name of Securities'] ===
									element['Name of Securities']
						);
						let curr_wt = Number(
							((element['No of Shares'] *
								Number(element['CMP'])) /
								total) *
								100
						);
						element['Delta'] = Number(
							(curr_wt / modelElement['Current Weight']) * 100
						);
						let StartPtVal;
						let EndPtVal;
						
						if(thresholdSec === undefined || thresholdSec === '' || thresholdSec > 100){
							StartPtVal = 95
							EndPtVal = 105
						}else{
							StartPtVal = 100 - Number(thresholdSec)
							EndPtVal = 100 + Number(thresholdSec)
						}

						if (
							!element['Name of Securities'].toLowerCase().includes('cash')
						) {
							if (
								element['Delta'] < StartPtVal ||
								element['Delta'] > EndPtVal
							) {
								element['Current Value'] = Number(
									(
										element['No of Shares'] * element['CMP']
									).toFixed(2)
								);
								element['Model Current Weight'] = Number(
									element['Model Current Weight']
								);
								const mC = Number(
									(((modelElement['No of Shares'] * Number(modelElement['CMP']))/totalModel) * 100).toFixed(6)
								);
								const pC = Number((((element['No of Shares'] *
								Number(element['CMP'])) /
								total) *
							100).toFixed(6))
								element['Actionable'] = 'Yes';
								element['User Action'] = 'No';
								if (element['Actionable'] === 'Yes') {
									element['Portfolio Current Weight'] = Number(
										(
											((element['No of Shares'] *
												Number(element['CMP'])) /
												total)*100 
										).toFixed(3)
									);
									element['Delta'] = Number(
										(
											(pC /
												mC) *
											100
										).toFixed(3)
									);
									if(element['Delta'] === Infinity){
										element['Delta'] = 100;
									}
									element['Suggested Trade'] = Math.round(
										Number(
											((pC - mC) * total) /
												100 /
												Number(element['CMP'])
										)
									);
									element['Suggested Amount Allocation'] =
										Number(
											(
												Math.round(
													Number(
														((pC - mC) * total) /
															100 /
															Number(element['CMP'])
													)
												) * Number(element['CMP'])
											).toFixed(3)
										);
									if (
										element['Suggested Trade'] === undefined
									) {
										element['Suggested Trade'] = String(0);
									}
									if (element['Suggested Trade'] > 0) {
										element['Suggested Trade'] = String(
											Math.abs(
												Number(
													element['Suggested Trade']
												)
											)
										);
										element['Suggested Amount Allocation'] =
											Math.abs(element[
												'Suggested Amount Allocation'
											]);
										element['Buy Sell'] = 'Sell';
										element['Price'] = 'Market Price';
									} else if (
										element['Suggested Trade'] === 0
									) {
										element['Suggested Trade'] = String(0);
										element['Suggested Amount Allocation'] =
											element[
												'Suggested Amount Allocation'
											];
										element['Buy Sell'] = '-';
										element['Price'] = '-';
									} else {
										element['Suggested Trade'] = String(
											Math.abs(
												Number(
													element['Suggested Trade']
												)
											)
										);
										element['Suggested Amount Allocation'] =
											Math.abs(element[
												'Suggested Amount Allocation'
											]);
										element['Buy Sell'] = 'Buy';
										element['Price'] = 'Market Price';
									}
								} else {
									element['Suggested Trade'] = '-';
									element['Buy Sell'] = '-';
									element['Price'] = '-';
								}
							} else {
								if(element['No of Shares'] === 0){
									element['Portfolio Current Weight'] = 0;
									element['Delta'] = 0;	
								}else{
									element['Portfolio Current Weight'] = Number((((element['No of Shares'] *
									Number(element['CMP'])) /
									total) *
									100).toFixed(3));
									element['Delta'] = Number(
										element['Delta'].toFixed(1)
									);
								}
								element['Actionable'] = 'No';
								element['User Action'] = element['Actionable'];
							}
						}
					});
					CustomerPortfolioData.push(...model.filter((el) => el.Actionable === 'Yes'));
				});
				setTotalVal(totalArr);

				let ultra = SACList.map((sac) => {
					let count = 0;
					let getPushed = [];
					CustomerNames.forEach((name) => {
						let filtered = CustomerPortfolioData.filter(
							(pd) =>
								pd['client name'] === name &&
								pd['Sub Asset Class'] === sac
						);
						let totalMCW = 0;
						let totalPCW = 0;
						filtered.forEach((el) => {
							totalMCW += el['Model Current Weight'];
							totalPCW += el['Portfolio Current Weight'];
						});
						if(totalMCW !== 0 && totalPCW !== 0){
							let StartPtVal;
							let EndPtVal;
							if(thresholdSubAsset === undefined || thresholdSubAsset === '' || thresholdSubAsset > 100){
								StartPtVal = 97.5
								EndPtVal = 102.5
							}else{
								StartPtVal = 100 - Number(thresholdSubAsset)
								EndPtVal = 100 + Number(thresholdSubAsset)
							}
							
							let ratio = ((totalPCW / totalMCW) * 100).toFixed(3);
							if (ratio >= EndPtVal || ratio <= StartPtVal){
								count += 1;
								getPushed.push({
									name,
									totalMCW: totalMCW.toFixed(3),
									totalPCW: totalPCW.toFixed(3),
									ratio,
								});
							}
						}
					});
					return { sac, count, releventData: [...getPushed] };
				});
				setDataSAC(ultra);
				generateSecuritiesClass(CustomerPortfolioData);
				setTableData(CustomerPortfolioData);
				if (!res.customer_portfolio.length) {
					toast.warning(`No portfolio associated with model ${prop}`);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const generateSecuritiesClass = (data) => {
		let securitiesListLocal = [];
		let checkArray = [];
		setSecuritiesList([]);
		data.forEach((element) => {
			if (!element['Name of Securities'].includes('Cash')) {
				if (!checkArray.includes(element['Name of Securities'])) {
					let count = 0;
					let filtered = data.filter(
						(el) =>
							el['Name of Securities'] ===
							element['Name of Securities']
					);
					filtered.forEach((el) => {
						if (el['Actionable'] === 'Yes') count += 1;
					});

					securitiesListLocal.push({
						sec_name: element['Name of Securities'],
						count,
						checked: count > 0 ? true : false,
					});
					checkArray.push(element['Name of Securities']);
				}
			}
		});
		setSecuritiesList(securitiesListLocal);
	};

	const processPortfolio = (tabDataNew) => {
		let CustomerPortfolioData = [];
		let totalModel = 0;
		modelData.forEach((element) => {
			totalModel += element['No of Shares'] * Number(element['CMP'])
		})
		tabDataNew.forEach((tabData) => {
			let total = 0;
			let modelFilteredWithPercentage = [];
			tabData.map((element) => {
				let objNeww = {};
				let modelElement = modelData.find(
					(ele) =>
						ele['ISIN Number'] === element['ISIN Number'] &&
						ele['Name of Securities'] ===
							element['Name of Securities']
				);
				let IndexOfTotal = totalVal.find((ele) => ele['client name'] === element['client name'])
				total = IndexOfTotal.total;
				objNeww['Delta'] = Number(
					(element['Portfolio Current Weight'] / element['Model Current Weight']) * 100
				);
				if (!element['Name of Securities'].toLowerCase().includes('cash')) {
					objNeww['id'] = element['id'];
					objNeww['client name'] = element['client name'];
					objNeww['Portfolio_name'] = element['Portfolio_name'];
					objNeww['ISIN Number'] = element['ISIN Number'];
					objNeww['Name of Securities'] = element['Name of Securities'];
					objNeww['Asset Class'] = element['Asset Class'];
					objNeww['Sub Asset Class'] = element['Sub Asset Class'];
					objNeww['CMP'] = String(element['CMP']);
					objNeww['Ratio Start Pt'] = element['Ratio Start Pt'];
					objNeww['Ratio End Pt'] = element['Ratio End Pt'];
					objNeww['Prescribed Weight'] = String(
						element['Prescribed Weight']
					);
					objNeww['No of Shares'] = Number(element['No of Shares']);
					objNeww['Price'] = element['Price'];
					objNeww['Current Value'] = Number(
						(element['No of Shares'] * element['CMP']).toFixed(2)
					);
					objNeww['Model Current Weight'] =
						element['Model Current Weight'];
					const mC = Number(((
						(modelElement['No of Shares'] * Number(modelElement['CMP']))/totalModel
					)*100).toFixed(6));
					objNeww['Portfolio Current Weight'] = element['Portfolio Current Weight'];
					const pC = Number((((element['No of Shares'] *
					Number(element['CMP'])) /
					total) *
					100).toFixed(6));
					objNeww['Delta'] = Number(
						(
							(pC /
								mC) *
							100
						).toFixed(1)
					);
					if(objNeww['Delta'] === Infinity){
						objNeww['Delta'] = 100;
					}
					objNeww['Actionable'] = element['Actionable'];
					objNeww['User Action'] = element['User Action'];
					if (objNeww['User Action'] === 'Yes') {
						objNeww['Suggested Trade'] = Math.round(
							Number(
								((pC - mC) * total) /
									100 /
									Number(objNeww['CMP'])
							)
						);
						objNeww['Suggested Amount Allocation'] = Number(
							(
								Math.round(
									Number(
										((pC - mC) * total) /
											100 /
											Number(objNeww['CMP'])
									)
								) * Number(objNeww['CMP'])
							).toFixed(3)
						);
						if (objNeww['Suggested Trade'] === undefined) {
							objNeww['Suggested Trade'] = String(0);
						}
						//Here Buy And Sell has different logic Buy(-) & Sell(+)
						if (objNeww['Suggested Trade'] > 0) {
							objNeww['Suggested Trade'] = String(
								Math.abs(Number(objNeww['Suggested Trade']))
							);
							objNeww['Suggested Amount Allocation'] =
								objNeww['Suggested Amount Allocation'];
							objNeww['Buy Sell'] = 'Sell';
							if(typeof(element['Price']) === 'object'){
								element['Price'] = element['Price'].main;
							}else{
								element['Price'] = element['Price'];
							}
						} else if (objNeww['Suggested Trade'] === 0) {
							objNeww['Suggested Trade'] = String(0);
							objNeww['Suggested Amount Allocation'] =
								objNeww['Suggested Amount Allocation'];
							objNeww['Buy Sell'] = '-';
							objNeww['Price'] = '-';
						} else {
							objNeww['Suggested Trade'] = String(
								Math.abs(objNeww['Suggested Trade'])
							);
							objNeww['Suggested Amount Allocation'] =
								Math.abs(objNeww['Suggested Amount Allocation']);
							objNeww['Buy Sell'] = 'Buy';
							if(typeof(element['Price']) === 'object'){
								element['Price'] = element['Price'].main;
							}else{
								element['Price'] = element['Price'];
							}
						}
					} else {
						objNeww['Suggested Trade'] = '-';
						objNeww['Suggested Amount Allocation'] = 0;
						objNeww['Buy Sell'] = '-';
						objNeww['Price'] = '-';
					}
					modelFilteredWithPercentage.push(objNeww);
				}
				// }
			});
			CustomerPortfolioData.push(...modelFilteredWithPercentage);
			})
			return CustomerPortfolioData;
	};

	const onSaveActionDataHandler = (
		value,
		client_name,
		isin_number,
		nameOfSecurities
	) => {
		let indexNew;
		let spreaded = [...tableData];
		indexNew = tableData.findIndex(
			(element) =>
				element['client name'] === client_name &&
				element['ISIN Number'] === isin_number &&
				element['Name of Securities'] === nameOfSecurities
		);

		if (indexNew !== -1) {
			spreaded[indexNew] = {
			...spreaded[indexNew],
			['User Action']: value,
		};
		   
		if (value === 'Yes') {
			spreaded[indexNew] = {
			...spreaded[indexNew],
			['Price']: 'Market Price',
		};
		}
		} else {
			return null;
		}
		const uniqueClients = [];
		spreaded.forEach(item => {
			if (uniqueClients.indexOf(item['client name']) === -1) {
				uniqueClients.push(item['client name'])
			}
		});
		let MainArray = [];
		uniqueClients.forEach((ele) => {
			let recordByClientName = spreaded.filter((element) => ele === element['client name']);
			MainArray.push(recordByClientName);
			
		})
		let model = processPortfolio(MainArray);
		setTableData(model);
	};

	//vishal gholap (created)
    function calcRange(mainValue, pcent, num) {
        if (isNaN(num)) return false;
       
        var mBelow = (mainValue / 100) * (100 - pcent);
        var mAbove = (mainValue / 100) * (100 + pcent);
       
        if (num >= mBelow && num <= mAbove) return false;
        if (num < mBelow) return 'Below Range';
        return 'Above Range';
        }

	const handlePrice = (val, isinNumber, nameOfSecurities, client_name) => {
		let Spreaded = [...tableData];
        let found = tableData.find(
			(element) =>
				element['client name'] === client_name &&
				element['ISIN Number'] === isinNumber &&
				element['Name of Securities'] === nameOfSecurities
		);
        let flag = calcRange(found['CMP'], 5, val);
        let index = tableData.findIndex(
        (element) =>
		element['client name'] === client_name &&
        element['ISIN Number'] === isinNumber &&
        element['Name of Securities'] === nameOfSecurities
        );
        Spreaded[index]['Price'] = {
        main: val,
        flag,
        };
		const uniqueClients = [];
		Spreaded.forEach(item => {
			if (uniqueClients.indexOf(item['client name']) === -1) {
				uniqueClients.push(item['client name'])
			}
		});
		let MainArray = [];
		uniqueClients.forEach((ele) => {
			let recordByClientName = Spreaded.filter((element) => ele === element['client name']);
			MainArray.push(recordByClientName);
			
		})
        let model = processPortfolio(MainArray);
        setTableData([]);
        model.forEach((element) => {
        setTableData((tableData) => [...tableData, element]);
        });
        };

	const onClickGenerateReportHandler = () => {
		let toSendNew = [];
		securitiesList.forEach((el) => {
			let selective = tableData.filter(
				(rim) => rim['Name of Securities'] === el.sec_name
			);
			selective.forEach((ele) => {
				if (ele.Actionable === 'Yes') {
					const { id, ...newObj } = ele;
					toSendNew.push(newObj);
				}
			});
		});

		toSendNew = toSendNew.map((ele) => {
			if(typeof(ele['Price']) === 'object'){
				return {...ele, ['Price'] : ele['Price'].main}
			}
			return ele;
		})

		const jsonRequestBody = {
			model: toSendNew,
			source: 'DVWM',
		};
		ApiServices.generateReport(type, jsonRequestBody)
			.then((res) => {
				toast.success(`${res.message}`);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<>
		<div id="style_main">
			<div
				className="shadow-none mb-3 rounded"
				style={{ textAlign: 'center' }}
			>
				<h3 id="dvwm-header" className="pt-2 pb-2">
					DVWM
				</h3>
			</div>
			<div className="row">
				<div className="col-sm-3" id="dvwm-select-model">
					<label className="form-label required">
						<ShortTextIcon /> Select Model
					</label>

					<Form.Group>
						<CustomInput
							type="select"
							required
							id={'select model'}
							onChange={(e) => {
								getTableData(e.target.value);
							}}
							value={type}
						>
							<option value="">Select Option</option>
							{modelDropdownData.map((ele, i) => (
								<option value={ele.name} key={i}>
									{ele.name}
								</option>
							))}
						</CustomInput>
					</Form.Group>
				</div>
			</div>
            
			<div className="row">
				<div className="col-sm-12" id="dvwm-header-SAC">
					Sub Asset Classes
				</div>
                {modelSelection && <>
				<div className="col-sm-12" id='threshold-main-container'>
					<div className='row'>
						<div className="col-sm-3">
							<label className="form-label"><ShortTextIcon /> Threshold (in %)</label>
							<Form.Group controlId="threshold">
								<Form.Control
									type="text"
									className="form-control"
									placeholder="Eg: 5% (97.5 to 102.5)"
									value={thresholdSubAssetClass}
									onChange={(e) => {
														setThresholdSubAssetClass(e.target.value);
														getTableData(type, thresholdSecurities, e.target.value);
													}}
									aria-hidden="true" 
									data-tip 
									data-for="Tooltip2"
								/>
								{thresholdSubAssetClass > 100 && <span id='threshold-error'>Value Should be less than 100</span>}
								<ReactTooltip id="Tooltip2" place="bottom" effect="solid" >Input threshold value in %</ReactTooltip>
							</Form.Group>
						</div>
						<div className="col-sm-3">
						<label className="form-label"><ShortTextIcon /> Ratio Start Pt.</label>
						<Form.Group controlId="threshold">
							<Form.Control
								type="text"
								className="form-control"
								placeholder="Eg: 80"
								value={thresholdSubAssetClass === undefined || thresholdSubAssetClass === '' || thresholdSubAssetClass > 100 ? 97.5 : 100 - Number(thresholdSubAssetClass)}
								readOnly
							/>
						</Form.Group>
						</div>
						<div className="col-sm-3">
							<label className="form-label"><ShortTextIcon /> Ratio End Pt.</label>
							<Form.Group controlId="threshold">
								<Form.Control
									type="text"
									className="form-control"
									placeholder="Eg: 125"
									value={thresholdSubAssetClass === undefined || thresholdSubAssetClass === '' || thresholdSubAssetClass > 100 ? 102.5 : 100 + Number(thresholdSubAssetClass)}
									readOnly
								/>
							</Form.Group>
						</div>
					</div>
				</div>
				</>}
				<div className="col-sm-12">
					{securitiesList.length ? (
						<button
							id="dvwm-accordian-toggle"
							onClick={() => setIsExpandOne((prev) => !prev)}
						>
							{isExpand_one
								? 'Collapse All'
								: 'Expand All'}
						</button>
					) : null}
				</div>
			</div>
			<div style={{ marginTop: '20px' }}>
				{dataSAC.map((element, index) => {
					if(element.count) return (
						<AccordianComponent
							key={index}
							title={
								<b>
									{element.sac}{' '}
									<span
										style={{ color: '#99582A' }}
									>{`( ${element.count} )`}</span>
								</b>
							}
							isExpand={isExpand_one}
							content={
								element.releventData.length?<div className="ag-theme-alpine" id="dvwm-ag-grid">
									<AgGridReact
										rowData={element.releventData}
										columnDefs={columnsSAC}
										defaultColDef={defaultColDef}
									/>
								</div>:<></>
							}
						></AccordianComponent>
					)
					else return null
				})}
			</div>

			<div className="row">
				<div className="col-sm-12" id="dvwm-header-securities">
					Securities
				</div>
				{/* <div className="col-sm-12" id="dvwm-accordian-toggle">
					
				</div> */}
				{modelSelection && <>
				<div className="col-sm-12" id='threshold-main-container'>
					<div className='row'>
						<div className="col-sm-3">
							<label className="form-label"><ShortTextIcon /> Threshold (in %)</label>
							<Form.Group controlId="threshold">
								<Form.Control
									type="text"
									className="form-control"
									placeholder="Eg: 5% (95 to 105)"
									value={thresholdSecurities}
									onChange={(e) => {
														setThresholdSecurities(e.target.value);
														getTableData(type, e.target.value, thresholdSubAssetClass);
													}}
									aria-hidden="true" 
									data-tip 
									data-for="Tooltip1"
								/>
								{thresholdSecurities > 100 && <span id='threshold-error'>Value Should be less than 100</span>}
								<ReactTooltip id="Tooltip1" place="bottom" effect="solid" >Input threshold value in %</ReactTooltip>
							</Form.Group>
						</div>
						<div className="col-sm-3">
						<label className="form-label"><ShortTextIcon /> Ratio Start Pt.</label>
						<Form.Group controlId="threshold">
							<Form.Control
								type="text"
								className="form-control"
								placeholder="Eg: 80"
								value={thresholdSecurities === undefined || thresholdSecurities === '' || thresholdSecurities > 100 ? 95 : 100 - Number(thresholdSecurities)}
								readOnly
							/>
						</Form.Group>
						</div>
						<div className="col-sm-3">
							<label className="form-label"><ShortTextIcon /> Ratio End Pt.</label>
							<Form.Group controlId="threshold">
								<Form.Control
									type="text"
									className="form-control"
									placeholder="Eg: 125"
									value={thresholdSecurities === undefined || thresholdSecurities === '' || thresholdSecurities > 100 ? 105 : 100 + Number(thresholdSecurities)}
									readOnly
								/>
							</Form.Group>
						</div>
					</div>
				</div>
				</>}
				<div className="col-sm-12">
					{securitiesList.length ? (
						<button
							id="dvwm-accordian-toggle"
							onClick={() => setIsExpandTwo((prev) => !prev)}
						>
							{isExpand_two
								? 'Collapse All'
								: 'Expand All'}
						</button>
					) : null}
					{securitiesList.length ? (
						<button
							title="Download Update Model as xls"
							className="btn btn-outline-success"
							id="btn-success"
							onClick={onClickGenerateReportHandler}
						>
							Generate Report
						</button>
					) : null}
				</div>
			</div>
			<div style={{ marginTop: '20px' }}>
				<div>
					{securitiesList.map((element, index) => (
						<AccordianComponent
							key={index}
							title={
								<div style={{ display: 'inline-block' }}>
									<div className="dvwm-accordian-text-sec">
										{/* <input
											type="checkbox"
											name={element.sec_name}
											onChange={checkboxHandler}
											checked={element.checked}
											disabled={!Boolean(element.count)}
										/> */}
										<span>
											{element.sec_name}
											<span
												style={{ color: '#99582A' }}
											>{` ( ${element.count} )`}</span>
										</span>
									</div>
								</div>
							}
							isExpand={isExpand_two}
							content={
								<ModelTable
									type="DVWM"
									tableData={tableData.filter(
										(ele) =>
											ele['Name of Securities'] ===
											element.sec_name
									)}
									COLUMNS={columns}
									onSaveActionData={onSaveActionDataHandler}
									handlePrice={handlePrice}
								/>
							}
						></AccordianComponent>
					))}
				</div>
			</div>
		</div>
		</>
	);
}

export default MainComponent;
