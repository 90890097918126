import React, { useEffect, useState } from 'react';
import TradeMIStable from '../../Trade-MIS-And-Reports/Trade-MIS-table/table';
import LoadingSpinner from '../../UI/Loader';
import './Accordian.css';

const AccordianComponent = ({
	title,
	isExpand,
	content,
	AcceptedStatus,
	PendingStatus,
	RejectedStatus,
}) => {
	const [expand, setExpand] = useState(false);

	console.log('PROP', isExpand, 'STATE', expand);

	useEffect(() => {
		setExpand(isExpand);
	}, [isExpand]);

	return (
		<div className="box">
			<div
				className="title-box"
				onClick={() => setExpand((expand) => !expand)}
			>
				<span className="title">{title}</span>
				<span className="span-right">
					{content.props.type === 'tradeMISTableSA' ||
					content.props.type === 'tradeMISTableCustomer' ||
					content.props.type === 'tradeMISTableBroker' ? (
						<>
							<span className="status-accept">
								Accepted({AcceptedStatus})
							</span>
							<span className="status-pending">
								Pending({PendingStatus})
							</span>
						</>
					) : content.props.type === 'tradeMISTableMF' ? (
						<span className="status-pending">Pending({PendingStatus})</span>
					) : null}
					{content.props.type === 'tradeMISTableSA' ? (
						<span className="status-reject">
							Rejected({RejectedStatus})
						</span>
					) : null}
					<span className="icon">
						<i
							className={`fa fa-${!expand ? 'plus' : 'minus'}`}
						></i>
					</span>
				</span>
				<div className="clearfix"></div>
			</div>
			{expand && (
				<div className="content">
					{/* <TradeMIStable tableData={tabData} COLUMNS={content.props.COLUMNS} type={content.props.type} /> */}
					{content}
				</div>
			)}
		</div>
	);
};

export default AccordianComponent;
