import { useEffect, useState } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import "../form.css";
import XLSX from "xlsx";
import { toast } from "react-toastify";

// material-ui icons
import ShortTextIcon from "@material-ui/icons/ShortText";
import TodayIcon from "@material-ui/icons/Today";
import MoneyIcon from "@material-ui/icons/Money";
import RefreshIcon from "@material-ui/icons/Refresh";
import DownloadIcon from "@mui/icons-material/Download";

import Form from "react-bootstrap/Form";

import ModelTable from "../Layout/Table-layout/ModelTable";
import ApiServices from "../../ApiService/ApiConfig";
import { columns } from "../Layout/Table-layout/Columns/columns-add-model";
import { useUserInfo } from "../../ContextProviders/UserInfo";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import * as FileSaver from "file-saver";
import { isInteger } from "formik";
import AccordianComponent from "./../Layout/Table-layout/Accordian";
import { Link } from "react-router-dom";

const AddModel = (props) => {
  const userInfo = useUserInfo();
  const [tableData, settableData] = useState([]);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("Unverified");
  const [thresholdLowerLimit, setThresholdLowerLimit] = useState("");
  const [thresholdUpperLimit, setThresholdUpperLimit] = useState("");
  const [inceptionDate, setInceptionDate] = useState("");
  const [inceptionValue, setInceptionValue] = useState("");
  const [totalCurrentValue, setTotalCurrentValue] = useState();
  const [startDate, setStartDate] = useState(new Date());

  const [disable, setDisable] = useState(true);

  const [model, setModel] = useState([]);

  const [assetList, setAssetList] = useState([]);

  const [fileContents, setFileContents] = useState();
  const [files, setFiles] = useState();
  const [total1, setTotal1] = useState();
  const [ListAssetClass, setListAssetClass] = useState([]);
  const [isExpand_one, setIsExpandOne] = useState(false);
  const [refresh, setRefresh] = useState();

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const fileName = "create model"; // here enter filename for your excel file

  useEffect(() => {
    ListSubAssetClass();
  }, [inceptionValue, refresh]);

  const ListSubAssetClass = () => {
    ApiServices.GetSubAssetList()
      .then((res) => {
        let arr = res.map((data) => data.asset.toLowerCase());
        setListAssetClass(arr);
        console.log("-1", res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let tableRowObj = {
    securities: "abce",
    cmp: null,
    prescribedWeight: null,
    numberOfShares: null,
    currentValue: null,
  };

  const numberWithCommas = (num) => {
    if (num) return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const calculatePrescribedWt = (type, WTtype) => {
    let totalPrescribedWt = 0;
    tableData.map((element) => {
      console.log("112", element["Prescribed Weight"]);
      if (type === element["Asset Class"]) {
        if (WTtype === "Prescribed Wt") {
          totalPrescribedWt += Number(element["Prescribed Weight"]);
        } else {
          totalPrescribedWt += Number(element["Current Weight"]);
        }
      } else if (type.toLowerCase() === "cash") {
        if (WTtype === "Prescribed Wt") {
          totalPrescribedWt = Number(element["Prescribed Weight"]);
        } else {
          totalPrescribedWt = Number(element["Current Weight"]);
        }
      }
      console.log("1100", type, totalPrescribedWt);
    });
    return totalPrescribedWt.toFixed(3);
    console.log("totalPrescribedWt", totalPrescribedWt);
  };

  const calculateTotalPrescribedWt = (WTtype) => {
    let totalPrescribedWt = 0;
    tableData.map((element) => {
      if (WTtype === "Prescribed Wt") {
        totalPrescribedWt += Number(element["Prescribed Weight"]);
      } else {
        totalPrescribedWt += Number(element["Current Weight"]);
      }
    });
    return totalPrescribedWt.toFixed(3);
  };

  const addRow = () => {
    settableData([...tableData, tableRowObj]);
  };

  const calculateTableRow = (id, property) => {
    let g = tableData[id];
    g[property] = tableData[id]["cmp"] * tableData[id]["numberOfShares"];

    settableData([...tableData.slice(0, id), g, ...tableData.slice(id + 1)]);
  };

  const updateTableRow = (id, property, value) => {
    let g = tableData[id];
    g[property] = value;

    settableData([...tableData.slice(0, id), g, ...tableData.slice(id + 1)]);
  };

  const processModel = (tableData) => {
    let model = [];
    let total = null;
    let EndPtLessThanStartPt = [];
    let StartPtLessThanStartPt = [];
    console.log(tableData);
    tableData.forEach((element, index) => {
      let obj = {};

      obj["ISIN Number"] = element["ISIN Number"];

      obj["Name of Securities"] = element["Name of Securities"];

      obj["Asset Class"] = element["Asset Class"];

      obj["Sub Asset Class"] = element["Sub Asset Class"];

      // obj['Marketcap'] = element['Marketcap']

      obj["CMP"] = String(element["CMP"]);

      obj["NSE Symbol"] = element["NSE Symbol"];

      obj["Prescribed Weight"] = String(element["Prescribed Weight"]);

      obj["Ratio Start Pt"] = element["Ratio Start Pt"];

      obj["Ratio End Pt"] = element["Ratio End Pt"];

      obj["No of Shares"] = Number(element["No of Shares"]);

      obj["Price"] = "Market Price";

      obj["Current Value"] = Number(obj["No of Shares"] * obj["CMP"]);

      total += obj["Current Value"];

      model.push(obj);
    });
    setTotalCurrentValue(Number(total.toFixed(3)));
    setTotal1(Number(total.toFixed(3)));
    console.log("total::::::::::::" + total);
    model.map((element) => {
      element["Current Value"] = Number(
        (element["No of Shares"] * element["CMP"]).toFixed(2)
      );
      if (!element["Name of Securities"].toLowerCase().includes("cash")) {
        element["Current Weight"] = Number(
          (
            ((element["No of Shares"] * Number(element["CMP"])) / total) *
            100
          ).toFixed(3)
        );
        element["Delta"] = Number(
          (
            ((((element["No of Shares"] * Number(element["CMP"])) / total) *
              100) /
              element["Prescribed Weight"]) *
            100
          ).toFixed(1)
        );
        if (typeof element["Delta"] === "number" && isNaN(element["Delta"])) {
          element["Delta"] = 0;
        }

        if (
          element["Delta"] < element["Ratio Start Pt"] ||
          element["Delta"] > element["Ratio End Pt"]
        ) {
          element["Actionable"] = "Yes";
        } else {
          element["Actionable"] = "No";
        }

        if (element["Prescribed Weight"] === 0) {
          element["Actionable"] = "Yes";
        }

        if (element["Actionable"] === "Yes") {
          element["Suggested Trade"] = Math.round(
            Math.abs(
              (Number(
                ((element["No of Shares"] * Number(element["CMP"])) / total) *
                  100
              ).toFixed(6) -
                element["Prescribed Weight"]) *
                total
            ) /
              element["CMP"] /
              100
          );
          console.log("11-", element["Suggested Trade"]);
          if (element["Suggested Trade"] === undefined) {
            element["Suggested Trade"] = 0;
          }
          if (element["Delta"] === Infinity) {
            element["Delta"] = 100;
          }
          if (
            Number(element["Prescribed Weight"]) === 0 &&
            element["No of Shares"] !== 0
          ) {
            element["Suggested Trade"] = String(element["No of Shares"]);
            element["Buy Sell"] = "Sell";
            element["Price"] = "Market Price";
          } else {
            if (element["Suggested Trade"] >= 1) {
              console.log("12-", element["Suggested Trade"]);
              element["Suggested Trade"] = String(element["Suggested Trade"]);
              if (element["Delta"] < 100) {
                element["Buy Sell"] = "Buy";
                element["Price"] = "Market Price";
              } else if (element["Delta"] > 100) {
                element["Buy Sell"] = "Sell";
                element["Price"] = "Market Price";
              }
            } else {
              element["Suggested Trade"] = String(element["Suggested Trade"]);
              element["Buy Sell"] = "-";
              element["Price"] = "-";
            }
          }
        } else {
          element["Suggested Trade"] = "-";
          element["Price"] = "-";
        }
        if (
          Number(element["Ratio End Pt"]) >= Number(element["Ratio Start Pt"])
        ) {
          EndPtLessThanStartPt.push(false);
        } else {
          EndPtLessThanStartPt.push(true);
        }
        if (
          Number(element["Ratio Start Pt"]) <= Number(element["Ratio End Pt"])
        ) {
          StartPtLessThanStartPt.push(false);
        } else {
          StartPtLessThanStartPt.push(true);
        }
      } else {
        element["Current Weight"] = Number(
          (
            ((element["No of Shares"] * Number(element["CMP"])) / total) *
            100
          ).toFixed(3)
        );
        element["Delta"] = Number(
          (
            ((((element["No of Shares"] * Number(element["CMP"])) / total) *
              100) /
              element["Prescribed Weight"]) *
            100
          ).toFixed(1)
        );
        element["Suggested Trade"] = String(
          Math.round(
            Math.abs(
              (Number(
                ((element["No of Shares"] * Number(element["CMP"])) / total) *
                  100
              ).toFixed(6) -
                element["Prescribed Weight"]) *
                total
            ) /
              element["CMP"] /
              100
          )
        );
        element["Buy Sell"] = "-";
        element["Price"] = "-";
      }
    });
    // setDisable(false);
    const NewEndPtLessThanStartPt = EndPtLessThanStartPt.filter((ele) => {
      return ele === true;
    });
    const NewStartPtLessThanStartPt = StartPtLessThanStartPt.filter((ele) => {
      return ele === true;
    });
    if (
      NewEndPtLessThanStartPt.length > 0 ||
      NewStartPtLessThanStartPt.length > 0
    ) {
      setDisable(true);
    } else {
      setDisable(false);
    }
    return model;
  };

  const handleDownload = async (e) => {
    e.preventDefault();

    let apiData = [];

    let obj = {};
    obj["ISIN Number"] = "";
    obj["Name of Securities"] = "";
    obj["Asset Class"] = "";
    obj["Sub Asset Class"] = "";
    // obj['Marketcap']='';
    obj["CMP"] = 0;
    obj["NSE Symbol"] = "";
    obj["Prescribed Weight"] = 0.0;
    obj["No of Shares"] = 0;
    obj["Ratio Start Pt"] = 0;
    obj["Ratio End Pt"] = 0;
    apiData.push(obj);
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { Sheet1: ws }, SheetNames: ["Sheet1"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const getInceptionDate = () => {
    let date = new Date().getDate().toString();
    let month = (new Date().getMonth() + 1).toString();
    if (month.length === 1) {
      month = "0" + month;
    }
    let year = new Date().getFullYear().toString();
    let modifiedDate = year + "-" + month + "-" + date;

    return modifiedDate;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name === "" || type === "") {
      toast.warn(`Input Should Not Empty`);
    } else {
      // setTotalCurrentValue(Number(total1.toFixed(2)));
      setInceptionValue(Number(total1.toFixed(2)));

      let jsonRequestBody = {};
      jsonRequestBody["name"] = name;
      jsonRequestBody["type"] = type;
      jsonRequestBody["threshold_lower_limit"] = thresholdLowerLimit;
      jsonRequestBody["threshold_upper_limit"] = thresholdUpperLimit;
      jsonRequestBody["date"] = getInceptionDate();
      jsonRequestBody["inception_value"] = parseFloat(inceptionValue);
      jsonRequestBody["status"] = status;
      jsonRequestBody["createdBy"] =
        userInfo.first_name +
        " " +
        userInfo.middle_name +
        " " +
        userInfo.last_name +
        "" +
        "(" +
        userInfo.id +
        ")";

      jsonRequestBody["model"] = model;
      if (inceptionValue != Number(total1.toFixed(2))) {
        toast.warning(
          `Inception Value will be autoset to Total Current Value.`
        );
        toast.warning(`Click create model again to continue.`, { delay: 5000 });
      } else {
        ApiServices.addModel(jsonRequestBody)
          .then((res) => {
            console.log(res);
            if (res.code === "P2002") {
              toast.warning(res.message);
            } else {
              toast.success(`Model Created Successful..`);
              props.history.push("/modport");
            }
          })
          .catch((err) => {
            console.log(err);
            toast.warning(`${err}`);
          });
      }
    }
  };

  const createTableColumns = (data) => {
    // setColumns([]);
    let keys = Object.keys(data[0]);

    Object.entries(data[0]).forEach((el) => {
      let obj = {};
      obj["Header"] = el[0];
      obj["accessor"] = el[0];
      // setColumns(columns => [...columns, obj]);
    });
  };

  const createTableData = (data) => {
    let tableDataLocal = [];
    let total = null;

    let assetListLocal = [];

    Object.entries(data).forEach((el) => {
      tableDataLocal.push(el[1]);
    });

    let model = processModel(tableDataLocal);

    setModel(model);
    settableData([]);
    model.forEach((element) => {
      if (element["Name of Securities"].toLowerCase() !== "cash")
        assetListLocal.push(element["Asset Class"]);
      else assetListLocal.push("Cash");

      settableData((tableData) => [...tableData, element]);
    });

    let assetClassSet = new Set(assetListLocal);
    Array.from(assetClassSet).map((element) => {
      setAssetList((assetList) => [...assetList, element]);
    });
  };

  const fileUpload = (file) => {
    setAssetList([]);
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    fileReader.onload = (e) => {
      // const bufferArray = e.target.result;

      // const wb = XLSX.read(bufferArray, { type: "binary" });

      // const wsname = wb.SheetNames[0];

      // const ws = wb.Sheets[wsname];

      // const data = XLSX.utils.sheet_to_json(ws, {});

      var workbook = XLSX.readFile(e.target.result);
      var sheet_name_list = workbook.SheetNames;
      let data = [];
      sheet_name_list.forEach(function (y) {
        var worksheet = workbook.Sheets[y];
        var headers = {};
        for (let z in worksheet) {
          if (z[0] === "!") continue;
          //parse out the column, row, and value
          var tt = 0;
          for (var i = 0; i < z.length; i++) {
            if (!isNaN(z[i])) {
              tt = i;
              break;
            }
          }
          var col = z.substring(0, tt);
          var row = parseInt(z.substring(tt));
          var value = worksheet[z].w;

          //store header names
          if (row == 1 && value) {
            headers[col] = value;
            continue;
          }

          if (!data[row]) data[row] = {};
          data[row][headers[col]] = value;
        }
        //drop those first two rows which are empty
        data.shift();
        data.shift();
        console.log(data);
      });

      console.log("data1212", data);
      // // createTableColumns(data);

      let arrBlankVal = [];
      data.map((i) => {
        if (i["ISIN Number"] === undefined) {
          arrBlankVal.push(i["ISIN Number"]);
        } else if (i["Name of Securities"] === undefined) {
          arrBlankVal.push(i["Name of Securities"]);
        } else if (i["Asset Class"] === undefined) {
          arrBlankVal.push(i["Asset Class"]);
        } else if (i["Sub Asset Class"] === undefined) {
          arrBlankVal.push(i["Sub Asset Class"]);
        } else if (i["CMP"] === undefined) {
          arrBlankVal.push(i["CMP"]);
        } else if (i["Prescribed Weight"] === undefined) {
          arrBlankVal.push(i["Prescribed Weight"]);
        } else if (i["No of Shares"] === undefined) {
          arrBlankVal.push(i["No of Shares"]);
        }
      });
      if (arrBlankVal.length) {
        toast.warning(`Blank spaces not allowed`);
      }
      let arrIntVal = [];
      data.map((i) => {
        i["No of Shares"] = Number(i["No of Shares"]);
        if (Number.isInteger(i["No of Shares"])) {
          arrIntVal.push(Number.isInteger(i["No of Shares"]));
        } else {
          i["No of Shares"] = Math.floor(i["No of Shares"]);
        }
      });
      let noOfSharesInDecimal = arrIntVal.filter((i) => {
        return i === false;
      });
      if (noOfSharesInDecimal.length) {
        toast.warning(`'No of Shares' cannot be a decimal value`);
      }

      Number.prototype.countDecimals = function () {
        if (Math.floor(this.valueOf()) === this.valueOf()) return 0;

        var str = this.toString();
        if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
          return str.split("-")[1] || 0;
        } else if (str.indexOf(".") !== -1) {
          return str.split(".")[1].length || 0;
        }
        return str.split("-")[1] || 0;
      };
      let prescribedWeight99 = [];
      data.map((i) => {
        i["CMP"] = Number(i["CMP"]);
        i["Prescribed Weight"] = Number(i["Prescribed Weight"]);
        if (i["Prescribed Weight"] > 99) {
          prescribedWeight99.push("Yes");
        }
      });
      if (prescribedWeight99.length) {
        toast.warning(`Prescribed weight greater than 99 will not be accepted`);
      }
      let decimalThreePresWeight = [];
      data.map((i) => {
        if (parseFloat(i["Prescribed Weight"]).countDecimals() > 3) {
          decimalThreePresWeight.push("Yes");
        }
      });
      if (decimalThreePresWeight.length) {
        toast.warning(
          `Prescribed weight will not accept greater than 3 decimals`
        );
      }
      console.log(
        "decimalThreePresWeight will not accept greater than 3 decimals",
        prescribedWeight99
      );
      let cashEntryNotAvailable = [];
      data.map((i) => {
        if (
          i["Name of Securities"] === "Cash" ||
          i["Name of Securities"] === "Cash & Cash Equivalents"
        ) {
          cashEntryNotAvailable.push("Yes");
        }
      });
      if (!cashEntryNotAvailable.length) {
        toast.warning(`Cash Entry not available in Excel Sheet.`);
      }
      let noOfSharesInCash = [];
      data.map((i) => {
        if (
          (i["Name of Securities"] === "Cash" ||
            i["Name of Securities"] === "Cash & Cash Equivalents") &&
          (i["CMP"] === 1.0 || i["CMP"] === 1 || i["CMP"] === 1.0)
        ) {
          noOfSharesInCash.push("Yes");
        }
      });
      if (!noOfSharesInCash.length) {
        toast.warning(`CMP in cash will always be 1`);
      }
      let totalCountPrescribedWt = 0;
      data.map((i) => {
        totalCountPrescribedWt += Number(i["Prescribed Weight"]);
      });

      let exp = totalCountPrescribedWt.toFixed(3);

      if (Number(exp) !== 100.0) {
        toast.warning(`The total of Prescribed Weight is not 100%`);
      }
      console.log(
        data[0]["Prescribed Weight"],
        "noOfSharesInDecimal",
        Number(exp)
      );
      const SubAssetClassFromExcelSheet = data
        .filter(
          (ele) => !ele["Name of Securities"].toLowerCase().includes("cash")
        )
        .map((data) => data["Sub Asset Class"].toLowerCase());
      const temp = new Set(SubAssetClassFromExcelSheet);
      const newTemp = [...temp];
      let difference = newTemp.filter((x) => !ListAssetClass.includes(x));
      if (difference.length > 0) {
        toast.warning(
          `${difference.toString()} Sub Asset Class are not in a Sub Asset Class List inside settings`
        );
      }

      let RatioStartNotMatched = [];
      data.forEach((element) => {
        if (
          Number(element["Ratio Start Pt"]) > Number(element["Ratio End Pt"])
        ) {
          RatioStartNotMatched.push("Yes");
        }
      });

      console.log("RATIOSTARTEND", RatioStartNotMatched);
      if (RatioStartNotMatched.length) {
        toast.warning(`Ratio Start Pt must be less than Ratio End Pt`);
      }

      // let RatioEndNotMatched = [];
      // data.forEach((element) => {
      //     if(element['Ratio End Pt'] < element['Ratio Start Pt']){
      //         RatioEndNotMatched.push('Yes');
      //     }
      // })

      // if(RatioEndNotMatched.length){
      //     toast.warning(`Ratio End Pt must be less than Ratio Start Pt`)
      // }

      if (
        !noOfSharesInDecimal.length &&
        !prescribedWeight99.length &&
        !decimalThreePresWeight.length &&
        !arrBlankVal.length &&
        cashEntryNotAvailable.length &&
        noOfSharesInCash.length &&
        Number(exp) === 100.0 &&
        difference.length === 0 &&
        !RatioStartNotMatched.length
      ) {
        console.log("INSIDELOOP");
        createTableData(data);
        setDisable(false);
        toast.success(`${file.name} Uploaded Sucessfully`);
      }
    };
  };

  const handleRefresh = () => {
    window.location.reload(false);
    // setRefresh(0);
  };

  const handleCMP = (val, isinNumber, nameOfSecurities) => {
    let modified = val.replace(/,/g, "");
    if (isNaN(Number(modified)) || Math.sign(Number(modified)) === -1) return;
    let index = tableData.findIndex(
      (element) =>
        element["ISIN Number"] == isinNumber &&
        element["Name of Securities"] == nameOfSecurities
    );
    let temp = [...tableData];
    temp[index] = { ...temp[index], CMP: modified };
    settableData(temp);
    let model = processModel(temp);
    setModel(model);
    settableData([]);
    model.forEach((element) => {
      settableData((tableData) => [...tableData, element]);
    });
  };

  const handleShares = (val, isinNumber, nameOfSecurities) => {
    console.log("NO OF SHARES", val);
    let modified = val.replace(/,/g, "");
    if (
      isNaN(Number(modified)) ||
      Math.sign(Number(modified)) === -1 ||
      modified.includes(".")
    )
      return;
    let index = tableData.findIndex(
      (element) =>
        element["ISIN Number"] == isinNumber &&
        element["Name of Securities"] == nameOfSecurities
    );
    let temp = [...tableData];
    if (val.length) {
      temp[index] = {
        ...temp[index],
        ["No of Shares"]: modified,
      };
    } else {
      temp[index] = { ...temp[index], ["No of Shares"]: "0" };
    }
    settableData(temp);
    let model = processModel(temp);
    setModel(model);
    settableData([]);
    model.forEach((element) => {
      settableData((tableData) => [...tableData, element]);
    });
  };

  const handlePrescribedWeight = (val, isinNumber, nameOfSecurities) => {
    if (isNaN(Number(val)) || Math.sign(Number(val)) === -1) return;
    let CashSec = tableData.find(
      (el) => el["Name of Securities"] === "Cash & Cash Equivalents"
    );
    let index = tableData.findIndex(
      (element) =>
        element["ISIN Number"] == isinNumber &&
        element["Name of Securities"] == nameOfSecurities
    );
    let condition = val - tableData[index]["Prescribed Weight"];
    console.log("THE CONDITION OF MATRIX", condition, CashSec);
    if (Math.sign(condition) === 1) {
      if (condition > CashSec["Prescribed Weight"]) {
        toast.warning("Prescribed weight of cash can't be negative");
        return;
      }
    }
    let temp = [...tableData];
    temp[index] = { ...temp[index], ["Prescribed Weight"]: val };
    settableData(temp);
    let model = processModel(temp);

    let prescribedWeightTotal = 0;
    model.forEach((element) => {
      prescribedWeightTotal += Number(element["Prescribed Weight"]);
    });

    if (prescribedWeightTotal != 100) {
      let index = model.findIndex((ele) =>
        ele["Name of Securities"].toLowerCase().includes("cash")
      );
      model[index]["Prescribed Weight"] = (
        Number(model[index]["Prescribed Weight"]) +
        (100 - prescribedWeightTotal)
      ).toFixed(3);
    }

    setModel(model);
    settableData([]);
    model.forEach((element) => {
      settableData((tableData) => [...tableData, element]);
    });
  };

  const handleRatioStartPt = (val, isinNumber, nameOfSecurities) => {
    let index = tableData.findIndex(
      (element) =>
        element["ISIN Number"] == isinNumber &&
        element["Name of Securities"] == nameOfSecurities
    );
    // tableData[index]['Ratio Start Pt'] = val.replace(/,/g,"");
    if (val !== "") {
      // if(Number(val) <= Number(tableData[index]['Ratio End Pt'])){
      tableData[index]["Ratio Start Pt"] = String(val.replace(/,/g, ""));
      // }else{
      //     tableData[index]['Ratio Start Pt'] =  String(tableData[index]['Ratio Start Pt']);
      // }
    } else {
      tableData[index]["Ratio Start Pt"] = val;
    }
    settableData([...tableData]);
    let model = processModel(tableData);
    setModel(model);
    settableData([]);
    model.forEach((element) => {
      settableData((tableData) => [...tableData, element]);
    });
  };

  const handleRatioEndPt = (val, isinNumber, nameOfSecurities) => {
    let index = tableData.findIndex(
      (element) =>
        element["ISIN Number"] == isinNumber &&
        element["Name of Securities"] == nameOfSecurities
    );
    tableData[index]["Ratio End Pt"] = val.replace(/,/g, "");
    settableData([...tableData]);
    let model = processModel(tableData);
    setModel(model);
    settableData([]);
    model.forEach((element) => {
      settableData((tableData) => [...tableData, element]);
    });
  };

  const addModelForm = () => (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-3">
          <label className="form-label required">
            <ShortTextIcon /> Name
          </label>

          <Form.Group controlId="threshold">
            <Form.Control
              type="text"
              className="form-control"
              placeholder="e.g. : Model name"
              value={name}
              onChange={(e) => setName(e.target.value.replace(" ", "_"))}
              required
            />
          </Form.Group>
        </div>

        <div className="col-sm-3">
          <label className="form-label required">
            <ShortTextIcon /> Type
          </label>

          <Form.Group controlId="threshold">
            <Form.Control
              type="text"
              className="form-control"
              placeholder="e.g. : Model type"
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
            />
          </Form.Group>
        </div>
      </div>
      {/* <div className="row">

                <div className="col-sm-3">
                    <label className="form-label required"><TodayIcon /> Threshold Lower Limit({'<'}100)</label>

                    <Form.Group controlId="thresholdLowerLimit">
                        <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="e.g. : 80"
                            value={thresholdLowerLimit}
                            onChange={(e) => setThresholdLowerLimit(e.target.value)}
                            required
                        />
                    </Form.Group>
                </div>

                <div className="col-sm-3">
                    <label className="form-label required"><TodayIcon /> Threshold Upper Limit({'>'}100)</label>

                    <Form.Group controlId="thresholdUpperLimit">
                        <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="e.g. : 125"
                            value={thresholdUpperLimit}
                            onChange={(e) => setThresholdUpperLimit(e.target.value)}
                            required
                        />
                    </Form.Group>
                </div>

            </div> */}
      <div className="row">
        <div className="col-sm-3">
          <label className="form-label">
            <ShortTextIcon /> Total Prescribed Weight(%)
          </label>

          <Form.Group controlId="threshold">
            <Form.Control
              type="text"
              className="form-control"
              value={calculateTotalPrescribedWt("Prescribed Wt")}
              readOnly
            />
          </Form.Group>
        </div>
        <div className="col-sm-3">
          <label className="form-label">
            <ShortTextIcon /> Total Current Weight(%)
          </label>

          <Form.Group controlId="threshold">
            <Form.Control
              type="text"
              className="form-control"
              value={calculateTotalPrescribedWt("Current Wt")}
              readOnly
            />
          </Form.Group>
        </div>
      </div>
      <div className="row">
        {/* <div className="col-sm-3">
                    <label className="form-label required"><TodayIcon /> Creation Date</label>

                    <Form.Group controlId="date">
                        <Form.Control
                            type="date"
                            name="duedate"
                            placeholder="Due date"
                            value={inceptionDate}
                            onChange={(e) => setInceptionDate(e.target.value)}
                        />
                    </Form.Group>
                </div> */}

        {/* <div className="col-sm-3">
                    <label className="form-label required"><MoneyIcon /> Investment Amount</label>
                    <Form.Group controlId="inceptionValue">
                        <Form.Control
                            type="text"
                            className="form-control"
                            name="inceptionvalue"
                            placeholder="e.g. : 10000000"
                            value={numberWithCommas(inceptionValue)}
                            onChange={(e) => setInceptionValue(e.target.value)}
                            readOnly
                        />
                    </Form.Group>
                </div> */}

        <div className="col-sm-3">
          <label className="form-label required">
            <MoneyIcon /> Add securities +
          </label>
          <Form.Group controlId="fileContents">
            <Form.Control
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              name="fileContents"
              value={fileContents}
              onChange={(e) => {
                console.log("--", e.target.files[0]);
                const file = e.target.files[0];
                if (e.target.files[0] !== undefined) {
                  if (
                    e.target.files[0].type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  ) {
                    fileUpload(file);
                  } else {
                    alert("Please upload only Excel sheet format");
                  }
                }
              }}
            />
          </Form.Group>
        </div>
      </div>

      <div className="row">
        {/* <div className="col-sm-3">
                    <label className="form-label required"><MoneyIcon /> Add securities +</label>
                    <Form.Group controlId="fileContents">
                        <Form.Control
                            type="file"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            name="fileContents"
                            value={fileContents}
                            onChange={(e) => {
                                console.log("--", e.target.files[0])
                                const file = e.target.files[0];
                                if(e.target.files[0] !== undefined){
                                if(e.target.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
                                    fileUpload(file)
                                }else{
                                    alert("Please upload only Excel sheet format")
                                }
                                }
                            }}
                        />
                    </Form.Group>
                </div>
                <div className="col-sm-3">
                    <label className="form-label required"><MoneyIcon /> Total Current Value</label>
                    <Form.Group controlId="inceptionValue">
                        <Form.Control
                            type="text"
                            className="form-control"
                            name="inceptionvalue"
                            placeholder="e.g. : 10000000"
                            value={numberWithCommas(totalCurrentValue)}
                            readOnly
                        />
                    </Form.Group>
                </div> */}
      </div>
    </form>
  );

  return (
    <>
      <div id="style_main">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={{ pathname: `/modport` }}>Models</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Add Model</BreadcrumbItem>
        </Breadcrumb>

        <div className="container">
          <div className="row">
            <div className="col-md-12 offset-md-3">{addModelForm()}</div>
          </div>
          <div>
            <span>
              <button
                style={{ marginLeft: "1%" }}
                title="Download Model as xls"
                className="btn btn-outline-success"
                id="btn-success"
                onClick={handleDownload}
              >
                <DownloadIcon />
              </button>
              <button
                style={{ marginLeft: "1%" }}
                title="Refresh Upload Model"
                className="btn btn-outline-success"
                id="btn-success"
                onClick={handleRefresh}
              >
                <RefreshIcon />
              </button>
              <button
                disabled={disable}
                className="btn btn-outline-success"
                id="btn-success"
                onClick={handleSubmit}
              >
                Create Model
              </button>
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            {assetList.length ? (
              <button
                id="dvwm-accordian-toggle"
                onClick={() => setIsExpandOne((prev) => !prev)}
              >
                {isExpand_one ? "Collapse All" : "Expand All"}
              </button>
            ) : null}
          </div>
        </div>
        <div style={{ marginTop: "5%" }}>
          {assetList.map((element, index) => (
            // <Accordion key={index+1} defaultActiveKey={index+1}>

            //       <Accordion.Toggle as={Card.Header} eventKey={index+1} style={{border:'solid 1px #D3D3D3', borderRadius:'4px', cursor:'pointer',backgroundColor: '#e9ecef'}}>
            //       <span style={{fontWeight:'bolder'}}> {element} </span>
            //       <span style={{marginLeft: '10px', fontSize: '12px'}}>(PW Total : {calculatePrescribedWt(element)} of {calculateTotalPrescribedWt()})</span>
            //       </Accordion.Toggle>

            //     <Accordion.Collapse eventKey={index+1}>
            //        <ModelTable type="add-model-table" tableData={tableData.filter(el=>{
            //                             if(element!='Cash')
            //                              return el['Asset Class']==element && !el['Name of Securities'].includes('Cash');
            //                             else
            //                              return el['Name of Securities']==element;
            //                         })

            //                     } COLUMNS={columns} handleCMP={handleCMP}
            //             handleShares={handleShares} handlePrescribedWeight={handlePrescribedWeight} handleRatioStartPt={handleRatioStartPt} handleRatioEndPt={handleRatioEndPt} />

            //     </Accordion.Collapse>

            // </Accordion>
            <AccordianComponent
              key={index + 1}
              title={
                <span style={{ marginLeft: "10px", fontSize: "12px" }}>
                  {
                    <span style={{ fontWeight: "bold", marginRight: "20px" }}>
                      {element}
                    </span>
                  }
                  <span style={{ color: "blue" }}>PW</span>:{" "}
                  {calculatePrescribedWt(element, "Prescribed Wt")}%{" "}
                  <span style={{ color: "blue" }}>CW</span>:{" "}
                  {calculatePrescribedWt(element, "Current Wt")}%
                </span>
              }
              isExpand={isExpand_one}
              content={
                <ModelTable
                  type="add-model-table"
                  tableData={tableData.filter((el) => {
                    if (!element.toLowerCase().includes("cash"))
                      return (
                        el["Asset Class"] == element &&
                        el["Name of Securities"].toLowerCase() !== "cash"
                      );
                    else return el["Name of Securities"] == element;
                  })}
                  COLUMNS={columns}
                  handleCMP={handleCMP}
                  handleShares={handleShares}
                  handlePrescribedWeight={handlePrescribedWeight}
                  handleRatioStartPt={handleRatioStartPt}
                  handleRatioEndPt={handleRatioEndPt}
                />
              }
            ></AccordianComponent>
          ))}
        </div>
      </div>
    </>
  );
};

export default AddModel;
