import { useState, useEffect } from "react";
import ApiServices from "../../ApiService/ApiConfig";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { FormGroup, Label, Input } from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";

import * as Yup from "yup";

const PortfolioCustomerReportPage = (props) => {
  const [reportInfo, setReportInfo] = useState("");
  const [reportListData, setListReportData] = useState([]);
  const [withoutMutualFundData, setWithoutMutualFundData] = useState([]);
  const [mutualFundData, setMutualFundData] = useState([]);
  const [modalData, setModalData] = useState("");
  const [reportStatus, setReportStatus] = useState("Accepted");
  const [deltaData, setDeltaData] = useState("");
  const [remittanceAmt, setRemittanceAmt] = useState();
  const [tradeAdviceCode, setTradeAdviceCode] = useState();

  let {
    name,
    client_first_name,
    client_last_name,
    token,
    portfolio_name,
    trade_advice_code,
  } = useParams();

  trade_advice_code = trade_advice_code.replace(/\+/g, " ");

  console.log("MUTUAL FUNDS DATA", mutualFundData);

  useEffect(() => {
    console.log("Your changes", trade_advice_code, token);
    LandingPageData();
  }, []);

  const LandingPageData = () => {
    ApiServices.getPortfolioCustomerReportData(portfolio_name)
      .then((res) => {
        console.log(res);
        setReportInfo(res);
        let tabData = res.customer_portfolio_securities.filter(
          (item) =>
            item["User Action"] === "Yes" &&
            item["Suggested Trade"] !== "0" &&
            !item["Name of Securities"].toLowerCase().includes("cash")
        );
        console.log(tabData, "TABDAAATTTAAA");
        let tabDataNew = tabData.map((data) => ({
          "Suggested Amount Allocation": Number(
            data["Suggested Trade"] * Number(data["CMP"])
          ),
          "Name of Securities": data["Name of Securities"],
          "Sub Asset Class": data["Sub Asset Class"],
          CMP: data["CMP"],
          "No of Shares": toIndianCurrency(data["No of Shares"]),
          "Suggested Trade":
            data["Suggested Trade"] === "-"
              ? 0
              : toIndianCurrency(data["Suggested Trade"]),
          "Buy Sell": data["Buy Sell"],
          Price: data["Price"],
        }));
        let tabDataWithoutMutualFund = tabDataNew.filter(
          (data) =>
            !data["Sub Asset Class"].toLowerCase().includes("mutual fund")
        );
        let tabDataWithMutualFund = tabDataNew.filter((data) =>
          data["Sub Asset Class"].toLowerCase().includes("mutual fund")
        );
        console.log("SEE IF COMMA ", tabDataWithMutualFund);
        const tabDataWithMutualFundNew = tabDataWithMutualFund.map((data) => ({
          "Name of Securities": data["Name of Securities"],
          Amount: toIndianCurrency(
            Math.ceil(data["Suggested Amount Allocation"] / 100) * 100
          ),
          "Buy Sell": data["Buy Sell"],
        }));
        setWithoutMutualFundData(tabDataWithoutMutualFund);
        setMutualFundData(tabDataWithMutualFundNew);
        let newWithMutualFundData = tabDataWithMutualFundNew.filter(
          (ele) => ele["Buy Sell"] === "Buy"
        );
        let RemittanceAmt = 0;
        newWithMutualFundData.map((element) => {
          RemittanceAmt += Number(element["Amount"].replace(/,/g, ""));
        });
        const RoundedRemittanceAmt = Math.ceil(RemittanceAmt / 100) * 100;
        setRemittanceAmt(RoundedRemittanceAmt);
        // getDelta()
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    customerdata();
    listTradeData();
  }, []);

  const customerdata = () => {
    ApiServices.listCustomer()
      .then((res) => {
        console.log("custdata", res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const listTradeData = () => {
    ApiServices.listAllTradeMISReportData()
      .then((res) => {
        console.log("--", res);
        setListReportData(res);
        res.map((element) => {
          if (element.reportResetToken === token) {
            setTradeAdviceCode(element.trade_advice_code.replace(/\+/g, " "));
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toIndianCurrency = (num) => {
    if (num !== undefined) {
      const curr = Number(num).toLocaleString("en-IN", {
        currency: "INR",
      });
      return curr;
    }
  };

  const columns = [
    {
      headerName: "Script Name",
      field: "Name of Securities",
    },

    {
      headerName: "Suggested Trade Qty",
      field: "Suggested Trade",
    },

    {
      headerName: "Buy Sell",
      field: "Buy Sell",
    },

    {
      headerName: "Price",
      field: "Price",
    },
  ];

  const MutualFundColumns = [
    {
      headerName: "Script Name",
      field: "Name of Securities",
    },
    {
      headerName: "Amount",
      field: "Amount",
    },
    {
      headerName: "Buy/Sell",
      field: "Buy Sell",
    },
    // {
    //     headerName: 'Price', field:'Price',
    // },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  const SubmitHandler = async () => {
    console.log("button clicked");
    // console.log(reportInfo);
    console.log(portfolio_name);
    // console.log(modalData);
    // console.log(cust_received_data);
    const destUrl = `${process.env.REACT_APP_URL_API}/customerportfolio/updateRecord/${portfolio_name}`;
    const data = await axios({
      method: "post",
      url: destUrl,
      data: {
        status: reportStatus,
        name: name,
        //   cust_email_ref: cust_received_data,
        //   cust_borker_report_email: received_broker_email,
        portfolio_name: portfolio_name,
        resetTokenData: token,
        trade_advice_code: trade_advice_code.replace(/\+/g, " "),
      },
    })
      .then((res) => {
        toast.success(`response recorded Successfully`);
        props.history.push("/custthank");
      })
      .catch((err) => {
        console.log(err);
        toast.warning(`response is recorded already Or Link is Expired`);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 125,
      }}
    >
      <div
        className="container-fluid text-center"
        style={{
          width: "85%",
          marginTop: 100,
          backgroundColor: "#eff6f9",
        }}
      >
        <h3 className="pt-3 pb-3">Model Report</h3>
      </div>

      <div
        className="ag-theme-alpine"
        style={{
          height: 400,
          width: 550,
          marginTop: 30,
        }}
      >
        <div style={{ border: "1px solid #babfc7", padding: "15px" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <p>
                  <b>Investor's Name</b>{" "}
                  <span style={{ paddingLeft: "5px" }}>
                    : {client_first_name + " " + client_last_name}
                  </span>
                </p>
                <p>
                  <b>Trade ID</b>{" "}
                  <span style={{ paddingLeft: "5px" }}>
                    : {tradeAdviceCode}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <AgGridReact
          rowData={withoutMutualFundData}
          columnDefs={columns}
          defaultColDef={defaultColDef}
        />
        <br />
        {mutualFundData.length > 0 && (
          <AgGridReact
            rowData={mutualFundData}
            columnDefs={MutualFundColumns}
            defaultColDef={defaultColDef}
          />
        )}
        <br />
        {mutualFundData.length > 0 && (
          <p>
            Required remittance to MFU account - Rs{" "}
            {toIndianCurrency(remittanceAmt)}
          </p>
        )}
        <div className="form-group" style={{ height: "100px" }}>
          <button
            onClick={SubmitHandler}
            className="btn btn-success mt-3 mb-3 mr-2"
          >
            Submit
          </button>
        </div>
        {/* <Formik
					initialValues={{
						acceptTerms: false,
					}}
					validationSchema={Yup.object().shape({
						acceptTerms: Yup.bool().oneOf(
							[true],
							'Accept Terms is required'
						),
					})}
					onSubmit={SubmitHandler}
				>
					{({ errors, status, touched }) => (
						<Form>
							<div className="form-group form-check">
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<Field
										type="checkbox"
										name="acceptTerms"
										className={
											'form-check-input ' +
											(errors.acceptTerms &&
											touched.acceptTerms
												? ' is-invalid'
												: '')
										}
									/>
									<label
										style={{
											display: 'block',
											marginLeft: '5px',
										}}
										htmlFor="acceptTerms"
									>
										I Agree.
									</label>
								</div>
								<ErrorMessage
									name="acceptTerms"
									component="div"
									style={{
										color: 'red',
										marginLeft: '5px',
										fontSize: '12px',
									}}
								/>
							</div>
							<div
								className="form-group"
								style={{ height: '100px' }}
							>
								<button
									type="submit"
									className="btn btn-success mt-3 mb-3 mr-2"
								>
									Submit
								</button>
							</div>
						</Form>
					)}
				</Formik> */}
      </div>
    </div>
  );
};

export default PortfolioCustomerReportPage;
