import React from 'react';

const User_Thank = (props) => {
	const user = 'some';

	// if(user) {
	//   props.history.pushState(null, null, window.location.href);
	//     window.onpopstate = function(event) {
	//       props.history.go(1);
	//     };
	//   }

	return (
		<div
			style={{
				width: '100%',
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<h1 style={{ color: 'teal' }}>Thanks For Your Response</h1>
		</div>
	);
};

export default User_Thank;
