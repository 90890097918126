import React, { useMemo, useState } from 'react'; 
import {Link} from 'react-router-dom';
import { Table } from 'reactstrap';
import { useTable, useSortBy, useGlobalFilter, usePagination, useRowSelect } from 'react-table';
import { Fragment } from 'react';
import GlobalFilter from './GlobalFilter';
import './MainTable.css';
import Checkbox from './Checkbox';
// import { AddNew } from './AddNew';
import { Tooltip } from 'reactstrap';
import CellCustUser from './CellCustUser';
// import CustStatus from './CustStatus';
import RedressalFormStatus from './RedressalFormStatus';
import LoadingSpinner from '../../UI/Loader'

const MainTable = (props) => {
 const [tooltipOpen, setTooltipOpen] = useState(false);
 const columns = useMemo(() => props.COLUMNS, [props.COLUMNS]);
 const data = useMemo(() => props.tableData, [props.tableData]);
 //console.log(columns, "Array" , data)
 const { getTableProps, 
 getTableBodyProps, 
 headerGroups, 
 page,
 nextPage,
 previousPage,
 canNextPage,
 canPreviousPage,
 pageOptions,
 gotoPage,
 pageCount,
 setPageSize, 
 prepareRow,
 selectedFlatRows,
 state,
 setGlobalFilter } = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination, useRowSelect,
 // (hooks) => {
 // hooks.visibleColumns.push((columns) => {
 // return [
 // {
 // id: 'selection',
 // Header: ({getToggleAllRowsSelectedProps}) => (
 // <Checkbox {...getToggleAllRowsSelectedProps()} />
 // ),
 // Cell: ({row}) => <Checkbox {...row.getToggleRowSelectedProps()} />,
 // },
 // ...columns
 // ]
 // })
 // }
 );
 const { globalFilter, pageIndex, pageSize } = state;
 console.log(state);

 

 const toggle = () => setTooltipOpen(!tooltipOpen);
 
 return (
 <Fragment>
 <div id="style-adjust">
 <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} type={props.type} urlAdd={props.urlAdd} />
 {/* <AddNew type={props.type} urlAdd={props.urlAdd}/> */}
 </div>
 <div id="style-adjust">
 <Table {...getTableProps()} hover>
 <thead className="set-background">
 {headerGroups.map((headerGroup) => (
 <tr {...headerGroup.getHeaderGroupProps()}>
 {headerGroup.headers.map((column)=> (
 <th {...column.getHeaderProps(column.getSortByToggleProps())}>
 {column.render('Header')}
 <span>
 {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼' ) : ''}
 </span>
 </th>  
 ))}
 {props.type === "Grievance" && (<th><p id="widthAction">Action</p></th>)}
 {(props.type === "Customer" || props.type === "Users" || props.type === "Model" || props.type === "CustomerPortfolio") && <th>Action</th>}
 </tr>
 ))}
 </thead>
 <tbody {...getTableBodyProps()}>
 {!props.isLoading ? (page.map((row) => {
 prepareRow(row)
 console.log("row",row.original)
 return (
 <tr {...row.getRowProps()}>
 {row.cells.map((cell) => {
 return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
 })}
 <RedressalFormStatus type={props.type} rowStatus={row.original.status} rowPriority={row.original.priority} urlActionEdit={props.urlActionEdit} rowId={row.original.name} rowData={row.original} />
 <CellCustUser type={props.type} urlActionEdit={props.urlActionEdit} urlActionView={props.urlActionView} urlRiskProfile={props.urlRiskProfile} rowId={row.original.name} rowId1={row.original.portfolio_name} rowData={row.original} onDelete={props.onDelete} />
 </tr>
 )
 })): <div><LoadingSpinner isLoading={props.isLoading} /></div>}
 </tbody>
 </Table>
 </div>
 <div id="style-adjust-footer">
 <span>
 Page{' '}
 <strong>
 {pageIndex + 1} of {pageOptions.length}
 </strong>{' '}
 </span>
 <span style={{paddingLeft: '10px'}}>
 | Go to page: {' '}
 <input type='number' defaultValue={pageIndex + 1} 
 onChange={(e) => {
 const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
 gotoPage(pageNumber);
 }}
 style={{width: '35px', height: '35px'}} />
 </span>
 <span style={{paddingLeft: '10px'}}>
 <select value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))} style={{width: '9em', height: '35px'}}>
 {[25, 50, 100].map((pageSize) => (
 <option key={pageSize} value={pageSize}>
 Show {pageSize} Records
 </option>
 ))}
 </select>
 </span>
 <span className="pagination-style">
 <button className={`btn-actions ${!canPreviousPage ? 'disabled' : ''}`} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
 {'<<'}
 </button>
 <button className={`btn-style ${!canPreviousPage ? 'disabled' : ''}`} onClick={(e) => previousPage()} disabled={!canPreviousPage}>Previous</button>
 <button className={`btn-style ${!canNextPage ? 'disabled' : ''}`} onClick={(e) => nextPage()} disabled={!canNextPage}>Next</button>
 <button className={`btn-actions ${!canNextPage ? 'disabled' : ''}`} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
 {'>>'}
 </button></span>
 </div>
 {/* <pre>
 <code>
 {JSON.stringify(
 {
 selectedFlatRows: selectedFlatRows.map((row) => row.original)
 },
 null,
 2
 )}
 </code>
 </pre> */}
 </Fragment>
 );
}

export default MainTable;
