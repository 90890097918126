import React, { useEffect, useState } from "react";
var style = {
  backgroundColor: "#F8F8F8",
  borderTop: "1px solid #E7E7E7",
  textAlign: "center",
  padding: "10px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "40px",
  width: "100%",
};

var phantom = {
  display: "block",
  padding: "20px",
  height: "40px",
  width: "100%",
};

function Footer(props) {
  return (
    <div>
      <div style={phantom} />
      <div style={style}>
        <span>Copyright &copy; 2024 All Rights Reserved, v3.4.6</span>
      </div>
    </div>
  );
}

export default Footer;
