import { useState } from "react";
import { toast } from "react-toastify";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Formik } from 'formik';
import './form.css';

import ApiServices from "../ApiService/ApiConfig";
import { useUserInfo } from '../ContextProviders/UserInfo';

// material-ui icons
import ShortTextIcon from '@material-ui/icons/ShortText';
import LoadingSpinner from "./UI/Loader";
import { Link } from "react-router-dom";
 
const ChangePassword = (props) => {
  const userInfo = useUserInfo();
  const user_id = userInfo.id;

  // states
  // const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  // const handleSubmit = async (e) => {
  //   e.preventDefault();

    // console.log(props.rowData);

  //  console.log(userInfo.id);
  //   if((newPassword && confirmPassword) && (newPassword === confirmPassword) ){
  //     setIsLoading(true);
  //     ApiServices.changePassword(user_id, {
  //       password:confirmPassword
  //      })
  //      .then(res => {
  //        console.log(res.data);
  //        setIsLoading(false);
  //        props.history.push("/user");
  //        toast.success(
  //         `Password Changed Successfully.`
  //       );
  //      }).catch((err) => {
  //        console.log(err);
  //        setIsLoading(false);
  //      })
        
  //   }else{
    
  //       alert('Mismatch Password')
  //   }
 
      
  
  // };

  const changePasswordForm = () => (
    <div>
  
    <Formik 
          initialValues={{ Password: ''
                          //   mobileNumber: '',
                          //  password: '', 
                          //  role: [{admin: 2}, {superAdmin: 1}],
                          //  email: '', 
                          //  brokerCode: '', brokerName: ''
                           }}
          validate={values => {
            const errors = {};
             // firstname
            // password
            if (!values.Password) {
              errors.Password = 'Required';
            } else if (
              !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(values.Password)
            
            ) {
              errors.Password = 'Please create a valid password';
            }
            return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        if((values.Password && confirmPassword) && (values.Password === confirmPassword) ){
          setIsLoading(true);
          ApiServices.changePassword(user_id, {
            password:confirmPassword
           })
           .then(res => {
             console.log(res.data);
             setIsLoading(false);
             props.history.push("/user");
             toast.success(
              `Password Changed Successfully.`
            );
           }).catch((err) => {
             console.log(err);
             setIsLoading(false);
           })
            
        }else{
        
            toast.warn(`Password Mismatch`)
        }
      }}
      >
        {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
    <form onSubmit={handleSubmit} className="mt-3">
      
      <div className="form-group mb-1">
        <label className="form-label required"><ShortTextIcon/> Enter new Password</label>
        <input
          type="password"
          name="Password"
          minLength="6"
          maxLength="12"
          className="form-control"
          placeholder="Enter Password..."
          id="first"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.Password}
          // onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <p style={{color: 'red'}}>{errors.Password}</p>
        <p style={{color: 'red'}}>{touched.Password}</p>
      </div>
      <div className="form-group mb-3">
        <label className="form-label required"><ShortTextIcon/> Confirm new Password</label>
        <input
          type="password"
          className="form-control"
          placeholder="Confirm Password..."
          value={confirmPassword}
          name={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
      </div>
     
      <button className="btn btn-success mt-1 mb-3" style={{width: '30%'}}>
      {isLoading && <LoadingSpinner isLoading={isLoading} />}Submit</button>

    </form>
    )}
    </Formik>

    </div>
  );

  return (
    <>
    
    <div className="container" id="adjust-top">
      <Breadcrumb>
        <BreadcrumbItem><Link to={{pathname: `/profile`}}>Settings</Link></BreadcrumbItem>
        <BreadcrumbItem active>Change Password </BreadcrumbItem>
      </Breadcrumb>
      <div className="container-fluid bg-light text-center">
        <h3 className="pl-3 pr-3 pt-3 pb-3">Change Password</h3>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">{changePasswordForm()}</div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ChangePassword;