const COLUMNS = [
    {
        Header: 'Customer Name',
        accessor: 'customer_name',
    },
    // {
    //     Header: 'Status',
    //     accessor: ''
    // },
    // {
    //     Header: 'Email',
    //     accessor: 'cust_email_verified_status',
    // },
    // {
    //     Header: 'Whatsapp',
    //     accessor: 'cust_phone_verified_status',
    // },
]

export default COLUMNS;