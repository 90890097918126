import React from 'react';
import * as XLSX from 'xlsx';

function parseExcelToJSON(props) {
    const readExcel = (file) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload=(e)=>{
            const bufferArray = e.target.result;

            const wb = XLSX.read(bufferArray, { type: "buffer" });

            const wsname = wb.SheetNames[0];

            const ws = wb.Sheets[wsname];

            const data = XLSX.utils.sheet_to_json(ws);

            console.log("data", data);
        }
    }
    return (
        <div className="container" style={{marginLeft: '90px'}}>
           <input type="file" onChange={(e) => {
               const file = e.target.files[0];
               readExcel(file);
           }} /> 
        </div>
    );
}

export default parseExcelToJSON;