import ColumnFilter from '../Trade-MIS-table/column-filter';

const COLUMNS = [
    {
		Header: 'Date',
		accessor: properties => new Date(properties.createdAt).toLocaleString('en-IN', { day: 'numeric', month: 'numeric',year: 'numeric' }),
	},
    {
		Header: 'Customer Name',
		accessor: 'customer_name',
	},
    {
        Header: 'Trade ID',
        accessor: 'trade_advice_code',
        // Filter: ColumnFilter,
    },
    {
        Header: 'Model Name',
        accessor: 'model_name',
        // Filter: ColumnFilter,
    },
    {
        Header: 'Portfolio Name',
        accessor: 'portfolio_name',
        Cell: ({ row }) => {
            return(
                (row.original.type === "SA") ? (row.original.source === 'Add Funds' ? <p>{row.original.customer_portfolio.portfolio_name}</p> : <p>N.A.</p>) : <p>{row.original.customer_portfolio.portfolio_name}</p>
            )
        },
        filterMethod: (filter, row) => {
            return row.portfolio_name.findIndex(item => item.indexOf(filter.value) >= 0) >= 0;
          }
        // Filter: ColumnFilter,
    },
    {
        Header: 'Location',
        accessor: 'type',
        // Filter: ColumnFilter,
    },
    {
        Header: 'Status',
        accessor: 'reportVerified',
        // Filter: ColumnFilter,
    },
    {
        Header: 'Accept Date / Consent Date',
        accessor: 'updatedAt',
        Cell: ({ row }) => {
            let Consent_Date = new Date(row.original.updatedAt).toLocaleString('en-IN', { day: 'numeric', month: 'numeric',year: 'numeric' });
            return(
                (row.original.type === 'Broker') ? <p>{Consent_Date}</p> : (row.original.reportVerified === 'Accepted' ? <p>{Consent_Date}</p> : <p>Pending</p>)
            )
        }
        // Filter: ColumnFilter,
    },
    {
        Header: 'Source',
        accessor: 'source',
        // Filter: ColumnFilter,
    },
]

export default COLUMNS;
