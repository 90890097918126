import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ApiServices from '../../ApiService/ApiConfig';
import { Breadcrumb, BreadcrumbItem, Label, CustomInput } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import './updateRedressalform.css';
import '../form.css';
import LoadingSpinner from '../UI/Loader';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';

const AddRedressalform = (props) => {
	const [custId, setCustId] = useState('');
	const [custName, setCustName] = useState('');
	const [status, setStatus] = useState('');
	const [enteredEmail, setEnteredEmail] = useState('');
	const [enteredPhone, setEnteredPhone] = useState('');
	const [subject, setSubject] = useState('');
	const [description, setDescription] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [customerData, setCustomerData] = useState([]);

	const onChangeCustomerHandler = (e) => {
		setCustId('');
		setEnteredEmail('');
		setEnteredPhone('');
		setCustName(e.target.value);
		if (e.target.value !== '-1') {
			let data = customerData.filter(
				(ele) =>
					ele.first_name +
						' ' +
						ele.middle_name +
						' ' +
						ele.last_name ===
					e.target.value
			);
			setCustId(data[0].id);
			setEnteredEmail(data[0].email);
			setEnteredPhone(data[0].phone);
		}
	};

	useEffect(() => {
		customerdata();
	}, []);

	const customerdata = () => {
		ApiServices.listCustomer()
			.then((res) => {
				setIsLoading(false);
				setCustomerData(res);
			})
			.catch((err) => {
				setIsLoading(false);
				console.log(err);
			});
	};

	const handleSubmit = (e) => {
		// console.log(values, status, custId, description);
		e.preventDefault();
		setIsLoading(true);
		ApiServices.addGrievance({
			cust_id: parseInt(custId),
			cust_name: custName,
			cust_email: enteredEmail,
			cust_phone: enteredPhone,
			subject: subject,
			status: status,
			description: description,
		})
			.then((res) => {
				console.log(res.data);
				setIsLoading(false);
				props.history.push('/redressalform');
				toast.success(`Ticket Submitted Successful.`);
			})
			.catch((err) => {
				toast.warning(`${err}`);
				setIsLoading(false);
			});
	};

	return (
		<>
			<div className="container" id="adjust-top">
				<Breadcrumb>
					<BreadcrumbItem>
						<Link to={{pathname: `/redressalform`}}>Grievance Redressal</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>Add Grievance</BreadcrumbItem>
				</Breadcrumb>
				<div
					className="shadow-none mb-3 rounded"
					style={{ backgroundColor: '#eff6f9' }}
				>
					<h3 className="pt-3 pb-3" style={{ textAlign: 'center' }}>
						Grievance Redressal
					</h3>
				</div>

				<div>
					<form onSubmit={handleSubmit}>
						<div className="row">
							<div className="col-6">
								<div class="form-group row">
									<label
										for="inputEmail3"
										class="col-sm-4 col-form-label ml-3"
									>
										Customer Id :
									</label>
									<div class="col-sm-6">
										<input
											type="number"
											class="form-control mt-3"
											id="inputEmail3"
											placeholder=""
											value={custId}
											name={custId}
											onChange={(e) =>
												setCustId(e.target.value)
											}
											required
										/>
									</div>
								</div>
								<div class="form-group row">
									<label
										for="inputPassword3"
										class="col-sm-4 col-form-label ml-3"
									>
										Customer Name :
									</label>
									<div class="col-sm-6">
										{/* <input 
                              type="text" 
                              name="custName"
                              class="form-control mt-3" 
                              id="inputPassword3" 
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.custName}
                              required />
                              <p style={{color: 'red'}}>{errors.custName}</p>
                              <p style={{color: 'red'}}>{touched.custName}</p> */}

										<Form.Group>
											<CustomInput
												type="select"
												required
												onChange={
													onChangeCustomerHandler
												}
												value={custName}
											>
												<option value="-1">
													Select Option
												</option>
												{customerData.map((ele) => (
													<option
														value={
															ele.first_name +
															' ' +
															ele.middle_name +
															' ' +
															ele.last_name
														}
													>
														{ele.first_name +
															' ' +
															ele.middle_name +
															' ' +
															ele.last_name}
													</option>
												))}
											</CustomInput>
										</Form.Group>
									</div>
								</div>
								<div class="form-group row">
									<label
										for="inputPassword3"
										class="col-sm-4 col-form-label ml-3"
									>
										Email Address :
									</label>
									<div class="col-sm-6">
										<input
											type="email"
											name="enteredEmail"
											class="form-control mt-3"
											id="inputPassword3"
											placeholder=""
											value={enteredEmail}
											onChange={(e) =>
												setEnteredEmail(
													e.currentTarget.value
												)
											}
											// onChange={handleChange}
											// onBlur={handleBlur}
											required
										/>
										{/* <p style={{color: 'red'}}>{errors.enteredEmail}</p>
                              <p style={{color: 'red'}}>{touched.enteredEmail}</p> */}
									</div>
								</div>
								<div class="form-group row">
									<label
										for="inputPassword3"
										class="col-sm-4 col-form-label ml-3"
									>
										Mobile No. :
									</label>
									<div class="col-sm-6">
										<input
											type="tel"
											name="enteredPhone"
											maxLength="10"
											class="form-control mt-3"
											id="inputPassword3"
											// onChange={handleChange}
											// onBlur={handleBlur}
											value={enteredPhone}
											onChange={(e) =>
												setEnteredPhone(e.target.value)
											}
											required
										/>
										{/* <p style={{color: 'red'}}>{errors.enteredPhone}</p>
                              <p style={{color: 'red'}}>{touched.enteredPhone}</p> */}
									</div>
								</div>
								<div class="form-group row">
									<label
										for="inputPassword3"
										class="col-sm-4 col-form-label ml-3"
									>
										Subject :
									</label>
									<div class="col-sm-6">
										<textarea
											name="subject"
											class="form-control mt-3"
											aria-label="With textarea"
											// onChange={handleChange}
											// onBlur={handleBlur}
											value={subject}
											onChange={(e) =>
												setSubject(e.target.value)
											}
											required
										/>
										{/* <p style={{color: 'red'}}>{errors.subject}</p>
                              <p style={{color: 'red'}}>{touched.subject}</p> */}
									</div>
								</div>
								<div class="form-group row">
									<label
										for="inputPassword3"
										class="col-sm-4 col-form-label ml-3"
									>
										Status :
									</label>
									<div class="col-sm-6">
										<select
											class="form-control mt-3"
											aria-label=".form-select-sm example"
											onChange={(e) =>
												setStatus(e.target.value)
											}
											required
										>
											<option selected>
												Select Option
											</option>
											<option value="Open" name={status}>
												Open
											</option>
											<option
												value="Pending"
												name={status}
											>
												Pending
											</option>
											<option
												value="Resolved"
												name={status}
											>
												Resolved
											</option>
										</select>
									</div>
								</div>
							</div>
							<div>
								<div class="vl"></div>
							</div>
							<div className="col-5">
								<textarea
									class="form-control mt-3"
									rows="10"
									style={{
										width: '430px',
										marginLeft: '20px',
									}}
									aria-label="With textarea"
									placeholder="Description.."
									value={description}
									name={description}
									onChange={(e) =>
										setDescription(e.target.value)
									}
									required
								/>
							</div>

							<button
								className="btn btn-outline-success mt-3 mb-3"
								style={{ width: '25%', marginLeft: '370px' }}
							>
								{isLoading && (
									<LoadingSpinner isLoading={isLoading} />
								)}
								Submit
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default AddRedressalform;
