import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ApiServices from '../../ApiService/ApiConfig';
import {
	Breadcrumb,
	BreadcrumbItem,
	Input,
	InputGroup,
	InputGroupText,
	Label,
	CustomInput,
} from 'reactstrap';
import CreateIcon from '@mui/icons-material/Create';
import Form from 'react-bootstrap/Form';
import './updateRedressalform.css';
import '../form.css';
import LoadingSpinner from '../UI/Loader';
import { useUserInfo } from '../../ContextProviders/UserInfo';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';

const UpdateRedressalform = (props) => {
	const userInfo = useUserInfo();
	const redressal_Data = props.location.state.data;
	const grev_id = redressal_Data.id;
	const cust_id = redressal_Data.cust_id;
	const cust_name = redressal_Data.cust_name;
	const date = redressal_Data.date;
	const email = redressal_Data.cust_email;
	const phone = redressal_Data.cust_phone;
	const grev_sub = redressal_Data.subject;
	const cust_status = redressal_Data.status;
	const cust_description = redressal_Data.description;

	const datetime = new Date(date).toLocaleString('en-US', {
		year: 'numeric',
		month: '2-digit',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
	});
	console.log('datetime', datetime);

	const [custId, setCustId] = useState(cust_id);
	const [custName, setCustName] = useState(cust_name);
	const [enteredEmail, setEnteredEmail] = useState(email);
	const [enteredPhone, setEnteredPhone] = useState(phone);
	const [subject, setSubject] = useState(grev_sub);
	const [status, setStatus] = useState(cust_status);
	const [description, setDescription] = useState(cust_description);
	const [notes, setNotes] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [customerData, setCustomerData] = useState([]);
	const [defaultDisabled, setDefaultDisabled] = useState(true);

	const onChangeCustomerHandler = (e) => {
		setCustId('');
		setEnteredEmail('');
		setEnteredPhone('');
		setCustName(e.target.value);
		if (e.target.value !== '-1') {
			let data = customerData.filter(
				(ele) =>
					ele.first_name +
						' ' +
						ele.middle_name +
						' ' +
						ele.last_name ===
					e.target.value
			);
			setCustId(data[0].id);
			setEnteredEmail(data[0].email);
			setEnteredPhone(data[0].phone);
		}
	};

	useEffect(() => {
		customerdata();
	}, []);

	const customerdata = () => {
		ApiServices.listCustomer()
			.then((res) => {
				setIsLoading(false);
				setCustomerData(res);
			})
			.catch((err) => {
				setIsLoading(false);
				console.log(err);
			});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(
			custId,
			custName,
			enteredEmail,
			enteredPhone,
			subject,
			status,
			description,
			notes
		);
		setIsLoading(true);
		ApiServices.updateGrievance(grev_id, {
			cust_id: parseInt(custId),
			cust_name: custName,
			cust_email: enteredEmail,
			cust_phone: enteredPhone,
			subject: subject,
			status: status,
			description: description,
			notes: {
				note_name:
					`${userInfo.first_name} ${userInfo.middle_name} ${userInfo.last_name}@:  ` +
					notes,
				createdBy: userInfo.id,
			},
		})
			.then((res) => {
				console.log(res.data);
				setIsLoading(false);
				props.history.push('/redressalform');
				toast.success(`Ticket Updated Successful.`);
			})
			.catch((err) => {
				toast.warning(`${err}`);
				setIsLoading(false);
			});
	};

	const handleEditPage = () => {
		setDefaultDisabled(false);
	};

	return (
		<>
			<div className="container" id="adjust-top">
				<Breadcrumb>
					<BreadcrumbItem>
						<Link to={{pathname: `/redressalform`}}>Grievance Redressal</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>Update Grievance</BreadcrumbItem>
				</Breadcrumb>
				<div class="shadow-none mb-3 rounded" id="page-head1">
					<h2
						className="pt-4"
						style={{
							textAlign: 'center',
							backgroundColor: '#eff6f9',
						}}
					>
						Grievance Redressal
					</h2>
					<p id="datetime">Date Of Grievance : {datetime} </p>
				</div>
				<div className="row">
					<div className="col-12">
						<button
							style={{ marginLeft: '1%' }}
							title="Edit Grievance"
							className="btn btn-outline-success"
							id="btn-success"
							onClick={handleEditPage}
						>
							<CreateIcon />
						</button>
					</div>
				</div>
				<form onSubmit={handleSubmit}>
					<div className="row">
						<div className="col-6">
							<div class="form-group row">
								<label
									for="inputEmail3"
									class="col-sm-4 col-form-label ml-3"
								>
									Customer Id :
								</label>
								<div class="col-sm-6">
									<input
										type="number"
										class="form-control mt-3"
										id="inputEmail3"
										placeholder="Enter Customer-Id.."
										value={custId}
										name={custId}
										onChange={(e) =>
											setCustId(e.target.value)
										}
										disabled={defaultDisabled}
										required
									/>
								</div>
							</div>
							<div class="form-group row">
								<label
									for="inputPassword3"
									class="col-sm-4 col-form-label ml-3"
								>
									Customer Name :
								</label>
								<div class="col-sm-6">
									<Form.Group>
										<CustomInput
											type="select"
											required
											onChange={onChangeCustomerHandler}
											value={custName}
											disabled={defaultDisabled}
										>
											<option value="-1">
												Select Option
											</option>
											{customerData.map((ele) => (
												<option
													value={
														ele.first_name +
														' ' +
														ele.middle_name +
														' ' +
														ele.last_name
													}
												>
													{ele.first_name +
														' ' +
														ele.middle_name +
														' ' +
														ele.last_name}
												</option>
											))}
										</CustomInput>
									</Form.Group>
								</div>
							</div>
							<div class="form-group row">
								<label
									for="inputPassword3"
									class="col-sm-4 col-form-label ml-3"
								>
									Email Address :
								</label>
								<div class="col-sm-6">
									<input
										type="email"
										class="form-control mt-3"
										id="inputPassword3"
										placeholder=""
										value={enteredEmail}
										name={enteredEmail}
										onChange={(e) =>
											setEnteredEmail(e.target.value)
										}
										disabled={defaultDisabled}
										required
									/>
								</div>
							</div>
							<div class="form-group row">
								<label
									for="inputPassword3"
									class="col-sm-4 col-form-label ml-3"
								>
									Mobile No. :
								</label>
								<div class="col-sm-6">
									<input
										type="tel"
										class="form-control mt-3"
										id="inputPassword3"
										placeholder=""
										value={enteredPhone}
										name={enteredPhone}
										onChange={(e) =>
											setEnteredPhone(e.target.value)
										}
										disabled={defaultDisabled}
										required
									/>
								</div>
							</div>

							<div class="form-group row">
								<label
									for="inputPassword3"
									class="col-sm-4 col-form-label ml-3"
								>
									Subject :
								</label>
								<div class="col-sm-6">
									<textarea
										class="form-control mt-3"
										aria-label="With textarea"
										placeholder=""
										pattern="[A-Za-z]{3}"
										title="Field contain letters only"
										value={subject}
										name={subject}
										onChange={(e) =>
											setSubject(e.target.value)
										}
										disabled={defaultDisabled}
										required
									/>
								</div>
							</div>

							<div class="form-group row">
								<label
									for="inputPassword3"
									class="col-sm-4 col-form-label ml-3"
								>
									Status :
								</label>
								<div class="col-sm-6">
									<select
										class="form-control mt-3"
										aria-label=".form-select-sm example"
										onChange={(e) =>
											setStatus(e.target.value)
										}
										value={status}
										disabled={defaultDisabled}
									>
										<option selected>Select Option</option>
										<option value="Open" name={status}>
											Open
										</option>
										<option value="Pending" name={status}>
											Pending
										</option>
										<option value="Resolved" name={status}>
											Resolved
										</option>
									</select>
								</div>
							</div>

							{/* <div class="form-group row">
                        <div class="col-sm-6">
                        <label>Subject :</label>
                            <textarea 
                            class="form-control" 
                            aria-label="With textarea"
                            value={subject}
                            name={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            required />
                        </div> 
                        </div> */}

							<br />
							<div class="form-group row"></div>
						</div>
						<div>
							<div class="vl"></div>
						</div>
						<div className="col-5">
							<div class="form-group row">
								<textarea
									class="form-control mt-3"
									rows="10"
									style={{
										width: '430px',
										marginLeft: '20px',
									}}
									aria-label="With textarea"
									placeholder="Description.."
									value={description}
									name={description}
									onChange={(e) =>
										setDescription(e.target.value)
									}
									disabled={defaultDisabled}
									required
								/>
							</div>
							<div
								style={{
									'overflow-y': 'scroll',
									height: '150px',
								}}
							>
								<label>Logs:</label>
								{redressal_Data.notes.map((q) => (
									<p style={{ backgroundColor: '#fcffa4' }}>
										{q.id + ' ' + q.note_name}
									</p>
								))}
							</div>
							<br />
							{/* <div class="form-group row">
                        <div class="col-sm-4">
                        <p>{`${userInfo.first_name} ${userInfo.middle_name} ${userInfo.last_name}@`}</p>
                        </div>
                        <div class="col-sm-8"> */}
							{/* <textarea 
                        class="form-control" 
                        rows="2" 
                        style={{width: '270px'}}
                        aria-label="With textarea" 
                        placeholder="Logs.."
                        value={notes}
                        name={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        required /> */}
							{/* <Input 
                        type="textarea" 
                        placeholder="Logs.."
                        value={notes}
                        name={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        required />
                        </div>
                    </div> */}
							<div>
								<InputGroup>
									<InputGroupText id="userInfo">
										{`${userInfo.first_name} ${userInfo.middle_name} ${userInfo.last_name}@:  `}
									</InputGroupText>
									<Input
										type="textarea"
										placeholder="Logs.."
										value={notes}
										name={notes}
										onChange={(e) =>
											setNotes(e.target.value)
										}
										disabled={defaultDisabled}
										required
									/>
								</InputGroup>
							</div>
							{/* <div contentEditable onInput={(e) => console.log(e.currentTarget.innerText)}><input type="text" value={test} disabled style={{width: "140px"}} /></div> */}
						</div>
						<button
							className="btn btn-outline-success mt-2 mb-3"
							style={{ width: '17%', marginLeft: '475px' }}
							disabled={defaultDisabled}
						>
							{isLoading && (
								<LoadingSpinner isLoading={isLoading} />
							)}
							Submit
						</button>
					</div>
				</form>
			</div>
		</>
	);
};

export default UpdateRedressalform;
