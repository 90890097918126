import React, { useEffect, useState } from 'react';
import MainTable from "./Layout/Table-layout/MainTable";
//import tableData from './json-data/redressalForm.json';
import ApiServices from '../ApiService/ApiConfig';
import { COLUMNS } from './Layout/Table-layout/Columns/columns-redressalform';

const Redressalform = (props) => {
  const [tableData, settableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    grievancedata();
  }, [])

  const grievancedata = () => {
    ApiServices.listGrievance()
      .then((res) => {
            setIsLoading(false);
        settableData(res);
      }).catch((err) => {
        setIsLoading(false);
        console.log(err);
      })
}

if (!tableData) return null;
    return (
        <div id="style_main">
          <div className="shadow-none mb-3 rounded" style={{textAlign: "center"}}>
            <h3 style={{ marginTop: "110px", width: "100%", backgroundColor: '#f5f3f3', fontFamily: 'sans-serif'}} className="pt-2 pb-2">Grievance List</h3>
          </div>
        <MainTable tableData={tableData} COLUMNS={COLUMNS} type="Grievance" urlAdd="/addredressalform" urlActionEdit="/updateredressalform/" urlActionView="/viewPage/" isLoading={isLoading} />
      </div>
    );
}

export default Redressalform;