import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Formik } from 'formik';
import './form.css';
import ApiServices from "../ApiService/ApiConfig";

// material-ui icons
import ShortTextIcon from '@material-ui/icons/ShortText';
import LoadingSpinner from "./UI/Loader";
import { Link } from "react-router-dom";

const UpdateAddress = (props) => {

    // states
    const [isLoading, setIsLoading] = useState(false);
    const [enable, setEnable] = useState(false);
    const [getCompanyAddressData, setGetCompanyAddressData] = useState("");
    
	useEffect(() => {
		const fetchData = () => {
			ApiServices.GetCompanyAddress()
			.then((res) => {
                if(res.length){
                    setGetCompanyAddressData(res[0].address);
                } else {
                    setGetCompanyAddressData("")
                }
			})
			.catch((err) => {
				console.log('err', err);
			})
		};
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	  }, []);

      const handleAdd = () => {
        props.history.push("/add-address");
      }

    const AddAddressForm = () => {
        return (
        <div>
            <Formik
                initialValues={{
                    Address: getCompanyAddressData.address
                }}
                validate={values => {
                    const errors = {}; 
                    if (!values.Address) {
                        errors.Address = 'Required';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    setIsLoading(true);
                    ApiServices.UpdateCompanyAddress({
                        id: 1, 
                        address: values.Address 
                    })
                        .then(res => {
                            console.log(res);
                            setIsLoading(false);
                            props.history.push("/profile");
                            toast.success(
                                `Company Adress Updated Successfully.`
                            );
                        }).catch((err) => {
                            console.log(err);
                            setIsLoading(false);
                        })
                }}
            >
                {({
                    values,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit} className="mt-3">
                        <div className="form-group mb-1" onClick={() => setEnable(true)}>
                            <label className="form-label required"><ShortTextIcon />Click Here to Update Company Address</label>
                            <input
                                disabled={!enable ? true : false}
                                type="text"
                                name="Address"
                                className="form-control"
                                placeholder="Enter Current Address..."
                                id="first"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.Address}
                                required
                            />
                            <p style={{ color: 'red' }}>{touched.Address}</p>
                        </div>
                        <button className="btn btn-success mt-1 mb-3" style={{ width: '30%' }}>
                            {isLoading && <LoadingSpinner isLoading={isLoading} />}Update Address</button>
                    </form>
                )}
            </Formik>

        </div>)
};

    return (
        <>

            <div className="container" id="adjust-top">
                <Breadcrumb>
                    <BreadcrumbItem><Link to={{ pathname: `/profile` }}>Settings</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Company Address</BreadcrumbItem>
                </Breadcrumb>
                <div className="container-fluid bg-light text-center">
                    <h3 className="pl-3 pr-3 pt-3 pb-3">Company Address</h3>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">{AddAddressForm()}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                        <div className="form-group mb-1">
                            <label className="form-label required"><ShortTextIcon />Previous Address</label>
                            <input
                                type="text"
                                className="form-control"
                                disabled
                                value={getCompanyAddressData}
                            />
                        </div>
                        <button className="btn btn-success mt-1 mb-3" style={{ width: '20%' }} onClick={() => handleAdd()}>+ Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpdateAddress;