import { useState, useEffect } from 'react';
import { Breadcrumb, BreadcrumbItem, CustomInput } from 'reactstrap';
import '../form.css';
import XLSX from 'xlsx';

// material-ui icons
import ShortTextIcon from '@material-ui/icons/ShortText';
import TodayIcon from '@material-ui/icons/Today';
import RefreshIcon from '@material-ui/icons/Refresh';
import DownloadIcon from '@mui/icons-material/Download';

import Form from 'react-bootstrap/Form';

import ModelTable from '../Layout/Table-layout/ModelTable';
import ApiServices from '../../ApiService/ApiConfig';

import { useParams } from 'react-router-dom';

import * as moment from 'moment';

import { toast } from 'react-toastify';

import Modal from '../Modal-UI/Modal';
import Wrapper from '../Modal-UI/Wrapper';
import AccordianComponent from '../Layout/Table-layout/Accordian';

import GlobalFundsTable from './GlobalFundsTable';

import { columns } from '../Layout/Table-layout/Columns/columns-model';

import { COLUMNS } from './columns-global-funds';
import { useUserInfo } from '../../ContextProviders/UserInfo';

import * as FileSaver from 'file-saver';

const AddFundsGlobal = (props) => {
	//added by vishal
	const [seeMore, setSeeMore] = useState(true);
	//
	const userInfo = useUserInfo();
	const [modal, setModal] = useState();
	const [model, setModel] = useState([]);

	const [portfolioModel, setPortfolioModel] = useState([]);

	const [amount, setAmount] = useState('');
	const [fundCash, setfundCash] = useState('');
	const [tableData, settableData] = useState([]);
	const [tableFundsData, settableFundsData] = useState([]);
	const [assetList, setAssetList] = useState([]);

	const [clientName, setClientName] = useState('');
	const [portfolioName, setPortfolioName] = useState('');
	const [fundsAdd, setFundsAdd] = useState('');
	const [cashAvailable, setCashAvailable] = useState('');
	const [customerDropdownData, setCustomerDropdownData] = useState([]);
	const [customerPortfolioDropdownData, setCustomerPortfolioDropdownData] =
		useState([]);
	const [
		allCustomerPortfolioDropdownData,
		setAllCustomerPortfolioDropdownData,
	] = useState([]);
	const [
		relatedCustomerPortfolioDropdownData,
		setRelatedCustomerPortfolioDropdownData,
	] = useState([]);

	const [buttonDisable, setButtonDisable] = useState(true);
	const [fundsButtonDisable, setFundsButtonDisable] = useState(false);
	const [fileUploadDisable, setFileUploadDisable] = useState(false);

	const [userActionNew, setUserActionNew] = useState();
	const [idForUserActionNew, setIdForUserActionNew] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [thresholdReload, setThresholdReload] = useState();
	const [buttonGenerateReportDisabled, setButtonGenerateReportDisabled] =
		useState(true);
	const [rerenderPage, setRerenderPage] = useState();
	const [fundsAvailable, setFundsAvailable] = useState();
	const [buttonDisabled, setButtonDisabled] = useState(true);
	const [buttonRecaliberateDisabled, setButtonRecaliberateDisabled] =
		useState(true);
	const [fundsadditionDisabled, setFundsadditionDisabled] = useState(true);
	const [initialInstanceTableData, setInitialInstanceTableData] = useState(
		[]
	);
	const [portfolioDataForAddFunds, setPortfolioDataForAddFunds] = useState(
		[]
	);
	const [totalCurrValTrade, setTotalCurrValTrade] = useState();

	const [modelSecData, setModelSecData] = useState([]);
	const [totalCurrVal, setTotalCurrVal] = useState();
	const [refresh, setRefresh] = useState();
	const [newwTabData, setNewwTabData] = useState([]);
	const [totalSuggestedAllocation, setTotalSuggestedAllocation] = useState();
	const [isExpand_one, setIsExpandOne] = useState(false);

	const { name: model_name } = useParams();

	//portfolio-states
	const [portfolioInceptionVal, setPortfolioInceptionVal] = useState();

	useEffect(() => {
		ApiServices.listCustomer().then((res) => {
			const statusCompleted = res.filter((element) => {
				return element.customer_profile.status === 'Completed';
			});
			setCustomerDropdownData(statusCompleted);
		});

		ApiServices.customerPortfolioList().then((res) => {
			setCustomerPortfolioDropdownData(res);
		});

		//    modelData();
	}, [userActionNew, idForUserActionNew, refresh]);

	const numberWithCommas = (num) => {
		if (num) return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	};

	const toIndianCurrency = (num) => {
		if (num !== '') {
			const curr = Number(num).toLocaleString('en-IN', {
				currency: 'INR',
			});
			return curr;
		}
		return '';
	};

	const generateAssetClass = (data) => {
		let assetListLocal = [];
		setAssetList([]);
		data.forEach((element) => {
			if (!element['Name of Securities'].toLowerCase().includes('Cash'))
				assetListLocal.push(element['Asset Class']);
			else assetListLocal.push('Cash');
		});

		let assetClassSet = new Set(assetListLocal);
		Array.from(assetClassSet).map((element) => {
			setAssetList((assetList) => [...assetList, element]);
		});
	};

	const onChangeClientNameHandler = (e) => {
		setClientName(e.target.value);
		if (e.target.value === '-1') {
			setPortfolioName(e.target.value);
			settableData([]);
			setAssetList([]);
			setFundsAdd('');
			setFundsAvailable();
			setButtonRecaliberateDisabled(true);
			setButtonDisabled(true);
			setRefresh(Math.random());
		}
		const filterPortfolioById = customerPortfolioDropdownData.filter(
			(ele) => ele.user.id == e.target.value
		);
		setAllCustomerPortfolioDropdownData(filterPortfolioById);
	};

	const portfolioTableData = (val) => {
		const SortPortfolioByName = customerPortfolioDropdownData.filter(
			(element) => val === element.portfolio_name
		);
		setRelatedCustomerPortfolioDropdownData(SortPortfolioByName);
		setPortfolioDataForAddFunds(SortPortfolioByName);
		SortPortfolioByName.map((element1) => {
			element1.customer_portfolio_securities.map((ele) => {
				if (ele['Name of Securities'].toLowerCase().includes('cash')) {
					setCashAvailable(ele['Current Value']);
				}
			});
			settableFundsData(element1.customer_portfolio_securities);
			let processedData = [];
			let processedDataFunds = [];
			let total = 0;
			setFundsAvailable(element1.cust_funds_log.length);
			element1.customer_portfolio_securities.map((element) => {
				let temp = element1.portfolio_model.model_securities.find(
					(ele) => ele['ISIN_Number'] === element['ISIN Number']
				);
				let obj = {};
				obj['id'] = element['id'];
				obj['ISIN Number'] = element['ISIN Number'];
				obj['Name of Securities'] = element['Name of Securities'];
				obj['Asset Class'] = element['Asset Class'];
				obj['Sub Asset Class'] = element['Sub Asset Class'];
				// obj['Marketcap'] = element['Marketcap'];
				obj['CMP'] = String(Number(element['CMP']).toFixed(3));
				obj['NSE Symbol'] = element['NSE Symbol'];
				obj['Ratio Start Pt'] = temp['Ratio_Start_Pt'];
				obj['Ratio End Pt'] = temp['Ratio_End_Pt'];
				obj['No of Shares'] = Number(element['No of Shares']);

				obj['Prescribed Weight'] = Number(
					element['Prescribed Weight']
				).toFixed(3);

				obj['Current Value'] = Number(element['Current Value']);
				obj['Current Weight'] = Number(element['Current Weight']);
				obj['Delta'] = element['Delta'];
				obj['Actionable'] = element['Actionable'];
				obj['User Action'] = element['User Action'];
				obj['Suggested Trade'] = element['Suggested Trade'];
				obj['Buy Sell'] = element['Buy Sell'];
				obj['Price'] = element['Price'];
				var momentDate = moment(element['updatedAt']);
				obj['Updated On'] = momentDate.format('MM-DD-YYYY hh:mm:ss A ');
				obj['updatedBy'] = element['updatedBy'];
				total += Number(obj['Current Value']);
				processedData.push(obj);
				processedDataFunds.push(obj);
			});
			processedData.map((element) => {
				if (!element['Name of Securities'].toLowerCase().includes('cash')) {
					element['Current Weight'] = Number(
						((element['Current Value'] / total) * 100).toFixed(3)
					);
					element['Delta'] = Number(
						(
							(element['Current Weight'] /
								Number(element['Prescribed Weight'])) *
							100
						).toFixed(3)
					);

					if (element['User Action'] === 'Yes') {
						element['Suggested Trade'] = (
							Math.round(
								Math.abs(
									(element['Current Weight'] -
										element['Prescribed Weight']) *
										total
								) /
									element['CMP'] /
									100
							)
						);
						if (element['Suggested Trade'] === undefined) {
							element['Suggested Trade'] = 0;
							element['Buy Sell'] = '-';
						} else if (
								element['Suggested Trade'] >= 1
						) {
							element['Suggested Trade'] = element['Suggested Trade'];
							if (element['Delta'] < 100) {
								element['Buy Sell'] = 'Buy';
							} else if (element['Delta'] > 100) {
								element['Buy Sell'] = 'Sell';
							}
						} else {
							element['Buy Sell'] = '-';
						}
						element['Price'] = 'Market Price';
					} else {
						element['Suggested Trade'] = '-';
						element['Price'] = '-';
					}
				}
			});

			setTotalCurrVal(Number(total.toFixed(3)));
			settableData([]);
			settableFundsData([]);
			setModelSecData(element1.customer_portfolio_securities);
			settableData(processedData);
			setInitialInstanceTableData(processedData);
			settableFundsData(processedDataFunds);
			generateAssetClass(processedDataFunds);
			// settableData(element.customer_portfolio_securities);

			if (processedDataFunds.length > 0) {
				setButtonRecaliberateDisabled(false);
				setFundsadditionDisabled(false);
			} else {
				setButtonRecaliberateDisabled(true);
				setFundsadditionDisabled(true);
			}

			setRerenderPage(Math.random());
		});
	};

	const onChangePortfolioNameHandler = (e) => {
		setPortfolioName(e.target.value);
		if (e.target.value === '-1') {
			settableData([]);
			setAssetList([]);
			setFundsAdd('');
			setFundsAvailable();
			setRefresh(Math.random());
			setFundsAvailable();
		} else {
			portfolioTableData(e.target.value);
		}
	};

	const onChangeFundsAddition = (e) => {
		if (Number(e.target.value) === 0) return setFundsAdd('');
		setFundsAdd(e.target.value.replace(/,/g, ''));
		// setFundsAdd(e.target.value);
		setButtonRecaliberateDisabled(false);
	};

	const onClickRecaliberateWt = () => {
		setAmount(Number(fundsAdd) + Number(cashAvailable));
		handleFundsChange(Number(fundsAdd) + Number(cashAvailable));
		modalTestHandler();
	};

	const handlePrice = (val, isinNumber, nameOfSecurities) => {
		let index = tableFundsData.findIndex(
			(element) =>
				element['ISIN Number'] == isinNumber &&
				element['Name of Securities'] == nameOfSecurities
		);
		tableFundsData[index]['Price'] = val;
		settableFundsData([]);
		tableFundsData.forEach((element) => {
			settableFundsData((tableFundsData) => [...tableFundsData, element]);
		});
	};

	const processPortfolio = (tableData, customerPortfolio, currentModel) => {
		let total = null;

		let portfolioModel = [];

		if (customerPortfolio.length) {
			customerPortfolio.map((noOfShares) => {
				let model = [];
				tableData.forEach((element, index) => {
					let obj = {};

					obj['ISIN Number'] = element['ISIN Number'];

					obj['Name of Securities'] = element['Name of Securities'];

					obj['Asset Class'] = element['Asset Class'];

					obj['Sub Asset Class'] = element['Sub Asset Class'];

					// obj['Marketcap'] = element['Marketcap']

					obj['CMP'] = String(element['CMP']);

					obj['NSE Symbol'] = element['NSE Symbol'];

					obj['Ratio Start Pt'] = element['Ratio Start Pt'];

					obj['Ratio End Pt'] = element['Ratio End Pt'];

					obj['Prescribed Weight'] = String(
						element['Prescribed Weight']
					);

					obj['No of Shares'] = Math.floor(
						element[noOfShares['name']]
					);

					obj['Price'] = element['Price'];

					obj['Current Value'] = Number(
						(obj['No of Shares'] * Number(obj['CMP'])).toFixed(2)
					);
					total += obj['Current Value'];

					model.push(obj);

					model.map((element) => {
						//extract actionable from model

						let modelElement = currentModel.find(
							(ele) =>
								ele['ISIN Number'] == element['ISIN Number'] &&
								ele['Name of Securities'] ==
									element['Name of Securities']
						);

						element['Current Weight'] = Number(
							((element['Current Value'] / total) * 100).toFixed(
								3
							)
						);
						element['Delta'] = Number(
							(
								(element['Current Weight'] /
									Number(element['Prescribed Weight'])) *
								100
							).toFixed(3)
						);

						element['Actionable'] = modelElement['Actionable'];
						if (modelElement['Actionable'] == 'Yes') {
							element['Suggested Trade'] = String(
								Math.round(
									Math.abs(
										(element['Current Weight'] -
											element['Prescribed Weight']) *
											total
									) /
										element['CMP'] /
										100
								)
							);
							if (element['Delta'] < element['Ratio Start Pt'])
								element['Suggested Trade'] += '(buy)';
							if (element['Delta'] > element['Ratio End Pt'])
								element['Suggested Trade'] += '(sell)';
						} else {
							element['Actionable'] = 'No';
							element['Suggested Trade'] = '-';
						}
					});
				});
				let obj_portfolio = {};
				obj_portfolio['model_portfolio'] = model;
				obj_portfolio['portfolio_name'] = noOfShares['name'];
				if (noOfShares['version'] < 1) {
					obj_portfolio['inception_value'] = total;
				}
				obj_portfolio['version'] = noOfShares['version'] + 1;
				portfolioModel.push(obj_portfolio);
			});
		}
		setPortfolioInceptionVal(total);
		return portfolioModel;
	};

	const processModel = (tableData) => {
		let total = 0;
		let model = [];
		tableData.forEach((element, index) => {
			let obj = {};

			obj['id'] = element['id'];

			obj['ISIN Number'] = element['ISIN Number'];

			obj['Name of Securities'] = element['Name of Securities'];

			obj['Asset Class'] = element['Asset Class'];

			obj['Sub Asset Class'] = element['Sub Asset Class'];

			// obj['Marketcap'] = element['Marketcap'];

			obj['CMP'] = String(element['CMP']);

			obj['NSE Symbol'] = element['NSE Symbol'];

			obj['Ratio Start Pt'] = element['Ratio Start Pt'];

			obj['Ratio End Pt'] = element['Ratio End Pt'];

			obj['Prescribed Weight'] = String(element['Prescribed Weight']);

			obj['No of Shares'] = Number(element['No of Shares']);

			obj['Price'] = element['Price'];

			obj['Current Value'] = Number(
				obj['No of Shares'] * Number(obj['CMP'])
			);
			total += obj['Current Value'];
			if (element['User Action'] !== undefined) {
				obj['User Action'] = element['User Action'];
			}
			model.push(obj);
		});
		setTotalCurrVal(Number(total.toFixed(3)));
		model.map((element) => {
			element['Current Weight'] = Number((
				((element['No of Shares'] * Number(element['CMP'])) / total) *
					100
			).toFixed(6));
			element['Delta'] = Number(
				(
					((((element['No of Shares'] * Number(element['CMP'])) /
						total) *
						100) /
						Number(element['Prescribed Weight'])) *
					100
				).toFixed(3)
			);
			if (!element['Name of Securities'].toLowerCase().includes('cash')) {
				if (
					element['Delta'] < element['Ratio Start Pt'] ||
					element['Delta'] > element['Ratio End Pt']
				) {
					element['Actionable'] = 'Yes';
					if (element['User Action'] === undefined) {
						element['User Action'] = element['Actionable'];
					}
				} else {
					element['Actionable'] = 'No';
				}

				if (element['User Action'] === 'Yes') {
					element['Suggested Trade'] = (
						Math.round(
							Math.abs(
								(Number((((element['No of Shares'] *
									Number(element['CMP'])) /
									total) *
									100).toFixed(6)) -
									element['Prescribed Weight']) *
									total
							) /
								element['CMP'] /
								100
						)
					);
					if (element['Suggested Trade'] === undefined) {
						element['Suggested Trade'] = 0;
					}
					if (element['Suggested Trade'] >= 1) {
						element['Suggested Trade'] = String(element['Suggested Trade']);
						if (element['Delta'] < 100) {
							element['Buy Sell'] = 'Buy';
						} else if (element['Delta'] > 100) {
							element['Buy Sell'] = 'Sell';
						}
					} else {
						element['Buy Sell'] = '-';
					}
					element['Price'] = 'Market Price';
				} else {
					element['Suggested Trade'] = '-';
					element['Price'] = '-';
				}
				if (element['User Action'] === undefined) {
					element['User Action'] = element['Actionable'];
				}
				if (thresholdReload !== undefined) {
					element['User Action'] = element['Actionable'];
				}
			}
		});

		return model;
	};


	const handleRefresh = () => {
		setRefresh(Math.random());
		portfolioTableData(portfolioName);
		setFileUploadDisable(false);
		setFundsButtonDisable(false);
		setButtonDisable(true);
	};

	const exportToCSV = () => {
		let apiData = [];
		let filename;
		if (relatedCustomerPortfolioDropdownData[0].cust_funds_log.length) {
			relatedCustomerPortfolioDropdownData[0].cust_funds_log.map(
				(item) => {
					const created_date = new Date(
						item.createdAt
					).toLocaleDateString('en', {
						day: 'numeric',
						month: '2-digit',
						year: 'numeric',
					});
					let obj = {
						'Portfolio Name':
							relatedCustomerPortfolioDropdownData[0]
								.portfolio_name,
						'Added Funds': item.funds_added,
						'Funds Added date': created_date,
					};
					apiData.push(obj);
				}
			);
			filename =
				'added_funds_log' +
				relatedCustomerPortfolioDropdownData[0].portfolio_name +
				new Date().toLocaleDateString('en', {
					day: 'numeric',
					month: '2-digit',
					year: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
					second: 'numeric',
				});
		}
		const fileType =
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		const fileExtension = '.xlsx';
		const ws = XLSX.utils.json_to_sheet(apiData);
		const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, filename + fileExtension);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		let jsonRequestBody = {};
		jsonRequestBody['funds_added'] = fundsAdd;
		jsonRequestBody['updatedBy'] =
			userInfo.first_name +
			' ' +
			userInfo.middle_name +
			' ' +
			userInfo.last_name +
			'' +
			'(' +
			userInfo.id +
			')';

		let modelNew;
		if (thresholdReload !== undefined) {
			modelNew = processModel(tableData);
		}
		if (thresholdReload !== undefined) {
			jsonRequestBody['model'] = modelNew;
		} else {
			jsonRequestBody['model'] = model;
		}
		// }
		setButtonGenerateReportDisabled(false);
		ApiServices.updateFundsGlobally(jsonRequestBody, portfolioName)
			.then((res) => {
				toast.success(`Funds Updated Successfully..`);
				setClientName('');
				setPortfolioName('');
				setFundsAdd('');
				setCashAvailable(numberWithCommas('0'));
				setAssetList([]);
				settableData([]);
			})
			.catch((err) => {
				console.log(err);
			});

		if (portfolioModel.length > 0) {
			ApiServices.updateModelCustomerPortfolio(portfolioModel)
				.then((res) => {
					console.log(res.data);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	const processUserActionChangeMath = (tabFundsData, i) => {
		let newTableFund = [];
		let index = tabFundsData.findIndex(
			(ele) => ele['Name of Securities'].toLowerCase().includes('cash')
		);
		let temp = Number(
			Math.round(
				Number(
					(totalCurrValTrade * tabFundsData[i]['Current Weight']) /
						100 /
						tabFundsData[i]['CMP']
				)
			) * tabFundsData[i]['CMP']
		);
		if (tabFundsData[i]['User Action'] === 'No') {
			tabFundsData[i]['Suggested Trade'] = '-';
			tabFundsData[i]['Suggested Amount Allocation'] = 0;
			tabFundsData[i]['Buy Sell'] = '-';
			newTableFund = [
				...tabFundsData.slice(0, index),
				Object.assign({}, tabFundsData[index], {
					'No of Shares':
						tabFundsData[index]['Suggested Amount Allocation'] +
						temp,
					'Suggested Amount Allocation': Number(
						(
							tabFundsData[index]['Suggested Amount Allocation'] +
							temp
						).toFixed(2)
					),
					'Suggested Trade': String(
						(
							tabFundsData[index]['Suggested Amount Allocation'] +
							temp
						).toFixed(2)
					),
				}),
				...tabFundsData.slice(index + 1),
			];
		} else if (tabFundsData[i]['User Action'] === 'Yes') {
			tabFundsData[i]['Suggested Trade'] = Math.round(
					Number(
						(totalCurrValTrade *
							tabFundsData[i]['Current Weight']) /
							100 /
							tabFundsData[i]['CMP']
					)
				);
			tabFundsData[i]['Suggested Amount Allocation'] = Number(
				(
					Math.round(
						Number(
							(totalCurrValTrade *
								tabFundsData[i]['Current Weight']) /
								100 /
								tabFundsData[i]['CMP']
						)
					) * tabFundsData[i]['CMP']
				).toFixed(2)
			);

			if (tabFundsData[i]['Suggested Trade'] === undefined) {
				tabFundsData[i]['Suggested Trade'] = 0;
				tabFundsData[i]['Suggested Amount Allocation'] = 0;
			}
			if (tabFundsData[i]['Suggested Trade'] > 0) {
				tabFundsData[i]['Suggested Trade'] = String(Math.abs(tabFundsData[i]['Suggested Trade']));
				tabFundsData[i]['Buy Sell'] = 'Buy';
			} else if (tabFundsData[i]['Suggested Trade'] === 0) {
				tabFundsData[i]['Suggested Trade'] = String(
					tabFundsData[i]['Suggested Trade']
				);
				tabFundsData[i]['Buy Sell'] = '-';
			} else {
				tabFundsData[i]['Suggested Trade'] = String(Math.abs(tabFundsData[i]['Suggested Trade']));
				tabFundsData[i]['Buy Sell'] = 'Sell';
			}
			newTableFund = [
				...tabFundsData.slice(0, index),
				Object.assign({}, tabFundsData[index], {
					'No of Shares':
						tabFundsData[index]['Suggested Amount Allocation'] -
						temp,
					'Suggested Amount Allocation': Number(
						(
							tabFundsData[index]['Suggested Amount Allocation'] -
							temp
						).toFixed(2)
					),
					'Suggested Trade': String(
						(
							tabFundsData[index]['Suggested Amount Allocation'] -
							temp
						).toFixed(2)
					),
				}),
				...tabFundsData.slice(index + 1),
			];
		}
		// }
		// })
		return newTableFund;
	};

	const onSaveUserActionDataHandler = (
		value,
		isin_number,
		nameOfSecurities
	) => {
		let index = tableFundsData.findIndex(
			(element) =>
				element['ISIN Number'] == isin_number &&
				element['Name of Securities'] == nameOfSecurities
		);
		tableFundsData[index]['User Action'] = value;

		settableFundsData([...tableFundsData]);
		let model = processUserActionChangeMath(tableFundsData, index);
		let TotalSuggestedAlloc = 0;
		model.forEach((ele) => {
			TotalSuggestedAlloc += ele['Suggested Amount Allocation'];
		});
		setTotalSuggestedAllocation(TotalSuggestedAlloc.toFixed(3));
		settableFundsData(model);
	};

	const onSaveActionDataHandler = (value, isin_number, nameOfSecurities) => {
		let index = tableData.findIndex(
			(element) =>
				element['ISIN Number'] == isin_number &&
				element['Name of Securities'] == nameOfSecurities
		);
		tableData[index]['User Action'] = value;

		settableData([...tableData]);
		let model = processModel(tableData);

		setFundsButtonDisable(true);
		setButtonDisable(false);
		setModel(model);
		settableData([]);
		model.forEach((element) => {
			settableData((tableData) => [...tableData, element]);
		});
	};

	const handleCMP = (val, isinNumber, nameOfSecurities) => {
		let index = tableData.findIndex(
			(element) =>
				element['ISIN Number'] == isinNumber ||
				element['Name of Securities'] == nameOfSecurities
		);
		tableData[index]['CMP'] = val.replace(/,/g, '');
		tableData.map((ele) => {
			ele['User Action'] = undefined;
		});
		settableData([...tableData]);
		let model = processModel(tableData);

		setFundsButtonDisable(true);
		setButtonDisable(false);
		setModel(model);
		settableData([]);
		model.forEach((element) => {
			settableData((tableData) => [...tableData, element]);
		});
	};

	const handleShares = (val, isinNumber, nameOfSecurities) => {
		if (val.length > 0) {
			let index = tableData.findIndex(
				(element) =>
					element['ISIN Number'] == isinNumber ||
					element['Name of Securities'] == nameOfSecurities
			);
			tableData[index]['No of Shares'] = parseFloat(
				val.replace(/,/g, '')
			);
		} else {
			let index = tableData.findIndex(
				(element) =>
					element['ISIN Number'] == isinNumber ||
					element['Name of Securities'] == nameOfSecurities
			);
			tableData[index]['No of Shares'] = parseFloat('0');
		}
		tableData.map((ele) => {
			ele['User Action'] = undefined;
		});
		settableData([...tableData]);
		let model = processModel(tableData);
		setFundsButtonDisable(true);
		setButtonDisable(false);

		setModel(model);
		settableData([]);
		model.forEach((element) => {
			settableData((tableData) => [...tableData, element]);
		});
	};

	const handlePrescribedWeight = (val, isinNumber, nameOfSecurities) => {
		let index = tableData.findIndex(
			(element) =>
				element['ISIN Number'] == isinNumber ||
				element['Name of Securities'] == nameOfSecurities
		);
		tableData[index]['Prescribed Weight'] = val;
		tableData.map((ele) => {
			ele['User Action'] = undefined;
		});
		settableData([...tableData]);
		let model = processModel(tableData);
		setFundsButtonDisable(true);
		setButtonDisable(false);

		let prescribedWeightTotal = 0;
		model.forEach((element) => {
			prescribedWeightTotal += Number(element['Prescribed Weight']);
		});

		if (prescribedWeightTotal != 100) {
			let index = model.findIndex(
				(ele) => ele['Name of Securities'] == 'Cash'
			);
			model[index]['Prescribed Weight'] = (
				Number(model[index]['Prescribed Weight']) +
				(100 - prescribedWeightTotal)
			).toFixed(3);
		}

		setModel(model);
		settableData([]);
		model.forEach((element) => {
			settableData((tableData) => [...tableData, element]);
		});
	};

	const handleRatioStartPt = (val, isinNumber, nameOfSecurities) => {
		let index = tableData.findIndex(
			(element) =>
				element['ISIN Number'] == isinNumber ||
				element['Name of Securities'] == nameOfSecurities
		);
		tableData[index]['Ratio Start Pt'] = val.replace(/,/g, '');
		if (tableData[index]['Price'] === '-') {
			tableData[index]['Price'] = 'Market Price';
		}
		tableData.map((ele) => {
			ele['User Action'] = undefined;
		});
		settableData([...tableData]);
		let model = processModel(tableData);

		setFundsButtonDisable(true);
		setButtonDisable(false);
		setModel(model);
		settableData([]);
		model.forEach((element) => {
			settableData((tableData) => [...tableData, element]);
		});
	};

	const handleRatioEndPt = (val, isinNumber, nameOfSecurities) => {
		let index = tableData.findIndex(
			(element) =>
				element['ISIN Number'] == isinNumber ||
				element['Name of Securities'] == nameOfSecurities
		);
		tableData[index]['Ratio End Pt'] = val.replace(/,/g, '');
		tableData.map((ele) => {
			ele['User Action'] = undefined;
		});
		settableData([...tableData]);
		let model = processModel(tableData);

		setFundsButtonDisable(true);
		setButtonDisable(false);
		setModel(model);
		settableData([]);
		model.forEach((element) => {
			settableData((tableData) => [...tableData, element]);
		});
	};

	const createTableData = (data) => {
		let tableDataLocal = [];
		let cust_portfolio = [];

		Object.entries(data).forEach((el) => {
			tableDataLocal.push(el[1]);
		});
		let model = processModel(tableDataLocal);

		ApiServices.getModelCustomerPortfolio(model_name).then(
			(res) => {
				setIsLoading(false);

				res['customer_portfolio'].map((el) => {
					cust_portfolio.push({
						name: el['portfolio_name'],
						noOfShares: 0,
						version: el['version'],
					});
				});

				if (cust_portfolio.length) {
					let portfolioModel = processPortfolio(
						tableDataLocal,
						cust_portfolio,
						model
					);
					setPortfolioModel(portfolioModel);
				}
			},
			(error) => {}
		);

		setModel(model);
		settableData([]);

		model.forEach((element) => {
			settableData((tableData) => [...tableData, element]);
		});
		generateAssetClass(model);
	};


	const modalTestHandler = (event) => {
		// event.preventDefault();
		setModal({
			title: props.title,
			message: props.message,
		});
	};

	const cancelHandler = () => {
		setFundsAdd('');
		setCashAvailable('');
		setModal(null);
		setAmount('');
		settableFundsData([]);
		portfolioTableData(portfolioName);
		setRefresh(Math.random());
	};

	const applyHandler = () => {
		let newTableData = [];
		let updateCashInPortfolio;
		tableFundsData.map((element) => {
			let obj = {};
			if (!element['Name of Securities'].toLowerCase().includes('cash')) {
				obj['ISIN Number'] = element['ISIN Number'];
				obj['Name of Securities'] = element['Name of Securities'];
				obj['CMP'] = element['CMP'];
				obj['Asset Class'] = element['Asset Class'];
				obj['Sub Asset Class'] = element['Sub Asset Class'];
				obj['Suggested Amount Allocation'] = Math.abs(
					element['Suggested Amount Allocation']
				);
				if (
					element['User Action'] === undefined ||
					element['User Action'] === 'Yes'
				) {
					obj['User Action'] = 'Yes';
				} else {
					obj['User Action'] = element['User Action'];
				}
				obj['Suggested Trade'] = element['Suggested Trade'];
				obj['Buy Sell'] = element['Buy Sell'];
				if (element['User Action'] === 'No') {
					obj['Price'] = '-';
				} else {
					obj['Price'] = element['Price'];
				}
				newTableData.push(obj);
			} else {
				obj['ISIN Number'] = element['ISIN Number'];
				obj['Name of Securities'] = element['Name of Securities'];
				obj['Asset Class'] = element['Asset Class'];
				obj['Sub Asset Class'] = element['Sub Asset Class'];
				// obj['Marketcap'] = element['Marketcap'];
				obj['CMP'] = element['CMP'];
				obj['Ratio Start Pt'] = element['Ratio Start Pt'];
				obj['Ratio End Pt'] = element['Ratio End Pt'];
				obj['Prescribed Weight'] = element['Prescribed Weight'];
				obj['Suggested Amount Allocation'] = Math.abs(
					element['Suggested Amount Allocation']
				);
				if (
					element['User Action'] === undefined ||
					element['User Action'] === 'Yes'
				) {
					obj['User Action'] = 'Yes';
				} else {
					obj['User Action'] = element['User Action'];
				}
				obj['Suggested Trade'] = element['Suggested Trade'];
				obj['Buy Sell'] = element['Buy Sell'];
				if (element['User Action'] === 'No') {
					obj['Price'] = '-';
				} else {
					obj['Price'] = element['Price'];
				}
				const Updated_No_Of_Shares = portfolioDataForAddFunds[0].customer_portfolio_securities.find((ele) => ele['Name of Securities'].toLowerCase().includes('cash'));
				console.log(Updated_No_Of_Shares['No of Shares'], element['Suggested Amount Allocation'], 'LOOK HEREEE');
				updateCashInPortfolio = {
					cash_id: element['id'],
					new_cash_amt: Math.round(element['Suggested Amount Allocation']),
				};
			}
		});
		const jsonRequestBody = {
			cust_email: relatedCustomerPortfolioDropdownData[0].user.email,
			broker_details:
				relatedCustomerPortfolioDropdownData[0].user.customer_profile
					.broker_details,
			funds_added: parseInt(fundsAdd),
			updatedBy:
				userInfo.first_name +
				' ' +
				userInfo.middle_name +
				' ' +
				userInfo.last_name +
				'' +
				'(' +
				userInfo.id +
				')',
			model: newTableData,
			updateCashInPortfolio: updateCashInPortfolio,
			source: 'Add Funds',
		};

		console.log('JSON REQUEST BODY', jsonRequestBody);

		ApiServices.updateFundsGlobally(jsonRequestBody, portfolioName)
			.then((res) => {
				toast.success(`${res.message}`);
			})
			.catch((err) => {
				console.log(err);
			});
		setButtonDisabled(false);
		setModal(null);
	};

	const addModelForm = () => (
		<form onSubmit={handleSubmit}>
			<div className="row">
				<div className="col-sm-3">
					<label className="form-label">
						<ShortTextIcon /> Name of the Client
					</label>

					<Form.Group>
						<CustomInput
							type="select"
							required
							onChange={onChangeClientNameHandler}
							value={clientName}
						>
							<option value="-1">Select Option</option>
							{customerDropdownData.map((ele) => (
								<option value={ele.id}>
									{ele.first_name +
										' ' +
										ele.middle_name +
										' ' +
										ele.last_name}
								</option>
							))}
						</CustomInput>
					</Form.Group>
				</div>

				<div className="col-sm-3">
					<label className="form-label">
						<ShortTextIcon /> Portfolio Name
					</label>

					<Form.Group>
						<CustomInput
							type="select"
							required
							onChange={onChangePortfolioNameHandler}
							value={portfolioName}
						>
							<option value="-1">Select Option</option>
							{allCustomerPortfolioDropdownData.map((ele) => (
								<option value={ele.portfolio_name}>
									{ele.portfolio_name}
								</option>
							))}
						</CustomInput>
					</Form.Group>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-3">
					<label className="form-label">
						<TodayIcon /> Funds Addition
					</label>

					<Form.Group controlId="fundsAdd">
						<Form.Control
							type="text"
							name="funds_add"
							placeholder="Funds Addition"
							value={toIndianCurrency(fundsAdd)}
							onChange={onChangeFundsAddition}
							disabled={fundsadditionDisabled}
						/>
						{fundsAdd ? (
							<span
								style={{
									color: 'GrayText',
									textTransform: 'capitalize',
								}}
							>
								{seeMore ? (
									<>
										{getString(dreds(fundsAdd), 'sub')}
										<span
											style={{
												fontWeight: 'bold',
											}}
											onClick={() => setSeeMore(false)}
										>
											.....
										</span>
									</>
								) : (
									getString(dreds(fundsAdd), 'full')
								)}
							</span>
						) : null}
					</Form.Group>
				</div>
			</div>
		</form>
	);

	const handleFundsChange = (amt) => {
		let tableFundsD;
		function updateItem(id, itemAttributes) {
			var index = tableFundsData.findIndex(
				(x) => x['Name of Securities'].toLowerCase() === id || x['Name of Securities'] === 'Cash & Cash Equivalents'
			);
			if (index === -1) {
				// handle error
			} else {
				tableFundsD = [
					...tableFundsData.slice(0, index),
					Object.assign({}, tableFundsData[index], {
						'Current Value': amt,
						'No of Shares': amt,
					}),
					...tableFundsData.slice(index + 1),
				];
			}
		}

		updateItem('cash');

		console.log(tableFundsD, 'TABLE FUNDS D');
		const modelNew = processModel(tableFundsD);
		let total = 0;
		let newTabData = [];
		modelNew.forEach((ele) => {
			let obj = {};
			let modelInfo =
				portfolioDataForAddFunds[0].portfolio_model.model_securities.map(
					(data) => ({
						'ISIN Number': data.ISIN_Number,
						'Name of Securities': data.Name_of_Securities,
						CMP: data.CMP,
						'No of Shares': data.No_of_Shares,
						'Prescribed Weight': data.Prescribed_Weight,
					})
				);
                        console.log("-----modelInfo------------", modelInfo);
			let modelData = processModel(modelInfo);
			let totalModel = 0;
                        console.log("-----modelData-----before--foreach-----", modelData);
			modelData.forEach((ele) => {
				totalModel += ele['No of Shares']* Number(ele['CMP'])
			})
                        console.log("-----modelData-----after--foreach-----", modelData);
                        console.log("--------------ele---------", ele);
			let selectCurrWt = modelData.filter(
				(element) => element['Name of Securities'] === ele['Name of Securities']
			);
                        console.log("-----selectCurrWt-------", selectCurrWt);
			obj['id'] = ele['id'];

			obj['ISIN Number'] = ele['ISIN Number'];

			obj['Name of Securities'] = ele['Name of Securities'];

			obj['Asset Class'] = ele['Asset Class'];

			obj['Sub Asset Class'] = ele['Sub Asset Class'];

			// obj['Marketcap'] = ele['Marketcap'];

			obj['CMP'] = String(ele['CMP']);

			obj['NSE Symbol'] = ele['NSE Symbol'];

			obj['Ratio Start Pt'] = ele['Ratio Start Pt'];

			obj['Ratio End Pt'] = ele['Ratio End Pt'];

			obj['Prescribed Weight'] = String(ele['Prescribed Weight']);

			obj['No of Shares'] = Number(ele['No of Shares']);

			obj['Price'] = 'Market Price';

			obj['Current Value'] = ele['No of Shares'] * Number(ele['CMP']);
			total += obj['Current Value'];
			if (!ele['Name of Securities'].toLowerCase().includes('cash')) {
				obj['Current Weight'] = Number(
					(
						Number(((selectCurrWt[0]['No of Shares'] * Number(selectCurrWt[0]['CMP'])/totalModel)*100).toFixed(6)) -
						Number(ele['Current Weight'])
					)
				);
			} else {
				obj['Current Weight'] = '-';
			}
			newTabData.push(obj);
		});

		console.log('NEW TAB DATA', newTabData);
		setTotalCurrValTrade(total);
		let total_suggested_allocation = 0;
		newTabData.map((element) => {
			let currWt = element['Current Weight'];
			if (!element['Name of Securities'].toLowerCase().includes('cash')) {
				element['Current Weight'] = Number((currWt).toFixed(3));
				element['Suggested Trade'] = Math.round(
					Number(
						(total * currWt) /
							100 /
							element['CMP']
					)
				);
				element['Suggested Amount Allocation'] = (Number(
					(
						Math.round(
							Number(
								(total * currWt) /
									100 /
									element['CMP']
							)
						) * element['CMP']
					).toFixed(3)
				));
				total_suggested_allocation +=
					Math.round(
						Number(
							(total * currWt) /
								100 /
								element['CMP']
						)
					) * element['CMP'];
				if (element['Suggested Trade'] > 0) {
					element['Suggested Trade'] = String(
						element['Suggested Trade']
					);
					element['Buy Sell'] = 'Buy';
				} else if (element['Suggested Trade'] === 0) {
					element['Suggested Trade'] = '-';
					element['User Action'] = 'No';
					element['Buy Sell'] = '-';
					element['Price'] = '-';
				} else {
					element['Suggested Trade'] = String(
						Math.abs(Number(element['Suggested Trade']))
					);
					element['Buy Sell'] = 'Sell';
				}
			}
		});


		newTabData.map((element) => {
			if (element['Name of Securities'].toLowerCase().includes('cash')) {
				element['Suggested Trade'] = (
					amt - total_suggested_allocation
				).toFixed(2);
				element['Suggested Amount Allocation'] = Number(
					(amt - total_suggested_allocation).toFixed(2)
				);
			}
		});

		let TotalSuggestedAlloc = 0;
		newTabData.forEach((ele) => {
			TotalSuggestedAlloc += ele['Suggested Amount Allocation'];
		});
		setTotalSuggestedAllocation(TotalSuggestedAlloc.toFixed(3));

		setfundCash(0);
		let fundCash = 0;

		settableFundsData([]);
		newTabData.forEach((element) => {
			settableFundsData((newTabData) => [...newTabData, element]);
		});

		setNewwTabData([]);
	};

	const addFundsModelForm = () => (
		<form>
			<div className="row">
				<div className="col-sm-6">
					<label className="form-label">
						<ShortTextIcon /> Funds Addition + Available Cash
					</label>

					<Form.Group controlId="threshold">
						<Form.Control
							type="text"
							className="form-control"
							placeholder="e.g. : 10000000"
							value={numberWithCommas(amount)}
							onChange={(e) => {
								handleFundsChange(e);
								setFundsAdd(amount);
							}}
							readOnly
						/>
					</Form.Group>
				</div>
				<div style={{ marginTop: '4.5%' }} className="col-sm-6"></div>
			</div>
		</form>
	);

	return (
		<>
			<div id="style_main">
				<Breadcrumb>
					<BreadcrumbItem active>Add Funds</BreadcrumbItem>
				</Breadcrumb>

				<div className="container">
					<div className="row">
						<div className="col-md-12 offset-md-3">
							{addModelForm()}
						</div>
					</div>
					<div style={{ textAlign: 'right' }}>
						{fundsAvailable > 0 ? (
							<button
								style={{ marginLeft: '1%' }}
								title="Funds Logs Report"
								className="btn btn-outline-success mt-0 mr-3"
								id="btn-success"
								onClick={(e) => exportToCSV()}
							>
								<DownloadIcon />
							</button>
						) : null}
						<button
							style={{ marginLeft: '1%' }}
							title="Refresh"
							className="btn btn-outline-success"
							id="btn-success"
							onClick={handleRefresh}
							disabled={buttonRecaliberateDisabled}
						>
							<RefreshIcon />
						</button>
						<button
							style={{ marginLeft: '1%' }}
							className="btn btn-outline-success"
							id="btn-success"
							onClick={onClickRecaliberateWt}
							disabled={fundsAdd?buttonRecaliberateDisabled:true}
						>
							Recaliberate Weight
						</button>

						<Wrapper>
							{modal && (
								<Modal
									title={modal.title}
									type="funds_added_globally"
									message={
										<div>
											<div className="row">
												<div className="col-md-12">
													{addFundsModelForm()}
												</div>
											</div>

											<GlobalFundsTable
												tableData={tableFundsData}
												COLUMNS={COLUMNS}
												handlePrice={handlePrice}
												onSaveUserActionDataHandler={
													onSaveUserActionDataHandler
												}
											/>
											<p
												style={{
													textAlign: 'right',
													fontWeight: 'bold',
													color: '#5d6a74',
												}}
											>
												Total Amount Allocation:{' '}
												{toIndianCurrency(
													totalSuggestedAllocation
												)}
											</p>
										</div>
									}
									onConfirm={applyHandler}
									onCancel={cancelHandler}
								/>
							)}

							{
								<form onSubmit={modalTestHandler}>
									{/* <Button disabled={fundsButtonDisable} type="submit">Add Funds</Button> */}
								</form>
							}
						</Wrapper>
					</div>
				</div>
				<div className="row">
                        <div className="col-sm-12">
                            {assetList.length ? (
                                <button
                                    id="dvwm-accordian-toggle"
                                    onClick={() => setIsExpandOne((prev) => !prev)}
                                >
                                    {isExpand_one
                                        ? 'Collapse All'
                                        : 'Expand All'}
                                </button>
                            ) : null}
                        </div>
			    </div>
				<div style={{ marginTop: '5%' }}>
					<div>
						{assetList.map((element, index) => (
							<AccordianComponent 
							title={<span style={{marginLeft: '10px', fontSize: '12px'}}>{<span style={{fontWeight:'bold', marginRight: '20px'}}>{element}</span> }</span>} isExpand={isExpand_one} content={
									<ModelTable
										type="SeperateAddFundsTable"
										tableData={tableData.filter((el) => {
											if (element != 'Cash')
												return (
													el['Asset Class'] == element
												);
											else
												return (
													el['Name of Securities'] ==
													element
												);
										})}
										COLUMNS={columns}
										handleCMP={handleCMP}
										handleShares={handleShares}
										handlePrescribedWeight={
											handlePrescribedWeight
										}
										handlePrice={handlePrice}
										handleRatioStartPt={handleRatioStartPt}
										handleRatioEndPt={handleRatioEndPt}
										onSaveActionData={
											onSaveActionDataHandler
										}
										defaultDisabled={false}
									/>
								}></AccordianComponent>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default AddFundsGlobal;

var tens = (num) => {
	var box = [
		'',
		'one',
		'two',
		'three',
		'four',
		'five',
		'six',
		'seven',
		'eight',
		'nine',
		'ten',
		'eleven',
		'twelve',
		'thirteen',
		'fourteen',
		'fifteen',
		'sixteen',
		'seventeen',
		'eighteen',
		'nineteen',
	];
	var box_ = [
		'twenty',
		'thirty',
		'fourty',
		'fifty',
		'sixty',
		'seventy',
		'eighty',
		'ninety',
	];
	if (num > 19 && num < 100) {
		var splitted = num
			.toString()
			.split('')
			.map((el) => Number(el));
		var ready = box_[splitted[0] - 2] + ' ' + tens(splitted[1]);
		return ready.trim();
	}
	return box[num];
};

const dreds = (num) => {
	if (Number(num) > 10000000000) return 'number too big';
	var modified = num.toString();
	var dredList = {
		3: ['hundred', 100],
		4: ['thousand', 1000],
		5: ['thousand', 1000],
		6: ['lakh', 100000],
		7: ['lakh', 100000],
		8: ['crore', 10000000],
		9: ['crore', 10000000],
		10: ['billion', 1000000000],
		11: ['billion', 1000000000],
	};

	if (modified.length > 2) {
		let callit = dredList[modified.length][0];
		let point = dredList[modified.length][1];
		let integer = Math.trunc(num / point);
		let devide = (num / point).toPrecision(modified.length);
		let decimal = devide.toString().split('.')[1];
		let toReturn =
			tens(integer) + ' ' + callit + ' ' + dreds(Number(decimal));

		return toReturn.trim();
	}
	return tens(num).trim();
};

const getString = (value, flag) => {
	if (flag === 'sub') {
		return value.substring(0, 30);
	}
	if (flag === 'full') {
		return value;
	}
};
