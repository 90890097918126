

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useUserInfo } from '../ContextProviders/UserInfo';
import './profile.css';

const Profile = () => {
	const userInfo = useUserInfo();
	const item = userInfo.role.role_name;
	if (!userInfo) {
		return <Redirect to="/login" />;
	}

	return (
		<div className="container" id="profile-all-items">
			{/* <header className="jumbotron">
        <h3>
          <strong>{currentUser.username}</strong> Profile
        </h3>
      </header> */}

			<div
				className="shadow-none mb-3 rounded"
				style={{ textAlign: 'center' }}
			>
				<h3
					style={{
						marginTop: '110px',
						width: '100%',
						backgroundColor: '#f5f3f3',
						fontFamily: 'sans-serif',
					}}
					className="pt-2 pb-2"
				>
					Profile Setting
				</h3>
			</div>

			<div className="container-top">
				<Link
					to={{
						pathname: `/updateprofile/${userInfo.id}`,
						state: {
							data: {
								id: userInfo.id,
								designation: userInfo.designation,
								email: userInfo.email,
								first_name: userInfo.first_name,
								middle_name: userInfo.middle_name,
								last_name: userInfo.last_name,
								phone: userInfo.phone,
								role_id: userInfo.role_id,
							},
						},
					}}
				>
					<div
						class="shadow-sm p-2 mb-5 rounded"
						id="profile-box-item"
					>
						<p className="profile-item-style">
							<i
								className="fa fa-user-circle-o"
								aria-hidden="true"
								id="profile-data-icon"
							></i>
							Update Profile
						</p>
					</div>
				</Link>

				<Link
					to={{
						pathname: `/changepassword/${userInfo.id}`,
						state: { data: {} },
					}}
				>
					<div
						class="shadow-sm p-2 mb-5 rounded"
						id="profile-box-item"
					>
						<p className="profile-item-style">
							<i
								className="fa fa-lock"
								aria-hidden="true"
								id="profile-data-icon"
							></i>
							Change Password
						</p>
					</div>
				</Link>
			</div>
			
				<div className="container-top">
					<Link to={{ pathname: `/managecustomers` }}>
						<div
							class="shadow-sm p-2 mb-5 rounded"
							id="profile-box-item"
						>
							<p className="profile-item-style">
								<i
									className="fa fa-users"
									aria-hidden="true"
									id="profile-data-icon"
								></i>
								Manage Customers
							</p>
						</div>
					</Link>

					

					{item === 'super-admin' && 
						(<Link to={{ pathname: `/manageusers` }}>
							<div
								class="shadow-sm p-2 mb-5 rounded"
								id="profile-box-item"
							>
								<p className="profile-item-style">
									<i
										className="fa fa-user-o"
										aria-hidden="true"
										id="profile-data-icon"
									></i>
									Manage Users
								</p>
							</div>
						</Link>)
					}
				</div>

			<div className="container-top">
					<Link to={{ pathname: `/managebroker` }}>
						<div
							class="shadow-sm p-2 mb-5 rounded"
							id="profile-box-item"
						>
							<p className="profile-item-style">
								<i
									className="fa fa-users"
									aria-hidden="true"
									id="profile-data-icon"
								></i>
								Manage Broker
							</p>
						</div>
					</Link>

					<Link to={{ pathname: `/communication-channel` }}>
						<div
							class="shadow-sm p-2 mb-5 rounded"
							id="profile-box-item"
						>
							<p className="profile-item-style">
								Communication Channel
							</p>
						</div>
					</Link>
			</div>

			<div className="container-top">
				<Link to={{ pathname: `/other-settings` }}>
					<div
						class="shadow-sm p-2 mb-5 rounded"
						id="profile-box-item"
					>
						<p className="profile-item-style">
							<i
								className="fa fa-ellipsis-v"
								aria-hidden="true"
								id="profile-data-icon"
							></i>
							other settings
						</p>
					</div>
				</Link>

				<Link to={{ pathname: `/update-address` }}>
					<div
						class="shadow-sm p-2 mb-5 rounded"
						id="profile-box-item"
					>
						<p className="profile-item-style">
							<i
								className="fa fa-ellipsis-v"
								aria-hidden="true"
								id="profile-data-icon"
							></i>
							Company Address
						</p>
					</div>
				</Link>
			</div>
			
		</div>
	);
};

export default Profile;
