const COLUMNS = [
    // {
    //     Header: 'Sr. No.',
    //     accessor: 'Sr_no',
    // },
    {
        Header: 'Trade ID',
        accessor: 'trade_advice_code',
    },
    {
        Header: 'Client Name',
        accessor: properties => properties.source === 'Add Funds' ? (properties.customer_portfolio.user.middle_name === "" ? properties.customer_portfolio.user.first_name + ' ' + properties.customer_portfolio.user.last_name : properties.customer_portfolio.user.first_name + ' ' + properties.customer_portfolio.user.middle_name + ' ' + properties.customer_portfolio.user.last_name) : "N.A.",
        // Cell: ({ row }) => {
        //     return(
        //         (row.original.source === 'Add Funds') ? <p>{row.original.customer_portfolio.user.first_name + " " + row.original.customer_portfolio.user.middle_name + " " + row.original.customer_portfolio.user.last_name}</p> : <p>N.A.</p>
        //     )
        // }
    },
    {
        Header: 'Portfolio Name',
        accessor: properties => properties.source === 'Add Funds' ? properties.customer_portfolio.portfolio_name : "N.A.",
        // Cell: ({ row }) => {
        //     return(
        //         (row.original.source === 'Add Funds') ? <p>{row.original.customer_portfolio.portfolio_name}</p> : <p>N.A.</p>
        //     )
        // }
    },
    {
        Header: 'Model Name',
        accessor: 'model_name',
    },
    {
        Header: 'Source',
        accessor: 'source'
    },
    // {
    //     Header: 'Verified By',
    //     accessor: 'verifiedBy'
    // },
    {
        Header: 'Report Status',
        accessor: properties => (properties.reportVerified === null) ? "Pending" : properties.reportVerified,
        // Cell: ({ row }) => {
        //     return(
        //         (row.original.reportVerified === null) ? <p>Pending</p> : <p>{row.original.reportVerified}</p>
        //     )
        // }
    },
    {
        Header: 'Reason',
        accessor: 'reason',
        Cell: ({ row }) => {
            return(
                (row.original.reason === null) ? <p>-</p> : <p>{row.original.reason}</p>
            )
        }
    },
]

export default COLUMNS;