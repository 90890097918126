const COLUMNS = [
    // {
    //     Header: 'Sr. No.',
    //     accessor: 'Sr_no',
    // },
    {
        Header: 'Trade ID',
        accessor: 'trade_advice_code',
    },
    {
        Header: 'Client Name',
        accessor: properties => properties.customer_portfolio.user.middle_name === "" ? properties.customer_portfolio.user.first_name + ' ' + properties.customer_portfolio.user.last_name : properties.customer_portfolio.user.first_name + ' ' + properties.customer_portfolio.user.middle_name + ' ' + properties.customer_portfolio.user.last_name,
    },
    {
        Header: 'Portfolio Name',
        accessor: 'portfolio_name',
    },
    // {
    //     Header: 'Model Name',
    //     accessor: 'model_name'
    // },
    {
        Header: 'Source',
        accessor: 'source'
    },
    {
        Header: 'Super Admin Approval',
        accessor: 'super_admin_approval'
    },
    {
        Header: 'Customer Verification Status',
        accessor: properties => (properties.reportVerified === null) ? "Pending" : properties.reportVerified,
        // Cell: ({ row }) => {
        //     return(
        //         (row.original.reportVerified === null) ? <p>Pending</p> : <p>{row.original.reportVerified}</p>
        //     )
        // }
    },
    {
        Header: 'Customer Consent Date',
        accessor: properties => properties.reportVerified === "Accepted" ? new Date(properties.updatedAt).toLocaleString('en-IN', { day: 'numeric', month: 'numeric',year: 'numeric' }) : "Pending",
        // Cell: ({ row }) => {
        //     let Consent_Date = new Date(row.original.updatedAt).toLocaleString('en-IN', { day: 'numeric', month: 'numeric',year: 'numeric' });
        //     return(
        //         (row.original.reportVerified === 'Accepted') ? <p>{Consent_Date}</p> : <p>Pending</p>
        //     )
        // }
    },
]

export default COLUMNS;