import { useState, useEffect } from "react";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";

let superAdminArr = [
    {
        date: "12-01-2022",
    },
    {
        date: "13-01-2022",
    },
    {
        date: "14-01-2022",
    },
    {
        date: "15-01-2022",
    },
    {
        date: "16-01-2022"
    }
]

const TradeMISMainPage = () => {
    return(
        <>
        <div className="container" style={{ marginTop: '100px' }}>
            <Breadcrumb>
                    <BreadcrumbItem><Link to={{pathname: `/modport`}}>Models</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Update Model</BreadcrumbItem>
            </Breadcrumb>
            <div>
            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>Accordion Item #1</Accordion.Header>
                <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                est laborum.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>Accordion Item #2</Accordion.Header>
                <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                est laborum.
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            </div>
        </div>
        </>
    )
}

export default TradeMISMainPage;