import {Link} from 'react-router-dom';
export const COLUMNS = [
    { 
    Header: 'ID', 
    accessor: 'id'
    },
    {
    Header: 'Broker House',
    accessor: properties => properties.broker_house,
    Cell: ({ row }) => {
        return(
            <Link to={{pathname: `/viewbroker/${row.original.id}`, state: {data : row.original}}}>{row.original.broker_house }</Link>
        )
        }
    },
    {
    Header: 'Mobile Number',
    accessor: properties => !properties.contact[0].number? <p style={{color: 'red'}}>Not Filled Yet</p> : <p>{properties.contact[0].number}</p>
    },
    {
    Header: 'Email address',
    accessor: properties => !properties.mail[0].email ? <p style={{color: 'red'}}>Not Filled Yet</p> : <p>{properties.mail[0].email}</p>
    },
   ]