import * as types from './index';

export const getAllSubAssetMaster = () => {
  console.log("In sub--asset get master")
    return {
      type: types.SUB_ASSETS_REQUEST
    }
  };

export const receiveSubAssetMasterList = subAssetMasterList => {
    console.log("assetMasterListReceived Daa", subAssetMasterList)
    return{
        type : types.ASSET_RECEIVED,
        subAssetMasterList
    }
} 

// export const postSubAssetMaster = assetName => {
//   console.log("assetMaster------------Save",assetName)
//   return{
//     type: types.ADD_ASSET,
//     assetName
//   }
// }

// export const deleteAsset = assetId => {
//   console.log("assetId-----------delete",assetId)
//   return{
//     type: types.DELETE_ASSET,
//     assetId
//   }
// }

// export const editAsset = assetId => {
//   console.log("assetId-----------delete",assetId)
//   return{
//     type: types.EDIT_ASSET,
//     assetId
//   }
// }

// export const requestAsset = assetId => {
//   return{
//     type:types.ASSET_REQUEST,
//     assetId
//   }
// }

// export const requestUpdateAsset = (updatedAsset, assetId) => {
// return {
//    type: types.UPDATE_ASSET, updatedAsset, assetId 
// }
// }

