import { useState, useEffect } from "react";
import axios from 'axios';
import { Button, FormGroup, Label, CustomInput, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import '../form.css';

import ApiServices from "../../ApiService/ApiConfig";

// material-ui icons
import ShortTextIcon from '@material-ui/icons/ShortText';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import { Link } from "react-router-dom";


const ViewUser = (props) => {
  
  // imported data
  console.log("state-data",props.location.state.data);
  const Cust_Data = props.location.state.data;
  // const fname = Cust_Data.employee_name.substr(0,Cust_Data.employee_name.indexOf(' '));
  // const lname = Cust_Data.employee_name.substr(Cust_Data.employee_name.indexOf(' ')+1);
  const fname = Cust_Data.first_name;
  const mname = Cust_Data.middle_name;
  const lname = Cust_Data.last_name;
  const emailId = Cust_Data.email;
  const phone = Cust_Data.phone;
  const designationE = Cust_Data.designation;
  const user_role = Cust_Data.user_role;

  // states
  const [firstname, setFirstname] = useState(fname);
  const [middleName, setMiddleName] = useState(mname);
  const [lastName, setLastName] = useState(lname);
  const [mobileNumber, setMobileNumber] = useState(phone);
  const [email, setEmail] = useState(emailId);
  const [designation, setDesignation] = useState(designationE);
  const [role, setRole] = useState(Cust_Data.role.role_name);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   console.log({ 
  //     // firstname, middleName, lastName,
  //     fname, lname, emailId, phone, 
  //     mobileNumber, email, designation, role});
  // };

  useEffect(() => {
    const getData = async () => {
      ApiServices.listUser({
        "first_name": firstname,
        "middle_name": middleName,
        "last_name": lastName,
        "phone": mobileNumber,
        "email": email,
        "designation": designation,
        "role": role
      })
      .then(res => {
        console.log(res.data);
      }).catch((err) => {
        console.log(err);
      })
    };
    getData();
  }, [])

  const ViewUserForm = () => (
    <form 
    // onSubmit={handleSubmit} 
    className="mt-3">
      
      <div className="form-group mb-3">
        <label className="form-label required"><ShortTextIcon/> First Name</label>
        <input
          type="text"
          className="form-control"
          placeholder="First Name"
          id="first"
          value={firstname}
          onChange={(e) => setFirstname(e.target.value)}
          required
          readonly = "readonly"
        />
      </div>
      <div className="form-group mb-3">
        <label className="form-label required"><ShortTextIcon/> Middle name</label>
        <input
          type="text"
          className="form-control"
          placeholder=""
          value={middleName}
          onChange={(e) => setMiddleName(e.target.value)}
          required
          readonly = "readonly"
        />
      </div>
      <div className="form-group mb-3">
        <label className="form-label required"><ShortTextIcon/> Last name</label>
        <input
          type="text"
          className="form-control"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
          readonly = "readonly"
        />
        <div className="form-group mb-3">
        <label className="form-label required"><PhoneIphoneIcon/> Mobile Number</label>
        <input
          type="text"
          min="10"
          className="form-control"
          placeholder="Mobile Number"
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
          required
          readonly = "readonly"
        />
      </div>
      </div>
      <div className="form-group mb-3">
        <label className="form-label required"><EmailOutlinedIcon/> Email address</label>
        <input
          type="email"
          className="form-control"
          placeholder="Enter email"
          value="akshay23@gmail.com"
          name={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          readonly = "readonly"
        />
      </div>
      <div className="form-group mb-3">
        <label className="form-label required"><ShortTextIcon/> Designation</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Designation For Users"
          value={designation}
          onChange={(e) => setDesignation(e.target.value)}
          required
          readonly = "readonly"
        />
      </div>
      <div className="form-group mb-3">
        <label className="form-label required"><i class="fas fa-user-tie"></i> Role</label>
        <input
          type="text"
          className="form-control"
          value={role}
          name={role}
          onChange={(e) => setRole(e.target.value)}
          required
          readonly = "readonly"
        />
      </div>
    </form>
  );

  return (
    <>
    <div className="container" id="adjust-top">
      <Breadcrumb>
        <BreadcrumbItem><Link to={{pathname: `/user`}}>Users</Link></BreadcrumbItem>
        <BreadcrumbItem active>View User</BreadcrumbItem>
      </Breadcrumb>
      <div className="container-fluid bg-light p-3 text-center">
        <h1>View User</h1>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">{ViewUserForm()}</div>
        </div>
      </div>
    </div>  
    </>
  );
};

export default ViewUser;
