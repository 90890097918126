import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ApiServices from "../../ApiService/ApiConfig";
import { useUserInfoUpdate } from "../../ContextProviders/UserInfo";
import LoadingSpinner from "../../components/UI/Loader";
import userimage from "../../../src/images/DVIA_Logo.jpeg";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default function Login({ history }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const updateUser = useUserInfoUpdate();

  function checkAPI() {
    ApiServices.checkAPI()
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    checkAPI();
  }, []);

  function onEmailChange(event) {
    setEmail(event.target.value);
  }

  function onPasswordChange(event) {
    setPassword(event.target.value);
  }

  function onLogin() {
    setLoading(true);
    if (email && password) {
      ApiServices.login({ email, password }).then((res) => {
        if (!res.token) {
          // toast(res.message, {
          //     position: "top-center",
          //     autoClose: 5000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //     });
          toast.warning(res.message);
          // toast.warning(res.message)
        } else {
          updateUser(res);
          localStorage.setItem("token", res.token);
          history.push("/home");
          toast.success(`Login Successful.`);
        }
        setLoading(false);
      });
    } else if (!email && !password) {
      toast.warning(`Please Enter Email And Password.`);
      setLoading(false);
    } else if (!email) {
      toast.warning(`Please Enter Email.`);
      setLoading(false);
    } else {
      toast.warning(`Please Enter Password.`);
      setLoading(false);
    }
  }

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      onLogin();
    }
  };

  return (
    <div className="col-md-12" style={{ marginTop: "110px" }}>
      <div className="card card-container">
        <img src={userimage} alt="profile-img" className="profile-img-card" />
        <div className="form-group">
          <label htmlFor="email" id="username">
            Username
          </label>
          <input
            type="text"
            className="form-control"
            name="email"
            value={email}
            onChange={onEmailChange}
            validations={[required]}
          />
        </div>

        <div className="form-group">
          <label htmlFor="password" id="password">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            name="password"
            value={password}
            onChange={onPasswordChange}
            onKeyPress={handleKeypress}
            validations={[required]}
          />
        </div>

        <div className="form-group">
          <button
            onClick={onLogin}
            className="btn btn-primary btn-block mt-3 mb-2"
            disabled={loading}
          >
            {loading && <LoadingSpinner loading={loading} />}
            <span>Login</span>
          </button>
          <Link to="/forgot-password">
            <span id="forget-pass">Forgot Password ?</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
