import React, { useState } from 'react';
import ApiServices from '../../../ApiService/ApiConfig';

function AddSubAsset({ handleModel, setDependency, list }) {
	const [asset, setAsset] = useState('');
	const [message, setMessage] = useState('');

	const submitHandler = async () => {
		if (!asset.trim()) return setMessage('please fill it to submit');
		let list_els = list.map((el) => el.asset);
		if (list_els.includes(asset)) return setMessage('asset already exits');
		let response = await ApiServices.AddSubAssetToList({ asset });
		setDependency(response);
		setAsset('');
		handleModel(false);
	};
	return (
		<div className="add-subasset-modal">
			<div>
				<label className="sub-asset-label" htmlFor="subasset">
					sub asset class name:
				</label>
				<input
					className="sub-asset-input"
					type="text"
					name="subasset"
					value={asset}
					onChange={(e) => {
						setMessage('');
						setAsset(e.target.value);
					}}
				/>
				<span style={{ color: 'red', textTransform: 'capitalize' }}>
					{message}
				</span>
				<button
					className="add-button-subasset add"
					onClick={submitHandler}
				>
					add
				</button>
				<button
					className="add-button-subasset cancel"
					onClick={() => {
						setAsset('');
						handleModel(false);
					}}
				>
					cancel
				</button>
			</div>
		</div>
	);
}
export default AddSubAsset;
