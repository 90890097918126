import { takeLatest } from 'redux-saga/effects';
import {loginSaga} from '../sagas/authenticationSaga';
import {getAllAssetSaga,saveAssetSaga,deleteAssetSaga,getSingleAssetSaga,updateAssetSaga} from '../sagas/assetSaga';
import {getAllSubAssetSaga} from '../sagas/subAssetSaga'
import * as types from '../actions/index';

export default function* root() {
  console.log("calling root11111")
  yield takeLatest(types.LOGIN_USER, loginSaga)
  yield takeLatest(types.ASSETS_REQUEST,getAllAssetSaga)
  yield takeLatest(types.ADD_ASSET,saveAssetSaga)
  yield takeLatest(types.DELETE_ASSET,deleteAssetSaga)
  yield takeLatest(types.ASSET_REQUEST, getSingleAssetSaga)
  yield takeLatest(types.UPDATE_ASSET,updateAssetSaga)
  yield takeLatest(types.SUB_ASSETS_REQUEST,getAllSubAssetSaga)
}