import { useState, useEffect } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import "../form.css";
import XLSX from "xlsx";

// material-ui icons
import ShortTextIcon from "@material-ui/icons/ShortText";
import TodayIcon from "@material-ui/icons/Today";
import MoneyIcon from "@material-ui/icons/Money";
import CreateIcon from "@mui/icons-material/Create";
import RefreshIcon from "@material-ui/icons/Refresh";
import DownloadIcon from "@mui/icons-material/Download";
import Model from "@mui/material/Modal";
import Box from "@mui/material/Box";

import PopUpModelTable from "./PopUpModelTable";

import Form from "react-bootstrap/Form";

import ModelTable from "../Layout/Table-layout/ModelTable";
import ApiServices from "../../ApiService/ApiConfig";

import { Link, useParams } from "react-router-dom";

import * as moment from "moment";
import axios from "axios";

import { toast } from "react-toastify";

import Modal from "../Modal-UI/Modal";
import Wrapper from "../Modal-UI/Wrapper";
import Button from "../Modal-UI/Button";
import { Accordion } from "react-bootstrap";
import Card from "react-bootstrap/Card";
// import Button from 'react-bootstrap/Button';

import FundsTable from "../Layout/Table-layout/FundsTable";

import { columns } from "../Layout/Table-layout/Columns/columns-model";

import { COLUMNS } from "../Layout/Table-layout/Columns/columns-funds";
import { useUserInfo } from "../../ContextProviders/UserInfo";

import * as FileSaver from "file-saver";

import AccordianComponent from "./../Layout/Table-layout/Accordian";
import { object } from "yup/lib/locale";

const UpdateModel = (props) => {
  const userInfo = useUserInfo();
  const Portfolio_Data = props.location.state.data;
  let apiData = [];
  let filename;
  if (Portfolio_Data.funds_log.length) {
    // let inceptionVal = Portfolio_Data.inception_value;
    Portfolio_Data.funds_log.map((item) => {
      const created_date = new Date(item.createdAt).toLocaleDateString("en", {
        day: "numeric",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });
      let obj = { Added_Funds: item.funds, Funds_created_date: created_date };
      apiData.push(obj);
    });
    filename =
      "added_funds_log" +
      Portfolio_Data.funds_log[0].model_name +
      new Date().toLocaleDateString("en", {
        day: "numeric",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });
  }
  const [modal, setModal] = useState();
  const [status, setStatus] = useState("Unverified");
  const [model, setModel] = useState([]);

  const [portfolioModel, setPortfolioModel] = useState([]);

  const [amount, setAmount] = useState("");
  const [addedFunds, setAddedFunds] = useState("");
  const [fundCash, setfundCash] = useState("");
  const [tableData, settableData] = useState([]);
  const [tableFundsData, settableFundsData] = useState([]);
  const [assetList, setAssetList] = useState([]);

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [thresholdLowerLimit, setThresholdLowerLimit] = useState("");
  const [thresholdUpperLimit, setThresholdUpperLimit] = useState("");
  const [inceptionDate, setInceptionDate] = useState("");
  const [inceptionValue, setInceptionValue] = useState("");
  const [addFunds, setAddFunds] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const [fileContents, setFileContents] = useState();
  const [files, setFiles] = useState();

  const [customerPortfolio, setCustomerPortfolio] = useState([]);

  const [processedData, setProcessedData] = useState();

  const [buttonDisable, setButtonDisable] = useState(true);
  const [fundsButtonDisable, setFundsButtonDisable] = useState(false);
  const [fileUploadDisable, setFileUploadDisable] = useState(false);

  const [buttonRefreshDisable, setButtonRefreshDisable] = useState(false);
  const [thresholdButtonDisable, setThresholdButtonDisable] = useState(true);

  const [userActionNew, setUserActionNew] = useState();
  const [idForUserActionNew, setIdForUserActionNew] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [thresholdReload, setThresholdReload] = useState();
  const [buttonGenerateReportDisabled, setButtonGenerateReportDisabled] =
    useState(true);
  const [portfolioModelData, setPortfolioModelData] = useState([]);
  const [portfolio, setPortfolio] = useState([]);

  const [showHide, setShowHide] = useState(false);

  const [open, setOpen] = useState(false);

  const [tabs, setTabs] = useState([
    { id: 1, label: "Tab 1", description: "Content of Tab 1" },
    { id: 2, label: "Tab 2", description: "Content of Tab 2" },
    { id: 3, label: "Tab 3", description: "Content of Tab 3" },
  ]);

  const [modelSecData, setModelSecData] = useState([]);
  const [totalCurrVal, setTotalCurrVal] = useState();
  const [ListAssetClass, setListAssetClass] = useState([]);
  const [defaultDisabled, setDefaultDisabled] = useState(true);
  const [isExpand_one, setIsExpandOne] = useState(false);

  const { name: model_name } = useParams();

  //portfolio-states
  const [portfolioInceptionVal, setPortfolioInceptionVal] = useState();
  const [manualUpdateState, setManualUpdateState] = useState(false);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  useEffect(() => {
    modelData();
    ListSubAssetClass();
  }, [userActionNew, idForUserActionNew]);

  const numberWithCommas = (num) => {
    if (num) return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const generateAssetClass = (data) => {
    let assetListLocal = [];
    setAssetList([]);
    console.log("--------assetlistfunc-----data---------------", data);
    data.forEach((element) => {
      if (element["Name of Securities"].toLowerCase() !== "cash")
        assetListLocal.push(element["Asset Class"]);
      else assetListLocal.push("Cash");
    });
    console.log("--------assetlistfunc-----data-------222--------", data);
    let assetClassSet = new Set(assetListLocal);
    console.log("--------assetlistfunc-----data-----assetClassSet----------", assetClassSet);
    Array.from(assetClassSet).map((element) => {
      setAssetList((assetList) => [...assetList, element]);
    });
  };

  const calculatePrescribedWt = (type, WTtype) => {
    let totalPrescribedWt = 0;
    tableData.map((element) => {
      if (type === element["Asset Class"]) {
        if (WTtype === "Prescribed Wt") {
          totalPrescribedWt += Number(element["Prescribed Weight"]);
        } else {
          totalPrescribedWt += Number(element["Current Weight"]);
        }
      } else if (type.toLowerCase() === "cash") {
        if (WTtype === "Prescribed Wt") {
          totalPrescribedWt = Number(element["Prescribed Weight"]);
        } else {
          totalPrescribedWt = Number(element["Current Weight"]);
        }
      }
    });
    return totalPrescribedWt.toFixed(3);
  };

  const calculateTotalPrescribedWt = (WTtype) => {
    let totalPrescribedWt = 0;
    tableData.map((element) => {
      if (WTtype === "Prescribed Wt") {
        totalPrescribedWt += Number(element["Prescribed Weight"]);
      } else {
        totalPrescribedWt += Number(element["Current Weight"]);
      }
    });
    return totalPrescribedWt.toFixed(3);
  };

  const modelData = () => {
    ApiServices.getModel(model_name)
      .then((res) => {
        setPortfolio(res.customer_portfolio);
        setName(res.name);
        setType(res.type);
        setThresholdLowerLimit(res.threshold_lower_limit);
        setThresholdUpperLimit(res.threshold_upper_limit);
        setInceptionDate(res.date);
        setInceptionValue(res.inception_value);
        setPortfolioModelData(res.customer_portfolio);
        let processedData = [];
        let processedDataFunds = [];
        let total = 0;
        res.model_securities.map((element) => {
          let obj = {};
          // if(element['ISIN Number'] === idForUserActionNew){
          obj["id"] = element["id"];
          obj["ISIN Number"] = element["ISIN Number"];
          obj["Name of Securities"] = element["Name of Securities"];
          obj["Asset Class"] = element["Asset Class"];
          obj["Sub Asset Class"] = element["Sub Asset Class"];
          // obj['Marketcap'] = element['Marketcap'];
          obj["CMP"] = String(Number(element["CMP"]).toFixed(3));
          obj["NSE Symbol"] = element["NSE Symbol"];
          obj["Ratio Start Pt"] = String(element["Ratio Start Pt"]);
          obj["Ratio End Pt"] = String(element["Ratio End Pt"]);
          obj["No of Shares"] = Number(element["No of Shares"]);

          obj["Prescribed Weight"] = Number(
            element["Prescribed Weight"]
          ).toFixed(3);

          obj["Current Value"] = Number(element["Current Value"]);
          obj["Current Weight"] = Number(element["Current Weight"]);

          if (element["Delta"] === null) {
            obj["Delta"] = 100;
          } else {
            obj["Delta"] = Number(element["Delta"].toFixed(1));
          }
          if (element["User Action"] === null) {
            obj["Actionable"] = element["Actionable"];
            obj["User Action"] = element["Actionable"];
          } else {
            obj["Actionable"] = element["Actionable"];
            obj["User Action"] = element["User Action"];
          }
          obj["Suggested Trade"] = String(element["Suggested Trade"]);
          obj["Buy Sell"] = element["Buy Sell"];
          obj["Price"] = element["Price"];

          var momentDate = moment(element["updatedAt"]);
          obj["Updated On"] = momentDate.format("MM-DD-YYYY hh:mm:ss A ");
          obj["updatedBy"] =
            userInfo.first_name +
            " " +
            userInfo.middle_name +
            " " +
            userInfo.last_name +
            "" +
            "(" +
            userInfo.id +
            ")";

          total += Number(obj["Current Value"]);
          processedData.push(obj);
          processedDataFunds.push(obj);
        });
        setTotalCurrVal(Number(total.toFixed(3)));
        settableData([]);
        settableFundsData([]);
        setModelSecData(res.model_securities);
        settableData(processedData);
        settableFundsData(processedDataFunds);
        generateAssetClass(processedDataFunds);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ListSubAssetClass = () => {
    ApiServices.GetSubAssetList()
      .then((res) => {
        let arr = res.map((data) => data.asset.toLowerCase());
        setListAssetClass(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Threshold Lower Limit Handler
  const thresholdLowerLimitHandler = (e) => {
    setButtonDisable(false);
    setThresholdLowerLimit(e.target.value);
    setThresholdReload(1);
  };

  const thresholdUpperLimitHandler = (e) => {
    setButtonDisable(false);
    setThresholdUpperLimit(e.target.value);
    setThresholdReload(2);
  };

  const handleEditPage = () => {
    setDefaultDisabled(false);
  };

  let tableRowObj = {
    securities: "abce",
    cmp: null,
    prescribedWeight: null,
    numberOfShares: null,
    currentValue: null,
  };

  const calculateTableRow = (id, property) => {
    let g = tableData[id];
    g[property] = tableData[id]["cmp"] * tableData[id]["numberOfShares"];

    settableData([...tableData.slice(0, id), g, ...tableData.slice(id + 1)]);
  };

  const updateTableRow = (id, property, value) => {
    let g = tableData[id];
    g[property] = value;

    settableData([...tableData.slice(0, id), g, ...tableData.slice(id + 1)]);
  };

  const processPortfolio = (tableData, customerPortfolio, currentModel) => {
    let total = null;

    console.log("tableData", tableData);
    let portfolioModel = [];
    if (customerPortfolio.length) {
      customerPortfolio.map((noOfShares) => {
        console.log("noOfShares", noOfShares);
        let totalNew = 0;
        tableData.forEach((item) => {
          console.log(
            '--------Number(item["CMP"])---------',
            Number(item["CMP"]),
            "-----item---",
            item,
            'item[noOfShares["name"]]',
            item[noOfShares["name"]],
            'item[noOfShares["name"]] * Number(item["CMP"])',
            item[noOfShares["name"]] * Number(item["CMP"])
          );
          totalNew += item[noOfShares["name"]] * Number(item["CMP"]);
        });
        console.log("totalNew", totalNew);
        let model = [];
        tableData.forEach((element, index) => {
          //Refreence some extra logic
          // for (const property in element) {
          //     if(property === noOfShares['name']){
          //         NoOfShares = element[property];
          //         console.log("keys", property, noOfShares['name'])
          //     }
          //   }
          let obj = {};

          obj["ISIN Number"] = element["ISIN Number"];

          obj["Name of Securities"] = element["Name of Securities"];

          obj["Asset Class"] = element["Asset Class"];

          obj["Sub Asset Class"] = element["Sub Asset Class"];

          // obj['Marketcap'] = element['Marketcap']

          obj["CMP"] = String(element["CMP"]);

          obj["NSE Symbol"] = element["NSE Symbol"];

          obj["Ratio Start Pt"] = element["Ratio Start Pt"].toString();

          obj["Ratio End Pt"] = element["Ratio End Pt"].toString();

          obj["Prescribed Weight"] = String(element["Prescribed Weight"]);

          obj["No of Shares"] = parseInt(element[noOfShares["name"]]);
          console.log("NOOFSHARES", Math.floor(element[noOfShares["name"]]));

          obj["Price"] = element["Price"];

          obj["Current Value"] = Number(
            (obj["No of Shares"] * Number(obj["CMP"])).toFixed(2)
          );
          // total += Number(obj['No of Shares'] * Number(obj['CMP']));

          model.push(obj);
        });

        model.map((element) => {
          //extract actionable from model

          let modelElement = currentModel.find(
            (ele) =>
              ele["ISIN Number"] == element["ISIN Number"]
          );
          console.log(
            "",
            (Number(element["No of Shares"] * Number(element["CMP"])) /
              totalNew) *
              100,
            element["No of Shares"],
            element["CMP"]
          );
          element["Current Weight"] = Number(
            (
              (Number(element["No of Shares"] * Number(element["CMP"])) /
                totalNew) *
              100
            ).toFixed(3)
          );
          element["Delta"] = Number(
            (
              ((((element["No of Shares"] * Number(element["CMP"])) /
                totalNew) *
                100) /
                Number(element["Prescribed Weight"])) *
              100
            ).toFixed(1)
          );

          if (typeof element["Delta"] === "number" && isNaN(element["Delta"])) {
            element["Delta"] = 0;
          }
          element["User Action"] = modelElement["User Action"];
          if (
            element["Delta"] < element["Ratio Start Pt"] ||
            element["Delta"] > element["Ratio End Pt"]
          ) {
            element["Actionable"] = "Yes";
          } else {
            element["Actionable"] = "No";
          }
          if (element["Delta"] === Infinity) {
            element["Delta"] = 100;
          }
          if (modelElement["User Action"] === "Yes") {
            if (
              Number(element["Prescribed Weight"]) === 0 &&
              element["No of Shares"] !== 0
            ) {
              element["Suggested Trade"] = String(element["No of Shares"]);
              element["Delta"] = 100;
              element["Buy Sell"] = "Sell";
              if (element["Price"] === "-") {
                element["Price"] = "Market Price";
              }
            } else {
              element["Suggested Trade"] = Math.round(
                Math.abs(
                  (Number(
                    (
                      ((element["No of Shares"] * Number(element["CMP"])) /
                        totalNew) *
                      100
                    ).toFixed(6)
                  ) -
                    Number(element["Prescribed Weight"])) *
                    totalNew
                ) /
                  element["CMP"] /
                  100
              );
              console.log(
                "TRADE",
                element["No of Shares"],
                element["CMP"],
                totalNew,
                element["Suggested Trade"]
              );

              if (element["Suggested Trade"] === undefined) {
                element["Suggested Trade"] = "0";
              }

              if (element["Suggested Trade"] >= 1) {
                element["Suggested Trade"] = String(element["Suggested Trade"]);
                if (element["Delta"] < 100) {
                  element["Buy Sell"] = "Buy";
                  element["Price"] = "Market Price";
                } else if (element["Delta"] > 100) {
                  element["Buy Sell"] = "Sell";
                  element["Price"] = "Market Price";
                }
              } else {
                element["Suggested Trade"] = String(element["Suggested Trade"]);
                element["Buy Sell"] = "-";
                element["Price"] = "-";
              }
            }
          } else {
            element["Suggested Trade"] = "-";
            element["Price"] = "-";
          }
          if (element["Name of Securities"].toLowerCase().includes("cash")) {
            element["Buy Sell"] = "-";
            element["Price"] = "-";
          }
        });

        let obj_portfolio = {};
        obj_portfolio["model_portfolio"] = model;
        obj_portfolio["portfolio_name"] = noOfShares["name"];
        //if (noOfShares["version"] < 1) {
          //obj_portfolio["inception_value"] = totalNew;
        //}
        obj_portfolio["version"] = noOfShares["version"] + 1;
        obj_portfolio["inception_value"] = totalNew;
        console.log(
          "inception_value-------------",
          obj_portfolio["inception_value"],
          totalNew
        );
        portfolioModel.push(obj_portfolio);
        console.log("PORTFOLIMODEL", portfolioModel);
      });
    }
    setPortfolioInceptionVal(total);
    console.log("INSIDEPROCESSPORTFOLIO", portfolioModel);
    return portfolioModel;
  };

  const processModel = (tableData) => {
    console.log("-----------process---model----tableData-------", tableData);
    let total = 0;
    let model = [];
    let EndPtLessThanStartPt = [];
    let StartPtLessThanStartPt = [];
    tableData.forEach((element, index) => {
      let obj = {};

      obj["ISIN Number"] = element["ISIN Number"];

      obj["Name of Securities"] = element["Name of Securities"];

      obj["Asset Class"] = element["Asset Class"];

      obj["Sub Asset Class"] = element["Sub Asset Class"];

      // obj['Marketcap'] = element['Marketcap'];

      obj["CMP"] = String(element["CMP"]);

      obj["NSE Symbol"] = element["NSE Symbol"];

      obj["Ratio Start Pt"] = element["Ratio Start Pt"];

      obj["Ratio End Pt"] = element["Ratio End Pt"];

      obj["Prescribed Weight"] = String(element["Prescribed Weight"]);

      obj["No of Shares"] = Number(element["No of Shares"]);

      obj["Price"] = element["Price"];

      obj["Current Value"] = Number(obj["No of Shares"] * Number(obj["CMP"]));
      total += obj["Current Value"];
      if (element["User Action"] !== undefined) {
        obj["User Action"] = element["User Action"];
      }
      model.push(obj);
    });
    setTotalCurrVal(Number(total.toFixed(3)));
    model.map((element) => {
      element["Current Value"] = Number(
        (element["No of Shares"] * element["CMP"]).toFixed(2)
      );
      element["Current Weight"] = Number(
        (
          ((element["No of Shares"] * Number(element["CMP"])) / total) *
          100
        ).toFixed(3)
      );
      element["Delta"] = Number(
        (
          ((((element["No of Shares"] * Number(element["CMP"])) / total) *
            100) /
            Number(element["Prescribed Weight"])) *
          100
        ).toFixed(1)
      );
      console.log("DELTA", element["Delta"]);
      if (typeof element["Delta"] === "number" && isNaN(element["Delta"])) {
        element["Delta"] = 0;
      }
      if (
        element["Delta"] < element["Ratio Start Pt"] ||
        element["Delta"] > element["Ratio End Pt"]
      ) {
        element["Actionable"] = "Yes";
        if (element["User Action"] === undefined) {
          element["User Action"] = element["Actionable"];
        }
      } else {
        element["Actionable"] = "No";
      }
      if (element["Delta"] === Infinity) {
        element["Delta"] = 100;
      }

      if (element["User Action"] === "Yes") {
        console.log("INSIDEUSERACTIONCON", element["Price"]);
        if (
          Number(element["Prescribed Weight"]) === 0 &&
          element["No of Shares"] !== 0
        ) {
          element["Suggested Trade"] = String(element["No of Shares"]);
          element["Buy Sell"] = "Sell";
          if (element["Price"] === "-") {
            element["Price"] = "Market Price";
          }
        } else {
          element["Suggested Trade"] = Math.round(
            Math.abs(
              (Number(
                (
                  ((element["No of Shares"] * Number(element["CMP"])) / total) *
                  100
                ).toFixed(6)
              ) -
                Number(element["Prescribed Weight"])) *
                total
            ) /
              element["CMP"] /
              100
          );

          if (element["Suggested Trade"] === undefined) {
            element["Suggested Trade"] = "0";
          }
          if (element["Suggested Trade"] >= 1) {
            element["Suggested Trade"] = String(element["Suggested Trade"]);
            if (element["Delta"] < 100) {
              element["Buy Sell"] = "Buy";
              // if(element['Price'] === '-'){
              //     element['Price'] = 'Market Price';
              // }else{
              element["Price"] = element["Price"];
              // }
            } else if (element["Delta"] > 100) {
              element["Buy Sell"] = "Sell";
              // if(element['Price'] === '-'){
              //     element['Price'] = 'Market Price';
              // }else{
              element["Price"] = element["Price"];
              // }
            }
          } else {
            element["Suggested Trade"] = String(element["Suggested Trade"]);
            element["Buy Sell"] = "-";
            element["Price"] = "-";
          }
        }
      } else {
        element["Suggested Trade"] = "-";
        element["Price"] = "-";
      }

      if (element["Name of Securities"].toLowerCase().includes("cash")) {
        element["Buy Sell"] = "-";
        element["Price"] = "-";
      }

      // element['Price'] = element['Price'];

      if (element["User Action"] === undefined) {
        element["User Action"] = element["Actionable"];
        element["Price"] = "Market Price";
      }
      if (thresholdReload !== undefined) {
        element["User Action"] = element["Actionable"];
      }

      if (!element["Name of Securities"].toLowerCase().includes("cash")) {
        if (
          Number(element["Ratio End Pt"]) >= Number(element["Ratio Start Pt"])
        ) {
          EndPtLessThanStartPt.push(false);
        } else {
          EndPtLessThanStartPt.push(true);
        }
        if (
          Number(element["Ratio Start Pt"]) <= Number(element["Ratio End Pt"])
        ) {
          StartPtLessThanStartPt.push(false);
        } else {
          StartPtLessThanStartPt.push(true);
        }
      }
    });
    const NewEndPtLessThanStartPt = EndPtLessThanStartPt.filter((ele) => {
      return ele === true;
    });
    const NewStartPtLessThanStartPt = StartPtLessThanStartPt.filter((ele) => {
      return ele === true;
    });
    if (
      NewEndPtLessThanStartPt.length > 0 ||
      NewStartPtLessThanStartPt.length > 0
    ) {
      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    }
    console.log("INSIDEPROCESSMODEL", model);
    return model;
  };

  let today = new Date().toISOString().slice(0, 10);

  function changeDateFormat(today) {
    // expects Y-m-d
    var splitDate = today.split("-");
    if (splitDate.count == 0) {
      return null;
    }

    var year = splitDate[0];
    var month = splitDate[1];
    var day = splitDate[2];

    return day + "\\" + month + "\\" + year;
  }
  var newDate = changeDateFormat(today);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1080,
    height: 520,
    bgcolor: "background.paper",
    border: "1px solid #096dd9",
    boxShadow: 24,
    p: 4,
  };

  const handleOpen = () => setOpen(false);
  const handleClose = () => {
    setOpen(false);
  };

  const PopupWindow = () => {
    return (
      <Model
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="container">
            <div className="row">
              <div style={{ height: "400px", overflowY: "scroll" }}>
                <PopUpModelTable />
                <br />
              </div>
            </div>
          </div>

          <center>
            <button
              className="btn btn-success"
              style={{ marginRight: "150px", marginTop: "40px" }}
              onClick={generateReportHandler}
            >
              Accept
            </button>
            <button
              className="btn btn-danger"
              style={{ marginLeft: "150px", marginTop: "40px" }}
              onClick={handleClose}
            >
              Cancel
            </button>
          </center>
        </Box>
      </Model>
    );
  };

  const previewReportHandler = () => {
    setOpen(true);
    dataLog();
  };

  const verifyModelReportHandler = async () => {
    const user_email = JSON.parse(localStorage.getItem("userInfo"));
    const mail = user_email.email;
    const destUrl = `${process.env.REACT_APP_URL_API}/model/modellist/verifymodeldata/${model_name}`;
    const data = await axios({
      method: "post",
      url: destUrl,
      data: {
        name: model_name,
        email: mail,
      },
    });
    toast.success(
      `Report Generated Successfully and Email is sent to all super-admins for verification`
    );
  };

  // generate report
  const generateReportHandler = async () => {
    setOpen(true);
    const user_email = JSON.parse(localStorage.getItem("userInfo"));
    const mail = user_email.email;
    const destUrl = `${process.env.REACT_APP_URL_API}/model/modellist/generatereport/${model_name}`;
    const data = await axios({
      method: "post",
      url: destUrl,
      data: {
        name: model_name,
        email: mail,
      },
    });
    toast.success(
      `Report Generated Successfully and Email is sent to all super-admins for verification`
    );
    setOpen(false);
  };

  const addFundsClick = (data) => {
    setAddFunds(true);
  };

  const handleRefresh = () => {
    modelData();
    setFileUploadDisable(false);
    setFundsButtonDisable(false);
    setButtonDisable(true);
  };

  const exportToCSV = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleDownload = async (e) => {
    e.preventDefault();

    let apiData = [];

    let cust_portfolio = [];

    ApiServices.getModelCustomerPortfolio(model_name)
      .then((res) => {
        setIsLoading(false);

        res["customer_portfolio"].map((el) => {
          console.log("length", el["customer_portfolio_securities"].length);
          if (el["customer_portfolio_securities"].length > 0) {
            cust_portfolio.push({
              name: el["portfolio_name"],
              noOfShares: el["customer_portfolio_securities"],
            });
          } else {
            cust_portfolio.push({ name: el["portfolio_name"], noOfShares: 0 });
          }
        });
        console.log("cust_portfolio", cust_portfolio);
        console.log("tableData", tableData);

        cust_portfolio.map((data) => {
          setCustomerPortfolio((customerPortfolio) => [
            ...customerPortfolio,
            data,
          ]);
        });

        tableData.forEach((element, index) => {
          let obj = {};
          obj["ISIN Number"] = element["ISIN Number"];
          obj["Name of Securities"] = element["Name of Securities"];
          obj["Asset Class"] = element["Asset Class"];
          obj["Sub Asset Class"] = element["Sub Asset Class"];
          // obj['Marketcap']= element['Marketcap'];
          obj["CMP"] = element["CMP"];
          obj["NSE Symbol"] = element["NSE Symbol"];
          obj["Prescribed Weight"] = element["Prescribed Weight"];
          obj["No of Shares"] = element["No of Shares"];
          obj["Ratio Start Pt"] = element["Ratio Start Pt"];
          obj["Ratio End Pt"] = element["Ratio End Pt"];

          cust_portfolio.map((el) => {
            let portfolio = el["name"];
            if (el["noOfShares"] == 0) {
              if (obj["Name of Securities"] != "Cash") obj[portfolio] = 0;
              else obj[portfolio] = 0;
            } else {
              let record = el["noOfShares"].find(
                (rec) =>
                  rec["ISIN_Number"] === element["ISIN Number"] &&
                  rec["Name_of_Securities"] === element["Name of Securities"]
              );
              console.log("record", el["noOfShares"]);
              console.log("record---", record);
              if (record) obj[portfolio] = record["No_of_Shares"];
            }
          });
          console.log("obj", obj);
          apiData.push(obj);
        });
        console.log("apiData", apiData);
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { Sheet1: ws }, SheetNames: ["Sheet1"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });

        console.log("data", data);
        FileSaver.saveAs(data, name + fileExtension);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  /** Added by Prathmeshk 24-02-2022
   *  I/p: Portfolio_Data.customer_portfolio
   *  O/p: generate excel file with All Fund Report
   */
  const handleCustomerDataDownload = async (e) => {
    e.preventDefault();

    let cust_portfolio = [];
    Portfolio_Data.customer_portfolio.forEach((element, index) => {
      let field = element.cust_funds_log;
      let filterData = field.filter((e) => {
        return e.funds_added !== null;
      });
      let modified = filterData.map((ele) => {
        return {
          portfolio_name: ele.portfolio_name,
          funds_added: ele.funds_added,
          createdAt: ele.createdAt,
        };
      });
      cust_portfolio = [...cust_portfolio, ...modified];
    });

    const ws = XLSX.utils.json_to_sheet(cust_portfolio);
    const wb = { Sheets: { Sheet1: ws }, SheetNames: ["Sheet1"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, name + fileExtension);
  };

  const dataLog = () => {
    const temp = portfolioModelData.filter((el) => {
      return el.user.customer_status === "Active";
    });
    console.log("portfolioModelData-----", temp);
    console.log("tableData----", tableData);
    let modelDataaa = [];
    temp.forEach((ele) => {
      let obj1 = {};
      let modelDataa = [];
      ele.customer_portfolio_securities.forEach((element, index) => {
        let obj = {};
        let modelInfo = tableData.find((e) => {
          return (
            element["ISIN_Number"] === e["ISIN Number"] &&
            element["Name_of_Securities"] === e["Name of Securities"]
          );
        });

        console.log("modelInfo-------", modelInfo);        
        if (modelInfo === undefined) {
          // let nonMatchingElement = tableData.reduce((acc, e) => {
          //   if (element["Name_of_Securities"] !== e["Name of Securities"]) {
          //     return e; // Return the mismatched element
          //   }
          //   return acc; // Otherwise, return the previous value (accumulator)
          // }, null);

          const tableDataEntity = tableData.filter((k) => {
            return !ele.customer_portfolio_securities.some(
              (security) =>
                security["Name_of_Securities"] === k["Name of Securities"]
            );
          });

          console.log("tableDataEntity-----", tableDataEntity);
          const tempTableData = tableDataEntity.map((item) => {
            return {
              name: item["Name of Securities"],
            };
          });

          const showAlert = (tempTableData) => {
            const list = tempTableData
              .map((item, index) => `${index + 1}. Security Name: ${item.name}`)
              .join("\n");

            const message = `This Security names are not present or not matching in customer portfolio "${ele.portfolio_name}" (${ele.user.first_name} ${ele.user.middle_name} ${ele.user.last_name})\n\n${list}`;

            alert(message);
          };

          showAlert(tempTableData);

          // toast.error(
          //   `Security name "${ele.customer_portfolio_securities[index].Name_of_Securities}" is not matched with customer portfolio "${ele.portfolio_name}" security. (${ele.user.first_name} ${ele.user.middle_name} ${ele.user.last_name})`
          // );
        }
        obj["id"] = element["id"];
        obj["No of Shares"] = element["No of Shares"];
        obj["CMP"] = modelInfo["CMP"];
        obj["Prescribed Weight"] = modelInfo["Prescribed Weight"];
        obj["Ratio Start Pt"] = modelInfo["Ratio Start Pt"];
        obj["Ratio End Pt"] = modelInfo["Ratio End Pt"];
        obj["User Action"] = modelInfo["User Action"];
        obj["Price"] =
          typeof modelInfo["Price"] === "object"
            ? modelInfo["Price"].main
            : modelInfo["Price"];
        modelDataa.push(obj);
      });
      obj1["portfolio_name"] = ele.portfolio_name;
      obj1["model"] = modelDataa;
      modelDataaa.push(obj1);
    });
    console.log("ModelData----", modelDataaa);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("portfolioModelData-----", portfolioModelData);
    // console.log("tableData----", tableData);

    const temp = portfolioModelData.filter((el) => {
      return el.user.customer_status === "Active";
    });

    console.log("--------------portfolioModel--------------------------",portfolioModel);

    let jsonRequestBody = {};
    jsonRequestBody["name"] = name;
    jsonRequestBody["type"] = type;
    jsonRequestBody["threshold_lower_limit"] = thresholdLowerLimit;
    jsonRequestBody["threshold_upper_limit"] = thresholdUpperLimit;
    jsonRequestBody["date"] = inceptionDate;
    jsonRequestBody["inception_value"] = inceptionValue;
    jsonRequestBody["updatedBy"] =
      userInfo.first_name +
      " " +
      userInfo.middle_name +
      " " +
      userInfo.last_name +
      "" +
      "(" +
      userInfo.id +
      ")";
    // jsonRequestBody['status'] = status;
    if (addedFunds !== "") {
      jsonRequestBody["funds_added"] = parseFloat(addedFunds);
    } else {
      jsonRequestBody["funds_added"] = null;
    }
    let modelNew = processModel(tableData);
    if (thresholdReload !== undefined) {
      jsonRequestBody["model"] = modelNew;
    } else {
      jsonRequestBody["model"] = modelNew;
    }

    console.log(modelNew, "INSIDE-SUBMIT-HANDLER", model);

    jsonRequestBody["model"] = jsonRequestBody["model"].map((ele) => {
      if (typeof ele["Price"] === "object") {
        return { ...ele, ["Price"]: ele["Price"].main };
      }
      return ele;
    });
    // }
    setButtonGenerateReportDisabled(false);
    ApiServices.updateModel(jsonRequestBody, model_name)
      .then((res) => {
        console.log(res.data);
        console.log("1");
        props.history.push("/modport");
        toast.success(`Model and Portfolio Updated Successfully..`);
      })
      .catch((err) => {
        console.log(err);
      });

    if (portfolioModel.length > 0) {
      console.log("before api call", portfolioModel);
      ApiServices.updateModelCustomerPortfolio(portfolioModel)
        .then((res) => {
          console.log(res.data);
          console.log("2");
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (temp.length > 0 && temp[0].customer_portfolio_securities.length > 0) {
      let modelDataaa = [];
      temp.forEach((ele) => {
        let obj1 = {};
        let modelDataa = [];
        ele.customer_portfolio_securities.forEach((element) => {
          let obj = {};
          let modelInfo = tableData.find(
            (e) =>
              element["ISIN_Number"] === e["ISIN Number"] &&
              element["Name_of_Securities"] === e["Name of Securities"]
          );
          // console.log("modelInfo-----", modelInfo);
          obj["id"] = element["id"];
          obj["No of Shares"] = element["No of Shares"];
          obj["CMP"] = modelInfo["CMP"];
          obj["Prescribed Weight"] = modelInfo["Prescribed Weight"];
          obj["Ratio Start Pt"] = modelInfo["Ratio Start Pt"];
          obj["Ratio End Pt"] = modelInfo["Ratio End Pt"];
          obj["User Action"] = modelInfo["User Action"];
          obj["Price"] =
            typeof modelInfo["Price"] === "object"
              ? modelInfo["Price"].main
              : modelInfo["Price"];
          modelDataa.push(obj);
        });
        obj1["portfolio_name"] = ele.portfolio_name;
        obj1["model"] = modelDataa;
        modelDataaa.push(obj1);
      });
      console.log("ModelData", modelDataaa);
      // const timeOut = setTimeout(() => {
      ApiServices.updatePortfolioFromManualUpdate(modelDataaa)
        .then((res) => {
          console.log(res.data);
          console.log("3");
        })
        .catch((err) => {
          console.log(err);
        });
      // }, 1000);
      // clearTimeout(timeOut);
    }
  };

  const onSaveActionDataHandler = (value, isin_number, nameOfSecurities) => {
    setManualUpdateState(true);
    let spreaded = [...tableData];
    let index = tableData.findIndex(
      (element) =>
        element["ISIN Number"] == isin_number &&
        element["Name of Securities"] == nameOfSecurities
    );
    spreaded[index] = { ...spreaded[index], ["User Action"]: value };
    if (value === "Yes") {
      spreaded[index] = { ...spreaded[index], ["Price"]: "Market Price" };
    }
    // alert(value);
    settableData(spreaded);
    let model = processModel(spreaded);

    setFundsButtonDisable(true);
    setButtonDisable(false);
    setModel(model);
    settableData([]);
    model.forEach((element) => {
      settableData((tableData) => [...tableData, element]);
    });
  };

  const handleCMP = (val, isinNumber, nameOfSecurities) => {
    let modified = val.replace(/,/g, "");
    if (isNaN(Number(modified)) || Math.sign(Number(modified)) === -1) return;
    let index = tableData.findIndex(
      (element) =>
        element["ISIN Number"] == isinNumber &&
        element["Name of Securities"] == nameOfSecurities
    );
    // tableData[index]['CMP'] = val.replace(/,/g,"");
    // tableData.map((ele) => {
    //     ele['User Action'] = undefined
    // })

    let spreaded = [...tableData];
    spreaded[index] = { ...spreaded[index], CMP: modified };
    spreaded.map((ele) => {
      ele["User Action"] = undefined;
    });

    settableData(spreaded);
    let model = processModel(spreaded);

    setFundsButtonDisable(true);
    setButtonDisable(false);
    setModel(model);
    settableData([]);
    model.forEach((element) => {
      settableData((tableData) => [...tableData, element]);
    });
  };

  const handleShares = (val, isinNumber, nameOfSecurities) => {
    let modified = val.replace(/,/g, "");
    if (
      isNaN(Number(modified)) ||
      Math.sign(Number(modified)) === -1 ||
      modified.includes(".")
    )
      return;
    setManualUpdateState(true);
    let spreaded = [...tableData];
    if (val.length > 0) {
      let index = tableData.findIndex(
        (element) =>
          element["ISIN Number"] == isinNumber &&
          element["Name of Securities"] == nameOfSecurities
      );
      spreaded[index] = {
        ...spreaded[index],
        ["No of Shares"]: modified,
        Price: "Market Price",
      };
    } else {
      let index = tableData.findIndex(
        (element) =>
          element["ISIN Number"] == isinNumber &&
          element["Name of Securities"] == nameOfSecurities
      );
      spreaded[index] = {
        ...spreaded[index],
        ["No of Shares"]: "0",
      };
    }
    spreaded.map((ele) => {
      ele["User Action"] = undefined;
    });
    settableData(spreaded);
    let model = processModel(spreaded);
    setFundsButtonDisable(true);
    setButtonDisable(false);

    setModel(model);
    settableData([]);
    model.forEach((element) => {
      settableData((tableData) => [...tableData, element]);
    });
  };

  const handlePrescribedWeight = (val, isinNumber, nameOfSecurities) => {
    if (isNaN(Number(val)) || Math.sign(Number(val)) === -1) return;
    let CashSec = tableData.find((el) => el["Name of Securities"] === "Cash");
    console.log(tableData, "TTAABBLLEE-DDAATTAA");
    setManualUpdateState(true);
    let index = tableData.findIndex(
      (element) =>
        element["ISIN Number"] == isinNumber &&
        element["Name of Securities"] == nameOfSecurities
    );
    let condition = val - tableData[index]["Prescribed Weight"];
    console.log("THE CONDITION OF MATRIX", condition, CashSec);
    if (Math.sign(condition) === 1) {
      if (condition > CashSec["Prescribed Weight"]) {
        toast.warning("Prescribed weight of cash can't be negative");
        return;
      }
    }
    let temp = [...tableData];
    temp[index] = {
      ...temp[index],
      ["Prescribed Weight"]: val,
      Price: "Market Price",
    };
    temp.map((ele) => {
      ele["User Action"] = undefined;
    });
    // settableData([...tableData]);
    let model = processModel(temp);
    console.log("HANDLEPRESCRIBED", model);
    settableData([...model]);
    setFundsButtonDisable(true);
    setButtonDisable(false);

    let prescribedWeightTotal = 0;
    model.forEach((element) => {
      prescribedWeightTotal += Number(element["Prescribed Weight"]);
    });

    if (prescribedWeightTotal != 100) {
      let index = model.findIndex((ele) =>
        ele["Name of Securities"].toLowerCase().includes("cash")
      );
      model[index]["Prescribed Weight"] = (
        Number(model[index]["Prescribed Weight"]) +
        (100 - prescribedWeightTotal)
      ).toFixed(3);
    }

    setModel(model);
    settableData([]);
    model.forEach((element) => {
      settableData((tableData) => [...tableData, element]);
    });
  };

  //vishal gholap (created)
  function calcRange(mainValue, pcent, num) {
    if (isNaN(num)) return false;

    var mBelow = (mainValue / 100) * (100 - pcent);
    var mAbove = (mainValue / 100) * (100 + pcent);

    if (num >= mBelow && num <= mAbove) return false;
    if (num < mBelow) return "Below Range";
    return "Above Range";
  }

  const handlePrice = (val, isinNumber, nameOfSecurities) => {
    setManualUpdateState(true);
    let found = tableData.find(
      (el) =>
        el["ISIN Number"] === isinNumber &&
        el["Name of Securities"] === nameOfSecurities
    );
    let flag = calcRange(found["CMP"], 5, val);

    // if(val.length > 0){
    let index = tableData.findIndex(
      (element) =>
        element["ISIN Number"] == isinNumber &&
        element["Name of Securities"] == nameOfSecurities
    );

    let spreaded = [...tableData];

    spreaded[index] = {
      ...spreaded[index],
      Price: {
        main: val,
        flag,
      },
    };

    settableData(spreaded);
    let model = processModel(spreaded);
    setFundsButtonDisable(true);
    if (flag) {
      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    }
    setModel(model);
    settableData([]);
    model.forEach((element) => {
      settableData((tableData) => [...tableData, element]);
    });
  };

  const handleRatioStartPt = (val, isinNumber, nameOfSecurities) => {
    setManualUpdateState(true);
    let spreaded = [...tableData];
    let index = tableData.findIndex(
      (element) =>
        element["ISIN Number"] == isinNumber &&
        element["Name of Securities"] == nameOfSecurities
    );
    if (val !== "") {
      spreaded[index] = {
        ...spreaded[index],
        ["Ratio Start Pt"]: String(parseInt(val)),
        Price: "Market Price",
      };
    } else {
      spreaded[index] = {
        ...spreaded[index],
        ["Ratio Start Pt"]: val,
        Price: "Market Price",
      };
    }
    // if(spreaded[index]['Price'] === '-'){
    //     spreaded[index]['Price'] = 'Market Price'
    // }
    spreaded.map((ele) => {
      ele["User Action"] = undefined;
    });
    settableData(spreaded);
    let model = processModel(spreaded);

    setFundsButtonDisable(true);
    setButtonDisable(false);
    setModel(model);
    settableData([]);
    model.forEach((element) => {
      settableData((tableData) => [...tableData, element]);
    });
  };

  const handleRatioEndPt = (val, isinNumber, nameOfSecurities) => {
    setManualUpdateState(true);
    let spreaded = [...tableData];
    let index = tableData.findIndex(
      (element) =>
        element["ISIN Number"] == isinNumber &&
        element["Name of Securities"] == nameOfSecurities
    );
    const previousVal = tableData[index]["Ratio End Pt"];
    if (val !== "") {
      spreaded[index] = {
        ...spreaded[index],
        ["Ratio End Pt"]: String(parseInt(val)),
        Price: "Market Price",
      };
    } else {
      spreaded[index] = {
        ...spreaded[index],
        ["Ratio End Pt"]: val,
        Price: "Market Price",
      };
    }
    spreaded.map((ele) => {
      ele["User Action"] = undefined;
    });
    settableData(spreaded);
    let model = processModel(spreaded);

    setFundsButtonDisable(true);
    setButtonDisable(false);
    setModel(model);
    settableData([]);
    model.forEach((element) => {
      settableData((tableData) => [...tableData, element]);
    });
  };

  const createViewTableColumns = (data) => {
    // setColumns([]);
    console.log(data);
    let keys = Object.keys(data[0]);

    Object.entries(data[0]).forEach((el) => {
      let obj = {};
      obj["Header"] = el[0];
      obj["accessor"] = el[0];
      // setColumns(columns => [...columns, obj]);
    });
  };

  const createTableColumns = (data) => {
    // setColumns([]);
    let keys = Object.keys(data[0]);

    Object.entries(data[0]).forEach((el) => {
      let obj = {};
      obj["Header"] = el[0];
      obj["accessor"] = el[0];
      // setColumns(columns => [...columns, obj]);
    });
  };

  const createTableData = (data) => {
    let tableDataLocal = [];
    let total = null;

    let assetListLocal = [];

    let cust_portfolio = [];

    Object.entries(data).forEach((el) => {
      tableDataLocal.push(el[1]);
    });
    console.log("========tableDataLocal===============", tableDataLocal);
    let model = processModel(tableDataLocal);
    console.log("--------------model----------------", model);
    //30-03-2022 - Khema
    //    let tableDataLocalNew = tableDataLocal.
    ApiServices.getModelCustomerPortfolio(model_name).then(
      (res) => {
        console.log(res);
        setIsLoading(false);

        res["customer_portfolio"].map((el) => {
          cust_portfolio.push({
            name: el["portfolio_name"],
            noOfShares: 0,
            version: el["version"],
          });
        });

        if (cust_portfolio.length) {
          let portfolioModel = processPortfolio(
            tableDataLocal,
            cust_portfolio,
            model
          );
          console.log('==============portfolioModel-=================', portfolioModel)
          setPortfolioModel(portfolioModel);
        }
      },
      (error) => {}
    );
    setModel(model);
    settableData([]);

    model.forEach((element) => {
      settableData((tableData) => [...tableData, element]);
    });
    console.log("----------model----2---------", model);
    generateAssetClass(model);
  };

  const fileUpload = (file) => {
    console.log("INSIDE UPLOAD FUNCTION");
    setAssetList([]);
    settableData([]);
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    fileReader.onload = (e) => {
      // const bufferArray = e.target.result;

      // const wb = XLSX.read(bufferArray, { type: "buffer" });

      // const wsname = wb.SheetNames[0];

      // const ws = wb.Sheets[wsname];

      // const data = XLSX.utils.sheet_to_json(ws);

      var workbook = XLSX.readFile(e.target.result);
      var sheet_name_list = workbook.SheetNames;
      let data = [];
      sheet_name_list.forEach(function (y) {
        var worksheet = workbook.Sheets[y];
        var headers = {};
        for (let z in worksheet) {
          if (z[0] === "!") continue;
          //parse out the column, row, and value
          var tt = 0;
          for (var i = 0; i < z.length; i++) {
            if (!isNaN(z[i])) {
              tt = i;
              break;
            }
          }
          var col = z.substring(0, tt);
          var row = parseInt(z.substring(tt));
          var value = worksheet[z].w;

          //store header names
          if (row == 1 && value) {
            headers[col] = value;
            continue;
          }

          if (!data[row]) data[row] = {};
          data[row][headers[col]] = value;
        }
        //drop those first two rows which are empty
        data.shift();
        data.shift();
        console.log("data1212", data);
      });

      // // createTableColumns(data);

      setFundsButtonDisable(true);

      let modified = data.map((element) => {
        element["Price"] = "Market Price";
        return element;
      });

      let arrBlankVal = [];
      modified.map((i) => {
        if (i["ISIN Number"] === undefined) {
          arrBlankVal.push(i["ISIN Number"]);
        } else if (i["Name of Securities"] === undefined) {
          arrBlankVal.push(i["Name of Securities"]);
        } else if (i["Asset Class"] === undefined) {
          arrBlankVal.push(i["Asset Class"]);
        } else if (i["Sub Asset Class"] === undefined) {
          arrBlankVal.push(i["Sub Asset Class"]);
        } else if (i["CMP"] === undefined) {
          arrBlankVal.push(i["CMP"]);
        } else if (i["Prescribed Weight"] === undefined) {
          arrBlankVal.push(i["Prescribed Weight"]);
        } else if (i["No of Shares"] === undefined) {
          arrBlankVal.push(i["No of Shares"]);
        }
      });
      if (arrBlankVal.length) {
        toast.warning(`Blank spaces not allowed`);
      }
      let arrIntVal = [];
      modified.map((i) => {
        i["No of Shares"] = Number(i["No of Shares"]);
        if (Number.isInteger(i["No of Shares"])) {
          arrIntVal.push(Number.isInteger(i["No of Shares"]));
        } else {
          i["No of Shares"] = Math.floor(i["No of Shares"]);
        }
      });
      let noOfSharesInDecimal = arrIntVal.filter((i) => {
        return i === false;
      });
      if (noOfSharesInDecimal.length) {
        toast.warning(`'No of Shares' cannot be a decimal value`);
      }
      let prescribedWeight99 = [];
      modified.map((i) => {
        i["CMP"] = Number(i["CMP"]);
        i["Prescribed Weight"] = Number(i["Prescribed Weight"]);
        if (i["Prescribed Weight"] > 99) {
          prescribedWeight99.push("Yes");
        }
      });
      if (prescribedWeight99.length) {
        toast.warning(`Prescribed weight greater than 99 can not be accepted`);
      }
      let decimalThreePresWeight = [];
      Number.prototype.countDecimals = function () {
        if (Math.floor(this.valueOf()) === this.valueOf()) return 0;

        var str = this.toString();
        if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
          return str.split("-")[1] || 0;
        } else if (str.indexOf(".") !== -1) {
          return str.split(".")[1].length || 0;
        }
        return str.split("-")[1] || 0;
      };
      modified.map((i) => {
        if (parseFloat(i["Prescribed Weight"]).countDecimals() > 3) {
          decimalThreePresWeight.push("Yes");
        }
      });
      if (decimalThreePresWeight.length) {
        toast.warning(
          `Prescribed weight will not accept greater than 3 decimals`
        );
      }
      let cashEntryNotAvailable = [];
      modified.map((i) => {
        if (
          i["Name of Securities"] === "Cash" ||
          i["Name of Securities"] === "Cash & Cash Equivalents"
        ) {
          cashEntryNotAvailable.push("Yes");
        }
      });
      if (!cashEntryNotAvailable.length) {
        toast.warning(`Cash Entry not available in Excel Sheet.`);
      }
      let noOfSharesInCash = [];
      modified.map((i) => {
        if (
          (i["Name of Securities"] === "Cash" ||
            i["Name of Securities"] === "Cash & Cash Equivalents") &&
          (i["CMP"] === 1.0 || i["CMP"] === 1 || i["CMP"] === 1.0)
        ) {
          noOfSharesInCash.push("Yes");
        }
      });
      if (!noOfSharesInCash.length) {
        toast.warning(`CMP in cash will always be 1`);
      }
      let totalCountPrescribedWt = 0;
      modified.map((i) => {
        totalCountPrescribedWt += Number(i["Prescribed Weight"]);
      });
      let exp = totalCountPrescribedWt.toFixed(3);
      if (Number(exp) !== 100.0) {
        toast.warning(`The total of Prescribed Weight is not 100%`);
      }
      const SubAssetClassFromExcelSheet = data
        .filter(
          (ele) => !ele["Name of Securities"].toLowerCase().includes("cash")
        )
        .map((data) => data["Sub Asset Class"].toLowerCase());
      const temp = new Set(SubAssetClassFromExcelSheet);
      const newTemp = [...temp];
      let difference = newTemp.filter((x) => !ListAssetClass.includes(x));
      if (difference.length > 0) {
        toast.warning(
          `${difference.toString()} Sub Asset Class are not in a Sub Asset Class List inside settings`
        );
      }

      let RatioStartNotMatched = [];
      modified.forEach((element) => {
        if (
          Number(element["Ratio Start Pt"]) > Number(element["Ratio End Pt"])
        ) {
          RatioStartNotMatched.push("Yes");
        }
      });

      console.log("RATIOSTARTEND", RatioStartNotMatched);
      if (RatioStartNotMatched.length) {
        toast.warning(`Ratio Start Pt must be less than Ratio End Pt`);
      }

      if (
        !noOfSharesInDecimal.length &&
        !prescribedWeight99.length &&
        !decimalThreePresWeight.length &&
        !arrBlankVal.length &&
        cashEntryNotAvailable.length &&
        noOfSharesInCash.length &&
        Number(exp) === 100 &&
        difference.length === 0 &&
        !RatioStartNotMatched.length
      ) {
        createTableData(modified);
        setManualUpdateState(false);
        setButtonDisable(false);
        console.log("-------------modified------------------", modified);
        toast.success(`${file.name} Uploaded Sucessfully`);
      }

      // createTableData(data);
    };
  };

  const modalTestHandler = (event) => {
    event.preventDefault();
    setModal({
      title: props.title,
      message: props.message,
    });
  };

  const cancelHandler = () => {
    setModal(null);
    setAmount("");
    tableFundsData.forEach((element, index) => {
      element["Actual Allocation"] = null;
      element["Suggested Amount Allocation"] = null;
      element["Number of Shares"] = null;
    });
  };

  const applyHandler = () => {
    setAddedFunds(amount);
    const added_funds = parseFloat(inceptionValue) + parseFloat(amount);
    setInceptionValue(added_funds);
    setModal(null);
    let tableDataNew = [];
    tableFundsData.forEach((element, index) => {
      //find same security from update model data
      let updateElement = {};

      if (!element["Name of Securities"].toLowerCase().includes("cash")) {
        updateElement = tableData.find(
          (ele) => ele["Name of Securities"] == element["Name of Securities"]
        );

        if (String(updateElement["Suggested Trade"]).includes("sell")) {
          let arr = String(updateElement["Suggested Trade"]).split("(");
          updateElement["Suggested Trade"] =
            Math.abs(
              Number(arr[0].replace(/,/g, "")) -
                Number(element["Number of Shares"])
            ) +
            "(" +
            arr[1];
        } else if (String(updateElement["Suggested Trade"]).includes("buy")) {
          let arr = String(updateElement["Suggested Trade"]).split("(");

          let num =
            Number(arr[0].replace(/,/g, "")) +
            Number(element["Number of Shares"]);

          updateElement["Suggested Trade"] = Number(num) + "(" + arr[1];
        } else if (String(updateElement["Suggested Trade"]).includes("-")) {
          if (element["Number of Shares"] > 0) {
            updateElement["Suggested Trade"] =
              element["Number of Shares"] + "(buy)";
            updateElement["Actionable"] = "Yes";
          } else {
            updateElement["Suggested Trade"] = element["Number of Shares"];
            updateElement["Actionable"] = "No";
          }
        }
      } else if (element["Name of Securities"].toLowerCase().includes("cash")) {
        //update cash
        updateElement = tableData.find(
          (ele) => ele["Name of Securities"] == element["Name of Securities"]
        );
        // alert(fundCash+"=="+updateElement['Current Value'])
        updateElement["No of Shares"] = Math.floor(
          Number(updateElement["No of Shares"]) + fundCash
        );
        updateElement["Current Value"] += Math.floor(fundCash);
      }

      tableDataNew.push(updateElement);
      //  settableData(tableData => [...tableData, updateElement]);
    });

    settableData([]);
    tableDataNew.forEach((element) => {
      settableData((tableData) => [...tableData, element]);
    });

    setModel([...tableDataNew]);
    setButtonDisable(false);
    setFileUploadDisable(true);
    setAmount("");
    tableFundsData.forEach((element, index) => {
      delete element["Updated On"];

      delete element["Number of Shares"];
      delete element["Suggested Amount Allocation"];
      delete element["Actual Allocation"];
    });
  };

  const addModelForm = () => (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-3">
          <label className="form-label">
            <ShortTextIcon /> Name
          </label>

          <Form.Group controlId="threshold">
            <Form.Control
              type="text"
              className="form-control"
              placeholder="e.g. : Portfolio name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              readOnly
            />
          </Form.Group>
        </div>

        <div className="col-sm-3">
          <label className="form-label">
            <ShortTextIcon /> Type
          </label>

          <Form.Group controlId="threshold">
            <Form.Control
              type="text"
              className="form-control"
              placeholder="e.g. : Portfolio type"
              value={type}
              onChange={(e) => setType(e.target.value)}
              readOnly
            />
          </Form.Group>
        </div>
      </div>
      <div className="row">
        {/* <div className="col-sm-3">
                    <label className="form-label"><TodayIcon /> Threshold Lower Limit({'<'}100)</label>

                    <Form.Group controlId="thresholdLowerLimit">
                        <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="e.g. : 80"
                            value={thresholdLowerLimit}
                            onChange={thresholdLowerLimitHandler}
                            disabled={defaultDisabled}
                        />
                    </Form.Group>
                </div>

                <div className="col-sm-3">
                    <label className="form-label"><TodayIcon /> Threshold Upper Limit({'>'}100)</label>

                    <Form.Group controlId="thresholdUpperLimit">
                        <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="e.g. : 125"
                            value={thresholdUpperLimit}
                            onChange={thresholdUpperLimitHandler}
                            disabled={defaultDisabled}
                        />
                    </Form.Group>
                </div> */}

        {/* <div className="col-sm-2">
                    <button disabled={thresholdButtonDisable} className="btn btn-outline-success" style={{marginTop: '20%'}} id="btn-success" onClick={handleThreshold}>Set Threshold</button>
                </div> */}
      </div>
      <div className="row">
        <div className="col-sm-3">
          <label className="form-label">
            <TodayIcon /> Creation Date
          </label>

          <Form.Group controlId="date">
            <Form.Control
              type="date"
              name="duedate"
              placeholder="Due date"
              value={inceptionDate}
              onChange={(e) => setInceptionDate(e.target.value)}
              readOnly
            />
          </Form.Group>
        </div>

        {/* <div className="col-sm-3">
                    <label className="form-label"><MoneyIcon /> Investment Amount</label>
                    <Form.Group controlId="inceptionValue">
                        <Form.Control
                            type="text"
                            className="form-control"
                            name="inceptionvalue"
                            placeholder="e.g. : 10000000"
                            value={numberWithCommas(inceptionValue)}
                            onChange={(e) => setInceptionValue(e.target.value)}
                            readOnly
                        />
                    </Form.Group>
                </div> */}
        <div className="col-sm-3">
          <label className="form-label required">
            <MoneyIcon /> Add securities +
          </label>
          <Form.Group controlId="fileContents">
            <Form.Control
              type="file"
              name="fileContents"
              value={fileContents}
              onChange={(e) => {
                setButtonDisable(false);
                const file = e.target.files[0];
                fileUpload(file);
              }}
              disabled={fileUploadDisable}
            />
          </Form.Group>
        </div>
      </div>

      {/* <div className="row">
                <div className="col-sm-3">
                    <label className="form-label required"><MoneyIcon /> Add securities +</label>
                    <Form.Group controlId="fileContents">
                        <Form.Control
                            type="file"
                            name="fileContents"
                            value={fileContents}
                            onChange={(e) => {
                                setButtonDisable(false);
                                const file = e.target.files[0];
                                fileUpload(file)
                            }}
                            disabled={fileUploadDisable}
                        />
                    </Form.Group>
                </div> */}
      {/* <div className="col-sm-3">
                    <label className="form-label required"><MoneyIcon /> Total Current Value</label>
                    <Form.Group controlId="totalCurrValue">
                        <Form.Control
                            type="text"
                            className="form-control"
                            name="totalCurrVal"
                            placeholder="e.g. : 10000000"
                            value={numberWithCommas(totalCurrVal)}
                            onChange={(e) => setTotalCurrVal(e.target.value)}
                            readOnly
                        />
                    </Form.Group>
                </div> */}

      {/* </div> */}
      <div className="row">
        <div className="col-sm-3">
          <label className="form-label">
            <ShortTextIcon /> Total Prescribed Weight(%)
          </label>

          <Form.Group controlId="threshold">
            <Form.Control
              type="text"
              className="form-control"
              value={calculateTotalPrescribedWt("Prescribed Wt")}
              readOnly
            />
          </Form.Group>
        </div>
        <div className="col-sm-3">
          <label className="form-label">
            <ShortTextIcon /> Total Current Weight(%)
          </label>

          <Form.Group controlId="threshold">
            <Form.Control
              type="text"
              className="form-control"
              value={calculateTotalPrescribedWt("Current Wt")}
              readOnly
            />
          </Form.Group>
        </div>
      </div>
    </form>
  );

  const handleFundsChange = (e) => {
    setAmount(e.target.value.replace(/,/g, ""));
    setfundCash(0);
    let fundCash = 0;

    tableFundsData.forEach((element) => {
      element["Suggested Amount Allocation"] = Number(
        (
          (element["Current Weight"] / 100) *
          Number(e.target.value.replace(/,/g, ""))
        ).toFixed(2)
      );

      if (element["Suggested Amount Allocation"] < Number(element["CMP"])) {
        if (!element["Name of Securities"].toLowerCase().includes("cash")) {
          element["Actual Allocation"] = 0;
          element["Number of Shares"] = 0;
        } else {
          element["Number of Shares"] = 1;
        }
      } else {
        element["Number of Shares"] = Math.floor(
          element["Suggested Amount Allocation"] / Number(element["CMP"])
        );
        element["Actual Allocation"] = Number(
          (element["Number of Shares"] * Number(element["CMP"])).toFixed(2)
        );
        fundCash += element["Actual Allocation"];
      }
    });

    let cashElement = tableFundsData.find((element) =>
      element["Name of Securities"].toLowerCase().includes("cash")
    );
    cashElement["Number of Shares"] = (
      e.target.value.replace(/,/g, "") - fundCash
    ).toFixed(2);

    settableFundsData([]);
    tableFundsData.forEach((element) => {
      settableFundsData((tableFundsData) => [...tableFundsData, element]);
    });

    setfundCash(
      Number((e.target.value.replace(/,/g, "") - fundCash).toFixed(2))
    );
    let element = tableFundsData.find((element) =>
      element["Name of Securities"].toLowerCase().includes("cash")
    );
    element["Actual Allocation"] = Number(
      (e.target.value.replace(/,/g, "") - fundCash).toFixed(2)
    );
  };

  const addFundsModelForm = () => (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-6">
          <label className="form-label">
            <ShortTextIcon /> Enter amount
          </label>

          <Form.Group controlId="threshold">
            <Form.Control
              type="text"
              className="form-control"
              placeholder="e.g. : 10000000"
              value={numberWithCommas(amount)}
              onChange={(e) => handleFundsChange(e)}
            />
          </Form.Group>
        </div>
        <div style={{ marginTop: "4.5%" }} className="col-sm-6"></div>
      </div>
    </form>
  );

  return (
    <>
      <div id="style_main">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={{ pathname: `/modport` }}>Models</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Update Model</BreadcrumbItem>
        </Breadcrumb>

        <div className="container">
          <div className="row">
            <div className="col-12">
              <button
                style={{ marginLeft: "1%" }}
                title="Edit Model"
                className="btn btn-outline-success"
                id="btn-success"
                onClick={handleEditPage}
              >
                <CreateIcon />
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 offset-md-3">{addModelForm()}</div>
          </div>

          {handleOpen && <div>{PopupWindow()}</div>}

          <div className="row">
            <div className="col-md-2">
              {Portfolio_Data.status !== "Verified" && (
                <button
                  className="btn btn-outline-success mr-3"
                  id="btn-success"
                  onClick={verifyModelReportHandler}
                >
                  Verify Model
                </button>
              )}
            </div>
            <div className="col-md-10">
              {Portfolio_Data.funds_log.length ? (
                <button
                  className="btn btn-outline-success mt-0 mr-3"
                  id="btn-success"
                  onClick={(e) => exportToCSV(apiData, filename)}
                  disabled={defaultDisabled}
                >
                  Funds Logs Report
                </button>
              ) : null}
              <button
                title="Download Update Model as xls"
                className="btn btn-outline-success"
                id="btn-success"
                onClick={handleDownload}
                disabled={defaultDisabled}
              >
                <DownloadIcon />
              </button>
              <button
                title="Download Data of Customers as xls"
                className="btn btn-outline-success mr-2"
                id="btn-success"
                onClick={handleCustomerDataDownload}
                disabled={defaultDisabled}
              >
                All Funds Report
              </button>
              <button
                title="Refresh"
                className="btn btn-outline-success mr-2"
                id="btn-success"
                onClick={handleRefresh}
                disabled={defaultDisabled}
              >
                <RefreshIcon />
              </button>
              <button
                className="btn btn-outline-success mr-2"
                id="btn-success"
                onClick={previewReportHandler}
                // disabled={Portfolio_Data.customer_portfolio !== undefined && (Portfolio_Data.customer_portfolio.length > 0 ? (Portfolio_Data.customer_portfolio[0].customer_portfolio_securities.length > 0 ? false : true) : true)}
                disabled={Portfolio_Data.status === "Verified" ? false : true}
              >
                Generate Report
              </button>
              <button
                disabled={buttonDisable}
                className="btn btn-outline-success mr-2"
                id="btn-success"
                onClick={handleSubmit}
              >
                Update Model and Portfolio
              </button>
            </div>

            <Wrapper>
              {modal && (
                <Modal
                  title={modal.title}
                  message={
                    <div>
                      <div className="row">
                        <div className="col-md-12">{addFundsModelForm()}</div>
                      </div>

                      <FundsTable
                        tableData={tableFundsData}
                        COLUMNS={COLUMNS}
                      />
                    </div>
                  }
                  onConfirm={applyHandler}
                  onCancel={cancelHandler}
                />
              )}

              {
                <form onSubmit={modalTestHandler}>
                  {/* <Button disabled={fundsButtonDisable} type="submit">Add Funds</Button> */}
                </form>
              }
            </Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            {assetList.length ? (
              <button
                id="dvwm-accordian-toggle"
                onClick={() => setIsExpandOne((prev) => !prev)}
                style={{ marginTop: "22px", float: "right" }}
              >
                {isExpand_one ? "Collapse All" : "Expand All"}
              </button>
            ) : null}
          </div>
        </div>
        <div style={{ marginTop: "2%" }}>
          <div>
            {assetList.map((element, index) => (
              <AccordianComponent
                title={
                  <span style={{ marginLeft: "10px", fontSize: "12px" }}>
                    {
                      <span style={{ fontWeight: "bold", marginRight: "20px" }}>
                        {element}
                      </span>
                    }
                    <span style={{ color: "blue" }}>PW</span>:{" "}
                    {calculatePrescribedWt(element, "Prescribed Wt")}%{" "}
                    <span style={{ color: "blue" }}>CW</span>:{" "}
                    {calculatePrescribedWt(element, "Current Wt")}%
                  </span>
                }
                isExpand={isExpand_one}
                content={
                  <ModelTable
                    type="modelTable"
                    tableData={tableData.filter((el) => {
                      if (element != "Cash")
                        return el["Asset Class"] == element;
                      else return el["Name of Securities"] == element;
                    })}
                    COLUMNS={columns}
                    handleCMP={handleCMP}
                    handleShares={handleShares}
                    handlePrescribedWeight={handlePrescribedWeight}
                    handlePrice={handlePrice}
                    handleRatioStartPt={handleRatioStartPt}
                    handleRatioEndPt={handleRatioEndPt}
                    onSaveActionData={onSaveActionDataHandler}
                    defaultDisabled={defaultDisabled}
                  />
                }
              ></AccordianComponent>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateModel;
