import React, { useState } from "react";
import { toast } from "react-toastify";

import ApiServices from "../../ApiService/ApiConfig";

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("ENV --->", process.env.REACT_APP_REGISTER_REDIRECT_URL);
    const config = {
      url: process.env.REACT_APP_FORGOT_PASSWORD_REDIRECT_URL,
      handleCodeInApp: true,
    };

    ApiServices.checkEmail({
      "email": email
    }).then(res => {
      toast.success(
        `Email is sent to ${email}. Click the link to complete your registration.`
      );
      const userDetails = res;
      console.log(userDetails);
      window.localStorage.setItem("userDet", JSON.stringify(userDetails));
    })
    .catch((err) => {
      console.log(err);
    })
    // clear state
    setEmail("");
  };

  const ForgotPasswordForm = () => (
    <form onSubmit={handleSubmit}>
      <label className="form-label required">Enter Email</label>
      <input
        type="email"
        className="form-control"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        autoFocus
      />

      {/* <button type="submit" className="btn btn-raised">
        Register
      </button> */}
       <button type="submit"  className="btn btn-primary mt-3 mb-3" style={{width: '40%', marginLeft: '1px'}}>
        Send Link</button>
    </form>
  );

  return (
    <>
    <div className="container" style={{marginTop: "80px"}}>
     
      {/* <div className="container-fluid bg-light p-3 text-center">
        <h4>Reset Password</h4>
      </div> */}

      <div className="container p-5">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <h4>Reset Password</h4>
          {/* <h4>Enter Email</h4> */}
          {ForgotPasswordForm()}
        </div>
      </div>
    </div>
    </div>
    </>
   
  );
};

export default ForgotPassword;
