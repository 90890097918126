import { useState,useEffect } from 'react';
import { Link,useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { 
        Button,
        Breadcrumb, 
        BreadcrumbItem,
        Label,
        Row,
        Form,
        FormGroup
    } from 'reactstrap';
import { Formik,Field } from 'formik';
import TextEditor from '../Layout/components-manage-mailformats/reactquill';

// material-ui icons
import ShortTextIcon from '@material-ui/icons/ShortText';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import LoadingSpinner from '../UI/Loader';

import Select from 'react-select';
import ApiServices from '../../ApiService/ApiConfig';
import '../form.css';

const DropDown = props => {
    
    const options = props.multi
      ? [{ label: "Select All", value: "all" }, ...props.options]
      : props.options;
    return (
      <div>
        <Select
          name="example"
          options={options}
          isMulti={props.multi}
          value={props.value ? props.value : null}
          onChange={selected => {
          console.log("selected is >>",selected);
         
          props.multi &&
          selected.length &&
          selected.find(option => option.value === "all")
            ? props.handleChange(options.slice(1))
            : !props.multi
              ? props.handleChange((selected && selected.value) || null)
              : props.handleChange(selected);
        }}
        />
      </div>
    );
  };

const UpdateBroker = (props) => {
    const { id: id } = useParams();
    const brokerData=props.location.state.data;

    const [previewFlag, setPreviewFlag] = useState(false);
    const [warning, setWarning] = useState(false)

	const [isLoading, setIsLoading] = useState(false);
    const [SACList, setSACList] = useState([]);
    const [formData,setFormData]=useState({broker_house:'',address:'',sub_asset_classes:[], mail:[], contact:[]});

	useEffect(() => {
        let temp = {...brokerData}
        temp.sub_asset_classes  = temp.sub_asset_classes.map(el=>({label:el.name, value:el.name}))
        setFormData(temp)
        ApiServices.GetSubAssetList()
            .then((res) => {
                let modified = res.map((el) => ({
                    value: el.asset,
                    label: el.asset,
                }));
                setSACList(modified);
            });
	}, []);
    
    const handlebrokerdrp = (value) => { 
        setWarning(false)
        let temp={...formData};
        temp.sub_asset_classes=value;
        console.log("temp>>>>>",temp.sub_asset_classes);
        setFormData(temp);
    }

    const handleBrokerNameChange=(e)=>{
       setFormData(prev=>({...prev,broker_house:e.target.value}))
    }
    const handleBrokerAddressChange=(e)=>{
        setFormData(prev=>({...prev,address:e.target.value}))
    }
    const handleBrokerDraftingChange=(e)=>{
        setFormData(prev=>({...prev,mail_drafting:e.target.value}))
    }
    
    const handleNumberInputChange=(e,index)=>{
        let temp={...formData};
        temp.contact[index]={...temp.contact[index],number:e.target.value};
        setFormData(temp);
    }

    const handleEmailInputChange=(e, index)=>{
        let temp={...formData};
        temp.mail[index]={...temp.mail[index],email:e.target.value};
        setFormData(temp);
    }

    //mutiple phone numbers
	const onClickContactHandler=(e)=>{
        let temp={...formData};
        temp.contact=[...temp.contact,{number:''}]
		setFormData(temp);
	}

    const onClickEmailHandler=(e)=>{
        let temp={...formData};
        temp.mail=[...temp.mail,{email:''}]
		setFormData(temp);
	}

    const onClickMinus = (name, index) =>{
        let temp = {...formData};
        temp[name] = temp[name].filter((el,i)=>i !== index)
        setFormData(temp)
    }
	
    const getDynamicValues = (purpose) => {
        let dynamics = ['${url}' ,'${token}' ,'${reset_token}', '${reset_validity}', '${id}', '${user_id}']
		return dynamics.map((el, i) => <li id={i}>{el}</li>);
	};


	const updateBrokerForm = () => (
		<div>
			<Formik
				initialValues={{
					brokerName: '',
                    address:'',
                    mobileNumber:'',
					email:'',                   
                    drafting:''
				}}
				
				onSubmit={(values, { setSubmitting }) => {
                    if(!formData.sub_asset_classes.length){
                        setWarning(true)
                        return;
                    }
                   let toSubmit = {...formData}
                   toSubmit.sub_asset_classes = toSubmit.sub_asset_classes.map(el=>({name:el.value}))

                   console.log(toSubmit, 'TO BE SUBMITTED');

					ApiServices.updateBrokerbyID(id, toSubmit)
						.then((res) => {
                            console.log("res>>>>",res);
							setIsLoading(false);
                            toast.success(res.msg);
                            props.history.push('/broker');
						})
						.catch((err) => {
                            console.log("res>>>>",err.response);
							setIsLoading(false);
							toast.warning(`${err.response.data.error}`);
					});
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					/* and other goodies */
				}) => (
					<form onSubmit={handleSubmit} className="mt-3">
						<div className="row">
							<div className="col-6">
								<div className="form-group mb-3">
									<label className="form-label required">
										<ShortTextIcon /> Name of the Broker House 
									</label>
									<input
										type="text"
										name="brokerName"
										min="2"
										className="form-control"
										placeholder="e.g. : Akshay"
										
                                        onChange={e=>handleBrokerNameChange(e)}
                                        onBlur={handleBlur}
                                        value={formData.broker_house}
                                        required
									/>
									
								</div>
							</div>
						</div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group mb-3">
                                    <label>
                                        <ShortTextIcon /> Address
                                    </label>
                                    <input
                                        type="text"
                                        name="address"
                                        min="2"
                                        className="form-control"
                                        placeholder="e.g. : Address"

                                        onChange={e =>handleBrokerAddressChange(e)}
                                        value={formData.address}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
							<div className="col-6">
								<div className="form-group mb-3">
									<label>
										<PhoneIphoneIcon /> Mobile Number (Primary)
									</label>
                                        {formData.contact.map((el,index)=>{
                                            return(
                                                <div className={"input-group mb-3"}>
                                                    <input
                                                        type="tel"
                                                        name="contact"
                                                        maxLength="10"
                                                        className="form-control"
                                                        placeholder="e.g. : 9878654532"
                                                        pattern="[0-9]{10,10}"
                                                        Title="Number contain Ten Digits only"
                                                        onChange={e=>handleNumberInputChange(e,index)}
                                                        onBlur={handleBlur}
                                                        value={formData.contact[index].number}
                                                    />
                                                    {
                                                        index !== 0 ? 
                                                        <button className="btn btn-danger ml-2"  
                                                            type="button"
                                                            
                                                            onClick={
                                                                ()=>onClickMinus('contact', index)
                                                            }
                                                        >
                                                        -
                                                        </button>
                                                        : 
                                                        null
                                                    }
                                                </div>
                                            )
                                        })}
                                              <button className="btn btn-success" 
                                                    type="button"
                                                    onClick={
                                                        e=>onClickContactHandler(e)
                                                    }
                                                    disabled= {formData.contact.length === 5}

                                                >
                                                        +
                                             </button>
								</div>
							</div>
                            <div className="col-6">
								<div className="form-group mb-3">
									<label className="form-label required">
										<EmailOutlinedIcon /> Email address
									</label>

                                    {formData.mail.map((el,index)=>{
                                        return(
                                            <div className="input-group mb-3">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="e.g. : akshay23@gmail.com"
                                                    
                                                    onChange={e=>handleEmailInputChange(e,index)}
                                                    onBlur={handleBlur}
                                                    value={formData.mail[index].email}
                                                    required={index===0?true:false}
                                                />
                                                {
                                                    index !== 0 ? 
                                                    <button className="btn btn-danger ml-2"  
                                                        type="button"
                                                        onClick={
                                                            ()=>onClickMinus('mail', index)
                                                        }
                                                    >
                                                    -
                                                    </button>
                                                    : 
                                                    null
                                                }
                                             </div>
                                        )
                                    })}

                                    <button class="btn btn-success" 
                                            type="button"
                                            onClick={
                                                e=>onClickEmailHandler(e)
                                            }
                                            disabled= {formData.mail.length === 5}
                                    >
                                        +
                                    </button>

								</div>
							</div>
						</div>
   
						<Row md={12}>
                            <Row className="ml-4 mr-1">
                                <FormGroup>
                                    <Label className="required">
                                        <PhoneIphoneIcon />{' '}
                                        Broker Sub-Asset Class
                                    </Label>
                                    <div>
                                        <DropDown
                                            value={formData.sub_asset_classes}
                                            options={SACList}
                                            handleChange={handlebrokerdrp}
                                            multi={true} 
                                        />
                                    </div>
                                    {
                                        warning ? <span style={{color:'red'}}>Please, select atleast one sub asset class</span> : null
                                    }
                                </FormGroup>
                            </Row>
						</Row>
                        {
                            previewFlag ? 
                                <div className="display-mail preview" style={{width:'100%', marginTop:0}}>
                                    <iframe srcDoc={formData.mail_drafting} frameBorder="0"></iframe>
                                </div>
                                :
                                null
                        }
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group mb-3">
                                    <label>
                                        <ShortTextIcon /> Drafting
                                    </label>
                                    <span
                                        className='note-span'
						            >
                                        <b>NOTE</b> : Do not change the values written in{' '}
                                        <b>{'${ }'}</b>
                                    </span>
                                    <span
                                        className='custom-tooltip'
                                    >
							            VALUES TOOLTIP
                                        <ul className="tooltiptext-block">
                                            {getDynamicValues()}
                                        </ul>
						            </span>
                                    <TextEditor
                                        value={formData.mail_drafting}
                                        setTextarea={setFormData}
                                        fieldName = 'mail_drafting'
                                    />
                                    <div className="tripple-buttons">
                                        <button
                                            type='button'
                                            className="customize-mail-button previewbtn"
                                            onClick={() => {
                                                setPreviewFlag((prev) => !prev);
                                            }}
                                        >
                                            {previewFlag ? 'close preview' : 'preview'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
						<Button
							className="btn btn-success mt-3 mb-3"
							type="submit"
							style={{ width: '40%', marginLeft: '132px' }}
						>
							{isLoading && (
								<LoadingSpinner isLoading={isLoading} />
							)}
							Submit
						</Button>
					</form>
				)}
			</Formik>
		</div>
	);
    console.log("formData number>>",formData);
    
	return (
		<>
			<div className="container" id="adjust-top">
				<Breadcrumb>
					<BreadcrumbItem>
						<Link to={{pathname: `/broker`}}>Brokers</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>Update Broker</BreadcrumbItem>
				</Breadcrumb>
				<div className="container-fluid bg-light text-center">
					<h3 className="pl-3 pr-3 pt-3 pb-3">Update Brokers</h3>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-md-6 offset-md-3">
							{updateBrokerForm()}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default UpdateBroker;
