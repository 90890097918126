import React, { useMemo, useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import {
	useTable,
	useSortBy,
	useGlobalFilter,
	usePagination,
	useRowSelect,
} from 'react-table';
import { Fragment } from 'react';
import GlobalFilter from './GlobalFilter';
import './MainTable.css';
import Checkbox from './Checkbox';
import { Tooltip } from 'reactstrap';
import CellCustUser from './CellCustUser';
import RedressalFormStatus from './RedressalFormStatus';
import CreateIcon from '@material-ui/icons/Create';
import {
	Box,
	Button,
	Snackbar,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LoadingSpinner from '../../UI/Loader';

const MainTable = (props) => {
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const columns = useMemo(() => props.COLUMNS, [props.COLUMNS]);
	const data = useMemo(() => props.tableData, [props.tableData]);
	const [rows, setRows] = useState([]);
	const [open, setOpen] = useState(false);
	const [isEdit, setEdit] = useState(false);
	const [disable, setDisable] = useState(true);
	const [disabledDeleteButton, setDisabledDeleteButton] = useState(true);
	const [showConfirm, setShowConfirm] = useState(false);
	const [stateChecked, setStateCheked] = useState({});

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		pageOptions,
		gotoPage,
		pageCount,
		setPageSize,
		prepareRow,
		selectedFlatRows,
		state,
		setGlobalFilter,
	} = useTable(
		{ columns, data },
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
		(hooks) => {
			if (
				props.type === 'manage-customers' ||
				props.type === 'manage-users' || 
				props.type === 'manage-brokers'
			) {
				hooks.visibleColumns.push((columns) => {
					return [
						{
							id: 'selection',
							Header: ({ getToggleAllRowsSelectedProps }) => null,
							Cell: ({ row }) => (
								<Checkbox
									{...row.getToggleRowSelectedProps()}
									onClick={onCheckedCheckboxCustomerDelete}
								/>
							),
						},
						...columns,
					];
				});
			}
		}
	);
	const { globalFilter, pageIndex, pageSize } = state;

	const [trackTrue, setTrackTrue] = useState([]);
	const [trackFalse, setTrackFalse] = useState([]);

	useEffect(() => {
		setStateCheked(state.selectedRowIds);
	});

	let tempoT = [];
	let tempoF = [];

	const onCheckedCheckboxCustomerDelete = (e) => {
		if (e.target.checked)
			setTrackTrue((prev) => {
				tempoT = [...prev, e.target.checked];
				return [...prev, e.target.checked];
			});
		else
			setTrackFalse((prev) => {
				tempoF = [...prev, e.target.checked];
				return [...prev, e.target.checked];
			});
		if (tempoT.length === tempoF.length) setDisabledDeleteButton(true);
		else setDisabledDeleteButton(false);
		console.log(tempoF, tempoT, 'tteemmppoo');
	};

	const toggle = () => setTooltipOpen(!tooltipOpen);

	const handleAdd = () => {
		setRows([
			...rows,
			{
				id: rows.length + 1,
				initial_name: '',
				securities: '',
				CMP: '',
				isin_no: '',
			},
		]);
	};

	const handleEdit = (i) => {
		// If edit mode is true setEdit will
		// set it to false and vice versa
		setEdit(!isEdit);
	};

	// Function to handle save
	const handleSave = () => {
		setEdit(!isEdit);
		setRows(rows);
		setDisable(true);
		setOpen(true);
	};

	const handleInputChange = (e, index) => {
		setDisable(false);
		const { name, value } = e.target;
		const list = [...rows];
		list[index][name] = value;
		setRows(list);
	};

	const handleConfirm = () => {
		setShowConfirm(true);
	};

	const handleRemoveClick = (i) => {
		const list = [...rows];
		list.splice(i, 1);
		setRows(list);
		setShowConfirm(false);
	};

	const handleNo = () => {
		setShowConfirm(false);
	};

	return (
		<Fragment>
			<div id="style-adjust">
				<GlobalFilter
					filter={globalFilter}
					setFilter={setGlobalFilter}
					type={props.type}
					urlAdd={props.urlAdd}
					onDeleteCustomer={(data) => {
						props.onDeleteCustomer(data);
						setDisabledDeleteButton(true);
						setTrackFalse([]);
						setTrackTrue([]);
					}}
					selectedFlatRows={selectedFlatRows.map(
						(row) => row.original
					)}
					disabledDeleteButton={disabledDeleteButton}
				/>
				{/* <AddNew type={props.type} urlAdd={props.urlAdd}/> */}
				{/* {props.type === "Security" && <button onClick={handleAdd}>Add</button>} */}
				{props.type === 'Security' && (
					<center>
						{isEdit ? (
							<div>
								<Button onClick={handleAdd}>
									<AddBoxIcon onClick={handleAdd} />
									ADD
								</Button>
								{rows.length !== 0 && (
									<div>
										{disable ? (
											<Button
												disabled
												align="right"
												onClick={handleSave}
											>
												<DoneIcon />
												SAVE
											</Button>
										) : (
											<Button
												align="right"
												onClick={handleSave}
											>
												<DoneIcon />
												SAVE
											</Button>
										)}
									</div>
								)}
							</div>
						) : (
							<div>
								<Button onClick={handleAdd}>
									<AddBoxIcon onClick={handleAdd} />
									ADD
								</Button>
								<Button align="right" onClick={handleEdit}>
									<CreateIcon />
									EDIT
								</Button>
							</div>
						)}
					</center>
				)}
			</div>
			<div id="style-adjust">
				<Table {...getTableProps()} hover>
					<thead className="set-background">
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th
										{...column.getHeaderProps(
											column.getSortByToggleProps()
										)}
									>
										{column.render('Header')}
										<span>
											{column.isSorted
												? column.isSortedDesc
													? ' 🔽'
													: ' 🔼'
												: ''}
										</span>
									</th>
								))}
								{/* {props.type === "Grievance" && (<th><p id="widthAction">Action</p></th>)}
                        {(props.type === "Customer" || props.type === "Users" || props.type === "Model" || props.type === "Security") && <th>Action</th>} */}
								{(props.type === 'Model' ||
									props.type === 'Security') && (
									<th>Action</th>
								)}
								{props.type === 'Customer' && <th></th>}
								{(props.type === 'manage-customers' || props.type === 'manage-brokers' ||
									props.type === 'manage-users') && (
									<th>Status Update</th>
								)}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.length >= 0 && !props.isLoading ? (
							<>
								{props.type === 'Security' &&
									rows.map((row, i) => {
										return (
											<tr>
												{isEdit ? (
													<>
														<td></td>
														<td>
															<div class="form-group">
																{/* <input type="text" class="form-control" value={row.id} name="firstname" onChange={(e) => handleInputChange(e, i)} /> */}
																<label>
																	{row.id}
																</label>
															</div>
														</td>
														<td>
															<div class="form-group">
																<input
																	type="text"
																	class="form-control"
																	value={
																		row.initial_name
																	}
																	name="initial_name"
																	onChange={(
																		e
																	) =>
																		handleInputChange(
																			e,
																			i
																		)
																	}
																/>
															</div>
														</td>
														<td>
															<div class="form-group">
																<input
																	type="text"
																	class="form-control"
																	value={
																		row.securities
																	}
																	name="securities"
																	onChange={(
																		e
																	) =>
																		handleInputChange(
																			e,
																			i
																		)
																	}
																/>
															</div>
														</td>
														<td>
															<div class="form-group">
																<input
																	type="text"
																	class="form-control"
																	value={
																		row.CMP
																	}
																	name="CMP"
																	onChange={(
																		e
																	) =>
																		handleInputChange(
																			e,
																			i
																		)
																	}
																/>
															</div>
														</td>
														<td>
															<div class="form-group">
																<input
																	type="text"
																	class="form-control"
																	value={
																		row.isin_no
																	}
																	name="isin_no"
																	onChange={(
																		e
																	) =>
																		handleInputChange(
																			e,
																			i
																		)
																	}
																/>
															</div>
														</td>
													</>
												) : (
													<>
														<td></td>
														<td>{row.id}</td>
														<td>
															{row.initial_name}
														</td>
														<td>
															{row.securities}
														</td>
														<td>{row.CMP}</td>
														<td>{row.isin_no}</td>
													</>
												)}
												<td>
													{isEdit ? (
														<Button
															className="mr10"
															onClick={
																handleConfirm
															}
														>
															<ClearIcon />
														</Button>
													) : (
														<Button
															className="mr10"
															onClick={
																handleConfirm
															}
														>
															<DeleteOutlineIcon />
														</Button>
													)}
												</td>
												{showConfirm && (
													<div>
														<Dialog
															open={showConfirm}
															onClose={handleNo}
															aria-labelledby="alert-dialog-title"
															aria-describedby="alert-dialog-description"
														>
															<DialogTitle id="alert-dialog-title">
																{
																	'Confirm Delete'
																}
															</DialogTitle>
															<DialogContent>
																<DialogContentText id="alert-dialog-description">
																	Are you sure
																	to delete
																</DialogContentText>
															</DialogContent>
															<DialogActions>
																<Button
																	onClick={() =>
																		handleRemoveClick(
																			i
																		)
																	}
																	color="primary"
																	autoFocus
																>
																	Yes
																</Button>
																<Button
																	onClick={
																		handleNo
																	}
																	color="primary"
																	autoFocus
																>
																	No
																</Button>
															</DialogActions>
														</Dialog>
													</div>
												)}
											</tr>
										);
									})}
								{page.map((row) => {
									prepareRow(row);
									return (
										<tr {...row.getRowProps()}>
											{row.cells.map((cell) => {
												return (
													<td
														{...cell.getCellProps()}
													>
														{cell.render('Cell')}
													</td>
												);
											})}
											<RedressalFormStatus
												type={props.type}
												rowStatus={row.original.status}
												rowPriority={
													row.original.priority
												}
												urlActionEdit={
													props.urlActionEdit
												}
												rowId={row.original.id}
												rowData={row.original}
											/>
											<CellCustUser
												type={props.type}
												urlActionEdit={
													props.urlActionEdit
												}
												urlActionView={
													props.urlActionView
												}
												urlRiskProfile={
													props.urlRiskProfile
												}
												rowId={row.original.id}
												rowData={row.original}
												onUpdateCustomerStatus={
													props.onUpdateCustomerStatus
												}
											/>
										</tr>
									);
								})}
							</>
						) : (
							<div>
								<LoadingSpinner isLoading={props.isLoading} />
							</div>
						)}
					</tbody>
				</Table>
			</div>
			<div id="style-adjust-footer">
				<span>
					Page{' '}
					<strong>
						{pageIndex + 1} of {pageOptions.length}
					</strong>{' '}
				</span>
				<span style={{ paddingLeft: '10px' }}>
					| Go to page:{' '}
					<input
						type="number"
						defaultValue={pageIndex + 1}
						onChange={(e) => {
							const pageNumber = e.target.value
								? Number(e.target.value) - 1
								: 0;
							gotoPage(pageNumber);
						}}
						style={{ width: '35px', height: '35px' }}
					/>
				</span>
				<span style={{ paddingLeft: '10px' }}>
					<select
						value={pageSize}
						onChange={(e) => setPageSize(Number(e.target.value))}
						style={{ width: '9em', height: '35px' }}
					>
						{[25, 50, 100].map((pageSize) => (
							<option key={pageSize} value={pageSize}>
								Show {pageSize} Records
							</option>
						))}
					</select>
				</span>
				<span className="pagination-style">
					<button
						className={`btn-actions ${
							!canPreviousPage ? 'disabled' : ''
						}`}
						onClick={() => gotoPage(0)}
						disabled={!canPreviousPage}
					>
						{'<<'}
					</button>
					<button
						className={`btn-style ${
							!canPreviousPage ? 'disabled' : ''
						}`}
						onClick={(e) => previousPage()}
						disabled={!canPreviousPage}
					>
						Previous
					</button>
					<button
						className={`btn-style ${
							!canNextPage ? 'disabled' : ''
						}`}
						onClick={(e) => nextPage()}
						disabled={!canNextPage}
					>
						Next
					</button>
					<button
						className={`btn-actions ${
							!canNextPage ? 'disabled' : ''
						}`}
						onClick={() => gotoPage(pageCount - 1)}
						disabled={!canNextPage}
					>
						{'>>'}
					</button>
				</span>
			</div>
		</Fragment>
	);
};

export default MainTable;