import {Link} from 'react-router-dom';
export const COLUMNS = [
   
    {
        Header: 'Portfolio Name',
        accessor: 'portfolio_name',
        Cell: ({ row }) => {
            return(
                <Link to={{pathname: `/updatecustomerportfolio/${row.original.portfolio_name}`, state: {data : row.original}}}>{row.original.portfolio_name}</Link>
            )
        }
    },
    {
        Header: 'Customer Name',
        accessor: properties => properties.user.first_name+" "+properties.user.last_name
    },
    // {
    //     Header: 'Broker Name',
    //     accessor: properties => properties.user.customer_profile.broker_name
    // },
    {
        Header: 'Model Name',
        accessor: properties => properties.type,
        Cell: ({ row }) => {
            return(
                <Link to={{pathname: `/updatemodel/${row.original.portfolio_model.name}`, state: {data : row.original.portfolio_model}}}>{row.original.portfolio_model.name}</Link>
            )
        }
    },
    {
        Header: 'Inception Date',
        accessor: properties => new Date(properties.date).toLocaleDateString('en-GB', {day: 'numeric', month: 'numeric', year: 'numeric'})
    },
    {
        Header: 'Investment Amount',
        // accessor: properties => properties.inception_value !== null ? (properties.inception_value).toFixed(2) : <p style={{color: 'red'}}>Not Set.</p>,
        accessor: properties => properties.inception_value,
        Cell: ({ row }) => {
            let totalFunds = (row.original.cust_funds_log.map((element) => element["funds_added"]).reduce((prev, curr) => prev + curr, 0)) + row.original.inception_value;

            return(
                (totalFunds).toFixed(2) === "0.00" ? <p style={{color: 'red'}}>Not Set.</p> : (totalFunds).toFixed(2)
            )
        }
    },
    {
        Header: 'Total Current Value',
        accessor: properties => (properties.customer_portfolio_securities.map((element) => element["Current Value"]).reduce((prev, curr) => prev + curr, 0)).toFixed(2),
        Cell: ({ row }) => {
            return(
                (row.original.customer_portfolio_securities.map((element) => element["Current Value"]).reduce((prev, curr) => prev + curr, 0)).toFixed(2) === "0.00" ? <p style={{color: 'red'}}>Not Set.</p> : (row.original.customer_portfolio_securities.map((element) => element["Current Value"]).reduce((prev, curr) => prev + curr, 0)).toFixed(2)
            )
        }
    },
    // {
    // Header: 'Status',
    // accessor: properties => properties.status!=="Verified" ? <p style={{color: 'red'}}>Unverified</p> : <p style={{color: 'green'}}>Verified</p>
    // },
    {
        Header: 'Created By',
        accessor: 'createdBy'
    }
]