import React, { useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import Form from "react-bootstrap/Form";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useFilters,
} from "react-table";
import { Fragment } from "react";
import Filter from "./filter";
import "./table.css";
import Checkbox from "./Checkbox";
import { useUserInfo } from "../../../ContextProviders/UserInfo";
import RepeatIcon from "@mui/icons-material/Repeat";
import DownloadIcon from "@mui/icons-material/Download";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import ColumnFilter from "./column-filter";

const TradeMIStable = (props) => {
  const userInfo = useUserInfo();
  const columns = useMemo(() => props.COLUMNS, [props.COLUMNS]);
  const data = useMemo(() => props.tableData, [props.tableData]);
  const history = useHistory();
  //console.log(columns, "Array" , data)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    selectedFlatRows,
    state,
    setGlobalFilter,
    setFilter,
  } = useTable(
    { columns, data },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <Checkbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );
  const { globalFilter, pageIndex, pageSize } = state;

  const sendReportToBroker = (
    unique_id_send_report_broker,
    source,
    inception_val_added_status,
    trade_advice_code
  ) => {
    props.onClickSendBroker(
      unique_id_send_report_broker,
      source,
      inception_val_added_status,
      trade_advice_code
    );
  };

  const sendBulkReportToBroker = (selectedFlatRows) => {
    props.setSendLoading(true);
    props.onClickBulkSendBroker(selectedFlatRows);
  };

  const onClickResendButton = (
    id,
    type,
    source,
    inception_val_added_status,
    trade_advice_code
  ) => {
    props.onClickResendLink(
      id,
      type,
      source,
      inception_val_added_status,
      trade_advice_code
    );
  };

  const onClickDownloadButton = (
    id,
    type,
    source,
    inception_val_added_status,
    trade_advice_code
  ) => {
    props.onClickDownloadButton(
      id,
      type,
      source,
      inception_val_added_status,
      trade_advice_code
    );
  };

  const onClickDownloadReportButton = (data) => {
    props.onClickDownloadReportButton(data);
  };

  const onClickResendOTPButtonCRM = (id, type) => {
    props.onClickResendLinkOTPCRM(id, type);
  };

  const onChangeVal = (type, e) => {
    setFilter(type, e);
  };

  const saveTradeMIS = (id, data) => {
    props.onClickSaveMFData(id, data);
  };

  return (
    <Fragment>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            {props.type === "tradeMISTableReports" && props.FilterReports}
          </div>
          <div className="col-md-6">
            <div className="col-md-6" style={{ marginLeft: "105px" }}>
              <Filter
                setFilter={setGlobalFilter}
                selectedFlatRows={selectedFlatRows.map((row) => row.original)}
              />
              {/* </div>
                <div className='col-md-6' style={{float: 'right'}}> */}
              {props.type === "tradeMISTableReports" && (
                <ColumnFilter onChangeVal={onChangeVal} />
              )}
            </div>
          </div>
        </div>
        {props.type === "tradeMISTableReports" && (
          <button
            title="Download Report"
            className="btn btn-outline-success"
            id="btn-success"
            onClick={(e) => onClickDownloadReportButton(selectedFlatRows)}
          >
            <DownloadIcon />
          </button>
        )}
        {userInfo.role.role_name === "super-admin" &&
          props.type === "tradeMISTableBroker" && (
            <button
              type="button"
              className="btn btn-outline-success"
              style={{ float: "right" }}
              onClick={(e) => sendBulkReportToBroker(selectedFlatRows)}
            >
              {props.sendLoading ? "Sending..." : "Send"}
            </button>
          )}
      </div>
      <div id="style-adjust">
        <Table {...getTableProps()} hover>
          <thead className="set-background">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                      {/* {props.type === "tradeMISTableReports" && column.canFilter ? column.render('Filter') : null} */}
                    </span>
                  </th>
                ))}
                {userInfo.role.role_name === "super-admin" &&
                  props.type === "tradeMISTableBroker" && (
                    <th>Send to Broker</th>
                  )}
                {(props.type === "tradeMISTableSA" ||
                  props.type === "tradeMISTableCustomer" ||
                  props.type === "tradeMISTableBroker" ||
                  props.type === "tradeMISTableReports") && <th>Action</th>}
                {(props.type === "tradeMISTableSA" ||
                  props.type === "tradeMISTableCustomer") && <th>Open Mail</th>}
                {props.type === "tradeMISTableCRM" && (
                  <>
                    <th>Status</th>
                  </>
                )}
                {props.type === "tradeMISTableCRM" && (
                  <>
                    <th>Email</th>
                    <th>Whatsapp</th>
                  </>
                )}
                {props.type === "tradeMISTableMF" && (
                  <>
                    <th>Enter Unique code</th>
                    <th>Action</th>
                  </>
                )}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                  {userInfo.role.role_name === "super-admin" &&
                    props.type === "tradeMISTableBroker" && (
                      <td>
                        {row.original.reportVerified === "Accepted" ? (
                          row.original.send_report_broker_status !== 1 ? (
                            <button
                              type="button"
                              className="btn btn-outline-success"
                              onClick={(e) =>
                                sendReportToBroker(
                                  row.original.unique_id_send_report_broker,
                                  row.original.source,
                                  row.original.inception_val_added_status,
                                  row.original.trade_advice_code
                                )
                              }
                            >
                              Send
                            </button>
                          ) : (
                            "-"
                          )
                        ) : (
                          "-"
                        )}
                      </td>
                    )}
                  {(row.original.source === "Add Funds" ||
                    row.original.inception_val_added_status === 1) &&
                    (props.type === "tradeMISTableSA" ||
                      props.type === "tradeMISTableCustomer" ||
                      props.type === "tradeMISTableBroker") && (
                      <td>
                        {row.original.reportVerified === null ||
                        row.original.reportVerified === "Send To Broker" ? (
                          <>
                            <Link
                              onClick={(e) =>
                                onClickResendButton(
                                  row.original.unique_id_send_report_broker,
                                  row.original.type,
                                  row.original.source,
                                  row.original.inception_val_added_status,
                                  row.original.trade_advice_code
                                )
                              }
                              data-tip
                              data-for="Tooltip10"
                            >
                              <RepeatIcon />
                            </Link>
                            <ReactTooltip
                              id="Tooltip10"
                              place="bottom"
                              effect="solid"
                            >
                              Resend
                            </ReactTooltip>
                          </>
                        ) : (
                          "-"
                        )}
                      </td>
                    )}
                  {(row.original.source === "ModPort" ||
                    row.original.source === "DVWM") &&
                    row.original.inception_val_added_status === null &&
                    (props.type === "tradeMISTableSA" ||
                      props.type === "tradeMISTableCustomer" ||
                      props.type === "tradeMISTableBroker") && (
                      <td>
                        {row.original.reportVerified === null ||
                        row.original.reportVerified === "Send To Broker" ? (
                          <>
                            <Link
                              onClick={(e) =>
                                onClickResendButton(
                                  row.original.unique_id_send_report_broker,
                                  row.original.type,
                                  row.original.source,
                                  row.original.inception_val_added_status,
                                  row.original.trade_advice_code
                                )
                              }
                              data-tip
                              data-for="Tooltip12"
                            >
                              <RepeatIcon />
                            </Link>
                            <ReactTooltip
                              id="Tooltip12"
                              place="bottom"
                              effect="solid"
                            >
                              Resend
                            </ReactTooltip>
                            {/* {props.type === "tradeMISTableSA" ? 
                                                <>
                                                    <Link onClick={(e) => onClickDownloadButton(row.original.unique_id_send_report_broker, row.original.type, row.original.source, row.original.inception_val_added_status)} data-tip data-for="Tooltip13">
                                                        <DownloadIcon />
                                                    </Link>
                                                    <ReactTooltip id="Tooltip13" place="bottom" effect="solid" >
                                                        Download Report
                                                    </ReactTooltip>
                                                </> 
                                                : null
                                            } */}
                          </>
                        ) : (
                          "-"
                        )}
                      </td>
                    )}
                  {props.type === "tradeMISTableCRM" && (
                    <td>
                      {row.original.cust_email_verified_status === "Verified" &&
                      row.original.cust_phone_verified_status === "Verified"
                        ? "Done"
                        : "Pending"}
                    </td>
                  )}
                  {props.type === "tradeMISTableCRM" && (
                    <td>
                      {row.original.cust_email_verified_status ===
                      "Unverified" ? (
                        <>
                          <Link
                            onClick={(e) =>
                              onClickResendOTPButtonCRM(row.original.id, {
                                type: "email",
                              })
                            }
                            data-tip
                            data-for="Tooltip14"
                          >
                            <RepeatIcon />
                          </Link>
                          <ReactTooltip
                            id="Tooltip14"
                            place="bottom"
                            effect="solid"
                          >
                            Resend
                          </ReactTooltip>
                        </>
                      ) : (
                        row.original.cust_email_verified_status
                      )}
                    </td>
                  )}
                  {props.type === "tradeMISTableCRM" && (
                    <td>
                      {row.original.cust_phone_verified_status ===
                      "Unverified" ? (
                        <>
                          <Link
                            onClick={(e) =>
                              onClickResendOTPButtonCRM(row.original.id, {
                                type: "phone",
                              })
                            }
                            data-tip
                            data-for="Tooltip15"
                          >
                            <RepeatIcon />
                          </Link>
                          <ReactTooltip
                            id="Tooltip15"
                            place="bottom"
                            effect="solid"
                          >
                            Resend
                          </ReactTooltip>
                        </>
                      ) : (
                        row.original.cust_phone_verified_status
                      )}
                    </td>
                  )}
                  {props.type === "tradeMISTableReports" ? (
                    <>
                      <td>
                        {row.original.type === "SA" ? (
                          <>
                            <Link
                              onClick={(e) =>
                                onClickDownloadButton(
                                  row.original.unique_id_send_report_broker,
                                  row.original.type,
                                  row.original.source,
                                  row.original.inception_val_added_status,
                                  row.original.trade_advice_code
                                )
                              }
                              data-tip
                              data-for="Tooltip11"
                            >
                              <DownloadIcon />
                            </Link>
                            <ReactTooltip
                              id="Tooltip11"
                              place="bottom"
                              effect="solid"
                            >
                              Download Report
                            </ReactTooltip>
                          </>
                        ) : (
                          <p style={{ paddingLeft: "5px" }}>-</p>
                        )}
                      </td>
                    </>
                  ) : null}
                  {props.type === "tradeMISTableMF" && (
                    <>
                      <td>
                        <Form.Group controlId="UniqueCode">
                          <Form.Control
                            type="text"
                            name="unique_code"
                            value={row.original.entered_unique_code}
                            onChange={(e) =>
                              props.onChangeUniqueCode(
                                row.original.id,
                                e.target.value,
                                row.original.createdAt
                              )
                            }
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-outline-success"
                          onClick={(e) =>
                            saveTradeMIS(
                              row.original.id,
                              row.original.entered_unique_code
                            )
                          }
                        >
                          save
                        </button>
                      </td>
                    </>
                  )}
                  {props.type === "tradeMISTableSA" &&
                    data[index].source === "ModPort" && (
                      <td>
                        <button
                          onClick={() =>
                            history.push(
                              `/modPortSuperAdmin/${data[index].model_name}/${data[index].trade_advice_code}`
                            )
                          }
                          type="button"
                          className="btn btn-outline-success"
                        >
                          View
                        </button>
                        {/* {row.original.reportVerified === 'Accepted' ? (row.original.send_report_broker_status !== 1 ? 
                                    <button type='button' className="btn btn-outline-success" onClick={(e) => sendReportToBroker(row.original.unique_id_send_report_broker, row.original.source, row.original.inception_val_added_status, row.original.trade_advice_code)}>
                                        hellooooo
                                    </button> : '-') : '-'} */}
                      </td>
                    )}
                  {props.type === "tradeMISTableSA" &&
                    data[index].source === "Add Funds" && (
                      <td>
                        <button
                          onClick={() =>
                            history.push(
                              `/addFundsSuperAdminDataPage/${data[index].portfolio_name}/${data[index].reportResetToken}/${data[index].reportResetValidity}/${data[index].user_broker_cust_id}/${data[index].trade_advice_code}`
                            )
                          }
                          type="button"
                          className="btn btn-outline-success"
                        >
                          View
                        </button>
                        {/* {row.original.reportVerified === 'Accepted' ? (row.original.send_report_broker_status !== 1 ? 
                                    <button type='button' className="btn btn-outline-success" onClick={(e) => sendReportToBroker(row.original.unique_id_send_report_broker, row.original.source, row.original.inception_val_added_status, row.original.trade_advice_code)}>
                                        hellooooo
                                    </button> : '-') : '-'} */}
                      </td>
                    )}
                  {console.log("data------", data, data[index].portfolio_name)}
                  {props.type === "tradeMISTableCustomer" &&
                    data[index].source === "ModPort" && (
                      <td>
                        <button
                          onClick={() =>
                            history.push(
                              `/modPortCustomerDataPage/${data[index].portfolio_name}/${data[index].trade_advice_code}`
                            )
                          }
                          type="button"
                          className="btn btn-outline-success"
                        >
                          View
                        </button>
                        {/* {row.original.reportVerified === 'Accepted' ? (row.original.send_report_broker_status !== 1 ? 
                                    <button type='button' className="btn btn-outline-success" onClick={(e) => sendReportToBroker(row.original.unique_id_send_report_broker, row.original.source, row.original.inception_val_added_status, row.original.trade_advice_code)}>
                                        hellooooo
                                    </button> : '-') : '-'} */}
                      </td>
                    )}
                  {props.type === "tradeMISTableCustomer" &&
                    data[index].source === "Add Funds" && (
                      <td>
                        <button
                          onClick={() =>
                            history.push(
                              `/addFundsCustomerDataPage/${data[index].portfolio_name}/${data[index].reportResetToken}/${data[index].reportResetValidity}/${data[index].user_broker_cust_id}/${data[index].trade_advice_code}`
                            )
                          }
                          type="button"
                          className="btn btn-outline-success"
                        >
                          View
                        </button>
                        {/* {row.original.reportVerified === 'Accepted' ? (row.original.send_report_broker_status !== 1 ? 
                                    <button type='button' className="btn btn-outline-success" onClick={(e) => sendReportToBroker(row.original.unique_id_send_report_broker, row.original.source, row.original.inception_val_added_status, row.original.trade_advice_code)}>
                                        hellooooo
                                    </button> : '-') : '-'} */}
                      </td>
                    )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div id="style-adjust-footer">
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span style={{ paddingLeft: "10px" }}>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageNumber = e.target.value
                ? Number(e.target.value) - 1
                : 0;
              gotoPage(pageNumber);
            }}
            style={{ width: "35px", height: "35px" }}
          />
        </span>
        <span style={{ paddingLeft: "10px" }}>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            style={{ width: "9em", height: "35px" }}
          >
            {[25, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize} Records
              </option>
            ))}
          </select>
        </span>
        <span className="pagination-style">
          <button
            className={`btn-actions ${!canPreviousPage ? "disabled" : ""}`}
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </button>
          <button
            className={`btn-style ${!canPreviousPage ? "disabled" : ""}`}
            onClick={(e) => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </button>
          <button
            className={`btn-style ${!canNextPage ? "disabled" : ""}`}
            onClick={(e) => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </button>
          <button
            className={`btn-actions ${!canNextPage ? "disabled" : ""}`}
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>
        </span>
      </div>
      {/* <pre>
            <code>
                {JSON.stringify(
                    {
                        selectedFlatRows: selectedFlatRows.map((row) => row.original)
                    },
                    null,
                    2
                )}
            </code>
        </pre> */}
    </Fragment>
  );
};

export default TradeMIStable;
