import React, { useEffect, useState } from 'react';
import ApiServices from '../../ApiService/ApiConfig';
import { COLUMNS } from './Table-layout/Columns/column-manage-broker';
import MainTable from './Table-layout/MainTable';
import { toast } from "react-toastify";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';

const ManageBroker = (props) => {
    const [tableData, settableData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [del, setDel] = useState();
    
    useEffect(() => {
        brokerData();
    }, [del])

    const brokerData = () => {
        ApiServices.listBrokerWithStatus()
          .then((res) => {
            let modified = res.brokers.map(broker=>{
              return {
                first_name : broker.broker_house,
                middle_name : '', last_name: '',
                customer_status: broker.active ? 'Active' : 'InActive',
                id : broker.id
              }
            })
            setIsLoading(false);
            settableData(modified);
          }).catch((err) => {
            setIsLoading(false);
          })
    }

    if (!tableData) return null;

    const onDeleteCustomer = (data) => {
      let modified = data.map(el => el.id)
      ApiServices.deleteBroker({IdArray : modified})
      .then(res => {
        setIsLoading(false);
        props.history.push("/managebroker");
        setDel(Math.random());
        toast.success(
          `${res.message}`
        );
        }).catch((err) => {
          setIsLoading(false);
        })
    }

    const onUpdateCustomerStatus = (id, data) => {
      let modified = {
        status : data.customer_status.toLowerCase() === 'active' ? true : false
      }
      ApiServices.updateBrokerStatus(id, modified)
      .then(res => {
        setIsLoading(false);
        props.history.push("/managebroker");
        setDel(Math.random());
        toast.success(
          `${res.message}`
        );
        }).catch((err) => {
          setIsLoading(false);
        })
    }

    console.log('state table d',tableData);
    
    return (
        <div id="style_main">
          <Breadcrumb>
            <BreadcrumbItem><Link to={{pathname: `/profile`}}>Settings</Link></BreadcrumbItem>
            <BreadcrumbItem active>Manage Broker </BreadcrumbItem>
          </Breadcrumb>
           <MainTable tableData={tableData} COLUMNS={COLUMNS} type="manage-brokers" urlAdd="/adduser" urlActionEdit="/updateuser/" urlActionView="/viewuser/" 
           isLoading={isLoading} onDeleteCustomer={onDeleteCustomer} onUpdateCustomerStatus={onUpdateCustomerStatus}
           /> 
        </div>
    );
}

export default ManageBroker;