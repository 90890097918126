import React from 'react';
import './column-filter.css';

const ColumnFilter = (props) => {
	return (
		<>
			<div className="row">
				<div className="col-md-6">
					<input
						placeholder="Model Name"
						onChange={(e) =>
							props.onChangeVal('model_name', e.target.value)
						}
						style={{ width: '150px', marginBottom: '10px' }}
						id="input-pass-filter"
					/>
				</div>
				{/* <div className='col-md-6'>
            <input
                placeholder='Portfolio Name'
                onChange={(e) => props.onChangeVal("portfolio_name", e.target.value)}
                style={{width: "150px", marginLeft: "50px", marginBottom: "10px"}}
                id="input-pass-filter"
            />
            </div> */}
				<div className="col-md-6">
					<input
						placeholder="Customer Name"
						onChange={(e) =>
							props.onChangeVal('customer_name', e.target.value)
						}
						style={{
							width: '150px',
							marginLeft: '50px',
							marginBottom: '10px',
						}}
						id="input-pass-filter"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-md-6">
					<input
						placeholder="Location"
						onChange={(e) =>
							props.onChangeVal('type', e.target.value)
						}
						style={{ width: '150px', marginBottom: '10px' }}
						id="input-pass-filter"
					/>
				</div>
				<div className="col-md-6">
					<input
						placeholder="Status"
						onChange={(e) =>
							props.onChangeVal('reportVerified', e.target.value)
						}
						style={{
							width: '150px',
							marginLeft: '50px',
							marginBottom: '10px',
						}}
						id="input-pass-filter"
					/>
				</div>
			</div>
			<div className="row">
				{/* <div className="col-md-6">
					<input
						placeholder="Consent Date"
						onChange={(e) =>
							props.onChangeVal('consent_date', e.target.value)
						}
						style={{ width: '150px', marginBottom: '10px' }}
						id="input-pass-filter"
					/>
				</div> */}
				<div className="col-md-6">
					<input
						placeholder="Trade ID"
						onChange={(e) =>
							props.onChangeVal(
								'trade_advice_code',
								e.target.value
							)
						}
						style={{ width: '150px', marginBottom: '10px' }}
						id="input-pass-filter"
					/>
				</div>
				<div className="col-md-6">
					<input
						placeholder="Source"
						onChange={(e) =>
							props.onChangeVal('source', e.target.value)
						}
						style={{
							width: '150px',
							marginLeft: '50px',
							marginBottom: '10px',
						}}
						id="input-pass-filter"
					/>
				</div>
			</div>
		</>
	);
};

export default ColumnFilter;
