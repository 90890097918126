

import { useState, useEffect } from "react";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import '../form.css';
import XLSX from 'xlsx';


// material-ui icons
import ShortTextIcon from '@material-ui/icons/ShortText';
import TodayIcon from '@material-ui/icons/Today';
import MoneyIcon from '@material-ui/icons/Money';

import Form from "react-bootstrap/Form";

import ModelTable from '../Layout/Table-layout/ModelTable';

import FundsTable from '../Layout/Table-layout/FundsTable';

import ApiServices from "../../ApiService/ApiConfig";

import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';

import * as moment from 'moment';
import { COLUMNS } from '../Layout/Table-layout/Columns/columns-funds';

import { fundService } from "../../services/fund.service";

const AddFunds = (props) => {
    const [tableData, settableData] = useState([]);
    const [name, setName] = useState("");
    const [amount, setAmount] = useState("");
    const [type, setType] = useState("");
    const [thresholdLowerLimit, setThresholdLowerLimit] = useState("");
    const [thresholdUpperLimit, setThresholdUpperLimit] = useState("");
    const [inceptionDate, setInceptionDate] = useState("");
    const [inceptionValue, setInceptionValue] = useState("");
    const [startDate, setStartDate] = useState(new Date());

    const [fileContents, setFileContents] = useState();
    const [files, setFiles] = useState();
    const [columns, setColumns] = useState([]);

    const [buttonDisable, setButtonDisable] = useState(true);

    const { name: model_name } = useParams();

    useEffect(() => {
        // console.log(model_name);
        // modelData();
       
        settableData(props.tableData);
    },[])

    const modelData = () => {
        ApiServices.getModel('aaa')
            .then((res) => {
                console.log(res);
                setName(res.name);
                setType(res.type);
                setThresholdLowerLimit(res.threshold_lower_limit);
                setThresholdUpperLimit(res.threshold_upper_limit);
                setInceptionDate(res.date);
                setInceptionValue(res.inception_value);

                let processedData = [];
                res.model_securities.map(element => {
                    let obj = {};
                    obj['ISIN No'] = element['issin_number'];
                    obj['Security Name'] = element['name_of_securities'];
                    obj['Asset Class'] = element['asset_class'];
                    obj['Sub Asset Class'] = element['sub_asset_class'];
                    obj['Market Cap'] = element['market_cap'];
                    obj['CMP'] = element['cmp'];
                    obj['No of Shares'] = element['no_of_shares'];

                    obj['Prescribed Weight (%)'] = Math.round(Number(element['prescribed_weight']) * 100);

                    obj['Current Value'] = Math.round(Number(element['dv_model_current_value']));
                    obj['Current Weight (%)'] = Math.round(Number(element['dv_model_current_weight']) * 100);
                    obj['Delta (CW/PW)'] = element['dv_model_current_weight_prescribed_weight'];
                    obj['Actionable'] = element['actionable'];

                    if (obj['Delta (CW/PW)'] < res.threshold_lower_limit)
                        obj['Suggested Trade'] = element['no_of_shares_brought_sold'] + ' (buy)';
                    else if (obj['Delta (CW/PW)'] > res.threshold_upper_limit)
                        obj['Suggested Trade'] = element['no_of_shares_brought_sold'] + ' (sell)';


                    var momentDate = moment(element['updatedAt'])
                    obj['Updated On'] = momentDate.format("MM-DD-YYYY hh:mm:ss A ");

                    processedData.push(obj);
                })

                // createViewTableColumns(processedData);
                settableData(processedData);
            }).catch((err) => {
                console.log(err);
            })
    }


    let tableRowObj = {
        securities: 'abce',
        cmp: null,
        prescribedWeight: null,
        numberOfShares: null,
        currentValue: null
    }


    const calculateTableRow = (id, property) => {

        let g = tableData[id]
        g[property] = tableData[id]['cmp'] * tableData[id]['numberOfShares']

        settableData([
            ...tableData.slice(0, id),
            g,
            ...tableData.slice(id + 1)
        ]
        );

    }

    const updateTableRow = (id, property, value) => {

        let g = tableData[id]
        g[property] = value

        settableData([
            ...tableData.slice(0, id),
            g,
            ...tableData.slice(id + 1)
        ]
        );

    }


    const processModel = (model, total) => {

        console.log(model, total);
        model.map(element => {
            element['dv_model_current_weight'] = (element['dv_model_current_value'] / total);
            element['dv_model_current_weight_prescribed_weight'] = Math.round((element['dv_model_current_weight'] / element['prescribed_weight']) * 100);
            if ((element['dv_model_current_weight_prescribed_weight'] < thresholdLowerLimit) ||
                (element['dv_model_current_weight_prescribed_weight'] > thresholdUpperLimit)) {
                element['actionable'] = 'Yes';
                element['no_of_shares_brought_sold'] = String(Math.round((Math.abs(element['dv_model_current_weight'] - element['prescribed_weight']) * total) / element['cmp']))
            }
            else {
                element['actionable'] = 'No';
                element['no_of_shares_brought_sold'] = "-";
            }
        })

    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // let jsonRequestBody = {};
        // jsonRequestBody['name'] = name;
        // jsonRequestBody['type'] = type;
        // jsonRequestBody['threshold_lower_limit'] = thresholdLowerLimit;
        // jsonRequestBody['threshold_upper_limit'] = thresholdUpperLimit;
        // jsonRequestBody['date'] = inceptionDate;
        // jsonRequestBody['inception_value'] = inceptionValue;

        // let model = [];

        // let total = null;
        // tableData.forEach((element, index) => {
        //     let obj = {};

        //     obj['issin_number'] = element['ISIN Number'];

        //     obj['name_of_securities'] = element['Name of Securities'];

        //     obj['asset_class'] = element['Asset Class'];

        //     obj['sub_asset_class'] = element['Sub Asset Class'];

        //     obj['market_cap'] = element['Marketcap']

        //     obj['cmp'] = element['CMP'];

        //     obj['prescribed_weight'] = element['Prescribed Weight'];

        //     obj['no_of_shares'] = element['No of shares (Model)'];

        //     obj['dv_model_current_value'] = obj['no_of_shares'] * obj['cmp'];
        //     total += obj['dv_model_current_value'];
        //     console.log(obj);

        //     model.push(obj);

        // })


        // // processModel(model, total + 48515241);
        // processModel(model, total);

        // jsonRequestBody['model'] = model;

        // ApiServices.updateModel(jsonRequestBody, model_name)
        //     .then(res => {
        //         console.log(res.data);
        //         props.history.push("/modport");
        //     }).catch((err) => {
        //         console.log(err);
        //     })




    };


    const createViewTableColumns = (data) => {
        setColumns([]);
        console.log(data);
        let keys = Object.keys(data[0]);

        Object.entries(data[0]).forEach(el => {
            let obj = {};
            obj['Header'] = el[0];
            obj['accessor'] = el[0];
            setColumns(columns => [...columns, obj]);
        })

    }

    const createTableColumns = (data) => {

        setColumns([]);
        let keys = Object.keys(data[0]);

        Object.entries(data[0]).forEach(el => {
            let obj = {};
            obj['Header'] = el[0];
            obj['accessor'] = el[0];
            setColumns(columns => [...columns, obj]);
        })

    }

    const createTableData = (data) => {

        settableData([]);
        let keys = Object.keys(data[0]);

        Object.entries(data).forEach(el => {
            el[1]['Prescribed Weight'] = parseFloat(el[1]['Prescribed Weight']).toFixed(3);
            settableData(tableData => [...tableData, el[1]]);

        })

    }

    const handleChange=(e)=>{
        setAmount(e.target.value)
        console.log(e.target.value);

        props.tableData.forEach(element=>{

            element['Suggested Amount Allocation'] = Math.round(element['Current Weight (%)']/100 * Number(e.target.value));
            
            if(element['Suggested Amount Allocation']<element['CMP'])
            {
                console.log(element['Security Name']);
                if(element['Security Name']!='cash')
                {  element['Actual Allocation'] = 0;
                   element['Number of Shares'] = 0;
                }
                else{
                    element['Number of Shares'] = 1;
                }
              
            }

            else{
                element['Number of Shares'] = Math.ceil(element['Suggested Amount Allocation']/element['CMP']);
                element['Actual Allocation'] = Math.round(element['Number of Shares'] * element['CMP']);
            }
            settableData(tableData => [...tableData, element]);

        });
    }

    const handleClick = () =>{

       console.log('hieeeeeeeeeeeeeeeeee');
    }

    const addModelForm = () => (
        <form onSubmit={handleSubmit} >
            <div className="row">

                <div className="col-sm-6">
                    <label className="form-label"><ShortTextIcon /> Enter amount</label>

                    <Form.Group controlId="threshold">
                        <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="e.g. : 10000000"
                            value={amount}
                            onChange={(e) => handleChange(e)
                            
                            }
                          
                        />
                    </Form.Group>
                </div>
                <div style={{marginTop:'4.5%'}} className="col-sm-6"> 
                <button className="btn btn-success" id="btn-success" onClick={handleClick}>Apply to Model</button>

                </div>
              
            </div>

        </form>

    );

    return (
        <>
            <div>
                    <div className="row">
                        <div className="col-md-12">{addModelForm()}
                        </div>

                    </div>
               
                <FundsTable tableData={tableData} COLUMNS={props.COLUMNS} />

                
            </div>
            
        </>
    );
};

export default AddFunds;
