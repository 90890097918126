// // // Import the functions you need from the SDKs you need
// // import { initializeApp } from "firebase/app";
// // import "firebase/auth";
// // // TODO: Add SDKs for Firebase products that you want to use
// // // https://firebase.google.com/docs/web/setup#available-libraries

// // // Your web app's Firebase configuration
// // const firebaseConfig = {
// //   apiKey: "AIzaSyCaoQf1WxaZ-AnaGLA4HxifjiEg2Ei-fEg",
// //   authDomain: "ecom-service.firebaseapp.com",
// //   projectId: "ecom-service",
// //   storageBucket: "ecom-service.appspot.com",
// //   messagingSenderId: "759966767866",
// //   appId: "1:759966767866:web:1ffe5703a02ebc62e8c743"
// // };

// // // // Initialize Firebase
// // // const app = initializeApp(firebaseConfig);

// // // initialize firebase app
// // if (!firebase.apps.length) {
// //     firebase.initializeApp(firebaseConfig);
// //   }


// //   // export default firebase;
// // export const auth = firebase.auth();
// // export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
// // // // Initialize Firebase
// // // const app = initializeApp(firebaseConfig);



// import { initializeApp } from "firebase/app";
// import "firebase/auth";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyCaoQf1WxaZ-AnaGLA4HxifjiEg2Ei-fEg",
//   authDomain: "ecom-service.firebaseapp.com",
//   projectId: "ecom-service",
//   storageBucket: "ecom-service.appspot.com",
//   messagingSenderId: "759966767866",
//   appId: "1:759966767866:web:1ffe5703a02ebc62e8c743"
// };

// // initialize firebase app
// if (!firebase.apps.length) {
//     firebase.initializeApp(firebaseConfig);
//   }

// // export default firebase;
// export const auth = firebase.auth();
// export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();


// import * as firebase from "firebase/app"; // old way, wont work anymore
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// firebase config
const config = {
    apiKey: "AIzaSyCaoQf1WxaZ-AnaGLA4HxifjiEg2Ei-fEg",
    authDomain: "ecom-service.firebaseapp.com",
    projectId: "ecom-service",
    storageBucket: "ecom-service.appspot.com",
    messagingSenderId: "759966767866",
    appId: "1:759966767866:web:1ffe5703a02ebc62e8c743"
};
// initialize firebase app
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}
// export
// export default firebase;
export const auth = firebase.auth();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
