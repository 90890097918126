import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
	Col,
	Button,
	FormGroup,
	Label,
	Input,
	CustomInput,
	Breadcrumb,
	BreadcrumbItem,
} from 'reactstrap';
import '../form.css';
import { Formik } from 'formik';
import ApiServices from '../../ApiService/ApiConfig';

// material-ui icons
import ShortTextIcon from '@material-ui/icons/ShortText';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CreateIcon from '@mui/icons-material/Create';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import LoadingSpinner from '../UI/Loader';
import { Link } from 'react-router-dom';

const UpdateUser = (props) => {
	// imported data
	console.log('state-data', props.location.state.data);
	const Cust_Data = props.location.state.data;
	// const fname = Cust_Data.employee_name.substr(0,Cust_Data.employee_name.indexOf(' '));
	// const lname = Cust_Data.employee_name.substr(Cust_Data.employee_name.indexOf(' ')+1);
	const user_id = Cust_Data.id;
	console.log('user_id', user_id);
	const fname = Cust_Data.first_name;
	const mname = Cust_Data.middle_name;
	const lname = Cust_Data.last_name;
	const emailId = Cust_Data.email;
	const phone = Cust_Data.phone;
	const designationE = Cust_Data.designation;
	const user_role = Cust_Data.role_id;
	console.log('user_role', user_role);
	// states
	const [whatsAppNum, setWhatsAppNum] = useState(phone);
	const [role, setRole] = useState(user_role);
	const [isLoading, setIsLoading] = useState(false);
	const [defaultDisabled, setDefaultDisabled] = useState(true);
	const [WhatsappOptInStatus, setWhatsappOptInStatus] = useState(Cust_Data.OPTInWhatsappStatus === "true" ? true : false);

	const handleEditPage = () => {
		setDefaultDisabled(false);
	};

	const onChangeCheckboxWhatsappOptIn = async(e) => {
		let method;
		setWhatsappOptInStatus(e.target.checked);
		if (e.target.checked === true) {
			method = 'OPT_IN'
		}else{
			method = 'OPT_OUT'
		}
		// let whatsapp_no = countyCode + mobileNumber
		ApiServices.OTPInWhapsapp({'method_Whatsapp': method, 'whatsAppNo': whatsAppNum, 'cust_id': Cust_Data.id, 'Checked_Status': e.target.checked})
		.then((res) => {
			console.log("result11",res);
			if(res.Status === 'error'){
				toast.warn(`${res.Message}`)
			}else{
				toast.success(`${res.Message}`)
			}
			  }).catch((err) => {
				console.log("ERROR", err);
			  })
	}

	const updateUserForm = () => (
		<>
			<div className="row">
				<div className="col-12">
					<button
						style={{ marginLeft: '1%' }}
						title="Edit User"
						className="btn btn-outline-success"
						id="btn-success"
						onClick={handleEditPage}
					>
						<CreateIcon />
					</button>
				</div>
			</div>
			<div>
				<Formik
					initialValues={{
						firstName: fname,
						middleName: mname,
						lastName: lname,
						mobileNumber: phone,
						//  password: '',
						//  role: [{admin: 2}, {superAdmin: 1}],
						email: emailId,
						designation: designationE,
					}}
					validate={(values) => {
						const errors = {};
						// firstname
						if (!values.firstName) {
							errors.firstName = 'Required';
						} else if (!/^[A-Za-z]+/i.test(values.firstName)) {
							errors.firstName = 'Field contain only letters';
						}
						// middlename
						if (!values.middleName) {
							errors.middleName = 'Required';
						} else if (!/^[A-Za-z]+/i.test(values.middleName)) {
							errors.middleName = 'Field contain only letters';
						}
						// lastname
						if (!values.lastName) {
							errors.lastName = 'Required';
						} else if (!/^[A-Za-z]+/i.test(values.lastName)) {
							errors.lastName = 'Field contain only letters';
						}
						// mobilenumber
						if (!values.mobileNumber) {
							errors.mobileNumber = 'Required';
						} else if (
							!/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(
								values.mobileNumber
							)
						) {
							errors.mobileNumber =
								'Number contain Ten Digits only';
						}
						// email
						if (!values.email) {
							errors.email = 'Required';
						} else if (
							!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
								values.email
							)
						) {
							errors.email = 'Invalid email address';
						}
						// designation
						if (!values.designation) {
							errors.designation = 'Required';
						} else if (!/^[A-Za-z]+/i.test(values.designation)) {
							errors.designation =
								'Designation contain only letters ';
						}
						// role
						// if (!values.role) {
						//   errors.role = 'Required';
						// }
						return errors;
					}}
					onSubmit={(values, { setSubmitting }) => {
						setSubmitting(false);
						console.log(values, role);
						// console.log(role);

						setIsLoading(true);
						ApiServices.updateUser(user_id, {
							first_name: values.firstName,
							middle_name: values.middleName,
							last_name: values.lastName,
							phone: values.mobileNumber,
							email: values.email,
							designation: values.designation,
							role_id: parseInt(role),
						})
							.then((res) => {
								console.log(res.data);
								setIsLoading(false);
								if (res.code === 'P2002') {
									toast.warning(res.message);
								} else {
									props.history.push('/user');
									toast.success(`User Updated Successful.`);
								}
							})
							.catch((err) => {
								console.log(err);
								setIsLoading(false);
								toast.warning(`${err}`);
							});
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
						/* and other goodies */
					}) => (
						<form onSubmit={handleSubmit} className="mt-3">
							<div className="row">
								<div className="col-6">
									<div className="form-group mb-3">
										<label className="form-label">
											<ShortTextIcon /> First Name
										</label>
										<input
											type="text"
											name="firstName"
											min="2"
											className="form-control"
											placeholder="e.g. : Akshay"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.firstName}
											disabled={defaultDisabled}
											required
										/>
										<p style={{ color: 'red' }}>
											{errors.firstName}
										</p>
										<p style={{ color: 'red' }}>
											{touched.firstName}
										</p>
									</div>
								</div>
								<div className="col-6">
									<div className="form-group mb-3">
										<label className="form-label">
											<ShortTextIcon /> Middle name
										</label>
										<input
											type="text"
											name="middleName"
											min="2"
											className="form-control"
											placeholder="e.g. : Mukesh"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.middleName}
											disabled={defaultDisabled}
											required
										/>
										<p style={{ color: 'red' }}>
											{errors.middleName}
										</p>
										<p style={{ color: 'red' }}>
											{touched.middleName}
										</p>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-6">
									<div className="form-group mb-3">
										<label className="form-label">
											<ShortTextIcon /> Last name
										</label>
										<input
											type="text"
											name="lastName"
											min="2"
											className="form-control"
											placeholder="e.g. : Sharma"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.lastName}
											disabled={defaultDisabled}
											required
										/>
										<p style={{ color: 'red' }}>
											{errors.lastName}
										</p>
										<p style={{ color: 'red' }}>
											{touched.lastName}
										</p>
									</div>
								</div>
								<div className="col-6">
									<div className="form-group mb-3">
										<label className="form-label">
											<PhoneIphoneIcon /> Mobile Number
										</label>
										<input
											type="tel"
											name="mobileNumber"
											maxLength="10"
											className="form-control"
											placeholder="e.g. : 9878654532"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.mobileNumber}
											disabled={defaultDisabled}
											required
										/>
										<p style={{ color: 'red' }}>
											{errors.mobileNumber}
										</p>
										<p style={{ color: 'red' }}>
											{touched.mobileNumber}
										</p>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-6">
									<div className="form-group mb-3">
										<label className="form-label">
											<ShortTextIcon /> Designation
										</label>
										<input
											type="text"
											name="designation"
											min="2"
											className="form-control"
											placeholder="Enter Designation For Users"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.designation}
											disabled={defaultDisabled}
											required
										/>
										<p style={{ color: 'red' }}>
											{errors.designation}
										</p>
										<p style={{ color: 'red' }}>
											{touched.designation}
										</p>
									</div>
								</div>
								<div className="col-6">
									<div>
										<FormGroup
											onChange={(e) =>
												setRole(e.target.value)
											}
										>
											<Label>
												<i class="fas fa-user-tie"></i>{' '}
												Role
											</Label>
											<CustomInput
												type="select"
												value={role}
												disabled={defaultDisabled}
											>
												<option value="2" name={role}>
													Admin
												</option>
												<option value="1" name={role}>
													Super Admin
												</option>
											</CustomInput>
										</FormGroup>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-6">
									<div className="form-group mb-3">
										<label className="form-label">
											<EmailOutlinedIcon /> Email address
										</label>
										<input
											type="email"
											name="email"
											className="form-control"
											placeholder="e.g. : akshay23@gmail.com"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.email}
											disabled={defaultDisabled}
										/>
										<p style={{ color: 'red' }}>
											{errors.email}
										</p>
										<p style={{ color: 'red' }}>
											{touched.email}
										</p>
									</div>
								</div>
								<div className="col-6">
									<div className="form-group mb-3">
										<label className="form-label">
											<EmailOutlinedIcon /> WhatsApp
											Number
										</label>
										<input
											type="tel"
											maxLength="10"
											className="form-control"
											placeholder="e.g. : 9878654532"
											pattern="[0-9]{10,10}"
											title="WhatsApp Number should only have Digites. e.g. 9878654532 And should have length of Ten Digits"
											value={whatsAppNum}
											name={whatsAppNum}
											onChange={(e) =>
												setWhatsAppNum(e.target.value)
											}
											disabled={defaultDisabled}
										/>
									</div>
								</div>
							</div>
							{/* <div className='row'>
							<Col md={12}>
										<Col
											md={6}
											style={{float: 'right'}}
										>
											<FormGroup>
												<Input
													type="checkbox"
													className="mt-1 ml-1"
													checked={WhatsappOptInStatus}
													onChange={
														onChangeCheckboxWhatsappOptIn
													}
													disabled={whatsAppNum === "" || defaultDisabled === true ? true : false}
												/>
												<p
													style={{
														paddingLeft: '25px',
														color: 'red',
													}}
												>
													Opt In / Opt Out Mobile No.
												</p>
											</FormGroup>
										</Col>
									</Col>
							</div> */}
							<button
								className="btn btn-outline-success mt-3 mb-3"
								type="submit"
								style={{ width: '35%', marginLeft: '142px' }}
								disabled={defaultDisabled}
							>
								{isLoading && (
									<LoadingSpinner isLoading={isLoading} />
								)}
								Submit
							</button>
						</form>
					)}
				</Formik>
			</div>
		</>
	);

	return (
		<>
			<div className="container" id="adjust-top">
				<Breadcrumb>
					<BreadcrumbItem>
						<Link to={{pathname: `/user`}}>Users</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>Update User</BreadcrumbItem>
				</Breadcrumb>
				<div className="container-fluid bg-light text-center">
					<h3 className="pl-3 pr-3 pt-3 pb-3">Update User</h3>
				</div>

				<div className="container">
					<div className="row">
						<div className="col-md-6 offset-md-3">
							{updateUserForm()}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default UpdateUser;
