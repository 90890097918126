import { useState, useEffect } from "react";
import ApiServices from "../../ApiService/ApiConfig";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { Formik, Form } from "formik";
import LoadingSpinner from "../UI/Loader";

const AddFundsSuperAdminDataPage = (props) => {
  const [reportInfo, setReportInfo] = useState([]);
  const [reportListData, setListReportData] = useState([]);
  const [reportStatus, setReportStatus] = useState();
  const [tableData, setTableData] = useState([]);
  const [isLoading,setIsLoading] = useState(false)
  // const [reason, setReason] = useState();
  const [total, setTotal] = useState(0);

  let {
    name: portfolio_name,
    resettoken: reportResetToken,
    resetvalidity: reportResetValidity,
    id: user_broker_cust_id,
    trade_advice_code: trade_advice_code,
  } = useParams();

  let resetvalidity = reportResetValidity.replaceAll("_", " ");
  trade_advice_code = trade_advice_code.replaceAll("_", " ");

  useEffect(() => {
    LandingPageData();
  }, []);

  const LandingPageData = () => {
    setIsLoading(true)
    ApiServices.listDummyReportData({portfolio_name})
      .then((res) => {
        setListReportData(res);
        let uniqueTradeCode = [];
        let temp = [...res];
        let tabDataNew = [];
        temp.map((element) => {
          if (!uniqueTradeCode.includes(trade_advice_code)) {
            setReportInfo(element);
            let tabData = element.dummy_model_data.filter(
              (item) => item["User_Action"] === "Yes"
            );
            tabDataNew = tabData.map((data) => ({
              ISIN_Number: data["ISIN_Number"],
              Name_of_Securities: data["Name_of_Securities"],
              CMP: toIndianCurrency(data["CMP"]),
              Suggested_Amount_Allocation: toIndianCurrency(
                data["Suggested_Amount_Allocation"]
              ),
              User_Action: data["User_Action"],
              Suggested_Trade: toIndianCurrency(data["Suggested_Trade"]),
              Buy_Sell: data["Buy_Sell"],
              Price: data["Price"],
            }));
            let totalData = 0;
            tabData.forEach((element) => {
              totalData += element.Suggested_Amount_Allocation;
            });
            setTotal(totalData);
            uniqueTradeCode.push(element.trade_advice_code);
          }
        });
        setTableData(tabDataNew);
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err);
      });
  };

  const toIndianCurrency = (num) => {
    if (num !== undefined) {
      const curr = Number(num).toLocaleString("en-IN", {
        currency: "INR",
      });
      return curr;
    }
  };

  const columns = [
    {
      headerName: "Name of Security",
      field: "Name_of_Securities",
    },
    {
      headerName: "CMP",
      field: "CMP",
    },
    {
      headerName: "Suggested Trade Qty",
      field: "Suggested_Trade",
    },
    {
      headerName: "User Action",
      field: "User_Action",
    },
    {
      headerName: "Buy/Sell",
      field: "Buy_Sell",
      cellStyle: (params) => {
        if (params.value === "Buy") return { color: "#FF9F1C" };
        if (params.value === "Sell") return { color: "green" };
      },
    },
    {
      headerName: "Price",
      field: "Price",
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  const SubmitHandler = async () => {
    console.log("called submithandler");
    let reportModelData = [];
    reportInfo.dummy_model_data.map((element) => {
      let obj = {};
      obj["ISIN Number"] = element["ISIN_Number"];
      obj["Name of Securities"] = element["Name_of_Securities"];
      obj["Asset Class"] = element["Asset_Class"];
      obj["Sub Asset Class"] = element["Sub_Asset_Class"];
      obj["CMP"] = element["CMP"];
      obj["Ratio Start Pt"] = element["Ratio_Start_Pt"];
      obj["Ratio End Pt"] = element["Ratio_End_Pt"];
      obj["Prescribed Weight"] = element["Prescribed_Weight"];
      obj["User Action"] = element["User_Action"];
      obj["Suggested Amount Allocation"] =
        element["Suggested_Amount_Allocation"];
      obj["Suggested Trade"] = element["Suggested_Trade"];
      obj["Buy Sell"] = element["Buy_Sell"];
      obj["Price"] = element["Price"];
      reportModelData.push(obj);
    });

    let dataNew;
    if (reportInfo.funds_added === null) {
      dataNew = {
        status: reportStatus,
        inception_val_added_status: 1,
        updated_inception_val: reportInfo.customer_portfolio_inception_value,
        model: reportModelData,
        resetToken: reportResetToken,
        user_broker_cust_id: user_broker_cust_id,
        reason: "no reason",
        updateCashInPortfolio: {
          id: reportInfo.cash_id,
          "No of Shares": reportInfo.cash_update_in_portfolio,
        },
        source: reportInfo.source,
        trade_advice_code,
      };
    } else {
      dataNew = {
        status: reportStatus,
        funds_added: reportInfo.funds_added,
        model: reportModelData,
        resetToken: reportResetToken,
        user_broker_cust_id: user_broker_cust_id,
        reason: "no reason",
        updateCashInPortfolio: {
          id: reportInfo.cash_id,
          "No of Shares": reportInfo.cash_update_in_portfolio,
        },
        source: reportInfo.source,
        trade_advice_code,
      };
    }

    const destUrl = `${process.env.REACT_APP_URL_API}/customerportfolio/updatefundsgloballycustomer/${portfolio_name}`;
    await axios({
      method: "post",
      url: destUrl,
      data: dataNew,
    })
      .then((res) => {
        toast.success(`Response Recorded Successfully`);
        props.history.push("/thank");
      })
      .catch((err) => {
        if (err.response.status === 406) toast.warning("Response already sent");
      });
  };

  return (
    <div className="container">
      <div className="container-fluid text-center">
      {isLoading && <LoadingSpinner isLoading={isLoading}/>}
        <h3
          className="pb-3 pt-3"
          style={{ backgroundColor: "#eff6f9", marginTop: 100 }}
        >
          Transaction Report
        </h3>
      </div>

      <div
        className="ag-theme-alpine"
        style={{
          height: 400,
          width: 880,
          marginTop: 30,
          marginLeft: "10%",
        }}
      >
        <div style={{ border: "1px solid #babfc7", padding: "15px" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <p>
                  <b>Client Name</b>{" "}
                  <span style={{ paddingLeft: "17px" }}>
                    : {reportInfo.client_name}
                  </span>
                </p>
                <p>
                  <b>Portfolio Name</b> :{" "}
                  <span>{reportInfo.portfolio_name}</span>
                </p>
                {reportInfo.source === "Add Funds" && (
                  <p>
                    <b>Funds Addition</b> :{" "}
                    <span>{toIndianCurrency(reportInfo.funds_added)}</span>
                  </p>
                )}
                <p>
                  <b>Suggested Amount Allocation Total</b> :{" "}
                  <span>{toIndianCurrency(total)}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <AgGridReact
          rowData={tableData}
          columnDefs={columns}
          defaultColDef={defaultColDef}
        />
        <br />
        <Formik
          initialValues={{
            acceptTerms: false,
          }}
          onSubmit={SubmitHandler}
        >
          {() => (
            <div className="container" style={{ paddingBottom: "25px" }}>
              <Form>
                <div className="form-group">
                  <center>
                    <button
                      id="firstInput"
                      type="submit"
                      className="btn btn-success mb-3 mr-2 mt-3"
                      onClick={(e) => setReportStatus("Accepted")}
                    >
                      Accept
                    </button>
                    <button
                      type="submit"
                      id="secondInput"
                      className="btn btn-danger mb-3 mr-2 mt-3"
                      style={{ marginLeft: "10px" }}
                      onClick={(e) => setReportStatus("Rejected")}
                    >
                      Reject
                    </button>
                  </center>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddFundsSuperAdminDataPage;
