import React, { useEffect, useState } from 'react';
import './UserCustRedressalStyle.css';

import ApiServices from '../ApiService/ApiConfig';
import MainTable from './Layout/Table-layout/MainTable';
import { COLUMNS } from './Layout/Table-layout/Columns/columns-broker';

const BoardBroker = () => {
	const [tableData, settableData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		fetchBrokerdata();
	}, []);

	const fetchBrokerdata = () => {
		ApiServices.getAllBrokers()
			.then(res=>{
				setIsLoading(false);
				settableData(res.brokers);
			})
			.catch(err=>{
				setIsLoading(false);
				console.log(err);
		});
	};
	if (!tableData) return null;

	return (
		<div id="style_main">
			<div
				className="shadow-none mb-3 rounded"
				style={{ textAlign: 'center' }}
			>
				<h3
					style={{
						marginTop: '110px',
						width: '100%',
						backgroundColor: '#f5f3f3',
						fontFamily: 'sans-serif',
					}}
					className="pt-2 pb-2"
				>
					Broker List
				</h3>
			</div>

			<MainTable
				tableData={tableData}
				COLUMNS={COLUMNS}
				type="Broker"
				urlAdd="/addbroker"
				urlActionEdit="/updatecustomer/"
				urlActionView="/viewcustomer/"
				urlRiskProfile="/riskprofile/"
				isLoading={isLoading}
			/>
		</div>
	);
};

export default BoardBroker;
