import React, { Component } from "react";

import { Button, ButtonGroup, Container, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {getAllAssetMaster,deleteAsset} from "../../actions/assetActions";

class AssetList extends Component {
  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
    // this.handleEdit = this.handleEdit.bind(this);
    this.state = { groups: [], isLoading: true,content:"" };
  }

  componentDidMount() {
    this.props.getAssetMaster();
  }

  
  handleDelete(assetId) {
    const { deleteAsset } = this.props;
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Are you sure you want to delete this supplier?')) {
        deleteAsset(assetId);
      }
  }

  render() {
    const { assetMasterList } = this.props;
    // const suppliersFiltered = assetMasterList.filter(supp => supp.name.toLowerCase().indexOf(searchString) > -1);
  //  const assetList = [];
  console.log("assetMasterList==============",assetMasterList)
    const assetList = assetMasterList && assetMasterList.map((asset) => {
    return <tr>
          <td>{asset.asset_id}</td>
    <td>{asset.asset_name}</td>
    <td>
          <ButtonGroup>
            <Button size="sm" color="primary" tag={Link} to={"/assets/" + asset.asset_id}>Edit</Button>
            <Button size="sm" color="danger" onClick={() => this.handleDelete(asset.asset_id)}>Delete</Button>
          </ButtonGroup>
        </td>
    </tr>

    });
    return (
      <Container fluid>
        <div className="float-right">
        <Link to={{pathname: '/assets/addAsset'}}>
          <Button color="success">
            Add Asset
          </Button>
          </Link>
        </div>
        <h3>My Asset Master</h3>
        <Table className="mt-4">
          <thead>
            <tr>
            <th width="20%">Id</th>
              <th width="20%">Name</th> 
              <th width="10%">Actions</th>
            </tr>
          </thead>
          <tbody>{assetList}</tbody>
        </Table>
      </Container>
    );
  }


}

const mapStateToProps = state => (
  {
  // pathname: state.routing.locationBeforeTransitions.pathname,
  assetMasterList: state.assetMaster.assetMasterList
});

const mapDispatchToProps = dispatch => ({
  getAssetMaster: () => dispatch(getAllAssetMaster()),
  deleteAsset: assetId => dispatch(deleteAsset(assetId)),
  // editAsset: assetId => dispatch(editAsset(assetId))
});
export default connect(mapStateToProps, mapDispatchToProps)(AssetList);
