import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useUserInfo } from '../../ContextProviders/UserInfo';
import logo from '../../images/DVIA_Logo.jpeg'

function Header(props) {
    const userInfo = useUserInfo();
    const curTime = useState(new Date().toLocaleString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }));
    const LogOutHandler = () => {
      props.logoutHandler();
    }
    return (
      // <div className="container">
        <div className="nav-view" id="header-style">
        <nav className="navbar navbar-expand navbar-light" >
        <img src={logo} className="border border-white" id="logo" />
          <Link to={"/"} className="navbar-brand" id="navbar-adjust">
            DV Investment Advisors LLP
          </Link>
          {localStorage.getItem("userInfo") !== null ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/profile"} className="nav-link">
                  <div className="profile-details">
                    <h6 id="h6-color"><i class="fa fa-user" aria-hidden="true"></i><span> </span>{userInfo.first_name} {userInfo.middle_name} {userInfo.last_name} <span> </span><span id="user_role">({userInfo.role.role_name})</span></h6>
                    <h6 id="h6-color"><i class="fa fa-calendar" aria-hidden="true"></i><span> </span>{curTime}<span> </span>
                    </h6>
                  </div>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={{pathname: "/login"}} className="nav-link" onClick={LogOutHandler}>
                  <span id="logout-icon"><i class="fa fa-sign-out" aria-hidden="true" data-tip data-for="Tooltip11"></i></span>
                  <ReactTooltip id="Tooltip11" place="bottom" effect="solid" >Logout</ReactTooltip>
                </Link>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              {/* <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  <span id="home-icon"><i class="fa fa-sign-in" aria-hidden="true" data-tip data-for="Tooltip8"></i></span>
                  <ReactTooltip id="Tooltip8" place="bottom" effect="solid" >Login</ReactTooltip>
                </Link>
              </li> */}
            </div>
          )}
        </nav>
        <hr id="hr-shadow"></hr>
      </div>
      // </div>
    )
}

export default Header;