import React, { Component } from "react";

import { Button, ButtonGroup, Container, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {getAllSubAssetMaster} from "../../actions/subAssetActions";

class SubAssetList extends Component {
  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
    // this.handleEdit = this.handleEdit.bind(this);
  }

  componentDidMount() {
    this.props.getSubAssetMaster();
  }

  
  handleDelete(assetId) {
    const { deleteAsset } = this.props;
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Are you sure you want to delete this supplier?')) {
        deleteAsset(assetId);
      }
  }

  render() {
    const { subAssetMasterList } = this.props;
    console.log("subAssetMasterList==============",subAssetMasterList)
    // const subAssetList = []
    const subAssetList = subAssetMasterList && subAssetMasterList.map((subAsset) => {
    return <tr>
          <td>{subAsset.sub_asset_id}</td>
          <td>{subAsset.assetMaster.asset_name}</td>
    <td>{subAsset.sub_asset_name}</td>
    <td>
          <ButtonGroup>
            <Button size="sm" color="primary" tag={Link} to={"/assets/" + subAsset.sub_asset_id}>Edit</Button>
            <Button size="sm" color="danger" onClick={() => this.handleDelete(subAsset.sub_asset_id)}>Delete</Button>
          </ButtonGroup>
        </td>
    </tr>

    });
    return (
      <Container fluid>
        <div className="float-right">
        <Link to={{pathname: '/subAssets/addSubAsset'}}>
          <Button color="success">
            Add SubAsset
          </Button>
          </Link>
        </div>
        <h3>My Sub Asset Master</h3>
        <Table className="mt-4">
          <thead>
            <tr>
            <th width="20%">Id</th>
              <th width="20%">Asset Name</th> 
              <th width="20%">Sub Asset Name</th> 
              <th width="10%">Actions</th>
            </tr>
          </thead>
          <tbody>{subAssetList}</tbody>
        </Table>
      </Container>
    );
  }


}

const mapStateToProps = state => (
  {
    subAssetMasterList: state.subAssetMaster.subAssetMasterList
});

const mapDispatchToProps = dispatch => ({
  getSubAssetMaster: () => dispatch(getAllSubAssetMaster()),
  // deleteAsset: assetId => dispatch(deleteAsset(assetId)),
  // editAsset: assetId => dispatch(editAsset(assetId))
});
export default connect(mapStateToProps, mapDispatchToProps)(SubAssetList);
