import { useState, useEffect } from 'react';
import ApiServices from '../../ApiService/ApiConfig';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { FormGroup, Label, Input } from 'reactstrap';

import { Formik, Field, Form, ErrorMessage } from 'formik';

import * as Yup from 'yup';

const BrokerReportLandingPage = (props) => {
	const [reportInfo, setReportInfo] = useState('');
	// const [modalData, setModalData] = useState('')
	// const [reportStatus, setReportStatus] = useState('Verified')
	// const [deltaData, setDeltaData] = useState('')

	const { name: portfolio_name } = useParams();

	const { user_email: cust_received_data } = useParams();

	const { broker_email: cust_broker_email } = useParams();

	var newRes;

	useEffect(() => {
		console.log(portfolio_name, cust_received_data, cust_broker_email);
		LandingPageData();
	}, []);

	const LandingPageData = () => {
		ApiServices.getPortfolioCustomerReportData(portfolio_name)
			.then((res) => {
				console.log(res);
				setReportInfo(res);
				// getDelta()
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// for testing
	//   const getDelta = () => {
	//     const delta = localStorage.getItem('responseData');
	//     const deltarespo = delta
	//     console.log(deltarespo);
	//   }

	//    useEffect(() => {
	//         console.log(portfolio_name);
	//         modalPrevData();
	//     }, [])

	//     const modalPrevData = () => {
	//     ApiServices.getCustomerPortfolio(portfolio_name)
	//     .then((res) => {
	//         newRes = res
	//         setModalData(newRes)
	//         // console.log(modalData);
	//     })
	//     .catch((err) => {
	//         console.log(err);
	//     })
	//   }

	const columns = [
		{
			headerName: 'ISIN Number',
			field: 'ISIN_Number',
		},
		{
			headerName: 'Name of Security',
			field: 'Name_of_Securities',
		},
		{
			headerName: 'Buy/Sell',
			field: 'Suggested_Trade',
		},
	];

	const defaultColDef = {
		sortable: true,
		filter: true,
	};

	const SubmitHandler = async () => {
		console.log('button clicked');
		// console.log(reportInfo);
		console.log(portfolio_name);
		// console.log(modalData);
		console.log(cust_received_data);

		const destUrl = `${process.env.REACT_APP_URL_API}/customerportfolio/generatereport/${portfolio_name}`;
		const data = await axios({
			method: 'post',
			url: destUrl,
			data: {
				cust_email: cust_received_data,
				broker_email: cust_broker_email,
			},
		});
		toast.success(`Report Sent Successfully`);
		//  props.history.push("/custthank");
	};

	return (
		<div className="container">
			<div className="container-fluid text-center">
				<h3
					className="pt-3 pb-3"
					style={{ backgroundColor: '#eff6f9', marginTop: 100 }}
				>
					Customer Modal Report
				</h3>
			</div>

			<div
				className="ag-theme-alpine"
				style={{
					height: 400,
					width: 550,
					marginTop: 30,
					marginLeft: '27%',
				}}
			>
				<AgGridReact
					rowData={reportInfo}
					columnDefs={columns}
					defaultColDef={defaultColDef}
				/>
				<br />
				{/* <input readOnly type="radio" value="" name=""className="ml-1 mr-2" />
                <label>I Accept This Change</label> */}
			</div>
		</div>
	);
};

export default BrokerReportLandingPage;
