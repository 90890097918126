import {getSubAssetMaster} from '../services/subAsset.service'
import { put, call } from 'redux-saga/effects';
import {getAllSubAssetMaster} from '../actions/subAssetActions';
import {setMessage} from '../actions/message'
import * as types from '../actions/index'


export function* getAllSubAssetSaga() {
    console.log("getAllSubAssetSaga")
    try {
      const response = yield call(getSubAssetMaster);
      console.log("getAllSubAssetSaga--response",response);
    //   yield put(receiveAssetMasterList(response.data))
    if(response){
        const {data} = response
        yield put ({type: types.SUB_ASSET_RECEIVED, data})
    }
 else{
    console.log('Error');
 }
    } catch(error) {
    //   yield put({ type: types.LOGIN_USER_ERROR, error })
    }
  }

  // export function* getSingleAssetSaga(params) {
  //   console.log("payload")
  //   try {
  //     const response = yield call(getSingleAssetMaster(params));
  //     console.log("response",response);
  //   if(response){
  //       const {data} = response
  //       yield put ({type: types.ASSET_RECEIVED, data})
  //   }
 
  //   } catch(error) {
  //   }
  // }  

  // export function* saveAssetSaga(params){
  //   console.log("savepayload",params)
  //     try {
  //       const response = yield call(saveAssetMaster(params)); 
  //       if(response){
  //           yield put(setMessage(response))
  //       }
  //       console.log("save response",response);  
  //     } catch (error) {
          
  //     }
  // }

  // export function* deleteAssetSaga(params){
  //     console.log('assetIdSaga',params)
  //   try {
  //       const response = yield call(deleteAsset(params)); 
  //       console.log("respppp",response)
  //       // if(response){
  //           yield put(getAllAssetMaster())
  //       // }
  //       console.log("save response",response);  
  //     } catch (error) {
          
  //     }
  // }

  // export function* updateAssetSaga(action) {
  //   const { updatedAssetMaster, assetId } = action;
  //   // call to the api
  //   const response = yield call(updateAsset(updatedAssetMaster, assetId));
  //   // check response
  //   if (response.ok) {
  //   } else {
  //     // dispatch failure
  //     console.log('Error');
  //   }
  // }