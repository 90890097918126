import React, { useEffect, useState } from 'react';

//import UserService from "../services/user.service";
import ApiServices from '../ApiService/ApiConfig';
import MainTable from './Layout/Table-layout/MainTable';
//import tableData from './json-data/customerTableData.json';
import { COLUMNS } from './Layout/Table-layout/Columns/columns-customer';
import './UserCustRedressalStyle.css';

const BoardCustomer = (props) => {
	const [tableData, settableData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		customerdata();
	}, []);

	const customerdata = () => {
		ApiServices.listCustomer()
			.then((res) => {
				setIsLoading(false);
				settableData(res);
			})
			.catch((err) => {
				setIsLoading(false);
				console.log(err);
			});
	};
	console.log("tableData>>",tableData);
	if (!tableData) return null;

	return (
		<div id="style_main">
			<center>
				{/* <span>
            <button className="btn btn-success" >Add Customer +</button>className=''
            <span> </span>
            <button className="btn btn-success" >Update Customer </button>
          </span> */}
			</center>
			<div
				className="shadow-none mb-3 rounded"
				style={{ textAlign: 'center' }}
			>
				<h3
					style={{
						marginTop: '110px',
						width: '100%',
						backgroundColor: '#f5f3f3',
						fontFamily: 'sans-serif',
					}}
					className="pt-2 pb-2"
				>
					Customer List
				</h3>
			</div>

			<MainTable
				tableData={tableData}
				COLUMNS={COLUMNS}
				type="Customer"
				urlAdd="/addcustomer"
				urlActionEdit="/updatecustomer/"
				urlActionView="/viewcustomer/"
				urlRiskProfile="/riskprofile/"
				isLoading={isLoading}
			/>
		</div>
	);
};

export default BoardCustomer;
