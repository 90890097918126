import React, { useState, useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import AccordianComponent from "../Layout/Table-layout/Accordian";
import TradeMIStable from '../Trade-MIS-And-Reports/Trade-MIS-table/table';
import ApiServices from '../../ApiService/ApiConfig';
import COLUMNSSA from '../Trade-MIS-And-Reports/columns/columns-tradeMIS-SA';
import COLUMNSCustomer from '../Trade-MIS-And-Reports/columns/columns-tradeMIS-Customer';
import COLUMNSBroker from '../Trade-MIS-And-Reports/columns/columns-tradeMIS-Broker';
import COLUMNSBROKERDATAREPORTS from '../Trade-MIS-And-Reports/columns/columns-tradMIS-BrokerReportInfo';
import COLUMNSCRM from '../Trade-MIS-And-Reports/columns/columns-tradeMIS-CRM';
import COLUMNSReports from '../Trade-MIS-And-Reports/columns/columns-tradeMIS-Reports';
import COLUMNSMF from '../Trade-MIS-And-Reports/columns/columns-tradeMIS-MF';
import './tradeMisAndReports.component.css';
import ReportFilter from '../Trade-MIS-And-Reports/Reports/IndividualFilter';
import { toast } from "react-toastify";
import XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../UI/Loader";

const TradeMisAndReports = () => {
  const [allReportData, setAllReportData] = useState([]);
  const [tableDataSA, settableDataSA] = useState([]);
  const [tableDataCustomer, settableDataCustomer] = useState([]);
  const [tableDataBroker, settableDataBroker] = useState([]);
  const [tableMFData,settableMFData]=useState([]);
  const [tableDataReports, settableDataReports] = useState([]);
  const [tableDataCRM, settableDataCRM] = useState([]);
  const [dateForAccordion, setDateForAccordion] = useState([]);
  const [generateDateSA, setGenerateDateSA] = useState([]);
  const [generateDateCustomer, setGenerateDateCustomer] = useState([]);
  const [generateDateBroker, setGenerateDateBroker] = useState([]);
  const [generateDateMF, setGenerateDateMF] = useState([]);
  const [generateDateReports, setGenerateDateReports] = useState([]);
  const [acceptedStatusSA, setAcceptedStatusSA] = ([]);
  const [pendingStatusSA, setPendingStatusSA] = ([]);
  const [rejectedStatusSA, setRejectedStatusSA] = ([]);
  const [tradeAdviceCodeData, setTradeAdviceCodeData] = useState([]);
  const [modelNameData, setModelNameData] = useState([]);
  const [portfolioNameData, setPortfolioNameData] = useState([]);
  const [typeData, setTypeData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [consentDateData, setConsentDateData] = useState([]);
  const [sourceData, setSourceData] = useState([]);
  const [refresh, setRefresh] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [sendLoading, setSendLoading] = useState(false);

  useEffect(() => {
      // userdata();
      SuperAdminDataHandler();
  }, [refresh])

  // const userdata = () => {
  //     ApiServices.listAllTradeMISReportData()
  //       .then((res) => {
  //         console.log("result",res);
  //         setAllReportData(res);
  //           setIsLoading(false);
  //       }).catch((err) => {
  //         setIsLoading(false);
  //         console.log(err);
  //       })
  // }

  const handleSelectTab = (key) => {
    if(key === "1"){
      SuperAdminDataHandler();
    }else if(key === "2"){
      CustomerDataHandler();
    }else if(key === "3"){
      BrokerDataHandler();
    }else if(key === "4"){
      MutualFundsData();
    }else if(key === "5"){
      CRMInfo();
    }else{
      ReportsDataHandler();
    }
  }
  
  const SuperAdminDataHandler = () => {
    // let uniqueTAC = [];
    //       let SAData = [];
    //         allReportData.forEach((ele) => {
    //         if(ele.type === "SA" && ele.reportVerified !== 'Accepted' && ele.Multiple_SA_Accepted_Status === 0){
    //         let obj = {};
    //         obj['trade_advice_code'] = ele['trade_advice_code'];
    //         obj['source'] = ele.source;
    //         if(ele.source === "ModPort" || ele.source === "DVWM"){
    //           obj['model_name'] = ele.model_name;
    //           obj['portfolio_name'] = 'N.A.';
    //           obj['client_name'] = 'N.A.';
    //         }else if(ele.source === "Add Funds"){
    //           obj['portfolio_name'] = ele.customer_portfolio.portfolio_name;
    //           obj['client_name'] = ele.customer_portfolio.user.first_name + " " + ele.customer_portfolio.user.middle_name + " " + ele.customer_portfolio.user.last_name;
    //           obj['model_name'] = ele.model_name;
    //         }
    //         if(ele.reportVerified === null){
    //           obj['report_status'] = 'Pending';
    //         }else{
    //           obj['report_status'] = ele.reportVerified;
    //         }
    //         if(ele.reason === null){
    //           obj['reason'] = "-";
    //         }else{
    //           obj['reason'] = ele.reason;
    //         }
    //         obj['verifiedBy'] = ele.user_broker_cust_name;
    //         const date = new Date(ele.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'numeric',year: 'numeric' });
    //         obj['date'] = date;
    //         obj['unique_id_send_report_broker'] = ele.unique_id_send_report_broker;
    //         obj['type'] = ele.type;
    //         obj['inception_val_added_status'] = ele.inception_val_added_status;
    //         if(!uniqueTAC.includes(ele.trade_advice_code)){
    //           uniqueTAC.push(ele.trade_advice_code);
    //         }
    //         SAData.push(obj);
    //       }
    //       })

    //       let SADataNew = [];

    //       uniqueTAC.forEach((ele) => {
    //         let filtered = SAData.filter((element) => element.trade_advice_code === ele)
    //         let found = filtered.find((item) => item.report_status === "Rejected")
    //         if(found === undefined){
    //           SADataNew.push(filtered[0])
    //         }else{
    //           SADataNew.push(found)
    //         }
    //       })

    //       settableDataSA(SADataNew);
    //       let generateDataByTradeCode = generateDataByTradeAdviceCode(SAData);
    //       let newSAData = SAData.filter((ele) => !ele.trade_advice_code.includes(generateDataByTradeCode))
    //       let generateDateArraySA = generateDate(SAData);
    //       let AcceptedStatus = [];
    //       generateDateArraySA.forEach((date) => {
    //         let Count = getAcceptedStatus(date, "SA", "Accepted", 1);
    //         let obj = {"date" : date, "Count": Count}
    //         AcceptedStatus.push(obj);
    //       })
          
          //ROUGH
        setIsLoading(true);
          ApiServices.listAllTradeMISSAData()
        .then((res) => {
          console.log("result",res);
          settableDataSA(res);
            setIsLoading(false);
        }).catch((err) => {
          setIsLoading(false);
          console.log(err);
        })
          //ROUGH

          // setAcceptedStatusSA(AcceptedStatus);
          // let PendingStatus = [];
          // generateDateArraySA.forEach((date) => {
          //   let Count = getAcceptedStatus(date, "SA", "Pending", 0);
          //   let obj = {"date" : date, "Count": Count}
          //   PendingStatus.push(obj);
          // })
          // setPendingStatusSA(PendingStatus);
          // let RejectedStatus = [];
          // generateDateArraySA.forEach((date) => {
          //   let Count = getAcceptedStatus(date, "SA", "Rejected", 0);
          //   let obj = {"date" : date, "Count": Count}
          //   RejectedStatus.push(obj);
          // })
          // setRejectedStatusSA(RejectedStatus);
          // console.log("COUNTSARECORDS", AcceptedStatus, PendingStatus, RejectedStatus);
          // setGenerateDateSA(generateDateArraySA);

  }

  const CustomerDataHandler = () => {
    setIsLoading(true);
        ApiServices.listAllTradeMISCustomerData()
        .then((res) => {
          console.log("result",res);
          settableDataCustomer(res);
          let generateDateCustomer = generateDate(res);
          setGenerateDateCustomer(generateDateCustomer);
          setIsLoading(false);
        }).catch((err) => {
          setIsLoading(false);
          console.log(err);
        })
  }

  const BrokerDataHandler = () => {
    setIsLoading(true);
    ApiServices.listAllTradeMISBrokerData()
        .then((res) => {
          settableDataBroker(res);
          let generateDateBroker = generateDate(res);
          setGenerateDateBroker(generateDateBroker);
          setIsLoading(false);
        }).catch((err) => {
          setIsLoading(false);
          console.log(err);
        })
  }

  const ReportsDataHandler = () => {
    setIsLoading(true);
        ApiServices.listAllTradeMISReportsData()
        .then((res) => {
          console.log("result",res);
          settableDataReports(res);
          setIsLoading(false);
        }).catch((err) => {
          setIsLoading(false);
          console.log(err);
        })
  }

  const uniqueRecord = (data, item) => {
    return new Set(data.map((ele) => ele[item]));
  }

  // const ReportData = () => {
  //   ApiServices.listAllTradeMISReportData()
  //       .then((res) => {
  //         console.log("result10",res);
  //         setAllReportData(res);
  //       }).catch((err) => {
  //         setIsLoading(false);
  //         console.log(err);
  //       })
  // }

  const MutualFundsData = () => {
    setIsLoading(true);
    ApiServices.getMutualFundReport()
        .then((res) => {
          console.log("result11",res);
          //prathmeshk 22-02-2022
          // let MFData= res.map((data) => ({"id": data["id"], "trade_advice_code": data["trade_advice_code"], "model_name": data["model_name"], "portfolio_name": data["portfolio_name"], "customer_name": data["customer_name"], "mutual_fund_name": data["mutual_fund_name"], "unique_code": data["unique_code"], "entered_unique_code":"", "date": new Date(data["createdAt"]).toLocaleString('en-US', { day: 'numeric', month: 'numeric',year: 'numeric' })}));
          settableMFData(res);
          // let generateDateMF = generateDate(MFData);
          // setGenerateDateMF(generateDateMF);
          setIsLoading(false)
            }).catch((err) => {
              setIsLoading(false);
              console.log(err);
            })
  }

  const CRMInfo = () => {
    setIsLoading(true);
    ApiServices.listCustomerWithStatus()
        .then((res) => {
          console.log("res21", res);
          let CRMData = [];
          res.map((element) => {
            let obj = {};
            obj['id'] = element.id;
            obj['customer_name'] = element.middle_name === "" ? element.first_name + ' ' + element.last_name : element.first_name + ' ' + element.middle_name + ' ' + element.last_name;
            if(element.cust_email_verified_status !== null){
              obj['cust_email_verified_status'] = element.cust_email_verified_status;
            }else{
              obj['cust_email_verified_status'] = "Unverified";
            }
            if(element.cust_phone_verified_status !== null){
              obj['cust_phone_verified_status'] = element.cust_phone_verified_status;
            }else{
              obj['cust_phone_verified_status'] = "Unverified";
            }
            CRMData.push(obj);
          })
          console.log("CRMData", CRMData);
          settableDataCRM(CRMData);
          setIsLoading(false);
        }).catch((err) => {
          setIsLoading(false);
          console.log(err);
        })
  }

  if (!tableDataSA) return null;

  const generateDate=(data)=>{

        let dateListLocal=[];
        setDateForAccordion([]);
        data.forEach(element=>{
          const dateToday = new Date();
          const dateInRecord = new Date(element.date);
          const diffTime = Math.abs(dateInRecord - dateToday);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          if(diffDays < 36){
          dateListLocal.push(new Date(element.date).toLocaleString('en-IN', { day: 'numeric', month: 'numeric',year: 'numeric' }))
        }
        })
        const uniqueDates = [];
        dateListLocal.forEach(item => {
          if (uniqueDates.indexOf(item) === -1) {
            uniqueDates.push(item)
          }
        });
        return uniqueDates;
    }

    const generateDataByTradeAdviceCode=(data)=>{

      let dateListLocal=[];
      setDateForAccordion([]);
      data.forEach(element=>{
        if(element.reportVerified === "Accepted"){
        dateListLocal.push(element.trade_advice_code)
      }
      })

      let dateClassSet = new Set(dateListLocal);
      let DateArray = [];
      Array.from(dateClassSet).map(element=>{
        DateArray.push(element);
      })
      return DateArray;
  }

    // const getAcceptedStatus = (date, type, verifiedStatus, SAStatus, data) => {
    //   console.log("Start", new Date())
    //   let count = 0;
    //   if(type === "SA"){
    //     // let SANewData = [];
    //     // let SAUpdatedData = [];
    //     // if(verifiedStatus === 'Accepted'){
    //       // allReportData.forEach((item) => {
    //       //   if(!SANewData.includes(item.trade_advice_code) && item.type === "SA" && item.reportVerified === 'Accepted'){
    //       //     SANewData.push(item.trade_advice_code);
    //       //     SAUpdatedData.push(item);
    //       //   }
    //       // })
    //       data.forEach((ele) => { 
    //       const dateNew = new Date(ele.createdAt).toLocaleString('en-IN', { day: 'numeric', month: 'numeric',year: 'numeric' })
    //       if(dateNew === date && ele.type === type && ele.reportVerified === verifiedStatus){
    //           count += 1;
    //         }
    //       })
    //       console.log("End", new Date())
    //       return count;
    //     // }else{
    //       // allReportData.forEach((item) => {
    //       //   if(!SANewData.includes(item.trade_advice_code) && item.type === "SA" && item.reportVerified === verifiedStatus){
    //       //     SANewData.push(item.trade_advice_code);
    //       //     SAUpdatedData.push(item);
    //       //   }
    //       // })
    //     //   data.forEach((ele) => { 
    //     //   const dateNew = new Date(ele.createdAt).toLocaleString('en-IN', { day: 'numeric', month: 'numeric',year: 'numeric' })
    //     //   if(dateNew === date && ele.type === type && ele.reportVerified === verifiedStatus){
    //     //       count += 1;
    //     //   }
    //     //   })
    //     //   console.log("End", date, SAUpdatedData)
    //     //   return count;
    //     // }
    // }else{
    //   allReportData.forEach((ele) => {
    //     const dateNew = new Date(ele.createdAt).toLocaleString('en-IN', { day: 'numeric', month: 'numeric',year: 'numeric' })
    //      if(dateNew === date && ele.type === type && ele.reportVerified === verifiedStatus){
    //         count += 1;
    //      }
    //   })
    //   console.log("End", new Date())
    //   return count;
    // }
    // }
    
    // const getPendingStatus = (date, type, sendStatus, verifiedStatus) => {
    //   let count = 0;
    //   if(sendStatus === 1){
    //   let CUSTOMERNewData = [];
    //     let CUSTOMERUpdatedData = [];
    //     allReportData.forEach((item) => {
    //       CUSTOMERNewData.push(item.trade_advice_code);
    //       if(!CUSTOMERNewData.includes(item.trade_advice_code) && item.type === "Customer" && item.send_report_broker_status === 1){
    //         CUSTOMERUpdatedData.push(item);
    //       }
    //     })
    //   return CUSTOMERUpdatedData.length;
    // }else{
    //   allReportData.forEach((ele) => {
    //     const dateNew = new Date(ele.createdAt).toLocaleString('en-IN', { day: 'numeric', month: 'numeric',year: 'numeric' })
    //      if(dateNew === date && ele.type === type && ele.send_report_broker_status === sendStatus && ele.reportVerified === verifiedStatus){
    //         count += 1;
    //      }
    //   })
    //   return count;
    // }
    // }

    const exportToXLSX = (apiData, fileName) => {
      const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(apiData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    };

    const onClickSendBroker = (unique_id_send_report_broker, source, inception_val_added_status, trade_advice_code) => {
    setIsLoading(true);
      if(source === "Add Funds" || (source === "ModPort" && inception_val_added_status === 1)){
      ApiServices.MailToBrokerAddFunds(unique_id_send_report_broker, {source : source, trade_advice_code: trade_advice_code, bulkIndex: 0})
            .then(res => {
                console.log("11", res.data);
                toast.success(
                    `${res.message}`
                )
                setRefresh(Math.random());
                setIsLoading(false);
            }).catch((err) => {
                console.log(err);
                setIsLoading(false);
            })
      }

      if(source === "ModPort" && inception_val_added_status === null){
        console.log('MAIL TO BROKER MODPORT','comp');
        ApiServices.MailToBrokerModPort(unique_id_send_report_broker, {trade_advice_code: trade_advice_code, bulkIndex: 0})
            .then(res => {
                console.log(res.data);
                toast.success(
                    `${res.message}`
                )
                setRefresh(Math.random());
                setIsLoading(false);
            }).catch((err) => {
                console.log(err);
                setIsLoading(false);
            })
      }

      if(source === "DVWM"){
        ApiServices.MailToBrokerDVWM(unique_id_send_report_broker, {source : source, trade_advice_code: trade_advice_code, bulkIndex: 0})
            .then(res => {
                console.log(res.data);
                toast.success(
                    `${res.message}`
                )
                setRefresh(Math.random());
                setIsLoading(false);
            }).catch((err) => {
                console.log(err);
                setIsLoading(false);
            })
      }

    }

    const onClickBulkSendBroker = async(selectedFlatRows) => {
    
      if(!selectedFlatRows.length) return;

      let modifiedData = selectedFlatRows.map((row, index) => {
        return {
          id: row.original.unique_id_send_report_broker,
          // type: row.original.type,
          source: row.original.source,
          reportVerified: row.original.reportVerified,
          trade_advice_code: row.original.trade_advice_code,
          bulkIndex: index,
          inception_val_added_status: row.original.inception_val_added_status,
          send_report_broker_status: row.original.send_report_broker_status
        }
      })

      try {
        let response = await ApiServices.bulkMailsToBrokers(modifiedData);
        setSendLoading(false);
        toast.success(response.data.message)
      } catch (error) {
        console.log(error);
      }
      // selectedFlatRows.map((row, index) => {
      //   if(row.original.source === "Add Funds" || (row.original.source === "ModPort" && row.original.inception_val_added_status === 1)){
      //     console.log("Yes done", index)
      //   ApiServices.MailToBrokerAddFunds(row.original.unique_id_send_report_broker, {source : row.original.source, trade_advice_code: row.original.trade_advice_code, bulkIndex: index})
      //         .then(res => {
      //             console.log("11", res.data);
      //             toast.success(
      //                 `${res.message}`
      //             )
      //             setRefresh(Math.random());
      //         }).catch((err) => {
      //             console.log(err);
      //         })
      //   }

      //   if(row.original.source === "ModPort" && row.original.inception_val_added_status === null){
      //     ApiServices.MailToBrokerModPort(row.original.unique_id_send_report_broker, {source : row.original.source, trade_advice_code: row.original.trade_advice_code, bulkIndex: index})
      //         .then(res => {
      //             console.log(res.data);
      //             toast.success(
      //                 `${res.message}`
      //             )
      //             setRefresh(Math.random());
      //         }).catch((err) => {
      //             console.log(err);
      //         })
      //   }
      // })
    }

    const onClickResendLink = (id, type, source, inception_val_added_status, trade_advice_code) => {
      setIsLoading(true);
      if(type === "SA" && (source === "Add Funds" || inception_val_added_status === 1)){
        ApiServices.ResendLinkForSAAddFunds(id, {trade_advice_code: trade_advice_code})
              .then(res => {
                  console.log(res.data);
                  toast.success(
                      `${res.message}`
                  )
                  setRefresh(Math.random());
                  setIsLoading(false);
              }).catch((err) => {
                  console.log(err);
              })
        }
        
        if(type === "SA" && source === "ModPort" && inception_val_added_status === null){
          ApiServices.ResendLinkForSAModPort(id, {trade_advice_code, source}) 
          .then(res => {
            console.log(res.data);
            toast.success(
              `${res.message}`
            )
            setRefresh(Math.random());
            setIsLoading(false);
          }).catch((err) => {
            console.log(err);
            setIsLoading(false);
          })
          }

          if(type === "SA" && source === "DVWM"){
            ApiServices.ResendLinkForSADVWM(id, {"trade_advice_code": trade_advice_code, "source": source}) 
            .then(res => {
              console.log(res.data);
              toast.success(
                `${res.message}`
              )
              setRefresh(Math.random());
              setIsLoading(false);
            }).catch((err) => {
              console.log(err);
              setIsLoading(false);
            })
            }

      if(type === "Customer" && (source === "Add Funds" || inception_val_added_status === 1)){
        console.log('inside customer');
        ApiServices.ResendLinkForCustomerAddFunds(id, {trade_advice_code: trade_advice_code})
              .then(res => {
                  console.log(res.data);
                  toast.success(
                      `${res.message}`
                  )
                  setRefresh(Math.random());
                  setIsLoading(false);
              }).catch((err) => {
                  console.log(err);
                  setIsLoading(false);
              })
      }

      if(type === "Customer" && source === "ModPort" && inception_val_added_status === null){
        console.log('inside customer');
          ApiServices.ResendLinkForCustomerModPort(id, {trade_advice_code: trade_advice_code})
          .then(res => {
            console.log(res.data);
          toast.success(
            `${res.message}`
          )
          setRefresh(Math.random());
          setIsLoading(false);
        }).catch((err) => {
          console.log(err);
          setIsLoading(false);
        })
      }

      if(type === "Customer" && source === "DVWM"){
        ApiServices.ResendLinkForCustomerDVWM(id, {trade_advice_code: trade_advice_code})
              .then(res => {
                  console.log(res.data);
                  toast.success(
                      `${res.message}`
                  )
                  setRefresh(Math.random());
                  setIsLoading(false);
              }).catch((err) => {
                  console.log(err);
                  setIsLoading(false);
              })
    }

      if(type === "Broker" && (source === "Add Funds" || inception_val_added_status === 1)){
        console.log('inside broker');
        ApiServices.ResendLinkForBrokerAddFunds(id)
              .then(res => {
                const data = res.data.filter((el) => {
                  return el.User_Action !== "No" && el.Name_of_Securities !== "cash"
                })
                  console.log("some-data", data);
                  toast.success(
                      `${res.message}`
                  )
                  setRefresh(Math.random());
                  setIsLoading(false);
              }).catch((err) => {
                  console.log(err);
                  setIsLoading(false);
              })
      }

      if(type === "Broker" && source === "ModPort" && inception_val_added_status === null){
        console.log('inside broker');
          ApiServices.ResendLinkForBrokerModPort(id)
          .then(res => {
            console.log(res.data);
          toast.success(
            `${res.message}`
          )
          setRefresh(Math.random());
          setIsLoading(false);
          }).catch((err) => {
          console.log(err);
          setIsLoading(false);
          })
      }

      if(type === "Broker" && source === "DVWM"){
        ApiServices.ResendLinkForBrokerDVWM(id)
              .then(res => {
                  toast.success(
                      `${res.message}`
                  )
                  setRefresh(Math.random());
                  setIsLoading(false);
              }).catch((err) => {
                  console.log(err);
                  setIsLoading(false);
              })
      }
  }

  const onClickDownloadButton = (id, type, source, inception_val_added_status, trade_advice_code) => {
    if(source === "Add Funds" || (source === "ModPort" && inception_val_added_status === 1)){
      ApiServices.getRecordByUniqueId(id)
            .then(res => {
                console.log("00" , res);
                const apiData = res[0].dummy_model_data.map((data) => ({'ISIN Number': data['ISIN_Number'], 'Name of Securities': data['Name_of_Securities'], 'CMP': data['CMP'], 'Suggested Amount Allocation': data['Suggested_Amount_Allocation'], 'User Action': data['User_Action'], "Suggested Trade": data['Suggested_Trade'], 'Buy Sell': data['Buy_Sell'], 'Price': data['Price']}));
                exportToXLSX(apiData.filter((item) => item['User Action'] === 'Yes' && !item['Name of Securities'].toLowerCase().includes('cash')), trade_advice_code)
                setRefresh(Math.random());
            }).catch((err) => {
                console.log(err);
            })
    }else if(source === "DVWM"){
        ApiServices.getRecordByUniqueId(id)
            .then(res => {
                console.log("00" , res);
                const apiData = res[0].dummy_model_data.map((data) => ({'Client Name': data['client_name'], "Model Name": res[0].model_name, "Portfolio Name": data['portfolio_name'], 'ISIN Number': data['ISIN_Number'], 'Name of Securities': data['Name_of_Securities'], 'CMP': data['CMP'], 'User Action': data['User_Action'], "Suggested Trade": data['Suggested_Trade'], 'Suggested Amount Allocation': data['Suggested_Amount_Allocation'], 'Buy Sell': data['Buy_Sell'], 'Price': data['Price']}));
                exportToXLSX(apiData, trade_advice_code)
                setRefresh(Math.random());
            }).catch((err) => {
                console.log(err);
            })
    }
    else {
      ApiServices.getRecordByUniqueId(id)
          .then(res => {
                console.log("00" , res);
                const apiData = res[0].dummy_model_data.map((data) => ({'ISIN Number': data['ISIN_Number'], 'Name of Securities': data['Name_of_Securities'], 'CMP': data['CMP'], 'Suggested Amount Allocation': data['Suggested_Amount_Allocation'], 'User Action': data['User_Action'], "Suggested Trade": data['Suggested_Trade'], 'Buy Sell': data['Buy_Sell'], 'Price': data['Price']}));
                console.log("apiData-here", apiData)
                exportToXLSX(apiData.filter((item) => item['User Action'] === 'Yes' && item['Name of Securities'].toLowerCase() !== 'cash'), trade_advice_code)
                setRefresh(Math.random());
        }).catch((err) => {
        console.log(err);
        })
      }
  }

  const onClickDownloadReportButton = (data) => {
    console.log("11@", data);
    if(data.length > 0){
    const ConsentDateHandler = (date, type, reportVerified) => {
      let Consent_Date = new Date(date).toLocaleString('en-IN', { day: 'numeric', month: 'numeric',year: 'numeric' });
      return (type === 'Broker') ? Consent_Date : (reportVerified === 'Accepted' ? Consent_Date : "Pending")
    }
    const apiData = data.map((data) => ({'Date': new Date(data.original['createdAt']).toLocaleString('en-IN', { day: 'numeric', month: 'numeric',year: 'numeric' }), 'Trade ID': data.original['trade_advice_code'], 'Model Name': data.original['model_name'], 'Portfolio Name': data.original['portfolio_name'], 'Location': data.original['type'], "Status": data.original['reportVerified'], 'Accept Date / Consent Date': ConsentDateHandler(data.original['updatedAt'], data.original['type'], data.original['reportVerified']), 'Source': data.original['source']}));
    const date = new Date().toLocaleString('en-IN', { day: 'numeric', month: 'numeric',year: 'numeric', hour: 'numeric', minute: 'numeric' });
    exportToXLSX(apiData, date)
      setRefresh(Math.random());
    }else{
      toast.warn(
        `Select checkbox to download a Report`
      )
    }
  }

  const onClickResendLinkOTPCRM = (id, newObj) => {
    ApiServices.updateCustomerEmailMobileToken(id, newObj)
              .then(res => {
                  console.log(res.data);
                  toast.success(
                      `${res.message}`
                  )
                  setRefresh(Math.random());
              }).catch((err) => {
                  console.log(err);
              })
  }

  //prathmeshk 22-02-22
  const onClickSaveMFData=(id, data)=>{
    console.log("inside save MF-467",data);
    ApiServices.updateUniqueCodeMutualFund(id, {unique_code: data})
      .then(res => {
          console.log(res);
          toast.success(
              `${res.message}`
          )
          setRefresh(Math.random());
      }).catch((err) => {
          console.log(err);
    })
  }

  const handleSearch = (obj) => {
    let data = Object.keys(obj).reduce((acc, elem) => {
      if (obj[elem] !== undefined && obj[elem] !== "-1"){
        acc[elem] = obj[elem]
      }
      return acc
    }, {})
    console.log("--1", data)
    ApiServices.filterReports(data)
              .then(res => {
                  settableDataReports(res);
              }).catch((err) => {
                  console.log(err);
              })

  }

  const onChangeUniqueCode = (id, val, date) => {
    console.log("DATE", date);
    let tempo = [...tableMFData]
    let MainIndex = tempo.findIndex((element) => element.date === new Date(date).toLocaleString('en-IN', { day: 'numeric', month: 'numeric',year: 'numeric' }))
    let FilterByDate = tempo.find((element) => element.date === new Date(date).toLocaleString('en-IN', { day: 'numeric', month: 'numeric',year: 'numeric' }))
    console.log("FILTER", FilterByDate)
    let index = FilterByDate.data.findIndex((element) => element["id"] === id);
    tempo[MainIndex]["data"][index]["entered_unique_code"] = val;
    console.log("TEMPO", tempo);
    settableMFData(tempo);
  }

  const handleRefresh = () => {
    setRefresh(Math.random());
    ReportsDataHandler();
  }

  return (
      <div id="style_main">
        <center>
          <div class="shadow-none mb-3 rounded">
            <h3 class="pt-2 pb-2" style={{ background: " rgb(245, 243, 243)" }}>
              Trade MIS &amp; Reports
            </h3>
          </div>
          </center>
          {/* {console.log("HII")} */}
          {isLoading && <LoadingSpinner isLoading={isLoading}/>}
          <Tabs variant="pills" defaultActiveKey="1" onSelect={handleSelectTab}>
            <Tab eventKey="1" title="Super Admin" class="tabsComponentItems" >
              {/* {generateDateSA.map((date, index)=> {
                return(
                <AccordianComponent title={date} AcceptedStatus={getAcceptedStatus(date, "SA", "Accepted", 1)} RejectedStatus={getAcceptedStatus(date, "SA", "Rejected", 0)} PendingStatus={getAcceptedStatus(date, "SA", "Pending", 0)} content={<TradeMIStable tableData={tableDataSA.filter((ele) => date === new Date(ele.date).toLocaleString('en-IN', { day: 'numeric', month: 'numeric',year: 'numeric' }))} COLUMNS={COLUMNSSA} type="tradeMISTableSA" onClickResendLink={onClickResendLink} isLoading={isLoading} />} />
                )})} */}
                {tableDataSA.map((element, index)=> {
                return(
                <AccordianComponent title={element.date} AcceptedStatus={element.CountAccepted} RejectedStatus={element.CountRejected} PendingStatus={element.CountPending} content={<TradeMIStable tableData={element.data} COLUMNS={COLUMNSSA} type="tradeMISTableSA" onClickResendLink={onClickResendLink} isLoading={isLoading} />} />
                )})}
            </Tab>
            <Tab eventKey="2" title="Customer" >
              {/* {generateDateCustomer.map((date)=> (
                <AccordianComponent title={date} AcceptedStatus={getAcceptedStatus(date, "Customer", "Accepted")} RejectedStatus={getAcceptedStatus(date, "Customer", "Rejected")} PendingStatus={getAcceptedStatus(date, "Customer", null)} content={<TradeMIStable tableData={tableDataCustomer.filter((ele) => date === new Date(ele.date).toLocaleString('en-IN', { day: 'numeric', month: 'numeric',year: 'numeric' }))} COLUMNS={COLUMNSCustomer} type="tradeMISTableCustomer" onClickResendLink={onClickResendLink} />} />
                ))} */}
                {tableDataCustomer.map((element)=> (
                <AccordianComponent title={element.date} AcceptedStatus={element.CountAccepted} PendingStatus={element.CountPending} content={<TradeMIStable tableData={element.data} COLUMNS={COLUMNSCustomer} type="tradeMISTableCustomer" onClickResendLink={onClickResendLink} />} />
                ))}
            </Tab>
            <Tab eventKey="3" title="Broker" >
              {/* {generateDateBroker.map((date)=> (
                <AccordianComponent title={date} AcceptedStatus={getAcceptedStatus(date, "Broker", null)} RejectedStatus={getAcceptedStatus()} PendingStatus={getPendingStatus(date, "Customer", null, "Accepted")} content={<TradeMIStable tableData={tableDataBroker.filter((ele) => date === new Date(ele.date).toLocaleString('en-IN', { day: 'numeric', month: 'numeric',year: 'numeric' }))} COLUMNS={COLUMNSBroker} type="tradeMISTableBroker" onClickSendBroker={onClickSendBroker} onClickResendLink={onClickResendLink} onClickBulkSendBroker={onClickBulkSendBroker} />} />
                ))} */}
                {tableDataBroker.map((element)=> (
                <AccordianComponent title={element.date} AcceptedStatus={element.CountAccepted} PendingStatus={element.CountPending} content={<TradeMIStable tableData={element.data} COLUMNS={COLUMNSBroker} type="tradeMISTableBroker" onClickSendBroker={onClickSendBroker} onClickResendLink={onClickResendLink} onClickBulkSendBroker={onClickBulkSendBroker} sendLoading={sendLoading} setSendLoading={setSendLoading} />} />
                ))}
            </Tab>
            <Tab eventKey="4" title="MF" >
              {/* {generateDateMF.map((date)=> (
                <AccordianComponent title={date} PendingStatus={getPendingStatus(date, "Customer", 1, "Accepted")} content={<TradeMIStable tableData={tableMFData.filter((ele) => date === new Date(ele.date).toLocaleString('en-IN', { day: 'numeric', month: 'numeric',year: 'numeric' }))} COLUMNS={COLUMNSMF} type="tradeMISTableMF" onClickSaveMFData={onClickSaveMFData} onChangeUniqueCode={onChangeUniqueCode} />} />
                ))} */}
                {tableMFData.map((element)=> {
                  return(
                <AccordianComponent title={element.date} PendingStatus={element.CountPending} content={<TradeMIStable tableData={element.data} COLUMNS={COLUMNSMF} type="tradeMISTableMF" onClickSaveMFData={onClickSaveMFData} onChangeUniqueCode={onChangeUniqueCode} />} />
                )})}
            </Tab>
            <Tab eventKey="5" title="CRM" >
              <TradeMIStable tableData={tableDataCRM} COLUMNS={COLUMNSCRM} type="tradeMISTableCRM" onClickResendLinkOTPCRM ={onClickResendLinkOTPCRM} />
            </Tab>
            <Tab eventKey="6" title="Reports" >
                <TradeMIStable tableData={tableDataReports} COLUMNS={COLUMNSReports} type="tradeMISTableReports" onClickDownloadButton={onClickDownloadButton} onClickDownloadReportButton={onClickDownloadReportButton} FilterReports={<ReportFilter tradeAdviceCodeData={tradeAdviceCodeData} modelNameData={modelNameData} portfolioNameData={portfolioNameData} typeData={typeData} statusData={statusData} consentDateData={consentDateData} sourceData={sourceData} handleSearch={handleSearch} handleRefresh={handleRefresh} />} />
            </Tab>
          </Tabs>
      </div>
    );
  }

export default TradeMisAndReports;
