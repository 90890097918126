import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import { useUserInfo } from '../../../ContextProviders/UserInfo';
import './filter.css';

const Filter = (props) => {
	const userInfo = useUserInfo();

	const [value, setValue] = useState(props.filter);
	console.log("90", value)
	const onChangeHandler = useAsyncDebounce((value) => {
		if(value === "empty"){
			console.log("VALUE", value);
			props.setFilter(null);
		}else{
			console.log("VALUE", value);
			props.setFilter(value || undefined);
		}
	}, 500);

	return (
		<>
			<div className="searchInput" id="serachID-filter">
				<input
					type="text"
					placeholder="Search"
					value={value || ''}
					onChange={(e) => {
						setValue(e.target.value);
						onChangeHandler(e.target.value);
					}}
					id="input-pass"
				/>
			</div>
		</>
	);
};

export default Filter;
