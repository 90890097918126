import React, { useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const toolbar = [
	['bold', 'italic', 'underline', 'strike', 'blockquote'], // toggled button

	[{ list: 'ordered' }, { list: 'bullet' }],
	// ['link', 'image', 'video'],
	[({ script: 'sub' }, { script: 'super' })], // superscript/subscript
	// [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
	[{ direction: 'rtl' }], // text direction

	// [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
	[{ header: [1, 2, 3, 4, 5, 6, false] }, { link: true }],

	// [{ color: [] }, { background: [] }], // dropdown with defaults from theme
	// [{ font: [] }],
	[{ align: [] }],
	['clean'], // remove formatting button
];

const TextEditor = ({ value, setTextarea, fieldName, readOnly = false }) => {
	const ref = useRef(null);
	useEffect(() => {
		ref.current.editor.root.setAttribute('spellcheck', 'false');
	});
	return (
		<ReactQuill
			ref={ref}
			spellcheck="false"
			placeholder="write something..."
			value={value}
			modules={{ toolbar }}
			readOnly = {readOnly}
			onChange={(e) => {
				setTextarea((prev) => {
					return { ...prev, [fieldName]: e };
				});
			}}
		/>
	);
};

export default TextEditor;
