import { useState, useEffect } from 'react';
import ApiServices from '../../ApiService/ApiConfig';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { FormGroup, Label, Input } from 'reactstrap';

import { Formik, Field, Form, ErrorMessage } from 'formik';

import * as Yup from 'yup';

const reportInfoModified = (data) => {
	let formatter = new Intl.NumberFormat('en-IN');
	if (data.length) {
		let modified = data.map((el) => {
			return { ...el, Current_Value: formatter.format(el.Current_Value) };
		});
		return modified;
	} else {
		return data;
	}
};

const SuperAdminLandingPage = (props) => {
	const [reportInfo, setReportInfo] = useState('');
	const [modalData, setModalData] = useState('');
	const [reportStatus, setReportStatus] = useState('Verified');
	const [custData, setCustData] = useState('');

	const statusData = 'Rejected';

	let {
		name: model_name,
		resettoken,
		resetvalidity
	} = useParams();

	console.log('PARAMS',useParams());


	resetvalidity = resetvalidity.replace(/_/g,' ')

	var newRes;

	useEffect(() => {
		LandingPageData();
	}, []);

	// verifyReportDataCustomerSecuritoes

	const LandingPageData = () => {
		ApiServices.verifyModelReportData(model_name)
			.then((res) => {
				console.log('responce', res);
				setReportInfo(res);
				getDelta();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		LandingPageDataCust();
	}, []);

	const LandingPageDataCust = () => {
		ApiServices.verifyReportDataCustomerSecuritoes(model_name)
			.then((res) => {
				const data = res.map((el) => {
					return el.customer_portfolio_securities;
				});
				console.log('responce_Cust', data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// for testing
	const getDelta = () => {
		const delta = localStorage.getItem('responseData');
		const deltarespo = delta;
		console.log(deltarespo);
	};

	useEffect(() => {
		console.log(model_name);
		modalPrevData();
	}, []);

	const modalPrevData = () => {
		ApiServices.getModel(model_name)
			.then((res) => {
				newRes = res;
				setModalData(newRes);
				// console.log(modalData.threshold_upper_limit);
				console.log(modalData);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const columns = [
		// {
		//     headerName: 'Id', field:'id',
		// },

		// {
		// 	headerName: 'Name of Model',
		// 	field: 'model_name',
		// },

		// {
		// 	headerName: 'ISIN Number',
		// 	field: 'ISIN_Number',
		// },

		{
			headerName: 'Name of Securities',
			field: 'Name_of_Securities',
		},

		{
			headerName: 'Asset Class',
			field: 'Asset_Class',
		},

		{
			headerName: 'Sub Asset Class',
			field: 'Sub_Asset_Class',
		},

		// {
		// 	headerName: 'Marketcap',
		// 	field: 'Marketcap',
		// },

		{
			headerName: 'CMP',
			field: 'CMP',
		},

		// {
		// 	headerName: 'NSE_Symbol',
		// 	field: 'NSE_Symbol',
		// },

		{
			headerName: 'No_of_Shares',
			field: 'No_of_Shares',
		},

		{
			headerName: 'Prescribed_Weight',
			field: 'Prescribed_Weight',
		},

		{
			headerName: 'Current_Value',
			field: 'Current_Value',
		},

		{
			headerName: 'Current_Weight',
			field: 'Current_Weight',
		},

		{
			headerName: 'Delta',
			field: 'Delta',
		},

		{
			headerName: 'Ratio_Start_Pt',
			field: 'Ratio_Start_Pt',
		},

		{
			headerName: 'Ratio_End_Pt',
			field: 'Ratio_End_Pt',
		},

		// {
		//     headerName: 'System Suggested Actionable', field:'Actionable',
		// },

		// {
		//     headerName: 'Suggested Trade', field:'Suggested_Trade',
		// },

		// {
		//     headerName: 'Buy Sell',
		// 	field: 'Buy_Sell'
		// },

		// {
		//     headerName: 'Price', field:'Price',
		// },
	];

	const defaultColDef = {
		sortable: true,
		filter: true,
	};

	let today = new Date().toISOString().slice(0, 10);

	function changeDateFormat(today) {
		// expects Y-m-d
		var splitDate = today.split('-');
		if (splitDate.count == 0) {
			return null;
		}

		var year = splitDate[0];
		var month = splitDate[1];
		var day = splitDate[2];

		return day + '/' + month + '/' + year;
	}

	//

	// const d = new Date();

	var newDate = changeDateFormat(today);

	const SubmitHandler = async (e) => {
		e.preventDefault();
		console.log('button clicked');
		console.log(reportStatus);
		// console.log(reportInfo);
		// console.log(model_name);
		// console.log(modalData);

		const destUrl = `${process.env.REACT_APP_URL_API}/model/updateRecordVerifyModel/${model_name}`;
		const data = await axios({
			method: 'post',
			url: destUrl,
			data: {
				status: reportStatus,
			},
		}).then((res) => {
			console.log('res', res);
			toast.success(`Response Recorded Successfully`);
			props.history.push('/thank');
			})
			.catch((err) => {
			toast.warning(`${err.response.data}`);
		});
	};

	console.log('COLS', columns);
	console.log('ROWS', reportInfo);
	console.log('MODEL DATA', modalData);

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				marginBottom: 125,
			}}
		>
			<div className="container-fluid text-center" 
			style={{
				width: '75%',
				marginTop: '100px',
				backgroundColor: '#eff6f9',
			}}
			>
				<h3
					className="pt-3 pb-3"
				>
					Model Report
				</h3>
			</div>

			<div
				className="ag-theme-alpine"
				style={{
					height: 400,
					width: 900,
					marginTop: 30,
				}}
			>
				<p>Verification date: {newDate}</p>
				<div
					style={{
						border: '1px solid #babfc7',
						padding: '15px',
						marginBottom: '20px',
					}}
				>
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-12">
								<p>
									<b>Model Name</b>{' '}
									<span style={{ padding: '0px 12px' }}>
										:
									</span>
									<b>{modalData.name}</b>
								</p>
							</div>
							{/* <div className="col-md-4">
                                <p><b>Portfolio Name</b> : {reportInfo.portfolio_name}</p>
                            </div>
                            <div className="col-md-4">
                                <p><b>Funds Addition</b> : {reportInfo.funds_added}</p>
                            </div> */}
						</div>
					</div>
				</div>
				{/* <div
					style={{
						marginBottom: 20,
						display: 'flex',
						textTransform: 'capitalize',
						minWidth: '400px',
					}}
				>
					<div
						style={{
							width: '130px',
							fontSize: '16px',
							background: 'cadetblue',
							border: '2px solid cadetblue',
							padding: '8px 16px',
							color: 'white',
						}}
					>
						modal name
					</div>
					<div
						style={{
							minWidth: '50px',
							color: '#1D3958',
							fontWeight: 'bold',
							fontSize: '16px',
							border: '2px solid cadetblue',
							padding: '8px 16px',
						}}
					>
						{modalData.name}
					</div>
				</div> */}
				<AgGridReact
					rowData={reportInfoModified(reportInfo)}
					columnDefs={columns}
					defaultColDef={defaultColDef}
				/>
				<br />
				{/* <input readOnly type="radio" value="" name=""className="ml-1 mr-2" />
                <label>I Accept This Change</label> */}

				<div className="form-group" style={{ height: '125px' }}>
					<form onSubmit={(e) => SubmitHandler(e)}>
						<button
							id="firstInput"
							type="submit"
							className="btn btn-success mt-3 mb-3 mr-2"
							onClick={(e) => setReportStatus('Verified')}
						>
							Accept
						</button>
						{/* <button type="reset" className="btn btn-secondary">Reset</button> */}
						<button
							id="secondInput"
							type="submit"
							className="btn btn-danger mt-3 mb-3 mr-2"
							style={{ marginLeft: '760px' }}
							onClick={(e) => setReportStatus(statusData)}
						>
							Reject
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default SuperAdminLandingPage;
