import React, { useEffect, useState } from 'react';
import ApiServices from '../../../ApiService/ApiConfig';
import './communication-channel-table.css';

var validRegex =
	/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

function CommunicationChannelTable({ id }) {
	const [validationFlag, setValidationFlag] = useState(false);
	const [boolean, setBoolean] = useState(false);
	const [data, setData] = useState([]);
	const [credentials, setCredentials] = useState({});
	const [disable, setDisable] = useState(false);

	const handleVisible = (purpose) => {
		let oldObj = [...data];
		let found = oldObj.findIndex((el) => {
			return el.purpose === purpose;
		});
		oldObj[found]['visible'] = !oldObj[found]['visible'];
		setData(oldObj);
	};

	const handleClick = (purpose) => {
		let oldObj = [...data];
		let found = oldObj.findIndex((el) => {
			return el.purpose === purpose;
		});
		oldObj[found]['flag'] = true;
		setData(oldObj);
	};

	const onChangeHandler = (e, purpose) => {
		setValidationFlag(false);
		let oldObj = [...data];
		let found = oldObj.findIndex((el) => {
			return el.purpose === purpose;
		});
		oldObj[found][e.target.name] = e.target.value;
		if (oldObj[found]['email'] === '' || oldObj[found]['password'] === '')
			setDisable(true);
		else setDisable(false);
		setData(oldObj);
		setCredentials({ ...credentials, [e.target.name]: e.target.value });
	};

	const submitHandler = (purpose) => {
		if (credentials.email && !validRegex.test(credentials.email)) {
			return setValidationFlag(true);
		}
		if (!credentials.email && !credentials.password) {
			userdata();
			setBoolean(false);
		} else {
			ApiServices.UpdateServiceMails({ credentials, purpose }, id).then(
				(res) => {
					if (res.data.message === 'successfull') {
						setCredentials({});
						userdata();
						setBoolean(false);
					}
				}
			);
		}
	};

	useEffect(() => {
		console.log('useEffect...');
		userdata();
	}, []);

	const userdata = () => {
		console.log('userData..');
		ApiServices.GetServiceMails(id)
			.then((res) => {
				let modified = res.map((el) => {
					el.flag = false;
					el.visible = false;
					el.validation = false;
					return el;
				});
				setData(modified);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div className="table-wrapper">
			<table className="my-custom-table">
				<tr>
					<th style={{ width: '25%' }}>mail purpose</th>
					<th style={{ width: '35%' }}>e-mail</th>
					<th style={{ width: '30%' }}>password</th>
					<th style={{ width: '10%' }}>action</th>
				</tr>
				{data.map((item) => {
					return (
						<tr key={item.purpose}>
							<td className="communication-purpose">
								{item.purpose.toLowerCase()}
							</td>
							<td>
								{item.flag ? (
									<div>
										<input
											name="email"
											className="email-edit-input-tag"
											type="email"
											value={item.email}
											onChange={(e) => {
												onChangeHandler(
													e,
													item.purpose
												);
											}}
										/>
										{validationFlag ? (
											<span className="mail-validation-span">
												please, put valid email
											</span>
										) : null}
									</div>
								) : (
									item.email
								)}
							</td>
							<td>
								{item.flag ? (
									<div className="password-div">
										<input
											name="password"
											value={item.password}
											onChange={(e) => {
												onChangeHandler(
													e,
													item.purpose
												);
											}}
											type={
												item.visible
													? 'text'
													: 'password'
											}
										/>
										<i
											className="fa fa-eye"
											onClick={() => {
												handleVisible(item.purpose);
											}}
										></i>
									</div>
								) : (
									<div className="password-div">
										<input
											type={
												item.visible
													? 'text'
													: 'password'
											}
											value={item.password}
											readOnly
										/>
										<i
											className="fa fa-eye"
											onClick={() => {
												handleVisible(item.purpose);
											}}
										></i>
									</div>
								)}
							</td>
							<td>
								{item.flag ? (
									<div className="action-column">
										<button
											className="button-update"
											onClick={() => {
												submitHandler(item.purpose);
											}}
											disabled={disable}
										>
											update
										</button>
									</div>
								) : (
									<div className="action-column">
										<i
											className={
												boolean === item.flag
													? 'fa fa-edit'
													: ''
											}
											id="profile-data-icon"
											onClick={() => {
												setBoolean(true);
												handleClick(item.purpose);
											}}
										></i>
									</div>
								)}
							</td>
						</tr>
					);
				})}
			</table>
		</div>
	);
}

export default CommunicationChannelTable;
