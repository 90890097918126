import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import './CustomerVerificationPage.css';

import ApiServices from '../../ApiService/ApiConfig';

const CustomerVerificationPage = () => {
	const location = useLocation();
	let VerificationSource = location.pathname.substring(location.pathname.lastIndexOf("/") + 1).split(' ')[0];
	console.log("PARAMS", useLocation (), VerificationSource);
	const { id: id, resetOTPToken: resetOTPToken, resetOTPValidity } = useParams();
	const [verifiedStatus, setVerifiedStatus] = useState(false);

	useEffect(() => {
		ApiServices.listAllUser()
			.then((res) => {
				console.log('--', res);
				res.map((element) => {
					if (element.resetOTPToken === resetOTPToken) {
						if(VerificationSource === "email"){
							if(element.cust_email_verified_status === null){
								setVerifiedStatus(true);
								UpdateEmailVerificationStatus(element.id, {cust_email_verified_status: "Verified"});
							}else{
								setVerifiedStatus(false);
							}
						}else{
							if(element.cust_phone_verified_status === null){
								setVerifiedStatus(true);
								UpdateEmailVerificationStatus(element.id, {cust_phone_verified_status: "Verified"});
							}else{
								setVerifiedStatus(false);
							}
						}
					}
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}, [])

	const UpdateEmailVerificationStatus = (id, status) => {
		ApiServices.updateUserStatus(id, status)
			.then((res) => {
				console.log('--', res);
			})
			.catch((err) => {
				console.log(err);
			});
	}
	
	return (
		<div
			style={{
				width: '100%',
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			{VerificationSource === "email" ? (verifiedStatus ? <h1 style={{ color: 'teal' }}>Thanks For Your Response</h1> : <h1 style={{ color: '#faad14' }}>Response Already Recorded</h1>) : (verifiedStatus ? <h1 style={{ color: 'teal' }}>Thanks For Your Response</h1> : <h1 style={{ color: '#faad14' }}>Response Already Recorded</h1>)}
		</div>
	);
};

export default CustomerVerificationPage;
