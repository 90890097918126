import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from "../PrivateRoute";
import Login from "../../Pages/Login";
import Home from "../home.component";
import Profile from "../profile.component";
import BoardUser from "../board-user.component";
import CommunicationChannel from "../Layout/communication-channel";
import OtherSettings from "../Layout/other-settings";

import BoardAdmin from "../board-admin.component";
import AssetList from "../assetMaster/AssetList.component";
import AssetForm from "../assetMaster/AssetForm.component";
import SubAssetList from "../subAssetMaster/SubAssetList.component";
import forgetPass from "../Layout/forget-password";
import EditTableRecord from "../Layout/Table-layout/EditTableRecord";
import RedressalformComponent from "../redressalform.component";
import UpdateRedressalform from "../GrievanceRedressal/updateRedressalform";
import BoardCustomer from "../board-customer.component";
import AddCustomer from "../Customers/AddCustomer";
import UpdateCustomer from "../Customers/UpdateCustomer";

//broker
import BoardBroker from "../board-broker.component";
import AddBroker from "../Broker/AddBroker";
import ViewBroker from "../Broker/ViewBroker";
import UpdateBroker from "../Broker/UpdateBroker";

import AddUser from "../Users/AddUser";
import UpdateUser from "../Users/UpdateUser";
import AddModel from "../Model/AddModel";
import UpdateModel from "../Model/UpdateModel";
import RiskProfileQustionair from "../RiskProfile/RiskProfileQustionair";
import ViewCustomer from "../Customers/ViewCustomer";
import ViewUser from "../Users/ViewUser";
import BrokerReportInfo from "../Trade-MIS-And-Reports/Reports/BrokerReportInfo";
import CustomerLandingReportPageDVWM from "../Model/CustomerLandingReportPageDVWM";
import SuperAdminLandingPageDVWM from "../Model/SuperAdminLandingPageDVWM";
import Header from "../Header";
import SidebarNew from "../Layout/SidebarNew";
import { useUserInfo } from "../../ContextProviders/UserInfo";
import "../../App.css";
import "../Layout/SidebarNew.css";
import AddRedressalform from "../GrievanceRedressal/AddRedressalform";
import NewPasswordForm from "../../Pages/Login/NewPasswordForm";
import ForgotPassword from "../../Pages/Login/ForgotPassword";
import ChangePassword from "../ChangePassword";
import UpdateAddress from "../UpdateAddress";
import AddAddress from "../AddAddress";
import ModalList from "../ModPort/board-ModelList.component";
import CustomerLandingReportPage from "../Model/CustomerLandingReportPage";
import AllCustomersReport from "../Model/AllCustomersReport";
import SuperAdminLandingPage from "../Model/SuperAdminLandingPage";
import PortfolioCustomerReportPage from "../Customer-Portfolio/PortfolioCustomerReportPage";
import UserAprovelPage from "../Customer-Portfolio/UserAprovelPage";
import BrokerReportLandingPage from "../Customer-Portfolio/BrokerReportLandingPage";
import Thank from "../Model/Thank";

import Cust_Thank from "../Customer-Portfolio/Cust_Thank";
import User_Thank from "../Customer-Portfolio/User_Thank";
// example import for testing modal
import BasicModal from "../Modal-UI/TestModal/ModalTestExample";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SecurityList from "../../Pages/Securities/SecurityList";
import parseExcelToJSON from "../../Pages/Securities/parseExcelToJSON";
import CustList from "../ModPort/board-CustPortfolioList.component";
import AddCustPortfolio from "../Customer-Portfolio/AddCustPortfolio";
import UpdateCustomerPortfolio from "../Customer-Portfolio/UpdateCustPortfolio";
import ModalFunds from "../Modal-UI/TestModal/FundsModal";
import AddFunds from "../Model/AddFunds";
import ManageCustomers from "../Layout/manage-customers";
import ManageBroker from "../Layout/manage-broker";
import ManageUsers from "../Layout/manage-users";
import UpdateProfile from "../updateprofile";
import Footer from "../Footer";
// import Footer from "../Footer/index (2)";
import Pcomp from "../Customers/Pcomp";
import CustomerVerificationPage from "../Customer-Verification/CustomerVerificationPage";
import Otpverified from "../Customer-Verification/Otpverified";
import AddFundsGlobal from "../Add-Funds-global.js";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TradeMISMainPage from "../Trade-MIS-Module/TradeMISLandingPage";
import CustomerReportLandingReportPage from "../Add-Funds-global.js/CustomerReportLandingPage";
import SuperAdminReportLandingReportPage from "../Add-Funds-global.js/SuperAdminReportLandingPage";
import AddFundsSuperAdminDataPage from "../Add-Funds-global.js/AddFundsSuperAdminDataPage.js";
import AddFundsCustomerDataPage from "../Add-Funds-global.js/AddFundsCustomerDataPage.js";
import BrokerReportAddFundLandingPage from "../Add-Funds-global.js/BrokerReportAddFundLandingPage";
import TradeMisAndReports from "../Trade-MIS-And-Reports/tradeMisAndReports.component";
//added by vishal
import ModuleDVWM from "../ModuleDVWM/main";
import CustomerLandingReportPage1 from "../Model/CustomerLandingReportPage1.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: 500,
  bgcolor: "background.paper",
  border: "1px solid #096dd9",
  boxShadow: 24,
  p: 4,
};

function MainRouter(props) {
  const [isLoggedIn, setIsLoggedOut] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(false);
  const handleClose = () => {
    setOpen(false);
  };
  const userInfo = useUserInfo();

  var item;
  useEffect(() => {}, [isLoggedIn]);
  const logoutHandler = () => {
    localStorage.clear();
    setIsLoggedOut(Math.random());
  };
  if (userInfo) {
    item = userInfo.role.role_name;
  }

  const PopupWindow = () => {
    alert("1");
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="container">
            <h4>First you need to update latest model details</h4>
            <div className="row">
              <div className="col-sm-6">
                <h4>bhudb</h4>
                <h4>bhudb</h4>
                <h4>bhudb</h4>
                <h4>bhudb</h4>
                <h4>bhudb</h4>
                <h4>bhudb</h4>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    );
  };

  return (
    <BrowserRouter basename="/app">
      <Header logoutHandler={logoutHandler} />
      {localStorage.getItem("userInfo") !== null && (
        <>
          <SidebarNew />{" "}
        </>
      )}
      <ToastContainer position="top-center" />
      <Switch>
        {localStorage.getItem("userInfo") === null && (
          <Route exact path="/" component={Login} />
        )}

        {localStorage.getItem("userInfo") !== null && (
          <PrivateRoute exact path={["/", "/home"]} component={Home} />
        )}

        <Route exact path="/login" component={Login} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route
          exact
          path="/customerreportlandingpage/:name/:resetToken/:resetValidity/:trade_advice_code"
          component={CustomerLandingReportPage}
        />
        <Route
          exact
          path="/modPortSuperAdmin/:name/:trade_advice_code"
          component={CustomerLandingReportPage1}
        />
        <Route
          exact
          path="/addFundsSuperAdminDataPage/:name/:resettoken/:resetvalidity/:id/:trade_advice_code"
          component={AddFundsSuperAdminDataPage}
        />
        <Route
          exact
          path="/modPortCustomerDataPage/:name/:trade_advice_code"
          component={CustomerLandingReportPage1}
        />
        <Route
          exact
          path="/addFundsCustomerDataPage/:name/:resettoken/:resetvalidity/:id/:trade_advice_code"
          component={AddFundsCustomerDataPage}
        />
        <Route
          exact
          path="/allReportsLandingpage/:name/:resetToken/:resetValidity/:trade_advice_code"
          component={AllCustomersReport}
        />
        <Route
          exact
          path="/superadminlandingpage/:name/:resettoken/:resetvalidity"
          component={SuperAdminLandingPage}
        />
        <Route
          exact
          path="/customergeneratereportlandingpage/:token/:portfolio_name/:client_first_name/:client_last_name/:name/:trade_advice_code"
          component={PortfolioCustomerReportPage}
        />
        <Route
          exact
          path="/useraprovelpage/:name/:user_email/:broker_email/:token"
          component={UserAprovelPage}
        />
        <Route
          exact
          path="/brokerreportlandingpage/:name/:token"
          component={BrokerReportLandingPage}
        />
        <Route exact path="/thank" component={Thank} />
        <Route exact path="/custthank" component={Cust_Thank} />
        <Route exact path="/userthank" component={User_Thank} />
        <Route
          exact
          path="/newpassword-page/:token"
          component={NewPasswordForm}
        />
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute
          exact
          path="/changepassword/:id"
          component={ChangePassword}
        />
        <PrivateRoute exact path="/update-address" component={UpdateAddress} />
        <PrivateRoute exact path="/add-address" component={AddAddress} />
        <PrivateRoute
          exact
          path="/customerverificationpage/:id/:resetOTPToken/:resetOTPValidity/email"
          component={CustomerVerificationPage}
        />
        <PrivateRoute
          exact
          path="/customerverificationpage/:id/:resetOTPToken/:resetOTPValidity/whatsapp"
          component={CustomerVerificationPage}
        />
        <PrivateRoute exact path="/otpverified" component={Otpverified} />
        <PrivateRoute
          exact
          path="/fundsaddedglobal"
          component={AddFundsGlobal}
        />
        <Route path="/downloadcustdata" component={Pcomp} />
        <PrivateRoute
          exact
          path="/trademislandingpage"
          component={TradeMISMainPage}
        />
        {/* <Route
          exact
          path="/addfundsSuperAdminlandingpage/:name/:resettoken/:resetvalidity/:id/:trade_advice_code"
          component={SuperAdminReportLandingReportPage}
        /> */}
        <Route
          exact
          path="/addfundsSuperAdminlandingpage/:name/:resettoken/:resetvalidity/:id/:trade_advice_code"
          component={SuperAdminReportLandingReportPage}
        />
        <Route
          exact
          path="/addfundsCustomerlandingpage/:name/:resettoken/:resetvalidity/:id/:trade_advice_code"
          component={CustomerReportLandingReportPage}
        />
        <Route
          exact
          path="/addfundsBrokerlandingpage/:name/:resettoken/:resetvalidity/:id/:trade_advice_code"
          component={BrokerReportAddFundLandingPage}
        />

        <Route
          exact
          path="/customerreportlandingpagedvwm/:name/:portfolioName/:resetToken/:resetvalidity/:trade_advice_code"
          component={CustomerLandingReportPageDVWM}
        />

        <Route
          exact
          path="/superadminlandingpagedvwm/:name/:resettoken/:resetvalidity/:trade_advice_code"
          component={SuperAdminLandingPageDVWM}
        />

        <PrivateRoute
          path="/checkcustomerportfolio"
          component={BrokerReportInfo}
        />

        {item === "super-admin" && (
          <>
            <PrivateRoute path="/user" component={BoardUser} />
            <PrivateRoute path="/admin" component={BoardAdmin} />
            <PrivateRoute exact path="/assets" component={AssetList} />
            <PrivateRoute
              path="/assets/addAsset"
              name="AddAsset"
              component={AssetForm}
            />
            <PrivateRoute exact path="/subAssets" component={SubAssetList} />
            <PrivateRoute exact path="/forgetPassword" component={forgetPass} />
            <PrivateRoute
              exact
              path="/redressalform"
              component={RedressalformComponent}
            />
            <PrivateRoute
              exact
              path="/addredressalform"
              component={AddRedressalform}
            />
            <PrivateRoute
              exact
              path="/updateredressalform/:id"
              component={UpdateRedressalform}
            />
            <PrivateRoute exact path="/customer" component={BoardCustomer} />
            <PrivateRoute exact path="/addcustomer" component={AddCustomer} />
            <PrivateRoute
              exact
              path="/updatecustomer/:id"
              component={UpdateCustomer}
            />
            <PrivateRoute
              exact
              path="/viewcustomer/:id"
              component={ViewCustomer}
            />
            <PrivateRoute exact path="/broker" component={BoardBroker} />
            <PrivateRoute exact path="/addbroker" component={AddBroker} />
            <PrivateRoute exact path="/viewbroker/:id" component={ViewBroker} />
            <PrivateRoute
              exact
              path="/updatebroker/:id"
              component={UpdateBroker}
            />
            <PrivateRoute exact path="/adduser" component={AddUser} />
            <PrivateRoute exact path="/addmodel" component={AddModel} />
            <PrivateRoute
              exact
              path="/updatemodel/:name"
              component={UpdateModel}
            />
            <PrivateRoute exact path="/addFunds" component={AddFunds} />
            <PrivateRoute exact path="/updateuser/:id" component={UpdateUser} />
            <PrivateRoute exact path="/viewuser/:id" component={ViewUser} />
            <PrivateRoute exact path="/modport" component={ModalList} />
            <PrivateRoute
              exact
              path="/customerportfolio"
              component={CustList}
            />
            <PrivateRoute
              exact
              path="/addcustomerportfolio"
              component={AddCustPortfolio}
            />
            <PrivateRoute
              exact
              path="/updatecustomerportfolio/:name"
              component={UpdateCustomerPortfolio}
            />
            <PrivateRoute exact path="/Securities" component={SecurityList} />
            <PrivateRoute
              exact
              path="/ExcelTOJson"
              component={parseExcelToJSON}
            />
            {/* temporary route for testing */}
            <Route exact path="/example" component={ModalFunds} />
            <Route exact path="/example" component={BasicModal} />
            <PrivateRoute
              exact
              path="/riskprofile/:id"
              component={RiskProfileQustionair}
            />
            <PrivateRoute
              exact
              path="/updateprofile/:id"
              component={UpdateProfile}
            />
            <PrivateRoute
              exact
              path="/managecustomers"
              component={ManageCustomers}
            />
            <PrivateRoute exact path="/managebroker" component={ManageBroker} />
            <PrivateRoute exact path="/manageusers" component={ManageUsers} />
            <PrivateRoute
              exact
              path="/communication-channel"
              component={CommunicationChannel}
            />
            <PrivateRoute
              exact
              path="/other-settings"
              component={OtherSettings}
            />
            {/* <PrivateRoute path="/downloadcustdata" component={DownloadCustData} /> */}
            <PrivateRoute
              exact
              path="/trademisreports"
              component={TradeMisAndReports}
            />
            {/* added by vishal */}
            <PrivateRoute exact path="/dvwm" component={ModuleDVWM} />
          </>
        )}

        {item === "admin" && (
          <>
            <PrivateRoute path="/user" component={BoardUser} />
            <PrivateRoute path="/admin" component={BoardAdmin} />
            <PrivateRoute exact path="/assets" component={AssetList} />
            <PrivateRoute
              path="/assets/addAsset"
              name="AddAsset"
              component={AssetForm}
            />
            <PrivateRoute exact path="/subAssets" component={SubAssetList} />
            <PrivateRoute exact path="/forgetPassword" component={forgetPass} />
            <PrivateRoute
              exact
              path="/redressalform"
              component={RedressalformComponent}
            />
            <PrivateRoute
              exact
              path="/addredressalform"
              component={AddRedressalform}
            />
            <PrivateRoute
              exact
              path="/updateredressalform/:id"
              component={UpdateRedressalform}
            />
            <PrivateRoute exact path="/customer" component={BoardCustomer} />
            <PrivateRoute exact path="/addcustomer" component={AddCustomer} />
            <PrivateRoute
              exact
              path="/updatecustomer/:id"
              component={UpdateCustomer}
            />
            <PrivateRoute
              exact
              path="/viewcustomer/:id"
              component={ViewCustomer}
            />
            <PrivateRoute exact path="/broker" component={BoardBroker} />
            <PrivateRoute exact path="/addbroker" component={AddBroker} />
            <PrivateRoute exact path="/viewbroker/:id" component={ViewBroker} />
            <PrivateRoute
              exact
              path="/updatebroker/:id"
              component={UpdateBroker}
            />
            {/* <PrivateRoute exact path="/adduser" component={AddUser} /> */}
            <PrivateRoute exact path="/addmodel" component={AddModel} />
            <PrivateRoute
              exact
              path="/updatemodel/:name"
              component={UpdateModel}
            />
            <PrivateRoute exact path="/addFunds" component={AddFunds} />
            {/* <PrivateRoute exact path="/updateuser/:id" component={UpdateUser} />
 <PrivateRoute exact path="/viewuser/:id" component={ViewUser} /> */}
            <PrivateRoute exact path="/modport" component={ModalList} />
            <PrivateRoute
              exact
              path="/customerportfolio"
              component={CustList}
            />
            <PrivateRoute
              exact
              path="/addcustomerportfolio"
              component={AddCustPortfolio}
            />
            <PrivateRoute
              exact
              path="/updatecustomerportfolio/:name"
              component={UpdateCustomerPortfolio}
            />
            <PrivateRoute exact path="/Securities" component={SecurityList} />
            <PrivateRoute
              exact
              path="/ExcelTOJson"
              component={parseExcelToJSON}
            />
            {/* temporary route for testing */}
            <Route exact path="/example" component={ModalFunds} />
            <Route exact path="/example" component={BasicModal} />
            <PrivateRoute
              exact
              path="/riskprofile/:id"
              component={RiskProfileQustionair}
            />
            <PrivateRoute
              exact
              path="/updateprofile/:id"
              component={UpdateProfile}
            />
            <PrivateRoute
              exact
              path="/managecustomers"
              component={ManageCustomers}
            />
            <PrivateRoute exact path="/managebroker" component={ManageBroker} />
            {/* <PrivateRoute exact path="/manageusers" component={ManageUsers} /> */}
            <PrivateRoute
              exact
              path="/trademisreports"
              component={TradeMisAndReports}
            />
            <PrivateRoute
              exact
              path="/communication-channel"
              component={CommunicationChannel}
            />
            <PrivateRoute
              exact
              path="/other-settings"
              component={OtherSettings}
            />
            <PrivateRoute exact path="/dvwm" component={ModuleDVWM} />
          </>
        )}

        {item === "customer" && (
          <>
            {/* <PrivateRoute path="/user" component={BoardUser} /> */}

            {/* <PrivateRoute exact path='/forgetPassword' component={forgetPass} />
 <PrivateRoute exact path='/redressalform' component={RedressalformComponent} />
 <PrivateRoute exact path='/addredressalform' component={AddRedressalform} /> */}
            {/* <PrivateRoute exact path='/updateredressalform/:id' component={UpdateRedressalform} />
 <PrivateRoute exact path="/updateuser/:id" component={UpdateUser} /> */}
            {/* temporary route for testing */}
            {/* <Route exact path="/example" component={BasicModal} />
 <PrivateRoute exact path="/riskprofile/:id" component={RiskProfileQustionair} /> */}
          </>
        )}
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default MainRouter;
