import React, { useState, useEffect } from "react";
import moment, { invalid } from "moment";
import ApiServices from "../../ApiService/ApiConfig";
import "./Print.css";
import dv_logo from "./logoDV.jpg";

const Print = React.forwardRef((props, ref) => {
  const [getCompanyAddressData, setGetCompanyAddressData] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      ApiServices.GetCompanyAddress()
        .then((res) => {
          if (res.length) {
            setGetCompanyAddressData(res[0].address);
          } else {
            setGetCompanyAddressData("");
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldCheck = (value) => {
    if (value === null || value === "") return "NIL";
    return value;
  };

  return (
    <div>
      <div
        style={{
          paddingRight: "60px",
          paddingLeft: "60px",
          paddingTop: "20px",
          fontSize: "16px",
        }}
        className="px-30 width-100"
        id="page-container"
        ref={ref}
      >
        <div className="img_div">
          <img src={dv_logo} alt="" className="logo" />
          <div className="center">
            <div className="pageHeading_center width-60 underline text-center">
              DV INVESTMENTS ADVISORS LLP
            </div>
          </div>

          <div className="center">
            <div className="underline width-max-content mb-10 font-16">
              KNOW YOUR CLIENT (KYC) APPLICATION FORM
            </div>
          </div>
        </div>

        <div className="font-bold mb-3">
          <div className="flex-row space-between mt-20">
            <div className="font-16">
              Please fill the form in ENGLISH and in BLOCK LETTERS.
            </div>
            <div className="font-16">For Individuals</div>
          </div>
        </div>

        {/* <!-- A. IDENTITY DETAILS --> */}
        <div className="personal_details">
          <img
            className="person_img"
            src={`${process.env.REACT_APP_URL_API}/${props.passPortPhoto}`}
            alt=""
          />
          <div className="section-header">A. IDENTITY DETAILS</div>
          <div className="py-2 flex-column gap-1">
            {/* <!-- 1 --> */}
            <div className="flex-row flex-wrap">
              <div className="serial_number font-16">1.</div>
              <div className="font-bold font-16">Name of the Applicant :</div>
              <div className="underline font-16">
                {" " +
                  props.CustData.first_name +
                  " " +
                  props.CustData.middle_name +
                  " " +
                  props.CustData.last_name +
                  " "}
              </div>
            </div>
            {/* <!-- 2 --> */}
            <div className="flex-row flex-wrap">
              <div className="serial_number font-16">2.</div>
              <div className="font-bold font-16">
                Father's / Spouse's Name :
              </div>
              <div className="underline font-16">
                {"  " +
                  fieldCheck(
                    props.CustData.customer_profile.fathers_spouse_name
                  ) +
                  " "}
              </div>
            </div>
            {/* <!-- 3 --> */}
            <div className="flex-row flex-wrap">
              <div className="serial_number font-16">3.</div>
              <div className="flex-row gap-10 font-16">
                <div className="flex-row flex-wrap">
                  <div className="serial_number font-bold font-16">a.</div>
                  <div className="font-bold font-16">Gender :</div>
                  <div className="underline font-16">
                    {" " +
                      fieldCheck(props.CustData.customer_profile.gender) +
                      " "}
                  </div>
                </div>
                <div className="flex-row flex-wrap">
                  <div className="serial_number font-bold font-16">b.</div>
                  <div className="font-bold font-16">DOB :</div>
                  <div className="underline font-16">
                    {moment(props.CustData.customer_profile.dob).format(
                      "DD-MM-YYYY"
                    )}
                  </div>
                </div>
                <div className="flex-row flex-wrap">
                  <div className="serial_number font-bold font-16">c.</div>
                  <div className="font-bold font-16">Martial Status :</div>
                  <div className="underline font-16">
                    {fieldCheck(props.CustData.customer_profile.marital_status)}
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- 4 --> */}
            <div className="flex-row flex-wrap">
              <div className="serial_number font-16">4.</div>
              <div className="flex-row gap-10">
                <div className="flex-row flex-wrap">
                  <div className="serial_number font-bold font-16">a.</div>
                  <div className="font-bold font-16">Nationality :</div>
                  <div className="underline font-16">
                    {" " +
                      fieldCheck(props.CustData.customer_profile.nationality) +
                      " "}
                  </div>
                </div>
                <div className="flex-row flex-wrap">
                  <div className="serial_number font-bold font-16">b.</div>
                  <div className="font-bold font-16">Status :</div>
                  <div className="underline font-16">
                    {" " +
                      fieldCheck(
                        props.CustData.customer_profile.residental_status
                      ) +
                      " "}
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- 5 --> */}
            <div className="flex-row flex-wrap">
              <div className="serial_number font-16">5.</div>
              <div className="flex-column">
                <div className="flex-row flex-wrap">
                  <div className="serial_number font-bold font-16">a.</div>
                  <div className="font-bold font-16">PAN :</div>
                  <div className="underline font-16">
                    {" " + props.CustData.customer_profile.pan_no + " "}
                  </div>
                </div>
                <div className="flex-row flex-wrap">
                  <div className="serial_number font-bold font-16">b.</div>
                  <div className="font-bold font-16">
                    Unique Identification Number(UID) / Aadhaar, If any :
                  </div>
                  <div className="underline font-16">
                    {" " + props.CustData.customer_profile.aadhar_no + " "}
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- 6 --> */}
            <div className="flex-row flex-wrap">
              <div className="serial_number font-16">6.</div>
              <div className="font-bold font-16">
                Specify the proof of the identity submitted :
              </div>
              <div className="underline font-16">
                {" " +
                  fieldCheck(
                    props.CustData.customer_profile.proof_of_identity_submitted
                  ) +
                  " "}
              </div>
            </div>
          </div>
        </div>

        {/* <!-- B. ADDRESS DETAILS --> */}
        <div className="">
          <div className="section-header">B. ADDRESS DETAILS</div>
          <div className="py-2 flex-column gap-1">
            {/* <!-- 1 --> */}
            <div className="flex-row">
              <div className="serial_number font-16">1.</div>
              <div className="flex-row flex-wrap">
                <div className="flex-row flex-wrap">
                  <div className="pr-5">
                    <span className="font-bold font-16">
                      Address for Correspondence
                    </span>{" "}
                    :
                    <span className="underline font-16">
                      {fieldCheck(
                        props.CustData.customer_profile.communication_address
                      )}
                    </span>
                  </div>
                  <div className="flex-row flex-wrap gap-4">
                    <div className="font-bold font-16">
                      City / Town / Village :
                    </div>
                    <div className="underline font-16">
                      {" " +
                        fieldCheck(
                          props.CustData.customer_profile
                            .adr_correspondence_city_town_village
                        ) +
                        " "}
                    </div>
                    <div className="font-bold font-16">Pin Code :</div>
                    <div className="underline font-16">
                      {" " +
                        fieldCheck(props.CustData.customer_profile.pincode) +
                        " "}
                    </div>
                    <div className="serial_number font-16"></div>
                    <div className="font-bold font-16">State :</div>
                    <div className="underline font-16">
                      {" " +
                        fieldCheck(
                          props.CustData.customer_profile
                            .adr_correspondence_state
                        ) +
                        " "}
                    </div>
                    <div className="font-bold font-16">Country :</div>
                    <div className="underline font-16">
                      {" " +
                        fieldCheck(
                          props.CustData.customer_profile
                            .adr_correspondence_country
                        ) +
                        " "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- 2 --> */}
            <div className="flex-row">
              <div className="serial_number font-16">2.</div>
              <div className="flex-row flex-wrap">
                <div className="font-bold font-16">
                  Contact Details : Tel.(Off) :
                </div>
                <div className="underline font-16">
                  {fieldCheck(props.CustData.customer_profile.tel_off)}
                </div>
                <div className="font-bold font-16">Tel.(Res) :</div>
                <div className="underline font-16">
                  {fieldCheck(props.CustData.customer_profile.tel_res)}
                </div>
                <div className="font-bold font-16">Mobile No :</div>
                <span className="underline font-16">
                  {fieldCheck(props.CustData.phone)}
                </span>
                <br />
                <div className="font-bold font-16">Fax :</div>
                <div className="underline font-16">
                  {fieldCheck(props.CustData.customer_profile.fax)}
                </div>
                <div className="font-bold font-16">Email ID :</div>
                <div className="underline font-16">
                  {" " + fieldCheck(props.CustData.email) + " "}
                </div>
              </div>
            </div>
            {/* <!-- 3 --> */}
            <div className="flex-row flex-wrap">
              <div className="serial_number font-16">3.</div>
              <div className="flex-row flex-wrap">
                <div className="font-bold font-16">
                  Specify the proof of address submitted for correspondence
                  address :
                </div>
                <div className="underline font-16">
                  {" " +
                    fieldCheck(
                      props.CustData.customer_profile
                        .specify_correspondence_address_proof
                    ) +
                    " "}
                </div>
              </div>
            </div>
            {/* <!-- 4 --> */}
            <div className="flex-row">
              <div className="serial_number font-16">4.</div>
              <div className="flex-column">
                <div className="flex-column">
                  <div className="">
                    <span className="font-bold pr-2 font-16">
                      Permanent Address{" "}
                    </span>
                    <span class="font-16">
                      (if different from above or overseas address, mandatory
                      for):
                    </span>
                    <span className="underline font-16">
                      {fieldCheck(props.CustData.customer_profile.address)}
                    </span>
                  </div>
                </div>
                <div className="flex-row flex-wrap gap-4">
                  <div className="font-bold font-16">
                    City / Town / Village :
                  </div>
                  <div className="underline font-16">
                    {" " +
                      fieldCheck(
                        props.CustData.customer_profile
                          .permanant_adr_city_town_village
                      ) +
                      " "}
                  </div>
                  <div className="font-bold font-16">Pin Code :</div>
                  <div className="underline font-16">
                    {fieldCheck(props.CustData.customer_profile.pincode)}
                  </div>
                  <div className="serial_number font-16"></div>
                  <div className="font-bold font-16">State :</div>
                  <div className="underline font-16">
                    {" " +
                      fieldCheck(
                        props.CustData.customer_profile.permanant_adr_state
                      ) +
                      " "}
                  </div>
                  <div className="font-bold font-16">Country :</div>
                  <div className="underline font-16">
                    {" " +
                      fieldCheck(
                        props.CustData.customer_profile.permanant_adr_country
                      ) +
                      " "}
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- 5 --> */}
            <div className="flex-row">
              <div className="serial_number font-16">5.</div>
              <div className="flex-row">
                <div className="font-bold font-16">
                  Specify the proof of address submitted for permanent address :
                </div>
                <div className="underline font-16">
                  {" " +
                    fieldCheck(
                      props.CustData.customer_profile
                        .specify_permanant_address_proof
                    ) +
                    " "}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- C. OTHER DETAILS --> */}
        <div className="">
          <div className="section-header">C. OTHER DETAILS</div>
          <div className="py-2 flex-column gap-1">
            <div className="flex-row">
              <div className="serial_number font-16">1.</div>
              <div className="">
                <span className="font-bold pr-2 font-16">
                  Gross Annual Income Details{" "}
                </span>
                <span class="font-16">
                  (please specify) : Income range per annum : Below Rs 1 Lac /
                  1-5 Lac / 5-10 Lac / 10-25 Lac / 25 Lac :
                </span>
                <span className="underline font-16">
                  {fieldCheck(props.CustData.customer_profile.annual_income)}
                </span>
                <strong className="px-10 font-16">or</strong>{" "}
                <span class="font-16">Net-worth as on (date)</span>
                <span className="underline font-16">……………………</span>
                <span class="font-16">
                  (Net worth should not be older than 1 year)
                </span>
              </div>
            </div>
            <div className="flex-row">
              <div className="serial_number font-16">2.</div>
              <div className="">
                <span className="font-bold pr-2 font-16">Occupation</span>
                <span class="font-16">
                  (please tick any one and give brief details) : Private Sector
                  / Public Sector / Government Sector / Business / Professional
                  / Agriculturist / Retired / Housewife / Student / Others :
                </span>
                <span className="underline font-16">
                  {" " +
                    fieldCheck(props.CustData.customer_profile.occupation) +
                    " "}
                </span>
              </div>
            </div>
            <div className="flex-row">
              <div className="serial_number font-16">3.</div>
              <div className="">
                <span className="font-bold pr-2 font-16">
                  Please tick any, if applicable
                </span>{" "}
                <span class="font-16">
                  : Income range per annum : Politically Exposed Person (PEP)/
                  Related to a Politically Exposed Person (RPEP)/ No :
                </span>
                <span className="underline font-16">
                  {fieldCheck(
                    props.CustData.customer_profile.politically_exposed
                  )}
                </span>
              </div>
            </div>
            <div className="flex-row">
              <span className="serial_number font-16">4.</span>
              <span className="font-bold pr-2 font-16">
                {" "}
                Any other information :{" "}
              </span>
              <span className="underline font-16">
                _______________________________________________________________________
              </span>
            </div>
          </div>
        </div>

        {/* <!-- DECLARATION --> */}
        <div className="">
          <div className="section-header">DECLARATION</div>
          <div class="pt-2">
            <div className="">
              <div className="font-16">
                I hereby declare that the details furnished above are true and
                correct to the best of my knowledge and <br />I undertake to
                inform you of any changes therein, immediately. In case any of
                the above information is found to be false or untrue or
                misleading or misinterpreting, I am aware that I may be held
                liable for it. I hereby provide my consent to download my
                information from CKYC / KRA as well and suitably amend / modify
                as required.
              </div>
            </div>
            <div className="flex-row space-between">
              <div className="signature"></div>
              <div className="date-input"></div>
            </div>
            <div className="flex-row space-between">
              <p className="font-bold font-16">Signature of the Applicant</p>
              <div className="date_div">
                <p className="">
                  <span className="font-bold font-16">Date</span> :{" "}
                  <span class="font-16">(dd/mm/yy)</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- FOR OFFICE USE ONLY --> */}
        <div className="">
          <div className="section-header text-center">FOR OFFICE USE ONLY</div>
          <div class="py-2">
            <div className="">
              <div className="row-align-center">
                <p className="checkbox"></p>
                <p class="font-16">
                  (Originals verified True copies of documents received)
                </p>
              </div>
              <div className="row-align-center">
                <p className="checkbox"></p>
                <p class="font-16">
                  (Self-Attested) Self Certified Document copies received
                </p>
              </div>
            </div>
            <div className="dotted-signature"></div>
            <div>
              <p className="font-bold font-16">
                Signature of the Authorized Signatory
              </p>
            </div>
            <div className="flex-row space-between mt-10">
              <div className="flex-row gap-10">
                <p className="font-bold font-16">Date</p>
                <p className="dotted-input"></p>
              </div>
              <div className="font-bold font-16">
                Seal/Stamp of the intermediary
              </div>
            </div>
            <div className="center mt-20 font-16">
              {`Office Address – ${getCompanyAddressData}`}
            </div>
            <div className="center  font-16">
              Contact Details – Email Id - clients@dvia.in, Mobile - 8591426656
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Print;
