import axios from "axios";
import ApiServices from "../ApiService/ApiConfig";

// const API_URL = "http://localhost:8080/api/auth/";
const API_URL = {username: 'khema Raul', password: 'Raul@11', roles: 'Super Admin'};


// class AuthService {
  export const loginService = (data) => {
    const {username, password } = data.payload;
    console.log("data.payload", data.payload);
    // console.log("API_URL",API_URL);
    // if(data.payload.username === API_URL.username && data.payload.password === API_URL.password){
    //   localStorage.setItem("user", JSON.stringify(API_URL));
    // }
    // return axios
    //   .post(API_URL + "signin", { username,password })
    //   .then((response) => {
    //     if (response.data.accessToken) {
    //       localStorage.setItem("user", JSON.stringify(response.data));
    //     }

    //     return response.data;
    //   });
    ApiServices.login({
      "email": username,
      "password": password
    }).then(res => {
      console.log("res", res);
      const data = {token : res.token, first_name : res.first_name, middle_name : res.middle_name, last_name : res.last_name, roles: res.role.role_name};
      localStorage.setItem('token', JSON.stringify(data));
      window.location.reload();
    })
  }

  export const logout = () => {
    localStorage.removeItem("user");
  }

//   register(username, email, password) {
//     return axios.post(API_URL + "signup", {
//       username,
//       email,
//       password,
//     });
//   }
// }

// export default new AuthService();


// export const registerUserService = (request) => {
//   const REGISTER_API_ENDPOINT = 'http://localhost:8080/api/auth/signup';
  
//   const parameters = {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(request.user)
//   };

//   return fetch(REGISTER_API_ENDPOINT, parameters)
//     .then(response => {
//       return response.json();
//     })
//     .then(json => {
//       return json;
//     });
// };

// export const loginUserService = (request) => {
//   const LOGIN_API_ENDPOINT = 'http://localhost:8080/api/auth/signin';

//   const parameters = {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(request.user)
//   };

//   return fetch(LOGIN_API_ENDPOINT, parameters)
//     .then(response => {
//       return response.json();
//     })
//     .then(json => {
//       return json;
//     });
// };