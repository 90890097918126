import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment, { invalid } from 'moment';
import { Link } from 'react-router-dom';
import '../form.css';
import './customerStyle.css';
import { Formik } from 'formik';

import MultiSelect from 'react-multiple-select-dropdown-lite';
import 'react-multiple-select-dropdown-lite/dist/index.css';
import 'antd/dist/antd.css';

//akshay's code
import Select from 'react-select';

import {
	Col,
	Row,
	Button,
	Form,
	FormGroup,
	Label,
	Input,
	FormText,
	CustomInput,
	Breadcrumb,
	BreadcrumbItem,
} from 'reactstrap';

import ApiServices from '../../ApiService/ApiConfig';
import { useParams } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';

// material-ui icons
import ShortTextIcon from '@material-ui/icons/ShortText';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CreateIcon from '@mui/icons-material/Create';
import EqualizerOutlinedIcon from '@material-ui/icons/EqualizerOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import LoadingSpinner from '../UI/Loader';
import { useUserInfo } from '../../ContextProviders/UserInfo';
import { useHistory } from 'react-router-dom';

//country code imports
import PhoneCode from 'react-phone-code';

import axios from 'axios';
axios.defaults.baseURL = process.env.REACT_APP_API;

const enumGenderOption = ['Male', 'Female', 'Other'];

const enumSourceOfFundOption = [
	{ source_of_fund_flow: 'Salary' },
	{ source_of_fund_flow: 'Gifts' },
	{ source_of_fund_flow: 'Inheritance' },
	{ source_of_fund_flow: 'Investment Income' },
	{ source_of_fund_flow: 'Business/Profession' },
	{ source_of_fund_flow: 'Other Sources' },
];

const getAssetClasses = (parameter) => {
	// console.log('PARAMETER', parameter);
	let splited = parameter.split('$$').map((el) => {
		return { value: el, label: el };
	});
	return splited;
};

const UpdateCustomer = (props) => {
	const history = useHistory();
	const userInfo = useUserInfo();
	const Cust_Data = props.location.state.data;
	const cust_id = Cust_Data.id;
	const fname = Cust_Data.first_name;
	const mname = Cust_Data.middle_name;
	const lname = Cust_Data.last_name;
	const emailId = Cust_Data.email;
	const phone = Cust_Data.phone;

	///broker///

	let newBrokerDet;

	if (Cust_Data.customer_profile.broker_details.length > 0) {
		newBrokerDet = Cust_Data.customer_profile.broker_details.map((item) => {
			var temp = Object.assign({});
			temp.broker_name = {label:item.broker_name, value: item.broker_name};
			temp.broker_code = item.broker_code;
			// temp.broker_email = {label : item.broker_email, value : item.broker_email};
			temp.broker_email = item.broker_email
			temp.broker_sub_asset_class = getAssetClasses(
				item.broker_sub_asset_class
			);
			temp.createdBy = userInfo.id;
			return temp;
		});
	} else {
		newBrokerDet = [
			{
				broker_name: '',
				broker_code: '',
				broker_email: '',
				broker_sub_asset_class: '',
				createdBy: userInfo.id,
			},
		];
	}

	///broker-function-end///

	let newNomineeDet;
	if (Cust_Data.customer_profile.nominee_details.length > 0) {
		newNomineeDet = Cust_Data.customer_profile.nominee_details.map(
			(item) => {
				var temp = Object.assign({});
				temp.nominee_name = item.nominee_name;
				temp.nominee_mobile_no = item.nominee_mobile_no;
				temp.nominee_relation = item.nominee_relation;
				temp.nominee_email = item.nominee_email;
				temp.nomineeAAdharPanPath = item.nomineeAAdharPanPath;
				temp.createdBy = userInfo.id;
				return temp;
			}
		);
	} else {
		newNomineeDet = [
			{
				nominee_name: '',
				nominee_mobile_no: '',
				nominee_relation: '',
				nominee_email: '',
				createdBy: userInfo.id,
			},
		];
	}

	let newBankDet;
	if (Cust_Data.customer_profile.bank_acc_details.length > 0) {
		newBankDet = Cust_Data.customer_profile.bank_acc_details.map((item) => {
			var temp = Object.assign({});
			temp.bank_name = item.bank_name;
			temp.bank_acc_no = item.bank_acc_no;
			temp.ifsc_code = item.ifsc_code;
			temp.micr_code = item.micr_code;
			temp.crn = item.crn;
			temp.customer_id = item.customer_id;
			temp.createdBy = userInfo.id;
			return temp;
		});
	} else {
		newBankDet = [
			{
				bank_name: '',
				bank_acc_no: '',
				ifsc_code: '',
				micr_code: '',
				crn: '',
				customer_id: '',
				createdBy: userInfo.id,
			},
		];
	}

	let newSecuritiesDet;
	if (Cust_Data.customer_profile.demat_acc_details.length > 0) {
		newSecuritiesDet = Cust_Data.customer_profile.demat_acc_details.map(
			(item) => {
				var temp = Object.assign({});
				temp.dp_id = item.dp_id;
				temp.dp_name = item.dp_name;
				temp.client_code = item.client_code;
				temp.user_id = item.user_id;
				temp.createdBy = userInfo.id;
				return temp;
			}
		);
	} else {
		newSecuritiesDet = [
			{
				dp_id: '',
				dp_name: '',
				client_code: '',
				user_id: '',
				createdBy: userInfo.id,
			},
		];
	}


	const source_of_fund = Cust_Data.customer_profile.source_of_fund_flow;
	const newSourceOfFundField = enumSourceOfFundOption.map((ele) => ({
		source_of_fund_flow: ele.source_of_fund_flow,
		checked: source_of_fund.some(
			(NewEle) => NewEle.source_of_fund_flow === ele.source_of_fund_flow
		),
	}));

	//countycode state
	const [countyCode, setCountyCode] = useState(
		Cust_Data.customer_profile.country_code
	);


	// name and other-info state
	const [profileData, setProfileData] = useState();
	const [firstname, setFirstname] = useState(fname);
	const [middleName, setMiddleName] = useState(mname);
	const [lastName, setLastName] = useState(lname);
	const [dob, setDob] = useState(
		moment(Cust_Data.customer_profile.dob).format('YYYY-MM-DD')
	);
	const [dataArray, setDataArray] = useState([]);
	const [occupation, setOccupation] = useState(
		Cust_Data.customer_profile.occupation
	);
	const [residental_status, setResidental_status] = useState(
		Cust_Data.customer_profile.residental_status
	);
	const [designation, setDesignation] = useState(
		Cust_Data.customer_profile.designation
	);
	const [organization, setOrganization] = useState(
		Cust_Data.customer_profile.organization_name
	);
	const [mobileNumber, setMobileNumber] = useState(phone);
	const [whatsAppNum, setWhatsAppNum] = useState(
		Cust_Data.customer_profile.whatsapp_no
	);
	const [email, setEmail] = useState(emailId);
	const [emailVerificationStatus, setEmailVerificationStatus] = useState(
		Cust_Data.cust_email_verified_status !== null
			? Cust_Data.cust_email_verified_status
			: 'Unverified'
	);
	const [phoneVerificationStatus, setPhoneVerificationStatus] = useState(
		Cust_Data.cust_phone_verified_status !== null
			? Cust_Data.cust_phone_verified_status
			: 'Unverified'
	);
	const [personal_email, setPersonal_email] = useState(
		Cust_Data.customer_profile.personal_email
	);
	const [fathers_spouse_name, setFathers_spouse_name] = useState(
		Cust_Data.customer_profile.fathers_spouse_name
	);
	const [emailPassword, setEmailPassword] = useState(Cust_Data.password);
	const [motherName, setMotherName] = useState(
		Cust_Data.customer_profile.mothers_maiden_name
	);
	const [proof_of_identity_submitted, setProof_of_identity_submitted] =
		useState(Cust_Data.customer_profile.proof_of_identity_submitted);

	const [
		specify_permanant_address_proof,
		setSpecify_permanant_address_proof,
	] = useState(Cust_Data.customer_profile.specify_permanant_address_proof);
	const [
		permanant_adr_city_town_village,
		setPermanant_adr_city_town_village,
	] = useState(Cust_Data.customer_profile.permanant_adr_city_town_village);
	const [permanant_adr_state, setPermanant_adr_state] = useState(
		Cust_Data.customer_profile.permanant_adr_state
	);
	const [permanant_adr_country, setPermanant_adr_country] = useState(
		Cust_Data.customer_profile.permanant_adr_country
	);

	const [brokerDataLength, setBrokerDataLength] = useState(false);

	// gender state
	const [gender, setGender] = useState(Cust_Data.customer_profile.gender);
	// documents and address state
	const [nationality, setNationality] = useState(
		Cust_Data.customer_profile.nationality
	);
	const [pan, setPan] = useState(Cust_Data.customer_profile.pan_no);
	const [adhar, setAdhar] = useState(Cust_Data.customer_profile.aadhar_no);
	const [address, setAddress] = useState(Cust_Data.customer_profile.address);
	const [pincode, setPincode] = useState(Cust_Data.customer_profile.pincode);

	// commu address (correspondence address) states
	const [commAddress, setCommAddress] = useState(
		Cust_Data.customer_profile.communication_address
	);
	const [commAddressChecked, setCommAddressChecked] = useState(false);
	const [whatsAppNumChecked, setWhatsAppNumChecked] = useState(false);
	const [commPincode, setCommPincode] = useState(
		Cust_Data.customer_profile.communicationAddressPincode
	);
	const [
		adr_correspondence_city_town_village,
		setAdr_correspondence_city_town_village,
	] = useState(
		Cust_Data.customer_profile.adr_correspondence_city_town_village
	);
	const [adr_correspondence_state, setAdr_correspondence_state] = useState(
		Cust_Data.customer_profile.adr_correspondence_state
	);
	const [adr_correspondence_country, setAdr_correspondence_country] =
		useState(Cust_Data.customer_profile.adr_correspondence_country);
	const [
		specify_correspondence_address_proof,
		setSpecify_correspondence_address_proof,
	] = useState(
		Cust_Data.customer_profile.specify_correspondence_address_proof
	);

	const [bankDetails, setBankDetails] = useState(newBankDet);
	const [securitiesAccount, setSecuritiesAccount] =
		useState(newSecuritiesDet);


	// Nominee state
	const [nomineeDetails, setNomineeDetails] = useState(newNomineeDet);

	// Broker state
	const [brokerDetails, setBrokerDetails] = useState(newBrokerDet);

	// Source of Fund Flow details state
	const [incsSource, setIncsSource] = useState(
		newSourceOfFundField || enumSourceOfFundOption
	);

	// Annual Income Range state
	const [incomeRang, setIncomeRang] = useState(
		Cust_Data.customer_profile.annual_income
	);
	// other-info state
	const [birthPlace, setBirthPlace] = useState(
		Cust_Data.customer_profile.birth_place
	);
	const [highestEdu, setHighestEdu] = useState(
		Cust_Data.customer_profile.highest_education
	);
	// weather politically exposed state
	const [weatherPoliticallyExp, setWeatherPoliticallyExp] = useState(
		Cust_Data.customer_profile.politically_exposed
	);
	// marriage state
	const [marriageStatus, setMarriageStatus] = useState(
		Cust_Data.customer_profile.marital_status
	);
	const [dateOfMariage, setDateOfMariage] = useState(
		moment(Cust_Data.customer_profile.date_of_marriage).format('YYYY-MM-DD')
	);

	var commAddressPhotoPath;
	var nomineeAadharPanPath;
	var passPortPhotoPath;
	var panFilePath;
	var adharFilePath;
	var provAccountCanCheckPath;
	var provAccountTwoCanCheckPath;
	//myaddition
	var someFileCanCheckPath;



	const [commAddressPath, setCommAddressPath] = useState(
		Cust_Data.customer_profile.communication_address_proof_path
	);
	const [commAddressCanCheck, setCommAddressCanCheck] = useState();

	const [nomineeProof, setNomineeProof] = useState('');
	const [passPath, setPassPath] = useState(
		Cust_Data.customer_profile.passport_photo_path
	);
	const [panPath, setPanPath] = useState(
		Cust_Data.customer_profile.pan_file_path
	);
	const [adharPath, setAdharPath] = useState(
		Cust_Data.customer_profile.adhar_file_path
	);
	const [provAcPath, setProvAcPath] = useState(
		Cust_Data.customer_profile.prov_account_file_path
	);

	const [provAcTwoPath, setProvAcTwoPath] = useState(
		Cust_Data.customer_profile.prov_account_file_path_two
	);

	//my addition
	const [someFilePath, setSomeFilePath] = useState(
		Cust_Data.customer_profile.some_file_path
	);

	// file upload-image prev states
	const [passPortPhoto, setPassPortPhoto] = useState();
	const [panFile, setPanFile] = useState();
	const [adharFile, setAdharFile] = useState();
	const [provAccountCanCheck, setProvAccountCanCheck] = useState();
	const [provAccountTwoCanCheck, setProvAccountTwoCanCheck] = useState();
	//my addition
	const [someFileCanCheck, setSomeFileCanCheck] = useState();

	// other
	const [username, setUserName] = useState(phone); //username
	const [isLoading, setIsLoading] = useState(false);
	const [defaultDisabled, setDefaultDisabled] = useState(false);

	//other-contact details
	const [tel_off, setTel_off] = useState(Cust_Data.customer_profile.tel_off);
	const [tel_res, setTel_res] = useState(Cust_Data.customer_profile.tel_res);
	const [fax, setFax] = useState(Cust_Data.customer_profile.fax);
	const [WhatsappOptInStatus, setWhatsappOptInStatus] = useState(Cust_Data.OPTInWhatsappStatus === "true" ? true : false);

	useEffect(() => {
		ApiServices.listCustomer().then((res) => {
			const fa = res.filter((el) => {
				return el.first_name + "" + el.middle_name + "" + el.last_name !== fname + "" + mname + "" + lname
			})
			const filterData = fa.map((el) => {
				return el.first_name.slice(0, 1) + "" + el.middle_name.slice(0, 1) + "" + el.last_name.slice(0, 1) + "" + el.customer_profile.pan_no
			})
			setDataArray(filterData)
		})
		.catch((err) => {
			console.log(err);
		});
	}, []);

	const { id: id } = useParams();

	// prev apiservices code was here

	const onChangeCheckboxVal = (e) => {
		setCommAddressChecked(e.target.checked);
		if (e.target.checked === true) {
			setCommAddress(address);
			setCommPincode(pincode);
			setAdr_correspondence_city_town_village(
				permanant_adr_city_town_village
			);

			setAdr_correspondence_state(permanant_adr_state);
			setAdr_correspondence_country(permanant_adr_country);
		} else {
			setCommAddress('');
			setCommPincode('');
		}
	};


	const onChangeCheckboxMobNoVal = (e) => {
		setWhatsAppNumChecked(e.target.checked);
		if (e.target.checked === true) {
			setWhatsAppNum('' + countyCode + mobileNumber);
		} else {
			setWhatsAppNum('');
		}
	};

	//communication address
	const commAddressProofHandler = async (e) => {
		if (e.target.files[0].size > 500000) {
			toast.warning(`File size should be less than 500KB.`);
		} else {
			const file = e.target.files[0];
			const bodyFormData = new FormData();
			bodyFormData.append('image', file);
			try {
				const { data } = await axios.post(`file/create`, bodyFormData, {
					headers: { 'Content-Type': 'multipart/form-data' },
				});

				commAddressPhotoPath = data.res;
				setCommAddressPath(commAddressPhotoPath);
				setCommAddressCanCheck(
					`${process.env.REACT_APP_URL_API}/${commAddressPhotoPath}`
				);
				//  setPassPortPhoto(`${process.env.REACT_APP_URL_API}/${passPortPhotoPath}`)
			} catch (error) {
				toast.warning(`File should have pdf or jpeg format.`);
			}
		}
	};

	const nomineeAadharPanHandler = async (e, index) => {
		if (e.target.files[0].size > 500000) {
			toast.warning(`File size should be less than 500KB.`);
		} else {
			const file = e.target.files[0];
			const bodyFormData = new FormData();
			bodyFormData.append('image', file);
			try {
				const { data } = await axios.post(`file/create`, bodyFormData, {
					headers: { 'Content-Type': 'multipart/form-data' },
				});
				console.log(data.res);
				nomineeAadharPanPath = data.res;
				const { name, value } = e.target;
				const list = [...nomineeDetails];
				list[index][name] = data.res;
				setNomineeProof(nomineeAadharPanPath);
				//  setPassPortPhoto(`${process.env.REACT_APP_URL_API}/${nomineeAadharPanPath}`)
			} catch (error) {
				console.log(error.message);
				toast.warning(`File should have pdf or jpeg format.`);
			}
		}
	};

	// file upload function for Passport Size Photo-image/file
	const passPortPhotoHandeler = async (e) => {
		if (e.target.files[0].size > 500000) {
			toast.warning(`File size should be less than 500KB.`);
		} else {
			const file = e.target.files[0];
			const bodyFormData = new FormData();
			bodyFormData.append('image', file);
			try {
				const { data } = await axios.post('file/create', bodyFormData, {
					headers: { 'Content-Type': 'multipart/form-data' },
				});
				console.log(data.res);
				passPortPhotoPath = data.res;
				setPassPath(passPortPhotoPath);
				setPassPortPhoto(
					`${process.env.REACT_APP_URL_API}/${passPortPhotoPath}`
				);
			} catch (error) {
				console.log(error.message);
				toast.warning(`File should have pdf or jpeg format.`);
			}
		}
	};

	// file upload function for Pan-card image/file
	const panCardFileUploadHandler = async (e) => {
		if (e.target.files[0].size > 500000) {
			toast.warning(`File size should be less than 500KB.`);
		} else {
			const file = e.target.files[0];
			const bodyFormData = new FormData();
			bodyFormData.append('image', file);
			try {
				const { data } = await axios.post('file/create', bodyFormData, {
					headers: { 'Content-Type': 'multipart/form-data' },
				});
				console.log(data.res);
				panFilePath = data.res;
				setPanPath(panFilePath);
				setPanFile(`${process.env.REACT_APP_URL_API}/${panFilePath}`);
			} catch (error) {
				console.log(error.message);
				toast.warning(`File should have pdf or jpeg format.`);
			}
		}
	};

	// file upload function for Adhar-card-front and Back image/file
	const adharCardUploadFileHandler = async (e) => {
		if (e.target.files[0].size > 500000) {
			toast.warning(`File size should be less than 500KB.`);
		} else {
			const file = e.target.files[0];
			const bodyFormData = new FormData();
			bodyFormData.append('image', file);
			try {
				const { data } = await axios.post('file/create', bodyFormData, {
					headers: { 'Content-Type': 'multipart/form-data' },
				});
				console.log(data.res);
				adharFilePath = data.res;
				setAdharPath(adharFilePath);
				setAdharFile(
					`${process.env.REACT_APP_URL_API}/${adharFilePath}`
				);
			} catch (error) {
				console.log(error.message);
				toast.warning(`File should have pdf or jpeg format.`);
			}
		}
	};

	// file upload function for Cancelled Cheque of Provided Account-image/file
	const proviAccountUploadFileHandler = async (e) => {
		if (e.target.files[0].size > 500000) {
			toast.warning(`File size should be less than 500KB.`);
		} else {
			const file = e.target.files[0];
			const bodyFormData = new FormData();
			bodyFormData.append('image', file);
			try {
				const { data } = await axios.post('file/create', bodyFormData, {
					headers: { 'Content-Type': 'multipart/form-data' },
				});
				console.log(data.res);
				provAccountCanCheckPath = data.res;
				setProvAcPath(provAccountCanCheckPath);
				setProvAccountCanCheck(
					`${process.env.REACT_APP_URL_API}/${provAccountCanCheckPath}`
				);
			} catch (error) {
				console.log(error.message);
				toast.warning(`File should have pdf or jpeg format.`);
			}
		}
	};

	// file upload function for Cancelled Cheque of Provided Account-image/file
	const proviAccountTwoUploadFileHandler = async (e) => {
		if (e.target.files[0].size > 500000) {
			toast.warning(`File size should be less than 500KB.`);
		} else {
			const file = e.target.files[0];
			const bodyFormData = new FormData();
			bodyFormData.append('image', file);
			try {
				const { data } = await axios.post('file/create', bodyFormData, {
					headers: { 'Content-Type': 'multipart/form-data' },
				});
				console.log(data.res);
				provAccountTwoCanCheckPath = data.res;
				setProvAcTwoPath(provAccountTwoCanCheckPath);
				setProvAccountTwoCanCheck(
					`${process.env.REACT_APP_URL_API}/${provAccountTwoCanCheckPath}`
				);
			} catch (error) {
				console.log(error.message);
				toast.warning(`File should have pdf or jpeg format.`);
			}
		}
	};

	const SomeFileToBeUploaded = async (e) => {
		if (e.target.files[0].size > 500000) {
			toast.warning(`File size should be less than 500KB.`);
		} else {
			const file = e.target.files[0];
			const bodyFormData = new FormData();
			bodyFormData.append('image', file);
			try {
				const { data } = await axios.post('file/create', bodyFormData, {
					headers: { 'Content-Type': 'multipart/form-data' },
				});
				console.log('WHAT YOU ADDED', data);
				someFileCanCheckPath = data.res;
				setSomeFilePath(someFileCanCheckPath);
				setSomeFileCanCheck(
					`${process.env.REACT_APP_URL_API}/${someFileCanCheckPath}`
				);
			} catch (error) {
				console.log(error.message);
				toast.warning(`File should have pdf or jpeg format.`);
			}
		}
	};

	const onClickSendOTP = () => {
		console.log("inside onClickSendOTP");
		console.log("whatsAppNum >>",whatsAppNum);
		console.log("emailVerificationStatus>> ",emailVerificationStatus);
		console.log("phoneVerificationStatus>> ",phoneVerificationStatus);

		ApiServices.sendOTP(cust_id, {
			whatsapp_no: whatsAppNum,
			cust_email_verified_status: emailVerificationStatus,
			cust_phone_verified_status: phoneVerificationStatus,
		})
			.then((res) => {
				console.log("response for WhatsApp and Email ",res);
				setIsLoading(false);
				toast.success(`${res.message}`);
			})
			.catch((err) => {
				console.log(err);
				if (err.message === 'Request failed with status code 406') {
					toast.warning(
						`Duplicate entries please enter unique inputs.`
					);
				} else {
					toast.warning(`${err}`);
				}
				setIsLoading(false);
			});
	};

	const onBankDetChange = (index, e) => {
		console.log(e.target.value);
		const { name, value } = e.target;
		const list = [...bankDetails];
		list[index][name] = value;
		setBankDetails(list);
	};

	const onValueBroChange = (index, e = '', isMultiSelectEvent = false, name) => {
		if (isMultiSelectEvent) {
			const list = [...brokerDetails];
			list[index][name] = e;
			if(name === 'broker_name'){
				list[index] = {...list[index], broker_sub_asset_class : []}

				//filter condition to get mails as per broker
				const selectedBroker=brokerResult.brokers.filter(el=>el.broker_house===e.value)[0];
				const selectedBrokerModified=selectedBroker?.mail?.map(el=>({label:el.email,value:el.email}))
				list[index]['broker_email'] = list[index].broker_name.id
				setBrokerDetails(list)

			}else{
				setBrokerDetails(list);
			}
		} else {
			const { name, value } = e.target;
			const list = [...brokerDetails];
			list[index][name] = value;

			setBrokerDetails(list);
		}
	};

	const onMultiChoiceDropdownChange = (index, data, x, name) => {
		onValueBroChange(index, data, true, name);
	};

	const onValueChange = (index, e) => {
		const { name, value } = e.target;
		const list = [...nomineeDetails];
		if (e.target.name === 'nomineeAAdharPanPath') {
			// t(1)aler
			nomineeAadharPanHandler(e, index);
		}
		list[index][name] = value;

		setNomineeDetails(list);
	};

	const addBankDet = (e) => {
		console.log('clicked', e);
		setBankDetails([
			...bankDetails,
			{
				bank_name: '',
				bank_acc_no: '',
				ifsc_code: '',
				micr_code: '',
				crn: '',
				customer_id: '',
				createdBy: userInfo.id,
			},
		]);
	};

	const removeBankDet = (index) => {
		const list = [...bankDetails];
		list.splice(index, 1);
		setBankDetails(list);
	};

	const onSecuritiesAccChange = (index, e) => {
		const { name, value } = e.target;
		const list = [...securitiesAccount];
		list[index][name] = value;
		setSecuritiesAccount(list);
	};

	const addSecuritiesAccDet = (e) => {
		console.log('clicked', e);
		setSecuritiesAccount([
			...securitiesAccount,
			{
				dp_id: '',
				dp_name: '',
				client_code: '',
				user_id: '',
				createdBy: userInfo.id,
			},
		]);
	};

	const removeSecuritiesAccDet = (index) => {
		const list = [...securitiesAccount];
		list.splice(index, 1);
		setSecuritiesAccount(list);
	};

	const addNominee = (e) => {
		console.log('clicked');
		setNomineeDetails([
			...nomineeDetails,
			{
				nominee_name: '',
				nominee_mobile_no: '',
				nominee_relation: '',
				nominee_email: '',
				createdBy: userInfo.id,
			},
		]);
	};

	const removeNominee = (index) => {
		const list = [...nomineeDetails];
		list.splice(index, 1);
		setNomineeDetails(list);
	};

	//

	const addBroker = (e) => {
		console.log('clicked');
		setBrokerDetails([
			...brokerDetails,
			{
				broker_name: '',
				broker_code: '',
				broker_sub_asset_class: '',
				broker_email: '',
				createdBy: userInfo.id,
			},
		]);
	};

	const removeBroker = (index) => {
		const list = [...brokerDetails];
		list.splice(index, 1);
		setBrokerDetails(list);
	};


	const MultiselectCheckbox = ({ options, onChange }) => {
		const toggle = (index) => {
			const newData = [...incsSource];
			newData.splice(index, 1, {
				source_of_fund_flow: incsSource[index].source_of_fund_flow,
				checked: !incsSource[index].checked,
			});
			setIncsSource(newData);
			onChange(newData.filter((x) => x.checked));
			console.log('incssource', incsSource);
		};

		return (
			<>
				{incsSource.map((item, index) => (
					<label
						key={item.source_of_fund_flow}
						style={{ paddingLeft: '25px' }}
					>
						<input
							readOnly
							type="checkbox"
							checked={item.checked || false}
							onClick={() => toggle(index)}
							style={{ paddingLeft: '10px' }}
						/>
						<span> </span>
						{item.source_of_fund_flow}
					</label>
				))}
			</>
		);
	};

	const [message, setMessage] = useState('');
	// const [SACList, setSACList] = useState([]);
	// const [brokers, setBrokers] = useState([])
	const [originalList, setOriginalList] = useState([])
	const [brokerSACList, setBrokerSACList] = useState({})
	const [brokerMails, setBrokerMails] = useState({})
	const [defaultBroker,setDefaultBroker]=useState([])
	const [brokerResult,setBrokerResult]=useState([]);

	// const reduceBrokers = (index)=>{
	// 	let selectedBros = brokerDetails.map(el=>el.broker_name.value)
	// 	let filtered = []
	// 	originalList.forEach(el=>{
	// 		if(!selectedBros.includes(el.value)) filtered.push(el)
	// 	})
	// 	setBrokers(filtered)
	// 	let temp = [...brokerDetails]
	// 	temp[index] = {...temp[index], broker_sub_asset_class : []}
	// 	setBrokerDetails(temp)
	// }

	const getAppropriateBrokers = () => {
		let selectedBros = brokerDetails.map(el=>el.broker_name.value)
		let filtered = []
		originalList.forEach(el=>{
			if(!selectedBros.includes(el.value)) filtered.push(el)
		})
		return filtered;
	}

	const getAppropriateSAC = (brokerHouse) => {
		let reference = [] 
		brokerDetails.forEach(broker=>{
			let {broker_sub_asset_class} = broker
			reference = [...reference, ...broker_sub_asset_class]
		})
		let modified = reference.map(el=>el.value)
		let toReturn = []
		if(brokerSACList[brokerHouse] !== undefined){
			brokerSACList[brokerHouse].forEach(el=>{
				if(!modified.includes(el.value)) toReturn.push(el)
			})
		}
		return toReturn;
	}


	useEffect(() => {

		ApiServices.GetBrokersList()
				.then(
					res => {
						let modifiedSAC = {}
						let modifiedBrokers = [] 
						let modifiedMails = {}
						let multioptionsMails
						setBrokerResult(res);
						console.log('RESPONSE BROKER LIST', res);
						res.brokers.forEach(el=>{
							let {sub_asset_classes, broker_house, mail, id} = el
							let multioptionsSAC = sub_asset_classes.map(el=>({label:el.name, value: el.name}))
							multioptionsMails = mail.map(el=>({label:el.email, value: el.email}))
							modifiedSAC[el.broker_house] = [...multioptionsSAC]
							modifiedMails[el.broker_house] = [...multioptionsMails]
							modifiedBrokers.push({label:broker_house, value: broker_house, id})
						})
						setBrokerSACList(modifiedSAC)
						setBrokerMails(modifiedMails)
						setDefaultBroker(multioptionsMails);
						setOriginalList(modifiedBrokers)

					}
				)
	}, []);

	const handleDateOfBirth = (dob) => {
		let pureDate = new Date().toISOString().split('T')[0];
 		let dateSelected = new Date(dob).toISOString();
 		let dateToday = new Date(pureDate).toISOString();

 		if (dateSelected >= dateToday) {
			setMessage('invalid date');
			setDob('');
 		} else {
			setMessage('');
			setDob(dob);
 		}
	};
	
	return (
		<>
			<div className="container" id="adjust-top">
				<Breadcrumb>
					<BreadcrumbItem>
						<Link to={{pathname: `/customer`}}>Customers</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>Update Customer</BreadcrumbItem>
				</Breadcrumb>
				<div className="container-fluid bg-light text-center">
					<h3 className="pl-3 pr-3 pt-3 pb-3">Update Customer</h3>
				</div>
				
				<div>
					<Formik
						initialValues={{
							// firstName: fname, middleName: mname, lastName: lname,
							firstName: fname,
							lastName: lname,
							// address: Cust_Data.customer_profile.address, pincode: Cust_Data.customer_profile.pincode,
							mobileNumber: phone,
							whatsAppNum: Cust_Data.customer_profile.whatsapp_no,
							email: emailId,
							designation: Cust_Data.customer_profile.designation,
							organization:
								Cust_Data.customer_profile.organization_name,
							// motherName:
							// 	Cust_Data.customer_profile.mothers_maiden_name,
							nationality: Cust_Data.customer_profile.nationality,
							pan: Cust_Data.customer_profile.pan_no,
							adhar: Cust_Data.customer_profile.aadhar_no,
						}}
						validate={(values) => {
							const errors = {};

							// firstname
							if (!values.firstName) {
								errors.firstName = 'Required';
							} else if (!/^[A-Za-z]+/i.test(values.firstName)) {
								errors.firstName = 'Field contain only letters';
							}

							// lastName
							if (!values.lastName) {
								errors.lastName = 'Required';
							} else if (!/^[A-Za-z]+/i.test(values.lastName)) {
								errors.lastName = 'Field contain only letters';
							}

							// email
							if (!values.email) {
								errors.email = 'Required';
							} else if (
								!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
									values.email
								)
							) {
								errors.email = 'Invalid email address';
							}


							// designation
							// if (!values.designation) {
							// 	errors.designation = 'Required';
							// } else if (
							// 	!/^[A-Za-z]+/i.test(values.designation)
							// ) {
							// 	errors.designation =
							// 		'Field contain only letters';
							// }

							/**
							removed mandotory field of designation
							 */
							if (values.designation!=="" && !/^[A-Za-z]+/i.test(values.designation)) {
								errors.designation ='Field contain only letters';
							}

							// organization
							// if (!values.organization) {
							// 	errors.organization = 'Required';
							// } else if (
							// 	!/^[A-Za-z]+/i.test(values.organization)
							// ) {
							// 	errors.organization =
							// 		'Field contain only letters';
							// }

							/**
							removed mandotory field of organization
							 */
							if (values.organization!=="" && !/^[A-Za-z]+/i.test(values.organization)) {
								errors.organization ='Field contain only letters';
							}


							// nationality
							if (!values.nationality) {
								errors.nationality = 'Required';
							} else if (
								!/^[A-Za-z]+/i.test(values.nationality)
							) {
								errors.nationality =
									'Field contain only letters';
							}

							// pan
							if (!values.pan) {
								errors.pan = 'Required';
							}

							// adhar
							if (!values.adhar) {
								errors.adhar = 'Required';
							} else if (
								!/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(
									values.adhar
								)
							) {
								errors.adhar =
									'adhar number should have 12 Digits only';
							}

							//
							return errors;
						}}
						onSubmit={(values, { setSubmitting }) => {
                            
						if(!brokerDataLength){
							return toast.warning("Please select atleat one sub-asset class for broker");
						}
							let a = values.firstName.slice(0, 1) + "" + middleName.slice(0, 1) + "" + 
							values.lastName.slice(0, 1) + values.pan.toString()
                            let b = "Initials should not same for first and last name"
							if(dataArray.includes(a)){
								return toast.warning(`${b}`);
							} 
							else{
							setSubmitting(false);
							setIsLoading(true);
							setIsLoading(true);

							const newDob = new Date(dob);
							let newdateOfMariage;
							if (
								dateOfMariage === 'Invalid date' ||
								dateOfMariage === undefined ||
								dateOfMariage === ''
							) {
								newdateOfMariage = null;
							} else {
								const newdate = new Date(dateOfMariage);
								newdateOfMariage = newdate.toISOString();
							}

							const incSArrOfObj = incsSource
								.filter((item) => {
									return item.checked === true;
								})
								.map((a) => {
									var temp = Object.assign({});
									temp.source_of_fund_flow =
										a.source_of_fund_flow;
									temp.createdBy = userInfo.id;
									return temp;
								});

							console.log('ON SUBMIT Called', brokerDetails);

							let brokerDetailsModified = brokerDetails.map(
								(broker) => {
									return {
										// ...broker,
										broker_code : broker.broker_code,
										broker_name : broker.broker_name.value,
										// broker_email : broker.broker_email.value,
										broker_email: broker.broker_email.toString(),
										broker_sub_asset_class:
											broker.broker_sub_asset_class
												.map((asset) => asset.value)
												.join('$$'),
												createdBy : broker.createdBy
									};
								}
							);

							console.log('BROKER DETAILS', brokerDetailsModified);
							
							ApiServices.updateCustomer(cust_id, {
								first_name: values.firstName,
								middle_name: middleName,
								last_name: values.lastName,
								passport_photo_path: passPath,
								pan_file_path: panPath,
								adhar_file_path: adharPath,
								prov_account_file_path: provAcPath,
								prov_account_file_path_two: provAcTwoPath,
								some_file_path: someFilePath,
								phone: mobileNumber,
								country_code: countyCode,
								email: values.email,
								username: values.adhar,
								dob: newDob,
								tel_off: tel_off,
								tel_res: tel_res,
								fax: fax,
								gender: gender,
								occupation: occupation,
								adr_correspondence_city_town_village:
									adr_correspondence_city_town_village,
								adr_correspondence_state:
									adr_correspondence_state,
								adr_correspondence_country:
									adr_correspondence_country,
								specify_correspondence_address_proof:
									specify_correspondence_address_proof,
								residental_status: residental_status,
								address: address,
								permanant_adr_city_town_village:
									permanant_adr_city_town_village,
								permanant_adr_state: permanant_adr_state,
								permanant_adr_country: permanant_adr_country,
								specify_permanant_address_proof:
									specify_permanant_address_proof,
								pincode: parseInt(pincode),
								communication_address: commAddress,
								communicationAddressPincode:
									parseInt(commPincode),
								communication_address_proof_path:
									commAddressPath,
								password: emailPassword,
								designation: values.designation,
								organization_name: values.organization,
								mothers_maiden_name: motherName,
								whatsapp_no: whatsAppNum,
								email: values.email,
								cust_email_verified_status:
									emailVerificationStatus,
								cust_phone_verified_status:
									phoneVerificationStatus,
								nationality: values.nationality,
								pan_no: values.pan,
								personal_email: personal_email,
								fathers_spouse_name: fathers_spouse_name,
								aadhar_no: values.adhar,
								proof_of_identity_submitted:
									proof_of_identity_submitted,
								bank_acc_details: bankDetails,
								demat_acc_details: securitiesAccount,
								// "trading_acc_details": tradingAccount,
								source_of_fund_flow: incSArrOfObj,
								nominee_details: nomineeDetails,
								broker_details: brokerDetailsModified,
								annual_income: incomeRang,
								birth_place: birthPlace,
								politically_exposed: weatherPoliticallyExp,
								highest_education: highestEdu,
								marital_status: marriageStatus,
								date_of_marriage: newdateOfMariage,
								user_id: cust_id,
							})
								.then((res) => {
									setIsLoading(false);
									if (res.code === 'P2002') {
										toast.warning(res.message);
									} else {
										props.history.push('/customer');
										toast.success(`${res.message}`);
									}
								})
								.catch((err) => {
									toast.warning(`${err}`);
									setIsLoading(false);
								});
							 }

							
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							/* and other goodies */
						}) => (
							<Form onSubmit={handleSubmit}>
								<Row form>
									<Col md={12}>
										<br />
										<FormGroup className="shadow-none rounded">
											<h5
												className="pl-1 pr-1 pt-1 pb-1"
												style={{
													width: '100%',
													textAlign: 'center',
													backgroundColor: '#eff6f9',
												}}
											>
												Personal Details
											</h5>
										</FormGroup>
									</Col>
									<Col md={4}>
										<FormGroup>
											<Label className="required">
												<ShortTextIcon /> First Name
											</Label>
											<Input
												type="text"
												name="firstName"
												min="2"
												className="form-control"
												placeholder="e.g. : Akshay"
												pattern="[A-Za-z]{2,15}"
												title="FirstName should only contain letters.and only have single word e.g. john"
												// value={firstname}
												// onChange={(e) => setFirstname(e.target.value)}
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.firstName}
												disabled={defaultDisabled}
												required
											/>
											<p style={{ color: 'red' }}>
												{errors.firstName}
											</p>
											<p style={{ color: 'red' }}>
												{touched.firstName}
											</p>
										</FormGroup>
									</Col>
									<Col md={4}>
										<FormGroup>
											<Label>
												<ShortTextIcon /> Middle Name
											</Label>
											<Input
												type="text"
												name="middleName"
												min="2"
												className="form-control"
												placeholder="e.g. : Mukesh"
												// pattern="[A-Za-z]{2,15}"
												// title="MiddleName should only contain letters.and only have single word e.g. john"
												value={middleName}
												onChange={(e) =>
													setMiddleName(
														e.target.value
													)
												}
												// onChange={handleChange}
												// onBlur={handleBlur}
												disabled={defaultDisabled}
											/>
											{/* <p style={{color: 'red'}}>{errors.middleName}</p>
            <p style={{color: 'red'}}>{touched.middleName}</p> */}
										</FormGroup>
									</Col>
									<Col md={4}>
										<FormGroup>
											<Label className="required">
												<ShortTextIcon /> Last Name
											</Label>
											<Input
												type="text"
												name="lastName"
												min="2"
												className="form-control"
												placeholder="e.g. : Sharma"
												pattern="[A-Za-z]{2,15}"
												title="LastName should only contain letters. e.g. john"
												// value={lastName}
												// onChange={(e) => setLastName(e.target.value)}
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.lastName}
												disabled={defaultDisabled}
												required
											/>
											<p style={{ color: 'red' }}>
												{errors.lastName}
											</p>
											<p style={{ color: 'red' }}>
												{touched.lastName}
											</p>
										</FormGroup>
									</Col>
									<Col md={4}>
										<FormGroup>
											<Label className="required">
												<i class="far fa-calendar-plus"></i>{' '}
												Date of Birth
											</Label>
											<Input
												type="date"
												className="form-control"
												name="dob"
												placeholder="Date-of-Birth"
												value={dob}
												onChange={(e) =>
													handleDateOfBirth(
														e.target.value
													)
												}
												disabled={defaultDisabled}
												required
											/>
										</FormGroup>
										{message ? (
											<span
												style={{
													fontSize: 12,
													textTransform: 'capitalize',
													color: 'red',
												}}
											>
												{message}
											</span>
										) : null}
									</Col>
									<Col md={4}>
										<FormGroup
											onChange={(e) =>
												setGender(e.target.value)
											}
										>
											<Label className="required">
												<PeopleOutlinedIcon /> Gender
											</Label>
											<br />
											{/* <input type="radio" value="male" name={gender} className="ml-1" /> Male
              <input type="radio" value="female" name={gender} className="ml-2" /> Female
              <input type="radio" value="other" name={gender} className="ml-2" /> Other */}
											{enumGenderOption.map((a) => (
												<>
													<input
														readOnly
														type="radio"
														value={a}
														name={gender}
														checked={a === gender}
														className="ml-1"
														disabled={
															defaultDisabled
														}
														required
													/>{' '}
													{a}
												</>
											))}
										</FormGroup>
									</Col>
									<Col md={4}>
										<FormGroup>
											<Label>
												<ShortTextIcon /> Father/ Spouse
												Name
											</Label>
											<Input
												type="text"
												name="fathers_spouse_name"
												min="2"
												className="form-control"
												placeholder="e.g. : Mukesh or Neha"
												// pattern="[A-Za-z]{2,15}"
												// title="MiddleName should only contain letters.and only have single word e.g. john"
												value={fathers_spouse_name}
												onChange={(e) =>
													setFathers_spouse_name(
														e.target.value
													)
												}
												// onChange={handleChange}
												// onBlur={handleBlur}
												disabled={defaultDisabled}
											/>
											{/* <p style={{color: 'red'}}>{errors.middleName}</p>
            <p style={{color: 'red'}}>{touched.middleName}</p> */}
										</FormGroup>
									</Col>

									<Col md={2}>
										<FormGroup
											onChange={(e) =>
												setOccupation(e.target.value)
											}
										>
											<Label className="required">
												<ShortTextIcon />
												Occupation
											</Label>
											<CustomInput
												type="select"
												value={occupation}
												disabled={defaultDisabled}
												required
											>
												<option value="-1">
													Select Option
												</option>
												<option value="Private Sector">
													Private Sector
												</option>
												<option value="Public Sector">
													Public Sector
												</option>
												<option value="Government Service">
													Government Service
												</option>
												<option value="Businessman">
													Businessman
												</option>
												<option value="Professional">
													Professional
												</option>
												<option value="Agriculturist">
													Agriculturist
												</option>
												<option value="Retired">
													Retired
												</option>
												<option value="Housewife">
													Housewife
												</option>
												<option value="Student">
													Student
												</option>
												<option value="Others">
													Others
												</option>
											</CustomInput>
										</FormGroup>
									</Col>

									<Col md={2}>
										<FormGroup
											onChange={(e) =>
												setResidental_status(
													e.target.value
												)
											}
										>
											<Label className="required">
												<ShortTextIcon />
												Resident Status
											</Label>
											<CustomInput
												type="select"
												value={residental_status}
												disabled={defaultDisabled}
												required
											>
												<option value="-1">
													Select Option
												</option>
												<option value="Resident Individual">
													Resident Individual
												</option>
												<option value="Non Resident">
													Non Resident
												</option>
												<option value="Foreign National">
													Foreign National
												</option>
											</CustomInput>
										</FormGroup>
									</Col>

									<Col md={5}>
										<FormGroup>
											<Label className="required">
												<i class="far fa-address-book"></i>{' '}
												Permanent Address
											</Label>
											<Input
												type="text"
												name="address"
												min="3"
												className="form-control"
												placeholder="e.g. : Apartment, studio, or floor"
												title="Address should contain atlist Three letters. e.g. City Name"
												value={address}
												onChange={(e) =>
													setAddress(e.target.value)
												}
												// onChange={handleChange}
												// onBlur={handleBlur}
												// value={values.address}
												disabled={defaultDisabled}
												required
											/>
											{/* <p style={{color: 'red'}}>{errors.address}</p>
           <p style={{color: 'red'}}>{touched.address}</p> */}
										</FormGroup>
									</Col>

									<Col md={3}>
										<FormGroup>
											<Label className="required">
												<ShortTextIcon />
												Specify Permanant Address Proof
											</Label>
											<Input
												type="text"
												name="specify_permanant_address_proof"
												className="form-control"
												placeholder="e.g. : adhar/pan/elec bill"
												// value={email}
												// onChange={(e) => setEmail(e.target.value)}
												// onChange={handleChange}
												// onBlur={handleBlur}
												value={
													specify_permanant_address_proof
												}
												onChange={(e) =>
													setSpecify_permanant_address_proof(
														e.target.value
													)
												}
												disabled={defaultDisabled}
												required
											/>
											{/* <p style={{color: 'red'}}>{errors.personal_email}</p>
            <p style={{color: 'red'}}>{touched.personal_email}</p>    */}
										</FormGroup>
									</Col>

									<Col md={2}>
										<FormGroup>
											<Label className="required">
												<RoomOutlinedIcon /> Pin code
											</Label>
											<Input
												type="tel"
												name="pincode"
												minLength="4"
												maxLength="10"
												className="form-control"
												placeholder="e.g. : 425403"
												pattern="[0-9]{4,10}"
												title="Pin-Code should only have Digites. e.g. 654532 And should have length of range 4-10 Digits"
												value={pincode}
												onChange={(e) =>
													setPincode(e.target.value)
												}
												// onChange={handleChange}
												// onBlur={handleBlur}
												// value={values.pincode}
												disabled={defaultDisabled}
												required
											/>
											{/* <p style={{color: 'red'}}>{errors.pincode}</p>
              <p style={{color: 'red'}}>{touched.pincode}</p> */}
										</FormGroup>
									</Col>

									<Col md={3}>
										<FormGroup>
											<Label>
												<ShortTextIcon />{' '}
												City/Town/Village
											</Label>
											<Input
												type="text"
												min="3"
												className="form-control mt-1"
												placeholder="e.g. : Mumbai"
												title="City/Town/Village should contain atlist Three letters. e.g. City Name"
												value={
													permanant_adr_city_town_village
												}
												onChange={(e) =>
													setPermanant_adr_city_town_village(
														e.target.value
													)
												}
												disabled={defaultDisabled}
											/>
										</FormGroup>
									</Col>

									<Col md={3}>
										<FormGroup>
											<Label>
												<ShortTextIcon /> State
											</Label>
											<Input
												type="text"
												min="3"
												className="form-control mt-1"
												placeholder="e.g. : Maharashtra"
												title="State should contain only letters. e.g. GOA"
												value={permanant_adr_state}
												onChange={(e) =>
													setPermanant_adr_state(
														e.target.value
													)
												}
												disabled={defaultDisabled}
											/>
										</FormGroup>
									</Col>

									<Col md={3}>
										<FormGroup>
											<Label>
												<ShortTextIcon /> Country
											</Label>
											<Input
												type="text"
												min="3"
												className="form-control mt-1"
												placeholder="e.g. : India"
												title="Country should contain only letters. e.g. India"
												value={permanant_adr_country}
												onChange={(e) =>
													setPermanant_adr_country(
														e.target.value
													)
												}
												disabled={defaultDisabled}
											/>
										</FormGroup>
									</Col>

									<Col md={4}>
										<FormGroup>
											<Label>
												<i class="far fa-address-book"></i>{' '}
												Communication Address
											</Label>
											<Input
												type="text"
												min="3"
												className="form-control mt-1"
												placeholder="e.g. : Apartment, studio, or floor"
												title="Address should contain atlist Three letters. e.g. City Name"
												value={commAddress}
												onChange={(e) =>
													setCommAddress(
														e.target.value
													)
												}
												disabled={defaultDisabled}
											/>
											{/* {commAddressChecked && <Input 
            type="text"
            min="3"
            className="form-control mt-1"
            placeholder="e.g. : Apartment, studio, or floor"
            title="Address should contain atlist Three letters. e.g. City Name"
            value={address}
            onChange={(e) => setCommAddress(address)}
          />} */}
										</FormGroup>
									</Col>

									<Col md={4}>
										<FormGroup>
											<Label>
												<ShortTextIcon />{' '}
												City/Town/Village
											</Label>
											<Input
												type="text"
												min="3"
												className="form-control mt-1"
												placeholder="e.g. : Mumbai"
												title="City/Town/Village should contain atlist Three letters. e.g. City Name"
												value={
													adr_correspondence_city_town_village
												}
												onChange={(e) =>
													setAdr_correspondence_city_town_village(
														e.target.value
													)
												}
												disabled={defaultDisabled}
											/>
										</FormGroup>
									</Col>

									<Col md={4}>
										<FormGroup>
											<Label>
												<ShortTextIcon /> State
											</Label>
											<Input
												type="text"
												min="3"
												className="form-control mt-1"
												placeholder="e.g. : Maharashtra"
												title="State should contain only letters. e.g. GOA"
												value={adr_correspondence_state}
												onChange={(e) =>
													setAdr_correspondence_state(
														e.target.value
													)
												}
												disabled={defaultDisabled}
											/>
										</FormGroup>
									</Col>

									<Col md={4}>
										<FormGroup>
											<Label>
												<ShortTextIcon /> Country
											</Label>
											<Input
												type="text"
												min="3"
												className="form-control mt-1"
												placeholder="e.g. : India"
												title="Country should contain only letters. e.g. India"
												value={
													adr_correspondence_country
												}
												onChange={(e) =>
													setAdr_correspondence_country(
														e.target.value
													)
												}
												disabled={defaultDisabled}
											/>
										</FormGroup>
									</Col>

									<Col md={4}>
										<FormGroup>
											<Label>
												<RoomOutlinedIcon />{' '}
												Communication Pin code
											</Label>
											<Input
												type="tel"
												minLength="4"
												maxLength="10"
												className="form-control mt-1"
												placeholder="e.g. : 425403"
												pattern="[0-9]{4,10}"
												title="Communication Pin-Code should only have Digites. e.g. 654532 And should have length of range 4-10 Digits"
												value={commPincode}
												onChange={(e) =>
													setCommPincode(
														e.target.value
													)
												}
												disabled={defaultDisabled}
											/>
										</FormGroup>
									</Col>

									<Col md={4}>
										<FormGroup>
											<Label>
												<ShortTextIcon />
												Specify Communication Address
												Proof
											</Label>
											<Input
												type="text"
												name="specify_correspondence_address_proof"
												className="form-control"
												placeholder="e.g. : adhar"
												// value={email}
												// onChange={(e) => setEmail(e.target.value)}
												// onChange={handleChange}
												// onBlur={handleBlur}
												value={
													specify_correspondence_address_proof
												}
												onChange={(e) =>
													setSpecify_correspondence_address_proof(
														e.target.value
													)
												}
												disabled={defaultDisabled}
											/>
											{/* <p style={{color: 'red'}}>{errors.personal_email}</p>
            <p style={{color: 'red'}}>{touched.personal_email}</p>    */}
										</FormGroup>
									</Col>

									<Col md={12}>
										<Col md={4}>
											<FormGroup>
												<Input
													type="checkbox"
													className="mt-1 ml-1"
													onChange={
														onChangeCheckboxVal
													}
													disabled={defaultDisabled}
												/>
												<p
													style={{
														paddingLeft: '25px',
														color: 'red',
													}}
												>
													Same as Permanent Address
												</p>
											</FormGroup>
										</Col>
									</Col>

									<Col md={3}>
										<FormGroup>
											<Label>
												<DescriptionOutlinedIcon />
												Upload Communication Address
												Proof
											</Label>
											<Input
												type="file"
												className="mt-1"
												id="commAddressProof"
												onChange={
													commAddressProofHandler
												}
												disabled={defaultDisabled}
											/>
											<FormText color="muted">
												PDF or JPEG format
											</FormText>
											{commAddressPath ? (
												<embed
													src={`${process.env.REACT_APP_URL_API}/${commAddressPath}`}
													width="50px"
													height="50px"
													className="mt-2"
												/>
											) : commAddressCanCheck ? (
												<embed
													src={`${commAddressCanCheck}`}
													width="50px"
													height="50px"
													className="mt-2"
												/>
											) : null}
										</FormGroup>
									</Col>

									<Col md={2}>
										<FormGroup>
											<Label className="required">
												<ShortTextIcon /> select Country
											</Label>
											<PhoneCode
												onSelect={(code) =>
													setCountyCode(code)
												} // required
												showFirst={['IN']}
												defaultValue="India"
												id="some-id"
												name="some-name"
												className="form-control"
												disabled={true}
												required
											/>
										</FormGroup>
									</Col>

									<Col md={1}>
										<FormGroup>
											<Label className="required">
												<ShortTextIcon /> code
											</Label>
											<Input
												type="tel"
												name="mobileNumber"
												className="form-control"
												value={countyCode}
												disabled={defaultDisabled}
												required
											/>
										</FormGroup>
									</Col>

									<Col md={2}>
										<FormGroup>
											<Label className="required">
												<PhoneIphoneIcon /> Mobile
												Number
											</Label>
											<Input
												type="tel"
												name="mobileNumber"
												// maxLength="20"
												className="form-control"
												placeholder="e.g. : 7745021891"
												// pattern="[0-19]{20,20}"
												// title="Mobile Number should only have Digits. e.g. 9878654532 And should have length of Ten Digits"
												value={mobileNumber}
												onChange={(e) =>
													setMobileNumber(
														e.target.value
													)
												}
												// onChange={handleChange}
												// onBlur={handleBlur}
												// value={values.mobileNumber}
												disabled={defaultDisabled}
												required
											/>
											{/* <p style={{color: 'red'}}>{errors.mobileNumber}</p>
            <p style={{color: 'red'}}>{touched.mobileNumber}</p>    */}
										</FormGroup>
									</Col>

									<Col md={2}>
										<FormGroup>
											<Label className="required">
												<WhatsAppIcon /> WhatsApp Number
											</Label>
											<Input
												type="tel"
												name="whatsAppNum"
												maxLength="20"
												className="form-control"
												placeholder="e.g. : 7745021891"
												// pattern="[0-19]{20,20}"
												// title="whatsApp Number should only have Digits. e.g. 9878654532 And should have length of Ten Digits"
												value={whatsAppNum}
												onChange={(e) =>
													setWhatsAppNum(
														e.target.value
													)
												}
												// onChange={handleChange}
												// onBlur={handleBlur}
												// value={values.whatsAppNum}
												disabled={defaultDisabled}
												required
											/>
											{/* <p style={{color: 'red'}}>{errors.whatsAppNum}</p>
            <p style={{color: 'red'}}>{touched.whatsAppNum}</p>    */}
										</FormGroup>
									</Col>

									<Col md={12}>
									
										<Col
											md={4}
											style={{ marginLeft: '56em' }}
										>
											<p>
											
											<FormGroup>
												<Input
													type="checkbox"
													className="mt-1 ml-1"
													onChange={
														onChangeCheckboxMobNoVal
													}
													disabled={defaultDisabled}
												/>
												<p
													style={{
														paddingLeft: '25px',
														color: 'red',
													}}
												>
													Same as Mobile Number
												</p>
											</FormGroup>
											</p>
										</Col>
									</Col>

									<Col md={2}>
										<FormGroup>
											<Label>
												<PhoneIphoneIcon /> Tel(Off).
											</Label>
											<Input
												type="tel"
												name="tel_off"
												// maxLength="20"
												className="form-control"
												placeholder="e.g. : +2309"
												// pattern="[0-19]{20,20}"
												// title="Mobile Number should only have Digits. e.g. 9878654532 And should have length of Ten Digits"
												value={tel_off}
												onChange={(e) =>
													setTel_off(e.target.value)
												}
												disabled={defaultDisabled}
											/>
										</FormGroup>
									</Col>

									<Col md={2}>
										<FormGroup>
											<Label>
												<PhoneIphoneIcon /> Tel(Res).
											</Label>
											<Input
												type="tel"
												name="tel_res"
												// maxLength="20"
												className="form-control"
												placeholder="e.g. : +2309"
												// pattern="[0-19]{20,20}"
												// title="Mobile Number should only have Digits. e.g. 9878654532 And should have length of Ten Digits"
												value={tel_res}
												onChange={(e) =>
													setTel_res(e.target.value)
												}
												disabled={defaultDisabled}
											/>
										</FormGroup>
									</Col>

									<Col md={2}>
										<FormGroup>
											<Label>
												<PhoneIphoneIcon /> Fax{' '}
											</Label>
											<Input
												type="text"
												name="fax"
												// maxLength="20"
												className="form-control"
												placeholder="e.g. : +2309"
												// pattern="[0-19]{20,20}"
												// title="Mobile Number should only have Digits. e.g. 9878654532 And should have length of Ten Digits"
												value={fax}
												onChange={(e) =>
													setFax(e.target.value)
												}
												disabled={defaultDisabled}
											/>
										</FormGroup>
									</Col>

									<Col md={3}>
										<FormGroup>
											<Label className="required">
												<EmailOutlinedIcon /> Email
											</Label>
											<Input
												type="email"
												name="email"
												className="form-control"
												placeholder="e.g. : akshay23@gmail.com"
												// value={email}
												// onChange={(e) => setEmail(e.target.value)}
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.email}
												disabled={defaultDisabled}
												required
											/>
											<p style={{ color: 'red' }}>
												{errors.email}
											</p>
											<p style={{ color: 'red' }}>
												{touched.email}
											</p>
										</FormGroup>
									</Col>
									<Col md={3}>
										<FormGroup>
											<label className="form-label required">
												<VpnKeyIcon /> Email Password
											</label>
											<input
												type="password"
												name="password"
												min="6"
												max="10"
												onChange={(e) =>
													setEmailPassword(
														e.target.value
													)
												}
												value={emailPassword}
												className="form-control"
												placeholder="e.g. : Abc!@#"
												disabled={defaultDisabled}
												required
											/>
										</FormGroup>
									</Col>
									<Col md={4}>
										<FormGroup>
											<Label>
												<ShortTextIcon /> Designation
											</Label>
											<Input
												type="text"
												name="designation"
												min="2"
												className="form-control"
												placeholder="e.g. : CA"
												// pattern="[A-Za-z]{2,15}"
												// title="Occupation should only contain letters. e.g. CA"
												// value={designation}
												// onChange={(e) => setDesignation(e.target.value)}
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.designation}
												disabled={defaultDisabled}
												
											/>
											<p style={{ color: 'red' }}>
												{errors.designation}
											</p>
											<p style={{ color: 'red' }}>
												{touched.designation}
											</p>
										</FormGroup>
									</Col>
									<Col md={4}>
										<FormGroup>
											<Label>
												<ShortTextIcon /> Name Of
												Organization
											</Label>
											<Input
												type="text"
												name="organization"
												min="2"
												className="form-control"
												placeholder="e.g. : Aditya Finance"
												// pattern="[A-Za-z]{2,15}"
												// title="Occupation should only contain letters. e.g. CA"
												// value={designation}
												// onChange={(e) => setDesignation(e.target.value)}
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.organization}
												disabled={defaultDisabled}
												
											/>
											<p style={{ color: 'red' }}>
												{errors.organization}
											</p>
											<p style={{ color: 'red' }}>
												{touched.organization}
											</p>
										</FormGroup>
									</Col>
									<Col md={4}>
										<FormGroup>
											<Label>
												<ShortTextIcon /> Mother's
												maiden name
											</Label>
											<Input
												type="text"
												name="motherName"
												min="2"
												className="form-control"
												placeholder="e.g. : Lalita"
												
												value={motherName}
												onChange={(e) =>
													setMotherName(
														e.target.value
													)
												}
												
											/>
											
										</FormGroup>
									</Col>

									<Col md={4}>
										<FormGroup>
											<Label>
												<EmailOutlinedIcon /> Personal
												Email
											</Label>
											<Input
												type="email"
												name="personal_email"
												className="form-control"
												placeholder="e.g. : akshay23@gmail.com"
												
												value={personal_email}
												onChange={(e) =>
													setPersonal_email(
														e.target.value
													)
												}
												disabled={defaultDisabled}
											/>
											
										</FormGroup>
									</Col>
									<Col md={3}>
										<FormGroup>
											<Label>
												<EmailOutlinedIcon /> Email
												Verification Status
											</Label>
											<Input
												type="text"
												name="email_verification_status"
												className="form-control"
												placeholder="e.g. : Verified / Unverified"
												value={emailVerificationStatus}
												onChange={(e) =>
													setEmailVerificationStatus(
														e.target.value
													)
												}
												
												disabled={true}
											/>
											
										</FormGroup>
									</Col>
									<Col md={3}>
										<FormGroup>
											<Label>
												<EmailOutlinedIcon /> Whatsapp
												Verification Status
											</Label>
											<Input
												type="text"
												name="mobile_verification_status"
												className="form-control"
												placeholder="e.g. : Verified / Unverified"
												value={phoneVerificationStatus}
												onChange={(e) =>
													setPhoneVerificationStatus(
														e.target.value
													)
												}
												
												disabled={true}
											/>
											{/* <p style={{color: 'red'}}>{errors.email}</p>
            <p style={{color: 'red'}}>{touched.email}</p>    */}
										</FormGroup>
									</Col>
									<Col md={4}>
										<FormGroup>
											{(emailVerificationStatus ===
												'Unverified' ||
												phoneVerificationStatus ===
													'Unverified') && (
													<Button
														className="btn btn-success mt-5"
														onClick={onClickSendOTP}
													>
														Verify
													</Button>
												)}
										</FormGroup>
										{(emailVerificationStatus ===
												'Unverified' ||
												phoneVerificationStatus ===
													'Unverified') &&<p style={{fontSize: '10px', color: 'red'}}>Click to verify Email and Whatsapp number</p>}
									</Col>
									<Col md={12}>
										<br />
										<FormGroup
											className="shadow-none rounded"
											id="page-head"
										>
											<h5
												className="pl-1 pr-1 pt-1 pb-1"
												style={{
													width: '100%',
													textAlign: 'center',
													backgroundColor: '#eff6f9',
												}}
											>
												Additional Info
											</h5>
										</FormGroup>
									</Col>
									<Col md={3}>
										<FormGroup>
											<Label className="required">
												<ShortTextIcon /> Nationality
											</Label>
											<Input
												type="text"
												name="nationality"
												min="2"
												className="form-control"
												placeholder="e.g. : Indian"
											
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.nationality}
												disabled={defaultDisabled}
												required
											/>
											<p style={{ color: 'red' }}>
												{errors.nationality}
											</p>
											<p style={{ color: 'red' }}>
												{touched.nationality}
											</p>
										</FormGroup>
									</Col>
									<Col md={3}>
										<FormGroup>
											<Label className="required">
												<i class="fas fa-id-card"></i>{' '}
												PAN Card Number
											</Label>
											<Input
												type="text"
												name="pan"
												maxLength="10"
												className="form-control"
												placeholder="e.g. : ABCTY1234D"
												pattern="[A-Z0-9]{10,10}"
												title="Pan-Card number should contain Upparcase letters And Numbers and It should have length of 10 digits. e.g. ABCTY1234D"
												// value={pan}
												// onChange={(e) => setPan(e.target.value)}
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.pan}
												disabled={defaultDisabled}
												required
											/>
											<p style={{ color: 'red' }}>
												{errors.pan}
											</p>
											<p style={{ color: 'red' }}>
												{touched.pan}
											</p>
										</FormGroup>
									</Col>
									<Col md={3}>
										<FormGroup>
											<Label className="required">
												<i class="far fa-address-card"></i>{' '}
												Aadhar Card Number
											</Label>
											<Input
												type="tel"
												name="adhar"
												maxLength="12"
												className="form-control"
												placeholder="e.g. : 1234/1234/1234"
												pattern="[0-9]{12,12}"
												title="Aadhar Number should only have Digites. e.g. 4468.. And should have length of 12 Digits"
												// value={adhar}
												// onChange={(e) => setAdhar(e.target.value)}
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.adhar}
												disabled={defaultDisabled}
												required
											/>
											<p style={{ color: 'red' }}>
												{errors.adhar}
											</p>
											<p style={{ color: 'red' }}>
												{touched.adhar}
											</p>
										</FormGroup>
									</Col>
									<Col md={3}>
										<FormGroup>
											<Label className="required">
												<ShortTextIcon /> Specify the
												proof of Identity submitted{' '}
											</Label>
											<Input
												type="text"
												name="proof_of_identity_submitted"
												className="form-control"
												placeholder="e.g. : adhar"
											
												value={
													proof_of_identity_submitted
												}
												onChange={(e) =>
													setProof_of_identity_submitted(
														e.target.value
													)
												}
												disabled={defaultDisabled}
												required
											/>
											
										</FormGroup>
									</Col>

									<Col md={12}>
										<br />
										<FormGroup
											className="shadow-none rounded"
											id="page-head"
										>
											<h5
												className="pl-1 pr-1 pt-1 pb-1"
												style={{
													width: '100%',
													textAlign: 'center',
													backgroundColor: '#eff6f9',
												}}
											>
												Broker Details
											</h5>
										</FormGroup>
									</Col>

									{/*broker_sub_asset_class*/}

									{brokerDetails.map((broker, index) => {
		broker.broker_sub_asset_class.length ? setBrokerDataLength(true) : setBrokerDataLength(false)
                                        
										return (
											<>
												<Row md={12} style={{display:'flex', alignItems:'center'}}>
													<Row className="ml-4 mr-1">
														<FormGroup
															onChange={(e) =>
																onValueBroChange(
																	index,
																	e
																)
															}	 
														>
															<Label className="required">
																<ShortTextIcon />{' '}
																Broker Name
															</Label>
															<div style={{width:'250px'}}>
																<Select
																	name="broker_name"
																	id="broker_name"
																	isMulti={
																		false
																	}
																	value={broker.broker_name}
																	onChange={(
																		data
																	) =>
																		{
																			onMultiChoiceDropdownChange(
																				index,
																				data,
																				broker.broker_name,
																				'broker_name'
																			)
																		}
																	}
																	options={
																		getAppropriateBrokers()
																	}
																	disabled={
																		defaultDisabled
																	}
																	required
																/>
															</div>
															
														</FormGroup>
													</Row>
									
													<Row className="ml-4 mr-1">
														<FormGroup
															onChange={(e) =>
																onValueBroChange(
																	index,
																	e
																)
															}
														>
															{/* onChange={e => onValueBroChange(index, e)} */}
															<Label className="required">
																<PhoneIphoneIcon />{' '}
																Broker Sub-Asset
																Class
															</Label>

															
															<div
																style={{
																	width: '325px',
																}}
															>
																<Select
																	name="brockerSubAssetClass"
																	isMulti={
																		true
																	}
																	value={
																		broker.broker_sub_asset_class
																	}
																	onChange={(
																		data
																	) =>
																	onMultiChoiceDropdownChange(
																		index,
																		data,
																		broker.broker_sub_asset_class,
																		'broker_sub_asset_class'
																	)
																	}
																	options={
																		getAppropriateSAC(broker.broker_name.value)
																		// brokerSACList[broker.broker_name.value]
																	}
																/>
																{
																	broker.broker_sub_asset_class.length ? null : <span style={{ color: 'red' }}>Please select atleast 1 option</span>
																}
															</div>
														</FormGroup>
													</Row>

													{/* <Row className="ml-4 mr-1">
														<FormGroup
															onChange={(e) =>
																onValueBroChange(
																	index,
																	e
																)
															}	 
														>
															<Label className="required">
																<EmailOutlinedIcon />{' '}
																Broker Email
															</Label>
															<div
																style={{
																	width: '250px',
																}}
															>
																<Select
																	name="broker_email"
																	isMulti={
																		false
																	}
																	value={
																		broker.broker_email
																		//brokerMails[broker.broker_name[0]]
																		//{label: 'REIT', value: 'REIT'}
																	}
																	onChange={(
																		data
																	) =>
																		onMultiChoiceDropdownChange(
																			index,
																			data,
																			broker.broker_email,
																			'broker_email'
																		)
																	}
																	options={
																		brokerMails[broker.broker_name.value]
																	}
																	disabled={
																		defaultDisabled
																	}
																	required
																/>
															</div>
														</FormGroup>
													</Row> */}

													<Row className="ml-2 mr-1">
														<FormGroup>
															<Label className="required">
																<PhoneIphoneIcon />{' '}
																Broker Code
															</Label>
															<Input
																type="tel"
																min="1"
																style={{
																	width: '150px',
																	height:'38px'
																}}
																className="form-control"
																placeholder="e.g. : 774502"
																// pattern="[0-9]{10,10}"
																// title="Nominee Mobile Number should only have Digits. e.g. 9878654532 And should have length of Ten Digits"
																value={
																	broker.broker_code
																}
																name="broker_code"
																onChange={(e) =>
																	onValueBroChange(
																		index,
																		e
																	)
																}
																disabled={
																	defaultDisabled
																}
																required
															/>
														</FormGroup>
													</Row>

													<Row className="ml-5 mt-5">
														<FormGroup>
															{brokerDetails.length !==
																1 && (
																<Button
																	onClick={() =>
																		removeBroker(
																			index
																		)
																	}
																	className="btn btn-danger"
																	disabled={
																		defaultDisabled
																	}
																>
																	-
																</Button>
															)}
														</FormGroup>
													</Row>
												</Row>

												<br />
												<Col md={6}>
													<FormGroup>
														{brokerDetails.length -
															1 ===
															index && (
															<Button
																onClick={
																	addBroker
																}
																className="btn btn-success"
																disabled={
																	defaultDisabled
																}
															>
																+
															</Button>
														)}
													</FormGroup>
												</Col>
											</>
										);
									})}

									<Col md={12}>
										<br />
										<FormGroup
											className="shadow-none rounded"
											id="page-head"
										>
											<h5
												className="pl-1 pr-1 pt-1 pb-1"
												style={{
													width: '100%',
													textAlign: 'center',
													backgroundColor: '#eff6f9',
												}}
											>
												Bank Details
											</h5>
										</FormGroup>
										<FormGroup className="shadow-none rounded">
											<h5
												className="pl-1 pr-1 pt-1 pb-1"
												style={{ width: '100%' }}
											>
												Bank Account Details :
											</h5>
										</FormGroup>
									</Col>
									{bankDetails.map((bankdetNew, index) => (
										<>
											<Row md={12}>
												<Row className="ml-4 mr-1">
													<FormGroup>
														<Label className="required">
															<AccountBalanceIcon />{' '}
															Bank Name
														</Label>
														<Input
															type="text"
															min="2"
															className="form-control"
															placeholder="e.g. : HDFC BANK"
															// pattern="[A-Za-z]{2,30}"
															// title="Bank-Name should only contain letters. e.g. HDFC BANK"
															value={
																bankdetNew.bank_name
															}
															name="bank_name"
															id="bank_name"
															onChange={(e) =>
																onBankDetChange(
																	index,
																	e
																)
															}
															disabled={
																defaultDisabled
															}
															required
														/>
													</FormGroup>
												</Row>
												<Row className="ml-4 mr-1">
													<FormGroup>
														<Label className="required">
															<ShortTextIcon />{' '}
															Bank Account Number
														</Label>
														<Input
															type="tel"
															minLength="8"
															maxLength="30"
															className="form-control"
															placeholder="e.g. : 5623456789123456"
															pattern="[0-9]{8,30}"
															title="Bank Acc should only have Digits. e.g. 58786.. And should be in the range of 8-30 Digits"
															value={
																bankdetNew.bank_acc_no
															}
															name="bank_acc_no"
															id="bank_acc_no"
															onChange={(e) =>
																onBankDetChange(
																	index,
																	e
																)
															}
															disabled={
																defaultDisabled
															}
															required
														/>
													</FormGroup>
												</Row>
												<Row className="ml-4 mr-1">
													<FormGroup>
														<Label className="required">
															<ShortTextIcon />{' '}
															IFSC Code
														</Label>
														<Input
															type="text"
															min="11"
															className="form-control"
															placeholder="e.g. : SBIN0001707"
															pattern="[A-Z0-9]{11,11}"
															title="IFSC Code should contain Upparcase letters And Numbers and it should have length of 11 digits. e.g. ABC12.."
															value={
																bankdetNew.ifsc_code
															}
															name="ifsc_code"
															id="ifsc_code"
															onChange={(e) =>
																onBankDetChange(
																	index,
																	e
																)
															}
															disabled={
																defaultDisabled
															}
															required
														/>
													</FormGroup>
												</Row>
												<Row className="ml-4 mr-1">
													<FormGroup>
														<Label className="required">
															<ShortTextIcon />{' '}
															MICR Code
														</Label>
														<Input
															type="text"
															min="9"
															className="form-control"
															placeholder="e.g. : 897689765"
															pattern="[0-9]{9,9}"
															title="MICR Code should only contain Numbers and It should have length of 9 digits. e.g. 897689765"
															value={
																bankdetNew.micr_code
															}
															name="micr_code"
															id="micr_code"
															onChange={(e) =>
																onBankDetChange(
																	index,
																	e
																)
															}
															disabled={
																defaultDisabled
															}
															required
														/>
													</FormGroup>
												</Row>

												<Row className="ml-4 mr-1">
													<FormGroup>
														<Label className="required">
															<ShortTextIcon />{' '}
															CRN / Customer Id{' '}
														</Label>
														<Input
															type="text"
															minLength="1"
															className="form-control"
															placeholder="e.g. : CRN-516567913"
															// pattern="[A-Z0-9]{11,11}"
															// title="IFSC Code should contain Upparcase letters And Numbers and it should have length of 11 digits. e.g. ABC12.."
															value={
																bankdetNew.crn
															}
															name="crn"
															id="crn"
															onChange={(e) =>
																onBankDetChange(
																	index,
																	e
																)
															}
															disabled={
																defaultDisabled
															}
															required
														/>
													</FormGroup>
												</Row>

												{/* <Row className="ml-4 mr-1">
													<FormGroup>
														<Label className="required">
															<ShortTextIcon />{' '}
															Customer Id{' '}
														</Label>
														<Input
															type="text"
															minLength="1"
															className="form-control"
															placeholder="e.g. : 25977212"
															// pattern="[A-Z0-9]{11,11}"
															// title="IFSC Code should contain Upparcase letters And Numbers and it should have length of 11 digits. e.g. ABC12.."
															value={
																bankdetNew.customer_id
															}
															name="customer_id"
															id="customer_id"
															onChange={(e) =>
																onBankDetChange(
																	index,
																	e
																)
															}
															disabled={
																defaultDisabled
															}
															required
														/>
													</FormGroup>
												</Row> */}

												<Row className="ml-5 mt-5">
													<FormGroup>
														{bankDetails.length !==
															1 && (
															<Button
																onClick={() =>
																	removeBankDet(
																		index
																	)
																}
																className="btn btn-danger"
																disabled={
																	defaultDisabled
																}
															>
																-
															</Button>
														)}
													</FormGroup>
												</Row>
											</Row>
											{index < 5 && (
												<>
													<br />
													<Col md={6}>
														<FormGroup>
															{bankDetails.length -
																1 ===
																index && (
																<Button
																	onClick={
																		addBankDet
																	}
																	className="btn btn-success"
																	disabled={
																		defaultDisabled
																	}
																>
																	+
																</Button>
															)}
														</FormGroup>
													</Col>
												</>
											)}
										</>
									))}
									<Col md={12}>
										<FormGroup className="shadow-none rounded">
											<h5
												className="pl-1 pr-1 pt-1 pb-1"
												style={{ width: '100%' }}
											>
												Securities Account Details :
											</h5>
										</FormGroup>
									</Col>
									{securitiesAccount.map(
										(securitiesNew, index) => (
											<>
												<Row md={12}>
													<Row className="ml-4 mr-1">
														<FormGroup>
															<Label className="required">
																<ShortTextIcon />{' '}
																DP Id
															</Label>
															<Input
																type="text"
																minLength="2"
																className="form-control"
																placeholder="e.g. : IN300214"
																// pattern="[0-9]{8,8}"
																// title="DP Id only contains numbers and also have length of 8 digits. e.g. 90356789"
																value={
																	securitiesNew.dp_id
																}
																name="dp_id"
																id="dp_id"
																onChange={(e) =>
																	onSecuritiesAccChange(
																		index,
																		e
																	)
																}
																disabled={
																	defaultDisabled
																}
																required
															/>
														</FormGroup>
													</Row>
													<Row className="ml-4 mr-1">
														<FormGroup>
															<Label className="required">
																<ShortTextIcon />{' '}
																DP Name
															</Label>
															<Input
																type="text"
																min="12"
																className="form-control"
																placeholder="e.g. : DP Name"
																// pattern="[A-Za-z]{2,30}"
																// title="DP-Name should only contain letters. e.g. Ramesh J. Rao"
																value={
																	securitiesNew.dp_name
																}
																name="dp_name"
																id="dp_name"
																onChange={(e) =>
																	onSecuritiesAccChange(
																		index,
																		e
																	)
																}
																disabled={
																	defaultDisabled
																}
																required
															/>
														</FormGroup>
													</Row>
													<Row className="ml-4 mr-1">
														<FormGroup>
															<Label className="required">
																<ShortTextIcon />{' '}
																Client Code
															</Label>
															<Input
																type="text"
																maxLength="30"
																className="form-control"
																placeholder="e.g. : 12341234"
																pattern="[A-Za-z0-9]{0,30}"
																title="Client Code required"
																value={
																	securitiesNew.client_code
																}
																name="client_code"
																id="client_code"
																onChange={(e) =>
																	onSecuritiesAccChange(
																		index,
																		e
																	)
																}
																disabled={
																	defaultDisabled
																}
																required
															/>
														</FormGroup>
													</Row>

													<Row className="ml-4 mr-1">
														<FormGroup>
															<Label className="required">
																<ShortTextIcon />{' '}
																User Id
															</Label>
															<Input
																type="text"
																maxLength="30"
																className="form-control"
																placeholder="e.g. : ABCD/1234/*#@"
																// pattern="[A-Za-z0-9]{0,30}"
																// title="User Id required"
																value={
																	securitiesNew.user_id
																}
																name="user_id"
																id="user_id"
																onChange={(e) =>
																	onSecuritiesAccChange(
																		index,
																		e
																	)
																}
																disabled={
																	defaultDisabled
																}
																required
															/>
														</FormGroup>
													</Row>

													<Row className="ml-5 mt-5">
														<FormGroup>
															{securitiesAccount.length !==
																1 && (
																<Button
																	onClick={() =>
																		removeSecuritiesAccDet(
																			index
																		)
																	}
																	className="btn btn-danger"
																	disabled={
																		defaultDisabled
																	}
																>
																	-
																</Button>
															)}
														</FormGroup>
													</Row>
												</Row>
												{index < 1 && (
													<>
														<br />
														<Col md={6}>
															<FormGroup>
																{securitiesAccount.length -
																	1 ===
																	index && (
																	<Button
																		onClick={
																			addSecuritiesAccDet
																		}
																		className="btn btn-success"
																		disabled={
																			defaultDisabled
																		}
																	>
																		+
																	</Button>
																)}
															</FormGroup>
														</Col>
													</>
												)}
											</>
										)
									)}
									
									<Col md={12}>
										<br />
										<FormGroup
											className="shadow-none rounded"
											id="page-head"
										>
											<h5
												className="pl-1 pr-1 pt-1 pb-1"
												style={{
													width: '100%',
													textAlign: 'center',
													backgroundColor: '#eff6f9',
												}}
											>
												Nominee Details
											</h5>
										</FormGroup>
									</Col>

									{nomineeDetails.map((nominee, index) => (
										<>
											<Row md={12}>
												<Row className="ml-4 mr-1">
													<FormGroup>
														<Label className="required">
															<ShortTextIcon />{' '}
															Nominee Name
														</Label>
														<Input
															type="text"
															min="2"
															style={{
																width: '300px',
															}}
															className="form-control"
															placeholder="e.g. : Akshay"
															value={
																nominee.nominee_name ||
																''
															}
															name="nominee_name"
															id="nominee_name"
															onChange={(e) =>
																onValueChange(
																	index,
																	e
																)
															}
															disabled={
																defaultDisabled
															}
															required
														/>
													</FormGroup>
												</Row>

												<Row className="ml-2 mr-1">
													<FormGroup>
														<Label className="required">
															<PhoneIphoneIcon />{' '}
															Nominee Mobile
															Number
														</Label>
														<Input
															type="tel"
															maxLength="10"
															style={{
																width: '300px',
															}}
															className="form-control"
															placeholder="e.g. : 7745021881"
															pattern="[0-9]{10,10}"
															title="Nominee Mobile Number should only have Digits. e.g. 9878654532 And should have length of Ten Digits"
															value={
																nominee.nominee_mobile_no
															}
															name="nominee_mobile_no"
															onChange={(e) =>
																onValueChange(
																	index,
																	e
																)
															}
															disabled={
																defaultDisabled
															}
															required
														/>
													</FormGroup>
												</Row>

												<Row className="ml-2">
													<FormGroup>
														<Label className="required">
															<ShortTextIcon />{' '}
															Relationship with
															Nominee
														</Label>
														<Input
															type="text"
															min="2"
															style={{
																width: '300px',
															}}
															className="form-control"
															placeholder="e.g. : Brother, Sister"
															value={
																nominee.nominee_relation
															}
															name="nominee_relation"
															onChange={(e) =>
																onValueChange(
																	index,
																	e
																)
															}
															disabled={
																defaultDisabled
															}
															required
														/>
													</FormGroup>
												</Row>

												<Row className="ml-4 mr-1">
													<FormGroup>
														<Label className="required">
															<EmailOutlinedIcon />{' '}
															Nominee Email
														</Label>
														<Input
															type="email"
															className="form-control"
															placeholder="e.g. : akshay23@gmail.com"
															name="nominee_email"
															value={
																nominee.nominee_email
															}
															onChange={(e) =>
																onValueChange(
																	index,
																	e
																)
															}
															disabled={
																defaultDisabled
															}
															required
														/>
													</FormGroup>
												</Row>

												<Row className="ml-4 mr-1">
													<FormGroup>
														<Label>
															<DescriptionOutlinedIcon />
															Nominee Aadhaar /
															Pan
														</Label>
														<Input
															type="file"
															name="nomineeAAdharPanPath"
															id="nomineeAAdharPanPath"
															onChange={(e) =>
																onValueChange(
																	index,
																	e
																)
															}
															disabled={
																defaultDisabled
															}
														/>
														<FormText color="muted">
															PDF or JPEG format
														</FormText>
														{nominee.nomineeAAdharPanPath && (
															<embed
																src={`${process.env.REACT_APP_URL_API}/${nominee.nomineeAAdharPanPath}`}
																width="50px"
																height="50px"
																className="mt-2"
															/>
														)}
													</FormGroup>
												</Row>
												<Row className="ml-5 mt-5">
													<FormGroup>
														{nomineeDetails.length !==
															1 && (
															<Button
																onClick={() =>
																	removeNominee(
																		index
																	)
																}
																className="btn btn-danger"
																disabled={
																	defaultDisabled
																}
															>
																-
															</Button>
														)}
													</FormGroup>
												</Row>
											</Row>

											<br />
											<Col md={6}>
												<FormGroup>
													{nomineeDetails.length -
														1 ===
														index && (
														<Button
															onClick={addNominee}
															className="btn btn-success"
															disabled={
																defaultDisabled
															}
														>
															+
														</Button>
													)}
												</FormGroup>
											</Col>
										</>
									))}

									<Col md={12}>
										<br />
										<FormGroup
											className="shadow-none rounded"
											id="page-head"
										>
											<h5
												className="pl-1 pr-1 pt-1 pb-1"
												style={{
													width: '100%',
													textAlign: 'center',
													backgroundColor: '#eff6f9',
												}}
											>
												Source of Fund Flow
											</h5>
										</FormGroup>
									</Col>

									<Col md={12}>
										<FormGroup>
											<Label
												for="exampleCheckbox"
												className="required"
											>
												<ShortTextIcon /> Select Source
											</Label>
											<div>
												<MultiselectCheckbox
													options={
														enumSourceOfFundOption
													}
													onChange={(incsSource) => {
														console.log(incsSource);
													}}
													disabled={defaultDisabled}
													required
												/>
											</div>
										</FormGroup>
									</Col>

									<Col md={12}>
										<br />
										<FormGroup
											className="shadow-none rounded"
											id="page-head"
										>
											<h5
												className="pl-1 pr-1 pt-1 pb-1"
												style={{
													width: '100%',
													textAlign: 'center',
													backgroundColor: '#eff6f9',
												}}
											>
												Annual Income Range
											</h5>
										</FormGroup>
									</Col>
									<Col md={6}>
										<FormGroup
											onChange={(e) =>
												setIncomeRang(e.target.value)
											}
										>
											<Label className="required">
												<EqualizerOutlinedIcon /> Select
												Range
											</Label>
											<CustomInput
												type="select"
												id="IncomeId"
												value={incomeRang}
												disabled={defaultDisabled}
												required
											>
												<option value="Below 1 Lac">
													Below 1 Lac
												</option>
												<option value="1 - 5 Lac">
													1 - 5 Lac
												</option>
												<option value="5 - 10 Lac">
													5 - 10 Lac
												</option>
												<option value="10 - 25 Lac">
													10 - 25 Lac
												</option>
												<option value="Above 25 Lac">
													Above 25 Lac
												</option>
											</CustomInput>
										</FormGroup>
									</Col>
									<Col md={12}>
										<br />
										<FormGroup
											className="shadow-none rounded"
											id="page-head"
										>
											<h5
												className="pl-1 pr-1 pt-1 pb-1"
												style={{
													width: '100%',
													textAlign: 'center',
													backgroundColor: '#eff6f9',
												}}
											>
												Other Details
											</h5>
										</FormGroup>
									</Col>
									<Col md={4}>
										<FormGroup>
											<Label>
												<ShortTextIcon /> Place of Birth
											</Label>
											<Input
												type="text"
												min="2"
												className="form-control"
												placeholder="e.g. : Delhi"
												// pattern="[A-Za-z]{2,20}"
												// title="Birth-Place should only contain letters. e.g. Delhi"
												value={birthPlace}
												onChange={(e) =>
													setBirthPlace(
														e.target.value
													)
												}
												disabled={defaultDisabled}
											/>
										</FormGroup>
									</Col>
									<Col md={4}>
										<FormGroup
											onChange={(e) =>
												setWeatherPoliticallyExp(
													e.target.value
												)
											}
										>
											<Label className="required">
												<ShortTextIcon />
												Whether Politically Exposed
											</Label>
											<CustomInput
												type="select"
												value={weatherPoliticallyExp}
												disabled={defaultDisabled}
												required
											>
												<option value="Politically Exposed Person (PEP)">
													Politically Exposed Person
													(PEP)
												</option>
												<option value="Related to a Politically Exposed Person (RPEP)">
													Related to a Politically
													Exposed Person (RPEP)
												</option>
												<option value="No">No</option>
											</CustomInput>
										</FormGroup>
									</Col>
									<Col md={4}>
										<FormGroup>
											<Label>
												<i class="fas fa-user-graduate"></i>{' '}
												Highest Education
											</Label>
											<Input
												type="text"
												min="2"
												className="form-control"
												placeholder="e.g. : Bachelore's, Master's"
												// pattern="[A-Za-z]{2,20}"
												// title="Education Details should Have a letters. e.g. Bachelore's etc etc."
												value={highestEdu}
												onChange={(e) =>
													setHighestEdu(
														e.target.value
													)
												}
												disabled={defaultDisabled}
											/>
										</FormGroup>
									</Col>
									<Col md={4}>
										<FormGroup
											onChange={(e) => {
												setMarriageStatus(
													e.target.value
												);
												if (
													marriageStatus === 'Single'
												) {
													setDateOfMariage();
												}
											}}
										>
											<Label className="required">
												<ShortTextIcon />
												Marital Status
											</Label>
											<CustomInput
												type="select"
												value={marriageStatus}
												disabled={defaultDisabled}
												required
											>
												<option
													value="Single"
													name={marriageStatus}
												>
													Single
												</option>
												<option
													value="Married"
													name={marriageStatus}
												>
													Married
												</option>
											</CustomInput>
										</FormGroup>
									</Col>
									{marriageStatus === 'Married' && (
										<Col md={4}>
											<FormGroup>
												<Label>
													<i class="far fa-calendar-plus"></i>{' '}
													Date of Marriage
												</Label>
												<Input
													type="date"
													className="form-control"
													value={dateOfMariage}
													onChange={(e) => {
														setDateOfMariage(
															e.target.value
														);
													}}
													disabled={defaultDisabled}
												/>
											</FormGroup>
										</Col>
									)}

									<Col md={4}>
										<FormGroup>
											<Input
												type="hidden"
												min="2"
												className="form-control"
												value={passPath}
												disabled={defaultDisabled}
											/>
										</FormGroup>
									</Col>
									<Col md={4}>
										<FormGroup>
											<Input
												type="hidden"
												min="2"
												className="form-control"
												value={panPath}
												disabled={defaultDisabled}
											/>
										</FormGroup>
									</Col>
									<Col md={4}>
										<FormGroup>
											<Input
												type="hidden"
												min="2"
												className="form-control"
												value={adharPath}
												disabled={defaultDisabled}
											/>
										</FormGroup>
									</Col>
									<Col md={4}>
										<FormGroup>
											<Input
												type="hidden"
												min="2"
												className="form-control"
												value={provAcPath}
												disabled={defaultDisabled}
											/>
										</FormGroup>
									</Col>

									<Col md={12}>
										<br />
										<FormGroup
											className="shadow-none rounded"
											id="page-head"
										>
											<h5
												className="pl-1 pr-1 pt-1 pb-1"
												style={{
													width: '100%',
													textAlign: 'center',
													backgroundColor: '#eff6f9',
												}}
											>
												Upload Required Document Copies
											</h5>
										</FormGroup>
									</Col>

									<Col md={6}>
										<FormGroup>
											<Label>
												<DescriptionOutlinedIcon />
												Passport Size Photo
											</Label>
											<Input
												type="file"
												id="passportPhotoImageFile"
												onChange={passPortPhotoHandeler}
												disabled={defaultDisabled}
											/>
											{passPath ? (
												<embed
													src={`${process.env.REACT_APP_URL_API}/${passPath}`}
													width="50px"
													height="50px"
													className="mt-2"
												/>
											) : passPortPhoto ? (
												<embed
													src={`${passPortPhoto}`}
													width="50px"
													height="50px"
													className="mt-2"
												/>
											) : null}
											{/* { passPortPhoto &&  <embed src={`${passPortPhoto}`} width="50px" height="50px" className="mt-2"/> } */}
											<FormText color="muted">
												PDF or JPEG format
											</FormText>
										</FormGroup>
									</Col>

									<Col md={6}>
										<FormGroup>
											<Label>
												<DescriptionOutlinedIcon />
												Pan Card
											</Label>
											<Input
												type="file"
												id="panCardImageFile"
												onChange={
													panCardFileUploadHandler
												}
												disabled={defaultDisabled}
											/>
											{panPath ? (
												<embed
													src={`${process.env.REACT_APP_URL_API}/${panPath}`}
													width="50px"
													height="50px"
													className="mt-2"
												/>
											) : panFile ? (
												<embed
													src={`${panFile}`}
													width="50px"
													height="50px"
													className="mt-2"
												/>
											) : null}
											<FormText color="muted">
												PDF or JPEG format
											</FormText>
										</FormGroup>
									</Col>
									<Col md={6}>
										<FormGroup>
											<Label>
												<DescriptionOutlinedIcon />
												Aadhar Card (Front and Back)
											</Label>
											<Input
												type="file"
												id="adharCardImageFile"
												onChange={
													adharCardUploadFileHandler
												}
												disabled={defaultDisabled}
											/>
											{adharPath ? (
												<embed
													src={`${process.env.REACT_APP_URL_API}/${adharPath}`}
													width="50px"
													height="50px"
													className="mt-2"
												/>
											) : adharFile ? (
												<embed
													src={`${adharFile}`}
													width="50px"
													height="50px"
													className="mt-2"
												/>
											) : null}
											<FormText color="muted">
												PDF or JPEG format
											</FormText>
										</FormGroup>
									</Col>

									<Col md={6}>
										<FormGroup>
											<Label>
												<DescriptionOutlinedIcon />
												Cancelled Cheque of Provided
												Account
											</Label>
											<Input
												type="file"
												id="provAccountImageFile"
												onChange={
													proviAccountUploadFileHandler
												}
												disabled={defaultDisabled}
											/>
											{provAcPath ? (
												<embed
													src={`${process.env.REACT_APP_URL_API}/${provAcPath}`}
													width="50px"
													height="50px"
													className="mt-2"
												/>
											) : provAccountCanCheck ? (
												<embed
													src={`${provAccountCanCheck}`}
													width="50px"
													height="50px"
													className="mt-2"
												/>
											) : null}
											<FormText color="muted">
												PDF or JPEG format
											</FormText>
										</FormGroup>
									</Col>

									<Col md={6}>
										<FormGroup>
											<Label>
												<DescriptionOutlinedIcon />
												Cancelled Cheque of Provided
												Account Two
											</Label>
											<Input
												type="file"
												id="provAccountImageFileTwo"
												onChange={
													proviAccountTwoUploadFileHandler
												}
												disabled={defaultDisabled}
											/>
											{provAcTwoPath ? (
												<embed
													src={`${process.env.REACT_APP_URL_API}/${provAcTwoPath}`}
													width="50px"
													height="50px"
													className="mt-2"
												/>
											) : provAccountTwoCanCheck ? (
												<embed
													src={`${provAccountTwoCanCheck}`}
													width="50px"
													height="50px"
													className="mt-2"
												/>
											) : null}
											<FormText color="muted">
												PDF or JPEG format
											</FormText>
										</FormGroup>
									</Col>

									<Col md={6}>
										<FormGroup>
											<Label>
												<DescriptionOutlinedIcon />
												CML - Client Master List
											</Label>
											<Input
												type="file"
												id="someFileImage"
												onChange={SomeFileToBeUploaded}
												disabled={defaultDisabled}
											/>
											{someFilePath ? (
												<embed
													src={`${process.env.REACT_APP_URL_API}/${someFilePath}`}
													width="50px"
													height="50px"
													className="mt-2"
												/>
											) : someFileCanCheck ? (
												<embed
													src={`${someFileCanCheck}`}
													width="50px"
													height="50px"
													className="mt-2"
												/>
											) : null}
											<FormText color="muted">
												PDF or JPEG format
											</FormText>
										</FormGroup>
									</Col>
								</Row>
								<input
									className="btn btn-outline-success mt-3 mb-3"
									type="submit"
									value="Submit"
									style={{
										marginLeft: '455px',
										width: '18%',
									}}
									disabled={defaultDisabled}
								/>
								{isLoading && (
									<LoadingSpinner isLoading={isLoading} />
								)}
							</Form>
						)}
					</Formik>

					<br />
				</div>
			</div>
		</>
	);
};

export default UpdateCustomer;
