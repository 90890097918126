import { useState, useEffect } from 'react';
import ApiServices from '../../ApiService/ApiConfig';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { FormGroup, Label, Input } from 'reactstrap';

import { Formik, Field, Form, ErrorMessage } from 'formik';

import * as Yup from 'yup';

const BrokerReportAddFundLandingPage = (props) => {
	const [reportInfo, setReportInfo] = useState([]);
	const [reportListData, setListReportData] = useState([]);
	const [reportStatus, setReportStatus] = useState('Accepted');

	const {
		name: portfolio_name,
		resettoken: resetToken,
		resetvalidity: resetValidity,
		id: id,
	} = useParams();

	var newRes;

	useEffect(() => {
		console.log(
			portfolio_name,
			'++++++++++++',
			resetToken,
			resetValidity,
			id
		);
		LandingPageData();
	}, []);

	const LandingPageData = () => {
		ApiServices.listDummyReportData()
			.then((res) => {
				console.log('--', res);
				setListReportData(res);
				res.map((element) => {
					if (element.new_reset_token === resetToken) {
						setReportInfo(element);
					}
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const columns = [
		{
			headerName: 'ISIN Number',
			field: 'ISIN_Number',
		},
		{
			headerName: 'Name of Security',
			field: 'Name_of_Securities',
		},
		{
			headerName: 'Buy/Sell',
			field: 'Suggested_Trade',
		},
	];

	const defaultColDef = {
		sortable: true,
		filter: true,
	};

	const SubmitHandler = async () => {
		// console.log(modalData);

		let reportModelData = [];
		reportInfo.dummy_model_data.map((element) => {
			let obj = {};
			obj['ISIN Number'] = element['ISIN_Number'];
			obj['Name_of_Securities'] = element['Name_of_Securities'];
			obj['Suggested_Trade'] = element['Suggested_Trade'];
			reportModelData.push(obj);
		});

		const destUrl = `${process.env.REACT_APP_URL_API}/customerportfolio/updatestatusaddfundsglobal/${portfolio_name}`;
		const data = await axios({
			method: 'post',
			url: destUrl,
			data: {
				status: reportStatus,
				funds_added: reportInfo.funds_added,
				model: reportModelData,
				user_broker_cust_id: id,
				resetToken: resetToken,
				source: reportInfo.source,
			},
		}).then((res) => {
			if (res.data.message !== undefined) {
				toast.warn(`${res.data.message}`, { delay: 5000 });
			}
		});
		toast.success(`Response Recorded Successfully`);
		props.history.push('/thank');
	};

	return (
		<div className="container">
			<div className="container-fluid text-center">
				<h3
					className="pt-3 pb-3"
					style={{ backgroundColor: '#eff6f9', marginTop: 100 }}
				>
					Transaction Report
				</h3>
			</div>

			<div
				className="ag-theme-alpine"
				style={{
					height: 400,
					width: 550,
					marginTop: 30,
					marginLeft: '27%',
				}}
			>
				<AgGridReact
					rowData={reportInfo.dummy_model_data}
					columnDefs={columns}
					defaultColDef={defaultColDef}
				/>
				<br />
				{/* <input readOnly type="radio" value="" name=""className="ml-1 mr-2" />
                <label>I Accept This Change</label> */}

				<div className="form-group">
					<button
						onClick={SubmitHandler}
						className="btn btn-success mt-3 mb-3 mr-2"
					>
						Submit
					</button>
					{/* <button type="reset" className="btn btn-secondary">Reset</button> */}
				</div>
				{/* <Formik
					initialValues={{
						acceptTerms: false,
					}}
					validationSchema={Yup.object().shape({
						acceptTerms: Yup.bool().oneOf(
							[true],
							'Accept Terms is required'
						),
					})}
					onSubmit={SubmitHandler}
				>
					{({ errors, status, touched }) => (
						<Form>
							<div className="form-group form-check">
								<Field
									type="checkbox"
									name="acceptTerms"
									className={
										'form-check-input ' +
										(errors.acceptTerms &&
										touched.acceptTerms
											? ' is-invalid'
											: '')
									}
								/>
								<label
									htmlFor="acceptTerms"
									className="form-check-label"
								>
									I Agree With The Change
								</label>
								<ErrorMessage
									name="acceptTerms"
									component="div"
									className="invalid-feedback"
								/>
							</div>
						</Form>
					)}
				</Formik> */}
			</div>
		</div>
	);
};

export default BrokerReportAddFundLandingPage;
