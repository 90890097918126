import * as types from './index';

export const getAllAssetMaster = () => {
  console.log("In assset get master")
    return {
      type: types.ASSETS_REQUEST
    }
  };

export const receiveAssetMasterList = assetMasterList => {
    console.log("assetMasterListReceived Daa", assetMasterList)
    return{
        type : types.ASSET_RECEIVED,
        assetMasterList
    }
} 

export const postAssetMaster = assetName => {
  console.log("assetMaster------------Save",assetName)
  return{
    type: types.ADD_ASSET,
    assetName
  }
}

export const deleteAsset = assetId => {
  console.log("assetId-----------delete",assetId)
  return{
    type: types.DELETE_ASSET,
    assetId
  }
}

// export const editAsset = assetId => {
//   console.log("assetId-----------delete",assetId)
//   return{
//     type: types.EDIT_ASSET,
//     assetId
//   }
// }

export const requestAsset = assetId => {
  return{
    type:types.ASSET_REQUEST,
    assetId
  }
}

export const requestUpdateAsset = (updatedAsset, assetId) => {
return {
   type: types.UPDATE_ASSET, updatedAsset, assetId 
}
}

