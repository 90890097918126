// Name_of_Securities,  Suggested_Trade(Qty), Buy_Sell, Price

const COLUMNS = [
    {
        Header: 'Name of Securities',
        accessor: 'Name_of_Securities',
    },
    {
        Header: 'Qty',
        accessor: 'Suggested_Trade'
    },
    {
        Header: 'Buy Sell',
        accessor: 'Buy_Sell',
    },
    {
        Header: 'Price',
        accessor: 'Price'
    },
]

export default COLUMNS;