import React, { useState } from 'react'; 
import { Link, NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import data from '../json-data/sidebarData.json';
import logo from '../../images/DVIA_Logo.jpeg';
import './SidebarNew.css';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { useUserInfo } from '../../ContextProviders/UserInfo';

const style = {
    position: 'absolute',
    top: '80%',
    left: '12%',
    transform: 'translate(-50%, -50%)',
    width: 150,
    height: 200,
    bgcolor: 'background.paper',
    border: '1px solid #fff',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
  };

const SidebarNew = props => {

 const userInfo = useUserInfo();
 const [open, setOpen] = useState(false);
 const handleOpen = () => setOpen(true);
 const handleClose = () => {
    setOpen(false);
    }

 var item;

 if(userInfo) {
 item = userInfo.role.role_name
 console.log(item);
 }

 console.log(data);
 const filteredData = data.filter((filteredIp) => filteredIp.roles.some((a) => a === "super-admin"));
 console.log(filteredData);

 const onClickMoreButton = () => {
    setOpen(true)
 }

 const PopupWindow = () => {
    return (
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          style: {
            opacity: 0
          }
      }}
      >
        <Box sx={style}>
        <div className="container">
          <div className="row">
                <div className="col-sm-6">
                <NavLink to={"/customer"} activeClassName='is-active'>
                <span id="home-icon"><i class="fa fa-users" aria-hidden="true" data-tip data-for="Tooltip3"></i></span>
                <ReactTooltip id="Tooltip3" place="bottom" effect="solid" >Customers</ReactTooltip>
                </NavLink>

                <NavLink to={"/broker"} activeClassName='is-active'>
                <span id="home-icon"><i class="fa fa-handshake-o" aria-hidden="true" data-tip data-for="Tooltip10"></i></span>
                <ReactTooltip id="Tooltip10" place="bottom" effect="solid" >Broker</ReactTooltip>
                </NavLink>

                {
                  item === 'super-admin' ? 
                    <NavLink to={"/user"} activeClassName='is-active'>
                      <span id="home-icon"><i class="fa fa-user" aria-hidden="true" data-tip data-for="Tooltip4"></i></span>
                      <ReactTooltip id="Tooltip4" place="bottom" effect="solid" >Users</ReactTooltip>
                    </NavLink>
                    :
                    null
                }

                <NavLink to={"/redressalform"} activeClassName='is-active'>
                <span id="home-icon"><i class="fa fa-ticket" aria-hidden="true" data-tip data-for="Tooltip5"></i></span>
                <ReactTooltip id="Tooltip5" place="bottom" effect="solid" >Grievance Redressal</ReactTooltip>
                </NavLink>
                </div>
            </div>
        </div>
        </Box>
      </Modal>
      )
 }
 
 return (
 <div className="sidenav">
 <div id="adjust-sidebar">
 

 
 { (item === 'super-admin') && 

 <>
 
 <NavLink to={"/home"} activeClassName='is-active' > 
 <span id="home-icon"><i class="fa fa-desktop" aria-hidden="true" data-tip data-for="Tooltip"></i></span>
 <ReactTooltip id="Tooltip" place="bottom" effect="solid" >Dashboard</ReactTooltip>
 </NavLink>
 {/* <NavLink to={"/customer"} activeClassName='is-active'>
 <span id="home-icon"><i class="fa fa-users" aria-hidden="true" data-tip data-for="Tooltip3"></i></span>
 <ReactTooltip id="Tooltip3" place="bottom" effect="solid" >Customers</ReactTooltip>
 </NavLink> */}
 <NavLink to={"/modport"} activeClassName='is-active' >
 <span id="home-icon"><i class="fa fa-chart-bar" aria-hidden="true" data-tip data-for="Tooltip1"></i></span>
 <ReactTooltip id="Tooltip1" place="bottom" effect="solid" >Models</ReactTooltip>
 </NavLink>
 <NavLink to={"/customerportfolio"} activeClassName='is-active'>
 <span id="home-icon"><i class="fa fa-address-card" aria-hidden="true" data-tip data-for="Tooltip2"></i></span>
 <ReactTooltip id="Tooltip2" place="bottom" effect="solid" >Customer Portfolio</ReactTooltip>
 </NavLink>
 <NavLink to={'/dvwm'} activeClassName="is-active">
	<span id="home-icon"><i className="fa fa-file-text-o" aria-hidden="true" data-tip data-for="Tooltip3"></i></span>
	<ReactTooltip id="Tooltip3" place="bottom" effect="solid">DVWM</ReactTooltip>
	</NavLink>
 <NavLink to={"/fundsaddedglobal"} activeClassName='is-active'>
 <span id="home-icon"><i class="fa fa-money" aria-hidden="true" data-tip data-for="Tooltip9"></i></span>
 <ReactTooltip id="Tooltip9" place="bottom" effect="solid" >Add Funds</ReactTooltip>
 </NavLink>

 {/* <NavLink to={"/user"} activeClassName='is-active'>
 <span id="home-icon"><i class="fa fa-user" aria-hidden="true" data-tip data-for="Tooltip4"></i></span>
 <ReactTooltip id="Tooltip4" place="bottom" effect="solid" >Users</ReactTooltip>
 </NavLink>

 <NavLink to={"/redressalform"} activeClassName='is-active'>
 <span id="home-icon"><i class="fa fa-ticket" aria-hidden="true" data-tip data-for="Tooltip5"></i></span>
 <ReactTooltip id="Tooltip5" place="bottom" effect="solid" >Grievance Redressal</ReactTooltip>
 </NavLink> */}
 
 <NavLink to={"/trademisreports"} activeClassName="is-active">
  <span id="home-icon"><i className="fa fa-file" aria-hidden="true" data-tip data-for="Tooltip6"></i></span>
    <ReactTooltip id="Tooltip6" place="bottom" effect="solid">
      Trade MIS &amp; Report
    </ReactTooltip>
 </NavLink>

 <NavLink to={"/profile"} activeClassName='is-active'>
 <span id="home-icon"><i class="fa fa-cogs" aria-hidden="true" data-tip data-for="Tooltip7"></i></span>
 <ReactTooltip id="Tooltip7" place="bottom" effect="solid" >Settings</ReactTooltip>
 </NavLink>

 <Link to={"/"} activeClassName='is-active'>
 <span id="home-icon"><button id="more-btn" onClick={onClickMoreButton}><i class="fa fa-ellipsis-h" aria-hidden="true" data-tip data-for="Tooltip8"></i></button></span>
 <ReactTooltip id="Tooltip8" place="bottom" effect="solid" >More</ReactTooltip>
 </Link>
 {handleOpen && <div>{PopupWindow()}</div>}
 </>

 }
 

 { (item === 'admin') && 

 <>
 
 <NavLink to={"/home"} activeClassName='is-active' > 
 <span id="home-icon"><i class="fa fa-desktop" aria-hidden="true" data-tip data-for="Tooltip"></i></span>
 <ReactTooltip id="Tooltip" place="bottom" effect="solid" >Dashboard</ReactTooltip>
 </NavLink>
 {/* <NavLink to={"/customer"} activeClassName='is-active'>
 <span id="home-icon"><i class="fa fa-users" aria-hidden="true" data-tip data-for="Tooltip3"></i></span>
 <ReactTooltip id="Tooltip3" place="bottom" effect="solid" >Customers</ReactTooltip>
 </NavLink> */}
 <NavLink to={"/modport"} activeClassName='is-active' >
 <span id="home-icon"><i class="fa fa-chart-bar" aria-hidden="true" data-tip data-for="Tooltip1"></i></span>
 <ReactTooltip id="Tooltip1" place="bottom" effect="solid" >Models</ReactTooltip>
 </NavLink>
 <NavLink to={"/customerportfolio"} activeClassName='is-active'>
 <span id="home-icon"><i class="fa fa-address-card" aria-hidden="true" data-tip data-for="Tooltip2"></i></span>
 <ReactTooltip id="Tooltip2" place="bottom" effect="solid" >Customer Portfolio</ReactTooltip>
 </NavLink>
 <NavLink to={'/dvwm'} activeClassName="is-active">
	<span id="home-icon"><i className="fa fa-file-text-o" aria-hidden="true" data-tip data-for="Tooltip3"></i></span>
	<ReactTooltip id="Tooltip3" place="bottom" effect="solid">DVWM</ReactTooltip>
	</NavLink>
 <NavLink to={"/fundsaddedglobal"} activeClassName='is-active'>
 <span id="home-icon"><i class="fa fa-money" aria-hidden="true" data-tip data-for="Tooltip9"></i></span>
 <ReactTooltip id="Tooltip9" place="bottom" effect="solid" >Add Funds</ReactTooltip>
 </NavLink>

 {/* <NavLink to={"/user"} activeClassName='is-active'>
 <span id="home-icon"><i class="fa fa-user" aria-hidden="true" data-tip data-for="Tooltip4"></i></span>
 <ReactTooltip id="Tooltip4" place="bottom" effect="solid" >Users</ReactTooltip>
 </NavLink>

 <NavLink to={"/redressalform"} activeClassName='is-active'>
 <span id="home-icon"><i class="fa fa-ticket" aria-hidden="true" data-tip data-for="Tooltip5"></i></span>
 <ReactTooltip id="Tooltip5" place="bottom" effect="solid" >Grievance Redressal</ReactTooltip>
 </NavLink> */}
 
 <NavLink to={"/trademisreports"} activeClassName="is-active">
  <span id="home-icon"><i className="fa fa-file" aria-hidden="true" data-tip data-for="Tooltip6"></i></span>
    <ReactTooltip id="Tooltip6" place="bottom" effect="solid">
      Trade MIS &amp; Report
    </ReactTooltip>
 </NavLink>

 <NavLink to={"/profile"} activeClassName='is-active'>
 <span id="home-icon"><i class="fa fa-cogs" aria-hidden="true" data-tip data-for="Tooltip7"></i></span>
 <ReactTooltip id="Tooltip7" place="bottom" effect="solid" >Settings</ReactTooltip>
 </NavLink>

 <Link to={"/"} activeClassName='is-active'>
 <span id="home-icon"><button id="more-btn" onClick={onClickMoreButton}><i class="fa fa-ellipsis-h" aria-hidden="true" data-tip data-for="Tooltip8"></i></button></span>
 <ReactTooltip id="Tooltip8" place="bottom" effect="solid" >More</ReactTooltip>
 </Link>
 {handleOpen && <div>{PopupWindow()}</div>}
 </>

 }

 
 { (item === 'customer') && 

 <>

 </>

 }



 </div>
 </div>
 );
};

// fa-chart-bar

export default SidebarNew;