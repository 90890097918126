import React from "react";
import './home.css';
import DVLogo from '../images/DVIA_Logo.jpeg';

const Home = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img src={DVLogo} id="home-image" />
            <p id="Under-Construction-Status">Coming Soon ...</p>
          </div>
        </div>
      </div>
    );
}

export default Home;