import React from "react";
import ReactDOM from 'react-dom';
import classNames from "classnames";
import PropTypes from "prop-types";
import "font-awesome/css/font-awesome.min.css";
import classesNames from './Loader.module.css';

const Backdrop = (props) => {
  return <div className={classesNames.backdrop} onClick={props.onConfirm} />;
};

const ModalOverlay = (props) => {
  return (
    <div className={classesNames.modal}>
      <div className={classesNames.loader} id={props.id}>Loading...</div>
      {/* <div className={classesNames.loader}>
      <svg viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <circle className={classesNames.load} id={classesNames.one} cx="60" cy="60" r="40" />
        <circle className={classesNames.load} id={classesNames.two} cx="60" cy="60" r="40" />
        <circle className={classesNames.load} id={classesNames.three} cx="60" cy="60" r="40" />
        <g>
          <circle className={classesNames.point} id={classesNames.one1} cx="45" cy="70" r="5" />
          <circle className={classesNames.point} id={classesNames.two2} cx="60" cy="70" r="5" />
          <circle className={classesNames.point} id={classesNames.three3} cx="75" cy="70" r="5" />
        </g>
      </svg>
    </div> */}
    </div>
  );
};

const LoadingSpinner = props => {
  return(
  <>
  {ReactDOM.createPortal(
      <Backdrop onConfirm={props.onConfirm} />,
      document.getElementById('backdrop-root')
    )}
  {ReactDOM.createPortal(
      <ModalOverlay />,
      document.getElementById('overlay-root')
      )}
  </>)
};

LoadingSpinner.propTypes = {
  loading: PropTypes.bool
};

export default LoadingSpinner;