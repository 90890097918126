import React,{ useState } from 'react';
import { Link } from 'react-router-dom';

export const AddNew = (props) => {
  return (
    <>
    {<span>
      <Link to={props.urlAdd}><button className="btn btn-outline-success" id="btn-success" >Add {props.type} +</button></Link>
    </span>}
    </>
  );
};