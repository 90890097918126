import React, { useState, useEffect } from 'react';
import ApiServices from '../../ApiService/ApiConfig';
import './RiskProf.css';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const RiskProfileQustionair = (props) => {
	let { id } = useParams();
	console.log('state-data(Edit)', props.location.state.data);
	const Cust_Data = props.location.state.data;
	const ScoreUpdateCount = Cust_Data.customer_profile.score_update_count;

	console.log(id);

	var a = 1;
	var b = 2;
	var c = 3;
	var d = 4;
	var e = 5;
	var f = 6;

	const questions = [
		{
			questionText:
				'What is your Investment horizon? How long can you keep your money invested in the market before needing access to it ?',
			answerOptions: [
				{ answerText: 'Up to two years', isCorrect: a },
				{ answerText: 'Two and three years', isCorrect: b },
				{ answerText: 'Three and five years', isCorrect: c },
				{ answerText: 'Five years and Ten years', isCorrect: d },
				{ answerText: 'Ten years and more', isCorrect: e },
			],
		},
		{
			questionText: 'The age group you belong to ?',
			answerOptions: [
				{ answerText: '51 years and above', isCorrect: a },
				{ answerText: '36‐50 years', isCorrect: b },
				{ answerText: '25‐35 years', isCorrect: c },
				{ answerText: 'Less than 25 years', isCorrect: d },
			],
		},
		{
			questionText:
				'How well do you understand investing in the markets ?',
			answerOptions: [
				{
					answerText:
						'I am a novice. I don’t understand the markets at all.',
					isCorrect: a,
				},
				{
					answerText:
						'I have basic understanding of investing. I understand the risks and basic investment concepts like diversification.',
					isCorrect: b,
				},
				{
					answerText:
						'have an amateur interest in investing. I have invested earlier on my own. I understand how markets fluctuate and the pros and cons of different investment classes.',
					isCorrect: c,
				},
				{
					answerText:
						'I am an experienced investor. I have invested in different markets and understand different investment strategies. I have my own investment philosophy.',
					isCorrect: d,
				},
			],
		},
		{
			questionText:
				'My current and future income sources (example: salary, business income, investment income etc) are:',
			answerOptions: [
				{ answerText: 'Very unstable', isCorrect: a },
				{ answerText: 'Unstable', isCorrect: b },
				{ answerText: 'Somewhat stable', isCorrect: c },
				{ answerText: 'Stable', isCorrect: d },
				{ answerText: 'Very Stable', isCorrect: e },
			],
		},
		{
			questionText:
				'From the following 5 possible investment scenario, please select the option which defines your investment objective ?',
			answerOptions: [
				{ answerText: 'I cannot consider any Loss', isCorrect: a },
				{
					answerText:
						'I can consider Loss of 4% if the possible Gains are of 10%',
					isCorrect: b,
				},
				{
					answerText:
						'I can consider Loss of 8% if the possible Gains are of 22%',
					isCorrect: c,
				},
				{
					answerText:
						'I can consider Loss of 14% if the possible Gains are of 30%',
					isCorrect: d,
				},
				{
					answerText:
						'I can consider Loss of 25% if the possible Gains are of 50%',
					isCorrect: e,
				},
			],
		},
		// {
		// 	questionText:
		// 		'Which of the following best describes your current stage of life ?',
		// 	answerOptions: [
		// 		{
		// 			answerText:
		// 				'Young family with a home. You have a mortgage and childcare costs and maintain only small cash balances.',
		// 			isCorrect: a,
		// 		},
		// 		{
		// 			answerText:
		// 				'Retired. You rely on existing funds and investments to maintain your lifestyle in retirement. You may already be receiving a Government pension and/or Superannuation pension.',
		// 			isCorrect: b,
		// 		},
		// 		{
		// 			answerText:
		// 				'Preparing for retirement. You own your home and have few financial burdens; you want to ensure you can afford a comfortable retirement.',
		// 			isCorrect: c,
		// 		},
		// 		{
		// 			answerText:
		// 				'A couple without children. Preparing for the future by establishing a home. Expecting to have or already have a high purchase rate of household and consumer items.',
		// 			isCorrect: d,
		// 		},
		// 		{
		// 			answerText:
		// 				'Single with few financial burdens. Ready to accumulate wealth for future short term and long-term goals.',
		// 			isCorrect: e,
		// 		},
		// 		{
		// 			answerText:
		// 				'Mature family. You are in your peak earning years and your mortgage is under control. You both work and you may or may not have children that are growing up or have left home. You’re ready to start thinking about your retirement years.',
		// 			isCorrect: f,
		// 		},
		// 	],
		// },
		{
			questionText:
				'If your investment outlook is long‐term (more than five years), how long will you hold on to a poorly performing portfolio before cashing in ?',
			answerOptions: [
				{
					answerText:
						'Not hold and cash in immediately if there is an erosion of my capital',
					isCorrect: a,
				},
				{ answerText: 'I’d hold for 3 months', isCorrect: b },
				{ answerText: 'I’d hold for 6 months', isCorrect: c },
				{ answerText: 'I’d hold for one year', isCorrect: d },
				{ answerText: 'I’d hold for up to two years', isCorrect: e },
				{
					answerText: 'I’d hold for more than two years',
					isCorrect: f,
				},
			],
		},
		{
			questionText:
				'Volatile investments usually provide higher returns and tax efficiency. What is your desired balance ?',
			answerOptions: [
				{
					answerText:
						'Preferably guaranteed returns, before tax efficiency',
					isCorrect: a,
				},
				{
					answerText:
						'Stable, reliable returns, minimal tax efficiency',
					isCorrect: b,
				},
				{
					answerText:
						'Some variability in returns, some tax efficiency',
					isCorrect: c,
				},
				{
					answerText:
						'Moderate variability in returns, reasonable tax efficiency',
					isCorrect: d,
				},
				{
					answerText:
						'Unstable, but potentially higher returns, maximizing tax efficiency',
					isCorrect: e,
				},
			],
		},
		{
			questionText:
				'If a few months after investing, the value of your investments declines by 20%, what would you do ?',
			answerOptions: [
				{
					answerText:
						'Cut losses immediately and liquidate all investments. Capital preservation is paramount.',
					isCorrect: a,
				},
				{
					answerText:
						'Cut your losses and transfer investments to safer asset classes.',
					isCorrect: b,
				},
				{
					answerText:
						'You would be worried, but would give your investments a little more time.',
					isCorrect: c,
				},
				{
					answerText:
						'You are ok with volatility and accept decline in portfolio value as a part of investing. You would keep your investments as they are.',
					isCorrect: d,
				},
				{
					answerText:
						'You would add to your investments to bring the average buying price lower. You are confident about your investments and are not perturbed by notional losses.',
					isCorrect: e,
				},
			],
		},
		{
			questionText:
				'Which of these scenarios best describes your “Risk Range”? What level of losses and profits would be comfortable with ?',
			answerOptions: [
				{
					answerText:
						'Investment A(Choice) 1%(Worst Year) 15%(Best Year)',
					isCorrect: a,
				},
				{
					answerText:
						'Investment B(Choice) -5%(Worst Year) 20%(Best Year)',
					isCorrect: b,
				},
				{
					answerText:
						'Investment C(Choice) -10%(Worst Year) 25%(Best Year)',
					isCorrect: c,
				},
				{
					answerText:
						'Investment D(Choice) -14%(Worst Year) 30%(Best Year)',
					isCorrect: d,
				},
				{
					answerText:
						'Investment E(Choice) -18%(Worst Year) 35%(Best Year)',
					isCorrect: e,
				},
				{
					answerText:
						'Investment F(Choice) -21%(Worst Year) 40%(Best Year)',
					isCorrect: f,
				},
			],
		},
		// {
		// 	questionText:
		// 		'How secure is your current and future income from sources such as salary, pensions or other investments ?',
		// 	answerOptions: [
		// 		{ answerText: 'Very unstable', isCorrect: a },
		// 		{ answerText: 'Unstable', isCorrect: b },
		// 		{ answerText: 'Somewhat stable', isCorrect: c },
		// 		{ answerText: 'Stable', isCorrect: d },
		// 		{ answerText: 'Very Stable', isCorrect: e },
		// 	],
		// },
		// {
		// 	questionText:
		// 		'How well do you understand investing in the markets ?',
		// 	answerOptions: [
		// 		{
		// 			answerText:
		// 				'I am a novice. I don’t understand the markets at all.',
		// 			isCorrect: a,
		// 		},
		// 		{
		// 			answerText:
		// 				'I have basic understanding of investing. I understand the risks and basic investment concepts like diversification.',
		// 			isCorrect: b,
		// 		},
		// 		{
		// 			answerText:
		// 				'have an amateur interest in investing. I have invested earlier on my own. I understand how markets fluctuate and the pros and cons of different investment classes.',
		// 			isCorrect: c,
		// 		},
		// 		{
		// 			answerText:
		// 				'I am an experienced investor. I have invested in different markets and understand different investment strategies. I have my own investment philosophy.',
		// 			isCorrect: d,
		// 		},
		// 	],
		// },
		// {
		// 	questionText:
		// 		'What is your Investment horizon? How long can you keep your money invested in the market before needing access to it ?',
		// 	answerOptions: [
		// 		{ answerText: 'Up to two years', isCorrect: a },
		// 		{ answerText: 'Two and three years', isCorrect: b },
		// 		{ answerText: 'Three and five years', isCorrect: c },
		// 		{ answerText: 'Five years and Ten years', isCorrect: d },
		// 		{ answerText: 'Ten years and more', isCorrect: e },
		// 	],
		// },
		// {
		// 	questionText:
		// 		'From the following 5 possible investment scenario, please select the option which defines your investment objective ?',
		// 	answerOptions: [
		// 		{ answerText: 'I cannot consider any Loss', isCorrect: a },
		// 		{
		// 			answerText:
		// 				'I can consider Loss of 4% if the possible Gains are of 10%',
		// 			isCorrect: b,
		// 		},
		// 		{
		// 			answerText:
		// 				'I can consider Loss of 8% if the possible Gains are of 22%',
		// 			isCorrect: c,
		// 		},
		// 		{
		// 			answerText:
		// 				'I can consider Loss of 14% if the possible Gains are of 30%',
		// 			isCorrect: d,
		// 		},
		// 		{
		// 			answerText:
		// 				'I can consider Loss of 25% if the possible Gains are of 50%',
		// 			isCorrect: e,
		// 		},
		// 	],
		// },
		// {
		// 	questionText:
		// 		'If your investment outlook is long‐term (more than five years), how long will you hold on to a poorly performing portfolio before cashing in ?',
		// 	answerOptions: [
		// 		{
		// 			answerText:
		// 				'Not hold and cash in immediately if there is an erosion of my capital',
		// 			isCorrect: a,
		// 		},
		// 		{ answerText: 'I’d hold for 3 months', isCorrect: b },
		// 		{ answerText: 'I’d hold for 6 months', isCorrect: c },
		// 		{ answerText: 'I’d hold for one year', isCorrect: d },
		// 		{ answerText: 'I’d hold for up to two years', isCorrect: e },
		// 		{
		// 			answerText: 'I’d hold for more than two years',
		// 			isCorrect: f,
		// 		},
		// 	],
		// },
		// {
		// 	questionText:
		// 		'Volatile investments usually provide higher returns and tax efficiency. What is your desired balance ?',
		// 	answerOptions: [
		// 		{
		// 			answerText:
		// 				'Preferably guaranteed returns, before tax efficiency',
		// 			isCorrect: a,
		// 		},
		// 		{
		// 			answerText:
		// 				'Stable, reliable returns, minimal tax efficiency',
		// 			isCorrect: b,
		// 		},
		// 		{
		// 			answerText:
		// 				'Some variability in returns, some tax efficiency',
		// 			isCorrect: c,
		// 		},
		// 		{
		// 			answerText:
		// 				'Moderate variability in returns, reasonable tax efficiency',
		// 			isCorrect: d,
		// 		},
		// 		{
		// 			answerText:
		// 				'Unstable, but potentially higher returns, maximizing tax efficiency',
		// 			isCorrect: e,
		// 		},
		// 	],
		// },
		// {
		// 	questionText:
		// 		'If a few months after investing, the value of your investments declines by 20%, what would you do ?',
		// 	answerOptions: [
		// 		{
		// 			answerText:
		// 				'Cut losses immediately and liquidate all investments. Capital preservation is paramount.',
		// 			isCorrect: a,
		// 		},
		// 		{
		// 			answerText:
		// 				'Cut your losses and transfer investments to safer asset classes.',
		// 			isCorrect: b,
		// 		},
		// 		{
		// 			answerText:
		// 				'You would be worried, but would give your investments a little more time.',
		// 			isCorrect: c,
		// 		},
		// 		{
		// 			answerText:
		// 				'You are ok with volatility and accept decline in portfolio value as a part of investing. You would keep your investments as they are.',
		// 			isCorrect: d,
		// 		},
		// 		{
		// 			answerText:
		// 				'You would add to your investments to bring the average buying price lower. You are confident about your investments and are not perturbed by notional losses.',
		// 			isCorrect: e,
		// 		},
		// 	],
		// },
		// {
		// 	questionText:
		// 		'Which of these scenarios best describes your “Risk Range”? What level of losses and profits would be comfortable with ?',
		// 	answerOptions: [
		// 		{
		// 			answerText:
		// 				'Investment A(Choice) 1%(Worst Year) 15%(Best Year)',
		// 			isCorrect: a,
		// 		},
		// 		{
		// 			answerText:
		// 				'Investment B(Choice) -5%(Worst Year) 20%(Best Year)',
		// 			isCorrect: b,
		// 		},
		// 		{
		// 			answerText:
		// 				'Investment C(Choice) -10%(Worst Year) 25%(Best Year)',
		// 			isCorrect: c,
		// 		},
		// 		{
		// 			answerText:
		// 				'Investment D(Choice) -14%(Worst Year) 30%(Best Year)',
		// 			isCorrect: d,
		// 		},
		// 		{
		// 			answerText:
		// 				'Investment E(Choice) -18%(Worst Year) 35%(Best Year)',
		// 			isCorrect: e,
		// 		},
		// 		{
		// 			answerText:
		// 				'Investment F(Choice) -21%(Worst Year) 40%(Best Year)',
		// 			isCorrect: f,
		// 		},
		// 	],
		// },
		// {
		// 	questionText:
		// 		'Which one of the following statements describes your feelings towards choosing an investment ?',
		// 	answerOptions: [
		// 		{
		// 			answerText:
		// 				'I would prefer investments with little or no fluctuation in value and have a low degree of risk associated with them. I am willing to accept the lower return associated with these investments.',
		// 			isCorrect: a,
		// 		},
		// 		{
		// 			answerText:
		// 				'I prefer to diversify with a mix of investments that have an emphasis on low risk. I am happy to have a small proportion of the portfolio invested in assets that have a higher degree of risk in order to achieve a slightly higher return.',
		// 			isCorrect: b,
		// 		},
		// 		{
		// 			answerText:
		// 				'I prefer to have a spread of investments in a balanced portfolio.',
		// 			isCorrect: c,
		// 		},
		// 		{
		// 			answerText:
		// 				'I prefer to diversify my investments with an emphasis on more investments that have higher returns, but still having a small amount of low-risk investments.',
		// 			isCorrect: d,
		// 		},
		// 		{
		// 			answerText:
		// 				'I would select investments that have a higher degree of investment price fluctuation so that I can earn higher long-term returns.',
		// 			isCorrect: e,
		// 		},
		// 	],
		// },
		// {
		// 	questionText:
		// 		'Do you feel you are appropriately covered against personal and/or business risks such as accident, illness, trauma or death ?',
		// 	answerOptions: [
		// 		{ answerText: 'Yes', isCorrect: a },
		// 		{ answerText: 'No', isCorrect: b },
		// 	],
		// },
	];

	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [showScore, setShowScore] = useState(false);
	const [score, setScore] = useState(0);
	const [finalScore, setFinalScore] = useState('');
	const [riskProfStatus, setRiskProfStatus] = useState('Completed');

	// useEffect(() => {
	// CustomerData();
	// }, [])

	// const CustomerData = () => {
	// ApiServices.listCustomer()
	// .then((res) => {
	// console.log(res);
	// console.log(id);

	// // var newd = res.filter(function (el)
	// // {
	// // return el.id = id
	// // }
	// // );
	// // console.log(newd);

	// })
	// .catch((err) => {
	// console.log(err);
	// })
	// }

	const handleAnswerOptionClick = (isCorrect) => {
		if (isCorrect) {
			setScore(score + isCorrect);
		}
		//Previous Code written by Hitesh Borse.
		// if (score < 20) {
		// 	setFinalScore('Conservative');
		// } else if (score <= (20 || 29)) {
		// 	setFinalScore('Moderate');
		// } else if (score <= (30 || 39)) {
		// 	setFinalScore('Balanced');
		// } else if (score > 40) {
		// 	setFinalScore('Aggressive');
		// }

		//New Changes by Khema C Raul.
		if (score <= 5) {
			setFinalScore('Conservative');
		}else if (score >= 6 && score <= 95) {
			setFinalScore('Balanced');
		} else if (score >= 96 && score <= 100) {
			setFinalScore('Aggressive');
		}

		console.log("SCORE", score);

		const nextQuestion = currentQuestion + 1;
		if (nextQuestion < questions.length) {
			setCurrentQuestion(nextQuestion);
		} else {
			setShowScore(true);
		}
	};

	const handleRiskProf = () => {
		console.log('button clicked');
		console.log(riskProfStatus);
		console.log(score);
		console.log(finalScore);

		ApiServices.updateCustRiskProf(id, {
			status: riskProfStatus,
			score: finalScore,
			score_update_count: ScoreUpdateCount + 1,
		})
			.then((res) => {
				console.log(res);
				props.history.push('/customer'); 
				toast.success(
					`Customer Profile Verified Successfully..`
				);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div>
			<br />
			<div className="app">
				{showScore ? (
					<div className="score-section">
						{/* You scored {score} out of {questions.length} */}
						<br />
						Thanks For Your Response
						<button
							className="btn btn-success mt-3 mb-3"
							type="submit"
							style={{ width: '23%', height: '28px' }}
							onClick={handleRiskProf}
						>
							Ok
						</button>
					</div>
				) : (
					<>
						<div className="question-section">
							<div className="question-count">
								<span>Question {currentQuestion + 1}</span>/
								{questions.length}
							</div>
							<div className="question-text">
								{questions[currentQuestion].questionText}
							</div>
						</div>
						<div className="answer-section">
							{questions[currentQuestion].answerOptions.map(
								(answerOption) => (
									<button
										className="button"
										onClick={() =>
											handleAnswerOptionClick(
												answerOption.isCorrect
											)
										}
									>
										{answerOption.answerText}
									</button>
								)
							)}
						</div>
					</>
				)}
			</div>
			<br />
		</div>
	);
};

export default RiskProfileQustionair;
